.invoice-wrap-box {
  width: 100%;
  display: flex;
  height: 100%;

  @media only screen and (max-width: 55em) {
    flex-direction: column;
  }

  .left-box {
    flex: 0 0 60%;
    border-right: 0.1rem solid rgba(204, 204, 204, 0.5);
    display: flex;
    flex-direction: column;
    padding: 0rem 2rem;
    //   padding-top: 3rem;

    @media only screen and (max-width: 55em) {
      flex: 0 0 25%;
      padding: 0rem 1rem;
    }

    .invoice-preview-detail-wrap {
      flex: 1;
      overflow-y: scroll;
      width: 100%;
      border-radius: 2.4rem;
      min-height: 45rem;
      margin: 2rem auto;
      padding: 3rem 0rem;
      display: flex;
      flex-direction: column;
      border: unset;

      &::-webkit-scrollbar {
        display: none;
      }

      @media only screen and (max-width: 55em) {
        padding-bottom: 1.5rem;
        margin-bottom: unset;
      }

      &::-webkit-scrollbar:horizontal {
        display: none;
      }

      /* width */
      &::-webkit-scrollbar {
        width: 0.5rem;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: none !important;
        background-color: transparent !important;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #cccccc;
        border-radius: 30rem;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #cccccc;
      }

      .box {
        display: flex;
        padding: 2rem 2.5rem;
        margin-bottom: 1.5rem;

        @media only screen and (max-width: 55em) {
          padding: 1.5rem;
        }

        .left-box {
          flex: 0 0 40%;
          border-right: unset;
          padding-top: 0rem;

          @media only screen and (max-width: 55em) {
            flex: 0 0 25%;
          }

          .label {
            font-weight: 700;
            font-size: 1.25rem;
          }

          .value {
            font-size: 1.35rem;
          }
        }

        .right-box {
          flex: 1;

          .label {
            font-weight: 700;
            font-size: 1.35rem;
          }

          .value {
            font-size: 1.45rem;
          }
        }
      }

      .top-info {
        // padding-top: 2rem;
        // padding-bottom: 2rem;
        .left-box {
          .value {
            margin-top: 0.6rem;
          }
        }

        .right-box {
          .value {
            margin-top: 0.6rem;
          }
        }
      }

      .detail-info {
        .right-box {
          display: grid;
          grid-template-columns: 1fr max-content 1fr;

          & > *:last-child {
            text-align: right;
          }

          & > *:nth-child(2) {
            text-align: center;
          }
        }
      }

      .middle-info {
        // padding-top: 2rem;
        // padding-bottom: 2rem;
        .left-box {
          .label {
            margin-bottom: 1.5rem;
          }
          .value {
            margin-top: 0.8rem;
          }
        }

        .right-box {
          .label {
            margin-bottom: 1.5rem;
          }
          .value {
            margin-top: 0.8rem;
          }
        }
      }

      .sum-detail-box {
        padding: 0.5rem 2.5rem;
        margin-bottom: 0.5rem;
        font-size: 1.25rem;

        .left-box {
          .name {
            text-transform: capitalize;
          }
        }

        .right-box {
          display: grid;
          grid-template-columns: 1fr max-content 1fr;

          & > *:last-child {
            text-align: right;
          }

          & > *:nth-child(2) {
            text-align: center;
          }
        }

        .underline {
          width: 100%;
          margin: 1rem 0rem 0.4rem 0rem;
        }
      }

      .sum-detail-box-underline {
        .right-box {
          grid-template-columns: 1fr;
        }
      }

      .underline-box {
        padding: 0rem 2.5rem;
        margin-top: 3rem;
        margin-bottom: 1.5rem;
      }

      .note-wrap-box {
        padding: 0rem 2.5rem;

        .note {
          font-weight: 700;
          font-size: 1.3rem;
          margin-bottom: 0.8rem;
        }

        .text {
          font-size: 1.45rem;
          line-height: 2rem;
          letter-spacing: 0.02rem;
        }
      }
    }

    //   .preview {
    //     width: 100%;
    //     border-radius: 24px;
    //     padding-top: 3.2rem;
    //     padding-bottom: 5rem;
    //     height: max-content;

    //     .subs {
    //         color: #676767;
    //         font-size: 1rem;
    //     }

    //     .subtotal {
    //         color: #676767;
    //         padding-top: 2.85rem !important;
    //         font-size: 1rem;
    //     }

    //     .subvalue {
    //         color: #000000;
    //     }

    //     .first-sub {
    //             padding-top: 2.6rem;
    //             margin-top: 1.6rem;
    //             border-top: 0.7px solid #CCCCCC;
    //     }

    //     .last-sub {
    //         padding-bottom: 2.6rem;
    //         border-bottom: 0.7px solid #CCCCCC;
    // }

    //     .bottom_sub {
    //         padding-bottom: 2.3rem !important;

    //     }

    //     .invoice_total {
    //         color: #000000;
    //     }

    //     .invoice_total_value {
    //         color: #020202;
    //         font-weight: 600;
    //     }

    //     .invoice_header {
    //         display: flex;
    //         gap: 15rem;
    //         align-items: center;
    //         padding-left: 3.7rem;
    //         justify-content: flex-start;
    //         background: #F7F8F7;
    //         padding-top: 1.6rem;
    //         padding-bottom: 1.6rem;

    //         .invoice_number, .invoice_date {
    //             display: flex;
    //             flex-direction: column;
    //             gap: 0.6rem;

    //             span {
    //                 font-weight: 600;
    //                 font-size: 1.2rem;
    //                 color: #020202;
    //             }

    //             p {
    //                 font-weight: 400;
    //                 color: #676767;
    //                 font-size: 1.2rem;
    //             }
    //         }

    //     }

    //     .bill_to_from_wrapper {
    //         display: flex;
    //         gap: 8rem;
    //         align-items: center;
    //         padding-left: 3.7rem;
    //         justify-content: flex-start;
    //         width: 100%;
    //         padding-top: 3.6rem;
    //         padding-bottom: 3.2rem;

    //         .bill_to, .bill_from {
    //             display: flex;
    //             flex-direction: column;
    //             gap: 1.1rem;
    //             align-items: flex-start;

    //             .bill_header {
    //                 font-weight: 600;
    //                 font-size: 1.2rem;
    //                 color: #020202;
    //             }

    //             .header_content {
    //                 display: flex;
    //                 flex-direction: column;
    //                 gap: 0.6rem;
    //                 width: 70%;
    //                 span {
    //                     font-weight: 400;
    //                     font-size: 1.2rem;
    //                     color: #676767;
    //                 }

    //             }

    //         }

    //     }
    // }
    // .item_table {
    //     display: flex;
    //     align-items: flex-start;
    //     width: 100%;

    //     table {

    //         tr {
    //             text-align: start;

    //             .first_child {
    //                 padding-left: 3.7rem;

    //             }

    //             p.first_child {
    //                 padding-top: 1.1rem;
    //                 padding-bottom: 1.1rem;
    //             }

    //             div.first_child:first-child {
    //                 padding-top: 2.1rem;
    //             }

    //             div.others:first-child {
    //                 padding-top: 2.1rem;
    //             }

    //             td {
    //                 div {
    //                     font-weight: 400;
    //                     font-size: 1.2rem;
    //                     color: #020202;
    //                 }

    //                 &:last-child{
    //                     text-align: end;

    //                     div {
    //                         padding-right: 5.3rem
    //                     }
    //                 }

    //             }

    //             th {
    //                 text-align: start;
    //                 font-weight: 600;
    //                 font-size: 1.2rem;
    //                 color: #020202;

    //                 &:last-child{
    //                     text-align: end;

    //                     p {
    //                         padding-right: 5.3rem
    //                     }
    //                 }
    //             }
    //         }

    //         .table-header {
    //             background: #F7F8F7;
    //             padding-top: 1.1rem;
    //         }
    //     }
    // }

    // .line_break {
    //     width: 100%;
    //     margin-top: 1.6rem;
    //     justify-content: flex-end;
    //     align-items: flex-end;
    //     display: flex;
    //     border-bottom: 0.07rem solid #CCCCCC;
    // }

    // .footer_wrap {
    //     display: flex;
    //     justify-content: center;

    //     .footer {
    //         border-top: 0.781719px solid #CCCCCC;
    //         width: 90%;
    //         padding-top: 1.3rem;
    //         margin-top: 8.1rem;
    //         align-items: center;
    //         margin-left: 3.7rem;
    //         margin-right: 3.7rem;

    //         display: flex;
    //         gap: 1.3rem;
    //         flex-direction: column;
    //         justify-content: flex-start;
    //         align-self: start;
    //         align-items: flex-start;

    //         span {
    //             font-weight: 600;
    //             font-size: 1.2rem;
    //             color: #020202;
    //         };

    //         p {
    //             font-weight: 400;
    //             font-size: 1.2rem;
    //             color: #020202;

    //         }
    //     }

    // }
  }

  .right-box {
    flex: 1;
    padding-right: 3rem;
    padding-left: 3rem;

    @media only screen and (max-width: 55em) {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }

    .payment-detail-record {
      // background-color: red;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding-top: 1.5rem;

      .title {
        font-weight: 600;
        font-size: 1.65rem;
      }

      .label-value-detail-wrap {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .box {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .value {
            text-align: right;
            font-weight: 600;
          }
        }
      }
    }

    .content-selected-box {
      display: flex;
      flex-direction: column;
      padding: 0rem 2rem;
      padding-top: 3rem;
      width: 100%;
      animation: moveUpAnime 0.5s ease-in-out;

      @media only screen and (max-width: 55em) {
        padding-top: unset;
        //  padding: 0rem 1rem;
      }

      .link-clear-box {
        display: flex;
        align-items: center;
        width: 100%;

        .btn-outline-grey {
          margin-left: auto;
          border-radius: 3rem;
          padding: 0.6rem 1.3rem;
        }
      }

      .text-value-box {
        display: flex;
        flex-direction: column;
        margin-top: 2.5rem;
        gap: 3rem;

        .box {
          display: flex;
          align-items: center;
          font-size: 1.2rem;

          .value {
            margin-left: auto;
            text-align: right;
            font-weight: 600;
          }
        }
      }

      .btn-two-wrap {
        display: flex;
        align-items: center;
        margin-top: 3.5rem;
        gap: 2rem;
      }

      .launch-link-url-box {
        display: flex;
        flex-direction: column;
        padding: 1.6rem;
        background-color: #f7f8f7;
        border-radius: 0.9rem;
        border: 0.1rem solid #cccccc;
        margin-top: 2rem;

  
        

        .launch-link {
          text-decoration: none;
          margin-top: 2.5rem;
          display: flex;
          align-items: center;

          .preview-icon{
            margin-left: auto;
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            cursor: pointer;
            display: grid;
            place-items: center;
            transition: all .3s ease-in-out;
            

            &:hover{
              transform: scale(1.05);
            }
  
            .img-box{
              width: 50%;
              height: 50%;
  
  
              .img{
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          .launch-box {
            display: flex;
            padding: 0.9rem 1.7rem;
            border-radius: 2.4rem;
            display: flex;
            align-items: center;
            background-color: #ffffff;
            width: max-content;
            cursor: pointer;
            transition: all 0.3s;

            .img-box {
              width: 2rem;
              height: 2rem;
              margin-right: 0.5rem;
              display: grid;
              place-items: center;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            &:hover {
              transform: translateY(-0.1rem);
            }

            .text {
              font-weight: 600;
            }
          }
        }

        .link-copy-box {
          display: flex;
          align-items: center;

          .link-box {
            flex: 0 0 85%;
            display: flex;
            align-items: center;
            margin-right: auto;

            .link {
              font-size: 1.3rem;
            }

            .img-box {
              width: 2rem;
              height: 2rem;
              margin-right: 0.7rem;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          .copy-box {
            width: 3rem;
            height: 3rem;
            display: grid;
            place-items: center;

            .img-box {
              width: 3rem;
              height: 3rem;
              margin-right: 0.7rem;
              cursor: pointer;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .icon-box {
              width: 2.5rem;
              height: 2.5rem;
              background-color: #020202;
              border-radius: 50%;
              display: grid;
              place-items: center;

              .icon {
                width: 0.9rem;
                height: 0.9rem;
                fill: #ffffff;
              }
            }
          }
        }
      }
    }

    .change_invoice_status {
      margin-top: 2.4rem;
      display: flex;
      flex-direction: column;
      gap: 3.2rem;

      .btn_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2.5rem;

        .two-btn-wrapper {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 2rem;
          width: 100%;

          & > * {
            width: 100%;
          }

        .btn-outline-black-light{
            // border-color: #755AE2 !important;
            // color: #755AE2 !important;
            background-color: transparent !important;
            font-weight: 700;
          }
        }

        .delete {
          font-weight: 700;
          font-size: 1.4rem;
          text-align: center;
          color: #ff0f00;
          cursor: pointer;

          &:hover {
            transform: translateX(2px);
            transition: transform 300ms ease-in-out;
          }
        }
      }
    }
  }
}

@keyframes moveUpAnime {
  0% {
    transform: translateY(2%);
    opacity: 0.5;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.request-money-wrap-box {
  width: 40rem;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 10rem;

  & > *:not(:last-child) {
    margin-bottom: 2rem;
  }

  .reccuring-box {
    max-height: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    width: 100%;
    margin-top: -1rem;
  }

  .reccuring-box-show {
    margin-top: unset;
    max-height: 10rem;
    transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
  }

  .select-type-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;

    .box {
      padding: 1.5rem;
      border-radius: 0.8rem;
      background-color: rgba(247, 248, 247, 0.5);
      position: relative;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        border-radius: 0.8rem;
        border: 0.15rem solid #020202;
        visibility: hidden;
      }

      & > * {
        position: relative;
        z-index: 3;
      }

      .check-box {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        display: grid;
        place-items: center;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background-color: #020202;
        visibility: hidden;

        .icon {
          width: 0.6rem;
          height: 0.6rem;
          fill: #ffffff;
        }
      }

      .img-box {
        margin-bottom: 3rem;
        width: 2.5rem;
        height: 2.5rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .title {
        font-weight: 700;
        font-size: 1.4rem;
      }

      .text {
        font-size: 1.2rem;
        margin-top: 0.5rem;
      }
    }

    .box-active {
      &::before {
        visibility: visible;
      }

      .check-box {
        visibility: visible;
      }
    }
  }
}
