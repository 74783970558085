.reusable-schedule-box {
  display: flex;
  align-items: center;
  width: 100%;
}
@media only screen and (max-width: 42em) {
  .reusable-schedule-box {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.6rem;
  }
}
.reusable-schedule-box .reset {
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 700;
  margin-left: 1rem;
}
.reusable-schedule-box .text-schedule {
  font-size: 1.3rem;
  margin-right: 1rem;
  cursor: pointer;
}
.reusable-schedule-box .date-arrow-wrap {
  display: flex;
  position: relative;
}
.reusable-schedule-box .date-arrow-wrap .icon {
  position: absolute;
  top: 50%;
  right: 0.8rem;
  transform: translateY(-50%);
  z-index: 20;
  width: 1.7rem;
  height: 1.7rem;
  fill: #755ae2;
  cursor: pointer;
}
@media only screen and (max-width: 42em) {
  .reusable-schedule-box .date-arrow-wrap .icon {
    display: none;
  }
}
.reusable-schedule-box .date-arrow-wrap .form-group {
  width: unset;
}
.reusable-schedule-box .date-arrow-wrap .input-date .wrap {
  min-width: unset;
  width: 7rem;
}
@media only screen and (max-width: 42em) {
  .reusable-schedule-box .date-arrow-wrap .input-date .wrap {
    width: 100%;
  }
}
.reusable-schedule-box .date-arrow-wrap .input-date .wrap input {
  min-width: unset;
  width: 7rem;
  background-color: #fafaff;
  border: 0.15rem solid #dbd6fc;
  color: #755ae2;
  font-weight: 600;
  padding: 0.8rem 1rem;
}
@media only screen and (max-width: 42em) {
  .reusable-schedule-box .date-arrow-wrap .input-date .wrap input {
    width: 100%;
  }
}
.reusable-schedule-box .date-arrow-wrap .input-date .wrap input::-moz-placeholder {
  color: #755ae2;
}
.reusable-schedule-box .date-arrow-wrap .input-date .wrap input::placeholder {
  color: #755ae2;
}
.reusable-schedule-box .date-arrow-wrap .input-date .wrap .label-calendar {
  z-index: 10;
  display: none;
}
.reusable-schedule-box .date-arrow-wrap .input-date-long .wrap {
  width: 14rem;
}
@media only screen and (max-width: 42em) {
  .reusable-schedule-box .date-arrow-wrap .input-date-long .wrap {
    width: 90%;
  }
}
.reusable-schedule-box .date-arrow-wrap .input-date-long .wrap input {
  width: 14rem;
}
@media only screen and (max-width: 42em) {
  .reusable-schedule-box .date-arrow-wrap .input-date-long .wrap input {
    width: 90%;
  }
}/*# sourceMappingURL=ReusableScheduleBox.css.map */