.reusable-schedule-box {
  display: flex;
  align-items: center;
  width: 100%;
  // background-color: red;

  @media only screen and (max-width: 42em) {
    flex-direction: column;
    align-items: flex-start;
    gap: .6rem;
   }

  .reset{
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: 700;
    // color: #755ae2;
    margin-left: 1rem;
  }

  .text-schedule {
    font-size: 1.3rem;
    margin-right: 1rem;
    cursor: pointer;
  }

  .date-arrow-wrap {
    display: flex;
    position: relative;

    .icon{
        position: absolute;
        top: 50%;
        right: .8rem;
        transform: translateY(-50%);
        z-index: 20;
        width: 1.7rem;
        height: 1.7rem;
        fill: #755ae2;
        cursor: pointer;

        @media only screen and (max-width: 42em) {
         display: none;
         }
    }

    .form-group {
      width: unset;
    }

    .input-date {
      .wrap {
        min-width: unset;
        width: 7rem;
        // margin-left: auto;

        @media only screen and (max-width: 42em) {
          width: 100%;
         }

        input {
          min-width: unset;
          width: 7rem;
          background-color: #fafaff;
          border: 0.15rem solid #dbd6fc;
          color: #755ae2;
          font-weight: 600;
          padding: .8rem 1rem;

          @media only screen and (max-width: 42em) {
            width: 100%;
           }

          &::placeholder {
            color: #755ae2;
          }
        }

        .label-calendar {
          z-index: 10;
          display: none;
        }
      }
    }

    .input-date-long {
      .wrap {
        width: 14rem;
        // margin-left: auto;

        @media only screen and (max-width: 42em) {
          width: 90%;
         }

        input {
          width: 14rem;

          @media only screen and (max-width: 42em) {
            width: 90%;
           }
        }
      }
    }
  }
}
