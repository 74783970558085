.preview-details-common-box {
  border: 0.1rem solid #cccccc;
  padding: 2rem 2.5rem;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
}
.preview-details-common-box .top-box {
  border-bottom: 0.1rem solid rgba(204, 204, 204, 0.5);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.preview-details-common-box .top-box .icon-box {
  margin-left: auto;
}
.preview-details-common-box .top-box .icon-box .icon {
  transform: scale(1.2);
}
.preview-details-common-box .top-box .text-box {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.preview-details-common-box .top-box .text-box .key {
  font-size: 1.2rem;
}
.preview-details-common-box .top-box .text-box .value {
  font-weight: 600;
  font-size: 2rem;
}
.preview-details-common-box .middle-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-bottom: 0.1rem solid rgba(204, 204, 204, 0.5);
  padding-bottom: 1.5rem;
}
.preview-details-common-box .middle-box .text {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.preview-details-common-box .middle-box .text .img-wrap .img-box {
  width: 3rem;
  height: 3rem;
}
.preview-details-common-box .middle-box .text .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.preview-details-common-box .middle-box .text > *:first-child {
  margin-right: 0.5rem;
}
.preview-details-common-box .middle-box .text > *:last-child {
  font-weight: 600;
}
.preview-details-common-box .middle-box .pro-text-img {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.preview-details-common-box .middle-box .pro-text-img .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.preview-details-common-box .middle-box .pro-text-img .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.preview-details-common-box .bottom-box {
  width: 100%;
  display: flex;
  align-items: center;
}
.preview-details-common-box .bottom-box .text {
  font-size: 1.2rem;
}
.preview-details-common-box .bottom-box .img-box-info {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  margin-left: 1rem;
  display: grid;
  place-items: center;
}
.preview-details-common-box .bottom-box .img-box-info .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.preview-details-common-box .bottom-box .toggle-wrap {
  margin-left: auto;
  transform: scale(0.8);
}

.preview-details-common-box-collapse .top-box {
  margin-bottom: unset;
  border-bottom: unset;
  padding-bottom: unset;
}
.preview-details-common-box-collapse .middle-box {
  border-bottom: unset;
  padding-bottom: unset;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  width: 100%;
}
.preview-details-common-box-collapse .collapse-box {
  border-top: 0.1rem solid rgba(204, 204, 204, 0.5);
  padding-top: 1rem;
  margin-bottom: -0.5rem;
  margin-top: -0.5rem;
}
.preview-details-common-box-collapse .collapse-box .text {
  font-size: 1.2rem;
}
.preview-details-common-box-collapse .collapse-box .text > *:first-child {
  margin-right: 0.5rem;
}
.preview-details-common-box-collapse .collapse-box .text > *:last-child {
  font-weight: 600;
}

.preview-details-common-box-show .top-box {
  border-bottom: 0.1rem solid rgba(204, 204, 204, 0.5);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.preview-details-common-box-show .middle-box {
  border-bottom: 0.1rem solid rgba(204, 204, 204, 0.5);
  padding-bottom: 1.5rem;
  max-height: 30rem;
  transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
}

.step-two-send-raven {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 10rem;
}

.new-preview-detail-box-wrap {
  width: 40rem;
  border-radius: 1.2rem;
  padding: 2rem 2.5rem;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  padding-top: unset;
  margin-top: 2rem;
}
@media only screen and (max-width: 48em) {
  .new-preview-detail-box-wrap {
    width: 100%;
  }
}
.new-preview-detail-box-wrap .middle-content-wrap {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  margin-bottom: 1.5rem;
  flex: 1;
  gap: 2rem;
  width: 100%;
}
.new-preview-detail-box-wrap .middle-content-wrap .box {
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  width: 100%;
  align-items: center;
  gap: 1rem;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .label {
  text-align: left;
  width: -moz-max-content;
  width: max-content;
  text-wrap: nowrap;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value {
  text-align: right;
  font-weight: 500;
  position: relative;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .select-toggle-box {
  width: -moz-max-content;
  width: max-content;
  display: flex;
  justify-content: flex-end;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .view-all-box {
  position: absolute;
  top: 120%;
  right: 0;
  width: 35rem;
  height: 30rem;
  padding: 2rem;
  z-index: 4;
  overflow-y: scroll;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 2rem;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
  transform-origin: top right;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .view-all-box .wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .view-all-box .wrap .child-wrap {
  display: flex;
  align-items: center;
  padding: 1rem 1.3rem;
  border-radius: 0.8rem;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .view-all-box .wrap .child-wrap .text-box {
  display: flex;
  flex-direction: column;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .view-all-box .wrap .child-wrap .text-box .name {
  font-weight: 700;
  font-size: 1.3rem;
  align-self: flex-start;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .view-all-box .wrap .child-wrap .text-box .text {
  font-size: 1.1rem;
  align-self: flex-start;
  font-weight: 400;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .view-all-box .wrap .child-wrap .img-box {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .view-all-box .wrap .child-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .view-all-box .wrap .child-wrap .cancel-box {
  margin-left: auto;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background-color: #ffdfdd;
  margin-left: auto;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.3s;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .view-all-box .wrap .child-wrap .cancel-box:hover {
  transform: scale(1.1);
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .view-all-box .wrap .child-wrap .cancel-box .icon {
  fill: #ff0f00;
  width: 1.1rem;
  height: 1.1rem;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .view-all-box::-webkit-scrollbar:horizontal {
  display: none;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .view-all-box::-webkit-scrollbar {
  width: 0.3rem;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .view-all-box::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .view-all-box::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .view-all-box::-webkit-scrollbar-thumb:hover {
  background: #020202;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .view-all-box-show {
  transform: scale(1);
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .see-all-box {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  cursor: pointer;
  font-weight: 700;
}
.new-preview-detail-box-wrap .middle-content-wrap .box .value .see-all-box .see-all-text {
  font-weight: 700;
  color: #755ae2;
  cursor: pointer;
}
.new-preview-detail-box-wrap .img-top-box-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  margin-bottom: 1.5rem;
}
.new-preview-detail-box-wrap .img-top-box-wrap .amount-date-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -1.5rem;
}
.new-preview-detail-box-wrap .img-top-box-wrap .amount-date-box .amount {
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;
}
.new-preview-detail-box-wrap .img-top-box-wrap .amount-date-box .date {
  font-size: 1.4rem;
  margin-top: 0.4rem;
}
.new-preview-detail-box-wrap .img-top-box-wrap .img-wrap {
  transform: translateY(-50%);
  display: grid;
  place-items: center;
  position: relative;
}
.new-preview-detail-box-wrap .img-top-box-wrap .img-wrap .img-collapse {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}
.new-preview-detail-box-wrap .img-top-box-wrap .img-wrap .img-collapse > * {
  grid-row: 1/span 1;
  grid-column: 1/span 1;
}
.new-preview-detail-box-wrap .img-top-box-wrap .img-wrap .img-collapse > *:first-child {
  transform: translateX(-30%);
  z-index: 1;
}
.new-preview-detail-box-wrap .img-top-box-wrap .img-wrap .img-collapse > *:nth-child(2) {
  transform: translateX(0%);
  z-index: 2;
}
.new-preview-detail-box-wrap .img-top-box-wrap .img-wrap .img-collapse > *:nth-child(3) {
  transform: translateX(30%);
  z-index: 3;
}
.new-preview-detail-box-wrap .img-top-box-wrap .img-wrap .img-collapse > *:nth-child(4) {
  transform: translateX(60%);
  z-index: 4;
}
.new-preview-detail-box-wrap .img-top-box-wrap .img-wrap .arrow-wrap {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  transform: translateX(40%);
}
.new-preview-detail-box-wrap .img-top-box-wrap .img-wrap .arrow-wrap .arrow-box {
  background-color: #1ace37;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.new-preview-detail-box-wrap .img-top-box-wrap .img-wrap .arrow-wrap .arrow-box .icon {
  width: 1rem;
  height: 1rem;
  color: #ffffff;
}
.new-preview-detail-box-wrap .img-top-box-wrap .img-wrap .img-box {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  overflow: hidden;
}
.new-preview-detail-box-wrap .img-top-box-wrap .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.new-preview-detail-box-wrap .img-top-box-wrap-global-account-swap .img-wrap {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  position: relative;
}
.new-preview-detail-box-wrap .img-top-box-wrap-global-account-swap .img-wrap .img-box {
  width: 5rem;
  height: 5rem;
}
.new-preview-detail-box-wrap .img-top-box-wrap-global-account-swap .img-wrap .swap-global-icon-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  z-index: 3;
}
.new-preview-detail-box-wrap .img-top-box-wrap-global-account-swap .img-wrap .swap-global-icon-box .img-box {
  width: 70%;
  height: 70%;
  transform: rotate(90deg);
  display: grid;
  place-items: center;
  background-color: #333333;
}
.new-preview-detail-box-wrap .img-top-box-wrap-global-account-swap .img-wrap .swap-global-icon-box .img-box .img {
  width: 60%;
  height: 60%;
  -o-object-fit: contain;
     object-fit: contain;
}
.new-preview-detail-box-wrap .bottom-content-wrap {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.new-preview-detail-box-wrap .bottom-content-wrap .box-check {
  display: flex;
  align-items: center;
  justify-content: unset;
  gap: 1rem;
  margin-top: 1rem;
  padding-top: 2rem;
}
.new-preview-detail-box-wrap .bottom-content-wrap .box-check > *:first-child {
  transform: scale(0.85);
}
.new-preview-detail-box-wrap .bottom-content-wrap .box-check .des-text {
  font-size: 1.4rem !important;
}
.new-preview-detail-box-wrap .bottom-content-wrap .save-bulk-template-box {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  gap: 2rem;
}
.new-preview-detail-box-wrap .bottom-content-wrap .save-bulk-template-box .template-title-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  width: 100%;
}
.new-preview-detail-box-wrap .bottom-content-wrap .save-bulk-template-box .template-title-box-show {
  max-height: 15rem;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}
.new-preview-detail-box-wrap .bottom-content-wrap .save-bulk-template-box .toggle-select-text-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}
.new-preview-detail-box-wrap .bottom-content-wrap .save-bulk-template-box .toggle-select-text-box > *:last-child {
  transform: scale(0.8);
}
.new-preview-detail-box-wrap .bottom-content-wrap .bottom-box {
  width: 100%;
  display: flex;
  align-items: center;
}
.new-preview-detail-box-wrap .bottom-content-wrap .bottom-box .text {
  font-size: 1.4rem;
}
.new-preview-detail-box-wrap .bottom-content-wrap .bottom-box .img-box-info {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  margin-left: 1rem;
  display: grid;
  place-items: center;
}
.new-preview-detail-box-wrap .bottom-content-wrap .bottom-box .img-box-info .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.new-preview-detail-box-wrap .bottom-content-wrap .bottom-box .toggle-wrap {
  margin-left: auto;
  transform: scale(0.8);
}
.new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box {
  display: flex;
  align-items: center;
  width: 100%;
}
@media only screen and (max-width: 42em) {
  .new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.6rem;
  }
}
.new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box .reset {
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 700;
  margin-left: 1rem;
}
.new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box .text-schedule {
  font-size: 1.4rem;
  margin-right: auto;
  cursor: pointer;
  min-width: 10rem;
}
.new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box .date-arrow-wrap {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
}
.new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box .date-arrow-wrap form {
  width: -moz-max-content;
  width: max-content;
  display: flex;
  align-items: center;
}
.new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box .date-arrow-wrap .icon {
  position: absolute;
  top: 50%;
  right: 0.8rem;
  transform: translateY(-50%) rotate(-90deg);
  z-index: 20;
  width: 1.7rem;
  height: 1.7rem;
  fill: #755ae2;
  cursor: pointer;
  display: none;
}
@media only screen and (max-width: 42em) {
  .new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box .date-arrow-wrap .icon {
    display: none;
  }
}
.new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box .date-arrow-wrap .input-date {
  width: 100%;
}
.new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box .date-arrow-wrap .input-date .wrap {
  min-width: unset;
  width: 100% !important;
}
@media only screen and (max-width: 42em) {
  .new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box .date-arrow-wrap .input-date .wrap {
    width: 100%;
  }
}
.new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box .date-arrow-wrap .input-date .wrap input {
  min-width: unset;
  width: -moz-max-content;
  width: max-content;
  background-color: #fafaff;
  border: 0.15rem solid #dbd6fc;
  color: #755ae2;
  font-weight: 600;
  padding: 0.8rem 1rem;
  border: unset !important;
  background-color: transparent !important;
}
@media only screen and (max-width: 42em) {
  .new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box .date-arrow-wrap .input-date .wrap input {
    width: 100%;
  }
}
.new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box .date-arrow-wrap .input-date .wrap .label-calendar {
  z-index: 10;
  display: none;
}
.new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box .date-arrow-wrap .input-date-long .wrap {
  width: 100%;
}
@media only screen and (max-width: 42em) {
  .new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box .date-arrow-wrap .input-date-long .wrap {
    width: 90%;
  }
}
.new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box .date-arrow-wrap .input-date-long .wrap input {
  width: 100%;
}
@media only screen and (max-width: 42em) {
  .new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box .date-arrow-wrap .input-date-long .wrap input {
    width: 90%;
  }
}
.new-preview-detail-box-wrap .charge-rate-box-wrap {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  padding: 1rem 2rem;
  border-radius: 0.8rem;
  background-color: #fff6ed;
}
.new-preview-detail-box-wrap .charge-rate-box-wrap .text {
  font-size: 1.15rem;
}
.new-preview-detail-box-wrap .charge-rate-box-wrap .text span {
  color: #ea872d;
}
.new-preview-detail-box-wrap .charge-rate-box-wrap .img-wrap .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.new-preview-detail-box-wrap .charge-rate-box-wrap .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.new-preview-detail-box-wrap .bottom-box-confirm {
  width: 100%;
  display: flex;
  align-items: center;
}
.new-preview-detail-box-wrap .bottom-box-confirm .text {
  font-size: 1.2rem;
}
.new-preview-detail-box-wrap .bottom-box-confirm .img-box-info {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  margin-left: 1rem;
  display: grid;
  place-items: center;
}
.new-preview-detail-box-wrap .bottom-box-confirm .img-box-info .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.new-preview-detail-box-wrap .bottom-box-confirm .toggle-wrap {
  margin-left: auto;
  transform: scale(0.8);
}
.new-preview-detail-box-wrap .account-change-drop-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  padding-top: 2rem;
  gap: 1rem;
}
.new-preview-detail-box-wrap .account-change-drop-wrap .change-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 246, 237);
  padding: 1.5rem;
  border-radius: 0.8rem;
}
.new-preview-detail-box-wrap .account-change-drop-wrap .change-wrap .change-text .text {
  color: rgb(234, 135, 45);
  font-weight: 600;
  cursor: pointer;
  font-size: 1.5rem;
}
.new-preview-detail-box-wrap .account-change-drop-wrap .change-wrap .change-name-info .name-icon {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.new-preview-detail-box-wrap .account-change-drop-wrap .change-wrap .change-name-info .name-icon .img-box {
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
  place-items: center;
}
.new-preview-detail-box-wrap .account-change-drop-wrap .change-wrap .change-name-info .name-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.new-preview-detail-box-wrap .account-change-drop-wrap .change-wrap .change-name-info .name-icon .name {
  font-size: 1.65rem;
  font-weight: 600;
}/*# sourceMappingURL=PreviewDetailsCommonBox.css.map */