.global-index-wrap-box {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.global-index-wrap-box .content-create-box-wrap {
  width: 47rem;
  flex: 1;
  align-self: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 10rem;
}
@media only screen and (max-width: 48em) {
  .global-index-wrap-box .content-create-box-wrap {
    width: 95%;
  }
}
.global-index-wrap-box .content-create-box-wrap .mascot-img-box {
  margin-top: 4rem;
  height: 25rem;
  width: 100%;
  background-image: url("../../../assets/global-content-bg.png");
  background-position: 2.5rem center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom: -6rem;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box {
  border-radius: 1.2rem;
  border-width: 0.15rem;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box > *:not(:last-child) {
  padding-bottom: 2.5rem;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box {
  display: flex;
  flex-direction: column;
  border-width: 0.15rem !important;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .descriptio-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .descriptio-box .title-status-box {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .descriptio-box .title-status-box .title {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .descriptio-box .title-status-box .status-box {
  background-color: rgb(255, 246, 237);
  padding: 0.7rem 1.5rem;
  border-radius: 3rem;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .descriptio-box .title-status-box .status-box .status {
  font-size: 1rem;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .descriptio-box .title-status-box .status-box .status span {
  font-weight: 700;
  color: rgb(234, 135, 45) !important;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .descriptio-box .title-status-box .status-box-success {
  background-color: rgb(232, 255, 246);
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .descriptio-box .title-status-box .status-box-success .status span {
  color: rgb(26, 206, 55) !important;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .descriptio-box .img-icon-box {
  display: grid;
  place-items: center;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .descriptio-box .img-icon-box .img-box {
  width: 3.5rem;
  height: 3.5rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  border-width: 0.15rem;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .descriptio-box .img-icon-box .img-box .img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .descriptio-box .right-icon {
  margin-left: auto;
  transition: transform 0.3s ease-in-out;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .descriptio-box .right-icon .img-box {
  width: 2rem;
  height: 2rem;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .descriptio-box .right-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .descriptio-box .right-icon-down {
  transform: rotate(90deg);
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  width: 100%;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box .currency-to-select-box {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  padding-top: 1.5rem;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box .currency-to-select-box .cur-item {
  width: -moz-max-content;
  width: max-content;
  padding: 0.55rem 1.1rem;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  gap: 1.2rem;
  justify-content: space-between;
  border-width: 0.15rem;
  cursor: pointer;
  padding-left: 0.7rem;
  transition: transform 0.3s ease-in-out;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box .currency-to-select-box .cur-item:hover {
  transform: translateY(-0.1rem);
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box .currency-to-select-box .cur-item .img-select .img-box {
  width: 2.2rem;
  height: 2.2rem;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box .currency-to-select-box .cur-item .img-select .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box .currency-to-select-box .cur-item-active {
  background-color: rgb(240, 238, 255);
  border-color: rgb(209, 198, 244);
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box .down-text {
  font-size: 1.3rem;
  font-style: italic;
  margin-top: 2rem;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box .content-hide-box-verification {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box .content-hide-box-verification .box-value {
  padding: 1rem 2rem;
  border-radius: 5rem;
  background-color: rgb(255, 246, 237);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box .content-hide-box-verification .box-value:hover {
  transform: translateY(-0.1rem);
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box .content-hide-box-verification .box-value .value {
  font-weight: 500;
  color: rgb(234, 135, 45);
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box .content-hide-box-verification .box-value-green {
  background-color: rgb(232, 255, 246);
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box .content-hide-box-verification .box-value-green .value {
  color: rgb(26, 206, 55);
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box .term-content-box-wrap {
  display: flex;
  flex-direction: column;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box .term-content-box-wrap .text-term-box .text {
  font-size: 1.4rem;
  text-align: justify;
  line-height: 2.4rem;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box .term-content-box-wrap .agree-checkbox-wrap {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-top: 2rem;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box .term-content-box-wrap .agree-checkbox-wrap .text {
  font-size: 1.35rem;
  line-height: 2.4rem;
  text-align: left;
}
.global-index-wrap-box .content-create-box-wrap .content-wrap-global-box .description-content-box .content-hide-box-show {
  margin-top: 2.5rem;
  max-height: 100rem;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  padding-bottom: 2.5rem;
  border-width: 0.15rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box {
  flex: 1;
  display: flex;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box > * {
  padding: 2.5rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .view-exchange-amount-show-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 9rem;
}
@media only screen and (max-width: 48em) {
  .global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .view-exchange-amount-show-box {
    flex-wrap: wrap;
    gap: 2rem;
  }
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .view-exchange-amount-show-box .total-amount-show-balance {
  display: flex;
  flex-direction: column;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .view-exchange-amount-show-box .total-amount-show-balance .total-amount {
  display: flex;
  align-items: center;
  width: -moz-max-content;
  width: max-content;
  gap: 1rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .view-exchange-amount-show-box .total-amount-show-balance .total-amount .amount {
  font-size: 3.5rem;
  letter-spacing: -0.1rem;
  font-weight: 700;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .view-exchange-amount-show-box .total-amount-show-balance .show-balance-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  width: -moz-max-content;
  width: max-content;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .view-exchange-amount-show-box .total-amount-show-balance .show-balance-box .text {
  font-size: 1.6rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .view-exchange-amount-show-box .total-amount-show-balance .show-balance-box .eye-icon .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .view-exchange-amount-show-box .total-amount-show-balance .show-balance-box .eye-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .view-exchange-amount-show-box .view-exchange-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.4rem;
  border-radius: 3.5rem;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
@media only screen and (max-width: 48em) {
  .global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .view-exchange-amount-show-box .view-exchange-box {
    margin-left: auto;
  }
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .view-exchange-amount-show-box .view-exchange-box:hover {
  transform: translateY(-0.1rem);
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .view-exchange-amount-show-box .view-exchange-box .text {
  font-size: 1.35rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .view-exchange-amount-show-box .view-exchange-box .icon-box .img-box {
  width: 2rem;
  height: 2rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .view-exchange-amount-show-box .view-exchange-box .icon-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .currency-created-available-box {
  display: grid;
  margin-top: 2rem;
  height: calc(100vh - 36rem);
  grid-template-columns: repeat(auto-fit, minmax(27rem, 35rem));
  grid-gap: 2.5rem;
  align-items: flex-start;
  grid-template-rows: max-content;
  overflow-y: scroll;
  padding-bottom: 3rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .currency-created-available-box::-webkit-scrollbar {
  display: none;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .currency-created-available-box .item-global-box {
  padding: 2rem 2rem 1rem 2rem;
  border-radius: 1.2rem;
  border-width: 0.15rem !important;
  display: flex;
  flex-direction: column;
  height: -moz-max-content;
  height: max-content;
  cursor: pointer;
  border-width: 0.2rem !important;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .currency-created-available-box .item-global-box .cur-img-box {
  margin-bottom: 5rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .currency-created-available-box .item-global-box .cur-img-box .img-box {
  width: 3rem;
  height: 3rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .currency-created-available-box .item-global-box .cur-img-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .currency-created-available-box .item-global-box .name {
  font-size: 1.45rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .currency-created-available-box .item-global-box .amount {
  font-size: 2.1rem;
  font-weight: 700;
  line-height: 3rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .left-veri-content-box .currency-created-available-box-many {
  grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: none;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap {
  width: 80%;
  min-height: 45rem;
  margin-top: 4rem;
  border-radius: 1.2rem;
  border-width: 0.15rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: calc(100vh - 30rem);
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box {
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box::-webkit-scrollbar {
  display: none;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box > *:not(:last-child) {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-width: 0.15rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box > *:last-child {
  border: none !important;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .from-box,
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .to-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .from-box .value,
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .to-box .value {
  font-size: 1.6rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .from-box .label-box,
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .to-box .label-box {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .from-box .label-box .label-text,
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .to-box .label-box .label-text {
  font-weight: 600;
  font-size: 1.6rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .from-box .label-box .icon-wrap,
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .to-box .label-box .icon-wrap {
  display: grid;
  place-items: center;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .from-box .label-box .icon-wrap .img-box,
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .to-box .label-box .icon-wrap .img-box {
  width: 2rem;
  height: 2rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .from-box .label-box .icon-wrap .img-box .img,
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .to-box .label-box .icon-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .to-box .value {
  text-align: right;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .bottom-text-box-wrap {
  padding: 1.5rem 1.5rem;
  display: flex;
  align-items: center;
  border-width: 0.15rem;
  margin-top: auto;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .bottom-text-box-wrap .text {
  font-weight: 600;
  font-size: 1.5rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .title-select-drop-box {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-width: 0.15rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .title-select-drop-box .title {
  font-size: 1.6rem;
  font-weight: 600;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .title-select-drop-box .select-drop-box-wrapper {
  position: relative;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .title-select-drop-box .select-drop-box-wrapper .select-drop-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
  border-radius: 5rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .title-select-drop-box .select-drop-box-wrapper .select-drop-box .value {
  font-weight: 500;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .title-select-drop-box .select-drop-box-wrapper .select-drop-box .img-select .img-box {
  width: 1.7rem;
  height: 1.7rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .title-select-drop-box .select-drop-box-wrapper .select-drop-box .img-select .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .title-select-drop-box .select-drop-box-wrapper .select-drop-box .drop-icon .img-box {
  width: 1.7rem;
  height: 1.7rem;
}
.global-index-wrap-box .veri-complete-profile-content-wrap-box .right-veri-content-box .today-rate-box-wrap .title-select-drop-box .select-drop-box-wrapper .select-drop-box .drop-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=GlobalAccountIndex.css.map */