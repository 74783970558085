.payment-link-wrap-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media only screen and (max-width: 64em) {
  .payment-link-wrap-box {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    overflow-x: hidden;
  }
}
.payment-link-wrap-box .mobile-table-box {
  padding: 1.5rem;
}
.payment-link-wrap-box .mobile-table-box > * {
  transform: translateX(-1.3rem);
}
.payment-link-wrap-box .left-box {
  flex: 0 0 55%;
  border-right: 0.1rem solid rgba(204, 204, 204, 0.5);
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem;
  padding-top: 3rem;
}
@media only screen and (max-width: 64em) {
  .payment-link-wrap-box .left-box {
    grid-row: 1/-1;
    grid-column: 1/-1;
    overflow-x: scroll;
    padding-bottom: 3rem;
  }
}
@media only screen and (max-width: 48em) {
  .payment-link-wrap-box .left-box .table-wrap {
    display: none;
  }
}
.payment-link-wrap-box .left-box .table-wrap .text-avatar-box {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.payment-link-wrap-box .left-box .table-wrap .text-avatar-box .avatar-box {
  width: 3rem;
  height: 3rem;
  background-color: #f7f8f7;
  border: 0.1rem solid #cccccc;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.payment-link-wrap-box .left-box .table-wrap .text-avatar-box .avatar-box span {
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: capitalize;
}
.payment-link-wrap-box .left-box .table-wrap tbody {
  max-height: calc(100vh - 37rem);
  overflow-y: scroll;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.payment-link-wrap-box .left-box .table-wrap tbody::-webkit-scrollbar:horizontal {
  display: none;
}
.payment-link-wrap-box .left-box .table-wrap tbody::-webkit-scrollbar {
  width: 0.5rem;
}
.payment-link-wrap-box .left-box .table-wrap tbody::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.payment-link-wrap-box .left-box .table-wrap tbody::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.payment-link-wrap-box .left-box .table-wrap tbody::-webkit-scrollbar-thumb:hover {
  background: #020202;
}
.payment-link-wrap-box .left-box .table-wrap tbody tr {
  cursor: pointer;
}
.payment-link-wrap-box .left-box .table-wrap tbody tr td .table-data {
  font-size: 1.1rem !important;
}
.payment-link-wrap-box .left-box .table-wrap tbody tr td .status-type-wrap {
  background-color: #f7f8f7;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
}
.payment-link-wrap-box .left-box .table-wrap tbody tr td .status-type-wrap span {
  font-weight: 400 !important;
  font-size: 1.2rem;
}
.payment-link-wrap-box .left-box .table-wrap tbody tr td .status-type-wrap-fail {
  background-color: #fff5f5;
  color: #ff0f00;
}
.payment-link-wrap-box .left-box .table-wrap tbody tr td .status-type-wrap-success {
  background-color: #e8fff6;
  color: #1ace37;
}
.payment-link-wrap-box .left-box .table-wrap tbody tr td .status-type-wrap-pending {
  background-color: #fff6ed;
  color: #ea872d;
}
.payment-link-wrap-box .left-box .table-wrap tbody .table-row-payment-link {
  background-color: #f7f8f7;
}
.payment-link-wrap-box .left-box .paginate-wrap {
  display: flex;
  justify-content: center;
  margin-top: auto;
}
@media only screen and (max-width: 48em) {
  .payment-link-wrap-box .table-wrap {
    display: none;
  }
}
.payment-link-wrap-box .table-wrap .text-avatar-box {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.payment-link-wrap-box .table-wrap .text-avatar-box .avatar-box {
  width: 3rem;
  height: 3rem;
  background-color: #f7f8f7;
  border: 0.1rem solid #cccccc;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.payment-link-wrap-box .table-wrap .text-avatar-box .avatar-box span {
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: capitalize;
}
.payment-link-wrap-box .table-wrap tbody {
  max-height: calc(100vh - 37rem);
  overflow-y: scroll;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.payment-link-wrap-box .table-wrap tbody::-webkit-scrollbar:horizontal {
  display: none;
}
.payment-link-wrap-box .table-wrap tbody::-webkit-scrollbar {
  width: 0.5rem;
}
.payment-link-wrap-box .table-wrap tbody::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.payment-link-wrap-box .table-wrap tbody::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.payment-link-wrap-box .table-wrap tbody::-webkit-scrollbar-thumb:hover {
  background: #020202;
}
.payment-link-wrap-box .table-wrap tbody tr {
  cursor: pointer;
}
.payment-link-wrap-box .table-wrap tbody tr td .table-data {
  font-size: 1.1rem !important;
}
.payment-link-wrap-box .table-wrap tbody tr td .status-type-wrap {
  background-color: #f7f8f7;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
}
.payment-link-wrap-box .table-wrap tbody tr td .status-type-wrap span {
  font-weight: 400 !important;
  font-size: 1.2rem;
}
.payment-link-wrap-box .table-wrap tbody tr td .status-type-wrap-fail {
  background-color: #fff5f5;
  color: #ff0f00;
}
.payment-link-wrap-box .table-wrap tbody tr td .status-type-wrap-success {
  background-color: #e8fff6;
  color: #1ace37;
}
.payment-link-wrap-box .table-wrap tbody tr td .status-type-wrap-pending {
  background-color: #fff6ed;
  color: #ea872d;
}
.payment-link-wrap-box .table-wrap tbody tr td .payroll-current-action-wrap {
  margin: 0rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}
.payment-link-wrap-box .table-wrap tbody tr td .payroll-current-action-wrap .drop-down-box-payroll {
  position: relative;
  transform: unset !important;
}
.payment-link-wrap-box .table-wrap tbody tr td .payroll-current-action-wrap .drop-down-box-payroll .drop-down-wrap-payroll {
  position: absolute;
  top: 120%;
  right: 2rem;
  width: -moz-max-content;
  width: max-content;
  background-color: #ffffff;
  transform: scale(0);
  transform-origin: top right;
  transition: transform 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;
  border-radius: 1.2rem;
  gap: 2.5rem;
}
.payment-link-wrap-box .table-wrap tbody tr td .payroll-current-action-wrap .drop-down-box-payroll .drop-down-wrap-payroll .row-child {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.payment-link-wrap-box .table-wrap tbody tr td .payroll-current-action-wrap .drop-down-box-payroll .drop-down-wrap-payroll .row-child .img-wrap .img-box {
  width: 2rem;
  height: 2rem;
}
.payment-link-wrap-box .table-wrap tbody tr td .payroll-current-action-wrap .drop-down-box-payroll .drop-down-wrap-payroll .row-child .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payment-link-wrap-box .table-wrap tbody tr td .payroll-current-action-wrap .drop-down-box-payroll .drop-down-wrap-payroll .row-child .text {
  font-size: 1.35rem;
  font-weight: 500;
}
.payment-link-wrap-box .table-wrap tbody tr td .payroll-current-action-wrap .drop-down-box-payroll .drop-down-wrap-payroll-up {
  top: unset;
  bottom: 120%;
}
.payment-link-wrap-box .table-wrap tbody tr td .payroll-current-action-wrap .drop-down-box-payroll .drop-down-wrap-payroll-show {
  transform: scale(1);
}
.payment-link-wrap-box .table-wrap tbody tr td .payroll-current-action-wrap .tooltip-hover-wrap {
  position: relative;
  z-index: 15;
}
.payment-link-wrap-box .table-wrap tbody tr td .payroll-current-action-wrap .tooltip-hover-wrap > *:last-child {
  transform: scale(0.75);
}
.payment-link-wrap-box .table-wrap tbody tr td .payroll-current-action-wrap .img-box {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: transform 0.3s ease-in-out;
}
.payment-link-wrap-box .table-wrap tbody tr td .payroll-current-action-wrap .img-box:hover {
  transform: scale(1.05);
}
.payment-link-wrap-box .table-wrap tbody tr td .payroll-current-action-wrap .img-box .img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payment-link-wrap-box .table-wrap tbody tr td .payroll-current-action-wrap > *:nth-child(2) .img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payment-link-wrap-box .table-wrap tbody tr td .payroll-current-action-wrap .delete-box {
  background-color: #fff5f5;
}
.payment-link-wrap-box .table-wrap tbody .table-row-payment-link {
  background-color: #f7f8f7;
}
.payment-link-wrap-box .table-wrap tbody .payment-link-table-row {
  position: relative;
  z-index: 3 !important;
}
.payment-link-wrap-box .table-wrap tbody .payment-link-table-row-index {
  position: relative;
  z-index: 5 !important;
}
.payment-link-wrap-box .paginate-wrap {
  display: flex;
  justify-content: center;
  margin-top: auto;
}
.payment-link-wrap-box .right-box {
  flex: 1;
  transition: all 0.4s ease-in-out;
  border: none;
  box-shadow: none;
}
@media only screen and (max-width: 64em) {
  .payment-link-wrap-box .right-box {
    grid-row: 1/-1;
    grid-column: 1/-1;
  }
}
.payment-link-wrap-box .right-box .content-selected-box {
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem;
  padding-top: 3rem;
  width: 100%;
  animation: moveUpAnime 0.5s ease-in-out;
}
.payment-link-wrap-box .right-box .content-selected-box .link-clear-box {
  display: flex;
  align-items: center;
  width: 100%;
}
.payment-link-wrap-box .right-box .content-selected-box .link-clear-box .btn-outline-grey {
  margin-left: auto;
  border-radius: 3rem;
  padding: 0.6rem 1.3rem;
}
.payment-link-wrap-box .right-box .content-selected-box .text-value-box {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  gap: 3rem;
}
.payment-link-wrap-box .right-box .content-selected-box .text-value-box .box {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}
.payment-link-wrap-box .right-box .content-selected-box .text-value-box .box .value {
  margin-left: auto;
  text-align: right;
  font-weight: 600;
}
.payment-link-wrap-box .right-box .content-selected-box .btn-two-wrap {
  display: flex;
  align-items: center;
  margin-top: 3.5rem;
  gap: 2rem;
}
.payment-link-wrap-box .right-box .content-selected-box .launch-link-url-box {
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  background-color: #f7f8f7;
  border-radius: 0.9rem;
  border: 0.1rem solid #cccccc;
  margin-top: 2rem;
}
.payment-link-wrap-box .right-box .content-selected-box .launch-link-url-box .launch-link {
  text-decoration: none;
  margin-top: 1.5rem;
}
.payment-link-wrap-box .right-box .content-selected-box .launch-link-url-box .launch-link .launch-box {
  display: flex;
  padding: 0.9rem 1.7rem;
  border-radius: 2.4rem;
  display: flex;
  align-items: center;
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
  transition: all 0.3s;
}
.payment-link-wrap-box .right-box .content-selected-box .launch-link-url-box .launch-link .launch-box .img-box {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  display: grid;
  place-items: center;
}
.payment-link-wrap-box .right-box .content-selected-box .launch-link-url-box .launch-link .launch-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payment-link-wrap-box .right-box .content-selected-box .launch-link-url-box .launch-link .launch-box:hover {
  transform: translateY(-0.1rem);
}
.payment-link-wrap-box .right-box .content-selected-box .launch-link-url-box .launch-link .launch-box .text {
  font-weight: 600;
}
.payment-link-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box {
  display: flex;
  align-items: center;
}
.payment-link-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box .link-box {
  flex: 0 0 85%;
  display: flex;
  align-items: center;
  margin-right: auto;
}
.payment-link-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box .link-box .link {
  font-size: 1.3rem;
}
.payment-link-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box .link-box .img-box {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.7rem;
}
.payment-link-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box .link-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payment-link-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box .copy-box {
  width: 3rem;
  height: 3rem;
  display: grid;
  place-items: center;
}
.payment-link-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box .copy-box .img-box {
  width: 3rem;
  height: 3rem;
  margin-right: 0.7rem;
  cursor: pointer;
}
.payment-link-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box .copy-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payment-link-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box .copy-box .icon-box {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #020202;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.payment-link-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box .copy-box .icon-box .icon {
  width: 0.9rem;
  height: 0.9rem;
  fill: #ffffff;
}
.payment-link-wrap-box .right-box .no-content-selected {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
.payment-link-wrap-box .right-box .no-content-selected .wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
}
.payment-link-wrap-box .right-box .no-content-selected .wrap .img-box {
  width: 10rem;
  height: 10rem;
  display: grid;
  place-items: center;
  margin-bottom: 1.5rem;
}
.payment-link-wrap-box .right-box .no-content-selected .wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payment-link-wrap-box .right-box .no-content-selected .wrap .title {
  font-weight: 700;
  font-size: 1.6rem;
  text-align: center;
}
.payment-link-wrap-box .right-box .no-content-selected .wrap .text {
  font-size: 1.3rem;
  text-align: center;
}
@media only screen and (max-width: 64em) {
  .payment-link-wrap-box .right-box-mobile {
    transform: translateX(100%);
  }
  .payment-link-wrap-box .right-box-mobile-show {
    transform: translateX(0%);
  }
}

@keyframes moveUpAnime {
  0% {
    transform: translateY(2%);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
.request-money-wrap-box {
  min-height: 85rem;
  width: 40rem;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 42em) {
  .request-money-wrap-box {
    width: 90%;
    margin: 2rem auto;
    min-height: unset;
  }
}
.request-money-wrap-box > *:not(:last-child) {
  margin-bottom: 2rem;
}
.request-money-wrap-box .charge-rate-box-wrap {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  padding: 1rem 2rem;
  border-radius: 0.8rem;
  background-color: #fff6ed;
}
.request-money-wrap-box .charge-rate-box-wrap .text {
  font-size: 1.15rem;
}
.request-money-wrap-box .charge-rate-box-wrap .text span {
  color: #ea872d;
}
.request-money-wrap-box .charge-rate-box-wrap .img-wrap .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.request-money-wrap-box .charge-rate-box-wrap .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.request-money-wrap-box .edit-amount-check-wrap {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-top: -1rem;
}
.request-money-wrap-box .edit-amount-check-wrap .text {
  font-size: 1.3rem;
}
.request-money-wrap-box .reccuring-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  width: 100%;
  margin-top: -1.5rem;
}
.request-money-wrap-box .reccuring-box .expiry-group {
  position: relative;
  z-index: 20;
}
.request-money-wrap-box .reccuring-box .expiry-group .wrap {
  display: flex;
  position: relative;
  z-index: 20;
}
.request-money-wrap-box .reccuring-box-show {
  margin-top: unset;
  max-height: 10rem;
  transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
}
.request-money-wrap-box .select-type-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
}
.request-money-wrap-box .select-type-box .box {
  padding: 1.5rem;
  border-radius: 0.8rem;
  position: relative;
  cursor: pointer;
}
.request-money-wrap-box .select-type-box .box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 0.8rem;
  border: 0.15rem solid #020202;
  visibility: hidden;
}
.request-money-wrap-box .select-type-box .box > * {
  position: relative;
  z-index: 3;
}
.request-money-wrap-box .select-type-box .box .check-box {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: grid;
  place-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #020202;
  visibility: hidden;
}
.request-money-wrap-box .select-type-box .box .check-box .icon {
  width: 0.6rem;
  height: 0.6rem;
  fill: #ffffff;
}
.request-money-wrap-box .select-type-box .box .img-box {
  margin-bottom: 3rem;
  width: 2rem;
  height: 2rem;
}
.request-money-wrap-box .select-type-box .box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.request-money-wrap-box .select-type-box .box .title {
  font-weight: 700;
  font-size: 1.4rem;
}
.request-money-wrap-box .select-type-box .box .text {
  font-size: 1.2rem;
  margin-top: 0.5rem;
}
.request-money-wrap-box .select-type-box .box-active {
  border: none !important;
}
.request-money-wrap-box .select-type-box .box-active::after {
  visibility: visible;
}
.request-money-wrap-box .select-type-box .box-active .check-box {
  visibility: visible;
}

.payment-link-detail-info-wrap {
  width: 100%;
  display: flex;
  flex: 1;
}
.payment-link-detail-info-wrap .left-box {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 3rem;
}
.payment-link-detail-info-wrap .left-box > * {
  padding: 0rem 5rem 0rem 5rem;
}
.payment-link-detail-info-wrap .left-box .title {
  font-size: 1.7rem;
  font-weight: 700;
}
.payment-link-detail-info-wrap .left-box .detail-label-value-box {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.payment-link-detail-info-wrap .left-box .detail-label-value-box .box {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
}
.payment-link-detail-info-wrap .left-box .detail-label-value-box .box .label {
  flex: 0 0 30%;
  font-size: 1.5rem;
}
.payment-link-detail-info-wrap .left-box .detail-label-value-box .box .value {
  font-weight: 500;
  font-size: 1.5rem;
  opacity: 0.65;
  flex: 0 0 50%;
}
.payment-link-detail-info-wrap .left-box .info-copy-wrap {
  display: flex;
  padding-bottom: 2rem;
  gap: 2rem;
  min-height: 10rem;
  align-items: center;
}
.payment-link-detail-info-wrap .left-box .info-copy-wrap .img-wrap {
  flex: 0 0 13%;
  width: 100%;
  height: 9rem;
  background-image: url("../../../assets/new-invoice-img.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.payment-link-detail-info-wrap .left-box .info-copy-wrap .text-link-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
}
.payment-link-detail-info-wrap .left-box .info-copy-wrap .text-link-box .img-icon-link {
  display: flex;
  gap: 1.5rem;
  width: 100%;
}
.payment-link-detail-info-wrap .left-box .info-copy-wrap .text-link-box .img-icon-link .copy-box {
  flex: 0 0 10%;
  display: grid;
  place-items: center;
}
.payment-link-detail-info-wrap .left-box .info-copy-wrap .text-link-box .img-icon-link .copy-box .img-box {
  width: 5rem;
  height: 100%;
  background-color: #ffffff;
  display: grid;
  place-items: center;
  border-radius: 0.8rem;
  cursor: pointer;
}
.payment-link-detail-info-wrap .left-box .info-copy-wrap .text-link-box .img-icon-link .copy-box .img-box:hover .img {
  transform: scale(1.05);
}
.payment-link-detail-info-wrap .left-box .info-copy-wrap .text-link-box .img-icon-link .copy-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 50%;
  height: 50%;
  transition: all 0.3s;
}
.payment-link-detail-info-wrap .left-box .info-copy-wrap .text-link-box .img-icon-link .copy-box .icon-box {
  width: 3rem;
  height: 3rem;
  background-color: #020202 !important;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.payment-link-detail-info-wrap .left-box .info-copy-wrap .text-link-box .img-icon-link .copy-box .icon-box .icon {
  width: 0.9rem;
  height: 0.9rem;
  fill: #ffffff;
}
.payment-link-detail-info-wrap .left-box .info-copy-wrap .text-link-box .img-icon-link .box {
  padding: 1.5rem 1.7rem;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  flex: 1;
}
.payment-link-detail-info-wrap .left-box .info-copy-wrap .text-link-box .img-icon-link .box .text {
  margin-right: auto;
  margin-left: 1.5rem;
  font-size: 1.3rem;
  font-weight: 400;
}
.payment-link-detail-info-wrap .left-box .info-copy-wrap .text-link-box .img-icon-link .box .img-box {
  width: 1.65rem;
  height: 1.65rem;
}
.payment-link-detail-info-wrap .left-box .info-copy-wrap .text-link-box .img-icon-link .box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.payment-link-detail-info-wrap .left-box .info-copy-wrap .text-link-box .text {
  font-size: 1.25rem;
}
.payment-link-detail-info-wrap .left-box .info-copy-wrap .text-link-box .text span {
  color: #755ae2 !important;
  cursor: pointer;
  font-weight: 600;
}
.payment-link-detail-info-wrap .right-box {
  flex: 1;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .bottom-detail-box {
  position: relative;
  z-index: 1;
  flex: 1;
  padding-bottom: 3rem;
  overflow-y: scroll;
  gap: 2rem;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .bottom-detail-box::-webkit-scrollbar {
  display: none;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .bottom-detail-box .trans-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem 4rem 1.5rem 2rem;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .bottom-detail-box .trans-row .avatar-box {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.1rem;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .bottom-detail-box .trans-row .avatar-box span {
  font-weight: 600;
  display: inline-block;
  font-size: 1.65rem;
  text-transform: uppercase;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .bottom-detail-box .trans-row .name-email-box {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: 1.2rem;
  gap: 0.2rem;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .bottom-detail-box .trans-row .name-email-box .name {
  font-size: 1.6rem;
  font-weight: 600;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .bottom-detail-box .trans-row .name-email-box .email {
  font-size: 1.3rem;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .bottom-detail-box .trans-row .date-amount-box {
  text-align: right;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .bottom-detail-box .trans-row .date-amount-box .date {
  font-size: 1.2rem;
  font-weight: 500;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .bottom-detail-box .trans-row .date-amount-box .amount {
  font-size: 1.55rem;
  color: #1ace37;
  font-weight: 500;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .top-box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 4rem;
  padding-right: 4rem;
  position: relative;
  z-index: 3;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .top-box .filter-drop-box .drop-box-wrap {
  position: absolute;
  border-radius: 1.2rem;
  top: 110%;
  right: 4rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  min-width: 30rem;
  gap: 2rem;
  animation: moveDownSettingDrop 0.2s ease-in-out backwards;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .top-box .filter-drop-box .filter-box {
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 0.8rem;
  cursor: pointer;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .top-box .filter-drop-box .filter-box .line-box {
  margin: auto 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 1.8rem;
  align-items: center;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .top-box .filter-drop-box .filter-box .line-box span {
  display: inline-block;
  height: 0.15rem;
  width: 100%;
  border-radius: 1rem;
  background-color: #676767;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .top-box .filter-drop-box .filter-box .line-box > *:nth-child(2) {
  width: 70%;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .top-box .filter-drop-box .filter-box .line-box > *:last-child {
  width: 40%;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .top-box .filter-drop-box .filter-box .text {
  font-weight: 700;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .top-box .title-text-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .top-box .title-text-box .label-text {
  font-size: 1.25rem;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .top-box .title-text-box .title-box {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .top-box .title-text-box .title-box .title {
  font-size: 1.85rem;
  font-weight: 700;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .top-box .title-text-box .title-box .num {
  border-radius: 1.2rem;
  padding: 0.8rem 1rem;
  display: grid;
  place-items: center;
}
.payment-link-detail-info-wrap .right-box .transaction-info-wrap .top-box .title-text-box .title-box .num span {
  font-weight: 700;
  font-size: 1.1rem;
  display: inline-block;
  color: #ffffff;
}

.payment-link-test-modal-wrap {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
}
.payment-link-test-modal-wrap::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}
.payment-link-test-modal-wrap .content-box {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.share-payment-link-wrap {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-bottom: 2rem;
}
.share-payment-link-wrap .select-type-wrap {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.8s;
  -moz-transition: max-height 0.8s;
  width: 100%;
}
.share-payment-link-wrap .select-type-wrap-show {
  margin-top: 2.5rem;
  overflow-x: visible;
  overflow-y: visible;
  max-height: 15rem;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
}
.share-payment-link-wrap .share-box-wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: -0.5rem;
  margin-bottom: 2rem;
}
.share-payment-link-wrap .share-box-wrap .text {
  font-size: 1.2rem;
}
.share-payment-link-wrap .share-box-wrap .box {
  padding: 1.5rem 1.7rem;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  flex: 1;
}
.share-payment-link-wrap .share-box-wrap .box .text {
  margin-right: auto;
  margin-left: 1.5rem;
  font-size: 1.3rem;
  font-weight: 400;
}
.share-payment-link-wrap .share-box-wrap .box .img-box {
  width: 1.65rem;
  height: 1.65rem;
}
.share-payment-link-wrap .share-box-wrap .box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.share-payment-link-wrap .title-text-box .title {
  font-size: 1.7rem;
  font-weight: 700;
}
.share-payment-link-wrap .title-text-box .text {
  font-size: 1.25rem;
  width: 85%;
}
.share-payment-link-wrap .share-box-wrap {
  display: flex;
  flex-direction: column;
}

.new-payment-link-table-filter-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 2rem;
  gap: 2rem;
}
@media only screen and (max-width: 48em) {
  .new-payment-link-table-filter-wrap {
    width: 95%;
    justify-content: center;
    align-items: center;
    margin: 0rem auto;
  }
}
.new-payment-link-table-filter-wrap .export-table-content-box {
  display: grid;
  place-items: center;
  padding: 0.5rem 1.5rem;
  border-radius: 0.8rem;
  cursor: pointer;
}
.new-payment-link-table-filter-wrap .export-table-content-box:hover .img-box {
  transform: translateY(0.4rem);
}
.new-payment-link-table-filter-wrap .export-table-content-box .img-box {
  width: 2.2rem;
  height: 2.2rem;
  transition: all 0.3s;
}
.new-payment-link-table-filter-wrap .export-table-content-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.new-payment-link-table-filter-wrap .badge-filter-option-box {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.new-payment-link-table-filter-wrap .badge-filter-option-box .option-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
  border-radius: 2rem;
}
.new-payment-link-table-filter-wrap .badge-filter-option-box .option-box .icon {
  cursor: pointer;
  transform: translateY(0.1rem);
}

.payment-link-new-search-filter-chart-box {
  display: flex;
  align-items: center;
  padding: 0rem 3rem;
  position: relative;
  z-index: 20;
  gap: 1.5rem;
  width: 100%;
}
@media only screen and (max-width: 48em) {
  .payment-link-new-search-filter-chart-box {
    padding: 0rem 2rem;
  }
}
.payment-link-new-search-filter-chart-box .search-filter-box {
  display: flex;
  align-items: stretch;
  position: relative;
  z-index: 100;
  width: 100%;
}
@media only screen and (max-width: 48em) {
  .payment-link-new-search-filter-chart-box .search-filter-box {
    flex-wrap: wrap;
    gap: 1.5rem;
  }
}
.payment-link-new-search-filter-chart-box .search-filter-box .filter-drop-box {
  position: relative;
}
.payment-link-new-search-filter-chart-box .search-filter-box .filter-drop-box .drop-box-wrap {
  position: absolute;
  border-radius: 1.2rem;
  top: 110%;
  right: 0;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  min-width: 30rem;
  gap: 2rem;
  animation: moveDownSettingDrop 0.2s ease-in-out backwards;
}
.payment-link-new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box {
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 0.8rem;
  cursor: pointer;
}
.payment-link-new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .line-box {
  margin: auto 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 1.8rem;
  align-items: center;
}
.payment-link-new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .line-box span {
  display: inline-block;
  height: 0.15rem;
  width: 100%;
  border-radius: 1rem;
  background-color: #676767;
}
.payment-link-new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .line-box > *:nth-child(2) {
  width: 70%;
}
.payment-link-new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .line-box > *:last-child {
  width: 40%;
}
.payment-link-new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .text {
  font-weight: 700;
}/*# sourceMappingURL=PaymentLink.css.map */