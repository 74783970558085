.global-account-terms-and-condition-modal-box-wrap {
  // width: 75rem;

  .raven-modal-content-wrap {
    width: 70rem;

    @media only screen and (max-width: 48em) {
      width: 100%;
    }

    .global-account-terms-and-condition-modal-box {
      height: calc(100vh - 20rem);
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      padding: 0rem 2rem;

      @media only screen and (max-width: 48em) {
        padding: 0rem;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .frame-box {
        flex: 1;
        overflow-y: scroll;
        // background-color: red;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .btn-aggree-box {
        display: flex;
        align-items: center;
        gap: 2rem;

        .btn {
          width: max-content;
        }

        .agree-checkbox-wrap {
          display: flex;
          align-items: center;
          gap: 1.5rem;
          //   margin-top: 2rem;
          padding: 1.5rem 0rem;
          flex: 1;

          .text {
            font-size: 1.35rem;
            line-height: 2.4rem;
            text-align: left;
          }
        }
      }
    }
  }
}
