// .modal-parent-wrap .raven-modal-content-wrap .content {
//   padding: 1rem 0rem;

//   .notification-wrap-modal-index {
//     .title-all-box {
//       padding: 0rem 2rem;
//     }

//     .select-view-box{
//       padding: 0rem 2rem;
//       padding-bottom: 1.2rem;
//     }

//     & > * {
//       .notify-item {
//         padding: 2rem;
//       }
//     }
//   }
// }

.notification-wrap-modal-index {
  min-height: 60rem;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  // background-color: red;

  .title-all-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    .title {
      font-size: 2.3rem;
      font-weight: 600;
    }

    .all {
      font-size: 1.35rem;
      cursor: pointer;
      border-bottom: 0.1rem solid currentColor;
    }
  }

  .select-view-box {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-bottom: 1.2rem;
    border-width: 0.15rem;

    .select-view-item {
      display: flex;
      align-items: center;
      gap: .2rem;
      position: relative;
      cursor: pointer;

      &::after {
        content: "";
        top: 100%;
        height: 0.3rem;
        width: 100%;
        position: absolute;
        transform: translateY(1.1rem);
        // background-color: ;
        visibility: hidden;
      }

      .text {
        font-size: 1.5rem;  
      }

      .num {
        padding: 0.2rem 0.8rem;
        border-radius: 0.4rem;
        display: grid;
        place-items: center;
        opacity: 0.8;
        // display: none;

        span {
          color: #ffffff;
          display: inline-block;
          font-size: 1rem;
          font-weight: 600;
        }
        // background-color: #020202;
      }
    }

    .select-view-item-active {
      &::after {
        visibility: visible;
      }
    }
  }

  .notification-content-box {
    display: flex;
    flex-direction: column;
    // margin-top: 1rem;
    max-height: 40rem;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 1rem !important;

    &::-webkit-scrollbar {
      display: none;
    }

    &:hover .notify-item:not(:hover){
      position: relative;
      z-index: 5;
    }

    &:hover .notify-item:hover{
      position: relative;
      z-index: 10;
    }

    .notify-item {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 2rem 0rem;
      border-width: 0.15rem;
      cursor: pointer;

      .img-wrap {
        .img-box {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          display: grid;
          place-items: center;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .description-box {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;

        .notify-text {
          font-size: 1.45rem;
          font-weight: 400;

          span{
          font-weight: 600;
          }
        }
      }
    }
  }

  .view-all-box-wrap {
    margin-top: auto;
    align-self: center;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 1.5rem;
    margin-top: 1rem !important;

    .pagination-wrap__black-light .child{
      transform: scale(0.75) !important;
    }

    // .text {
    //   margin-top: 1.5rem;
    //   font-size: 1.6rem;
    // }
  }
}
