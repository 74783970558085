.add-money-select-wrap-box {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 3rem;
}
@media only screen and (max-width: 42em) {
  .add-money-select-wrap-box {
    gap: 1.7rem;
    margin-top: 2rem;
  }
}
.add-money-select-wrap-box .wrap-add-money {
  border-radius: 1.6rem;
  padding: 2.4rem 1.6rem;
  cursor: pointer;
  min-width: 50rem;
}
@media only screen and (max-width: 42em) {
  .add-money-select-wrap-box .wrap-add-money {
    padding: 2rem 1.2rem;
    min-width: unset;
    width: 100%;
    margin: 0rem auto;
  }
}
.add-money-select-wrap-box .wrap-add-money .icon {
  margin-left: auto;
  width: 2rem;
  height: 2rem;
}
.add-money-select-wrap-box .wrap-add-money .top-box {
  display: flex;
  align-items: center;
}
.add-money-select-wrap-box .wrap-add-money .top-box .title {
  font-size: 1.45rem;
  font-weight: 700;
}
@media only screen and (max-width: 42em) {
  .add-money-select-wrap-box .wrap-add-money .top-box .title {
    font-size: 1.35rem;
  }
}
.add-money-select-wrap-box .wrap-add-money .top-box .text {
  margin-top: 0.3rem;
  color: #676767;
  font-size: 1.25rem;
}
@media only screen and (max-width: 42em) {
  .add-money-select-wrap-box .wrap-add-money .top-box .text {
    font-size: 1.15rem;
    width: 80%;
  }
}
.add-money-select-wrap-box .wrap-add-money .top-box .img-box-icon {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}
.add-money-select-wrap-box .wrap-add-money .top-box .img-box-icon .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.add-money-select-wrap-box .wrap-add-money .top-box .coming-soon {
  align-self: center;
  margin-left: auto;
}
.add-money-select-wrap-box .wrap-add-money .bottom-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  width: 100%;
}
.add-money-select-wrap-box .wrap-add-money .bottom-box > *:not(:last-child) {
  padding-bottom: 2rem;
}
.add-money-select-wrap-box .wrap-add-money .bottom-box .text {
  font-size: 1.25rem;
  color: #676767;
  display: flex;
  align-items: center;
}
.add-money-select-wrap-box .wrap-add-money .bottom-box .text .copy-wrap {
  margin-left: auto;
  height: 3.5rem;
  width: 3.5rem;
  display: grid;
  place-items: center;
}
.add-money-select-wrap-box .wrap-add-money .bottom-box .text .copy-wrap .img-box-copy {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.add-money-select-wrap-box .wrap-add-money .bottom-box .text .copy-wrap .img-box-copy .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.add-money-select-wrap-box .wrap-add-money .bottom-box .text .copy-wrap .img-box-copy .icon {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.add-money-select-wrap-box .wrap-add-money .bottom-box .text .copy-wrap .img-box-copied {
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  background-color: #020202;
}
.add-money-select-wrap-box .wrap-add-money .bottom-box .text span {
  font-weight: 600;
  margin-left: 0.6rem;
}
.add-money-select-wrap-box .wrap-add-money .bottom-box-show {
  max-height: 15rem;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding-top: 1.5rem;
}
.add-money-select-wrap-box .wrap-coming-soon {
  opacity: 0.5;
  cursor: default;
}
@media only screen and (max-width: 42em) {
  .add-money-select-wrap-box .wrap-coming-soon {
    flex-direction: column;
    padding: 1rem;
  }
}
@media only screen and (max-width: 42em) {
  .add-money-select-wrap-box .wrap-coming-soon .top-box {
    flex-direction: column;
    align-items: flex-start;
  }
  .add-money-select-wrap-box .wrap-coming-soon .top-box > *:last-child {
    margin-left: unset;
    align-self: flex-end;
    margin-top: 1rem;
  }
}
.add-money-select-wrap-box .wrap-coming-soon .top-box .text {
  margin-top: 0.3rem;
  color: #676767;
  font-size: 1.25rem;
}
@media only screen and (max-width: 42em) {
  .add-money-select-wrap-box .wrap-coming-soon .top-box .text {
    font-size: 1.15rem;
    width: 100%;
  }
}
.add-money-select-wrap-box .wrap-coming-soon .coming-soon {
  cursor: default;
}
.add-money-select-wrap-box .wrap-coming-soon .coming-soon:hover {
  transform: none;
}

.add-money-select-wrap-box-new {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 4rem;
  width: 53rem;
}
@media only screen and (max-width: 42em) {
  .add-money-select-wrap-box-new {
    gap: 1.7rem;
    margin-top: 2rem;
    width: 100%;
  }
}
.add-money-select-wrap-box-new .title-drop-box {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  position: relative;
  z-index: 10;
  width: -moz-max-content;
  width: max-content;
}
@media only screen and (max-width: 48em) {
  .add-money-select-wrap-box-new .title-drop-box {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
  .add-money-select-wrap-box-new .title-drop-box .value-box {
    margin-left: auto;
  }
}
.add-money-select-wrap-box-new .title-drop-box .title {
  font-size: 1.6rem;
}
.add-money-select-wrap-box-new .title-drop-box .select-toggle-box {
  position: absolute;
  top: 120%;
  right: 0;
}
.add-money-select-wrap-box-new .title-drop-box .value-box {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  background-color: rgb(240, 238, 255);
  padding: 0.5rem 0.8rem;
  border-radius: 0.6rem;
  cursor: pointer;
}
.add-money-select-wrap-box-new .title-drop-box .value-box .value {
  color: rgb(117, 90, 226);
  font-weight: 600;
}
.add-money-select-wrap-box-new .title-drop-box .value-box .img-box {
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;
}
.add-money-select-wrap-box-new .title-drop-box .value-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  transform: translateY(0.2rem);
}
.add-money-select-wrap-box-new .title-drop-box .value-box .img-box-up {
  transform: translateY(0.25rem) rotateX(-180deg);
}
.add-money-select-wrap-box-new .content-box-two {
  width: 100%;
  padding: 2.5rem;
  border-width: 0.15rem;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: stretch;
  border-radius: 1.2rem;
}
@media only screen and (max-width: 48em) {
  .add-money-select-wrap-box-new .content-box-two {
    padding: 1.5rem 1rem;
  }
}
.add-money-select-wrap-box-new .content-box-two .add-money-item {
  align-self: stretch;
  border-width: 0.15rem;
  padding-bottom: 1.5rem;
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .top-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .top-box .title-text-box .title {
  font-size: 1.45rem;
  font-weight: 700;
}
@media only screen and (max-width: 42em) {
  .add-money-select-wrap-box-new .content-box-two .add-money-item .top-box .title-text-box .title {
    font-size: 1.35rem;
  }
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .top-box .title-text-box .text {
  margin-top: 0.3rem;
  color: #676767;
  font-size: 1.25rem;
}
@media only screen and (max-width: 42em) {
  .add-money-select-wrap-box-new .content-box-two .add-money-item .top-box .title-text-box .text {
    font-size: 1.15rem;
    width: 80%;
  }
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .top-box .img-wrap {
  margin-right: 1.5rem;
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .top-box .img-wrap .img-box {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .top-box .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .top-box .arrow-coming-soon-box {
  margin-left: auto;
  position: relative;
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .top-box .arrow-coming-soon-box .img-box-arrow {
  width: 2.5rem;
  height: 2.5rem;
  transform: rotate(-90deg);
  transition: all 0.3s ease-in-out;
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .top-box .arrow-coming-soon-box .img-box-arrow .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .top-box .arrow-coming-soon-box .img-box-arrow-rotate {
  transform: unset;
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .top-box .arrow-coming-soon-box .coming-soon {
  position: absolute;
  top: -3rem;
  right: 0;
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .content-display-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  width: 100%;
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .content-display-box .display-wrap {
  padding: 2rem;
  display: flex;
  align-items: center;
  border-radius: 1.2rem;
}
@media only screen and (max-width: 48em) {
  .add-money-select-wrap-box-new .content-box-two .add-money-item .content-display-box .display-wrap {
    flex-direction: column;
    align-items: flex-start;
  }
  .add-money-select-wrap-box-new .content-box-two .add-money-item .content-display-box .display-wrap .copy-box {
    margin-left: unset !important;
    margin-top: 1.5rem;
  }
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .content-display-box .display-wrap .copy-box {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.7rem 2rem;
  border-radius: 3rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .content-display-box .display-wrap .copy-box:hover {
  transform: scale(1.01);
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .content-display-box .display-wrap .copy-box .img-box {
  width: 1.5rem;
  height: 1.5rem;
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .content-display-box .display-wrap .copy-box .img-box .img {
  width: 100%;
  height: 100%;
  color: rgb(117, 90, 226);
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .content-display-box .display-wrap .copy-box .img-box .icon {
  width: 1.3rem;
  height: 1.3rem;
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .content-display-box .display-wrap .copy-box span {
  color: rgb(117, 90, 226);
  font-weight: 500;
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .content-display-box .display-wrap .info-box {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .content-display-box .display-wrap .info-box .bank-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .content-display-box .display-wrap .info-box .acc_num {
  font-weight: 600;
  font-size: 1.7rem;
}
.add-money-select-wrap-box-new .content-box-two .add-money-item .content-display-box-show {
  max-height: 15rem;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding-top: 1.5rem;
}
.add-money-select-wrap-box-new .wrap-coming-soon {
  opacity: 0.5;
  cursor: default;
}
@media only screen and (max-width: 42em) {
  .add-money-select-wrap-box-new .wrap-coming-soon {
    flex-direction: column;
    padding: 1rem;
  }
}
.add-money-select-wrap-box-new .wrap-coming-soon .add-money-item .top-box {
  cursor: not-allowed;
}
@media only screen and (max-width: 42em) {
  .add-money-select-wrap-box-new .wrap-coming-soon .add-money-item .top-box {
    flex-direction: column;
    align-items: flex-start;
  }
  .add-money-select-wrap-box-new .wrap-coming-soon .add-money-item .top-box > *:last-child {
    margin-left: unset;
    align-self: flex-end;
    margin-top: 1rem;
  }
}
.add-money-select-wrap-box-new .wrap-coming-soon .add-money-item .top-box .text {
  margin-top: 0.3rem;
  color: #676767;
  font-size: 1.25rem;
}
@media only screen and (max-width: 42em) {
  .add-money-select-wrap-box-new .wrap-coming-soon .add-money-item .top-box .text {
    font-size: 1.15rem;
    width: 100%;
  }
}
.add-money-select-wrap-box-new .coming-soon {
  cursor: default;
}
.add-money-select-wrap-box-new .coming-soon:hover {
  transform: none;
}/*# sourceMappingURL=AddMoney.css.map */