.payment-link-invoice-new-layout-wrap {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/version-two-bg-inv-pay.png"),
    linear-gradient(to right, rgba(240, 255, 249, 1), rgba(255, 250, 240, 1));
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;

  &::-webkit-scrollbar {
    display: none;
  }

  .size-wrap-layout {
    width: 80%;

    @media only screen and (max-width: 45em) {
      width: 92%;
    }
  }

  .header-nav {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0.15rem solid rgba(224, 241, 240, 1);
    z-index: 4;
    background-image: linear-gradient(
      to right,
      rgba(240, 255, 249, 1),
      rgba(255, 250, 240, 1)
    );

    .size-wrap-layout {
      display: flex;
      align-items: center;
      padding: 1.3rem 0rem;
      justify-content: space-between;

      .btn-box {
        display: flex;
        align-items: center;
        gap: 2.5rem;

        @media only screen and (max-width: 45em) {
          gap: 1.5rem;
        }

        .dowload-btn {
          background-color: rgba(224, 241, 240, 1);
          color: rgba(11, 131, 118, 1);
          border-color: rgba(127, 199, 194, 1) !important;
          border-width: 0.15rem;
        }

        // .procced-btn,
        // .dowload-btn {
        // //   min-width: 20rem;
        // //   position: relative;

        // //   &>*:first-child{
        // //     position: absolute;
        // //     top: 50%;
        // //     left: 50%;
        // //     transform: translate(-50%, -50%);
        // //   }

        //   @media only screen and (max-width: 45em){
        //     min-width: unset;
        //     width: max-content;
        //     min-height: 5rem !important;
        //   }
        // }
      }

      .logo-box {
        .img-box {
          width: 5rem;
          height: 5rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  .content-layout {
    padding-top: 11rem;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    z-index: 1;
    flex: 1;

    .size-wrap-layout {
      display: flex;
      justify-content: center;
      flex: 1;

      .version-two-public-invoice-wrap {
        width: 45%;
        display: flex;
        flex-direction: column;
        // background-color: red;
        // flex: 1;

        @media only screen and (max-width: 75em) {
          width: 80%;
        }

        @media only screen and (max-width: 45em) {
          width: 92%;
        }

        .currency-select-wrap {
          display: flex;
          align-items: center;
          gap: 2rem;
          width: 100%;
          position: relative;
          z-index: 10;

          .text {
            color: rgba(139, 139, 139, 1);
            font-size: 1.5rem;
            white-space: nowrap;
            font-weight: 500;
          }

          .form-group {
            .react-select-class {
              width: 15rem;

              & > *:nth-child(3) {
                background-color: transparent;
                padding: 0.12rem 0.4rem;
              }
            }
          }
        }

        .amount-detail-box {
          margin: 1.5rem 0rem;

          .text {
            color: rgba(139, 139, 139, 1);
            font-size: 1.5rem;
            white-space: nowrap;
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 1rem;

            @media only screen and (max-width: 45em) {
              flex-wrap: wrap;
            }

            span {
              font-weight: 700;
              color: rgba(1, 67, 69, 1);
              font-size: 2.5rem;
            }
          }
        }

        .invoice-public-content-main-wrap {
          height: calc(100vh - 24rem);
          //   background-color: red;
          overflow-y: scroll;
          width: 100%;
          border-radius: 1.2rem;
          border: 0.15rem solid rgba(233, 237, 244, 1);
          background-color: #ffffff;
          padding: 0rem 5rem 0rem 5rem;
          display: flex;
          flex-direction: column;
          align-items: center;

          @media only screen and (max-width: 45em) {
            padding: 0rem 2rem;
          }

          &::-webkit-scrollbar {
            display: none;
          }

          .preview-box {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            background-color: #ffffff;
            padding: 0rem 0rem 0rem 0rem;
            overflow-y: scroll;
            width: 100%;
            flex: 1;
            align-items: center;
            justify-content: center;

            .submit-btn {
              margin-top: 3rem;
            }

            @media only screen and (max-width: 80em) {
              //   width: 80% !important;
            }

            @media only screen and (max-width: 42em) {
              max-height: unset;
              width: 100% !important;
              position: relative;
              top: unset;
              left: unset;
              transform: unset;
              border-radius: 0.8rem;
              margin-bottom: 3rem;
            }

            &::-webkit-scrollbar {
              display: none;
            }

            .invoice-preview-detail-wrap {
              flex: 1;
              overflow-y: scroll;
              width: 100%;
              border-radius: unset;
              // background-color: red;
              // min-height: 45rem;
              // margin: 2rem auto;
              padding: 0rem 0rem 0rem 0rem;
              display: flex;
              flex-direction: column;
              border: unset;
              color: #020202;
              margin-bottom: unset;

              &::-webkit-scrollbar {
                display: none;
              }

              @media only screen and (max-width: 55em) {
                padding-bottom: 1.5rem;
                margin-bottom: unset;
              }

              .scroll-wrap-box {
                flex: 1;
                overflow-y: scroll;
                padding-bottom: 3rem;

                &::-webkit-scrollbar {
                  display: none;
                }
              }

              .box {
                display: flex;
                padding: 2rem 3rem;
                margin-bottom: 1.5rem;
                //   background-color: #676767;

                @media only screen and (max-width: 55em) {
                  padding: 1.5rem;
                }

                .left-box {
                  display: unset;
                  background-image: none;
                  flex: 0 0 40%;
                  border-right: unset;
                  padding-top: 0rem;

                  @media only screen and (max-width: 55em) {
                    flex: 0 0 25%;
                  }

                  .label {
                    font-weight: 700;
                    font-size: 1.25rem;
                  }

                  .value {
                    font-size: 1.35rem;
                    color: #676767;
                  }
                }

                .right-box {
                  flex: 1;
                  padding: unset;
                  align-items: flex-start;
                  //   justify-content: flex-start;

                  .date-img-box {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    .img-box {
                      width: 4.5rem;
                      height: 4.5rem;
                      border-radius: 50%;
                      overflow: hidden;

                      .img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                      }
                    }

                    .avatar-box {
                      width: 4.5rem;
                      height: 4.5rem;
                      border-radius: 50%;
                      display: grid;
                      place-items: center;
                      background-color: #020202;

                      span {
                        font-weight: 700;
                        text-transform: capitalize;
                        font-size: 1.65rem;
                        display: inline-block;
                        color: #ffffff;
                      }
                    }
                  }

                  .label {
                    font-weight: 700;
                    font-size: 1.35rem;
                    // color: #676767;
                  }

                  .value {
                    font-size: 1.45rem;
                    color: #676767;
                  }
                }
              }

              // .firt-box-date{
              //     display: flex !important;
              //     flex-direction: row !important;
              // }

              .top-info {
                // padding-top: 2rem;
                // padding-bottom: 2rem;
                background-color: #f7f8f7;
                //   display: flex;

                .left-box {
                  display: unset;
                  background-image: none;
                  .value {
                    margin-top: 0.6rem;
                    color: #676767;
                  }
                }

                .right-box {
                  .value {
                    margin-top: 0.6rem;
                    color: #676767;
                  }
                }
              }

              .detail-info {
                .right-box {
                  display: grid;
                  grid-template-columns: 1fr max-content 1fr;

                  & > *:last-child {
                    text-align: right;
                  }

                  & > *:nth-child(2) {
                    text-align: center;
                  }
                }
              }

              .middle-info {
                // padding-top: 2rem;
                // padding-bottom: 2rem;
                //   background-color: red;
                .left-box {
                  .label {
                    margin-bottom: 1.5rem;
                  }
                  .value {
                    margin-top: 0.8rem;
                  }
                }

                .right-box {
                  .label {
                    margin-bottom: 1.5rem;
                  }
                  .value {
                    margin-top: 0.8rem;
                  }
                }
              }

              // .detail-sum-wrap-box {
              //   // background-color: red;
              // }

              .sum-detail-box {
                //   background-color: red;
                padding: 0.5rem 2.5rem;
                margin-bottom: 0.5rem;
                font-size: 1.25rem;

                .left-box {
                  .name {
                    text-transform: capitalize;
                  }
                }

                .right-box {
                  display: grid;
                  grid-template-columns: 1fr max-content 1fr;

                  & > *:last-child {
                    text-align: right;
                  }

                  & > *:nth-child(2) {
                    text-align: center;
                  }
                }

                .underline {
                  width: 100%;
                  margin: 1rem 0rem 0.4rem 0rem;
                }
              }

              .sum-detail-box-underline {
                .right-box {
                  grid-template-columns: 1fr;
                }
              }

              .underline-box {
                padding: 0rem 2.5rem;
                margin-top: 3rem;
                margin-bottom: 1.5rem;
                height: 0.1rem;
                background-color: #cccccc;
              }

              .note-wrap-box {
                padding: 0rem 2.5rem;

                .note {
                  font-weight: 700;
                  font-size: 1.3rem;
                  margin-bottom: 0.8rem;
                }

                .text {
                  font-size: 1.45rem;
                  line-height: 2rem;
                  letter-spacing: 0.02rem;
                }
              }

              .pagination-box {
                padding: 1.2rem 2rem;
                background-color: #f7f8f7;
                margin-top: auto;
                border-top: 0.1rem solid #cccccc;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .show-text {
                  color: #676767;
                }

                .pagination-wrap {
                  display: flex;
                  align-items: center;
                  gap: 1.5rem;

                  .prev,
                  .next {
                    gap: 0.5rem;
                    display: flex;
                    align-items: center;
                    border: 0.175px solid #e5e6e6;
                    background-color: #ffffff;
                    padding: 0.7rem 1rem;
                    border-radius: 0.3rem;
                    cursor: pointer;

                    .text {
                      text-transform: capitalize;
                      font-size: 1.2rem;
                    }

                    .icon {
                      display: inline-block;
                      width: 1rem;
                      height: 1rem;
                    }
                  }
                }
              }
            }

            //   .invoice-preview-detai

            .content-cancel-wrap {
              display: grid;
              place-items: center;

              .wrap {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 4rem 0rem;
                gap: 1rem;

                .logo-name-box {
                  margin-bottom: 1rem;
                  .img-box {
                    width: 4rem;
                    height: 4rem;

                    .img {
                      width: 100%;
                      height: 100%;
                      object-fit: contain;
                    }
                  }
                }

                .title {
                  font-size: 1.8rem;
                  font-weight: 700;
                  color: #020202;
                }

                .text {
                  color: #676767;
                  font-size: 1.35rem;
                  line-height: 1.65rem;
                  width: 85%;
                  text-align: center;

                  span {
                    color: #755ae2;
                    cursor: pointer;
                  }
                }
              }
            }
          }

          .preview-box-cancel-expire {
            width: 100%;
            margin: auto;
            // flex: 1;
            // align-items: center;
            // display: flex;
            // flex-direction: column;
            // background-color: red;

            @media only screen and (max-width: 42em) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.version-two-payment-link-public-wrap-index {
  width: 100%;
  display: grid;
  place-items: center;
  //   background-color: red;

  @media only screen and (max-width: 48em) {
    width: 100%;
    align-items: flex-start;
  }

  .wrap-right-content {
    // flex: 1;
    // align-self: stretch;
    // background-color: red;
    overflow: hidden;
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // & > * {
    //   width: 45rem;

    //   @media only screen and (max-width: 48em) {
    //     width: 100%;
    //     border-radius: 0.8rem;
    //   }
    // }

    

    @media only screen and (max-width: 48em) {
      width: 95%;
      border-radius: 0.8rem;
      justify-content: flex-start;
    }

    & > * {
      padding: 2rem 2.5rem;
      width: 45rem;

      @media only screen and (max-width: 48em) {
        padding: 2rem 1.5rem;
        width: 100%;
        border-radius: 0.8rem;
      }
    }

    .outside-content-title-img-box {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: unset;
      margin-top: -10rem;

      @media only screen and (max-width: 48em) {
        margin-top: unset;
      }

      .logo-name-box {
        align-self: center;
        display: grid;
        place-items: center;
        margin-bottom: 2rem;

        .avatar-box {
          width: 6rem;
          height: 6rem;
          border-radius: 50%;
          display: grid;
          place-items: center;
          background-color: #020202;

          span {
            font-weight: 700;
            text-transform: capitalize;
            font-size: 1.65rem;
            display: inline-block;
            color: #ffffff;
          }
        }

        .name {
          margin-top: 1rem;
          font-size: 2.5rem;
          font-weight: 700;
          text-align: center;
          color: rgba(1, 67, 69, 1);
        }

        .img-box {
          width: 6rem;
          height: 6rem;
          display: grid;
          place-items: center;
          border-radius: 50%;
          overflow: hidden;
          border: 0.3rem solid rgba(238, 238, 238, 1);

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .img-box-default {
          background-color: #ffffff;

          .img {
            width: 50%;
            height: 50%;
            object-fit: contain;
          }
        }
      }
    }

    .content {
      //   flex: 1;
      background-color: #ffffff;
      // background-color: rgba(2, 2, 2, .1);
      border: 0.15rem solid rgba(233, 237, 244, 1);
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding-bottom: 4rem;
      border-radius: 1.2rem;
      box-shadow: 0.1rem 0.1rem 8rem 0rem,
        -0.1rem 0.1rem 8rem 0rem rgba(35, 42, 62, 0.15);
        // display: none;


      .title-detail-box {
        border-bottom: 0.15rem solid rgba(238, 238, 238, 1);
        padding-bottom: 2rem;
        margin-bottom: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 0.4rem;

        .title {
          font-size: 2rem;
          font-weight: 600;
          color: rgba(1, 67, 69, 1);
        }

        .text {
          font-size: 1.3rem;
          color: #676767;
        }
      }

      .form-content-box {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .cur-amount-box-label {
          .label {
            align-self: flex-start;
            margin-bottom: 0.4rem;
            font-size: 1.3rem;
            color: #020202;
            text-transform: capitalize;
            width: 100%;
            display: flex;
            align-items: center;
          }

          .cur-amount-box {
            display: grid;
            grid-template-columns: max-content 1fr;
            border: 0.1rem solid #ccc;
            background-color: #f7f8f7;
            border-radius: 0.8rem;

            .form-group__green-dark .form-input {
              background-color: transparent;
              border: unset !important;
              border-radius: unset !important;
            }

            .cur-box-drop {
              border-radius: 0.8rem;
              border-right: 0.15rem solid #ccc;
              border-top-right-radius: unset;
              border-bottom-right-radius: unset;

              z-index: 10;
              position: relative;
              height: 100%;
              .payment-cur {
                height: 100%;
                border-radius: 0.8rem;
                .selected-obj-wrap {
                  height: 100%;
                  border: unset;
                  border-radius: unset;
                  border-radius: 0.8rem;
                }

                .select-list {
                  top: unset;
                  bottom: 110%;
                }
              }
            }
          }
        }


        .address-currency-box {
            width: 100%;
            max-height: 0;
            overflow-x: hidden;
            overflow-y: hidden;
            transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
            -webkit-transition: max-height 0.3s;
            -moz-transition: max-height 0.3s;
            width: 100%;
            display: flex;
            flex-direction: column;
          }
      
          .address-currency-box-show {
            max-height: 30rem;
            transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
          }

        .two-group {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 2rem;

          @media only screen and (max-width: 48em) {
            grid-template-columns: 1fr;
          }
        }
      }

      .link-title-box {
        background-color: #f7f8f7;
        padding: 2rem 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        border-radius: 0.8rem;

        .link-title {
          font-weight: 700;
          font-size: 1.8rem;
          color: #020202;
        }

        .link-text {
          color: #676767;
          font-size: 1.3rem;
        }
      }

      .logo-name-box {
        align-self: center;
        display: grid;
        place-items: center;
        margin-bottom: 2rem;

        .avatar-box {
          width: 4.5rem;
          height: 4.5rem;
          border-radius: 50%;
          display: grid;
          place-items: center;
          background-color: #020202;

          span {
            font-weight: 700;
            text-transform: capitalize;
            font-size: 1.65rem;
            display: inline-block;
            color: #ffffff;
          }
        }

        .name {
          font-size: 1.55rem;
          font-weight: 700;
          text-align: center;
          color: #020202;
        }

        .img-box {
          width: 4.5rem;
          height: 4.5rem;
          display: grid;
          place-items: center;
          border-radius: 50%;
          overflow: hidden;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .content-cancel-wrap {
      display: grid;
      place-items: center;

      .wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 4rem 0rem;
        gap: 1rem;

        .logo-name-box {
          margin-bottom: 1rem;
          .img-box {
            width: 4rem;
            height: 4rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .title {
          font-size: 1.8rem;
          font-weight: 700;
          color: #020202;
        }

        .text {
          color: #676767;
          font-size: 1.35rem;
          line-height: 1.65rem;
          width: 85%;
          text-align: center;

          span {
            color: #755ae2;
            cursor: pointer;
          }
        }
      }
    }

    .content-success-wrap {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .top-detail-box {
        background-color: #f7f8f7;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 0.8rem;
        align-items: center;
        padding: 2.5rem 1.5rem;

        .img-wrap {
          margin-bottom: -1.5rem;
          .img-box {
            width: 12rem;
            height: 12rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .title {
          font-size: 1.8rem;
          font-weight: 700;
          color: #020202;
        }
      }

      .bottom-detail-box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .box {
          display: flex;
          justify-content: space-between;

          .label {
            font-size: 1.3rem;
            color: #676767;
          }

          .value {
            text-align: right;
            font-size: 1.3rem;
            font-weight: 700;
            color: #020202;
          }
        }
      }
    }

    // .btn-box {
    //   background-color: #f7f8f7;
    // }

    .btn-box-two {
      & > *:first-child {
        width: 100%;
      }
    }
  }
}
