.container_content_wrapper {
  display: flex;
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
  padding-bottom: 7rem;
}

.container_content_wrapper .content_contain {
  width: 40rem;
  display: flex;
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
@media only screen and (max-width: 55em) {
  .container_content_wrapper .content_contain {
    width: 90%;
    margin: 0rem auto;
  }
}
.container_content_wrapper .content_contain .beneficiary-box {
  width: 100%;
}
.container_content_wrapper .content_contain .beneficiary-box .text-line {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  width: 100%;
  margin: 1rem 0rem;
}
.container_content_wrapper .content_contain .beneficiary-box .text-line .text {
  color: #676767;
  background-color: #f7f8f7;
  font-size: 1.1rem;
  border: 0.1rem solid #cccccc;
  padding: 0.5rem 1.1rem;
  border-radius: 10rem;
  font-weight: 600;
  text-transform: uppercase;
}
.container_content_wrapper .content_contain .beneficiary-box .text-line span {
  height: 0.1rem;
  background-color: rgba(204, 204, 204, 0.5);
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box {
  display: flex;
  align-items: center;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .select-bene-wrap {
  position: relative;
  z-index: 15;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .select-bene-wrap .react-select-class > *:nth-child(3) {
  background-color: #fafaff;
  border: 0.15rem solid #dbd6fc;
  color: #755ae2;
  font-weight: 600;
  padding: 0.1rem;
  max-width: 17rem;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .select-bene-wrap .react-select-class > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  font-size: 1.2rem;
  color: #755ae2;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .select-bene-wrap .react-select-class > *:nth-child(3) > *:nth-child(2) > *:nth-child(2) {
  padding: unset;
  padding-right: 0.5rem;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .select-bene-wrap .react-select-class > *:nth-child(3) > *:nth-child(2) > *:nth-child(2) svg {
  fill: #755ae2;
  transform: scale(0.8);
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .select-bene-wrap .react-select-class > *:nth-child(3) > *:nth-child(2) span {
  display: none;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .select-bene-wrap .react-select-class > *:nth-child(4) {
  width: -moz-max-content;
  width: max-content;
  min-width: 20rem;
  left: unset;
  right: 0%;
  font-size: 1.2rem !important;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap {
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .text {
  font-size: 1.3rem;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .cancel-box {
  margin-left: auto;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background-color: #ffdfdd;
  margin-left: auto;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.3s;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .cancel-box:hover {
  transform: scale(1.1);
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .cancel-box .icon {
  fill: #ff0f00;
  width: 1.1rem;
  height: 1.1rem;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .view-all-box {
  position: absolute;
  bottom: 105%;
  right: 0;
  width: 35rem;
  height: 30rem;
  padding: 2rem;
  z-index: 4;
  overflow-y: scroll;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 2rem;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
  transform-origin: bottom right;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .view-all-box .wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap {
  display: flex;
  align-items: center;
  padding: 1rem 1.3rem;
  border: 0.1rem solid #f7f8f7;
  border-radius: 0.8rem;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .text-box .name {
  font-weight: 700;
  font-size: 1.3rem;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .text-box .text {
  font-size: 1.1rem;
  font-weight: 400;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .img-box {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .cancel-box {
  margin-left: auto;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background-color: #ffdfdd;
  margin-left: auto;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.3s;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .cancel-box:hover {
  transform: scale(1.1);
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .cancel-box .icon {
  fill: #ff0f00;
  width: 1.1rem;
  height: 1.1rem;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar:horizontal {
  display: none;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar {
  width: 0.3rem;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar-thumb:hover {
  background: #020202;
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .view-all-box-show {
  transform: scale(1);
}
.container_content_wrapper .content_contain .beneficiary-box .view-total-box .view-wrap .text {
  cursor: pointer;
  font-weight: 600;
  color: var(--primary-purple);
}
.container_content_wrapper .content_contain .beneficiary-box .preview-box {
  max-height: 15rem;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
  overflow-x: scroll;
}
.container_content_wrapper .content_contain .beneficiary-box .preview-box::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 42em) {
  .container_content_wrapper .content_contain .beneficiary-box .preview-box {
    grid-template-columns: repeat(2, 1fr);
  }
}
.container_content_wrapper .content_contain .beneficiary-box .preview-box .box {
  border-radius: 0.8rem;
  border: 0.1rem solid rgba(204, 204, 204, 0.5);
  padding: 1rem 0.8rem;
  cursor: pointer;
  position: relative;
  gap: 0.4rem;
  position: relative;
}
.container_content_wrapper .content_contain .beneficiary-box .preview-box .box .check-box {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: grid;
  place-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #020202;
  visibility: hidden;
}
.container_content_wrapper .content_contain .beneficiary-box .preview-box .box .check-box .icon {
  width: 0.6rem;
  height: 0.6rem;
  fill: #ffffff;
}
.container_content_wrapper .content_contain .beneficiary-box .preview-box .box .avatar-box {
  margin-bottom: -1rem;
  margin-top: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: #f7f8f7;
}
.container_content_wrapper .content_contain .beneficiary-box .preview-box .box .avatar-box span {
  font-weight: 700;
  font-size: 1.3rem;
  text-transform: uppercase;
  color: #020202;
}
.container_content_wrapper .content_contain .beneficiary-box .preview-box .box .name {
  margin-top: 2rem;
  font-size: 1.2rem;
  text-transform: capitalize;
}
.container_content_wrapper .content_contain .beneficiary-box .preview-box .box .text {
  font-size: 0.9rem;
}
.container_content_wrapper .content_contain .beneficiary-box .preview-box .box-card-active {
  border: 0.1rem solid #020202;
}
.container_content_wrapper .content_contain .beneficiary-box .preview-box .box-card-active .check-box {
  visibility: visible;
}
.container_content_wrapper .content_contain .text-line {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  width: 100%;
}
.container_content_wrapper .content_contain .text-line .text {
  color: #676767;
  background-color: #f7f8f7;
  font-size: 1.1rem;
  border: 0.1rem solid #cccccc;
  padding: 0.5rem 1.1rem;
  border-radius: 10rem;
  font-weight: 600;
  width: -moz-max-content;
  width: max-content;
  text-align: center;
  text-transform: uppercase;
}
.container_content_wrapper .content_contain .text-line span {
  height: 0.1rem;
  background-color: rgba(204, 204, 204, 0.5);
}

.container_content_wrapper .content_contain .grouped_input {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 10rem 1fr;
  grid-gap: 2rem;
}
@media only screen and (max-width: 55em) {
  .container_content_wrapper .content_contain .grouped_input {
    grid-gap: 1rem;
  }
}

.container_content_wrapper .content_contain .grouped_input .quantity_contain {
  position: relative;
}

.container_content_wrapper .content_contain .grouped_input .quantity_contain svg {
  display: grid;
  place-items: center;
  height: -moz-max-content;
  height: max-content;
  width: -moz-max-content;
  width: max-content;
  position: absolute;
  top: 65%;
  right: 0;
  z-index: 3;
  transform: translate(-45%, -50%);
}

.container_content_wrapper .content_contain .add_another_item {
  display: flex;
  gap: 0.4rem;
  align-items: center;
  color: #755ae2;
  font-weight: 600;
  font-size: 1.4rem;
}
.container_content_wrapper .content_contain .add_another_item span {
  cursor: pointer;
}

.container_content_wrapper .content_contain .add_another_item figure {
  display: grid;
  place-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.container_content_wrapper .content_contain .items_break {
  display: flex;
  margin-top: 0rem;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.container_content_wrapper .content_contain .items_break p {
  width: 100%;
  height: 0.5px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
  align-items: center;
}

.container_content_wrapper .content_contain .items_break span {
  padding: 0.27rem 3.5rem;
  background: #f7f8f7;
  border: 0.5px solid #cccccc;
  border-radius: 10rem;
  color: #676767;
}

.container_content_wrapper .content_contain .no_items_wrapper {
  width: 100%;
  border-radius: 1.2rem;
  display: grid;
  place-items: center;
  padding: 2.5rem;
}

.container_content_wrapper .content_contain .no_items_wrapper div {
  display: flex;
  align-items: center;
  gap: 0.9rem;
  flex-direction: column;
}

.container_content_wrapper .content_contain .no_items_wrapper div figure {
  display: grid;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  place-items: center;
}

.container_content_wrapper .content_contain .no_items_wrapper div svg {
  width: 90%;
  height: 90%;
}

.container_content_wrapper .content_contain .no_items_wrapper span {
  color: #676767;
  font-size: 1.4rem;
  font-weight: 400;
}

.container_content_wrapper .content_contain .items_wrapper {
  position: relative;
  display: flex;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box {
  position: absolute;
  top: 100%;
  right: 0;
  width: 35rem;
  height: 30rem;
  padding: 2rem;
  z-index: 4;
  overflow-y: scroll;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 2rem;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
  transform-origin: top right;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box .child-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.5rem;
  border-radius: 0.8rem;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box .child-row .two-icon-box {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box .child-row .two-icon-box .img-box {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box .child-row .two-icon-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box .child-row .name {
  font-size: 1.45rem;
  font-weight: 700;
  margin-bottom: 0.2rem;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box .child-row .price {
  font-size: 1.2rem;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box .wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box .wrap .child-wrap {
  display: flex;
  align-items: center;
  padding: 1rem 1.3rem;
  border: 0.1rem solid #f7f8f7;
  border-radius: 0.8rem;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box .wrap .child-wrap .text-box .name {
  font-weight: 700;
  font-size: 1.3rem;
  text-transform: capitalize;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box .wrap .child-wrap .text-box .text {
  font-size: 1.1rem;
  font-weight: 400;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box .wrap .child-wrap .img-box {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box .wrap .child-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box .wrap .child-wrap .cancel-box {
  margin-left: auto;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background-color: #ffdfdd;
  margin-left: auto;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.3s;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box .wrap .child-wrap .cancel-box:hover {
  transform: scale(1.1);
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box .wrap .child-wrap .cancel-box .icon {
  fill: #ff0f00;
  width: 1.1rem;
  height: 1.1rem;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box::-webkit-scrollbar:horizontal {
  display: none;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box::-webkit-scrollbar {
  width: 0.3rem;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box::-webkit-scrollbar-thumb:hover {
  background: #020202;
}
.container_content_wrapper .content_contain .items_wrapper .view-all-box-show {
  transform: scale(1);
}

.container_content_wrapper .content_contain .items_wrapper span:last-child {
  color: #755ae2;
}

.container_content_wrapper .content_contain .items {
  background-color: #f7f8f7;
}

.container_content_wrapper .content_contain .items .content {
  gap: 0.5rem;
}

.container_content_wrapper .content_contain .items p:last-child {
  color: #676767;
  font-size: 1.2rem;
}

.container_content_wrapper .content_contain .items figure {
  display: grid;
  place-items: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
}

.container_content_wrapper .content_contain .items figure:hover {
  transform: translate(0, -2px);
  transition: 300ms ease-in-out;
}

.container_content_wrapper .customer_detail_button {
  margin-top: 18.8rem;
}

.container_content_wrapper .additional_option {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.additional_option-wrap .hide {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s ease-in-out;
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
}

.additional_option-wrap .show {
  max-height: 30rem;
  transition: max-height 0.3s ease-out;
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  margin-bottom: 10rem;
}

.preview_invoice_wrapper {
  width: 100%;
  display: grid;
  margin-top: -3rem;
  place-items: center;
  padding-bottom: 10rem;
}

.preview_invoice_wrapper .preview {
  width: 51.3%;
  border: 1px solid #cccccc;
  border-radius: 24px;
  padding-top: 3.2rem;
  padding-bottom: 5rem;
  height: -moz-max-content;
  height: max-content;
}

.preview_invoice_wrapper .preview .subs {
  color: #676767;
  font-size: 1rem;
}

.preview_invoice_wrapper .preview .subtotal {
  color: #676767;
  padding-top: 2.85rem !important;
  font-size: 1rem;
}

.preview_invoice_wrapper .preview .subvalue {
  color: #000000;
}

.preview_invoice_wrapper .preview .first-sub {
  padding-top: 2.6rem;
  margin-top: 1.6rem;
  border-top: 0.7px solid #cccccc;
}

.preview_invoice_wrapper .preview .last-sub {
  padding-bottom: 2.6rem;
  border-bottom: 0.7px solid #cccccc;
}

.preview_invoice_wrapper .preview .bottom_sub {
  padding-bottom: 2.3rem !important;
}

.preview_invoice_wrapper .preview .invoice_total {
  color: #000000;
}

.preview_invoice_wrapper .preview .invoice_total_value {
  color: #020202;
  font-weight: 600;
}

.preview_invoice_wrapper .preview .invoice_header {
  display: flex;
  gap: 15rem;
  align-items: center;
  padding-left: 3.7rem;
  justify-content: flex-start;
  background: #f7f8f7;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}

.preview_invoice_wrapper .preview .invoice_header .invoice_number,
.preview_invoice_wrapper .preview .invoice_header .invoice_date {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.preview_invoice_wrapper .preview .invoice_header .invoice_number span,
.preview_invoice_wrapper .preview .invoice_header .invoice_date span {
  font-weight: 600;
  font-size: 1.2rem;
  color: #020202;
}

.preview_invoice_wrapper .preview .invoice_header .invoice_number p,
.preview_invoice_wrapper .preview .invoice_header .invoice_date p {
  font-weight: 400;
  color: #676767;
  font-size: 1.2rem;
}

.preview_invoice_wrapper .preview .bill_to_from_wrapper {
  display: flex;
  gap: 8rem;
  align-items: center;
  padding-left: 3.7rem;
  justify-content: flex-start;
  width: 100%;
  padding-top: 3.6rem;
  padding-bottom: 3.2rem;
}

.preview_invoice_wrapper .preview .bill_to_from_wrapper .bill_to,
.preview_invoice_wrapper .preview .bill_to_from_wrapper .bill_from {
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
  align-items: flex-start;
}

.preview_invoice_wrapper .preview .bill_to_from_wrapper .bill_to .bill_header,
.preview_invoice_wrapper .preview .bill_to_from_wrapper .bill_from .bill_header {
  font-weight: 600;
  font-size: 1.2rem;
  color: #020202;
}

.preview_invoice_wrapper .preview .bill_to_from_wrapper .bill_to .header_content,
.preview_invoice_wrapper .preview .bill_to_from_wrapper .bill_from .header_content {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  width: 70%;
}

.preview_invoice_wrapper .preview .bill_to_from_wrapper .bill_to .header_content span,
.preview_invoice_wrapper .preview .bill_to_from_wrapper .bill_from .header_content span {
  font-weight: 400;
  font-size: 1.2rem;
  color: #676767;
}

.preview_invoice_wrapper .item_table {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.preview_invoice_wrapper .item_table table tr {
  text-align: start;
}

.preview_invoice_wrapper .item_table table tr .first_child {
  padding-left: 3.7rem;
}

.preview_invoice_wrapper .item_table table tr p.first_child {
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
}

.preview_invoice_wrapper .item_table table tr div.first_child:first-child {
  padding-top: 2.1rem;
}

.preview_invoice_wrapper .item_table table tr div.others:first-child {
  padding-top: 2.1rem;
}

.preview_invoice_wrapper .item_table table tr td div {
  font-weight: 400;
  font-size: 1.2rem;
  color: #020202;
}

.preview_invoice_wrapper .item_table table tr td:last-child {
  text-align: end;
}

.preview_invoice_wrapper .item_table table tr td:last-child div {
  padding-right: 5.3rem;
}

.preview_invoice_wrapper .item_table table tr th {
  text-align: start;
  font-weight: 600;
  font-size: 1.2rem;
  color: #020202;
}

.preview_invoice_wrapper .item_table table tr th:last-child {
  text-align: end;
}

.preview_invoice_wrapper .item_table table tr th:last-child p {
  padding-right: 5.3rem;
}

.preview_invoice_wrapper .item_table table .table-header {
  background: #f7f8f7;
  padding-top: 1.1rem;
}

.preview_invoice_wrapper .line_break {
  width: 100%;
  margin-top: 1.6rem;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  border-bottom: 0.07rem solid #cccccc;
}

.preview_invoice_wrapper .footer_wrap {
  display: flex;
  justify-content: center;
}

.preview_invoice_wrapper .footer_wrap .footer {
  border-top: 0.781719px solid #cccccc;
  width: 90%;
  padding-top: 1.3rem;
  margin-top: 8.1rem;
  align-items: center;
  margin-left: 3.7rem;
  margin-right: 3.7rem;
  display: flex;
  gap: 1.3rem;
  flex-direction: column;
  justify-content: flex-start;
  align-self: start;
  align-items: flex-start;
}

.preview_invoice_wrapper .footer_wrap .footer span {
  font-weight: 600;
  font-size: 1.2rem;
  color: #020202;
}

.preview_invoice_wrapper .footer_wrap .footer p {
  font-weight: 400;
  font-size: 1.2rem;
  color: #020202;
}

.new-invoice-top-indicator {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.new-invoice-top-indicator .view-text {
  cursor: pointer;
  font-size: 1.3rem;
  color: #755ae2;
  font-weight: 600;
}

.invoice-create-preview-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.invoice-create-preview-box .child-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.5rem;
  border-radius: 0.8rem;
}
.invoice-create-preview-box .child-row .two-icon-box {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.invoice-create-preview-box .child-row .two-icon-box .img-box {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.invoice-create-preview-box .child-row .two-icon-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.invoice-create-preview-box .child-row > *:last-child {
  cursor: pointer;
}
.invoice-create-preview-box .child-row .name {
  font-size: 1.45rem;
  font-weight: 700;
  margin-bottom: 0.2rem;
}
.invoice-create-preview-box .child-row .price {
  font-size: 1.2rem;
}

.invoice-preview-detail-wrap {
  border-radius: 2.4rem;
  min-height: 45rem;
  margin: 2rem auto;
  padding: 3rem 0rem;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 55em) {
  .invoice-preview-detail-wrap {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 1.5rem 0rem;
  }
}
.invoice-preview-detail-wrap .box {
  display: flex;
  padding: 2rem 2.5rem;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 55em) {
  .invoice-preview-detail-wrap .box {
    display: flex;
    gap: 1.2rem;
    padding: 1.5rem;
  }
}
.invoice-preview-detail-wrap .box .left-box {
  flex: 0 0 50%;
}
@media only screen and (max-width: 55em) {
  .invoice-preview-detail-wrap .box .left-box {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    flex-wrap: wrap;
    flex: 0 0 35%;
  }
}
.invoice-preview-detail-wrap .box .left-box .label {
  font-weight: 700;
  font-size: 1.25rem;
}
.invoice-preview-detail-wrap .box .left-box .value {
  font-size: 1.35rem;
}
.invoice-preview-detail-wrap .box .right-box {
  flex: 1;
}
@media only screen and (max-width: 55em) {
  .invoice-preview-detail-wrap .box .right-box {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
}
.invoice-preview-detail-wrap .box .right-box .label {
  font-weight: 700;
  font-size: 1.35rem;
}
.invoice-preview-detail-wrap .box .right-box .value {
  font-size: 1.45rem;
}
.invoice-preview-detail-wrap .top-info .left-box .value {
  margin-top: 0.6rem;
}
@media only screen and (max-width: 55em) {
  .invoice-preview-detail-wrap .top-info .left-box .value {
    margin-top: unset;
  }
}
.invoice-preview-detail-wrap .top-info .right-box .value {
  margin-top: 0.6rem;
}
@media only screen and (max-width: 55em) {
  .invoice-preview-detail-wrap .top-info .right-box .value {
    margin-top: unset;
  }
}
.invoice-preview-detail-wrap .detail-info .right-box {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
}
.invoice-preview-detail-wrap .detail-info .right-box > *:last-child {
  text-align: right;
}
.invoice-preview-detail-wrap .detail-info .right-box > *:nth-child(2) {
  text-align: center;
}
.invoice-preview-detail-wrap .middle-info .left-box .label {
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 55em) {
  .invoice-preview-detail-wrap .middle-info .left-box .label {
    margin-bottom: unset;
  }
}
.invoice-preview-detail-wrap .middle-info .left-box .value {
  margin-top: 0.8rem;
}
@media only screen and (max-width: 55em) {
  .invoice-preview-detail-wrap .middle-info .left-box .value {
    margin-top: unset;
  }
}
.invoice-preview-detail-wrap .middle-info .right-box .label {
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 55em) {
  .invoice-preview-detail-wrap .middle-info .right-box .label {
    margin-bottom: unset;
  }
}
.invoice-preview-detail-wrap .middle-info .right-box .value {
  margin-top: 0.8rem;
}
@media only screen and (max-width: 55em) {
  .invoice-preview-detail-wrap .middle-info .right-box .value {
    margin-top: unset;
  }
}
.invoice-preview-detail-wrap .sum-detail-box {
  padding: 0.5rem 2.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}
.invoice-preview-detail-wrap .sum-detail-box .left-box .name {
  text-transform: capitalize;
}
.invoice-preview-detail-wrap .sum-detail-box .right-box {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
}
.invoice-preview-detail-wrap .sum-detail-box .right-box > *:last-child {
  text-align: right;
}
.invoice-preview-detail-wrap .sum-detail-box .right-box > *:nth-child(2) {
  text-align: center;
}
.invoice-preview-detail-wrap .sum-detail-box .underline {
  width: 100%;
  margin: 1rem 0rem 0.4rem 0rem;
}
.invoice-preview-detail-wrap .sum-detail-box-underline .right-box {
  grid-template-columns: 1fr;
}
.invoice-preview-detail-wrap .underline-box {
  padding: 0rem 2.5rem;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}
.invoice-preview-detail-wrap .note-wrap-box {
  padding: 0rem 2.5rem;
}
.invoice-preview-detail-wrap .note-wrap-box .note {
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
}
.invoice-preview-detail-wrap .note-wrap-box .text {
  font-size: 1.45rem;
  line-height: 2rem;
  letter-spacing: 0.02rem;
}

.invoice-step-four-preview-wrap {
  width: 100%;
  display: flex;
  flex: 1;
  padding: 0rem 5rem;
  overflow: hidden;
}
@media only screen and (max-width: 48em) {
  .invoice-step-four-preview-wrap {
    width: 100%;
    flex-direction: column;
    gap: 3rem;
    padding: 0rem 1rem;
  }
}
.invoice-step-four-preview-wrap .left-wrap {
  flex: 0 0 60%;
  overflow-y: scroll;
  max-height: 65rem;
  padding-bottom: 10rem;
}
.invoice-step-four-preview-wrap .left-wrap::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 48em) {
  .invoice-step-four-preview-wrap .left-wrap {
    width: 100%;
    flex: unset;
  }
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap {
  border-radius: 2.4rem;
  min-height: 45rem;
  margin: 2rem auto;
  padding: 3rem 0rem;
  display: flex;
  flex-direction: column;
  padding-top: unset;
  margin-top: 0rem;
  overflow: hidden;
}
@media only screen and (max-width: 55em) {
  .invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 1.5rem 0rem;
  }
}
@media only screen and (max-width: 48em) {
  .invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap {
    width: 100%;
    flex: unset;
  }
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .box {
  display: flex;
  padding: 2rem 2.5rem;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 55em) {
  .invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .box {
    display: flex;
    gap: 1.2rem;
    padding: 1.5rem;
  }
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .box .left-box {
  flex: 0 0 50%;
}
@media only screen and (max-width: 55em) {
  .invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .box .left-box {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    flex-wrap: wrap;
    flex: 0 0 35%;
  }
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .box .left-box .label {
  font-weight: 700;
  font-size: 1.25rem;
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .box .left-box .value {
  font-size: 1.35rem;
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .box .right-box {
  flex: 1;
}
@media only screen and (max-width: 55em) {
  .invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .box .right-box {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .box .right-box .label {
  font-weight: 700;
  font-size: 1.35rem;
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .box .right-box .value {
  font-size: 1.45rem;
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .top-info .left-box .value {
  margin-top: 0.6rem;
}
@media only screen and (max-width: 55em) {
  .invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .top-info .left-box .value {
    margin-top: unset;
  }
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .top-info .right-box .value {
  margin-top: 0.6rem;
}
@media only screen and (max-width: 55em) {
  .invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .top-info .right-box .value {
    margin-top: unset;
  }
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .detail-info .right-box {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .detail-info .right-box > *:last-child {
  text-align: right;
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .detail-info .right-box > *:nth-child(2) {
  text-align: center;
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .middle-info .left-box .label {
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 55em) {
  .invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .middle-info .left-box .label {
    margin-bottom: unset;
  }
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .middle-info .left-box .value {
  margin-top: 0.8rem;
}
@media only screen and (max-width: 55em) {
  .invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .middle-info .left-box .value {
    margin-top: unset;
  }
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .middle-info .right-box .label {
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 55em) {
  .invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .middle-info .right-box .label {
    margin-bottom: unset;
  }
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .middle-info .right-box .value {
  margin-top: 0.8rem;
}
@media only screen and (max-width: 55em) {
  .invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .middle-info .right-box .value {
    margin-top: unset;
  }
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .sum-detail-box {
  padding: 0.5rem 2.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .sum-detail-box .left-box .name {
  text-transform: capitalize;
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .sum-detail-box .right-box {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .sum-detail-box .right-box > *:last-child {
  text-align: right;
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .sum-detail-box .right-box > *:nth-child(2) {
  text-align: center;
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .sum-detail-box .underline {
  width: 100%;
  margin: 1rem 0rem 0.4rem 0rem;
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .sum-detail-box-underline .right-box {
  grid-template-columns: 1fr;
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .underline-box {
  padding: 0rem 2.5rem;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .note-wrap-box {
  padding: 0rem 2.5rem;
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .note-wrap-box .note {
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
}
.invoice-step-four-preview-wrap .left-wrap .invoice-preview-detail-wrap .note-wrap-box .text {
  font-size: 1.45rem;
  line-height: 2rem;
  letter-spacing: 0.02rem;
}
.invoice-step-four-preview-wrap .preview-account-source-wrap {
  flex: 1;
  height: 100%;
  padding-left: 2.5rem;
  margin-left: 3.5rem;
  border-width: 0.15rem;
  min-height: 70rem;
}
@media only screen and (max-width: 48em) {
  .invoice-step-four-preview-wrap .preview-account-source-wrap {
    width: 100%;
    min-height: unset;
    flex: unset;
    margin-left: unset;
    padding-left: unset;
    border: unset !important;
  }
}
.invoice-step-four-preview-wrap .preview-account-source-wrap .wrap {
  padding: 2rem;
  border-radius: 1.2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  border-width: 0.15rem;
}
.invoice-step-four-preview-wrap .preview-account-source-wrap .wrap > *:first-child {
  border-top: unset;
  width: 100%;
  padding-top: unset;
  margin-top: unset;
}
.invoice-step-four-preview-wrap .preview-account-source-wrap .wrap .save-to-draft-box {
  align-self: center;
  margin-top: -1rem;
}
.invoice-step-four-preview-wrap .preview-account-source-wrap .wrap .save-to-draft-box .text {
  text-align: center;
  color: #020202;
  font-size: 1.55rem;
  font-weight: 600;
  border-bottom: 0.15rem solid currentColor;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.invoice-step-four-preview-wrap .preview-account-source-wrap .wrap .save-to-draft-box .text:hover {
  transform: translateY(-0.1rem);
}
.invoice-step-four-preview-wrap .preview-account-source-wrap .wrap .submit-btn-box {
  width: 100%;
}
.invoice-step-four-preview-wrap .preview-account-source-wrap .wrap .submit-btn-box .submit-btn {
  width: 100%;
}
.invoice-step-four-preview-wrap .preview-account-source-wrap .wrap .account-change-drop-wrap-dark .change-wrap {
  background-color: rgb(255, 246, 237) !important;
}
.invoice-step-four-preview-wrap .preview-account-source-wrap .wrap .account-change-drop-wrap-dark .change-wrap .change-text {
  position: relative;
  z-index: 20;
}
.invoice-step-four-preview-wrap .preview-account-source-wrap .wrap .account-change-drop-wrap-dark .change-wrap .change-text .text {
  color: rgb(234, 135, 45);
}
.invoice-step-four-preview-wrap .preview-account-source-wrap .wrap .account-change-drop-wrap-dark .change-wrap .change-text .select-toggle-box {
  position: absolute;
  bottom: 100%;
  right: 0;
}
.invoice-step-four-preview-wrap .preview-account-source-wrap .wrap .account-change-drop-wrap-dark .change-wrap .change-text .select-toggle-box-down {
  top: 100%;
  bottom: unset;
}/*# sourceMappingURL=new_invoice.css.map */