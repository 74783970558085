.send-money-wrap-box {
  flex: 1;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box {
    width: 100%;
    align-self: stretch;
    flex: unset;
  }
}
.send-money-wrap-box .send-money-wrap {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  width: 50rem;
  gap: 2rem;
  padding-bottom: 5rem;
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box .send-money-wrap {
    width: 100%;
    padding: 2rem 2rem;
    align-self: stretch;
  }
}
.send-money-wrap-box .send-money-wrap .title {
  font-size: 1.6rem;
}
.send-money-wrap-box .send-money-wrap .title-drop-box {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  position: relative;
  z-index: 10;
  width: -moz-max-content;
  width: max-content;
}
@media only screen and (max-width: 48em) {
  .send-money-wrap-box .send-money-wrap .title-drop-box {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
  .send-money-wrap-box .send-money-wrap .title-drop-box .value-box {
    margin-left: auto;
  }
}
.send-money-wrap-box .send-money-wrap .title-drop-box .title {
  font-size: 1.6rem;
}
.send-money-wrap-box .send-money-wrap .title-drop-box .select-toggle-box {
  position: absolute;
  top: 120%;
  right: 0;
}
.send-money-wrap-box .send-money-wrap .title-drop-box .value-box {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  background-color: rgb(240, 238, 255);
  padding: 0.5rem 0.8rem;
  border-radius: 0.6rem;
  cursor: pointer;
}
.send-money-wrap-box .send-money-wrap .title-drop-box .value-box .value {
  color: rgb(117, 90, 226);
  font-weight: 600;
}
.send-money-wrap-box .send-money-wrap .title-drop-box .value-box .img-box {
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;
}
.send-money-wrap-box .send-money-wrap .title-drop-box .value-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  transform: translateY(0.2rem);
}
.send-money-wrap-box .send-money-wrap .title-drop-box .value-box .img-box-up {
  transform: translateY(0.25rem) rotateX(-180deg);
}
.send-money-wrap-box .send-money-wrap .select-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0rem;
  border-radius: 1.6rem;
  border-width: 0.15rem;
}
.send-money-wrap-box .send-money-wrap .select-box > *:not(:last-child) {
  padding-bottom: 2rem !important;
}
.send-money-wrap-box .send-money-wrap .select-box > *:last-child {
  border: unset;
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box .send-money-wrap .select-box {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    width: 100%;
    align-self: stretch;
  }
}
.send-money-wrap-box .send-money-wrap .select-box .box {
  cursor: pointer;
  padding: 1.5rem 0rem;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: unset;
}
.send-money-wrap-box .send-money-wrap .select-box .box:hover .right-arrow .icon {
  transform: translateX(0.4rem);
}
.send-money-wrap-box .send-money-wrap .select-box .box .text-box .name {
  margin-top: unset;
  margin-bottom: 0.3rem;
}
.send-money-wrap-box .send-money-wrap .select-box .box .img-wrap {
  margin-right: 1.3rem;
}
.send-money-wrap-box .send-money-wrap .select-box .box .img-wrap .img-box {
  width: 4.2rem;
  height: 4.2rem;
  display: grid;
  place-items: center;
  transition: all 0.3s;
  border-radius: 50%;
  background-color: #f7f8f7 !important;
}
.send-money-wrap-box .send-money-wrap .select-box .box .img-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 40%;
  height: 40%;
}
.send-money-wrap-box .send-money-wrap .select-box .box .right-arrow {
  margin-left: auto;
}
.send-money-wrap-box .send-money-wrap .select-box .box .right-arrow .icon {
  width: 1.5rem;
  height: 1.5rem;
  transition: all 0.3s;
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box .send-money-wrap .select-box .box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem 1rem;
  }
}
@media only screen and (max-width: 42em) and (max-width: 38em) {
  .send-money-wrap-box .send-money-wrap .select-box .box .img-box {
    margin-top: unset;
    width: 3.5rem;
    height: 3.5rem;
  }
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box .send-money-wrap .select-box .box .text {
    font-size: 1.2rem;
    width: 85%;
  }
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box .send-money-wrap .select-box .box .name {
    margin-top: unset;
    font-size: 1.45rem;
  }
}
.send-money-wrap-box .send-money-wrap .select-box > *:not(:first-child) .img-wrap .img-box .img {
  width: 70%;
  height: 70%;
}

.send-money-wrap-box-raven {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.send-money-wrap-box-raven .form {
  padding-top: 3rem;
  width: 40rem;
  flex-direction: column;
  display: flex;
  gap: 2rem;
  padding-bottom: 7rem;
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box-raven .form {
    width: 90%;
    align-self: center;
  }
}
.send-money-wrap-box-raven .form .recepient-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  width: 100%;
}
.send-money-wrap-box-raven .form .recepient-box .text-line {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  width: 100%;
}
.send-money-wrap-box-raven .form .recepient-box .text-line .text {
  color: #676767;
  background-color: #f7f8f7;
  font-size: 1.1rem;
  border: 0.1rem solid #cccccc;
  padding: 0.5rem 1.1rem;
  border-radius: 10rem;
  font-weight: 600;
  text-transform: uppercase;
}
.send-money-wrap-box-raven .form .recepient-box .text-line span {
  height: 0.1rem;
  background-color: rgba(204, 204, 204, 0.5);
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box {
  display: flex;
  align-items: center;
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap {
  margin-left: auto;
  position: relative;
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .text {
  font-size: 1.3rem;
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .cancel-box {
  margin-left: auto;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background-color: #ffdfdd;
  margin-left: auto;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.3s;
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .cancel-box:hover {
  transform: scale(1.1);
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .cancel-box .icon {
  fill: #ff0f00;
  width: 1.1rem;
  height: 1.1rem;
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .view-all-box {
  position: absolute;
  bottom: 105%;
  right: 0;
  width: 35rem;
  height: 30rem;
  padding: 2rem;
  z-index: 4;
  overflow-y: scroll;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 2rem;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
  transform-origin: bottom right;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap {
  display: flex;
  align-items: center;
  padding: 1rem 1.3rem;
  border-radius: 0.8rem;
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .text-box .name {
  font-weight: 700;
  font-size: 1.3rem;
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .text-box .text {
  font-size: 1.1rem;
  font-weight: 400;
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .img-box {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .cancel-box {
  margin-left: auto;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background-color: #ffdfdd;
  margin-left: auto;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.3s;
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .cancel-box:hover {
  transform: scale(1.1);
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .cancel-box .icon {
  fill: #ff0f00;
  width: 1.1rem;
  height: 1.1rem;
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar:horizontal {
  display: none;
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar {
  width: 0.3rem;
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar-thumb:hover {
  background: #020202;
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .view-all-box-show {
  transform: scale(1);
}
.send-money-wrap-box-raven .form .recepient-box .view-total-box .view-wrap .text {
  cursor: pointer;
  font-weight: 600;
  color: var(--primary-purple);
}
.send-money-wrap-box-raven .form .recepient-box .preview-box {
  margin-top: 1rem;
  max-height: 15rem;
  padding: 1rem 0rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.5rem;
}
.send-money-wrap-box-raven .form .recepient-box .preview-box .box {
  padding: 1rem;
  cursor: pointer;
  position: relative;
}
.send-money-wrap-box-raven .form .recepient-box .preview-box .box .cancel-box {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background-color: #ffdfdd;
  margin-left: auto;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.3s;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.send-money-wrap-box-raven .form .recepient-box .preview-box .box .cancel-box:hover {
  transform: scale(1.1);
}
.send-money-wrap-box-raven .form .recepient-box .preview-box .box .cancel-box .icon {
  fill: #ff0f00;
  width: 1.1rem;
  height: 1.1rem;
}
.send-money-wrap-box-raven .form .recepient-box .preview-box .box .name {
  margin-top: 2rem;
  font-size: 1.45rem;
}
.send-money-wrap-box-raven .form .recepient-box-show {
  overflow-x: unset;
  overflow-y: unset;
  max-height: 30rem;
  transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
}
.send-money-wrap-box-raven .form .label-span-amount {
  font-weight: 600 !important;
  opacity: 1 !important;
}
.send-money-wrap-box-raven .form .verify-name-box {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: -1rem;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  width: 100%;
}
.send-money-wrap-box-raven .form .verify-name-box > *:first-child .label-check {
  border-radius: 50%;
  transform: scale(0.8);
}
.send-money-wrap-box-raven .form .verify-name-box .text {
  font-weight: 600;
  font-size: 1.3rem;
  margin-left: 0.5rem;
}
.send-money-wrap-box-raven .form .verify-name-box-show {
  max-height: 5rem;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}
.send-money-wrap-box-raven .form .add-another-box {
  display: flex;
  align-items: center;
  margin-top: -1rem;
  align-self: flex-start;
  cursor: default;
  opacity: 0.5;
}
.send-money-wrap-box-raven .form .add-another-box:hover .img-box {
  transform: scale(1.2);
}
.send-money-wrap-box-raven .form .add-another-box .text {
  color: var(--primary-purple);
  font-weight: 600;
  font-size: 1.3rem;
}
.send-money-wrap-box-raven .form .add-another-box .img-box {
  transition: all 0.3s ease-in-out;
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
  margin-right: 0.3rem;
}
.send-money-wrap-box-raven .form .add-another-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-wrap-box-raven .form .add-another-box-active {
  opacity: 1;
  cursor: pointer;
}
.send-money-wrap-box-raven .form .label-span {
  font-weight: 700;
  opacity: 0.5;
  cursor: default;
}
.send-money-wrap-box-raven .form .label-span-active {
  opacity: 1;
  cursor: pointer;
}
.send-money-wrap-box-raven .form .bg-box {
  width: 100%;
  height: 10rem;
  background-color: #f7f8f7;
  border-radius: 1.6rem;
  background-image: url("../../../assets/raven-to-raven-bg-img.png");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}

@keyframes moveUpAnimeSendMoney {
  0% {
    transform: translateY(-3%) translateX(-50%);
    opacity: 0.7;
  }
  100% {
    transform: translateY(0%) translateX(-50%);
    opacity: 1;
  }
}
.bulk-drop-wrap {
  position: relative;
}
.bulk-drop-wrap .right-arrow-rotate {
  transform: rotate(90deg);
}
.bulk-drop-wrap .select-content-wrap {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  width: 100%;
}
.bulk-drop-wrap .select-content-wrap .wrap-content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
}
.bulk-drop-wrap .select-content-wrap .wrap-content .title-select {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}
.bulk-drop-wrap .select-content-wrap .wrap-content .select-item-box {
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}
.bulk-drop-wrap .select-content-wrap .wrap-content .select-item-box .select-item {
  cursor: pointer;
  padding: 1rem 1.7rem;
  border-radius: 2rem;
  transition: transform 0.3s ease-in-out;
}
.bulk-drop-wrap .select-content-wrap .wrap-content .select-item-box .select-item:hover {
  transform: scale(1.02);
}
.bulk-drop-wrap .select-content-wrap-show {
  padding: 2rem;
  max-height: 30rem;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}
.bulk-drop-wrap .select-bulk-type-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
}
.bulk-drop-wrap .select-bulk-type-box .second-drop-child-wrap {
  position: relative;
}
.bulk-drop-wrap .select-bulk-type-box .second-drop-child-wrap .child-row-two {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-20%);
  padding: 2rem;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
}
.bulk-drop-wrap .select-bulk-type-box .second-drop-child-wrap .child-row-two > *:first-child {
  padding-bottom: 1.5rem;
}
.bulk-drop-wrap .select-bulk-type-box .second-drop-child-wrap .child-row-two > *:last-child {
  border: unset !important;
}
.bulk-drop-wrap .select-bulk-type-box .second-drop-child-wrap .child-row-two .child-row {
  margin-left: unset;
  transform: unset !important;
}
.bulk-drop-wrap .select-bulk-type-box .second-drop-child-wrap .child-row-two .child-row-parent {
  padding: 1.5rem 0rem !important;
}
.bulk-drop-wrap .select-bulk-type-box .child-row {
  display: flex;
  align-items: center;
  width: -moz-max-content;
  width: max-content;
  align-self: stretch;
  margin-left: 5rem;
  width: 100%;
}
.bulk-drop-wrap .select-bulk-type-box .child-row:hover .img-box {
  transform: scale(1.03);
}
.bulk-drop-wrap .select-bulk-type-box .child-row .title {
  font-weight: 700;
}
.bulk-drop-wrap .select-bulk-type-box .child-row .img-box {
  width: 4rem;
  height: 4rem;
  display: grid;
  place-items: center;
  margin-right: 1rem;
  transition: all 0.3s;
  border-radius: 50%;
  background-color: #f7f8f7;
}
.bulk-drop-wrap .select-bulk-type-box .child-row .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 50%;
  height: 50%;
}
.bulk-drop-wrap .select-bulk-type-box-show {
  max-height: 30rem;
  overflow-x: visible;
  overflow-y: visible;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}
.bulk-drop-wrap .select-bulk-type-box-show > *:not(:last-child) {
  padding-bottom: 1.5rem;
}

.send-money-select-bulk-type-modal-wrap {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-bottom: 2rem;
}
.send-money-select-bulk-type-modal-wrap .des-text {
  font-size: 1.5rem;
  line-height: 2rem;
  background-color: #fff6ed;
  display: flex;
  align-items: flex-start;
  padding: 2rem 1rem;
  border-radius: 1.2rem;
}
.send-money-select-bulk-type-modal-wrap .des-text span {
  font-size: 1.35rem;
}
.send-money-select-bulk-type-modal-wrap .select-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1rem;
  padding-top: 2rem;
  margin-bottom: 2rem;
}
.send-money-select-bulk-type-modal-wrap .select-box .select-item {
  display: flex;
  align-items: center;
  padding: 2rem 1.5rem;
  border-radius: 0.8rem;
  cursor: pointer;
}
.send-money-select-bulk-type-modal-wrap .select-box .select-item:hover .icon {
  transform: translateX(0.3rem);
}
.send-money-select-bulk-type-modal-wrap .select-box .select-item .text-box {
  margin-right: auto;
}
.send-money-select-bulk-type-modal-wrap .select-box .select-item .text-box .text {
  font-weight: 700;
  font-size: 1.55rem;
}
.send-money-select-bulk-type-modal-wrap .select-box .select-item .img-box {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  margin-right: 1.5rem;
  display: grid;
  place-items: center;
}
.send-money-select-bulk-type-modal-wrap .select-box .select-item .img-box .img {
  width: 65%;
  height: 65%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-select-bulk-type-modal-wrap .select-box .select-item .icon {
  margin-left: auto;
  width: 2rem;
  height: 2rem;
  color: #755ae2;
  transition: all 0.3s ease-in-out;
}
.send-money-select-bulk-type-modal-wrap .switch-box {
  display: flex;
  align-items: center;
  padding: 0.7rem 1.5rem;
  border-radius: 3rem;
  gap: 2rem;
  width: -moz-max-content;
  width: max-content;
}
.send-money-select-bulk-type-modal-wrap .switch-box .switch-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 3rem;
  gap: 0.8rem;
  cursor: pointer;
  padding: 1rem 1.5rem;
}
.send-money-select-bulk-type-modal-wrap .switch-box .switch-item-active {
  cursor: default;
  box-shadow: 0px 3.0687px 4.0916px rgba(51, 51, 51, 0.04), 0px 0px 2.0458px rgba(51, 51, 51, 0.02);
  background-color: #755ae2;
}
.send-money-select-bulk-type-modal-wrap .switch-box .switch-item-active .text {
  color: #ffffff;
}

.coming-soon-type-box {
  position: relative;
}
.coming-soon-type-box .box-card {
  opacity: 0.5;
}
.coming-soon-type-box .security-lock-wrap-box {
  position: absolute;
  top: 0%;
  right: 1.5rem;
}
@media only screen and (max-width: 48em) {
  .coming-soon-type-box .security-lock-wrap-box {
    top: 0%;
    transform: unset;
  }
}/*# sourceMappingURL=SendMoneyIndex.css.map */