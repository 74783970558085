.exchange-rate-modal-box-index-wrap {
  .today-rate-box-wrap {
    width: 100%;
    min-height: 45rem;
    // background-color: red;
    // margin-top: 4rem;
    // border-radius: 1.2rem;
    // border-width: 0.15rem;
    border: none;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: calc(100vh - 35rem);

    .middle-content-rate-box {
      flex: 1;
      // background-color: red;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      padding: 2rem 0rem;
      position: relative;
      z-index: 1;

      &::-webkit-scrollbar {
        display: none;
      }

      & > *:not(:last-child) {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        border-width: 0.15rem;
      }

      & > *:last-child {
        border: none !important;
      }

      .from-to-rate-box-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding-left: 2rem;
        // padding-right: 2rem;

        .from-box,
        .to-box {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .value {
            font-size: 1.6rem;
          }

          .label-box {
            display: flex;
            align-items: center;
            gap: 0.7rem;

            .label-text {
              font-weight: 400;
              font-size: 1.6rem;
              display: flex;
              align-items: center;
              gap: 0.5rem;

              .img-select {
                .img-box {
                  width: 1.7rem;
                  height: 1.7rem;

                  .img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }
              }
            }

            .icon-wrap {
              display: grid;
              place-items: center;

              .img-box {
                width: 2rem;
                height: 2rem;

                .img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }
        }

        .to-box {
          .value {
            text-align: right;
          }
        }
      }
    }

    .bottom-text-box-wrap {
      padding: 1.5rem 1.5rem;
      display: flex;
      align-items: center;
      //   justify-content: center;
      border-width: 0.15rem;
      margin-top: auto;
      border: none;
      display: none;

      .text {
        font-weight: 600;
        font-size: 1.5rem;
      }
    }

    .title-select-drop-box {
      padding: 1rem 0rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-width: 0.15rem;
      background-color: transparent;
      position: relative;
      z-index: 2;

      .title {
        font-size: 1.6rem;
        font-weight: 600;
      }

      .select-drop-box-wrapper {
        position: relative;

        .select-drop-box {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 1rem 1.5rem;
          border-radius: 5rem;

          .value {
            font-weight: 500;
          }

          .img-select {
            .img-box {
              width: 1.7rem;
              height: 1.7rem;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          .drop-icon {
            .img-box {
              width: 1.7rem;
              height: 1.7rem;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }
    }
  }
}
