.header-nav-wrap {
  display: flex;
  align-items: center;
  width: 100%;
}
.header-nav-wrap .menu-icon-box {
  position: relative;
  z-index: 3;
  margin-right: 0.8rem;
}
@media only screen and (min-width: 56em) {
  .header-nav-wrap .menu-icon-box {
    display: none;
  }
}
.header-nav-wrap .menu-icon-box .icon {
  width: 2.7rem;
  height: 2.7rem;
}
.header-nav-wrap .mobile-logo-box {
  position: relative;
  z-index: 2;
}
@media only screen and (min-width: 56em) {
  .header-nav-wrap .mobile-logo-box {
    display: none;
  }
}
.header-nav-wrap .mobile-logo-box .img-box {
  width: 4.5rem;
  height: 3rem;
}
.header-nav-wrap .mobile-logo-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 56em) {
  .header-nav-wrap .greeting-box {
    display: none;
  }
}
.header-nav-wrap .greeting-box .text {
  font-weight: 700;
  font-size: 1.7rem;
}
.header-nav-wrap .greeting-box .text span {
  color: #676767;
  font-weight: 400;
}
.header-nav-wrap .profile-and-drop-wrap {
  position: relative;
}
.header-nav-wrap .profile-and-drop-wrap .profile-wrap {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.5rem 0.8rem 0.5rem 0.6rem;
  border-radius: 12rem;
  cursor: pointer;
  position: relative;
}
@media only screen and (max-width: 56em) {
  .header-nav-wrap .profile-and-drop-wrap .profile-wrap {
    border-radius: 50%;
    padding: 0.8rem;
    justify-content: center;
    border-width: 0.2rem;
  }
}
.header-nav-wrap .profile-and-drop-wrap .profile-wrap .icon {
  margin-left: 2rem;
}
@media only screen and (max-width: 56em) {
  .header-nav-wrap .profile-and-drop-wrap .profile-wrap .icon {
    display: none;
  }
}
.header-nav-wrap .profile-and-drop-wrap .profile-wrap .img-box {
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  overflow: hidden;
}
@media only screen and (max-width: 56em) {
  .header-nav-wrap .profile-and-drop-wrap .profile-wrap .img-box {
    margin-right: unset;
  }
}
.header-nav-wrap .profile-and-drop-wrap .profile-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.header-nav-wrap .profile-and-drop-wrap .profile-wrap .name {
  font-size: 1.3rem;
  font-weight: 600;
}
@media only screen and (max-width: 56em) {
  .header-nav-wrap .profile-and-drop-wrap .profile-wrap .name {
    display: none;
  }
}
.header-nav-wrap .profile-and-drop-wrap .drop-box-wrap {
  position: absolute;
  top: 115%;
  right: 0;
  width: 25rem;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem 3rem 2rem;
  opacity: 1 !important;
  animation: moveDownSettingDrop 0.5s ease-in-out backwards;
}
.header-nav-wrap .profile-and-drop-wrap .drop-box-wrap > *:not(:last-child) {
  margin-bottom: 2.5rem;
}
.header-nav-wrap .profile-and-drop-wrap .drop-box-wrap .business-select-switch .react-select-class > *:nth-child(3) {
  background-color: rgb(247, 247, 247);
  border: unset !important;
}
.header-nav-wrap .profile-and-drop-wrap .drop-box-wrap .child-row {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.header-nav-wrap .profile-and-drop-wrap .drop-box-wrap .child-row .img-wrap {
  flex: 0 0 15%;
}
.header-nav-wrap .profile-and-drop-wrap .drop-box-wrap .child-row .img-wrap .img-box {
  width: 2.2rem;
  height: 2.2rem;
}
.header-nav-wrap .profile-and-drop-wrap .drop-box-wrap .child-row .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.header-nav-wrap .profile-and-drop-wrap .drop-box-wrap .child-row .text {
  font-size: 1.45rem;
  font-weight: 500;
}
.header-nav-wrap .theme-box {
  margin-left: auto;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 0.6rem;
  border-radius: 10rem;
}
.header-nav-wrap .theme-box .box {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.8rem;
  border-radius: 1.6rem;
  cursor: pointer;
  transition: all 0.3s;
}
.header-nav-wrap .theme-box .box .text {
  font-size: 1.25rem;
  font-weight: 600;
  margin-left: 0.25rem;
}
@media only screen and (max-width: 42em) {
  .header-nav-wrap .theme-box .box .text {
    display: none;
  }
}
.header-nav-wrap .theme-box .box .img-box {
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
}
.header-nav-wrap .theme-box .box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.header-nav-wrap .theme-box .box-active,
.header-nav-wrap .theme-box .box-toggle-theme-active {
  box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04), 0px 0px 2px rgba(51, 51, 51, 0.02);
}
.header-nav-wrap .notify-box {
  position: relative;
  margin-right: 2rem;
  position: relative;
}
.header-nav-wrap .notify-box .notify-box-two {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.header-nav-wrap .notify-box .red-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  position: absolute;
  top: 0.65rem;
  right: 0.8rem;
  background-color: #f13d3d;
  z-index: 3;
}
.header-nav-wrap .notify-box .img-box-icon {
  width: 2.2rem;
  height: 2.2rem;
  display: grid;
  place-items: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header-nav-wrap .notify-box .img-box-icon:hover .img {
  transform: scale(1.1);
}
.header-nav-wrap .notify-box .img-box-icon .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  transition: all 0.3s;
}
.header-nav-wrap .notify-box .notification-big-screen-drop {
  position: absolute;
  top: 120%;
  right: 0%;
  animation: moveDownSettingDrop 0.5s ease-in-out backwards;
  z-index: 20;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big {
  border-radius: 1.2rem;
  max-height: 60rem;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  width: 45rem;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .no-content-reusuable-wrap-box {
  height: 25rem;
  padding: 0rem 2rem;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .title-all-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 0rem 2rem;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .title-all-box .title {
  font-size: 2.3rem;
  font-weight: 600;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .title-all-box .all {
  font-size: 1.35rem;
  cursor: pointer;
  border-bottom: 0.1rem solid currentColor;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .select-view-box {
  display: flex;
  align-items: center;
  gap: 2rem;
  border-width: 0.15rem;
  padding: 0rem 2rem;
  padding-bottom: 1.2rem;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .select-view-box .select-view-item {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  position: relative;
  cursor: pointer;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .select-view-box .select-view-item::after {
  content: "";
  top: 100%;
  height: 0.3rem;
  width: 100%;
  position: absolute;
  transform: translateY(1.1rem);
  visibility: hidden;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .select-view-box .select-view-item .text {
  font-size: 1.5rem;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .select-view-box .select-view-item .num {
  padding: 0.2rem 0.8rem;
  border-radius: 0.4rem;
  display: grid;
  place-items: center;
  opacity: 0.8;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .select-view-box .select-view-item .num span {
  color: #ffffff;
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .select-view-box .select-view-item-active::after {
  visibility: visible;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .notification-content-box {
  display: flex;
  flex-direction: column;
  max-height: 40rem;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 3rem;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .notification-content-box::-webkit-scrollbar {
  display: none;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .notification-content-box:hover .notify-item:not(:hover) {
  position: relative;
  z-index: 5;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .notification-content-box:hover .notify-item:hover {
  position: relative;
  z-index: 10;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .notification-content-box .notify-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  border-width: 0.15rem;
  cursor: pointer;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .notification-content-box .notify-item .img-wrap .img-box {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .notification-content-box .notify-item .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .notification-content-box .notify-item .description-box {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .notification-content-box .notify-item .description-box .notify-text {
  font-size: 1.45rem;
  font-weight: 400;
  max-width: 32rem;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .notification-content-box .notify-item .description-box .notify-text span {
  font-weight: 600;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .view-all-box-wrap {
  margin-top: 3rem;
  align-self: center;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 1.5rem;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .view-all-box-wrap .text {
  margin-top: 1.5rem;
  font-size: 1.6rem;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .view-all-box-wrap .pagination-wrap__black-light {
  gap: 0rem;
}
.header-nav-wrap .notify-box .notification-big-screen-drop .notification-wrap-modal-index-big .view-all-box-wrap .pagination-wrap__black-light .child {
  transform: scale(0.75) !important;
}

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}/*# sourceMappingURL=HeaderNav.css.map */