.bill-payment-wrap-box {
  flex: 1;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 42em) {
  .bill-payment-wrap-box {
    width: 100%;
    align-self: stretch;
    flex: unset;
  }
}

.bill-payment-wrap-box .bill-payment-wrap {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  width: 50rem;
  gap: 2rem;
}
@media only screen and (max-width: 42em) {
  .bill-payment-wrap-box .bill-payment-wrap {
    width: 100%;
    padding: 2rem 2rem;
    align-self: stretch;
  }
}

.bill-payment-wrap-box .bill-payment-wrap .title-drop-box {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  position: relative;
  z-index: 10;
  width: -moz-max-content;
  width: max-content;
}
@media only screen and (max-width: 48em) {
  .bill-payment-wrap-box .bill-payment-wrap .title-drop-box {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
  .bill-payment-wrap-box .bill-payment-wrap .title-drop-box .value-box {
    margin-left: auto;
  }
}
.bill-payment-wrap-box .bill-payment-wrap .title-drop-box .title {
  font-size: 1.6rem;
}
.bill-payment-wrap-box .bill-payment-wrap .title-drop-box .select-toggle-box {
  position: absolute;
  top: 120%;
  right: 0;
}
.bill-payment-wrap-box .bill-payment-wrap .title-drop-box .value-box {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  background-color: rgb(240, 238, 255);
  padding: 0.5rem 0.8rem;
  border-radius: 0.6rem;
  cursor: pointer;
}
.bill-payment-wrap-box .bill-payment-wrap .title-drop-box .value-box .value {
  color: rgb(117, 90, 226);
  font-weight: 600;
}
.bill-payment-wrap-box .bill-payment-wrap .title-drop-box .value-box .img-box {
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;
}
.bill-payment-wrap-box .bill-payment-wrap .title-drop-box .value-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  transform: translateY(0.2rem);
}
.bill-payment-wrap-box .bill-payment-wrap .title-drop-box .value-box .img-box-up {
  transform: translateY(0.25rem) rotateX(-180deg);
}

.bill-payment-wrap-box .bill-payment-wrap .select-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0rem;
  border-radius: 1.6rem;
  border-width: 0.15rem;
}
.bill-payment-wrap-box .bill-payment-wrap .select-box > *:not(:last-child) {
  padding-bottom: 2rem !important;
}
.bill-payment-wrap-box .bill-payment-wrap .select-box > *:last-child {
  border: unset;
}
@media only screen and (max-width: 42em) {
  .bill-payment-wrap-box .bill-payment-wrap .select-box {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    align-items: flex-start;
    width: 100%;
    align-self: stretch;
  }
}

.bill-payment-wrap-box .bill-payment-wrap .select-box .box {
  cursor: pointer;
  padding: 1.5rem 0rem;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: unset;
}
.bill-payment-wrap-box .bill-payment-wrap .select-box .box:hover .right-arrow .icon {
  transform: translateX(0.4rem);
}
.bill-payment-wrap-box .bill-payment-wrap .select-box .box .right-arrow {
  margin-left: auto;
}
.bill-payment-wrap-box .bill-payment-wrap .select-box .box .right-arrow .icon {
  width: 1.5rem;
  height: 1.5rem;
  transition: all 0.3s;
}
@media only screen and (max-width: 42em) {
  .bill-payment-wrap-box .bill-payment-wrap .select-box .box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem 1rem;
  }
}
@media only screen and (max-width: 42em) and (max-width: 38em) {
  .bill-payment-wrap-box .bill-payment-wrap .select-box .box .img-box {
    margin-top: unset;
    width: 3.5rem;
    height: 3.5rem;
  }
}
@media only screen and (max-width: 42em) {
  .bill-payment-wrap-box .bill-payment-wrap .select-box .box .text {
    font-size: 1.2rem;
    width: 85%;
  }
}
@media only screen and (max-width: 42em) {
  .bill-payment-wrap-box .bill-payment-wrap .select-box .box .name {
    margin-top: unset;
    font-size: 1.45rem;
  }
}
.bill-payment-wrap-box .bill-payment-wrap .select-box .box .text-box .name {
  margin-top: unset;
  margin-bottom: 0.3rem;
}

.bill-payment-wrap-box .bill-payment-wrap .select-box .box .select-bulk-type-box {
  z-index: 3;
  position: absolute;
  bottom: 80%;
  left: 50%;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 2.4rem;
  padding: 2rem 2.5rem;
  display: flex;
  flex-direction: column;
  width: 30rem;
  transform: translateX(-50%) scale(0);
  transform-origin: bottom center;
  transition: all 0.3s;
}

.bill-payment-wrap-box .bill-payment-wrap .select-box .box .select-bulk-type-box > *:first-child {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.1rem solid #f7f8f7;
}

.bill-payment-wrap-box .bill-payment-wrap .select-box .box .select-bulk-type-box .child-row {
  display: flex;
  align-items: center;
  width: -moz-max-content;
  width: max-content;
  align-self: stretch;
  width: 100%;
}

.bill-payment-wrap-box .bill-payment-wrap .select-box .box .select-bulk-type-box .child-row:hover .img-box {
  transform: scale(1.03);
}

.bill-payment-wrap-box .bill-payment-wrap .select-box .box .select-bulk-type-box .child-row .title {
  font-weight: 700;
  font-size: 1.4rem;
}

.bill-payment-wrap-box .bill-payment-wrap .select-box .box .select-bulk-type-box .child-row .text {
  font-size: 1.25rem;
}

.bill-payment-wrap-box .bill-payment-wrap .select-box .box .img-box {
  width: 4.5rem;
  height: 4.5rem;
  display: grid;
  place-items: center;
  margin-right: 1rem;
  transition: all 0.3s;
  border-radius: 50%;
  background-color: #f7f8f7;
}

.bill-payment-wrap-box .bill-payment-wrap .select-box .box .img-box .img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 60%;
  height: 60%;
}

.bill-payment-wrap-box .bill-payment-wrap .select-box > *:nth-last-child(2) .img-box .img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 40%;
  height: 40%;
}

.bill-payment-wrap-box .bill-payment-wrap .select-box .box .select-bulk-type-box-show {
  transform: translateX(-50%) scale(1);
} /*# sourceMappingURL=BillPaymentIndex.css.map */