.account-change-drop-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  padding-top: 2rem;
  gap: 1rem;
}
.account-change-drop-wrap .change-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 246, 237);
  padding: 1.5rem;
  border-radius: 0.8rem;
}
.account-change-drop-wrap .change-wrap .change-text .text {
  color: rgb(234, 135, 45);
  font-weight: 600;
  cursor: pointer;
  font-size: 1.5rem;
}
.account-change-drop-wrap .change-wrap .change-name-info .name-icon {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.account-change-drop-wrap .change-wrap .change-name-info .name-icon .img-box {
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
  place-items: center;
}
.account-change-drop-wrap .change-wrap .change-name-info .name-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.account-change-drop-wrap .change-wrap .change-name-info .name-icon .name {
  font-size: 1.65rem;
  font-weight: 600;
}

.account-change-drop-wrap-dark .change-wrap {
  background-color: rgb(247, 247, 247);
}
.account-change-drop-wrap-dark .change-wrap .change-text {
  position: relative;
  z-index: 20;
}
.account-change-drop-wrap-dark .change-wrap .change-text .text {
  color: rgb(117, 90, 226);
}
.account-change-drop-wrap-dark .change-wrap .change-text .select-toggle-box {
  position: absolute;
  bottom: 100%;
  right: 0;
}
.account-change-drop-wrap-dark .change-wrap .change-text .select-toggle-box-down {
  top: 100%;
  bottom: unset;
}/*# sourceMappingURL=ChangeSourceAccount.css.map */