// @import "../../breakpoints";

.bill-payment-wrap-box {
  flex: 1;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 42em) {
    width: 100%;
    align-self: stretch;
    flex: unset;
    // background-color: red;
  }
}
.bill-payment-wrap-box .bill-payment-wrap {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  width: 50rem;
  gap: 2rem;

  @media only screen and (max-width: 42em) {
    width: 100%;
    padding: 2rem 2rem;
    align-self: stretch;
  }
}
.bill-payment-wrap-box .bill-payment-wrap .title-drop-box {
  display: flex;
  align-items: center;
  // background-color: red;
  gap: 1.5rem;
  position: relative;
  z-index: 10;
  width: max-content;

  @media only screen and (max-width: 48em) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    
    .value-box {
      margin-left: auto;
    }
  }

  .title {
    font-size: 1.6rem;
    // color: red;
  }

  .select-toggle-box{
    position: absolute;
    top: 120%;
    right: 0;
  }

  .value-box{
    display: flex;
    align-items: center;
    gap: .7rem;
    background-color: rgba(240, 238, 255, 1);
    padding: .5rem .8rem;
    border-radius: .6rem;
    cursor: pointer;

    // &:hover .img-box{
    //   transform: translateY(.25rem);
    // }

    // &:hover .img-box-up{
    //   transform: translateY(-.25rem);
    // }

    .value{
      color: rgba(117, 90, 226, 1);
      font-weight: 600;
    }

    .img-box{
      display: grid;
      place-items: center;
      width: 2rem;
      height: 2rem;
      // transition: all .3s ease-in-out;

      .img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: translateY(.2rem);
      }
    }

    .img-box-up{
      transform: translateY(.25rem) rotateX(-180deg);
    }
  }
}
.bill-payment-wrap-box .bill-payment-wrap .select-box {
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  // grid-template-rows: repeat(2, 1fr);
  // grid-gap: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0rem;
  border-radius: 1.6rem;
  border-width: .15rem;

  &>*:not(:last-child){
    padding-bottom: 2rem !important;
  }

  & > *:last-child {
    border: unset;
    // padding-bottom: 2;
  }

  @media only screen and (max-width: 42em) {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    align-items: flex-start;
    width: 100%;
    align-self: stretch;
  }
}
.bill-payment-wrap-box .bill-payment-wrap .select-box .box {
  cursor: pointer;
  padding: 1.5rem 0rem;
  position: relative;
  // background-color: red;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: unset;

  &:hover .right-arrow .icon {
    transform: translateX(0.4rem);
  }

  .right-arrow {
    // @media only screen and (min-width: 42em){
    //  display: none;
    // }
    margin-left: auto;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
      transition: all 0.3s;
    }
  }

  @media only screen and (max-width: 42em) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem 1rem;

    .img-box {
      @media only screen and (max-width: 38em) {
        margin-top: unset;

        width: 3.5rem;
        height: 3.5rem;
      }
    }

    .text {
      font-size: 1.2rem;
      width: 85%;
    }
  }

  .name {
    @media only screen and (max-width: 42em) {
      margin-top: unset;
      font-size: 1.45rem;
    }
  }

  .text-box {
    .name {
      margin-top: unset;
      margin-bottom: 0.3rem;
    }
  }
}

.bill-payment-wrap-box
  .bill-payment-wrap
  .select-box
  .box
  .select-bulk-type-box {
  z-index: 3;
  position: absolute;
  bottom: 80%;
  left: 50%;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
    0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 2.4rem;
  padding: 2rem 2.5rem;
  display: flex;
  flex-direction: column;
  width: 30rem;
  transform: translateX(-50%) scale(0);
  transform-origin: bottom center;
  transition: all 0.3s;
}
.bill-payment-wrap-box
  .bill-payment-wrap
  .select-box
  .box
  .select-bulk-type-box
  > *:first-child {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.1rem solid #f7f8f7;
}
.bill-payment-wrap-box
  .bill-payment-wrap
  .select-box
  .box
  .select-bulk-type-box
  .child-row {
  display: flex;
  align-items: center;
  width: -moz-max-content;
  width: max-content;
  align-self: stretch;
  width: 100%;
}
.bill-payment-wrap-box
  .bill-payment-wrap
  .select-box
  .box
  .select-bulk-type-box
  .child-row:hover
  .img-box {
  transform: scale(1.03);
}
.bill-payment-wrap-box
  .bill-payment-wrap
  .select-box
  .box
  .select-bulk-type-box
  .child-row
  .title {
  font-weight: 700;
  font-size: 1.4rem;
}
.bill-payment-wrap-box
  .bill-payment-wrap
  .select-box
  .box
  .select-bulk-type-box
  .child-row
  .text {
  font-size: 1.25rem;
}
.bill-payment-wrap-box
  .bill-payment-wrap
  .select-box
  .box
  // .select-bulk-type-box
  // .child-row
  .img-box {
  width: 4.5rem;
  height: 4.5rem;
  display: grid;
  place-items: center;
  margin-right: 1rem;
  transition: all 0.3s;
  border-radius: 50%;
  background-color: #f7f8f7;
  // display: none;
}
.bill-payment-wrap-box
  .bill-payment-wrap
  .select-box
  .box
  // .select-bulk-type-box
  // .child-row
  .img-box
  .img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 60%;
  height: 60%;
}

.bill-payment-wrap-box
  .bill-payment-wrap
  .select-box
  > *:nth-last-child(2)
  .img-box
  .img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 40%;
  height: 40%;
}

// .bill-payment-wrap-box
//   .bill-payment-wrap
//   .select-box
//   >*:last-child{
//     .img-box
//     .img {
//     -o-object-fit: contain;
//     object-fit: contain;
//     width: 69%;
//     height: 69%;
//   }
//   }
.bill-payment-wrap-box
  .bill-payment-wrap
  .select-box
  .box
  .select-bulk-type-box-show {
  transform: translateX(-50%) scale(1);
} /*# sourceMappingURL=BillPaymentIndex.css.map */

//responsive styles
// @include xs {
//   .bill-payment-wrap {
//     width: 100% !important;
//     padding: 2rem;
//   }
// }
