.add-sub-account-index-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}
@media only screen and (max-width: 42em) {
  .add-sub-account-index-wrap {
    width: 90%;
    margin: 0rem auto;
  }
}
.add-sub-account-index-wrap .form {
  width: 40rem;
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  gap: 2.5rem;
  flex: 1;
  overflow-y: scroll;
}
@media only screen and (max-width: 42em) {
  .add-sub-account-index-wrap .form {
    width: 100%;
    margin: 0rem auto;
  }
}
.add-sub-account-index-wrap .form .select-wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1.5rem;
  overflow-y: scroll;
}
.add-sub-account-index-wrap .form .select-wrap::-webkit-scrollbar {
  display: none;
}
.add-sub-account-index-wrap .form .select-wrap .select-title {
  align-self: flex-start;
  margin-bottom: 1.5rem;
  font-size: 1.3rem;
  text-transform: capitalize;
}
.add-sub-account-index-wrap .form .select-wrap .select-box {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;
  flex-direction: column;
}
.add-sub-account-index-wrap .form .select-wrap .select-box > *:not(:last-child) {
  padding-bottom: 1.5rem !important;
  display: none;
}
.add-sub-account-index-wrap .form .select-wrap .select-box .select-role-item {
  width: 100%;
  display: flex;
  align-items: center;
}
.add-sub-account-index-wrap .form .select-wrap .select-box .select-role-item .role {
  text-transform: capitalize;
  font-weight: 500;
}
.add-sub-account-index-wrap .form .select-wrap .select-box .select-role-item .check-wrap {
  margin-left: auto;
}
.add-sub-account-index-wrap .form .select-wrap .select-box .select-role-item .check-wrap .check-box-wrap .input-check:checked ~ .label-check__purple-light {
  background-color: #755ae2 !important;
}
.add-sub-account-index-wrap .form .select-wrap .select-box .select-role-item .check-wrap .check-box-wrap .label-check__purple-light {
  border: 0.15rem solid rgb(204, 204, 204) !important;
}
.add-sub-account-index-wrap .form .select-wrap .select-box .select-role-item .check-wrap .check-box-wrap .input-check:checked ~ .label-check__purple-light {
  border: 0.15rem solid #755ae2 !important;
}
.add-sub-account-index-wrap .form .select-wrap .select-box .select-role-item .check-wrap-disabled label {
  cursor: not-allowed !important;
}
.add-sub-account-index-wrap .form .select-wrap .select-box .item {
  display: flex;
  align-items: center;
  border-radius: 2.5rem;
  padding: 1.2rem 2.1rem;
  cursor: pointer;
  gap: 1rem;
  position: relative;
}
.add-sub-account-index-wrap .form .select-wrap .select-box .item > * {
  position: relative;
  z-index: 3;
}
.add-sub-account-index-wrap .form .select-wrap .select-box .item::after {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 2.5rem;
  top: 0;
  left: 0;
  border: 0.25rem solid rgb(117, 90, 226);
  content: "";
  visibility: hidden;
}
.add-sub-account-index-wrap .form .select-wrap .select-box .item .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.add-sub-account-index-wrap .form .select-wrap .select-box .item .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.add-sub-account-index-wrap .form .select-wrap .select-box .item .name {
  font-weight: 600;
}
.add-sub-account-index-wrap .form .select-wrap .select-box .item-active {
  cursor: default;
}
.add-sub-account-index-wrap .form .select-wrap .select-box .item-active::after {
  visibility: visible;
}
.add-sub-account-index-wrap .form .select-manage-wrap {
  flex: 1;
  overflow-y: scroll;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 5rem;
}
@media only screen and (max-width: 42em) {
  .add-sub-account-index-wrap .form .select-manage-wrap {
    flex: unset;
    min-height: 100vh;
  }
}
.add-sub-account-index-wrap .form .select-manage-wrap::-webkit-scrollbar {
  display: none;
}
.add-sub-account-index-wrap .form .select-manage-wrap > *:not(:last-child) {
  padding-bottom: 2rem;
}
.add-sub-account-index-wrap .form .select-manage-wrap .select-react-disabled {
  cursor: not-allowed !important;
}
.add-sub-account-index-wrap .form .select-manage-wrap .select-react-disabled input {
  cursor: not-allowed !important;
}
.add-sub-account-index-wrap .form .select-manage-wrap .select-item {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 2rem;
  cursor: pointer;
}
.add-sub-account-index-wrap .form .select-manage-wrap .select-item .avatar-box {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: #020202 !important;
  display: grid;
  place-items: center;
}
.add-sub-account-index-wrap .form .select-manage-wrap .select-item .avatar-box span {
  font-weight: 600;
  color: #ffffff;
  display: inline-block;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.add-sub-account-index-wrap .form .select-manage-wrap .select-item .name-role-box {
  display: flex;
  flex-direction: column;
  align-self: center;
}
.add-sub-account-index-wrap .form .select-manage-wrap .select-item .name-role-box .name {
  font-size: 1.7rem;
  font-weight: 600;
}
.add-sub-account-index-wrap .form .select-manage-wrap .select-item .img-wrap .img-box {
  width: 4rem;
  height: 4rem;
}
.add-sub-account-index-wrap .form .select-manage-wrap .select-item .img-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.add-sub-account-index-wrap .form .select-manage-wrap .select-item .select-option-wrap {
  margin-left: auto;
}
.add-sub-account-index-wrap .form .select-manage-wrap .select-item .select-option-wrap form .form-group .react-select-class > *:nth-child(3) {
  width: -moz-max-content !important;
  width: max-content !important;
  border: unset !important;
  border-radius: 3rem;
  background-color: rgb(247, 247, 247);
  padding: 0.07rem 0.3rem;
}
.add-sub-account-index-wrap .form .select-manage-wrap .select-item .select-option-wrap form .form-group .react-select-class > *:nth-child(4) {
  left: unset;
  right: 0.5rem;
  width: -moz-max-content;
  width: max-content;
}
.add-sub-account-index-wrap .form-two {
  width: 100%;
  align-self: stretch;
  flex: 1;
}
.add-sub-account-index-wrap .form-two .two-view-box {
  width: 100%;
  flex: 1;
  align-self: stretch;
  position: relative;
}
.add-sub-account-index-wrap .form-two .two-view-box .left-box {
  display: flex;
  justify-content: center;
  border-width: 0.15rem;
  margin-right: 3rem;
  transition: all 0.3s ease-in-out;
  position: relative;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 50%;
  z-index: 4;
  height: 100%;
}
.add-sub-account-index-wrap .form-two .two-view-box .left-box .bring-back-box {
  position: absolute;
  top: 2rem;
  right: 0rem;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  display: grid;
  place-items: center;
}
.add-sub-account-index-wrap .form-two .two-view-box .left-box .bring-back-box .icon {
  width: 2rem;
  height: 2rem;
}
.add-sub-account-index-wrap .form-two .two-view-box .left-box .wrappert {
  display: flex;
  flex-direction: column;
}
.add-sub-account-index-wrap .form-two .two-view-box .left-box .wrappert .title {
  font-size: 1.4rem;
  margin-bottom: 2rem;
}
.add-sub-account-index-wrap .form-two .two-view-box .left-box-center {
  z-index: 4;
  width: 100%;
  transform: translateX(-50%);
  left: 50%;
}
.add-sub-account-index-wrap .form-two .two-view-box .right-box {
  display: grid;
  place-items: center;
  margin-left: auto;
  position: relative;
  z-index: 2;
  position: absolute;
  transition: all 0.3s ease-in-out;
  top: 0%;
  right: 0%;
  width: 50%;
}
.add-sub-account-index-wrap .form-two .two-view-box .right-box .wrapper-sub {
  width: 100%;
  display: grid;
  place-items: center;
  transition: all 0.3s ease-in-out;
}
.add-sub-account-index-wrap .form-two .two-view-box .right-box .wrapper-sub .wrap {
  border-radius: 1.2rem;
  width: 70%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.add-sub-account-index-wrap .form-two .two-view-box .right-box .wrapper-sub .wrap .title-header {
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-sub-account-index-wrap .form-two .two-view-box .right-box .wrapper-sub .wrap .title-header .title {
  font-size: 1.8rem;
  font-weight: 600;
}
.add-sub-account-index-wrap .form-two .two-view-box .right-box .wrapper-sub .wrap .title-header .cancel-box {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.add-sub-account-index-wrap .form-two .two-view-box .right-box .wrapper-sub .wrap .title-header .cancel-box .img-box {
  width: 50%;
  height: 50%;
}
.add-sub-account-index-wrap .form-two .two-view-box .right-box .wrapper-sub .wrap .title-header .cancel-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.add-sub-account-index-wrap .form-two .two-view-box .right-box .wrapper-sub .wrap .content-wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.add-sub-account-index-wrap .form-two .two-view-box .right-box .wrapper-sub .wrap .content-wrap > *:not(:last-child) {
  padding-bottom: 2rem;
}
.add-sub-account-index-wrap .form-two .two-view-box .right-box .wrapper-sub .wrap .content-wrap .read-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.add-sub-account-index-wrap .form-two .two-view-box .right-box .wrapper-sub .wrap .content-wrap .read-item .name {
  font-size: 1.3rem;
  font-weight: 600;
}
.add-sub-account-index-wrap .form-two .two-view-box .right-box .wrapper-sub .wrap .content-wrap .read-item .text {
  font-size: 1.3rem;
}
.add-sub-account-index-wrap .form-two .two-view-box .right-box .wrapper-sub-translate {
  animation: subInfoAnime 0.3s ease-in-out;
}
.add-sub-account-index-wrap .form-two .two-view-box .right-box-leave {
  transform: translateX(100%);
}

@keyframes subInfoAnime {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0.5;
  }
}/*# sourceMappingURL=AddSubAccountIdex.css.map */