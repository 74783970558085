.container-wraaper-box {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 1.6rem;
  flex: 1;
  overflow: hidden;
  margin-top: 1rem;
}
@media only screen and (max-width: 58em) {
  .container-wraaper-box {
    flex: unset;
  }
}
.container-wraaper-box .header-box {
  position: relative;
  z-index: 5;
  min-height: 6rem;
  display: flex;
  align-items: center;
  padding: 0rem 3rem;
}
.container-wraaper-box .header-box .bulk-select {
  width: -moz-max-content;
  width: max-content;
}
.container-wraaper-box .header-box .clear-bulk-list {
  color: #755AE2;
  font-size: 1.45rem;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
}
@media only screen and (max-width: 42em) {
  .container-wraaper-box .header-box {
    padding: 0rem 1.5rem;
  }
}
.container-wraaper-box .header-box .back-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.container-wraaper-box .header-box .back-box:hover .icon {
  animation: moveBackFort 0.3s ease-in-out;
}
.container-wraaper-box .header-box .back-box .icon {
  width: 1.45rem;
  height: 1.45rem;
  margin-right: 0.1rem;
  fill: rgba(80, 95, 109, 0.32);
  transform: scale(1.1);
}
.container-wraaper-box .header-box .back-box .text {
  font-size: 1.6rem;
  text-transform: capitalize;
  font-weight: 600;
}
.container-wraaper-box .header-box .step-box {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.container-wraaper-box .header-box .step-box .box {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.container-wraaper-box .header-box .step-box .box .num {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background-color: rgba(189, 189, 189, 0.2);
  display: grid;
  place-items: center;
}
.container-wraaper-box .header-box .step-box .box .num span {
  color: rgba(22, 45, 76, 0.4);
  font-size: 1.2rem;
}
.container-wraaper-box .header-box .step-box .box .text {
  margin-top: 0.1rem;
  color: #bdbdbd;
  font-size: 1.2rem;
}
@media only screen and (max-width: 42em) {
  .container-wraaper-box .header-box .step-box .box .text {
    display: none;
  }
}
.container-wraaper-box .header-box .step-box .box-active .num {
  background-color: #333333;
}
.container-wraaper-box .header-box .step-box .box-active .num span {
  color: #ffffff;
  font-weight: 600;
}
.container-wraaper-box .header-box .step-box .box-active .text {
  color: #020202;
}
@media only screen and (max-width: 42em) {
  .container-wraaper-box .header-box .step-box .box-active .text {
    display: none;
  }
}
.container-wraaper-box .content-box {
  position: relative;
  z-index: 2;
  flex: 1;
  display: flex;
  overflow-y: scroll;
  position: relative;
  z-index: 2;
  width: 100%;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
@media only screen and (max-width: 58em) {
  .container-wraaper-box .content-box {
    overflow-x: hidden;
    margin-bottom: 5rem;
  }
}
.container-wraaper-box .content-box > * {
  margin: 0rem auto;
}
.container-wraaper-box .content-box .wrap {
  display: flex;
}
@media only screen and (max-width: 42em) {
  .container-wraaper-box .content-box::-webkit-scrollbar {
    display: none;
  }
}
.container-wraaper-box .content-box::-webkit-scrollbar {
  display: none;
}
.container-wraaper-box .content-box::-webkit-scrollbar {
  width: 0.5rem;
}
.container-wraaper-box .content-box::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.container-wraaper-box .content-box::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.container-wraaper-box .content-box::-webkit-scrollbar-thumb:hover {
  background: #020202;
}
.container-wraaper-box .footer-box {
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 100%;
  padding: 1rem 0rem;
  display: grid;
  place-items: center;
  z-index: 10;
}
.container-wraaper-box .footer-box .table-pagination-box {
  display: flex;
  justify-content: center;
  padding: 0rem 3rem;
}
@media only screen and (max-width: 42em) {
  .container-wraaper-box .footer-box .table-pagination-box {
    padding: 0rem 1.5rem;
  }
}
.container-wraaper-box .footer-box .wrap {
  width: 40rem;
}
@media only screen and (max-width: 42em) {
  .container-wraaper-box .footer-box .wrap {
    width: 90%;
  }
}
.container-wraaper-box .footer-box .wrap form .form-group input {
  font-family: "br_firma", sans-serif;
}

@keyframes moveBackFort {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.2rem);
  }
  100% {
    transform: translateX(0rem);
  }
}/*# sourceMappingURL=ContainerWrapper.css.map */