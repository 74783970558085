.dashboard-profile-wrap-box {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  width: 100%;
}
@media only screen and (max-width: 52em) {
  .dashboard-profile-wrap-box {
    padding: 2rem;
  }
}
.dashboard-profile-wrap-box .name-image-top-box-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media only screen and (max-width: 52em) {
  .dashboard-profile-wrap-box .name-image-top-box-wrap {
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .edit-logout-btn-box {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  position: relative;
}
@media only screen and (max-width: 52em) {
  .dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box {
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .reset-to-default-box {
  position: absolute;
  top: 95%;
  left: 0%;
  width: 2.7rem;
  height: 2.7rem;
  cursor: pointer;
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .reset-to-default-box .img-box {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .reset-to-default-box .img-box .img {
  width: 60%;
  height: 60%;
  -o-object-fit: contain;
     object-fit: contain;
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .change-reset-default-box {
  position: absolute;
  top: 110%;
  left: 0%;
  padding: 1.5rem;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  animation: moveDownSettingDrop 0.5s ease-in-out backwards;
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .change-reset-default-box .change-text {
  border-width: 0.15rem;
  cursor: pointer;
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .name-email-box .transfer-limit-box {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;
  width: 40rem;
}
@media only screen and (max-width: 52em) {
  .dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .name-email-box .transfer-limit-box {
    width: 100%;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .name-email-box .transfer-limit-box .wrap {
  flex: 1;
}
@media only screen and (max-width: 52em) {
  .dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .name-email-box .transfer-limit-box .wrap {
    width: 70vw;
    flex: unset;
  }
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .name-email-box .transfer-limit-box .text-bar {
  width: -moz-max-content;
  width: max-content;
  font-weight: 700;
  color: #755ae2;
  font-size: 1.25rem;
  cursor: pointer;
  text-transform: uppercase;
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .name-email-box .name {
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 0.3rem;
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .name-email-box .busineness-name {
  font-size: 1.35rem;
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .profile-img-input {
  display: none;
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .label-save-box {
  display: flex;
  flex-direction: column;
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .label-save-box .upload-profile-btn {
  margin-top: 1.5rem;
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .label-save-box .save-image-text {
  margin-top: 1.5rem;
  align-self: center;
  width: -moz-max-content;
  width: max-content;
  font-weight: 700;
  color: #755ae2;
  font-size: 1.25rem;
  cursor: pointer;
  text-transform: uppercase;
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .label-save-box .profile-img-label {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .label-save-box .profile-img-label .img-box {
  position: absolute;
  bottom: 6%;
  right: 0rem;
  transform: translateX(10%);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: #ffffff;
  border: 0.3rem solid #fafaff;
}
.dashboard-profile-wrap-box .name-image-top-box-wrap .name-img-box .label-save-box .profile-img-label .img-box .img {
  width: 40%;
  height: 40%;
  -o-object-fit: contain;
     object-fit: contain;
}
.dashboard-profile-wrap-box .underline {
  margin: 2.5rem 0rem;
  width: 100%;
}
.dashboard-profile-wrap-box .action-box {
  width: 100%;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
  grid-row-gap: 4rem;
}
@media only screen and (max-width: 52em) {
  .dashboard-profile-wrap-box .action-box {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }
}
.dashboard-profile-wrap-box .action-box .box {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dashboard-profile-wrap-box .action-box .box .img-wrap .img-box {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.dashboard-profile-wrap-box .action-box .box .img-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 70%;
  height: 70%;
}
.dashboard-profile-wrap-box .action-box .box .img-wrap .img-box .img-small {
  width: 45%;
  height: 45%;
}
.dashboard-profile-wrap-box .action-box .box .text-box {
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
  font-size: 1.65rem;
  margin-left: 1rem;
}
.dashboard-profile-wrap-box .action-box .box .text-box .value {
  font-weight: 700;
}
.dashboard-profile-wrap-box .detail-box-wrap {
  width: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  grid-row-gap: 3rem;
}
@media only screen and (max-width: 52em) {
  .dashboard-profile-wrap-box .detail-box-wrap {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }
}
.dashboard-profile-wrap-box .detail-box-wrap .box {
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
  font-size: 1.65rem;
}
.dashboard-profile-wrap-box .detail-box-wrap .box .value {
  font-weight: 700;
  text-transform: capitalize;
}

.transfer-limit-modal-wrap .raven-modal-content-wrap {
  width: 55rem;
}

.manage-limit-transaction-modal-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
.manage-limit-transaction-modal-wrap .progress-label-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  margin-top: 1rem;
}
@media only screen and (max-width: 52em) {
  .manage-limit-transaction-modal-wrap .progress-label-box {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }
}
.manage-limit-transaction-modal-wrap .progress-label-box .label {
  font-weight: 700;
  font-size: 1.3rem;
}
.manage-limit-transaction-modal-wrap .show-all-box {
  padding: 0rem 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  width: 100%;
}
.manage-limit-transaction-modal-wrap .show-all-box > *:last-child {
  border-bottom: unset;
}
.manage-limit-transaction-modal-wrap .show-all-box > *:not(:last-child) {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
}
.manage-limit-transaction-modal-wrap .show-all-box .row {
  display: flex;
  align-items: flex-start;
}
.manage-limit-transaction-modal-wrap .show-all-box .row .left-box {
  flex: 0 0 20%;
  margin-right: 1rem;
}
.manage-limit-transaction-modal-wrap .show-all-box .row .left-box .text {
  font-size: 1.2rem;
  font-weight: 700;
}
.manage-limit-transaction-modal-wrap .show-all-box .row .right-box {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media only screen and (max-width: 52em) {
  .manage-limit-transaction-modal-wrap .show-all-box .row .right-box {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }
}
.manage-limit-transaction-modal-wrap .show-all-box .row .right-box .box .label {
  font-size: 1.25rem;
}
.manage-limit-transaction-modal-wrap .show-all-box .row .right-box .box .value {
  font-weight: 700;
  margin-top: 0.3rem;
}
.manage-limit-transaction-modal-wrap .show-all-box-show {
  padding: 2.5rem;
  max-height: 35rem;
  transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
}
.manage-limit-transaction-modal-wrap .preview-limit-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 1.5rem;
  border-radius: 1.2rem;
  margin: 2rem 0rem;
}
@media only screen and (max-width: 52em) {
  .manage-limit-transaction-modal-wrap .preview-limit-box {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }
}
.manage-limit-transaction-modal-wrap .preview-limit-box .box .value {
  font-weight: 700;
  margin-top: 0.25rem;
}
.manage-limit-transaction-modal-wrap .preview-limit-box .box .value span {
  font-weight: 400;
}
.manage-limit-transaction-modal-wrap .preview-limit-box .show-all {
  justify-self: flex-end;
  color: #755ae2;
  font-weight: 700;
  cursor: pointer;
  font-size: 1.15rem;
  text-transform: uppercase;
  align-self: center;
}
.manage-limit-transaction-modal-wrap .progress-container-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-radius: 4.8rem;
  background-color: #f7f8f7;
  overflow: hidden;
}
.manage-limit-transaction-modal-wrap .progress-container-wrap > *:not(:last-child) {
  border-right: 0.2rem solid #dbd6fc;
}
.manage-limit-transaction-modal-wrap .text-label-action-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.manage-limit-transaction-modal-wrap .text-label-action-box .text-box .title {
  font-weight: 700;
  font-size: 1.5rem;
}
.manage-limit-transaction-modal-wrap .text-label-action-box .text-box .text {
  font-size: 1.3rem;
}
.manage-limit-transaction-modal-wrap .text-label-action-box .upgrade {
  color: #755ae2;
  font-weight: 700;
  cursor: pointer;
  font-size: 1.45rem;
}
.manage-limit-transaction-modal-wrap .underline {
  width: 100%;
  margin: 2rem 0rem 3rem 0rem;
}

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}/*# sourceMappingURL=DashboardProfile.css.map */