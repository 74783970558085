.raven-to-raven-manual-page-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  //   margin-bottom: 5rem;
  //   flex: 1;
  //   gap: 2rem;

  tr {
    & > *:not(:last-child),
    & > *:not(:first-child) {
      padding-right: 2rem !important;
    }

    & > *:nth-child(3) {
      width: 25rem;
    }

    .edit-table-input input,
    .react-select-class > *:nth-child(3) {
      background-color: #ffffff;
    }

    td {
      .payroll-table-action-wrap {
        margin: 0rem auto;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: red;
        gap: 1rem;
        width: 100%;

        .img-box {
          width: 3.5rem;
          height: 3.5rem;
          // background-color: #f7f8f7;
          border-radius: 50%;
          display: grid;
          place-items: center;
          transition: transform 0.3s ease-in-out;

          &:hover {
            transform: scale(1.05);
          }

          .img {
            width: 40%;
            height: 40%;
            object-fit: contain;
          }
        }

        & > *:nth-child(2) {
          .img {
            width: 50%;
            height: 50%;
            object-fit: contain;
          }
        }

        .delete-box {
          background-color: #fff5f5;
        }
      }

      //   .react-select-class > *:nth-child(3) {
      //     width: 20rem;

      //     input{
      //         width: 100%;
      //     }

      //   }
    }

    .status-type-wrap {
      background-color: #e9f5ff;
      border-radius: 3rem;
      width: max-content;
      padding: 0.8rem 1.2rem;
      color: #476885;
      text-transform: capitalize;

      span {
        // font-weight: 700;
        font-size: 1.2rem;
      }
    }

    .status-type-wrap-fail {
      background-color: #fff5f5;
      color: #ff0f00;
    }

    .status-type-wrap-success {
      background-color: #e8fff6;
      color: #1ace37;
    }

    .status-type-wrap-pending {
      background-color: #fff6ed;
      color: #ea872d;
    }
  }

  .btn-wrap-box {
    padding-left: 3rem;
    padding-right: 3rem;
    margin-top: 3rem;
    padding-bottom: 5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > *:last-child {
      margin-left: 3rem;
    }
  }

  .bulk-schedule-narration-box {
    width: 40rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin: 0rem auto;
    margin-top: 3rem;

    .total-box-summary-wrap {
      //   display: grid;
      //   grid-template-columns: repeat(2, 1fr);
      //   gap: 1rem;
      display: flex;
      gap: 2rem;
      padding: 2rem 0rem;

      &>*{
        flex: 0 0 50%;
      }

      .box {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        width: max-content;

        .label {
          text-transform: uppercase;
          font-size: 1.3rem;
        }

        .value {
          font-weight: 700;
          font-size: 1.75rem;
        }
      }
    }

    @media only screen and (max-width: 42em) {
      width: 90%;
      margin: 2rem auto;
    }

    .save-bulk-template-box {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin-top: -1rem;

      .template-title-box {
        max-height: 0;
        overflow-x: hidden;
        overflow-y: hidden;
        transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
        -webkit-transition: max-height 0.3s;
        -moz-transition: max-height 0.3s;
        width: 100%;
        // background-color: red;
      }

      .template-title-box-show {
        max-height: 15rem;
        transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
      }

      .toggle-select-text-box {
        display: flex;
        align-items: center;
        gap: 1rem;

        .text {
          font-weight: 500;
        }

        & > *:last-child {
          transform: scale(0.8);
        }
      }
    }
  }
}

.raven-record-modal-wrap {
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;

  .text-title-box {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;

    .title {
      font-size: 1.75rem;
      font-weight: 700;
    }

    .text {
      font-size: 1.35rem;
    }
  }

  .record-wrap {
    margin-top: 3.5rem;
    max-height: 40rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    // padding-bottom: 3rem ;
    overflow-y: scroll;

    /* width */
    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none !important;
      background-color: transparent !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #cccccc;
      border-radius: 30rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #cccccc;
    }

    // &::-webkit-scrollbar {
    // //   display: none;
    // }

    & > *:not(:last-child) {
      padding-bottom: 1.5rem;
    }

    .template-row {
      display: flex;
      align-items: center;
      gap: 1rem;
      cursor: pointer;

      .img-wrap {
        display: grid;
        place-items: center;

        .img-box {
          width: 4rem;
          height: 4rem;

          .img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
      }

      .title-text-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        transform: translateY(-0.5rem);

        .title {
          font-weight: 700;
          font-size: 1.6rem;
        }

        .text {
          font-size: 1.3rem;
        }
      }
    }
  }
}
