.no-content-wrapper-page-box {
  width: 100%;
  border-radius: 1.6rem;
  flex: 1;
  display: grid;
  place-items: center;
}
.no-content-wrapper-page-box .spinner-wrap .img-box {
  width: 22rem;
  height: 22rem;
}
@media only screen and (max-width: 48em) {
  .no-content-wrapper-page-box .spinner-wrap .img-box {
    width: 15rem;
    height: 15rem;
  }
}
.no-content-wrapper-page-box .spinner-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
@media only screen and (min-width: 110em) {
  .no-content-wrapper-page-box {
    flex: unset;
    padding: 4rem 2rem;
    height: 70rem;
  }
}
@media only screen and (max-width: 56em) {
  .no-content-wrapper-page-box {
    min-height: 45rem;
    padding: 4rem 2rem;
    justify-self: center;
  }
}
.no-content-wrapper-page-box .wrap {
  display: flex;
  width: 80%;
  align-items: center;
}
@media only screen and (max-width: 56em) {
  .no-content-wrapper-page-box .wrap {
    flex-direction: column;
    gap: 3rem;
    width: 95%;
    align-self: flex-start;
  }
}
.no-content-wrapper-page-box .wrap .right-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 0 0 40%;
}
@media only screen and (max-width: 56em) {
  .no-content-wrapper-page-box .wrap .right-box {
    flex: unset;
    width: 100%;
  }
}
.no-content-wrapper-page-box .wrap .right-box .title-box .title {
  font-weight: 700;
  width: 85%;
  font-size: 2rem;
}
@media only screen and (max-width: 56em) {
  .no-content-wrapper-page-box .wrap .right-box .title-box .title {
    width: 100%;
  }
}
.no-content-wrapper-page-box .wrap .right-box .support-learn-box {
  margin-top: 1.5rem;
}
.no-content-wrapper-page-box .wrap .right-box .support-learn-box .icon-text-box {
  display: flex;
  align-items: flex-start;
  gap: 0.6rem;
}
.no-content-wrapper-page-box .wrap .right-box .support-learn-box .icon-text-box .text {
  font-size: 1.3rem;
  font-weight: 600;
}
.no-content-wrapper-page-box .wrap .right-box .support-learn-box .icon-text-box .text span {
  display: inline-block;
  color: #755ae2 !important;
  cursor: pointer;
  border-bottom: 0.1rem solid currentColor;
  transition: all 0.3s;
}
.no-content-wrapper-page-box .wrap .right-box .support-learn-box .icon-text-box .text span:hover {
  transform: scale(1.03);
}
.no-content-wrapper-page-box .wrap .right-box .support-learn-box .icon-text-box .img-box {
  width: 1.7rem;
  width: 1.7rem;
}
.no-content-wrapper-page-box .wrap .right-box .support-learn-box .icon-text-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.no-content-wrapper-page-box .wrap .right-box .btn-box {
  margin-top: 2rem;
  width: 100%;
  position: relative;
}
.no-content-wrapper-page-box .wrap .right-box .btn-box .select-bulk-type-box {
  z-index: 200;
  position: absolute;
  bottom: 120%;
  left: 50%;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 2.4rem;
  padding: 2rem 2.5rem;
  display: flex;
  flex-direction: column;
  width: 30rem;
  transform: translateX(-50%) scale(0);
  transform-origin: bottom center;
  transition: all 0.3s;
  opacity: 1;
}
.no-content-wrapper-page-box .wrap .right-box .btn-box .select-bulk-type-box > *:first-child {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.1rem solid #f7f8f7;
}
.no-content-wrapper-page-box .wrap .right-box .btn-box .select-bulk-type-box .child-row {
  display: flex;
  align-items: center;
  width: -moz-max-content;
  width: max-content;
  align-self: stretch;
  cursor: pointer;
  width: 100%;
}
.no-content-wrapper-page-box .wrap .right-box .btn-box .select-bulk-type-box .child-row:hover .img-box {
  transform: scale(1.03);
}
.no-content-wrapper-page-box .wrap .right-box .btn-box .select-bulk-type-box .child-row .title {
  font-weight: 700;
  font-size: 1.4rem;
  text-align: left;
}
.no-content-wrapper-page-box .wrap .right-box .btn-box .select-bulk-type-box .child-row .text {
  font-size: 1.25rem;
  text-align: left;
}
.no-content-wrapper-page-box .wrap .right-box .btn-box .select-bulk-type-box .child-row .img-box {
  width: 4rem;
  height: 4rem;
  display: grid;
  place-items: center;
  margin-right: 1rem;
  transition: all 0.3s;
  border-radius: 50%;
  background-color: #f7f8f7;
}
.no-content-wrapper-page-box .wrap .right-box .btn-box .select-bulk-type-box .child-row .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 50%;
  height: 50%;
}
.no-content-wrapper-page-box .wrap .right-box .btn-box .select-bulk-type-box-show {
  transform: translateX(-50%) scale(1);
}
.no-content-wrapper-page-box .wrap .right-box .btn-box .raven-btn {
  width: 100%;
}
.no-content-wrapper-page-box .wrap .right-box .list-box {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  gap: 1.5rem;
  width: 100%;
}
.no-content-wrapper-page-box .wrap .right-box .list-box .row-text {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
}
.no-content-wrapper-page-box .wrap .right-box .list-box .row-text .img-wrap .img-box {
  width: 1.7rem;
  width: 1.7rem;
}
.no-content-wrapper-page-box .wrap .right-box .list-box .row-text .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.no-content-wrapper-page-box .wrap .right-box .list-box .row-text .text {
  font-size: 1.5rem;
}
.no-content-wrapper-page-box .wrap .left-box {
  flex: 0 0 50%;
}
@media only screen and (max-width: 56em) {
  .no-content-wrapper-page-box .wrap .left-box {
    flex: unset;
    width: 100%;
  }
}
.no-content-wrapper-page-box .wrap .left-box .img-wrap {
  width: 100%;
  height: 35rem;
}
.no-content-wrapper-page-box .wrap .left-box .img-wrap .img-box {
  width: 100%;
  height: 100%;
}
.no-content-wrapper-page-box .wrap .left-box .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.no-content-wrapper-page-box .wrap-two {
  flex-direction: column;
  align-items: center;
}
.no-content-wrapper-page-box .wrap-two .left-box {
  flex: unset;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 56em) {
  .no-content-wrapper-page-box .wrap-two .left-box {
    margin-bottom: unset;
  }
}
.no-content-wrapper-page-box .wrap-two .left-box .img-wrap {
  width: 100%;
  height: 25rem;
}
.no-content-wrapper-page-box .wrap-two .left-box .img-wrap .img-box {
  width: 100%;
  height: 100%;
}
.no-content-wrapper-page-box .wrap-two .left-box .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.no-content-wrapper-page-box .wrap-two .right-box {
  flex: unset;
  align-items: center;
  gap: 0.5rem;
}
.no-content-wrapper-page-box .wrap-two .right-box .title-box .title {
  text-align: center;
  width: -moz-max-content;
  width: max-content;
}
.no-content-wrapper-page-box .wrap-two .right-box .normal-text {
  font-size: 1.5rem;
  text-align: center;
  width: 60%;
}
@media only screen and (max-width: 56em) {
  .no-content-wrapper-page-box .wrap-two .right-box .normal-text {
    flex: unset;
    width: 100%;
  }
}/*# sourceMappingURL=NocontentPage.css.map */