.role-and-permission-single-wrap {
  flex: 1;
  //  background-color: red;
  display: flex;
  //   padding: 0rem 3rem;

  @media only screen and (max-width: 48em){
    flex-direction: column;
    gap: 3rem;
    flex: unset;
    width: 100%;
  }

  & > * {
    padding: 5rem;
    padding-bottom: 2rem;

    @media only screen and (max-width: 48em){
      padding: 2rem ;
    }
  }

  .left-container-wrap {
    flex: 0 0 45%;
    display: flex;
    flex-direction: column;
    // padding-right: 2rem;
    // margin-right: 5rem;
    border-width: 0.2rem;
    // gap: 2rem;
    overflow-y: scroll;


  @media only screen and (max-width: 48em){
    flex: unset;
    width: 100%;
    border: unset !important;
    // padding-right: unset;
  }

    &::-webkit-scrollbar {
      display: none;
    }

    .section-title {
      font-size: 1.8rem;
      font-weight: 600;
      margin-bottom: 2rem;
    }

    .text-des-box {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding-bottom: 1.5rem;
      margin-bottom: 2rem;

      .text {
        font-size: 1.25rem;
        opacity: 0.8;
      }

      .des {
        font-size: 1.45rem;
        width: 80%;
        line-height: 2rem;
      }
    }

    .sub-text {
      font-size: 1.25rem;
      margin-bottom: 1.5rem;
    }

    .team-mate-box {
      overflow-y: scroll;
      max-height: calc(100vh - 25rem);
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin: 2rem 0rem;
      //   padding-bottom: 3rem;

     

      .team-item {
        display: flex;
        align-items: center;
        border-width: 0.12rem;
        padding-bottom: 1.5rem;
        gap: 1.5rem;

        @media only screen and (max-width: 48em){
          // flex-direction: column;
          // align-items: flex-start;
          flex-wrap: wrap;
        }

        .avatar-wrap {
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          display: grid;
          place-items: center;

          span {
            color: #ffffff;
            font-size: 1.1rem;
            font-weight: 600;
          }
        }

        .name {
          font-size: 1.6rem;
          font-weight: 500;
        }

        .remove-change-box {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin-left: auto;

          @media only screen and (max-width: 48em){
            // margin-left: 0rem;
          }

          .change {
            cursor: pointer;
            color: rgba(117, 90, 226, 1);
          }

          .remove {
            color: rgba(255, 15, 0, 1);
            cursor: pointer;
          }
        }

        .remove-badge {
          margin-left: auto;
          background-color: rgba(255, 245, 245, 1);
          padding: 1rem 2rem;
          border-radius: 2.5rem;

          span {
            color: rgba(255, 15, 0, 1);
          }
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .invite-label-box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .add-box {
        display: flex;
        align-items: center;
        border-radius: 2rem;
        padding: 1rem 2rem;
        gap: 0.7rem;
        cursor: pointer;

        &:hover .img-box {
          transform: scale(1.1);
        }

        .img-box {
          width: 2rem;
          height: 2rem;
          transition: transform 0.3s ease-in-out;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  .right-container-wrap {
    flex: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 48em){
      flex: unset;
      width: 100%;
      // background-color: red;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .edit-button-box {
      margin-top: auto;
      height: 5rem;
      width: 100%;
      align-self: stretch;
      // background-color: green;
      // padding-top: 1.5rem;
      border-width: 0.15rem;
      display: flex;
      justify-content: center;

      .edit-btn {
        width: 70%;
        // cursor: not-allowed;
      }
    }

    .first-child {
      flex: 1;
      display: flex;
      overflow-y: scroll;
      padding-bottom: 3rem;
      align-items: flex-start;
      margin-bottom: 1rem;

      &::-webkit-scrollbar {
        display: none;
      }

      @media only screen and (max-width: 48em){
        flex: unset;
        width: 100%;
      }
    }

    .role-permission-show-box {
      width: max-content;
      display: flex;
      flex-direction: column;
      border-width: 0.12rem;
      border-radius: 1.2rem;
      //   overflow: hidden;
      //   background-color: red;
      min-width: 45rem;

      @media only screen and (max-width: 48em){
        width: 100%;
        min-width: unset;
      }

      & > *:last-child {
        border-bottom: unset;
        // border-width: 0.12rem;
      }

      & > *:not(:last-child) {
        border-width: 0.12rem;
        // padding-bottom: 1.5rem;
      }

      .role-per-box {
        // min-width: ;
        .top-box {
          padding: 2rem 2rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          .role {
            font-weight: 500;
          }

          .img-box {
            width: 2rem;
            height: 2rem;
            transition: transform 0.3s ease-in-out;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .img-box-up {
            transform: rotate(-90deg);
          }
        }

        .bottom-box {
          max-height: 0;
          overflow-x: hidden;
          overflow-y: hidden;
          transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          width: 100%;

          .all-permission-box {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            padding: 1.5rem 2rem;

            .permission-item {
              display: flex;
              align-items: center;
              gap: 1rem;

              .check-box-wrap
                .input-check:checked
                ~ .label-check__purple-light {
                background-color: #755ae2 !important;
              }

              .check-box-wrap .label-check__purple-light {
                border: 0.15rem solid rgba(204, 204, 204, 1) !important;
              }

              .check-box-wrap
                .input-check:checked
                ~ .label-check__purple-light {
                border: 0.15rem solid #755ae2 !important;
              }
            }
          }
        }

        .bottom-box-show {
          // background-color: red;
          max-height: max-content;
          // display: none;
          transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
        }
      }
    }
  }
}

.creste-role-and-permission-form-wrap {
  flex: 1;
  // height: 40rem;
  //   background-color: red;
  display: flex;

  @media only screen and (max-width: 42em) {
    flex: unset;
    width: 100%;
    // background-color: green;
    // display: none;
  }

  .form-wrap-start {
    width: 40rem;
    padding-top: 3rem;
    padding-bottom: 6rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    flex: 1;

    @media only screen and (max-width: 42em) {
      width: 100% !important;
      padding: 2rem 2rem;
      align-self: stretch;
    // background-color: red;
     

      // .form-group{
      //   width: 100%;
      // }
    }

    .add-permission-to-role-box {
      display: flex;
      flex-direction: column;
      // gap: ;

      .label-title {
        align-self: flex-start;
        margin-bottom: 0.8rem;
        font-size: 1.3rem;
        text-transform: capitalize;
      }

      .role-permission-show-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-width: 0.12rem;
        border-radius: 1.2rem;
        margin-bottom: 10rem;
        //   overflow: hidden;
        //   max-width: 90% ;

        & > *:last-child {
          border-bottom: unset;
          // border-width: 0.12rem;
        }

        & > *:not(:last-child) {
          border-width: 0.12rem;
          // padding-bottom: 1.5rem;
        }

        .role-per-box {
          .top-box {
            padding: 2rem 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            .role {
              font-weight: 500;
            }

            .img-box {
              width: 2rem;
              height: 2rem;
              transition: transform 0.3s ease-in-out;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .img-box-up {
              transform: rotate(-90deg);
            }
          }

          .bottom-box {
            max-height: 0;
            overflow-x: hidden;
            overflow-y: hidden;
            transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            width: 100%;

            .all-permission-box {
              display: flex;
              flex-direction: column;
              gap: 2rem;
              padding: 1.5rem 2rem;

              .permission-item {
                display: flex;
                align-items: center;
                gap: 1rem;

                .check-box-wrap
                  .input-check:checked
                  ~ .label-check__purple-light {
                  background-color: #755ae2 !important;
                }

                .check-box-wrap .label-check__purple-light {
                  border: 0.15rem solid rgba(204, 204, 204, 1) !important;
                }

                .check-box-wrap
                  .input-check:checked
                  ~ .label-check__purple-light {
                  border: 0.15rem solid #755ae2 !important;
                }
              }
            }
          }

          .bottom-box-show {
            max-height: max-content;
            transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
          }
        }
      }
    }

    .add-team-to-role-box {
      display: flex;
      flex-direction: column;
      flex: 1;
      // background-color: red;
      overflow-y: scroll;
      // gap: ;

      .label-title {
        align-self: flex-start;
        margin-bottom: 0.8rem;
        font-size: 1.3rem;
        text-transform: capitalize;
      }

      .note-box-wrap {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        background-color: rgba(255, 246, 237, 1);
        border-radius: .8rem;
        margin-bottom: 1rem;

        .img-box {
          width: 3rem;
          height: 3rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .text {
          font-size: 1.2rem;
          color: rgba(234, 135, 45, 1);
        }
      }

      .team-to-add-box {
        flex: 1;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-top: 2rem;
        // background-color: blue;
        // padding-bottom: 5rem;

        &::-webkit-scrollbar {
          display: none;
        }

        & > *:not(:last-child) {
          padding-bottom: 2rem;
          border-width: 0.12rem;
        }

        &::-webkit-scrollbar {
          display: none;
        }

        .select-react-disabled {
          cursor: not-allowed !important;
          input {
            cursor: not-allowed !important;
          }
        }

        .select-item {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 2rem;
          cursor: pointer;

          .check-wrap {
            margin-left: auto;

            .check-box-wrap .input-check:checked ~ .label-check__purple-light {
              background-color: #755ae2 !important;
            }

            .check-box-wrap .label-check__purple-light {
              border: 0.15rem solid rgba(204, 204, 204, 1) !important;
            }

            .check-box-wrap .input-check:checked ~ .label-check__purple-light {
              border: 0.15rem solid #755ae2 !important;
            }
          }

          .avatar-box {
            width: 4.5rem;
            height: 4.5rem;
            border-radius: 50%;
            background-color: #020202 !important;
            display: grid;
            place-items: center;

            span {
              font-weight: 600;
              color: #ffffff;
              display: inline-block;
              font-size: 1.2rem;
              text-transform: uppercase;
            }
          }

          .name-role-box {
            display: flex;
            flex-direction: column;
            align-self: center;

            .name {
              font-size: 1.7rem;
              font-weight: 600;
            }
          }

          .img-wrap {
            .img-box {
              width: 4rem;
              height: 4rem;

              .img {
                object-fit: contain;
                width: 100%;
                height: 100%;
              }
            }
          }

          .select-option-wrap {
            // display: none;
            margin-left: auto;

            form {
              .form-group {
                .react-select-class {
                  & > *:nth-child(3) {
                    width: max-content !important;
                    border: unset !important;
                    border-radius: 3rem;
                    background-color: rgba(247, 247, 247, 1);
                    padding: 0.07rem 0.3rem;
                  }

                  & > *:nth-child(4) {
                    left: unset;
                    right: 0.5rem;
                    width: max-content;
                    // height: 30rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
