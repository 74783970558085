.mobile-table-card-wrapper-box {
  display: flex;
  align-items: flex-start;
  width: 100%;
  // transform: translateX(-1%);

  .avatar-img-box {
    display: grid;
    place-items: center;
    align-self: center;
    margin-right: 1rem;
    flex: 0 0 5%;

    .avatar-box{
        width: 3.3rem;
        height: 3.3rem;
        border-radius: 50%;
        display: grid;
        place-items: center;
        grid-gap: .4rem;

        span{
            font-weight: 700;
            font-size: 1.1rem;
            text-transform: uppercase;
        }
    }

    .img-box-cre-deb {
      width: 3rem;
      height: 3rem;
      background-color: #1ace37 !important;
      border-radius: 50%;
      display: grid;
      place-items: center;
      flex: 0 0 5%;

      .img {
        width: 35%;
        height: 35%;
        object-fit: contain;
        filter: brightness(1000%);
      }
    }

    .img-box-debit {
      background-color: #ff0f00 !important;
    }

    .img-box-failed {
      background-color: #f7f8f7;
    }
  }

  .title-text-box {
    .title {
      font-weight: 700;
      font-size: 1.3rem;
    }

    .text {
      margin-top: 0.6rem;
      font-size: 1.1rem;
    }
  }

  .amount-box {
    margin-left: auto;

    .amount {
      font-weight: 700;
      font-size: 1.25rem;
    }
  }
}
