// @import "../../breakpoints";

.payment-link-wrap-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;



  

  @media only screen and (max-width: 64em) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    overflow-x: hidden;
  }

  .mobile-table-box{
    padding: 1.5rem;

    &>*{
      transform: translateX(-1.3rem);
    }

    // &>*:first-child{
    //   background-color: green;
    // }
  }

  .left-box {
    flex: 0 0 55%;
    border-right: 0.1rem solid rgba(204, 204, 204, 0.5);
    display: flex;
    flex-direction: column;
    padding: 0rem 2rem;
    padding-top: 3rem;

    @media only screen and (max-width: 64em) {
      grid-row: 1 / -1;
      grid-column: 1 / -1;
      overflow-x: scroll;
      padding-bottom: 3rem;
    }

    .table-wrap {
      @media only screen and (max-width: 48em) {
        display: none;
      }
      // @media only screen and (max-width: 42em) {
      //  width: 60rem;
      // }

      .text-avatar-box {
        display: flex;
        align-items: center;
        gap: 1rem;

        .avatar-box {
          width: 3rem;
          height: 3rem;
          background-color: #f7f8f7;
          border: 0.1rem solid #cccccc;
          border-radius: 50%;
          display: grid;
          place-items: center;

          span {
            font-weight: 600;
            font-size: 1.2rem;
            text-transform: capitalize;
          }
        }
      }

      tbody {
        max-height: calc(100vh - 37rem);
        overflow-y: scroll;

        &::-webkit-scrollbar:horizontal {
          display: none;
        }

        /* width */
        &::-webkit-scrollbar {
          width: 0.5rem;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: none !important;
          background-color: transparent !important;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #020202;
          border-radius: 30rem;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #020202;
        }

        tr {
          cursor: pointer;

          td {
            .table-data {
              font-size: 1.1rem !important;
            }

            .status-type-wrap {
              background-color: #f7f8f7;
              border-radius: 3rem;
              width: max-content;
              padding: 0.8rem 1.2rem;
              // color: #476885;

              span {
                font-weight: 400 !important;
                font-size: 1.2rem;
              }
            }

            .status-type-wrap-fail {
              background-color: #fff5f5;
              color: #ff0f00;
            }

            .status-type-wrap-success {
              background-color: #e8fff6;
              color: #1ace37;
            }

            .status-type-wrap-pending {
              background-color: #fff6ed;
              color: #ea872d;
            }
          }
        }

        .table-row-payment-link {
          background-color: #f7f8f7;
        }
      }
    }

    .paginate-wrap {
      display: flex;
      justify-content: center;
      margin-top: auto;
    }
  }

  .table-wrap {
    @media only screen and (max-width: 48em) {
      display: none;
    }
    // @media only screen and (max-width: 42em) {
    //  width: 60rem;
    // }

    .text-avatar-box {
      display: flex;
      align-items: center;
      gap: 1rem;

      .avatar-box {
        width: 3rem;
        height: 3rem;
        background-color: #f7f8f7;
        border: 0.1rem solid #cccccc;
        border-radius: 50%;
        display: grid;
        place-items: center;

        span {
          font-weight: 600;
          font-size: 1.2rem;
          text-transform: capitalize;
        }
      }
    }

    tbody {
      max-height: calc(100vh - 37rem);
      overflow-y: scroll;

      &::-webkit-scrollbar:horizontal {
        display: none;
      }

      /* width */
      &::-webkit-scrollbar {
        width: 0.5rem;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: none !important;
        background-color: transparent !important;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #020202;
        border-radius: 30rem;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #020202;
      }

      tr {
        cursor: pointer;

        //  display: none;

        td {
          .table-data {
            font-size: 1.1rem !important;
          }

          .status-type-wrap {
            background-color: #f7f8f7;
            border-radius: 3rem;
            width: max-content;
            padding: 0.8rem 1.2rem;
            // color: #476885;

            span {
              font-weight: 400 !important;
              font-size: 1.2rem;
            }
          }

          .status-type-wrap-fail {
            background-color: #fff5f5;
            color: #ff0f00;
          }

          .status-type-wrap-success {
            background-color: #e8fff6;
            color: #1ace37;
          }

          .status-type-wrap-pending {
            background-color: #fff6ed;
            color: #ea872d;
          }

          .payroll-current-action-wrap {
            // position: relative;
            // z-index: 10;
            margin: 0rem auto;
            display: flex;
            align-items: center;
            justify-content: center;
            // background-color: red;
            gap: 1rem;
            width: 100%;

            .drop-down-box-payroll {
              position: relative;
              transform: unset !important;

              .drop-down-wrap-payroll {
                position: absolute;
                top: 120%;
                right: 2rem;
                width: max-content;
                background-color: #ffffff;
                transform: scale(0);
                transform-origin: top right;
                transition: transform 0.5s ease-in-out;
                display: flex;
                flex-direction: column;
                padding: 2rem 3rem;
                border-radius: 1.2rem;
                gap: 2.5rem;
                // box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.1);

                .row-child {
                  display: flex;
                  align-items: center;
                  gap: 1rem;

                  .img-wrap {
                    .img-box {
                      width: 2rem;
                      height: 2rem;

                      .img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                      }
                    }
                  }

                  .text {
                    font-size: 1.35rem;
                    font-weight: 500;
                  }
                }
              }

              .drop-down-wrap-payroll-up {
                top: unset;
                bottom: 120%;
              }

              .drop-down-wrap-payroll-show {
                transform: scale(1);
              }
            }

            .tooltip-hover-wrap {
              position: relative;
              z-index: 15;
              & > *:last-child {
                transform: scale(0.75);
              }
            }

            .img-box {
              width: 3.5rem;
              height: 3.5rem;
              // background-color: #f7f8f7 !important;
              border-radius: 50%;
              display: grid;
              place-items: center;
              transition: transform 0.3s ease-in-out;

              &:hover {
                transform: scale(1.05);
              }

              .img {
                width: 50%;
                height: 50%;
                object-fit: contain;
                // color:  rgba(117, 90, 226, .3);
              }
            }

            & > *:nth-child(2) {
              .img {
                width: 50%;
                height: 50%;
                object-fit: contain;
              }
            }

            .delete-box {
              background-color: #fff5f5;
            }
          }
        }
      }

      .table-row-payment-link {
        background-color: #f7f8f7;
      }

      .payment-link-table-row {
        position: relative;
        z-index: 3 !important;
      }

      .payment-link-table-row-index {
        position: relative;
        z-index: 5 !important;
      }
    }
  }

  .paginate-wrap {
    display: flex;
    justify-content: center;
    margin-top: auto;
  }

  .right-box {
    flex: 1;
    transition: all 0.4s ease-in-out;
    border: none;
    box-shadow: none;

    @media only screen and (max-width: 64em) {
      grid-row: 1 / -1;
      grid-column: 1 / -1;
    }

    .content-selected-box {
      display: flex;
      flex-direction: column;
      padding: 0rem 2rem;
      padding-top: 3rem;
      width: 100%;
      animation: moveUpAnime 0.5s ease-in-out;

      .link-clear-box {
        display: flex;
        align-items: center;
        width: 100%;

        .btn-outline-grey {
          margin-left: auto;
          border-radius: 3rem;
          padding: 0.6rem 1.3rem;
        }
      }

      .text-value-box {
        display: flex;
        flex-direction: column;
        margin-top: 2.5rem;
        gap: 3rem;

        .box {
          display: flex;
          align-items: center;
          font-size: 1.2rem;

          .value {
            margin-left: auto;
            text-align: right;
            font-weight: 600;
          }
        }
      }

      .btn-two-wrap {
        display: flex;
        align-items: center;
        margin-top: 3.5rem;
        gap: 2rem;
      }

      .launch-link-url-box {
        display: flex;
        flex-direction: column;
        padding: 1.6rem;
        background-color: #f7f8f7;
        border-radius: 0.9rem;
        border: 0.1rem solid #cccccc;
        margin-top: 2rem;

        .launch-link {
          text-decoration: none;
          margin-top: 1.5rem;

          .launch-box {
            display: flex;
            padding: 0.9rem 1.7rem;
            border-radius: 2.4rem;
            display: flex;
            align-items: center;
            // background-color: #ffffff;
            width: max-content;
            cursor: pointer;
            transition: all 0.3s;

            .img-box {
              width: 1.5rem;
              height: 1.5rem;
              margin-right: 0.5rem;
              display: grid;
              place-items: center;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            &:hover {
              transform: translateY(-0.1rem);
            }

            .text {
              font-weight: 600;
            }
          }
        }

        .link-copy-box {
          display: flex;
          align-items: center;

          .link-box {
            flex: 0 0 85%;
            display: flex;
            align-items: center;
            margin-right: auto;

            .link {
              font-size: 1.3rem;
            }

            .img-box {
              width: 1.5rem;
              height: 1.5rem;
              margin-right: 0.7rem;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          .copy-box {
            width: 3rem;
            height: 3rem;
            display: grid;
            place-items: center;

            .img-box {
              width: 3rem;
              height: 3rem;
              margin-right: 0.7rem;
              cursor: pointer;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .icon-box {
              width: 2.5rem;
              height: 2.5rem;
              background-color: #020202;
              border-radius: 50%;
              display: grid;
              place-items: center;

              .icon {
                width: 0.9rem;
                height: 0.9rem;
                fill: #ffffff;
              }
            }
          }
        }
      }
    }

    .no-content-selected {
      width: 100%;
      height: 100%;
      display: grid;
      place-items: center;

      .wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.3rem;

        .img-box {
          width: 10rem;
          height: 10rem;
          display: grid;
          place-items: center;
          margin-bottom: 1.5rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .title {
          font-weight: 700;
          font-size: 1.6rem;
          text-align: center;
        }

        .text {
          font-size: 1.3rem;
          text-align: center;
        }
      }
    }
  }

  @media only screen and (max-width: 64em) {
    .right-box-mobile {
      transform: translateX(100%);
    }

    .right-box-mobile-show {
      transform: translateX(0%);
    }
  }
}

@keyframes moveUpAnime {
  0% {
    transform: translateY(2%);
    opacity: 0.5;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.request-money-wrap-box {
  min-height: 85rem;
  width: 40rem;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 42em) {
    width: 90%;
    margin: 2rem auto;
    min-height: unset;
  }

  & > *:not(:last-child) {
    margin-bottom: 2rem;
  }

  .charge-rate-box-wrap {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    gap: 0.7rem;
    padding: 1rem 2rem;
    border-radius: 0.8rem;
    background-color: #fff6ed;

    .text {
      font-size: 1.15rem;

      span {
        color: #ea872d;
      }
    }

    .img-wrap {
      .img-box {
        width: 2.5rem;
        height: 2.5rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .edit-amount-check-wrap {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin-top: -1rem;

    .text {
      font-size: 1.3rem;
    }
  }

  .reccuring-box {
    max-height: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    width: 100%;
    margin-top: -1.5rem;
    // background-color: red;

    .expiry-group {
      position: relative;
      z-index: 20;
      .wrap {
        display: flex;
        position: relative;
        z-index: 20;
      }
    }
  }

  .reccuring-box-show {
    margin-top: unset;
    max-height: 10rem;
    // margin-bottom: 10rem;
    transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
  }



  .select-type-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;

    .box {
      padding: 1.5rem;
      border-radius: 0.8rem;
      // background-color: rgba(247, 248, 247, 0.5);
      // border: .1rem solid #cccccc;
      position: relative;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        border-radius: 0.8rem;
        border: 0.15rem solid #020202;
        visibility: hidden;
      }

      & > * {
        position: relative;
        z-index: 3;
      }

      .check-box {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        display: grid;
        place-items: center;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background-color: #020202;
        visibility: hidden;

        .icon {
          width: 0.6rem;
          height: 0.6rem;
          fill: #ffffff;
        }
      }

      .img-box {
        margin-bottom: 3rem;
        width: 2rem;
        height: 2rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .title {
        font-weight: 700;
        font-size: 1.4rem;
      }

      .text {
        font-size: 1.2rem;
        margin-top: 0.5rem;
      }
    }

    .box-active {
      border: none !important;

      &::after {
        visibility: visible;
      }

      .check-box {
        visibility: visible;
      }
    }
  }
}

.payment-link-detail-info-wrap {
  width: 100%;
  // height: 100%;
  display: flex;
  flex: 1;
  // background-color: red;
  // padding: 0rem 3rem;
  // overflow-y: scroll;

  .left-box {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 3rem;

    & > * {
      padding: 0rem 5rem 0rem 5rem;
    }

    .title {
      font-size: 1.7rem;
      font-weight: 700;
    }

    .detail-label-value-box {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 3rem;

      .box {
        display: flex;
        align-items: flex-start;
        gap: 1.5rem;

        .label {
          flex: 0 0 30%;
          font-size: 1.5rem;
        }

        .value {
          font-weight: 500;
          font-size: 1.5rem;
          opacity: 0.65;
          flex: 0 0 50%;
          // width: 65%;
        }
      }
    }

    .info-copy-wrap {
      display: flex;
      padding-bottom: 2rem;
      gap: 2rem;
      min-height: 10rem;
      align-items: center;

      .img-wrap {
        flex: 0 0 13%;
        width: 100%;
        height: 9rem;
        background-image: url("../../../assets/new-invoice-img.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      .text-link-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: space-between;

        .img-icon-link {
          display: flex;
          gap: 1.5rem;
          width: 100%;

          .copy-box {
            flex: 0 0 10%;
            display: grid;
            place-items: center;

            .img-box {
              width: 5rem;
              height: 100%;
              background-color: #ffffff;
              display: grid;
              place-items: center;
              border-radius: 0.8rem;
              cursor: pointer;

              &:hover .img {
                transform: scale(1.05);
              }

              .img {
                object-fit: contain;
                width: 50%;
                height: 50%;
                transition: all 0.3s;
              }
            }

            .icon-box {
              width: 3rem;
              height: 3rem;
              background-color: #020202 !important;
              border-radius: 50%;
              display: grid;
              place-items: center;

              .icon {
                width: 0.9rem;
                height: 0.9rem;
                fill: #ffffff;
              }
            }
          }

          .box {
            padding: 1.5rem 1.7rem;
            border-radius: 0.8rem;
            display: flex;
            align-items: center;
            flex: 1;

            .text {
              margin-right: auto;
              margin-left: 1.5rem;
              font-size: 1.3rem;
              font-weight: 400;
            }

            .img-box {
              width: 1.65rem;
              height: 1.65rem;

              .img {
                object-fit: contain;
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        .text {
          font-size: 1.25rem;

          span {
            color: #755ae2 !important;
            cursor: pointer;
            font-weight: 600;
          }
        }
      }
    }
  }

  .right-box {
    flex: 1;
    // background-color: red;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    // overflow-y: scroll;

    .transaction-info-wrap {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow-y: scroll;

      .bottom-detail-box {
        position: relative;
        z-index: 1;
        flex: 1;
        padding-bottom: 3rem;
        overflow-y: scroll;
        gap: 2rem;

        &::-webkit-scrollbar {
          display: none;
        }

        .trans-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 1.5rem 4rem 1.5rem 2rem;

          .avatar-box {
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: .1rem;

            span {
              font-weight: 600;
              display: inline-block;
              font-size: 1.65rem;
              text-transform: uppercase;
            }
          }

          .name-email-box {
            display: flex;
            flex-direction: column;
            margin-right: auto;
            margin-left: 1.2rem;
            gap: 0.2rem;

            .name {
              font-size: 1.6rem;
              font-weight: 600;
            }

            .email {
              font-size: 1.3rem;
            }
          }

          .date-amount-box {
            text-align: right;

            .date {
              font-size: 1.2rem;
              font-weight: 500;
            }

            .amount {
              font-size: 1.55rem;
              color: #1ace37;
              font-weight: 500;
            }

         
          }
        }
      }

      .top-box {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        padding-left: 4rem;
        padding-right: 4rem;
        position: relative;
        z-index: 3;

        .filter-drop-box {
          .drop-box-wrap {
            position: absolute;
            border-radius: 1.2rem;
            top: 110%;
            right: 4rem;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            min-width: 30rem;
            gap: 2rem;
            animation: moveDownSettingDrop 0.2s ease-in-out backwards;
          }

          .filter-box {
            height: 100%;
            display: flex;
            flex-direction: row;
            gap: 0.8rem;
            align-items: center;
            padding: 1rem 1.5rem;
            border-radius: 0.8rem;
            cursor: pointer;

            .line-box {
              margin: auto 0rem;
              display: flex;
              flex-direction: column;
              gap: 0.25rem;
              width: 1.8rem;
              align-items: center;
              // align-self: center;

              span {
                display: inline-block;
                height: 0.15rem;
                width: 100%;
                border-radius: 1rem;
                background-color: #676767;
              }

              & > *:nth-child(2) {
                width: 70%;
              }

              & > *:last-child {
                width: 40%;
              }
            }

            .text {
              font-weight: 700;
            }
          }
        }

        .title-text-box {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .label-text {
            font-size: 1.25rem;
          }

          .title-box {
            display: flex;
            align-items: center;
            gap: 1rem;

            .title {
              font-size: 1.85rem;
              font-weight: 700;
            }

            .num {
              border-radius: 1.2rem;
              padding: 0.8rem 1rem;
              display: grid;
              place-items: center;

              span {
                font-weight: 700;
                font-size: 1.1rem;
                display: inline-block;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}

.payment-link-test-modal-wrap {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  // display: none;

  &::after {
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(3px);
  }

  .content-box {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.share-payment-link-wrap {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-bottom: 2rem;

  .select-type-wrap {
    max-height: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
    -webkit-transition: max-height 0.8s;
    -moz-transition: max-height 0.8s;
    width: 100%;
  }

  .select-type-wrap-show {
    margin-top: 2.5rem;
    overflow-x: visible;
    overflow-y: visible;
    max-height: 15rem;
    transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
  }

  .share-box-wrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: -0.5rem;
    margin-bottom: 2rem;

    .text {
      font-size: 1.2rem;
    }

    .box {
      padding: 1.5rem 1.7rem;
      border-radius: 0.8rem;
      display: flex;
      align-items: center;
      flex: 1;

      .text {
        margin-right: auto;
        margin-left: 1.5rem;
        font-size: 1.3rem;
        font-weight: 400;
      }

      .img-box {
        width: 1.65rem;
        height: 1.65rem;

        .img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .title-text-box {
    .title {
      font-size: 1.7rem;
      font-weight: 700;
    }

    .text {
      font-size: 1.25rem;
      width: 85%;
    }
  }

  .share-box-wrap {
    display: flex;
    flex-direction: column;
  }
}

.new-payment-link-table-filter-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 2rem;
  gap: 2rem;

  @media only screen and (max-width: 48em){
    // display: none;
    width: 95%;
    justify-content: center;
    align-items: center;
    margin: 0rem auto;
  }

  .export-table-content-box {
    display: grid;
    place-items: center;
    padding: 0.5rem 1.5rem;
    border-radius: 0.8rem;
    cursor: pointer;
    // margin-left: -2rem;

    &:hover .img-box {
      transform: translateY(0.4rem);
    }

    .img-box {
      width: 2.2rem;
      height: 2.2rem;
      transition: all 0.3s;

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .badge-filter-option-box {
    display: flex;
    align-items: center;
    gap: 2rem;

    .option-box {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1rem 1.5rem;
      border-radius: 2rem;

      .icon {
        cursor: pointer;
        transform: translateY(0.1rem);
      }
    }
  }
}

.payment-link-new-search-filter-chart-box {
  display: flex;
  align-items: center;
  padding: 0rem 3rem;
  // margin: 2rem 0rem;
  position: relative;
  z-index: 20;
  gap: 1.5rem;
  width: 100%;

  @media only screen and (max-width: 48em){
   padding: 0rem 2rem;
  }

  .search-filter-box {
    display: flex;
    align-items: stretch;
    // gap: 2rem;
    position: relative;
    z-index: 100;
    width: 100%;

    @media only screen and (max-width: 48em){
      flex-wrap: wrap;
      gap: 1.5rem;
    }

    .filter-drop-box {
      position: relative;
      // margin-left: auto;

      .drop-box-wrap {
        position: absolute;
        border-radius: 1.2rem;
        top: 110%;
        right: 0;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        min-width: 30rem;
        gap: 2rem;
        animation: moveDownSettingDrop 0.2s ease-in-out backwards;
      }

      .filter-box {
        height: 100%;
        display: flex;
        flex-direction: row;
        gap: 0.8rem;
        align-items: center;
        padding: 1rem 1.5rem;
        border-radius: 0.8rem;
        cursor: pointer;

        .line-box {
          margin: auto 0rem;
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
          width: 1.8rem;
          align-items: center;
          // align-self: center;

          span {
            display: inline-block;
            height: 0.15rem;
            width: 100%;
            border-radius: 1rem;
            background-color: #676767;
          }

          & > *:nth-child(2) {
            width: 70%;
          }

          & > *:last-child {
            width: 40%;
          }
        }

        .text {
          font-weight: 700;
        }
      }
    }
  }
}
