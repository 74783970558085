.verification-layout-version-one-five-wrap-index {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 48em) {
  .verification-layout-version-one-five-wrap-index {
    overflow-x: hidden;
  }
}
.verification-layout-version-one-five-wrap-index .header-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 3rem;
  border-width: 0.15rem !important;
}
.verification-layout-version-one-five-wrap-index .header-box .logo-box {
  margin-right: auto;
}
@media only screen and (max-width: 42em) {
  .verification-layout-version-one-five-wrap-index .header-box .logo-box {
    margin-left: -2rem;
  }
}
.verification-layout-version-one-five-wrap-index .header-box .logo-box .img-box {
  width: 12rem;
  height: 2.5rem;
}
.verification-layout-version-one-five-wrap-index .header-box .logo-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.verification-layout-version-one-five-wrap-index .header-box .skip-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.verification-layout-version-one-five-wrap-index .header-box .skip-box:hover .icon {
  transform: translateX(0.1rem);
}
.verification-layout-version-one-five-wrap-index .header-box .skip-box .text {
  margin-right: 0.4rem;
}
.verification-layout-version-one-five-wrap-index .header-box .skip-box .icon {
  width: 1.5rem;
  height: 1.5rem;
  transition: all 0.3s ease-in-out;
}
.verification-layout-version-one-five-wrap-index .body-content-box {
  flex: 1;
  display: grid;
  place-items: center;
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .title {
  font-size: 2rem;
  font-weight: 600;
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box {
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  margin: 2rem 0rem;
  border-width: 0.15rem;
  min-width: 60rem;
}
@media only screen and (max-width: 48em) {
  .verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box {
    min-width: unset;
    width: 95%;
  }
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box > *:last-child {
  border: unset !important;
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box .item-tier-row {
  cursor: pointer;
  border-width: 0.15rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 2.5rem;
  width: 100%;
}
@media only screen and (max-width: 48em) {
  .verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box .item-tier-row {
    padding: 2.5rem 1.5rem;
  }
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box .item-tier-row:hover .right-arrow {
  transform: translateX(0.15rem);
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box .item-tier-row .right-arrow {
  transition: transform 0.3s ease-in-out;
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box .item-tier-row .right-arrow .img-box {
  width: 2rem;
  height: 2rem;
}
@media only screen and (max-width: 48em) {
  .verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box .item-tier-row .right-arrow .img-box {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box .item-tier-row .right-arrow .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box .item-tier-row .img-wrap-box .img-box {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box .item-tier-row .img-wrap-box .img-box .img {
  width: 55%;
  height: 55%;
  -o-object-fit: contain;
     object-fit: contain;
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box .item-tier-row .img-wrap-box-shift .img-box .img {
  transform: translateX(-0.15rem);
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box .item-tier-row .title-text-box {
  margin-right: auto;
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box .item-tier-row .title-text-box .title {
  font-size: 1.65rem;
  font-weight: 600;
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box .item-tier-row .title-text-box .text {
  font-size: 1.25rem;
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box .tooltip-hover-wrap .right-arrow,
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box .tooltip-hover-wrap .img-wrap-box,
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .tier-select-wrap-box .tooltip-hover-wrap .title-text-box {
  opacity: 0.35;
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .bottom-text-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
@media only screen and (max-width: 48em) {
  .verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .bottom-text-box {
    width: 95%;
  }
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .bottom-text-box .text {
  font-size: 1.45rem;
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .bottom-text-box .img-veri-box .img-box {
  width: 2rem;
  height: 2rem;
}
.verification-layout-version-one-five-wrap-index .body-content-box .middle-veri-content-box .bottom-text-box .img-veri-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=VerificationLayoutOneFive.css.map */