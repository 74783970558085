.global-index-wrap-box {
  flex: 1;
  // background-color: green;
  display: flex;
  flex-direction: column;
  //   padding-bottom: 15rem;

  .content-create-box-wrap {
    width: 47rem;
    flex: 1;
    // background-color: red;
    align-self: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 10rem;

    @media only screen and (max-width: 48em){
      width: 95%;
      }

    .mascot-img-box {
      margin-top: 4rem;
      height: 25rem;
      width: 100%;
      background-image: url("../../../assets/global-content-bg.png");
      background-position: 2.5rem center;
      background-repeat: no-repeat;
      background-size: 100%;
      margin-bottom: -6rem;
    }

    .content-wrap-global-box {
      //   min-height: 30rem;
      border-radius: 1.2rem;
      border-width: 0.15rem;
      padding: 3rem 2rem;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;

      & > *:not(:last-child) {
        padding-bottom: 2.5rem;
      }

      .description-content-box {
        display: flex;
        flex-direction: column;
        border-width: 0.15rem !important;

        .descriptio-box {
          display: flex;
          align-items: center;
          gap: 1rem;
          cursor: pointer;

          .title-status-box {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
              font-weight: 600;
              font-size: 1.5rem;
              line-height: 2rem;
            }

            .status-box {
              background-color: rgba(255, 246, 237, 1);
              padding: 0.7rem 1.5rem;
              border-radius: 3rem;

              .status {
                font-size: 1rem;
                span {
                  font-weight: 700;
                  color: rgba(234, 135, 45, 1) !important;
                }

                // .pending {
                //   color: rgba(234, 135, 45, 1) !important;
                // }

                // .verify {
                //   color: rgba(26, 206, 55, 1) !important;
                // }
              }
            }

            .status-box-success {
              background-color: rgba(232, 255, 246, 1);

              .status {
                span {
                  color: rgba(26, 206, 55, 1) !important;
                }
              }
            }
          }

          .img-icon-box {
            display: grid;
            place-items: center;
            .img-box {
              width: 3.5rem;
              height: 3.5rem;
              display: grid;
              place-items: center;
              border-radius: 50%;
              border-width: 0.15rem;

              .img {
                width: 50%;
                height: 50%;
                object-fit: contain;
              }
            }
          }

          .right-icon {
            margin-left: auto;
            transition: transform 0.3s ease-in-out;

            .img-box {
              width: 2rem;
              height: 2rem;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          .right-icon-down {
            transform: rotate(90deg);
          }
        }

        .content-hide-box {
          // min-height: 20rem;
          //   background-color: red;
          max-height: 0;
          overflow-x: hidden;
          overflow-y: hidden;
          transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
          -webkit-transition: max-height 0.3s;
          -moz-transition: max-height 0.3s;
          width: 100%;

          .currency-to-select-box {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            align-items: center;
            padding-top: 1.5rem;

            .cur-item {
              width: max-content;
              padding: 0.55rem 1.1rem;
              border-radius: 3rem;
              display: flex;
              align-items: center;
              gap: 1.2rem;
              justify-content: space-between;
              border-width: 0.15rem;
              cursor: pointer;
              padding-left: 0.7rem;
              transition: transform 0.3s ease-in-out;

              &:hover {
                transform: translateY(-0.1rem);
              }

              .img-select {
                .img-box {
                  width: 2.2rem;
                  height: 2.2rem;

                  .img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }
              }
            }

            .cur-item-active {
              background-color: rgba(240, 238, 255, 1);
              border-color: rgba(209, 198, 244, 1);
            }
          }

          .down-text {
            font-size: 1.3rem;
            font-style: italic;
            margin-top: 2rem;
          }

          .content-hide-box-verification {
            display: flex;
            align-items: center;
            gap: 1.5rem;
            flex-wrap: wrap;

            .box-value {
              padding: 1rem 2rem;
              border-radius: 5rem;
              background-color: rgba(255, 246, 237, 1);
              cursor: pointer;
              transition: all 0.3s ease-in-out;

              &:hover {
                transform: translateY(-0.1rem);
              }

              .value {
                font-weight: 500;
                color: rgba(234, 135, 45, 1);
              }
            }

            .box-value-green {
              background-color: rgba(232, 255, 246, 1);

              .value {
                color: rgba(26, 206, 55, 1);
              }
            }
          }

          .term-content-box-wrap {
            display: flex;
            flex-direction: column;

            .text-term-box {
              .text {
                font-size: 1.4rem;
                text-align: justify;
                line-height: 2.4rem;
              }
            }

            .agree-checkbox-wrap {
              display: flex;
              align-items: center;
              gap: 1.5rem;
              margin-top: 2rem;

              .text {
                font-size: 1.35rem;
                line-height: 2.4rem;
                text-align: left;
              }
            }
          }
        }

        .content-hide-box-show {
          //   height: 30rem;
          margin-top: 2.5rem;
          max-height: 100rem;
          transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
          padding-bottom: 2.5rem;
          border-width: 0.15rem;
        }
      }
    }
  }

  .veri-complete-profile-content-wrap-box {
    flex: 1;
    // background-color: red;
    display: flex;
    // gap: 3rem;

    & > * {
      padding: 2.5rem;
    }

    .left-veri-content-box {
      flex: 1;
      //   padding-right: 3rem;
      //   border-width: 0.15rem !important;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      .view-exchange-amount-show-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        // background-color: red;
        min-height: 9rem;

        @media only screen and (max-width: 48em){
         flex-wrap: wrap;
         gap: 2rem;
         }

        .total-amount-show-balance {
          display: flex;
          flex-direction: column;

          .total-amount {
            display: flex;
            align-items: center;
            width: max-content;
            gap: 1rem;

            .amount {
              font-size: 3.5rem;
              letter-spacing: -0.1rem;
              font-weight: 700;
            }
          }

          .show-balance-box {
            display: flex;
            align-items: center;
            gap: 1rem;
            cursor: pointer;
            width: max-content;

            .text {
              font-size: 1.6rem;
            }

            .eye-icon {
              .img-box {
                width: 2.5rem;
                height: 2.5rem;

                .img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }
        }

        .view-exchange-box {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.8rem 1.4rem;
          border-radius: 3.5rem;
          cursor: pointer;
          transition: transform 0.3s ease-in-out;

          @media only screen and (max-width: 48em){
           margin-left: auto;
           }

          &:hover {
            transform: translateY(-0.1rem);
          }

          .text {
            font-size: 1.35rem;
            // font-weight: 600;
          }

          .icon-box {
            .img-box {
              width: 2rem;
              height: 2rem;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }

      .currency-created-available-box {
        display: grid;
        // flex: 1;
        margin-top: 2rem;
        height: calc(100vh - 36rem);
        // grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        grid-template-columns: repeat(auto-fit, minmax(27rem, 35rem));
        grid-gap: 2.5rem;
        align-items: flex-start;
        grid-template-rows: max-content;
        // background-color: red;
        overflow-y: scroll;
        padding-bottom: 3rem;

        &::-webkit-scrollbar {
          display: none;
        }

        .item-global-box {
          padding: 2rem 2rem 1rem 2rem;
          border-radius: 1.2rem;
          border-width: 0.15rem !important;
          display: flex;
          flex-direction: column;
          height: max-content;
          cursor: pointer;
          border-width: 0.2rem !important;
          //   border: ;

          //   &:hover{
          //     box-shadow: ;
          //   }

          .cur-img-box {
            margin-bottom: 5rem;

            .img-box {
              width: 3rem;
              height: 3rem;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          .name {
            font-size: 1.45rem;
          }

          .amount {
            font-size: 2.1rem;
            font-weight: 700;
            line-height: 3rem;
          }
        }
      }

      .currency-created-available-box-many {
        grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
      }
    }

    .right-veri-content-box {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      display: none;

      .today-rate-box-wrap {
        width: 80%;
        min-height: 45rem;
        // background-color: red;
        margin-top: 4rem;
        border-radius: 1.2rem;
        border-width: 0.15rem;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        max-height: calc(100vh - 30rem);

        .middle-content-rate-box {
          flex: 1;
          // background-color: red;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;
          padding: 2rem 0rem;

          &::-webkit-scrollbar {
            display: none;
          }

          & > *:not(:last-child) {
            padding-bottom: 2rem;
            margin-bottom: 2rem;
            border-width: 0.15rem;
          }

          & > *:last-child {
            border: none !important;
          }

          .from-to-rate-box-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 2rem;
            padding-right: 2rem;

            .from-box,
            .to-box {
              display: flex;
              flex-direction: column;
              gap: 1rem;

              .value {
                font-size: 1.6rem;
              }

              .label-box {
                display: flex;
                align-items: center;
                gap: 0.7rem;

                .label-text {
                  font-weight: 600;
                  font-size: 1.6rem;
                }

                .icon-wrap {
                  display: grid;
                  place-items: center;

                  .img-box {
                    width: 2rem;
                    height: 2rem;

                    .img {
                      width: 100%;
                      height: 100%;
                      object-fit: contain;
                    }
                  }
                }
              }
            }

            .to-box {
              .value {
                text-align: right;
              }
            }
          }
        }

        .bottom-text-box-wrap {
          padding: 1.5rem 1.5rem;
          display: flex;
          align-items: center;
          //   justify-content: center;
          border-width: 0.15rem;
          margin-top: auto;

          .text {
            font-weight: 600;
            font-size: 1.5rem;
          }
        }

        .title-select-drop-box {
          padding: 1rem 1.5rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-width: 0.15rem;

          .title {
            font-size: 1.6rem;
            font-weight: 600;
          }

          .select-drop-box-wrapper {
            position: relative;

            .select-drop-box {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              padding: 1rem 1.5rem;
              border-radius: 5rem;

              .value {
                font-weight: 500;
              }

              .img-select {
                .img-box {
                  width: 1.7rem;
                  height: 1.7rem;

                  .img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }
              }

              .drop-icon {
                .img-box {
                  width: 1.7rem;
                  height: 1.7rem;

                  .img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
