.new-account-drop-down-index-wrap-subs-wrap .add-new-account {
  position: absolute;
  top: 1rem;
  right: 2rem;
  padding: 1rem 2rem;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.new-account-drop-down-index-wrap-subs-wrap .add-new-account:hover {
  transform: translateY(-0.12rem);
}
.new-account-drop-down-index-wrap-subs-wrap .add-new-account .text {
  font-weight: 600;
}
.new-account-drop-down-index-wrap-subs-wrap .add-new-account .img-icon .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.new-account-drop-down-index-wrap-subs-wrap .add-new-account .img-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap {
  width: 50rem;
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap .content {
  padding: 1rem 0rem;
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap .content .new-account-drop-down-index-wrap-subs {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-width: 0.15rem;
  padding: 1.5rem 0rem;
  position: relative;
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap .content .new-account-drop-down-index-wrap-subs > *:not(:last-child) {
  padding-bottom: 1.5rem;
  border-width: 0.15rem;
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap .content .new-account-drop-down-index-wrap-subs .item-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
  cursor: pointer;
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap .content .new-account-drop-down-index-wrap-subs .item-row .primary-box {
  background-color: rgb(240, 238, 255);
  padding: 0.6rem 1rem;
  border-radius: 2rem;
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap .content .new-account-drop-down-index-wrap-subs .item-row .primary-box .text {
  font-weight: 500;
  color: rgb(117, 90, 226);
  font-size: 1.2rem;
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap .content .new-account-drop-down-index-wrap-subs .item-row .account-copy-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  gap: 0.4rem;
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap .content .new-account-drop-down-index-wrap-subs .item-row .account-copy-details .copy-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.7rem 1.4rem;
  border-radius: 0.4rem;
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap .content .new-account-drop-down-index-wrap-subs .item-row .account-copy-details .copy-box .text {
  font-size: 1.25rem;
  transform: translateY(0.15rem);
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap .content .new-account-drop-down-index-wrap-subs .item-row .account-copy-details .copy-box .copy-icon {
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap .content .new-account-drop-down-index-wrap-subs .item-row .account-copy-details .copy-box .copy-icon .icon-box {
  width: 90%;
  height: 90%;
  display: grid;
  place-items: center;
  border-radius: 50%;
  position: relative;
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap .content .new-account-drop-down-index-wrap-subs .item-row .account-copy-details .copy-box .copy-icon .icon-box .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  color: #ffffff;
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap .content .new-account-drop-down-index-wrap-subs .item-row .account-copy-details .copy-box .copy-icon .img-box {
  width: 100%;
  height: 100%;
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap .content .new-account-drop-down-index-wrap-subs .item-row .account-copy-details .copy-box .copy-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap .content .new-account-drop-down-index-wrap-subs .item-row .bank-details {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap .content .new-account-drop-down-index-wrap-subs .item-row .bank-details .account-name {
  font-weight: 600;
  font-size: 1.55rem;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap .content .new-account-drop-down-index-wrap-subs .item-row .bank-details .account-name .check-box {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.new-account-drop-down-index-wrap-subs-wrap .raven-modal-content-wrap .content .new-account-drop-down-index-wrap-subs .item-row .bank-details .account-name .check-box .icon {
  width: 1rem;
  height: 1rem;
}/*# sourceMappingURL=NewAccountsDropDownModal.css.map */