.upload-business-custom-two-type-wrapper {
  position: relative;

  .file-text-box {
    display: flex;
    align-items: center;
    gap: 1rem;

    .text{
        margin-top: 1rem;
    }

    .file-box {
      width: 5rem;
      height: 5rem;
        // background-color: red;

      .img-box {
        width: 100%;
        height: 100%;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          display: inline-block !important;
        }
      }
    }
  }
}
