.dashboard-layout-wrap {
  width: 100vw;
  height: 100vh;
  position: relative;
}
@media only screen and (min-width: 110em) {
  .dashboard-layout-wrap {
    width: 100% !important;
  }
}
.dashboard-layout-wrap .mobile-blur-bg {
  position: fixed;
  z-index: 8;
  width: 100vw;
  height: 100vh;
}
@media only screen and (min-width: 56em) {
  .dashboard-layout-wrap .mobile-blur-bg {
    display: none;
  }
}
.dashboard-layout-wrap .mobile-blur-bg::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #020202;
  opacity: 0.4;
}
.dashboard-layout-wrap .chat-box {
  position: fixed !important;
  bottom: 3rem;
  right: 4rem;
  z-index: 7;
}
.dashboard-layout-wrap .chat-box .img-box {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}
.dashboard-layout-wrap .chat-box .img-box .img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  transform: scale(2);
  transition: all 0.3s;
  cursor: pointer;
}
.dashboard-layout-wrap .chat-box .img-box .img:hover {
  transform: scale(2) translateY(-0.1rem);
}
.dashboard-layout-wrap .sidebar-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 25rem;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
@media only screen and (max-width: 65em) {
  .dashboard-layout-wrap .sidebar-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 7rem;
    overflow-x: hidden;
    z-index: 4;
    padding-top: 0.5rem;
  }
}
@media only screen and (max-width: 56em) {
  .dashboard-layout-wrap .sidebar-wrap {
    width: 27.5rem;
    transform: translateX(-100%);
    height: 100vh;
    position: fixed;
    transition: all 0.4s ease-in-out;
    z-index: 10;
    padding: 0rem;
  }
}
@media only screen and (max-width: 56em) {
  .dashboard-layout-wrap .sidebar-wrap::-webkit-scrollbar {
    display: none;
  }
}
.dashboard-layout-wrap .sidebar-wrap::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 42em) {
  .dashboard-layout-wrap .sidebar-wrap {
    overflow-y: scroll;
    display: unset;
    overflow-x: hidden;
  }
  .dashboard-layout-wrap .sidebar-wrap > * {
    height: 100vh;
    padding-bottom: 4rem;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
.dashboard-layout-wrap .sidebar-wrap:hover::-webkit-scrollbar {
  display: block;
}
.dashboard-layout-wrap .sidebar-wrap::-webkit-scrollbar {
  width: 0.5rem;
}
.dashboard-layout-wrap .sidebar-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.dashboard-layout-wrap .sidebar-wrap::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 30rem;
}
.dashboard-layout-wrap .sidebar-wrap::-webkit-scrollbar-thumb {
  background: #cccccc;
}
@media only screen and (max-width: 56em) {
  .dashboard-layout-wrap .sidebar-wrap-show {
    transform: translateX(0%);
  }
}
.dashboard-layout-wrap .main-body-wrap {
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - 25rem);
  height: 100vh;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 110em) {
  .dashboard-layout-wrap .main-body-wrap {
    width: calc(100% - 25rem) !important;
    right: 0;
  }
}
@media only screen and (max-width: 65em) {
  .dashboard-layout-wrap .main-body-wrap {
    width: calc(100% - 7rem);
  }
}
@media only screen and (max-width: 56em) {
  .dashboard-layout-wrap .main-body-wrap {
    width: 100%;
    height: unset;
  }
}
.dashboard-layout-wrap .main-body-wrap .action-bar-wrapper-box .action-bar-set-pin-info {
  padding-left: 5rem;
  padding-right: 5rem;
  align-items: center;
}
.dashboard-layout-wrap .main-body-wrap .action-bar-wrapper-box .action-bar-set-pin-info .img-box {
  transform: translateY(-0.05rem);
}
.dashboard-layout-wrap .main-body-wrap .header-wrap {
  position: relative;
  z-index: 3;
  padding: 1rem 5rem;
}
@media only screen and (min-width: 110em) {
  .dashboard-layout-wrap .main-body-wrap .header-wrap {
    padding: 1rem 7rem;
  }
}
@media only screen and (max-width: 42em) {
  .dashboard-layout-wrap .main-body-wrap .header-wrap {
    padding: 1rem 2.5rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
  }
}
.dashboard-layout-wrap .main-body-wrap .main-wrap {
  flex: 1;
  overflow-y: scroll;
  position: relative;
  z-index: 2;
  padding: 2.5rem 5rem;
  display: flex;
  flex-direction: column;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
@media only screen and (min-width: 110em) {
  .dashboard-layout-wrap .main-body-wrap .main-wrap {
    width: 135rem !important;
    padding: 7rem;
  }
}
@media only screen and (max-width: 58em) {
  .dashboard-layout-wrap .main-body-wrap .main-wrap {
    flex: unset;
  }
}
@media only screen and (max-width: 42em) {
  .dashboard-layout-wrap .main-body-wrap .main-wrap {
    padding: 2.5rem;
    padding-top: 10rem;
    height: -moz-max-content;
    height: max-content;
  }
}
.dashboard-layout-wrap .main-body-wrap .main-wrap::-webkit-scrollbar:horizontal {
  display: none;
}
.dashboard-layout-wrap .main-body-wrap .main-wrap::-webkit-scrollbar {
  width: 0.5rem;
}
.dashboard-layout-wrap .main-body-wrap .main-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.dashboard-layout-wrap .main-body-wrap .main-wrap::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.dashboard-layout-wrap .main-body-wrap .main-wrap::-webkit-scrollbar-thumb:hover {
  background: #020202;
}/*# sourceMappingURL=DashboardLayout.css.map */