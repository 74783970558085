.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .close-box {
  display: none;
}

.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap {
  width: 100%;
  padding-bottom: 10rem;
  margin-top: 3rem;
}
.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap .account-change-drop-wrap {
  margin-top: 0.5rem;
}
.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap .description-box-wrap {
  padding: 2rem 1.5rem;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap .description-box-wrap .icon-subtitle {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  gap: 1rem;
}
.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap .description-box-wrap .icon-subtitle .img-wrap {
  display: grid;
  place-items: center;
}
.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap .description-box-wrap .icon-subtitle .img-wrap .img-box-select {
  width: 2rem;
  height: 2rem;
}
.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap .description-box-wrap .icon-subtitle .img-wrap .img-box-select .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap .description-box-wrap .icon-subtitle .sub-title {
  font-size: 1.35rem;
  text-transform: uppercase;
  transform: translateY(-0.25rem);
}
.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap .description-box-wrap .list-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap .description-box-wrap .list-box .list-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap .description-box-wrap .list-box .list-item .black-fade-white-bg {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  display: inline-block;
}

.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap .title-card {
  font-weight: 700;
  font-size: 1.9rem;
  margin-bottom: 1.5rem;
}

.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap .text {
  font-size: 1.45rem;
  width: 90%;
}

.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap .pin-test {
  margin-bottom: 2.5rem;
}

.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap .pin-test span {
  color: #020202;
  font-weight: 600;
}

.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .two-button-wrap {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #f7f8f7;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5rem 2rem;
  gap: 2rem;
}

.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .two-button-wrap .cancel {
  cursor: pointer;
  font-size: 1.5rem;
} /*# sourceMappingURL=Modal.css.map */