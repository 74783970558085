.alert-receiver-modal-wrap-index {
  display: flex;
  flex-direction: column;
  min-height: 35rem;
  // max-height: 50rem;
  max-height: 60rem;

  .loading-wrap {
    width: 100%;
    height: 35rem;
    // height: 60rem;
    display: grid;
    place-items: center;

    .img-box {
      width: 50%;
      height: 50%;

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .title-text-box {
    margin-top: -1rem;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    margin-bottom: 1rem;

    .title {
      font-weight: 600;
      font-size: 2.3rem;
    }

    .text {
      color: rgba(103, 103, 103, 1);
    }
  }
  .search-box-wrap {
    margin-top: 2rem;
  }
  .receiver-list-box {
    margin-top: 2rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding-bottom: 3rem;
    gap: 2.5rem;

    .flier-box-wrap {
      height: 35rem;
      background-color: #020202;
      border-radius: 1.2rem;

      .img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: inherit;
      }
    }

    .amount-show-box {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .text-amount- {
        font-size: 1.25rem;
        // font-weight: 600;
        // color: #333333;
        span {
          color: unset !important;
          // font-weight: 600;
        }
      }

      .img-box {
        width: 1.5rem;
        height: 1.5rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .settle-ment-account-wrap {
      display: flex;
      flex-direction: column;

      .label {
        margin-bottom: 0.4rem;
        font-size: 1.3rem;
        color: #020202;
      }

      .settle-ment-account-box {
        padding: 1.5rem;
        border-radius: 1.2rem;
        display: flex;
        align-items: center;
        gap: 1.5rem;

        .name-account-num-box {
          display: flex;
          flex-direction: column;
          gap: 0.3rem;
          .name {
            font-size: 1.35rem;
          }
          .acc-num {
            font-size: 2rem;
            font-weight: 700;
          }
        }

        .icon-wrap-box {
          .icon-wrap {
            width: 4.5rem;
            height: 4.5rem;
            border-radius: 50%;
            border-width: 0.15rem;

            .img-box {
              width: 100%;
              height: 100%;

              .img {
                object-fit: contain;
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }

    .no-alert-to-view-box {
      // width: 100%;
      // height: 100%;
      // background-color: red;
      display: grid;
      place-items: center;
      flex: 1;

      p {
        text-align: center;
        font-size: 1.5rem;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .form-group {
      .form-label {
        margin-bottom: 0.6rem;
        font-size: 1.3rem;
        color: #020202;
        text-transform: capitalize;
      }

      .select-option-list-box {
        display: flex;
        align-items: center;
        gap: 2rem;

        .item-select {
          display: flex;
          align-items: center;
          gap: 0.7rem;
          cursor: pointer !important;

          .name {
            font-size: 1.25rem;
            cursor: pointer;
          }
        }
      }
    }

    .item-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 1.6rem;
      border-width: 0.15rem;
      padding: 2rem 1.5rem;

      .delete-box {
        background-color: #fff5f5;
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        display: grid;
        place-items: center;
        cursor: pointer;

        .img-box {
          width: 55%;
          height: 55%;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .name-num-box {
        display: flex;
        flex-direction: column;
        grid-gap: 0.4rem;

        .name {
          font-weight: 600;
          font-size: 1.6rem;
          text-transform: capitalize;
        }

        .number {
          font-size: 1.25rem;
        }
      }
    }

    .note-box-wrap {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 2rem 1.5rem;
      background-color: rgba(255, 246, 237, 1);
      border-radius: 0.8rem;
      margin-bottom: 1rem;
      border-color: rgba(234, 135, 45, 1);
      // border-width: .15xrem;

      .img-box {
        width: 2.5rem;
        height: 2.5rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .text {
        font-size: 1.4rem;
        // color: rgba(234, 135, 45, 1);
      }
    }
  }
}

.edit-single-terminal-label-wrapper-index-box {
  display: flex;
  flex-direction: column;
  min-height: 35rem;
  // max-height: 50rem;

  .title-text-box {
    margin-top: -1rem;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    margin-bottom: 1rem;
    border-width: 0.15rem;
    padding-bottom: 1.5rem;

    .title {
      font-weight: 600;
      font-size: 2.3rem;
    }

    .text {
      color: rgba(103, 103, 103, 1);
      font-size: 1.3rem;
    }
  }

  .form-box {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    margin-top: 3rem;

    .two-factor-box-wrap {
      display: flex;
      flex-direction: column;
      // margin-top: 3rem;
      gap: 2rem;

      .text-line {
        display: grid;
        grid-template-columns: 1fr min-content 1fr;
        align-items: center;
        width: 100%;
        display: none;

        .text {
          color: #676767;
          background-color: #f7f8f7;
          font-size: 1.1rem;
          border: 0.1rem solid #cccccc;
          padding: 0.5rem 1.1rem;
          border-radius: 10rem;
          font-weight: 600;
          width: max-content;
          text-align: center;
          text-transform: uppercase;
        }

        span {
          height: 0.1rem;
          background-color: rgba(204, 204, 204, 0.5);
        }
      }
    }
  }

  .flier-box-wrap {
    height: 50rem;
    background-color: #020202;
    border-radius: 1.2rem;
    margin-bottom: 7rem;

    .img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }
  }

  .two-button-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 5rem;
    padding: 1rem 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;

    & > * {
      width: 100%;
    }

    .btn-white {
      color: #020202 !important;
    }
  }
}
