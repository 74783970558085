.step-box-wrap {
  display: grid;
  grid-gap: 1rem;
  position: relative;
}
.step-box-wrap::after {
  content: "";
  position: absolute;
  width: 40%;
  top: 1.7rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  height: 0.1rem;
  background-color: rgba(47, 19, 67, 0.1);
}
.step-box-wrap .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 3;
}
.step-box-wrap .box .num {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background-color: rgba(189, 189, 189, 0.2);
  display: grid;
  place-items: center;
  cursor: pointer;
}
.step-box-wrap .box .num span {
  color: rgba(22, 45, 76, 0.4);
  font-size: 1.2rem;
}
.step-box-wrap .box .text {
  margin-top: 0.1rem;
  color: #bdbdbd;
  font-size: 1.2rem;
}
.step-box-wrap .box-active .num {
  background-color: #333333;
}
.step-box-wrap .box-active .num span {
  color: #ffffff;
  font-weight: 600;
}
.step-box-wrap .box-active .text {
  color: #020202;
}/*# sourceMappingURL=StepBox.css.map */