.single-sub-account-detail-wrap {
  flex: 1;
  //  background-color: red;
  display: flex;
  //   padding: 0rem 3rem;

  & > * {
    padding: 2rem 5rem;
    padding-top: 5rem;
  }

  .left-detail-box {
    flex: 0 0 45%;
    display: flex;
    flex-direction: column;
    // padding-right: 2rem;
    // margin-right: 5rem;
    border-width: 0.2rem;
    gap: 2rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .card-detail-top-box {
      width: 100%;
      min-height: 19rem;
      border-radius: 1.2rem;
      background-image: url("../../../assets/black-gradient-bg.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 1.5rem;
      margin-bottom: 2rem;

      & > * {
        position: relative;
        z-index: 4;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 1.2rem;
        background-image: url("../../../assets/overview-available-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        visibility: hidden;
        z-index: 1;
      }

      .bank-number-name-box {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        margin-bottom: auto;

        .name-bank-box {
          display: flex;
          align-items: center;
          font-size: 1.25rem;
          color: #ffffff;
          // opacity: .8;
        }

        .number {
          font-weight: 600;
          font-size: 2.2rem;
          color: #ffffff;
        }
      }

      .account-name-box {
        .text {
          font-size: 1.45rem;
          color: #ffffff;
          text-transform: uppercase;
          letter-spacing: 0.16rem;
        }
      }
    }

    .role-to-attach-box {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;

      .title {
        font-size: 1.35rem;
      }

      .role-select-box {
        padding: 2rem;
        border-radius: 1.2rem;
        display: flex;
        align-items: center;
        gap: 2.5rem;
        flex-wrap: wrap;
        padding-left: unset;

        .role-item-box {
          min-height: 4rem;
          padding: 1rem 2rem;
          border-radius: 2.5rem;
          display: flex;
          align-items: center;
          gap: 1rem;

          .cancel-icon-box {
            cursor: pointer;
            // max-height: 0;
            overflow-x: hidden;
            overflow-y: hidden;
            transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            max-width: 2rem;

            .img-box {
              width: 2rem;
              height: 2rem;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          &:hover .cancel-icon-box {
            // max-height: 2rem;
            max-width: 2rem;
            transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
            overflow: visible;
          }

          .role {
            font-size: 1.45rem;
            white-space: nowrap;
          }
        }

        .purple-item-box {
          background-color: rgba(240, 238, 255, 1);
          cursor: pointer;

          .cancel-icon-box {
            max-height: 2rem;
            max-width: 2rem;
            transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
          }

          .role {
            color: rgba(117, 90, 226, 1);
          }
        }
      }
    }

    .three-action-box {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 100%;

      .icon-text-box {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1.2rem 2rem;
        border-width: 0.12rem;
        border-radius: 0.8rem;
        cursor: pointer;
        transition: transform 0.3s ease-in-out;

        &:hover {
          transform: translateY(-0.1rem);
        }

        .text {
          font-size: 1.55rem;
        }

        .delete {
          color: rgba(255, 15, 0, 1);
        }

        .img-wrap {
          .img-box {
            width: 3rem;
            height: 3rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }

  .right-detail-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    // background-color: red;

    .right-submit-wrap-btn {
      display: flex;
      align-items: center;
      padding-top: 1rem;
      border-width: 0.15rem;
      justify-content: center;

      .edit-btn{
        width: 70%;
      }
    }

    .filter-title-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1.5rem;
      margin-bottom: 2rem;
      border-width: 0.15rem;
      position: relative;
      z-index: 10;

      .title {
        font-size: 1.65rem;
        font-weight: 500;
      }

      .filter-badge-box {
        display: flex;
        gap: 2rem;
        align-items: center;

        .filter-drop-box {
          position: relative;

          .drop-box-wrap {
            position: absolute;
            border-radius: 1.2rem;
            top: 110%;
            right: 0;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            min-width: 30rem;
            gap: 2rem;
            animation: moveDownSettingDrop 0.2s ease-in-out backwards;
          }

          .filter-box {
            height: 100%;
            display: flex;
            flex-direction: row;
            gap: 0.8rem;
            align-items: center;
            padding: 1rem 1.5rem;
            border-radius: 0.8rem;
            cursor: pointer;

            .line-box {
              margin: auto 0rem;
              display: flex;
              flex-direction: column;
              gap: 0.25rem;
              width: 1.8rem;
              align-items: center;
              // align-self: center;

              span {
                display: inline-block;
                height: 0.15rem;
                width: 100%;
                border-radius: 1rem;
                background-color: #676767;
              }

              & > *:nth-child(2) {
                width: 70%;
              }

              & > *:last-child {
                width: 40%;
              }
            }

            .text {
              font-weight: 700;
            }
          }
        }

        .badge-filter-option-box {
          display: flex;
          align-items: center;
          gap: 2rem;

          .option-box {
            display: flex;
            align-items: center;
            gap: 1rem;
            padding: 1rem 1.5rem;
            border-radius: 2rem;

            .icon {
              cursor: pointer;
              transform: translateY(0.1rem);
            }
          }
        }
      }
    }

    .auth-level-view-box {
      flex: 1;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin-top: 2rem;
      padding-bottom: 4rem;
      // background-color: red;

      &::-webkit-scrollbar {
        display: none;
      }

      & > *:not(:last-child) {
        padding-bottom: 2rem;
      }

      .select-item {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 2rem;
        cursor: pointer;

        .avatar-box {
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 50%;
          background-color: #020202 !important;
          display: grid;
          place-items: center;

          span {
            font-weight: 600;
            color: #ffffff;
            display: inline-block;
            font-size: 1.2rem;
            text-transform: uppercase;
          }
        }

        .name-role-box {
          display: flex;
          flex-direction: column;
          align-self: center;

          .name {
            font-size: 1.7rem;
            font-weight: 600;
          }
        }

        .img-wrap {
          .img-box {
            width: 4rem;
            height: 4rem;

            .img {
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }
        }

        .select-option-wrap {
          // display: none;
          margin-left: auto;

          form {
            .form-group {
              .react-select-class {
                & > *:nth-child(3) {
                  width: max-content !important;
                  border: unset !important;
                  border-radius: 3rem;
                  background-color: rgba(247, 247, 247, 1);
                  padding: 0.07rem 0.3rem;
                }

                & > *:nth-child(4) {
                  left: unset;
                  right: 0.5rem;
                  width: max-content;
                  // height: 30rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
