.global-account-beneficiary-modal-wrap-index {
  min-height: 35rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .title {
    font-size: 1.6rem;
    font-weight: 700;
    align-self: flex-start;
    margin-bottom: 1rem;
  }

  .load-more-select-beneficiary-list-box {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    gap: 1.5rem;
    margin-bottom: 2rem;
    min-height: 20rem;

    .select-beneficiary-list-box {
      max-height: calc(100vh - 40rem);
      padding-bottom: 2.5rem;
      //   background-color: red;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;

      &::-webkit-scrollbar {
        display: none;
      }

      & > *:not(:last-child) {
        padding-bottom: 2rem;
        border-width: 0.15rem;
        margin-bottom: 2rem;
      }

      .bene-row {
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;

        .name-num-box {
          display: flex;
          flex-direction: column;
          gap: 0.2rem;

          .name {
            font-weight: 600;
            font-size: 1.65rem;
            text-transform: capitalize;
          }
        }

        .img-bene-wrap {
          .img-box {
            width: 4rem;
            height: 4rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }

    .load-more-box {
      padding: 1.2rem 2rem;
      border-radius: 3rem;
      align-self: center;
      cursor: pointer;
      margin-top: auto;

      .text {
        font-weight: 600;
      }
    }
  }
}

.index-beneficiary-global-account-wrap-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;

  .middle-content-box {
    width: 65rem;
    // background-color: red;
    align-self: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    position: relative;
    overflow-y: scroll;

    @media only screen and (max-width: 48em){
      width: 95%;
    }
    

    .select-box-list-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1rem 1.2rem;
      border-radius: 4rem;
      align-self: center;
      gap: 1rem;
      width: max-content;
      // position: sticky;
      // top: 0;

      @media only screen and (max-width: 48em){
       border-radius: 1.2rem;
       padding: .5rem .7rem;
      }

      .item-box {
        padding: 1.2rem 2.7rem;
        display: flex;
        justify-content: center;
        border-radius: 4rem;
        cursor: pointer;

        @media only screen and (max-width: 48em){
          // border-radius: 1.2rem;
          padding: 1rem 2rem;
         }

        .item {
          font-size: 1.45rem;
        }
      }

      .item-box-active {
        cursor: not-allowed;
        .item {
          font-weight: 600;
        }
      }
    }

    .box-wrap {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 3rem;
      overflow-y: scroll;
      border-radius: 1.6rem;
      padding-bottom: 3rem;
      margin-bottom: 5rem;
      // min-height: 35rem;

      @media only screen and (max-width: 48em){
        border-radius: 1.2rem;
       }

      &::-webkit-scrollbar {
        display: none;
      }

      & > *:last-child {
        border: unset;
      }

      & > *:first-child {
        padding-top: 2rem;
      }

      & > *:not(:last-child) {
        border-width: 0.15rem ;
        padding-bottom: 2rem ;
      }

      .noti-wrap {
        display: flex;
        align-items: center;
        padding-left: 2rem;
        padding-right: 2rem;
        // padding-bottom: unset;

        .avatar-box {
          width: 5.5rem;
          height: 5.5rem;
          border-radius: 50%;
          filter: drop-shadow(0px 1px 2px rgba(51, 51, 51, 0.06))
            drop-shadow(0px 0px 2px rgba(51, 51, 51, 0.02));
          background-color: #ffffff;
          display: grid;
          place-items: center;
          position: relative;

          @media only screen and (max-width: 48em){
            width: 4rem;
            height: 4rem;
           }

          .img-box {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .text-box {
          margin-right: auto;
          margin-left: 1rem;

          .value {
            font-weight: 700;
            font-size: 1.5rem;
            margin-bottom: 0.3rem;
          }

          .label {
            font-size: 1.2rem;
          }
        }

        .delete-box {
          // margin-left: auto;
          // margin-right: 3rem;
          width: 4rem !important;
          height: 4rem !important;
          border-radius: 0.8rem;
          position: relative;
          cursor: pointer;
          // border-width: .15rem;

          .img-box {
            width: 50% !important;
            height: 50% !important;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
