.select-currency-fragment-index-wrap {
  width: -moz-max-content;
  width: max-content;
  position: relative;
}
.select-currency-fragment-index-wrap .select-list {
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  padding: 2rem 0rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  border-width: 0.15rem;
  background-color: #ffffff;
  max-height: 25rem;
  overflow-y: scroll;
  border: 0.1rem solid rgb(209, 209, 209);
  box-shadow: 0.1rem 0.1rem 8rem 0.1rem rgba(0, 0, 0, 0.05);
  animation: moveDownSettingDrop 0.5s ease-in-out backwards;
}
.select-currency-fragment-index-wrap .select-list::-webkit-scrollbar {
  display: none;
}
.select-currency-fragment-index-wrap .select-list > *:not(:last-child) {
  border-bottom: 0.1rem solid rgb(229, 230, 230);
}
.select-currency-fragment-index-wrap .select-list .cur-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1.5rem;
}
.select-currency-fragment-index-wrap .select-list .cur-item .cur-img {
  margin-right: 1rem;
}
.select-currency-fragment-index-wrap .select-list .cur-item .cur-img .img-box {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin-right: 1rme;
}
.select-currency-fragment-index-wrap .select-list .cur-item .cur-img .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.select-currency-fragment-index-wrap .select-list .cur-item .select-value {
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
}
.select-currency-fragment-index-wrap .select-list .cur-item-selected {
  background-color: rgb(247, 248, 247);
  cursor: not-allowed;
}
.select-currency-fragment-index-wrap .selected-obj-wrap {
  width: 100%;
  border-radius: 0.8rem;
  display: flex;
  padding: 0.5rem 0.8rem;
  align-items: center;
  cursor: pointer;
  border: 0.15rem solid rgb(224, 241, 240);
}
.select-currency-fragment-index-wrap .selected-obj-wrap .selected-value {
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
}
.select-currency-fragment-index-wrap .selected-obj-wrap .cur-img {
  margin-right: 1rem;
}
.select-currency-fragment-index-wrap .selected-obj-wrap .cur-img .img-box {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 1rme;
}
.select-currency-fragment-index-wrap .selected-obj-wrap .cur-img .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.select-currency-fragment-index-wrap .selected-obj-wrap .down-icon {
  margin-left: 1rem;
}
.select-currency-fragment-index-wrap .selected-obj-wrap .down-icon .img-box {
  width: 2rem;
  height: 2rem;
  margin-right: unset;
}
.select-currency-fragment-index-wrap .selected-obj-wrap .down-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=SelectCurrencyFragment.css.map */