.track-delivery-terminal-bankbox-wrapper-box-modal .raven-modal-content-wrap {
  width: 60rem;
}
.track-delivery-terminal-bankbox-wrapper-box-modal .raven-modal-content-wrap .track-delivery-terminal-bankbox-wrapper-box {
  min-height: 25rem;
  display: flex;
  flex-direction: column;
}
.track-delivery-terminal-bankbox-wrapper-box-modal .raven-modal-content-wrap .track-delivery-terminal-bankbox-wrapper-box .default-progress-bar-grey-box {
  width: 100%;
  min-height: 3rem;
  border-radius: 5rem;
  margin-top: 3rem;
  position: relative;
}
.track-delivery-terminal-bankbox-wrapper-box-modal .raven-modal-content-wrap .track-delivery-terminal-bankbox-wrapper-box .default-progress-bar-grey-box .progress-bar-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.track-delivery-terminal-bankbox-wrapper-box-modal .raven-modal-content-wrap .track-delivery-terminal-bankbox-wrapper-box .default-progress-bar-grey-box .track-list-box {
  position: absolute;
  top: 100%;
  left: 0%;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  width: 100%;
  padding: 0rem 1rem;
  transform: translateY(-3.4rem);
  background-color: transparent;
}
.track-delivery-terminal-bankbox-wrapper-box-modal .raven-modal-content-wrap .track-delivery-terminal-bankbox-wrapper-box .default-progress-bar-grey-box .track-list-box .icon-text-date-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.track-delivery-terminal-bankbox-wrapper-box-modal .raven-modal-content-wrap .track-delivery-terminal-bankbox-wrapper-box .default-progress-bar-grey-box .track-list-box .icon-text-date-box .text {
  font-weight: 600;
  font-size: 1.65rem;
}
.track-delivery-terminal-bankbox-wrapper-box-modal .raven-modal-content-wrap .track-delivery-terminal-bankbox-wrapper-box .default-progress-bar-grey-box .track-list-box .icon-text-date-box .date {
  font-size: 1.15rem;
  margin-top: -0.3rem;
}
.track-delivery-terminal-bankbox-wrapper-box-modal .raven-modal-content-wrap .track-delivery-terminal-bankbox-wrapper-box .default-progress-bar-grey-box .track-list-box .icon-text-date-box .icon-box {
  margin-bottom: 1rem;
}
.track-delivery-terminal-bankbox-wrapper-box-modal .raven-modal-content-wrap .track-delivery-terminal-bankbox-wrapper-box .default-progress-bar-grey-box .track-list-box .icon-text-date-box .icon-box .img-box {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: rgb(219, 219, 219);
}
.track-delivery-terminal-bankbox-wrapper-box-modal .raven-modal-content-wrap .track-delivery-terminal-bankbox-wrapper-box .default-progress-bar-grey-box .track-list-box .icon-text-date-box .icon-box .img-box .img {
  width: 60%;
  height: 60%;
  -o-object-fit: contain;
     object-fit: contain;
}
.track-delivery-terminal-bankbox-wrapper-box-modal .raven-modal-content-wrap .track-delivery-terminal-bankbox-wrapper-box .default-progress-bar-grey-box .track-list-box .icon-text-date-box-end {
  align-items: flex-end;
}
.track-delivery-terminal-bankbox-wrapper-box-modal .raven-modal-content-wrap .track-delivery-terminal-bankbox-wrapper-box .default-progress-bar-grey-box .track-list-box .icon-text-date-box-end .text {
  text-align: right;
}
.track-delivery-terminal-bankbox-wrapper-box-modal .raven-modal-content-wrap .track-delivery-terminal-bankbox-wrapper-box .default-progress-bar-grey-box .track-list-box .icon-text-date-box-end .date {
  text-align: right;
}
.track-delivery-terminal-bankbox-wrapper-box-modal .raven-modal-content-wrap .track-delivery-terminal-bankbox-wrapper-box .title-text-box {
  display: flex;
  flex-direction: column;
}
.track-delivery-terminal-bankbox-wrapper-box-modal .raven-modal-content-wrap .track-delivery-terminal-bankbox-wrapper-box .title-text-box .title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.track-delivery-terminal-bankbox-wrapper-box-modal .raven-modal-content-wrap .track-delivery-terminal-bankbox-wrapper-box .title-text-box .text {
  font-size: 1.3rem;
  padding-bottom: 1.5rem;
}/*# sourceMappingURL=TrackDeliveryModal.css.map */