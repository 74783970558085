.mobile-table-card-wrapper-box {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.mobile-table-card-wrapper-box .avatar-img-box {
  display: grid;
  place-items: center;
  align-self: center;
  margin-right: 1rem;
  flex: 0 0 5%;
}
.mobile-table-card-wrapper-box .avatar-img-box .avatar-box {
  width: 3.3rem;
  height: 3.3rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  grid-gap: 0.4rem;
}
.mobile-table-card-wrapper-box .avatar-img-box .avatar-box span {
  font-weight: 700;
  font-size: 1.1rem;
  text-transform: uppercase;
}
.mobile-table-card-wrapper-box .avatar-img-box .img-box-cre-deb {
  width: 3rem;
  height: 3rem;
  background-color: #1ace37 !important;
  border-radius: 50%;
  display: grid;
  place-items: center;
  flex: 0 0 5%;
}
.mobile-table-card-wrapper-box .avatar-img-box .img-box-cre-deb .img {
  width: 35%;
  height: 35%;
  -o-object-fit: contain;
     object-fit: contain;
  filter: brightness(1000%);
}
.mobile-table-card-wrapper-box .avatar-img-box .img-box-debit {
  background-color: #ff0f00 !important;
}
.mobile-table-card-wrapper-box .avatar-img-box .img-box-failed {
  background-color: #f7f8f7;
}
.mobile-table-card-wrapper-box .title-text-box .title {
  font-weight: 700;
  font-size: 1.3rem;
}
.mobile-table-card-wrapper-box .title-text-box .text {
  margin-top: 0.6rem;
  font-size: 1.1rem;
}
.mobile-table-card-wrapper-box .amount-box {
  margin-left: auto;
}
.mobile-table-card-wrapper-box .amount-box .amount {
  font-weight: 700;
  font-size: 1.25rem;
}/*# sourceMappingURL=MobileTableCard.css.map */