.verification-layout-version-one-five-wrap-index {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 48em){
   overflow-x: hidden;
  }

  .header-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 3rem;
    border-width: 0.15rem !important;

    .logo-box {
      margin-right: auto;

      @media only screen and (max-width: 42em) {
        margin-left: -2rem;
      }

      .img-box {
        width: 12rem;
        height: 2.5rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .skip-box {
      // position: absolute;
      // top: 2.5rem;
      // right: 4rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      //   background-color: red;

      // @media only screen and (max-width: 42em) {
      //   display: none;
      // }

      &:hover .icon {
        transform: translateX(0.1rem);
      }

      .text {
        margin-right: 0.4rem;
      }

      .icon {
        width: 1.5rem;
        height: 1.5rem;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .body-content-box {
    flex: 1;
    display: grid;
    place-items: center;

    .middle-veri-content-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 2rem;
        font-weight: 600;
      }

      .tier-select-wrap-box {
        border-radius: 1.2rem;
        display: flex;
        flex-direction: column;
        // padding: 2.5rem 0rem;
        margin: 2rem 0rem;
        border-width: 0.15rem;
        min-width: 60rem;

        @media only screen and (max-width: 48em) {
          min-width: unset;
          width: 95%;
        }

        & > *:last-child {
          border: unset !important;
        }

        .item-tier-row {
          cursor: pointer;
          border-width: 0.15rem;
          display: flex;
          align-items: center;
          gap: 1.5rem;
          padding: 2.5rem;
          width: 100%;

          @media only screen and (max-width: 48em) {
            padding: 2.5rem 1.5rem;
            //  flex-direction: column;
          }

          &:hover {
            .right-arrow {
              transform: translateX(0.15rem);
            }
          }

          .right-arrow {
            transition: transform 0.3s ease-in-out;

            .img-box {
              width: 2rem;
              height: 2rem;

              @media only screen and (max-width: 48em) {
                width: 1.5rem;
                height: 1.5rem;
              }

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          .img-wrap-box {
            .img-box {
              width: 3.5rem;
              height: 3.5rem;
              border-radius: 50%;
              display: grid;
              place-items: center;

              .img {
                width: 55%;
                height: 55%;
                object-fit: contain;
              }
            }
          }

          .img-wrap-box-shift {
            .img-box {
              .img {
                transform: translateX(-0.15rem);
              }
            }
          }

          .title-text-box {
            margin-right: auto;

            .title {
              font-size: 1.65rem;
              font-weight: 600;
            }

            .text {
              font-size: 1.25rem;
            }
          }
        }

        .tooltip-hover-wrap {
          .right-arrow,
          .img-wrap-box,
          .title-text-box {
            opacity: 0.35;
          }
        }
      }

      .bottom-text-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        @media only screen and (max-width: 48em) {
          width: 95%;
        }

        .text {
          font-size: 1.45rem;
        }

        .img-veri-box {
          .img-box {
            width: 2rem;
            height: 2rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
