.no-content-wrapper-page-box {
  width: 100%;
  border-radius: 1.6rem;
  flex: 1;
  display: grid;
  place-items: center;
  //   width: max-content;
  //   padding: 5rem 0rem;
  //   margin: auto;

  .spinner-wrap{
    .img-box{
      width: 22rem;
      height: 22rem;

      @media only screen and (max-width: 48em){
        width: 15rem;
        height: 15rem;
      }

      .img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  @media only screen and (min-width: 110em) {
    flex: unset;
    padding: 4rem 2rem;
    height: 70rem ;
    // background-color: red;
  }

  @media only screen and (max-width: 56em) {
    min-height: 45rem;
    padding: 4rem 2rem;
    justify-self: center;
  }

  .wrap {
    display: flex;
    width: 80%;
    // gap: 5rem;
    align-items: center;

    @media only screen and (max-width: 56em) {
      flex-direction: column;
      gap: 3rem;
      width: 95%;
      align-self: flex-start;
    }

    .right-box {
      //   flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      flex: 0 0 40%;

      @media only screen and (max-width: 56em) {
        flex: unset;
        width: 100%;
      }

      .title-box {
        .title {
          font-weight: 700;
          width: 85%;
          font-size: 2rem;

          @media only screen and (max-width: 56em) {
            width: 100%;
          }
        }
      }

      .support-learn-box {
        margin-top: 1.5rem;

        .icon-text-box {
          display: flex;
          align-items: flex-start;
          gap: 0.6rem;

          .text {
            font-size: 1.3rem;
            font-weight: 600;
            // color: red !important;

            span {
              display: inline-block;
              color: #755ae2 !important;
              cursor: pointer;
              border-bottom: 0.1rem solid currentColor;
              transition: all 0.3s;

              &:hover {
                transform: scale(1.03);
              }
            }
          }

          .img-box {
            width: 1.7rem;
            width: 1.7rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }

      .btn-box {
        margin-top: 2rem;
        width: 100%;
        position: relative;

        .select-bulk-type-box {
          z-index: 200;
          position: absolute;
          bottom: 120%;
          left: 50%;
          //   transform: ;
          box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
            0px 48px 64px -36px rgba(51, 51, 51, 0.08);
          border-radius: 2.4rem;
          padding: 2rem 2.5rem;
          display: flex;
          flex-direction: column;
          width: 30rem;
          // border: none;
          transform: translateX(-50%) scale(0);
          transform-origin: bottom center;
          transition: all 0.3s;
          opacity: 1;

          & > *:first-child {
            margin-bottom: 1.5rem;
            padding-bottom: 1.5rem;
            border-bottom: 0.1rem solid #f7f8f7;
          }

          .child-row {
            display: flex;
            align-items: center;
            width: max-content;
            align-self: stretch;
            // background-color: red;
            cursor: pointer;
            width: 100%;

            &:hover .img-box {
              transform: scale(1.03);
            }

            .title {
              font-weight: 700;
              font-size: 1.4rem;
              // color: #020202;
              text-align: left;
            }

            .text {
              font-size: 1.25rem;
              text-align: left;
              // color: red;
            }

            .img-box {
              width: 4rem;
              height: 4rem;
              display: grid;
              place-items: center;
              margin-right: 1rem;
              transition: all 0.3s;
              border-radius: 50%;
              background-color: #f7f8f7;

              .img {
                object-fit: contain;
                width: 50%;
                height: 50%;
              }
            }
          }
        }

        .select-bulk-type-box-show {
          transform: translateX(-50%) scale(1);
        }

        .raven-btn {
          width: 100%;
        }
      }

      .list-box {
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;
        gap: 1.5rem;
        width: 100%;

        .row-text {
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: flex-start;

          .img-wrap {
            .img-box {
              width: 1.7rem;
              width: 1.7rem;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          .text {
            font-size: 1.5rem;
            // color: red;
          }
        }
      }
    }

    .left-box {
      //   min-height: 45rem;
      flex: 0 0 50%;

      @media only screen and (max-width: 56em) {
        flex: unset;
        width: 100%;
      }

      .img-wrap {
        width: 100%;
        height: 35rem;

        .img-box {
          width: 100%;
          height: 100%;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  .wrap-two {
    flex-direction: column;
    align-items: center;

    .left-box {
      flex: unset;
      margin-bottom: 3rem;

      @media only screen and (max-width: 56em) {
        margin-bottom: unset;
      }

      .img-wrap {
        width: 100%;
        height: 25rem;

        .img-box {
          width: 100%;
          height: 100%;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .right-box {
      flex: unset;
      align-items: center;
      gap: 0.5rem;

      .title-box {
        .title {
          text-align: center;
          width: max-content;
        }
      }

      .normal-text {
        font-size: 1.5rem;
        text-align: center;
        width: 60%;
        // color: red;

        @media only screen and (max-width: 56em) {
          flex: unset;
          width: 100%;
        }
      }
    }
  }
}
