.preview-details-common-box {
  // background-color: #f7f8f7;
  border: 0.1rem solid #cccccc;
  padding: 2rem 2.5rem;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
    0px 48px 64px -36px rgba(51, 51, 51, 0.08);

  .top-box {
    // margin-bottom: 2rem;
    border-bottom: 0.1rem solid rgba(204, 204, 204, 0.5);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon-box {
      margin-left: auto;

      .icon {
        transform: scale(1.2);
      }
    }

    .text-box {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;

      .key {
        font-size: 1.2rem;
      }

      .value {
        font-weight: 600;
        font-size: 2rem;
      }
    }
  }

  .middle-box {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-bottom: 0.1rem solid rgba(204, 204, 204, 0.5);
    padding-bottom: 1.5rem;

    .text {
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      gap: 1rem;

      .img-wrap {
        .img-box {
          width: 3rem;
          height: 3rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      & > *:first-child {
        margin-right: 0.5rem;
      }

      & > *:last-child {
        font-weight: 600;
      }
    }

    .pro-text-img {
      display: flex;
      align-items: center;
      gap: 1rem;

      .img-box {
        width: 2.5rem;
        height: 2.5rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .bottom-box {
    width: 100%;
    display: flex;

    align-items: center;

    .text {
      font-size: 1.2rem;
    }

    .img-box-info {
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
      margin-left: 1rem;
      display: grid;
      place-items: center;

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .toggle-wrap {
      margin-left: auto;
      transform: scale(0.8);
    }
  }
}

.preview-details-common-box-collapse {
  .top-box {
    margin-bottom: unset;
    border-bottom: unset;
    padding-bottom: unset;
  }

  .middle-box {
    border-bottom: unset;
    padding-bottom: unset;
    max-height: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    width: 100%;
  }

  .collapse-box {
    border-top: 0.1rem solid rgba(204, 204, 204, 0.5);
    padding-top: 1rem;
    margin-bottom: -0.5rem;
    margin-top: -0.5rem;

    .text {
      font-size: 1.2rem;
      & > *:first-child {
        margin-right: 0.5rem;
      }

      & > *:last-child {
        font-weight: 600;
      }
    }
  }
}

.preview-details-common-box-show {
  .top-box {
    border-bottom: 0.1rem solid rgba(204, 204, 204, 0.5);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  .middle-box {
    border-bottom: 0.1rem solid rgba(204, 204, 204, 0.5);
    padding-bottom: 1.5rem;
    max-height: 30rem;
    transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
  }
}

.step-two-send-raven {
  // background-color: red;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 10rem;
}

.new-preview-detail-box-wrap {
  width: 40rem;
  border-radius: 1.2rem;
  padding: 2rem 2.5rem;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
    0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  // min-height: 45rem;
  padding-top: unset;
  margin-top: 2rem;
  // margin-bottom: 20rem;

  @media only screen and (max-width: 48em) {
    width: 100%;
  }

  .middle-content-wrap {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    margin-bottom: 1.5rem;
    flex: 1;
    gap: 2rem;
    width: 100%;
    // display: none;

    .box {
      display: flex;
      justify-content: space-between;
      font-size: 1.4rem;
      width: 100%;
      align-items: center;
      gap: 1rem;

      .label {
        text-align: left;
        width: max-content;
        text-wrap: nowrap;
      }

      .value {
        text-align: right;
        font-weight: 500;
        position: relative;

        .select-toggle-box{
          width: max-content;
          display: flex;
          justify-content: flex-end;
        }

        .view-all-box {
          position: absolute;
          top: 120%;
          right: 0;
          width: 35rem;
          height: 30rem;
          padding: 2rem;
          z-index: 4;
          overflow-y: scroll;
          box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
            0px 48px 64px -36px rgba(51, 51, 51, 0.08);
          border-radius: 2rem;
          transform: scale(0);
          transition: all 0.3s ease-in-out;
          transform-origin: top right;
          border-top-right-radius: unset;
          border-bottom-right-radius: unset;

          .wrap {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            // padding-bottom: 5rem;

            .child-wrap {
              display: flex;
              align-items: center;
              padding: 1rem 1.3rem;
              // border: 0.1rem solid #f7f8f7;
              border-radius: 0.8rem;

              .text-box {
                display: flex;
                flex-direction: column;

                .name {
                  font-weight: 700;
                  font-size: 1.3rem;
                  align-self: flex-start;
                }

                .text {
                  font-size: 1.1rem;
                  align-self: flex-start;
                  font-weight: 400;
                }
              }

              .img-box {
                width: 3rem;
                height: 3rem;
                margin-right: 1rem;

                .img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }

              .cancel-box {
                margin-left: auto;
                width: 2.2rem;
                height: 2.2rem;
                border-radius: 50%;
                background-color: #ffdfdd;
                margin-left: auto;
                display: grid;
                place-items: center;
                cursor: pointer;
                transition: all 0.3s;

                &:hover {
                  transform: scale(1.1);
                }

                .icon {
                  fill: #ff0f00;
                  width: 1.1rem;
                  height: 1.1rem;
                }
              }
            }
          }

          &::-webkit-scrollbar:horizontal {
            display: none;
          }

          /* width */
          &::-webkit-scrollbar {
            width: 0.3rem;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: none !important;
            background-color: transparent !important;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #020202;
            border-radius: 30rem;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #020202;
          }
        }

        .view-all-box-show {
          transform: scale(1);
        }

        .see-all-box {
          display: flex;
          align-items: center;
          gap: 0.6rem;
          cursor: pointer;
          font-weight: 700;

          .see-all-text {
            font-weight: 700;
            color: #755ae2;
            cursor: pointer;
          }
        }
      }
    }
  }

  .img-top-box-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
    margin-bottom: 1.5rem;

    .amount-date-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -1.5rem;

      .amount {
        font-size: 2.2rem;
        font-weight: 700;
        text-align: center;
      }

      .date {
        font-size: 1.4rem;
        margin-top: 0.4rem;
      }
    }

    .img-wrap {
      transform: translateY(-50%);
      display: grid;
      place-items: center;
      position: relative;

      .img-collapse {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        & > * {
          grid-row: 1 / span 1;
          grid-column: 1 / span 1;
        }

        & > *:first-child {
          transform: translateX(-30%);
          z-index: 1;
        }

        & > *:nth-child(2) {
          transform: translateX(0%);
          z-index: 2;
        }

        & > *:nth-child(3) {
          transform: translateX(30%);
          z-index: 3;
        }

        & > *:nth-child(4) {
          transform: translateX(60%);
          z-index: 4;
        }
      }

      .arrow-wrap {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        display: grid;
        place-items: center;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 10;
        transform: translateX(40%);

        .arrow-box {
          background-color: #1ace37;
          width: 75%;
          height: 75%;
          border-radius: 50%;
          display: grid;
          place-items: center;

          .icon {
            width: 1rem;
            height: 1rem;
            color: #ffffff;
          }
        }
      }

      .img-box {
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        overflow: hidden;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .img-top-box-wrap-global-account-swap {
    .img-wrap {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      position: relative;

      .img-box {
        width: 5rem;
        height: 5rem;
      }

      .swap-global-icon-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        display: grid;
        place-items: center;
        z-index: 3;

        .img-box {
          width: 70%;
          height: 70%;
          transform: rotate(90deg);
          display: grid;
          place-items: center;
          background-color: #333333;

          .img {
            width: 60%;
            height: 60%;
            object-fit: contain;
          }
        }
      }
    }
  }

  .bottom-content-wrap {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .box-check {
      display: flex;
      align-items: center;
      justify-content: unset;
      gap: 1rem;
      margin-top: 1rem;
      padding-top: 2rem;

      & > *:first-child {
        transform: scale(0.85);
        // margin-right: -2.5rem;
      }

      .des-text {
        font-size: 1.4rem !important;
        // display: none;
      }
    }

    .save-bulk-template-box {
      display: flex;
      flex-direction: column;
      margin-top: 0.5rem;
      // display: none;
      gap: 2rem;
      // margin-bottom: -1rem;

      .template-title-box {
        max-height: 0;
        overflow-x: hidden;
        overflow-y: hidden;
        transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
        -webkit-transition: max-height 0.3s;
        -moz-transition: max-height 0.3s;
        width: 100%;
        // background-color: red;
      }

      .template-title-box-show {
        max-height: 15rem;
        transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
      }

      .toggle-select-text-box {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;

        // .text {
        //   // font-weight: 500;
        // }

        & > *:last-child {
          transform: scale(0.8);
        }
      }
    }

    .bottom-box {
      width: 100%;
      display: flex;

      align-items: center;

      .text {
        font-size: 1.4rem;
        // font-weight: 500;
      }

      .img-box-info {
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
        margin-left: 1rem;
        display: grid;
        place-items: center;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .toggle-wrap {
        margin-left: auto;
        transform: scale(0.8);
      }
    }

    .new-reusable-schedule-box {
      display: flex;
      align-items: center;
      width: 100%;
      // background-color: red;

      @media only screen and (max-width: 42em) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.6rem;
      }

      .reset {
        cursor: pointer;
        font-size: 1.4rem;
        font-weight: 700;
        // color: #755ae2;
        margin-left: 1rem;
      }

      .text-schedule {
        font-size: 1.4rem;
        margin-right: auto;
        cursor: pointer;
        min-width: 10rem;
      }

      .date-arrow-wrap {
        display: flex;
        position: relative;
        width: 100%;
        align-items: center;
        // justify-content: space-between;
        // background-color: red;

        form {
          width: max-content;
          display: flex;
          align-items: center;
          // gap: 1rem;

          // .arrow-right{

          // }
        }

        .icon {
          position: absolute;
          top: 50%;
          right: 0.8rem;
          transform: translateY(-50%) rotate(-90deg);
          z-index: 20;
          width: 1.7rem;
          height: 1.7rem;
          fill: #755ae2;
          cursor: pointer;
          display: none;

          @media only screen and (max-width: 42em) {
            display: none;
          }
        }

        // .form-group {
        //   // width: unset;
        //   // background-color: red;
        // }

        .input-date {
          // background-color: red;
          width: 100%;
          // margin-left: auto;
          .wrap {
            min-width: unset;
            width: 100% !important;
            // margin-left: auto;

            @media only screen and (max-width: 42em) {
              width: 100%;
            }

            input {
              min-width: unset;
              width: max-content;
              background-color: #fafaff;
              border: 0.15rem solid #dbd6fc;
              color: #755ae2;
              font-weight: 600;
              padding: 0.8rem 1rem;
              border: unset !important;
              background-color: transparent !important;

              @media only screen and (max-width: 42em) {
                width: 100%;
              }

              // &::placeholder {
              //   color: #755ae2;
              // }
            }

            .label-calendar {
              z-index: 10;
              display: none;
            }
          }
        }

        .input-date-long {
          .wrap {
            width: 100%;
            // margin-left: auto;

            @media only screen and (max-width: 42em) {
              width: 90%;
            }

            input {
              width: 100%;

              @media only screen and (max-width: 42em) {
                width: 90%;
              }
            }
          }
        }
      }
    }
  }

  .charge-rate-box-wrap {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    gap: 0.7rem;
    padding: 1rem 2rem;
    border-radius: 0.8rem;
    background-color: #fff6ed;
    // padding-top: 2rem !important;
    // background-color: red !important ;

    .text {
      font-size: 1.15rem;
      // display: none;

      span {
        color: #ea872d;
      }
    }

    .img-wrap {
      .img-box {
        width: 2.5rem;
        height: 2.5rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .bottom-box-confirm {
    width: 100%;
    display: flex;

    align-items: center;

    .text {
      font-size: 1.2rem;
    }

    .img-box-info {
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
      margin-left: 1rem;
      display: grid;
      place-items: center;

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .toggle-wrap {
      margin-left: auto;
      transform: scale(0.8);
    }
  }

  .account-change-drop-wrap {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    padding-top: 2rem;
    gap: 1rem;

    .change-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: rgba(255, 246, 237, 1);
      padding: 1.5rem;
      border-radius: 0.8rem;

      .change-text {
        .text {
          color: rgba(234, 135, 45, 1);
          font-weight: 600;
          cursor: pointer;
          font-size: 1.5rem;
        }
      }

      .change-name-info {
        .name-icon {
          display: flex;
          align-items: center;
          gap: 1rem;

          .img-box {
            width: 1.5rem;
            height: 1.5rem;
            display: grid;
            place-items: center;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .name {
            font-size: 1.65rem;
            font-weight: 600;
          }
        }
      }
    }
  }
}
