@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_extra_light_italic-webfont.woff2") format("woff2"), url("./fonts/br_firma_extra_light_italic-webfont.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_extra_light-webfont.woff2") format("woff2"), url("./fonts/br_firma_extra_light-webfont.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_light_italic-webfont.woff2") format("woff2"), url("./fonts/br_firma_light_italic-webfont.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_light-webfont.woff2") format("woff2"), url("./fonts/br_firma_light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_medium_italic-webfont.woff2") format("woff2"), url("./fonts/br_firma_medium_italic-webfont.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_medium-webfont.woff2") format("woff2"), url("./fonts/br_firma_medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_regular_italic-webfont.woff2") format("woff2"), url("./fonts/br_firma_regular_italic-webfont.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_regular-webfont.woff2") format("woff2"), url("./fonts/br_firma_regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_semibold_italic-webfont.woff2") format("woff2"), url("./fonts/br_firma_semibold_italic-webfont.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_semibold-webfont.woff2") format("woff2"), url("./fonts/br_firma_semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_thin_italic-webfont.woff2") format("woff2"), url("./fonts/br_firma_thin_italic-webfont.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_thin-webfont.woff2") format("woff2"), url("./fonts/br_firma_thin-webfont.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_black_italic-webfont.woff2") format("woff2"), url("./fonts/br_firma_black_italic-webfont.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_black-webfont.woff2") format("woff2"), url("./fonts/br_firma_black-webfont.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_bold_italic-webfont.woff2") format("woff2"), url("./fonts/br_firma_bold_italic-webfont.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_bold-webfont.woff2") format("woff2"), url("./fonts/br_firma_bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
:root {
  --base-white: #ffffff;
  --base-black: #020202;
  --base-background-white: #f9f9f9;
  --base-background-black: #676767;
  --border-green: #0b8376;
  --border-grey-dark: #676767;
  --border-grey-light: #cccccc;
  --border-light: #f7f8f7;
  --primary-green: #0b8376;
  --primary-deep-green: #014345;
  --primary-blue: #476885;
  --primary-orange: #ea872d;
  --primary-purple: #755ae2;
  --primary-black: #020202;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 55.5% !important;
  box-sizing: border-box;
}
@media only screen and (max-width: 75em) {
  html {
    font-size: 52% !important;
  }
}

body {
  font-size: 1.4rem;
  font-family: "br_firma", sans-serif !important;
  font-weight: 400;
  /* overflow-x: hidden !important; */
  position: relative;
  width: 100vw;
  overscroll-behavior: none;
  overflow-y: scroll;
  overflow-x: hidden;
  color: rgba(196, 196, 196, 0.3);
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
  display: none;
}

.blurr-bg-box {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
}
.blurr-bg-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(1rem);
  z-index: 3;
  background-color: rgba(2, 2, 2, 0.35);
  cursor: pointer;
}
.blurr-bg-box > * {
  position: absolute;
  z-index: 5 !important;
  top: 15rem;
  right: 13rem;
}
.blurr-bg-box .message-wrap-box {
  animation: moveDownSettingDrop 0.5s ease-in-out backwards;
  position: absolute;
  z-index: 10;
  width: 55rem;
  height: 15rem;
  border-radius: 1.6rem;
  display: flex;
  overflow: hidden;
}
@media only screen and (max-width: 48em) {
  .blurr-bg-box .message-wrap-box {
    min-width: unset;
    width: 80vw;
    flex-direction: column;
    gap: 1rem;
    height: unset;
    padding-bottom: 2rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: unset;
  }
}
.blurr-bg-box .message-wrap-box .left-box {
  flex: 0 0 35%;
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 48em) {
  .blurr-bg-box .message-wrap-box .left-box {
    flex: unset;
    width: 100%;
    min-height: 20rem;
  }
}
.blurr-bg-box .message-wrap-box .right-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem;
  justify-content: center;
}
@media only screen and (max-width: 48em) {
  .blurr-bg-box .message-wrap-box .right-box {
    flex: unset;
    width: 100%;
  }
}
.blurr-bg-box .message-wrap-box .right-box .title {
  font-weight: 700;
  font-size: 1.7rem;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 48em) {
  .blurr-bg-box .message-wrap-box .right-box .title {
    font-size: 2.2rem;
  }
}
.blurr-bg-box .message-wrap-box .right-box .text {
  font-size: 1.2rem;
}
@media only screen and (max-width: 48em) {
  .blurr-bg-box .message-wrap-box .right-box .text {
    font-size: 1.5rem;
  }
}
.blurr-bg-box .message-wrap-box .right-box .text span {
  font-weight: 700;
  color: #755ae2 !important;
  cursor: pointer;
}

@media only screen and (max-width: 48em) {
  .flatpickr-input {
    height: 5rem !important;
  }
  .form-modal-auth .form-group .pin-group .pin_field_group {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1.5rem;
  }
  .form-modal-auth .form-group .pin-group .pin_field_group input:focus {
    font-size: 16px;
  }
  .pin-group .pin_field_group {
    display: grid;
    justify-content: space-between;
    width: 90%;
  }
  .pin-group .pin_field_group input:focus {
    font-size: 16px;
  }
}
.table-business .table-body .table-row .table-data span {
  font-weight: 600;
}

.modal-parent-wrap .content-wrap {
  width: 45rem;
}

.raven-btn {
  font-family: "br_firma", sans-serif !important;
}

.box-card {
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  gap: 0.5rem;
}
.box-card .name {
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 2.5rem;
}
.box-card .text {
  font-size: 1.2rem;
}

@media only screen and (max-width: 42em) {
  .form-group .input-submit {
    height: 5rem !important;
  }
}

.recaptcha-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 5000;
}

.hover-white-class {
  transition: all 0.3s ease-in-out;
  position: relative;
}
.hover-white-class > * {
  position: relative;
  z-index: 3;
}
.hover-white-class::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
}

.per-page-component-wrap .form-group .react-select-class {
  min-width: unset;
}
.per-page-component-wrap .form-group .react-select-class > *:nth-child(3) > *:first-child {
  padding: unset;
  padding-left: 8px;
}
.per-page-component-wrap .form-group .react-select-class > *:nth-child(3) > *:nth-child(2) > *:nth-child(2) {
  padding-left: 2px;
}

.select-option-class {
  position: relative;
  cursor: pointer;
}
.select-option-class::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 0.8rem;
  visibility: hidden;
}
.select-option-class .check-box {
  position: absolute;
  left: unset;
  top: 1.2rem;
  right: 1.2rem;
  display: grid;
  place-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #020202;
  visibility: hidden;
}
.select-option-class .check-box .icon {
  width: 0.6rem;
  height: 0.6rem;
  fill: #ffffff;
}
.select-option-class .check-wrap {
  margin-left: auto;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  position: absolute;
  left: unset;
  top: 1.2rem;
  right: 1.2rem;
  visibility: hidden;
}
.select-option-class .check-wrap::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  height: 40%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.select-option-class .check-wrap-active {
  background-color: rgb(117, 90, 226);
}

.select-option-class-active::before {
  visibility: visible;
}
.select-option-class-active .check-box,
.select-option-class-active .check-wrap {
  visibility: visible;
}

.label-class-span {
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0.5;
}

.label-class-span-active {
  opacity: 1 !important;
}

.auth-pin-modal-wrap .raven-modal-content-wrap .close-box .img-box .img {
  fill: #020202;
  stroke: #020202;
}

.animate-move-up-class {
  animation: moveUpAnime 0.5s backwards ease-in-out;
}

@keyframes moveUpAnime {
  0% {
    transform: translateY(2%);
    opacity: 0.7;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
.mobile-table-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
@media only screen and (min-width: 48em) {
  .mobile-table-box {
    display: none;
  }
}
.mobile-table-box > *:not(:last-child) {
  border-right: unset;
  border-top: unset;
  border-left: unset;
  padding-bottom: 1.5rem;
}
.mobile-table-box > *:last-child {
  border: unset;
}

.space-bottom {
  height: 10rem;
}
@media only screen and (max-width: 55em) {
  .space-bottom {
    display: none;
  }
}

.form-group__black-light .input-submit {
  font-family: "br_firma", sans-serif !important;
}

.dayContainer .prevMonthDay,
.dayContainer .nextMonthDay {
  opacity: 0.05 !important;
}

.raven-btn svg {
  margin-left: unset !important;
}

.money-received-index-wrap-modal .wrap {
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2.5rem;
}
.money-received-index-wrap-modal .wrap .img-wrap {
  align-self: flex-start;
  margin-bottom: 1.5rem;
}
.money-received-index-wrap-modal .wrap .img-wrap .img-box {
  width: 4rem;
  height: 4rem;
}
.money-received-index-wrap-modal .wrap .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.money-received-index-wrap-modal .wrap .title {
  font-size: 2rem;
  font-weight: 600;
}
.money-received-index-wrap-modal .wrap .text {
  font-size: 1.55rem;
}

.session-expired-wrap input {
  border: none !important;
}
.session-expired-wrap .two-btn-box {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: flex-end;
}

.table-fixed-type .table-head tr {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5000;
}

.exchange-rate-modal-wrap .raven-modal-content-wrap {
  width: 55rem !important;
}
@media only screen and (max-width: 48em) {
  .exchange-rate-modal-wrap .raven-modal-content-wrap {
    width: 100% !important;
  }
}

.raven-big-content-view-text-box-modal .raven-modal-content-wrap {
  width: 85rem !important;
}
.raven-big-content-view-text-box-modal .raven-modal-content-wrap .raven-big-content-view-text-box {
  height: 90vh;
  overflow-y: scroll;
  padding-bottom: 15rem;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.raven-big-content-view-text-box-modal .raven-modal-content-wrap .raven-big-content-view-text-box::-webkit-scrollbar {
  width: 0.5rem;
}
.raven-big-content-view-text-box-modal .raven-modal-content-wrap .raven-big-content-view-text-box::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.raven-big-content-view-text-box-modal .raven-modal-content-wrap .raven-big-content-view-text-box::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.raven-big-content-view-text-box-modal .raven-modal-content-wrap .raven-big-content-view-text-box::-webkit-scrollbar-thumb {
  background: #020202;
}
.raven-big-content-view-text-box-modal .raven-modal-content-wrap .raven-big-content-view-text-box .text {
  text-align: justify;
  font-size: 1.65rem;
  line-height: 3rem;
  word-spacing: 0.3rem;
}
@media only screen and (max-width: 48em) {
  .raven-big-content-view-text-box-modal .raven-modal-content-wrap {
    width: 100% !important;
  }
}

.raven-big-content-view-text-box-modal-img-preview .raven-modal-content-wrap {
  width: 100rem !important;
}
.raven-big-content-view-text-box-modal-img-preview .raven-modal-content-wrap .raven-big-content-view-text-box {
  height: 80vh;
  overflow-y: scroll;
  background-image: url("../assets/cac-verified-success.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.raven-big-content-view-text-box-modal-img-preview .raven-modal-content-wrap .raven-big-content-view-text-box::-webkit-scrollbar {
  width: 0.5rem;
}
.raven-big-content-view-text-box-modal-img-preview .raven-modal-content-wrap .raven-big-content-view-text-box::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.raven-big-content-view-text-box-modal-img-preview .raven-modal-content-wrap .raven-big-content-view-text-box::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.raven-big-content-view-text-box-modal-img-preview .raven-modal-content-wrap .raven-big-content-view-text-box::-webkit-scrollbar-thumb {
  background: #020202;
}
.raven-big-content-view-text-box-modal-img-preview .raven-modal-content-wrap .raven-big-content-view-text-box .text {
  text-align: justify;
  font-size: 1.65rem;
  line-height: 3rem;
  word-spacing: 0.3rem;
}
@media only screen and (max-width: 48em) {
  .raven-big-content-view-text-box-modal-img-preview .raven-modal-content-wrap {
    width: 100% !important;
  }
}
.raven-big-content-view-text-box-modal-img-preview .raven-modal-content-wrap .raven-big-content-view-text-box-two {
  width: 100%;
  height: 90vh !important;
  background-image: unset;
  display: grid;
  place-items: center;
}

.highlight-red-form {
  color: rgb(255, 15, 0) !important;
  margin-left: 0.5rem !important;
  transform: scale(1.25) translateY(0.3rem);
}

.tooltip-hover-wrap .tool-tip-wrap {
  font-weight: 400 !important;
}

.invite-referee-scroll-bar-box-genenaral-control::-webkit-scrollbar {
  display: none;
}
.invite-referee-scroll-bar-box-genenaral-control .tool-tip-wrap_bottom-right {
  transform: translateY(2rem) translateX(-10%) !important;
}

.border-theme-inner-signnature .display-wrap .display-box .img-box {
  border: 0.15rem solid rgba(208, 213, 221, 0.5);
  display: grid !important;
  place-items: center !important;
  width: 5rem !important;
  height: 5rem !important;
}
.border-theme-inner-signnature .display-wrap .display-box .img-box .img {
  width: 85% !important;
  height: 85% !important;
}/*# sourceMappingURL=index.css.map */