.alert-receiver-list-modal-wrap-index {
  min-height: 35rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  max-height: 50rem;
  overflow-y: scroll;

  .title-text-box {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }

    .text {
      font-size: 1.3rem;
      // margin-bottom: 2rem;
      padding-bottom: 1.5rem;
    }
  }

  .list-box-wrap {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    overflow-y: scroll;
    flex: 1;
    padding-bottom: 10rem;

    &::-webkit-scrollbar {
      display: none;
    }

    & > *:not(:last-child) {
      padding-bottom: 1.5rem;
      border-width: 0.15rem;
    }

    & > *:last-child {
      border: none;
    }

    .list-item {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      .name-num-box{
        display: flex;
        flex-direction: column;

        .name{
            font-size: 1.55rem;
            font-weight: 600;
        }

        .num{
            font-size: 1.25rem;
        }
      }

      .delete-icon {
        margin-left: auto;
        cursor: pointer;

        .img-box {
          width: 4rem;
          height: 4rem;
          background-color: rgba(255, 239, 238, 1);
          border-radius: 50%;
          display: grid;
          place-items: center;

          .img {
            width: 50%;
            height: 50%;
            object-fit: contain;
          }
        }
      }
    }
  }
}
