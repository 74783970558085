.verification-five-status-resuable-box-wrap {
  width: 100%;
  min-height: 45rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.verification-five-status-resuable-box-wrap .img-big-box {
  margin-bottom: 2rem;
}
.verification-five-status-resuable-box-wrap .img-big-box .img-box {
  width: 15rem;
  height: 15rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  overflow: hidden;
}
.verification-five-status-resuable-box-wrap .img-big-box .img-box .img {
  width: 75%;
  height: 75%;
  -o-object-fit: contain;
     object-fit: contain;
}
.verification-five-status-resuable-box-wrap .img-big-box .img-box-bottom {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.verification-five-status-resuable-box-wrap .img-big-box .img-box-bottom .img {
  transform: translateY(1rem);
}
.verification-five-status-resuable-box-wrap .title {
  text-align: center;
  font-size: 2.3rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.verification-five-status-resuable-box-wrap .text {
  font-size: 1.45rem;
  text-align: center;
  line-height: 2.1rem;
}/*# sourceMappingURL=ReUsuableStatus.css.map */