.select-currency-fragment-index-wrap {
  width: max-content;
  position: relative;

  .select-list {
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    padding: 2rem 0rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    // gap: 2rem;
    border-radius: .8rem;
    border-width: 0.15rem;
    background-color: #ffffff;
    max-height: 25rem;
    overflow-y: scroll;
    border: 0.1rem solid rgba(209, 209, 209, 1);
    box-shadow: 0.1rem 0.1rem 8rem 0.1rem rgba(0, 0, 0, 0.05);
    animation: moveDownSettingDrop 0.5s ease-in-out backwards;

    &::-webkit-scrollbar {
      display: none;
    }

    & > *:not(:last-child) {
      border-bottom: 0.1rem solid rgba(229, 230, 230, 1);
      //   padding-bottom: 2rem;
    }

    .cur-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 1.5rem;

      .cur-img {
        margin-right: 1rem;
        .img-box {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          margin-right: 1rme;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .select-value {
        text-transform: uppercase;
        color: rgba(0, 0, 0, 1);
        font-size: 1.5rem;
      }
    }

    .cur-item-selected {
      background-color: rgba(247, 248, 247, 1);
      cursor: not-allowed;

    //   .select-value {
    //     color: #ffffff;
    //   }
    }
  }

  .selected-obj-wrap {
    width: 100%;
    border-radius: 0.8rem;
    display: flex;
    padding: 0.5rem .8rem;
    align-items: center;
    cursor: pointer;
    border: 0.15rem solid rgba(224, 241, 240, 1);

    .selected-value {
      text-transform: uppercase;
      color: rgba(0, 0, 0, 1);
      font-size: 1.5rem;
    }

    .cur-img {
      margin-right: 1rem;
    //   margin-left: 1rem;
      .img-box {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        margin-right: 1rme;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .down-icon {
      margin-left: 1rem;

      .img-box {
        width: 2rem;
        height: 2rem;
        margin-right: unset;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
