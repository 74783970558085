.payment-request-index-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.payment-request-index-wrap .mobile-table-box {
  max-height: 65rem;
  overflow-y: scroll;
  padding-bottom: 10rem;
  padding: 0rem 2rem;
  padding-bottom: 10rem;
}
.payment-request-index-wrap .mobile-table-box::-webkit-scrollbar {
  display: none;
}
.payment-request-index-wrap .new-search-filter-chart-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 3rem;
  margin: 2rem 0rem;
  width: 100%;
}
@media only screen and (max-width: 42em) {
  .payment-request-index-wrap .new-search-filter-chart-box {
    padding: 0rem 1.5rem;
  }
}
.payment-request-index-wrap .new-search-filter-chart-box .chart-hide-show-box .hide-show-box {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
@media only screen and (max-width: 42em) {
  .payment-request-index-wrap .new-search-filter-chart-box .chart-hide-show-box .hide-show-box {
    display: none;
  }
}
.payment-request-index-wrap .new-search-filter-chart-box .chart-hide-show-box .hide-show-box > *:last-child {
  transform: scale(0.8);
}
.payment-request-index-wrap .new-search-filter-chart-box .chart-hide-show-box .hide-show-box span {
  width: 1.8rem;
  height: 1.8rem;
}
.payment-request-index-wrap .new-search-filter-chart-box .search-filter-box {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 2rem;
  position: relative;
  z-index: 100;
  width: 100%;
}
@media only screen and (max-width: 42em) {
  .payment-request-index-wrap .new-search-filter-chart-box .search-filter-box {
    gap: 1rem;
    justify-content: space-between;
    width: 100%;
  }
}
.payment-request-index-wrap .new-search-filter-chart-box .search-filter-box .badge-filter-option-box {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.payment-request-index-wrap .new-search-filter-chart-box .search-filter-box .badge-filter-option-box .option-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
  border-radius: 2rem;
}
.payment-request-index-wrap .new-search-filter-chart-box .search-filter-box .badge-filter-option-box .option-box .icon {
  cursor: pointer;
  transform: translateY(0.1rem);
}
.payment-request-index-wrap .new-search-filter-chart-box .search-filter-box .export-table-content-box {
  display: grid;
  place-items: center;
  padding: 0.5rem 1.5rem;
  border-radius: 0.8rem;
  cursor: pointer;
}
.payment-request-index-wrap .new-search-filter-chart-box .search-filter-box .export-table-content-box:hover .img-box {
  transform: translateY(0.4rem);
}
.payment-request-index-wrap .new-search-filter-chart-box .search-filter-box .export-table-content-box .img-box {
  width: 2.2rem;
  height: 2.2rem;
  transition: all 0.3s;
}
.payment-request-index-wrap .new-search-filter-chart-box .search-filter-box .export-table-content-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payment-request-index-wrap .new-search-filter-chart-box .search-filter-box .filter-drop-box {
  position: relative;
}
.payment-request-index-wrap .new-search-filter-chart-box .search-filter-box .filter-drop-box .drop-box-wrap {
  position: absolute;
  border-radius: 1.2rem;
  top: 110%;
  right: 0;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  min-width: 30rem;
  gap: 2rem;
  animation: moveDownSettingDrop 0.2s ease-in-out backwards;
}
.payment-request-index-wrap .new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box {
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 0.8rem;
  cursor: pointer;
}
.payment-request-index-wrap .new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .line-box {
  margin: auto 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 1.8rem;
  align-items: center;
}
.payment-request-index-wrap .new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .line-box span {
  display: inline-block;
  height: 0.15rem;
  width: 100%;
  border-radius: 1rem;
  background-color: #676767;
}
.payment-request-index-wrap .new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .line-box > *:nth-child(2) {
  width: 70%;
}
.payment-request-index-wrap .new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .line-box > *:last-child {
  width: 40%;
}
.payment-request-index-wrap .new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .text {
  font-weight: 700;
}
.payment-request-index-wrap .table-wrap {
  padding-bottom: 8rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  z-index: 3;
  flex: 1;
  overflow-y: scroll;
}
.payment-request-index-wrap .table-wrap::-webkit-scrollbar {
  display: none;
}
.payment-request-index-wrap .table-wrap .mobile-table-box {
  max-height: 65rem;
  overflow-y: scroll;
  padding-bottom: 10rem;
}
.payment-request-index-wrap .table-wrap .mobile-table-box::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 48em) {
  .payment-request-index-wrap .table-wrap {
    padding-bottom: 3rem;
    width: 90%;
    margin: 3rem auto 0rem auto;
  }
}
.payment-request-index-wrap .table-wrap .table .table-body tr .table-data-action, .payment-request-index-wrap .table-wrap .table .table-body .table-row .table-data-action, .payment-request-index-wrap .table-wrap .table tbody tr .table-data-action, .payment-request-index-wrap .table-wrap .table tbody .table-row .table-data-action {
  justify-content: flex-start;
}
@media only screen and (max-width: 51em) {
  .payment-request-index-wrap .table-wrap .wrap-scroll {
    overflow-x: scroll;
    width: 100%;
    padding-bottom: 3rem;
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
  .payment-request-index-wrap .table-wrap .wrap-scroll::-webkit-scrollbar:vertical {
    display: none;
  }
  .payment-request-index-wrap .table-wrap .wrap-scroll::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.4rem;
  }
  .payment-request-index-wrap .table-wrap .wrap-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }
  .payment-request-index-wrap .table-wrap .wrap-scroll::-webkit-scrollbar-thumb {
    background: #020202;
    border-radius: 30rem;
  }
  .payment-request-index-wrap .table-wrap .wrap-scroll::-webkit-scrollbar-thumb:hover {
    background: #020202;
  }
}
@media only screen and (max-width: 51em) {
  .payment-request-index-wrap .table-wrap .wrap-scroll .table {
    width: 95rem;
  }
}
@media only screen and (max-width: 48em) {
  .payment-request-index-wrap .table-wrap .wrap-scroll {
    display: none;
  }
}
.payment-request-index-wrap .table-wrap .table-pagination-box {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  padding: 0rem 3rem;
}
.payment-request-index-wrap .table-wrap .table-body tr td {
  padding-top: 1.8rem !important;
  padding-bottom: 1.8rem !important;
  font-size: 1.45rem !important;
}
.payment-request-index-wrap .table-wrap .table-body tr > *:first-child {
  padding-left: 3rem;
  padding-right: 2rem;
}
.payment-request-index-wrap .table-wrap .table-body tr > *:last-child {
  padding-right: 3rem;
}
.payment-request-index-wrap .table-wrap .table-body tr .action-box {
  justify-content: center;
}
.payment-request-index-wrap .table-wrap .table-body tr .status-type-wrap {
  background-color: #e9f5ff;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #476885;
}
.payment-request-index-wrap .table-wrap .table-body tr .status-type-wrap span {
  font-weight: 400 !important;
  font-size: 1.2rem;
}
.payment-request-index-wrap .table-wrap .table-body tr .status-type-wrap-fail {
  background-color: #fff5f5;
  color: #ff0f00;
}
.payment-request-index-wrap .table-wrap .table-body tr .status-type-wrap-success {
  background-color: #e8fff6;
  color: #1ace37;
}
.payment-request-index-wrap .table-wrap .table-body tr .status-type-wrap-pending {
  background-color: #fff6ed;
  color: #ea872d;
}
.payment-request-index-wrap .table-wrap .transaction-action-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding: 0.45rem 0rem;
  margin: 0rem auto;
  min-width: 9rem;
}
.payment-request-index-wrap .table-wrap .transaction-action-wrap .img-box {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: transform 0.3s ease-in-out;
}
.payment-request-index-wrap .table-wrap .transaction-action-wrap .img-box:hover {
  transform: scale(1.05);
}
.payment-request-index-wrap .table-wrap .transaction-action-wrap .img-box .img {
  width: 60%;
  height: 60%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payment-request-index-wrap .table-wrap .transaction-action-wrap .img-box-delete {
  background-color: rgb(255, 245, 245);
}
.payment-request-index-wrap .table-wrap .transaction-action-wrap .icon-wraps {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.payment-request-index-wrap .table-wrap .transaction-action-wrap .img-box-main {
  margin: 0 auto;
}
.payment-request-index-wrap .table-wrap .transaction-action-wrap .drop-down-box-payroll {
  position: relative;
  transform: unset !important;
}
.payment-request-index-wrap .table-wrap .transaction-action-wrap .drop-down-box-payroll .drop-down-wrap-payroll {
  position: absolute;
  top: 120%;
  right: 2rem;
  width: -moz-max-content;
  width: max-content;
  background-color: #ffffff;
  transform: scale(0);
  transform-origin: top right;
  transition: transform 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;
  border-radius: 1.2rem;
  gap: 2.5rem;
}
.payment-request-index-wrap .table-wrap .transaction-action-wrap .drop-down-box-payroll .drop-down-wrap-payroll .row-child {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.payment-request-index-wrap .table-wrap .transaction-action-wrap .drop-down-box-payroll .drop-down-wrap-payroll .row-child .img-wrap .img-box {
  width: 2rem;
  height: 2rem;
}
.payment-request-index-wrap .table-wrap .transaction-action-wrap .drop-down-box-payroll .drop-down-wrap-payroll .row-child .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payment-request-index-wrap .table-wrap .transaction-action-wrap .drop-down-box-payroll .drop-down-wrap-payroll .row-child .text {
  font-size: 1.35rem;
  font-weight: 500;
}
.payment-request-index-wrap .table-wrap .transaction-action-wrap .drop-down-box-payroll .drop-down-wrap-payroll .row-child .text-red {
  color: #FF0000;
}
.payment-request-index-wrap .table-wrap .transaction-action-wrap .drop-down-box-payroll .drop-down-wrap-payroll-up {
  top: unset;
  bottom: 120%;
}
.payment-request-index-wrap .table-wrap .transaction-action-wrap .drop-down-box-payroll .drop-down-wrap-payroll-show {
  transform: scale(1);
}
.payment-request-index-wrap .table-wrap .type-wrap {
  padding: 0.8rem 1.2rem;
  background-color: #f7f8f7;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
}
.payment-request-index-wrap .table-wrap .type-wrap span {
  font-size: 1.2rem;
}
.payment-request-index-wrap .table-wrap .type-text-box {
  display: flex;
  align-items: center;
  width: -moz-max-content;
  width: max-content;
}
.payment-request-index-wrap .table-wrap .type-text-box .text-box {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}
.payment-request-index-wrap .table-wrap .type-text-box .text-box .text {
  font-size: 1.55rem;
  font-weight: 600;
}
.payment-request-index-wrap .table-wrap .type-text-box .text-box .text-small {
  font-size: 1.3rem;
  font-weight: 400 !important;
}
.payment-request-index-wrap .table-wrap .type-text-box .img-wrap {
  display: grid;
  place-items: center;
}
.payment-request-index-wrap .table-wrap .type-text-box .img-wrap .img-box-cre-deb {
  width: 3rem;
  height: 3rem;
  margin-right: 2rem;
  background-color: #1ace37 !important;
  border-radius: 50%;
  display: grid;
  place-items: center;
  flex: 0 0 5%;
}
.payment-request-index-wrap .table-wrap .type-text-box .img-wrap .img-box-cre-deb .img {
  width: 35%;
  height: 35%;
  -o-object-fit: contain;
     object-fit: contain;
  filter: brightness(1000%);
}
.payment-request-index-wrap .table-wrap .type-text-box .img-wrap .img-box-debit {
  background-color: #ff0f00 !important;
}

.confirm-payment-request-index-wrap {
  width: 40rem;
  margin-top: 4rem;
  padding-bottom: 80rem;
}/*# sourceMappingURL=PayentRequestIndex.css.map */