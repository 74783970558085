.security-lock-wrap-box {
    display: flex;
    align-items: center;
    background-color: rgba(204, 204, 204, 0.3);
    border-radius: 0.4rem;
    border: 0.1rem solid #cccccc;
    align-self: flex-start;
    width: max-content;
    padding: 0.3rem 0.8rem;
    cursor: pointer;
    transition: all 0.3s;

    .img-box{
        display: grid;
        place-items: center;
    }

    &:hover {
      transform: translateY(-0.1rem);
    }

    .text {
      font-size: 1.2rem;
      margin-left: 1rem;
      color: #676767 !important;
      // color: red;
    }
  }