.sign-up-wrap {
  width: 85%;
  display: flex;
  flex-direction: column;
  margin: 0rem auto;
  min-height: 100%;
}
@media only screen and (max-width: 42em) {
  .sign-up-wrap {
    width: 97%;
  }
}
.sign-up-wrap .text-box {
  margin-bottom: 2rem;
}
@media only screen and (max-width: 42em) {
  .sign-up-wrap .text-box {
    margin-bottom: 1rem;
  }
}
.sign-up-wrap .text-box .small-title {
  color: #676767;
  font-size: 2.4rem;
  font-weight: 300;
}
@media only screen and (max-width: 42em) {
  .sign-up-wrap .text-box .small-title {
    font-size: 2rem;
  }
}
.sign-up-wrap .text-box .big-title {
  font-size: 2.4rem;
  color: #020202;
  font-weight: 700;
}
@media only screen and (max-width: 42em) {
  .sign-up-wrap .text-box .big-title {
    font-size: 2rem;
  }
}
.sign-up-wrap .form {
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 2rem;
}
.sign-up-wrap .form .input-group .phone-wrap span,
.sign-up-wrap .form .input-group .show-hide {
  color: #020202;
}
.sign-up-wrap .form .password-wrapper-check {
  position: relative;
  z-index: 10;
}
.sign-up-wrap .form .two-group {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
}
@media only screen and (max-width: 42em) {
  .sign-up-wrap .form .two-group {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
.sign-up-wrap .form .two-group .form-group {
  width: 100%;
}
.sign-up-wrap .form .two-group .form-group .form-input {
  width: 100%;
}
.sign-up-wrap .form .register-team-submit-btn {
  margin-top: 3rem;
}
.sign-up-wrap .form .check-text-box {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.sign-up-wrap .form .check-text-box .text {
  font-size: 1.35rem;
  color: #676767;
}
.sign-up-wrap .form .check-text-box .text span {
  color: var(--primary-purple);
  font-weight: 600;
  cursor: pointer;
}
.sign-up-wrap .form .check-text-box .check-box-wrap .input-check:checked ~ .label-check {
  border: none;
}
.sign-up-wrap .form .check-text-box .check-box-wrap .label-check {
  border: 0.1rem solid #cccccc !important;
}
.sign-up-wrap .form .btn-submit {
  width: 100%;
  margin-top: 2rem;
}
.sign-up-wrap .form .already-text {
  font-size: 1.35rem;
  color: #676767;
  text-align: center;
}
.sign-up-wrap .form .already-text span {
  color: var(--primary-purple);
  font-weight: 600;
  cursor: pointer;
}
@media only screen and (max-width: 42em) {
  .sign-up-wrap .form .already-text span {
    font-size: 1.55rem;
  }
}

.sign-in-wrap {
  display: flex;
  flex-direction: column;
  margin: 0rem auto;
  min-height: 100%;
}
.sign-in-wrap .text-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2rem;
}
.sign-in-wrap .text-box .small-title {
  color: #676767;
  font-size: 1.4rem;
  font-weight: 400;
}
.sign-in-wrap .text-box .big-title {
  font-size: 2.1rem;
  color: #020202;
  font-weight: 700;
}
.sign-in-wrap .form {
  margin: auto 0rem;
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 2rem;
}
@media only screen and (max-width: 42em) {
  .sign-in-wrap .form {
    margin-top: 3rem;
  }
}
.sign-in-wrap .form .security-tip-wrap {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.9rem 1.5rem;
  background-color: rgb(255, 246, 237);
  border-radius: 0.8rem;
  margin-bottom: 1.5rem;
}
.sign-in-wrap .form .security-tip-wrap .text {
  white-space: nowrap;
  font-size: 1.25rem;
  color: rgb(27, 27, 27);
}
@media only screen and (max-width: 48em) {
  .sign-in-wrap .form .security-tip-wrap .text {
    white-space: unset;
  }
}
.sign-in-wrap .form .security-tip-wrap .text span {
  font-weight: 600;
  color: rgb(234, 135, 45);
}
.sign-in-wrap .form .security-tip-wrap .img-wrap .img-box {
  width: 2rem;
  height: 2rem;
}
.sign-in-wrap .form .security-tip-wrap .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.sign-in-wrap .form .forget-text {
  margin-top: -1.2rem;
  align-self: flex-end;
  cursor: pointer;
  color: var(--primary-purple);
  font-weight: 600;
  font-size: 1.3rem;
}
.sign-in-wrap .form .btn-submit {
  width: 100%;
  margin-top: 2rem;
}
.sign-in-wrap .form .already-text {
  font-size: 1.35rem;
  color: #676767;
  text-align: center;
}
.sign-in-wrap .form .already-text span {
  color: var(--primary-purple);
  font-weight: 600;
  cursor: pointer;
}
.sign-in-wrap .form-forget-password {
  width: 40rem;
}
@media only screen and (max-width: 48em) {
  .sign-in-wrap .form-forget-password {
    width: 100%;
  }
}
.sign-in-wrap .form-forget-password .security-tip-wrap {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.9rem 1.5rem;
  background-color: rgb(255, 246, 237);
  border-radius: 0.8rem;
  margin-bottom: 1.5rem;
}
.sign-in-wrap .form-forget-password .security-tip-wrap .text {
  white-space: nowrap;
  font-size: 1.25rem;
  color: rgb(27, 27, 27);
}
@media only screen and (max-width: 48em) {
  .sign-in-wrap .form-forget-password .security-tip-wrap .text {
    white-space: unset;
  }
}
.sign-in-wrap .form-forget-password .security-tip-wrap .text span {
  font-weight: 600;
  color: rgb(234, 135, 45);
}
.sign-in-wrap .form-forget-password .security-tip-wrap .img-wrap .img-box {
  width: 2rem;
  height: 2rem;
}
.sign-in-wrap .form-forget-password .security-tip-wrap .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.sign-in-wrap .form-forget-password .password-wrapper-check {
  position: relative;
  z-index: 10;
}
.sign-in-wrap .form-forget-password .back-text {
  align-self: flex-start;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.sign-in-wrap .form-forget-password .back-text:hover .icon {
  animation: moveBackFort 0.3s ease-in-out;
}
.sign-in-wrap .form-forget-password .back-text .icon {
  margin-right: 1rem;
  display: inline-block;
  fill: #676767;
  width: 1.2rem;
  height: 1.2rem;
}
.sign-in-wrap .form-forget-password .back-text .text {
  font-size: 1.35rem;
  color: #676767;
  text-align: center;
}
.sign-in-wrap .form-forget-password .back-text .text span {
  color: var(--primary-purple);
  font-weight: 600;
  cursor: pointer;
}
.sign-in-wrap .form-forget-password .img-wrap-forget {
  align-self: center;
  margin-bottom: -1rem;
}
@media only screen and (max-width: 42em) {
  .sign-in-wrap .form-forget-password .img-wrap-forget {
    align-self: flex-start;
  }
}
.sign-in-wrap .form-forget-password .img-wrap-forget .img-box {
  width: 8rem;
  height: 8rem;
}
@media only screen and (max-width: 42em) {
  .sign-in-wrap .form-forget-password .img-wrap-forget .img-box {
    width: 6rem;
    height: 6rem;
  }
}
.sign-in-wrap .form-forget-password .img-wrap-forget .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.sign-in-wrap .form-forget-password .text-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0.5rem;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 42em) {
  .sign-in-wrap .form-forget-password .text-box {
    text-align: left;
  }
}
.sign-in-wrap .form-forget-password .text-box .small-title {
  color: #676767;
  font-size: 1.4rem;
  font-weight: 400;
}
.sign-in-wrap .form-forget-password .text-box .big-title {
  font-size: 2.1rem;
  color: #020202;
  font-weight: 700;
}

@keyframes moveBackFort {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.2rem);
  }
  100% {
    transform: translateX(0rem);
  }
}
.form-modal-auth {
  display: flex;
  flex-direction: column;
}
.form-modal-auth .select-wrap-account-auth {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: 35rem;
  overflow-y: scroll;
}
.form-modal-auth .select-wrap-account-auth::-webkit-scrollbar {
  display: none;
}
.form-modal-auth .select-wrap-account-auth .select-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 0.8rem;
  border: 0.2rem solid rgb(238, 238, 238);
  cursor: pointer;
}
.form-modal-auth .select-wrap-account-auth .select-item .name {
  color: #020202;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
}
.form-modal-auth .select-wrap-account-auth .select-item .name .status-type-wrap {
  background-color: #fff6ed;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #ea872d;
  margin-left: 2rem;
}
.form-modal-auth .select-wrap-account-auth .select-item .name .status-type-wrap span {
  font-size: 1.2rem;
}
.form-modal-auth .select-wrap-account-auth .select-item .check-wrap {
  margin-left: auto;
  border: 0.2rem solid rgb(238, 238, 238);
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
}
.form-modal-auth .select-wrap-account-auth .select-item .check-wrap::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  height: 40%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
}
.form-modal-auth .select-wrap-account-auth .select-item .check-wrap-active {
  background-color: rgb(117, 90, 226);
}
.form-modal-auth .select-wrap-account-auth .select-item .img-avatar-wrap {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  overflow: hidden;
}
.form-modal-auth .select-wrap-account-auth .select-item .img-avatar-wrap .img-box {
  width: 100%;
  height: 100%;
}
.form-modal-auth .select-wrap-account-auth .select-item .img-avatar-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.form-modal-auth .select-wrap-account-auth .select-item .img-avatar-wrap .avatar-box {
  width: 100%;
  height: 100%;
  background-color: #020202;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-modal-auth .select-wrap-account-auth .select-item .img-avatar-wrap .avatar-box span {
  font-weight: 500;
  font-size: 1.2rem;
  color: #ffffff;
  display: inline-block;
}
.form-modal-auth .select-wrap-account-auth .select-item .img-avatar-wrap .avatar-box-two {
  background-color: unset;
}
.form-modal-auth .select-wrap-account-auth-in-app .select-item .img-avatar-wrap .img-box {
  background-color: #020202 !important;
}
.form-modal-auth .select-wrap-account-auth-in-app .select-item .img-avatar-wrap .img-box-cur {
  background-color: transparent !important;
}
.form-modal-auth .select-wrap-account-auth-in-app .select-item .img-avatar-wrap .avatar-box {
  background-color: #020202 !important;
}
.form-modal-auth .pin-group .pin_field_group {
  width: 85%;
}
.form-modal-auth .pin-group .black-white-color {
  color: #020202;
}
.form-modal-auth .pin-group .resend-text {
  color: var(--primary-purple);
  margin-bottom: 0rem;
  align-self: flex-start;
  margin-top: 2rem;
  cursor: pointer;
  font-weight: 600;
}
.form-modal-auth .resend-text {
  color: var(--primary-purple);
  margin-bottom: 0rem;
  align-self: flex-end;
  cursor: pointer;
  font-weight: 600;
}
.form-modal-auth .text-box {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.5rem;
  margin-bottom: 2rem;
}
.form-modal-auth .text-box .small-title {
  color: #676767;
  font-size: 1.45rem;
  font-weight: 400;
}
.form-modal-auth .text-box .big-title {
  font-size: 2rem;
  color: #020202;
  font-weight: 700;
}

.form-modal-switch-business-account .agree-checkbox-wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem 0rem;
  flex: 1;
}
.form-modal-switch-business-account .agree-checkbox-wrap .text {
  font-size: 1.35rem;
  line-height: 2.4rem;
  text-align: left;
}
.form-modal-switch-business-account .form-group .input-group input {
  border: none !important;
}/*# sourceMappingURL=SignUp.css.map */