.account-drop-down-box-toggle-wrap {
  display: flex;
  flex-direction: column;
  // padding: 2rem;
  border-radius: 1.2rem;
  // gap: 2rem;
  min-width: 25rem;
  animation: moveDownSettingDrop 0.5s ease-in-out backwards;
  overflow: hidden;
  max-height: 45rem;


  @media only screen and (max-width: 48em){
    max-height: 90vh;
  }

  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .label-value-box {
    display: flex;
    flex-direction: column;

    .label {
      font-weight: 600;
      font-size: 1.35rem;
      white-space: nowrap;
    }

    .value {
      font-size: 1.25rem;
    }
  }

  .account-select {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    padding: 2rem;

    .primary-box {
      margin-left: auto;
      background-color: rgba(240, 238, 255, 1);
      padding: 0.8rem 1.5rem;
      border-radius: 2rem;

      .text {
        font-weight: 500;
        color: rgba(117, 90, 226, 1);
      }
    }

    .img-wrap {
      .img-box {
        width: 3rem;
        height: 3rem;

        .img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .active-sub-account {
    .check-box {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      display: grid;
      place-items: center;
      background-color: #ffffff;

      .icon {
        width: 1rem;
        height: 1rem;
      }
    }
    // background-color: rgba(247, 248, 247, 1) !important;
  }
}

// .account-drop-down-box-toggle-wrap-big {
//     width: 100%;
//     animation: unset;

//     .account-select {
//       .primary-box {
//         margin-left: auto;
//         background-color: rgba(240, 238, 255, 1);
//         padding: 0.8rem 1.5rem;
//         border-radius: 2rem;

//         .text {
//           font-weight: 500;
//           color: rgba(117, 90, 226, 1);
//         }
//       }
//     }
//   }

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
    // visibility: hidden;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
    // visibility: ;
  }
}
