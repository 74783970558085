.account-change-drop-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  padding-top: 2rem;
  gap: 1rem;

  .change-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255, 246, 237, 1);
    padding: 1.5rem;
    border-radius: 0.8rem;

    .change-text {
      .text {
        color: rgba(234, 135, 45, 1);
        font-weight: 600;
        cursor: pointer;
        font-size: 1.5rem;
      }
    }

    .change-name-info {
      .name-icon {
        display: flex;
        align-items: center;
        gap: 1rem;

        .img-box {
          width: 1.5rem;
          height: 1.5rem;
          display: grid;
          place-items: center;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .name {
          font-size: 1.65rem;
          font-weight: 600;
        }
      }
    }
  }
}

.account-change-drop-wrap-dark {
  .change-wrap {
    background-color: rgba(247, 247, 247, 1);

    .change-text{
      position: relative;
      z-index: 20;
      .text{
        color: rgba(117, 90, 226, 1);
      }

      .select-toggle-box{
        position: absolute;
        bottom: 100%;
        right: 0;
      }

      .select-toggle-box-down{
        top: 100%;
        bottom: unset;
      }
    }
  }
}
