.settings-modal-wrap {
  .generate-modal-wrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    min-height: 35rem;

    .select-type-wrap {
      max-height: 0;
      overflow-x: hidden;
      overflow-y: hidden;
      transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
      -webkit-transition: max-height 0.8s;
      -moz-transition: max-height 0.8s;
      width: 100%;
    }

    .select-type-wrap-show {
      overflow-x: visible;
      overflow-y: visible;
      max-height: 30rem;
      transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
    }

    .theme-preference-wrap {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;

      .btn-text-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding: 0rem 2rem;

        .update {
          font-weight: 700;
          color: #755ae2;
          cursor: pointer;
          font-size: 1.6rem;
        }

        .text-box {
          display: flex;
          flex-direction: column;
          gap: 0.2rem;

          .content-title {
            font-size: 1.7rem;
            font-weight: 700;
            padding: 0rem;
          }
          margin-bottom: 1rem;
        }
      }

      .content-title {
        font-size: 1.7rem;
        font-weight: 700;
        padding: 0rem 1.6rem;
      }

      .select-quick-action-wrap {
        padding: 2.5rem 2rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;

        .child {
          padding: 1.2rem 2rem;
          border-radius: 1.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          cursor: pointer;
          width: max-content;
          // padding-left: unset;
          box-shadow: 0px 3.56px 7.12px -1.78px rgba(51, 51, 51, 0.1),
            0px 1.78px 3.56px -1.78px rgba(51, 51, 51, 0.06);
          // display: none;

          .check-box {
            width: 1.4rem;
            height: 1.4rem;
            display: grid;
            place-items: center;
            background-color: #ffffff;
            border-radius: 50%;

            .icon {
              width: 0.65rem;
              height: 0.65rem;
              fill: #755ae2;
            }
          }

          span {
            font-size: 1.25rem;
            font-weight: 700;
            display: inline-block;
          }
        }

        .child-active {
          // cursor: default;
          background-color: #755ae2 !important;

          span {
            color: #ffffff !important;
          }
        }
      }

      .select-wrapper {
        display: flex;
        // padding-left: 1.6rem;
        gap: 3rem;
        // display: none;

        @media only screen and (max-width: 42em) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          padding: 0rem 1.5rem;
        }

        .box {
          .img-wrap {
            min-height: 15rem;
            min-width: 20rem;
            background-position: center;
            background-size: contain;
            background-image: url("../../../assets/light-theme-example-img.png");
            background-repeat: no-repeat;
            position: relative;
            border-radius: 0.8rem;

            @media only screen and (max-width: 42em) {
              min-width: unset;
            }

            &::before {
              border-color: #755ae2 !important;
              border-width: 0.2rem;
            }

            .check-box {
              background-color: #755ae2 !important;

              .icon {
                color: #ffffff !important;
                fill: #ffffff !important;
              }
            }
          }

          .text {
            font-weight: 700;
            margin-top: 1rem;
            font-size: 1.35rem;
          }

          .minimal {
            background-image: url("../../../assets/sidenav-light-example-img.png") !important;
          }
          .solid {
            background-image: url("../../../assets/sidenav-dark-example-img.png") !important;
          }
        }

        .dark-img{
          background-image: url("../../../assets/dark-theme-example-img.png") !important;
        }
        .light-img {
          background-image: url("../../../assets/light-theme-example-img.png") !important;
        }

        // & > *:nth-child(2) {
        //   .dark-img{
        //     background-image: url("../../../assets/dark-theme-example-img.png");
        //   }
        //   .light-img {
        //     background-image: url("../../../assets/dark-theme-example-img.png");
        //   }
        // }
      }

      .table-select-font-wrap {
        display: flex;
        align-items: center;
        gap: 2rem;
        // margin-left: 2rem;

        .child {
          padding: 1.2rem 2rem;
          border-radius: 1.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          cursor: pointer;
          width: max-content;
          box-shadow: 0px 3.56px 7.12px -1.78px rgba(51, 51, 51, 0.1),
            0px 1.78px 3.56px -1.78px rgba(51, 51, 51, 0.06);

          .check-box {
            width: 1.4rem;
            height: 1.4rem;
            display: grid;
            place-items: center;
            background-color: #ffffff;
            border-radius: 50%;

            .icon {
              width: 0.65rem;
              height: 0.65rem;
              fill: #755ae2;
            }
          }

          span {
            font-size: 1.25rem;
            font-weight: 700;
            display: inline-block;
          }
        }

        .child-active {
          // cursor: default;
          background-color: #755ae2 !important;

          span {
            color: #ffffff !important;
          }
        }
      }
    }

    .title {
      font-size: 1.7rem;
      font-weight: 800;
    }

    .text {
      font-size: 1.3rem;
    }

    .select-wrap {
      margin-top: 2rem;
      // padding-left: 0rem;
    }

    .input-type-wrap {
      margin-top: 2rem;
      // min-height: 15rem;

      .select-type-wrap {
        max-height: 0;
        overflow-x: hidden;
        overflow-y: hidden;
        transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
        -webkit-transition: max-height 0.8s;
        -moz-transition: max-height 0.8s;
        width: 100%;
      }

      .select-type-wrap-show {
        overflow-x: visible;
        overflow-y: visible;
        max-height: 30rem;
        transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
      }

      .custom-type-wrap {
        max-height: 0;
        overflow-x: hidden;
        overflow-y: hidden;
        transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
        -webkit-transition: max-height 0.8s;
        -moz-transition: max-height 0.8s;
        width: 100%;
      }

      .custom-type-wrap-show {
        overflow-x: visible;
        overflow-y: visible;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        max-height: 30rem;
        transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
      }
    }

    .input-type-wrap-two {
      min-height: unset;
    }

    .electricity-child-wrap {
      max-height: 40vh;
      // background-color: red;
      margin-top: 1.5rem;
      overflow-y: scroll;
      // padding: 1.5rem  1rem;
      display: flex;
      flex-direction: column;

      .child-wrap-box {
        flex: 1;
        // background-color: red;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        overflow-y: scroll;
        padding-bottom: 4rem;

        &::-webkit-scrollbar:horizontal {
          display: none;
        }

        /* width */
        &::-webkit-scrollbar {
          width: 0.6rem;
          //   transform: translateX(2rem) !important;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          // transform: translateX(2rem) !important;
          -webkit-box-shadow: none !important;
          background-color: transparent !important;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #020202;
          border-radius: 30rem;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #020202;
        }

        .child-wrap {
          display: flex;
          align-items: center;
          padding: 1.5rem;
          border: 0.1rem solid #cccccc;
          border-radius: 1.5rem;
          position: relative;
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
            transform: translateY(-0.1rem);
          }

          & > * {
            position: relative;
            z-index: 3;
          }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            height: 100%;
            width: 100%;
            border: 0.15rem solid #333333;
            border-radius: 1.5rem;
            visibility: hidden;
          }

          .check-box-show {
            position: absolute;
            z-index: 3;
            top: 0;
            right: 0;
            padding: 1rem 1.5rem;
            border-radius: 1.5rem;
            border-top-left-radius: unset;
            border-bottom-right-radius: unset;
            background-color: #333333;
            color: #ffffff;
            display: grid;
            place-items: center;

            .icon {
              color: currentColor;
              width: 1rem;
              height: 1rem;
            }
          }

          .text-box {
            font-size: 1.45rem;

            .label {
              color: #676767;
            }

            .value {
              color: #020202;
              font-weight: 800;
            }
          }

          .img-wrap {
            margin-right: 1.5rem;

            .img-box {
              width: 4.5rem;
              height: 4.5rem;
            }
          }
        }

        .child-wrap-active {
          &:hover {
            transform: unset;
          }

          &::after {
            visibility: visible;
          }
        }
      }
    }

    .customer-invoice-child-wrap {
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      margin-top: 3rem;

      &::-webkit-scrollbar:horizontal {
        display: none;
      }

      .customer-item {
        display: flex;
        align-items: center;
        padding: 1.5rem;
        border-radius: 1.2rem;
        border-width: .15rem;
        cursor: pointer;

        .detail-box {
          display: flex;
          flex-direction: column;

          .name {
            font-size: 1.45rem;
            font-weight: 700;
            display: flex;
            align-items: center;
            gap: .8rem;

            .num-box{
              padding: .6rem 1rem;
              border-radius: 1.2rem;
              display: grid;
              place-items: center;

              span{
                font-weight: 700;
                font-size: 1rem;
              }
            }
          }

          .email-num {
            display: flex;
            align-items: center;
            // font-size: .9rem !important;
            gap: 0.2rem;

            p{
              font-size: 1.25rem !important;
            }
          }
        }

        .avatar-box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          margin-right: 1.5rem;
          gap: 0.1rem;

          span {
            font-weight: 700;
            text-transform: capitalize;
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}
