.success-modal-reuse-wrap {
  .raven-modal-content-wrap {
    padding: 0rem;
    position: relative;
    width: 36rem !important;  

    @media only screen and (max-width: 42em) {
      width: 90vw;
    }

    .close-box {
      display: none !important;
      color: rgba(204, 204, 204, .5);
    }
    .content {
      padding: 0rem !important;
      //   width: 35rem !important;
    }

    .container-wrap {
      padding-top: 2rem;
      padding-bottom: 10rem;

      .success-img-box {
        padding: 0rem 1.5rem;
        width: 100%;
        height: 25rem;
        display: flex;
        flex-direction: column;

        .wrap {
          width: 100%;
          flex: 0 0 80%;
          background: url("../../assets/success-bg-img.png"),
            linear-gradient(137.94deg, #f0fff9 15.22%, #fffaf0 92.42%);
          border-radius: 1.1rem;
          //   background-image: url("../../assets/success-bg-img.png");
          background-size: contain;
          background-position: bottom left;
          background-repeat: no-repeat;
          position: relative;

          &::after{
            content: '';
            position: absolute;
            top: 20%;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../../assets/success-mascot-img.png");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }

      .title {
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: 0.7rem;
        // color: #020202;
        padding: 0rem 2rem;
      }

      .text {
        font-size: 1.35rem;
        color: #676767;
        padding: 0rem 2rem;
      }
    }

    .button-wrapper {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #f7f8f7;
      display: flex;
      align-items: center;
      padding: 1.5rem 2rem;
      gap: 2rem;

      .raven-btn {
        width: 100%;
      }
    }
  }
}
