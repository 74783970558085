.view-schedule-payment-modal .reschedule-payment-box {
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.view-schedule-payment-modal .reschedule-payment-box .title {
  font-size: 1.6rem;
  font-weight: 700;
  align-self: flex-start;
}

.view-schedule-payment-modal .reschedule-payment-box .underline {
  width: 100%;
  height: 0.1rem;
  background-color: #f0eeff;
  margin: 1.3rem 0rem;
  align-self: stretch;
}

.view-schedule-payment-modal .reschedule-payment-box .wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.view-schedule-payment-modal .reschedule-payment-box .wrap .selected {
  background-color: #020202;
  border: none;
}

.view-schedule-payment-modal .reschedule-payment-box .wrap .form-input {
  border: none;
  outline: none;
  background-color: #f7f8f7;
  padding: 1.2rem;
  flex: 1;
  position: relative;
  display: inline-block;
  width: 76%;
  border-radius: 0.8rem;
  border: 0.1rem solid #cccccc;
  font-size: 1.4rem;
  color: #020202;
  position: relative;
  z-index: 3;
}

.view-schedule-payment-modal .reschedule-payment-box .wrap .form-input::-moz-placeholder {
  color: #b3b3b3;
}

.view-schedule-payment-modal .reschedule-payment-box .wrap .form-input::placeholder {
  color: #b3b3b3;
}

.view-schedule-payment-modal .two-button-wrap {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #f7f8f7;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  padding: 2rem 2rem;
  gap: 2rem;
}

.view-schedule-payment-modal .two-button-wrap .raven-btn {
  width: 100%;
}

.view-schedule-payment-modal .wrap-modal .title {
  font-size: 1.6rem;
  font-weight: 700;
}

.view-schedule-payment-modal .wrap-modal .underline {
  width: 100%;
  height: 0.1rem;
  background-color: #f0eeff;
  margin: 1.3rem 0rem;
}

.view-schedule-payment-modal .label-value-status-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 10rem;
}

.view-schedule-payment-modal .label-value-status-box .box {
  display: flex;
  align-items: flex-start;
  font-size: 1.2rem;
}
.view-schedule-payment-modal .label-value-status-box .box .value-toggle > * {
  transform: scale(0.7);
}

.view-schedule-payment-modal .label-value-status-box .box .label {
  flex: 0 0 50%;
}

.view-schedule-payment-modal .label-value-status-box .box .value {
  flex: 1;
  font-weight: 600;
}

.view-schedule-payment-modal .label-value-status-box .box .status-type-wrap {
  background-color: #e9f5ff;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #476885;
  text-transform: capitalize;
}

.view-schedule-payment-modal .label-value-status-box .box .status-type-wrap span {
  font-size: 1.2rem;
}

.view-schedule-payment-modal .label-value-status-box .box .status-type-wrap-fail {
  background-color: #fff5f5;
  color: #ff0f00;
}

.view-schedule-payment-modal .label-value-status-box .box .status-type-wrap-success {
  background-color: #e8fff6;
  color: #014345;
}

.view-schedule-payment-modal .label-value-status-box .box .status-type-wrap-pending {
  background-color: #fff6ed;
  color: #ea872d;
} /*# sourceMappingURL=ViewScheduleModal.css.map */