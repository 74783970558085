.reusable-add-and-preview-images-initiator-box {
  display: flex;
  flex-direction: column;
  //   background-color: red;
  margin-bottom: 1.5rem;
  width: 100%;

  .label {
    align-self: flex-start;
    margin-bottom: 0.4rem;
    font-size: 1.3rem;
    // color: #020202;
    text-transform: capitalize;
  }

  .add-preview-img-box {
    margin-top: 1rem;
    height: 7.5rem;
    // width: 40rem;
    overflow-x: scroll;
    // padding: 1rem 0rem;
    // border-radius: 0.8rem;

    &::-webkit-scrollbar {
      display: none;
    }

    .inner-scroll {
      display: flex;
      align-items: stretch;
      gap: 2rem;
      width: max-content;
      overflow-x: scroll;

      .general-img-box {
        position: relative;

        &:hover {
          .view-box {
            visibility: visible;
          }
        }

        .view-box {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 2rem;
          height: 2rem;
          z-index: 100;
          transform: translate(-50%, -50%);
          // background-color: red;
          cursor: pointer;
          visibility: hidden;
          transition: all 0.3s ease-in-out;

          .img-box {
            width: 100%;
            height: 100%;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }

    // .outer-box{}

    .box {
      width: 7.5rem;
      height: 7.5rem;
      border-radius: 0.8rem;
      // background-color: red;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-width: 0.15rem;
      position: relative;
      cursor: pointer;

      &:hover {
        .remove-box {
          visibility: visible;
        }
      }

      .remove-box {
        position: absolute;
        top: 0.1rem;
        right: 0.1rem;
        cursor: pointer;
        visibility: hidden;
      }
    }

    .add-box {
      display: grid;
      place-items: center;
      cursor: pointer !important;

      .label {
        width: 100%;
        height: 100%;
        display: grid;
        place-items: center;

        .img-box {
          width: 45%;
          height: 45%;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .input {
        display: none;
      }
    }
  }
}
