.request-pos-wrap-box {
  width: 40rem;
  margin-top: 3rem;
  padding-bottom: 10rem;
  // background-color: red;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 52em) {
    width: 90%;
    margin: 2rem auto;
  }

  .select-pos-request-type-box {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 2rem;

    .description-box-wrap {
      padding: 2rem 1.5rem;
      border-radius: 1.2rem;
      display: flex;
      flex-direction: column;
      margin-top: 2rem;

      .icon-subtitle {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        gap: 1rem;

        .img-wrap {
          display: grid;
          place-items: center;

          .img-box {
            width: 2rem;
            height: 2rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .sub-title {
          font-size: 1.35rem;
          text-transform: uppercase;
        }
      }

      .list-box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .list-item {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .black-fade-white-bg {
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 50%;
            display: inline-block;
          }
        }
      }
    }

    .select-title {
      font-size: 1.5rem;
    }

    // .img-select-wrap-box{}

    .img-select-wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2.5rem;

      .img-wrap {
        display: flex;
        flex-direction: column;
        padding: 3rem 2rem;
        border-radius: 1rem;
        gap: 1.5rem;
        align-items: center;
        // min-height: 23rem;
        justify-content: center;

        .img-box {
          width: 10rem;
          height: 7.5rem;
          display: flex;
          align-items: center;
          // display: none;

          .img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            // display: none;
          }
        }

        .name {
          text-align: center;
          font-size: 1.25rem;
          margin-top: auto;
        }
      }
    }
  }
}
.request-pos-wrap-box .download-info-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  border-radius: 1.2rem;
}
.request-pos-wrap-box .download-info-box .text-icon-box {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.request-pos-wrap-box .download-info-box .text-icon-box .img-box {
  margin-right: 1rem;
  display: grid;
  place-items: center;
}
.request-pos-wrap-box .download-info-box .text-icon-box .text {
  font-size: 1.3rem;
}
.request-pos-wrap-box .download-info-box .text-icon-box .text span {
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s;
  display: inline-block;
}
.request-pos-wrap-box .download-info-box .dot-text {
  display: flex;
}
.request-pos-wrap-box .download-info-box .dot-text .dot {
  margin-right: 0.5rem;
}
.request-pos-wrap-box .download-info-box .dot-text .text {
  font-size: 1.3rem;
}
.request-pos-wrap-box .download-info-box .dot-text .text span {
  font-weight: 700;
  /* color: #020202; */
}
.request-pos-wrap-box .form-input-wrap {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .terminal-trans-pin {
    // display: none;
    .pin-group {
      // grid-template-columns: unset;
      width: 60%;
      // .pin_field_group {
      //   // width: 4.5rem;
      // }
    }
  }
}

.set-terminal-transaction-pin-modal-wrap {
  margin-bottom: 1.5rem;
  .title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  .text {
    font-size: 1.3rem;
    // margin-bottom: 2rem;
    padding-bottom: 1.5rem;
  }

  .pin-input-two-wrap {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 3rem;

    .one-pin-wrap {
      max-height: 15rem;
      margin-top: 2rem;
      transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
    }

    .one-pin-wrap-hide {
      max-height: 0;
      overflow-x: hidden;
      overflow-y: hidden;
      transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
      -webkit-transition: max-height 0.8s;
      -moz-transition: max-height 0.8s;
      width: 100%;
      margin-top: unset;
    }

    .two-pin-wrap {
      display: flex;
      flex-direction: column;
      max-height: 0;
      overflow-x: hidden;
      overflow-y: hidden;
      transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
      -webkit-transition: max-height 0.8s;
      -moz-transition: max-height 0.8s;
      width: 100%;
    }

    .two-pin-wrap-show {
      gap: 2rem;
      max-height: 30rem;
      transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
    }
  }
  .terminal-trans-pin {
    // display: none;
    .pin-group {
      // grid-template-columns: unset;
      width: 85%;
      // .pin_field_group {
      //   // width: 4.5rem !important;
      // }
    }
  }
}

.request-pos-wrap-box .form-input-wrap .index-wrap-one {
  position: relative;
  z-index: 15;
}
.request-pos-wrap-box .form-input-wrap .two-grid-box {
  display: grid;
  grid-template-columns: 10rem 1fr;
  grid-gap: 1.5rem;
}
.request-pos-wrap-box .mascot-description-box {
  height: 12rem;
  border-radius: 0.8rem;
  background-image: url("../../../assets/request-terminal-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.settle-balance-pos-wrap {
  padding-top: 3rem;
  width: 40rem;
  height: 100%;

  @media only screen and (max-width: 52em) {
    width: 90%;
    margin: 2rem auto;
  }

  .verify-name-box {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: -1rem;
    max-height: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    width: 100%;

    & > *:first-child .label-check {
      border-radius: 50%;
      transform: scale(0.8);
    }
    .text {
      // color: var(--primary-black);
      font-weight: 600;
      font-size: 1.3rem;
      margin-left: 0.5rem;
    }
  }

  .verify-name-box-show {
    max-height: 5rem;
    transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  }
}
.settle-balance-pos-wrap .title {
  margin-bottom: 1.5rem;
}
.settle-balance-pos-wrap .select-wrap-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin-bottom: 4rem;
}
.settle-balance-pos-wrap .select-wrap-box .box {
  border-radius: 0.8rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: none;
}
.settle-balance-pos-wrap .select-wrap-box .box .img-box {
  width: 2rem;
  height: 2rem;
}
.settle-balance-pos-wrap .select-wrap-box .box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.settle-balance-pos-wrap .select-wrap-box .box .name {
  margin-top: 3rem;
  font-weight: 600;
}
.settle-balance-pos-wrap .text-line {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  width: 100%;
  margin: 2rem 0rem;
}
.settle-balance-pos-wrap .text-line .text {
  color: #676767;
  background-color: #f7f8f7;
  font-size: 1.1rem;
  border: 0.1rem solid #cccccc;
  padding: 0.5rem 1.1rem;
  border-radius: 10rem;
  font-weight: 600;
  width: -moz-max-content;
  width: max-content;
  text-align: center;
}
.settle-balance-pos-wrap .text-line span {
  height: 0.1rem;
  background-color: rgba(204, 204, 204, 0.5);
}

.settle-balance-pos-wrap .your-account-detail-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.settle-balance-pos-wrap .your-account-detail-box .personal-info-box {
  width: 100%;
  min-height: 14rem;
  background-color: #020202;
  background-image: url("../../../assets/settle-acount-bg-img.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1.2rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.settle-balance-pos-wrap .your-account-detail-box .personal-info-box .box {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.settle-balance-pos-wrap
  .your-account-detail-box
  .personal-info-box
  .box
  .label {
  color: #cccccc;
  font-size: 1.15rem;
}
.settle-balance-pos-wrap
  .your-account-detail-box
  .personal-info-box
  .box
  .value {
  font-weight: 700;
  color: #ffffff;
  font-size: 1.3rem;
}
.settle-balance-pos-wrap .toggle-slide-text-box {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  justify-content: space-between;
}
.settle-balance-pos-wrap .toggle-slide-text-box > *:last-child {
  align-self: center;
  transform: scale(0.8);
}
.settle-balance-pos-wrap .toggle-slide-text-box .text-info-box {
  display: flex;
  align-items: center;
}
.settle-balance-pos-wrap .toggle-slide-text-box .text-info-box .img-box {
  width: 1.2rem;
  height: 1.2rem;
  display: grid;
  place-items: center;
  margin-left: 0.7rem;
}
.settle-balance-pos-wrap .toggle-slide-text-box .text-info-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.settle-balance-pos-wrap .toggle-slide-text-box .text-info-box .text {
  font-size: 1.2rem;
}
.settle-balance-pos-wrap .form-input-wraps {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.settle-balance-pos-wrap .yellow-info-box {
  margin-top: 5rem;
  display: flex;
  align-items: flex-start;
  gap: 0.6rem;
  min-height: 20rem;

  @media only screen and (max-width: 52em) {
    min-height: unset;
  }
}
.settle-balance-pos-wrap .yellow-info-box .img-box {
  width: 2.2rem;
  height: 2.2rem;
}
.settle-balance-pos-wrap .yellow-info-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.settle-balance-pos-wrap .yellow-info-box .text {
  font-size: 1.3rem;
  color: #ea872d;
  text-align: center;
} /*# sourceMappingURL=RequestPos.css.map */

.bankbox-withraw-index-wrapper-box {
  width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: scroll;
  padding-bottom: 5rem;

  &::-webkit-scrollbar {
    display: none;
  }

  .title-text-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.5rem;

    .title {
      font-weight: 600;
      font-size: 2.2rem;
      text-align: center;
    }

    .text {
      text-align: center;
      font-size: 1.3rem;
      margin-top: -0.1rem;
    }
  }

  .verify-name-box {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: -1rem;
    max-height: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    width: 100%;

    & > *:first-child .label-check {
      border-radius: 50%;
      transform: scale(0.8);
    }
    .text {
      // color: var(--primary-black);
      font-weight: 600;
      font-size: 1.3rem;
      margin-left: 0.5rem;
    }
  }

  .verify-name-box-show {
    max-height: 5rem;
    transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  }

  .select-wrap-box {
    margin-bottom: 0rem;
  }

  .account-drop-option-box {
    max-height: calc(100vh - 60rem);
    // background-color: red;
    display: flex;
    flex-direction: column;
    border-width: 0.15rem;
    border-radius: 1.2rem;

    & > *:last-child {
      border: unset;
    }

    .account-item {
      border-width: 0.15rem;
      padding: 2rem;
      display: flex;
      align-items: center;

      .check-wrap {
        margin-left: auto;
        border: 0.2rem solid rgba(238, 238, 238, 1);
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 40%;
          height: 40%;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          background-color: rgba(255, 255, 255, 1);
        }
      }

      .check-wrap-active {
        background-color: rgba(117, 90, 226, 1);
      }

      .name-acc-num-box {
        display: flex;
        flex-direction: column;

        .name {
          font-size: 1.5rem;
          font-weight: 600;
        }

        .account-num {
          font-size: 1.3rem;
        }
      }
    }
  }

  .amount-option-list-box {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
    // justify-content: space-between;

    .amount-box {
      padding: 1rem 1.5rem;
      border-radius: 0.8rem;
      border-width: 0.15rem;
      cursor: pointer;
    }
  }
}
