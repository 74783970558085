.side-menu-wrap {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  color: #ffffff;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 5rem;
}
.side-menu-wrap::-webkit-scrollbar {
  display: none;
}
.side-menu-wrap .logo-box-ipad {
  margin: 0rem auto 2rem auto;
}
@media only screen and (min-width: 65em) {
  .side-menu-wrap .logo-box-ipad {
    display: none;
  }
}
@media only screen and (max-width: 56em) {
  .side-menu-wrap .logo-box-ipad {
    display: none;
  }
}
.side-menu-wrap .logo-box-ipad .img-box {
  width: 5.5rem;
  height: 5.5rem;
}
.side-menu-wrap .logo-box-ipad .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.side-menu-wrap .logo-box {
  display: flex;
  align-items: center;
  padding: 0rem 2rem;
  cursor: pointer;
  margin-bottom: 2rem;
  margin-top: -0.5rem;
}
@media only screen and (max-width: 65em) {
  .side-menu-wrap .logo-box {
    display: none;
  }
}
@media only screen and (max-width: 56em) {
  .side-menu-wrap .logo-box {
    display: flex;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }
}
.side-menu-wrap .logo-box .img-box {
  width: 8rem;
  height: 3rem;
  display: inline-block;
}
.side-menu-wrap .logo-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.side-menu-wrap .logo-box .text {
  color: #ffffff;
  font-weight: 700;
  font-size: 1.2rem;
  margin-left: 1rem;
}
.side-menu-wrap .account-detail-box {
  width: 90%;
  padding: 1rem 0.8rem 1rem 1.4rem;
  background-color: #333333;
  border-radius: 0.8rem;
  margin: 0rem auto;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.side-menu-wrap .account-detail-box .copied-box {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  position: relative;
  background-color: #ffffff;
}
.side-menu-wrap .account-detail-box .copied-box .icon {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #020202;
}
.side-menu-wrap .account-detail-box .img-box {
  cursor: pointer;
  display: grid;
  place-items: center;
  transition: all 0.3s;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.side-menu-wrap .account-detail-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.side-menu-wrap .account-detail-box .img-box:hover {
  transform: scale(1.05);
}
.side-menu-wrap .account-detail-box .img-box-drop {
  background-color: rgb(103, 103, 103);
}
.side-menu-wrap .account-detail-box .img-box-drop .img {
  width: 60%;
  height: 60%;
  -o-object-fit: contain;
     object-fit: contain;
}
.side-menu-wrap .account-detail-box .text-box {
  margin-right: auto;
}
@media only screen and (max-width: 65em) {
  .side-menu-wrap .account-detail-box .text-box {
    display: none;
  }
}
@media only screen and (max-width: 56em) {
  .side-menu-wrap .account-detail-box .text-box {
    display: unset;
  }
}
.side-menu-wrap .account-detail-box .text-box .num {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.3rem;
}
.side-menu-wrap .account-detail-box .text-box .text {
  font-size: 1.1rem;
  color: #cccccc;
}
.side-menu-wrap .list-box {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  gap: 0.5rem;
}
.side-menu-wrap .list-box .nav-link {
  display: flex;
  align-items: center;
  padding: 0.9rem 2rem;
  padding-left: 2rem;
  gap: 1.2rem;
  position: relative;
  cursor: pointer;
}
.side-menu-wrap .list-box .nav-link::after, .side-menu-wrap .list-box .nav-link::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 0.5rem;
  height: 100%;
  transform: translateY(-50%) scale(0);
  background-color: #ffffff;
}
.side-menu-wrap .list-box .nav-link::before {
  width: 100%;
  background-color: #333333;
}
.side-menu-wrap .list-box .nav-link:hover .name {
  color: #ffffff;
  font-weight: 500;
}
.side-menu-wrap .list-box .nav-link:hover .icon {
  fill: #ffffff;
}
.side-menu-wrap .list-box .nav-link > * {
  position: relative;
  z-index: 3;
}
.side-menu-wrap .list-box .nav-link .img-box {
  display: grid;
  place-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #333333;
}
.side-menu-wrap .list-box .nav-link .img-box .img {
  width: 75%;
  height: 75%;
  -o-object-fit: contain;
     object-fit: contain;
}
.side-menu-wrap .list-box .nav-link .name {
  font-size: 1.3rem;
  color: rgba(204, 204, 204, 0.7);
  transition: all 0.3s;
  margin-right: auto;
}
@media only screen and (max-width: 65em) {
  .side-menu-wrap .list-box .nav-link .name {
    display: none;
  }
}
@media only screen and (max-width: 56em) {
  .side-menu-wrap .list-box .nav-link .name {
    display: unset;
  }
}
.side-menu-wrap .list-box .nav-link .icon {
  fill: rgba(204, 204, 204, 0.7);
  transition: all 0.3s;
}
@media only screen and (max-width: 65em) {
  .side-menu-wrap .list-box .nav-link .icon {
    display: none;
  }
}
@media only screen and (max-width: 56em) {
  .side-menu-wrap .list-box .nav-link .icon {
    display: unset;
  }
}
.side-menu-wrap .list-box .children-box {
  max-height: 0;
  margin-left: 4.2rem;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}
.side-menu-wrap .list-box .children-box .nav-link::after, .side-menu-wrap .list-box .children-box .nav-link::before {
  display: none;
}
.side-menu-wrap .list-box .children-box-show {
  max-height: 30rem;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}
.side-menu-wrap .list-box .nav-link-active:hover .name {
  font-weight: 700;
  color: #ffffff !important;
}
.side-menu-wrap .list-box .nav-link-active .img-box {
  background-color: #020202;
}
.side-menu-wrap .list-box .nav-link-active .name {
  font-weight: 700;
  color: #ffffff !important;
}
.side-menu-wrap .list-box .nav-link-active .icon {
  fill: #ffffff;
}
.side-menu-wrap .list-box .nav-link-active::after, .side-menu-wrap .list-box .nav-link-active::before {
  transform: translateY(-50%) scale(1);
}
.side-menu-wrap .list-box .wrap-title {
  padding-left: 2rem;
  font-weight: 800;
  color: #676767;
  font-size: 1rem;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 65em) {
  .side-menu-wrap .list-box .wrap-title {
    display: none;
  }
}
@media only screen and (max-width: 56em) {
  .side-menu-wrap .list-box .wrap-title {
    display: unset;
  }
}
.side-menu-wrap .list-box .underline {
  width: 100%;
  height: 0.04rem;
  background-color: #333333;
  margin: 1rem 0rem;
}
.side-menu-wrap .list-box .wrap {
  display: flex;
  flex-direction: column;
}
.side-menu-wrap .list-box .wrap .nav-link {
  display: flex;
  align-items: center;
  padding: 0.9rem 2rem;
  padding-left: 2rem;
  gap: 1rem;
  position: relative;
  cursor: pointer;
}
.side-menu-wrap .list-box .wrap .nav-link::after, .side-menu-wrap .list-box .wrap .nav-link::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 0.5rem;
  height: 90%;
  transform: translateY(-50%) scale(0);
  background-color: #ffffff;
}
.side-menu-wrap .list-box .wrap .nav-link::before {
  width: 100%;
  background-color: #333333;
}
.side-menu-wrap .list-box .wrap .nav-link:hover .name {
  color: #ffffff;
  font-weight: 500;
}
.side-menu-wrap .list-box .wrap .nav-link:hover .icon {
  fill: #ffffff;
}
.side-menu-wrap .list-box .wrap .nav-link > * {
  position: relative;
  z-index: 3;
}
.side-menu-wrap .list-box .wrap .nav-link .img-box {
  display: grid;
  place-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.side-menu-wrap .list-box .wrap .nav-link .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.side-menu-wrap .list-box .wrap .nav-link .name {
  font-size: 1.3rem;
  color: rgba(204, 204, 204, 0.7);
  transition: all 0.3s;
  margin-right: auto;
}
@media only screen and (max-width: 65em) {
  .side-menu-wrap .list-box .wrap .nav-link .name {
    display: none;
  }
}
@media only screen and (max-width: 56em) {
  .side-menu-wrap .list-box .wrap .nav-link .name {
    display: unset;
  }
}
.side-menu-wrap .list-box .wrap .nav-link .icon {
  fill: rgba(204, 204, 204, 0.7);
  transition: all 0.3s;
}
@media only screen and (max-width: 65em) {
  .side-menu-wrap .list-box .wrap .nav-link .icon {
    display: none;
  }
}
@media only screen and (max-width: 56em) {
  .side-menu-wrap .list-box .wrap .nav-link .icon {
    display: unset;
  }
}
.side-menu-wrap .list-box .wrap .children-box {
  max-height: 0;
  margin-left: 3rem;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  width: 100%;
}
.side-menu-wrap .list-box .wrap .children-box .nav-link::after, .side-menu-wrap .list-box .wrap .children-box .nav-link::before {
  display: none;
}
.side-menu-wrap .list-box .wrap .children-box-show {
  max-height: 30rem;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}
.side-menu-wrap .list-box .wrap .nav-link-active:hover .name {
  font-weight: 700;
  color: #ffffff;
}
.side-menu-wrap .list-box .wrap .nav-link-active .name {
  font-weight: 700;
  color: #ffffff;
}
.side-menu-wrap .list-box .wrap .nav-link-active .icon {
  fill: #ffffff;
}
.side-menu-wrap .list-box .wrap .nav-link-active::after, .side-menu-wrap .list-box .wrap .nav-link-active::before {
  transform: translateY(-50%) scale(1);
}

.new-side-menu-wrap-box {
  overflow-x: hidden;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.new-side-menu-wrap-box::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 42em) {
  .new-side-menu-wrap-box {
    overflow-y: scroll;
  }
}
.new-side-menu-wrap-box > * {
  padding: 2rem;
}
.new-side-menu-wrap-box .top-info-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1.5rem;
}
.new-side-menu-wrap-box .top-info-box .logo-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}
@media only screen and (max-width: 65em) {
  .new-side-menu-wrap-box .top-info-box .logo-box {
    display: none;
  }
}
@media only screen and (max-width: 56em) {
  .new-side-menu-wrap-box .top-info-box .logo-box {
    display: flex;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }
}
.new-side-menu-wrap-box .top-info-box .logo-box .img-box {
  width: 10.5rem;
  height: 3rem;
  display: inline-block;
}
.new-side-menu-wrap-box .top-info-box .logo-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.new-side-menu-wrap-box .top-info-box .logo-box .text {
  color: #ffffff;
  font-weight: 700;
  font-size: 1.2rem;
  transform: translateX(-1rem);
}
.new-side-menu-wrap-box .top-info-box .account-detail-box {
  width: 100%;
  padding: 1.3rem 0.8rem 1.3rem 1.4rem;
  box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04), 0px 0px 2px rgba(51, 51, 51, 0.02);
  border-radius: 0.8rem;
  margin: 0rem auto;
  display: flex;
  align-items: center;
}
.new-side-menu-wrap-box .top-info-box .account-detail-box .copied-box {
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 50%;
  position: relative;
  background-color: #ffffff;
}
.new-side-menu-wrap-box .top-info-box .account-detail-box .copied-box .icon {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #020202;
}
.new-side-menu-wrap-box .top-info-box .account-detail-box .img-box {
  cursor: pointer;
  display: grid;
  place-items: center;
  transition: all 0.3s;
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 50%;
}
.new-side-menu-wrap-box .top-info-box .account-detail-box .img-box .img {
  width: 45%;
  height: 45%;
  -o-object-fit: contain;
     object-fit: contain;
}
.new-side-menu-wrap-box .top-info-box .account-detail-box .img-box:hover {
  transform: scale(1.05);
}
.new-side-menu-wrap-box .top-info-box .account-detail-box .text-box {
  margin-right: auto;
}
@media only screen and (max-width: 65em) {
  .new-side-menu-wrap-box .top-info-box .account-detail-box .text-box {
    display: none;
  }
}
@media only screen and (max-width: 56em) {
  .new-side-menu-wrap-box .top-info-box .account-detail-box .text-box {
    display: unset;
  }
}
.new-side-menu-wrap-box .top-info-box .account-detail-box .text-box .num {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.3rem;
  color: #ffffff;
}
.new-side-menu-wrap-box .top-info-box .account-detail-box .text-box .text {
  font-size: 1.1rem;
  color: #cccccc;
}
.new-side-menu-wrap-box .bottom-info-box {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-right: unset;
}
@media only screen and (max-width: 56em) {
  .new-side-menu-wrap-box .bottom-info-box {
    padding-right: 1rem;
    overflow-y: scroll;
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
  .new-side-menu-wrap-box .bottom-info-box::-webkit-scrollbar:horizontal {
    display: none;
  }
  .new-side-menu-wrap-box .bottom-info-box::-webkit-scrollbar {
    display: none;
  }
  .new-side-menu-wrap-box .bottom-info-box::-webkit-scrollbar {
    width: 0.5rem;
  }
  .new-side-menu-wrap-box .bottom-info-box::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }
  .new-side-menu-wrap-box .bottom-info-box::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 30rem;
  }
  .new-side-menu-wrap-box .bottom-info-box::-webkit-scrollbar-thumb:hover {
    background: #cccccc;
  }
}
.new-side-menu-wrap-box .bottom-info-box .nav-link {
  display: flex;
  align-items: center;
  padding: 0.9rem 0rem;
  padding-left: 1.5rem;
  gap: 1.2rem;
  padding-right: 2rem;
  position: relative;
  cursor: pointer;
}
.new-side-menu-wrap-box .bottom-info-box .nav-link::after, .new-side-menu-wrap-box .bottom-info-box .nav-link::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 0.5rem;
  height: 90%;
  transform: translateY(-50%) scale(0);
}
.new-side-menu-wrap-box .bottom-info-box .nav-link::after {
  background-color: #020202;
}
.new-side-menu-wrap-box .bottom-info-box .nav-link::before {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1019607843);
}
.new-side-menu-wrap-box .bottom-info-box .nav-link:hover .name {
  font-weight: 500;
}
.new-side-menu-wrap-box .bottom-info-box .nav-link:hover .img-box .img {
  filter: saturate(100%);
}
.new-side-menu-wrap-box .bottom-info-box .nav-link > * {
  position: relative;
  z-index: 3;
}
.new-side-menu-wrap-box .bottom-info-box .nav-link .img-box {
  margin-right: 0.7rem;
  display: grid;
  place-items: center;
  width: 3.1rem;
  height: 3.1rem;
  border-radius: 50%;
  border-radius: 50%;
}
.new-side-menu-wrap-box .bottom-info-box .nav-link .img-box .img {
  width: 80%;
  height: 80%;
  -o-object-fit: contain;
     object-fit: contain;
  filter: saturate(0%);
}
.new-side-menu-wrap-box .bottom-info-box .nav-link .name {
  font-size: 1.3rem;
  transform: translateY(0.1rem);
  transition: all 0.3s;
  margin-right: auto;
  font-weight: 500;
}
@media only screen and (max-width: 65em) {
  .new-side-menu-wrap-box .bottom-info-box .nav-link .name {
    display: none;
  }
}
@media only screen and (max-width: 56em) {
  .new-side-menu-wrap-box .bottom-info-box .nav-link .name {
    display: unset;
  }
}
.new-side-menu-wrap-box .bottom-info-box .nav-link .icon {
  transition: all 0.3s;
}
@media only screen and (max-width: 65em) {
  .new-side-menu-wrap-box .bottom-info-box .nav-link .icon {
    display: none;
  }
}
@media only screen and (max-width: 56em) {
  .new-side-menu-wrap-box .bottom-info-box .nav-link .icon {
    display: unset;
  }
}
.new-side-menu-wrap-box .bottom-info-box .children-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.8s;
  -moz-transition: max-height 0.8s;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}
.new-side-menu-wrap-box .bottom-info-box .children-box .nav-link {
  position: relative;
}
.new-side-menu-wrap-box .bottom-info-box .children-box .nav-link > * {
  margin-left: 4rem;
}
.new-side-menu-wrap-box .bottom-info-box .children-box .nav-link::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 0.5rem;
  height: 90%;
  transform: translateY(-50%) scale(0);
}
.new-side-menu-wrap-box .bottom-info-box .children-box .nav-link-active::before {
  transform: translateY(-50%) scale(1);
  width: 0.5rem;
  height: 50%;
  right: unset;
  left: 3rem;
}
.new-side-menu-wrap-box .bottom-info-box .children-box .nav-link-active .name {
  color: #020202;
}
.new-side-menu-wrap-box .bottom-info-box .children-box-show {
  max-height: 30rem;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
}
.new-side-menu-wrap-box .bottom-info-box .nav-link-active:hover .name {
  font-weight: 700;
}
.new-side-menu-wrap-box .bottom-info-box .nav-link-active .name {
  font-weight: 700;
}
.new-side-menu-wrap-box .bottom-info-box .nav-link-active .img-box .img {
  filter: saturate(100%);
}
.new-side-menu-wrap-box .bottom-info-box .nav-link-active .icon {
  color: #ffffff;
}
.new-side-menu-wrap-box .bottom-info-box .nav-link-active::after, .new-side-menu-wrap-box .bottom-info-box .nav-link-active::before {
  transform: translateY(-50%) scale(1);
}

.new-side-menu-wrap-box-dark::-webkit-scrollbar {
  display: none;
}
.new-side-menu-wrap-box-dark .top-info-box .account-detail-box {
  background-color: #333333 !important;
  border: none;
}
.new-side-menu-wrap-box-dark .top-info-box .account-detail-box .img-box {
  background-color: #ffffff !important;
}
.new-side-menu-wrap-box-dark .bottom-info-box .nav-link .name {
  color: #676767;
}
.new-side-menu-wrap-box-dark .bottom-info-box .nav-link .img-box {
  width: 4.1rem;
  height: 4.1rem;
  display: grid;
  place-items: center;
}
.new-side-menu-wrap-box-dark .bottom-info-box .nav-link .img-box .img {
  width: 60%;
  height: 60%;
  -o-object-fit: contain;
     object-fit: contain;
}
.new-side-menu-wrap-box-dark .bottom-info-box .nav-link .icon {
  fill: #676767;
}
.new-side-menu-wrap-box-dark .bottom-info-box .nav-link-active .name {
  color: #ffffff;
}
.new-side-menu-wrap-box-dark .bottom-info-box .nav-link-active .icon {
  fill: #ffffff;
}
.new-side-menu-wrap-box-dark .bottom-info-box .children-box .nav-link-active {
  color: #ffffff;
}

.side-menu-wrap-round-icon .top-info-box .account-detail-box {
  background-color: #333333 !important;
  border: none;
}
.side-menu-wrap-round-icon .top-info-box .account-detail-box .img-box {
  background-color: #ffffff !important;
}
.side-menu-wrap-round-icon .bottom-info-box .nav-link .name {
  color: #676767;
}
.side-menu-wrap-round-icon .bottom-info-box .nav-link .img-box {
  width: 4.1rem;
  height: 4.1rem;
  display: grid;
  place-items: center;
}
.side-menu-wrap-round-icon .bottom-info-box .nav-link .img-box .img {
  width: 60%;
  height: 60%;
  -o-object-fit: contain;
     object-fit: contain;
}
.side-menu-wrap-round-icon .bottom-info-box .nav-link .icon {
  fill: #676767;
}
.side-menu-wrap-round-icon .bottom-info-box .nav-link-active .name {
  color: #ffffff;
}
.side-menu-wrap-round-icon .bottom-info-box .nav-link-active .icon {
  fill: #ffffff;
}
.side-menu-wrap-round-icon .bottom-info-box .children-box .nav-link-active {
  color: #ffffff;
}/*# sourceMappingURL=SideMenu.css.map */