//  @import "../../breakpoints";

.bill-payment-airtime-wrap {
    margin-top: 3rem;
    // padding-bottom: 10rem;
    width: 40rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    // margin-bottom: 10rem;
    // background-color: red;

    @media only screen and (max-width: 42em) {
      width: 90%;
      margin: 2rem auto;
      // background-color: red;
    }

    .verify-name-box {
      display: flex;
      align-items: center;
      align-self: flex-start;
      margin-top: -1rem;
      max-height: 0;
      overflow-x: hidden;
      overflow-y: hidden;
      transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
      -webkit-transition: max-height 0.3s;
      -moz-transition: max-height 0.3s;
      width: 100%;
  
      & > *:first-child .label-check {
        border-radius: 50%;
        transform: scale(0.8);
      }
      .text {
        // color: var(--primary-black);
        font-weight: 600;
        font-size: 1.3rem;
        margin-left: 0.5rem;
      }
    }
  
    .verify-name-box-show {
      max-height: 5rem;
      transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
    }
  }

  .bill-payment-airtime-wrap .select-data-plan {
    position: relative;
    z-index: 5;
  }
  .bill-payment-airtime-wrap .select-type-box {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .bill-payment-airtime-wrap .label-span-class{
    font-weight: 600;
  }
  .bill-payment-airtime-wrap .select-type-box .title {
    // color: #011f3d;
    font-size: 1.45rem;
  }
  .bill-payment-airtime-wrap .select-type-box .select-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
  }
  .bill-payment-airtime-wrap .select-type-box .select-wrap .child-wrap {
    position: relative;
  }
  .bill-payment-airtime-wrap .select-type-box .select-wrap .child-wrap > * {
    position: relative;
    z-index: 3;
  }
  .bill-payment-airtime-wrap .select-type-box .select-wrap .child-wrap .img-box {
    width: 8rem;
    height: 8rem;
    cursor: pointer;
    border-radius: 0.8rem;
    overflow: hidden;
  }
  .bill-payment-airtime-wrap .select-type-box .select-wrap .child-wrap .img-box .img {
    -o-object-fit: contain;
       object-fit: contain;
    width: 100%;
    height: 100%;
  }
  .bill-payment-airtime-wrap .add-another-box {
    display: flex;
    align-items: center;
    margin-top: -1rem;
    align-self: flex-start;
    cursor: default;
    opacity: 0.5;
  }
  .bill-payment-airtime-wrap .add-another-box:hover .img-box {
    transform: scale(1.2);
  }
  .bill-payment-airtime-wrap .add-another-box .text {
    color: var(--primary-purple);
    font-weight: 600;
    font-size: 1.3rem;
  }
  .bill-payment-airtime-wrap .add-another-box .img-box {
    transition: all 0.3s ease-in-out;
    width: 2rem;
    height: 2rem;
    display: grid;
    place-items: center;
    margin-right: 0.3rem;
  }
  .bill-payment-airtime-wrap .add-another-box .img-box .img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .bill-payment-airtime-wrap .add-another-box-active {
    opacity: 1;
    cursor: pointer;
  }
  .bill-payment-airtime-wrap .recepient-box {
    max-height: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    width: 100%;
  }
  .bill-payment-airtime-wrap .recepient-box .text-line {
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    align-items: center;
    width: 100%;
  }
  .bill-payment-airtime-wrap .recepient-box .text-line .text {
    color: #676767;
    background-color: #f7f8f7;
    font-size: 1.1rem;
    border: 0.1rem solid #cccccc;
    padding: 0.5rem 1.1rem;
    border-radius: 10rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .bill-payment-airtime-wrap .recepient-box .text-line span {
    height: 0.1rem;
    background-color: rgba(204, 204, 204, 0.5);
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap {
    margin-left: auto;
    position: relative;
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .text {
    font-size: 1.3rem;
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .cancel-box {
    margin-left: auto;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    background-color: #ffdfdd;
    margin-left: auto;
    display: grid;
    place-items: center;
    cursor: pointer;
    transition: all 0.3s;
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .cancel-box:hover {
    transform: scale(1.1);
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .cancel-box .icon {
    fill: #ff0f00;
    width: 1.1rem;
    height: 1.1rem;
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .view-all-box {
    position: absolute;
    bottom: 105%;
    right: 0;
    width: 35rem;
    height: 30rem;
    padding: 2rem;
    z-index: 4;
    overflow-y: scroll;
    box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
    border-radius: 2rem;
    transform: scale(0);
    transition: all 0.3s ease-in-out;
    transform-origin: bottom right;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .view-all-box .wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap {
    display: flex;
    align-items: center;
    padding: 1rem 1.3rem;
    border: 0.1rem solid #f7f8f7;
    border-radius: 0.8rem;
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .text-box .name {
    font-weight: 700;
    font-size: 1.3rem;
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .text-box .text {
    font-size: 1.1rem;
    font-weight: 400;
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .img-box {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .img-box .img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .cancel-box {
    margin-left: auto;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    background-color: #ffdfdd;
    margin-left: auto;
    display: grid;
    place-items: center;
    cursor: pointer;
    transition: all 0.3s;
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .cancel-box:hover {
    transform: scale(1.1);
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .cancel-box .icon {
    fill: #ff0f00;
    width: 1.1rem;
    height: 1.1rem;
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar:horizontal {
    display: none;
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar {
    width: 0.3rem;
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar-thumb {
    background: #020202;
    border-radius: 30rem;
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar-thumb:hover {
    background: #020202;
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .view-all-box-show {
    transform: scale(1);
  }
  .bill-payment-airtime-wrap .recepient-box .view-total-box .view-wrap .text {
    cursor: pointer;
    font-weight: 600;
    color: var(--primary-purple);
  }
  .bill-payment-airtime-wrap .recepient-box .preview-box {
    margin-top: 1rem;
    max-height: 15rem;
    padding: 1rem 0rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1.5rem;
  }
  .bill-payment-airtime-wrap .recepient-box .preview-box .box {
    background-color: #f7f8f7;
    padding: 1rem;
    cursor: pointer;
    position: relative;
  }
  .bill-payment-airtime-wrap .recepient-box .preview-box .box .cancel-box {
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    background-color: #ffdfdd;
    margin-left: auto;
    display: grid;
    place-items: center;
    cursor: pointer;
    transition: all 0.3s;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .bill-payment-airtime-wrap .recepient-box .preview-box .box .cancel-box:hover {
    transform: scale(1.1);
  }
  .bill-payment-airtime-wrap .recepient-box .preview-box .box .cancel-box .icon {
    fill: #ff0f00;
    width: 1.1rem;
    height: 1.1rem;
  }
  .bill-payment-airtime-wrap .recepient-box .preview-box .box .img-box {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
  }
  .bill-payment-airtime-wrap .recepient-box .preview-box .box .img-box .img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
    border-radius: 50%;
  }
  .bill-payment-airtime-wrap .recepient-box .preview-box .box .name {
    margin-top: 2rem;
    font-size: 1.45rem;
  }
  .bill-payment-airtime-wrap .recepient-box-show {
    overflow-x: unset;
    overflow-y: unset;
    max-height: 30rem;
    transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
  }

  .bill-payment-electricity-wrap{
    min-height: 65rem;
    margin-bottom: 10rem;
    padding-bottom: 70rem;
  }
  
  .bill-payment-electricity-wrap .select-electricy {
    position: relative;
    z-index: 10;
  }
  .bill-payment-electricity-wrap .select-type-box .select-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }
  .bill-payment-electricity-wrap .select-type-box .select-wrap .child-wrap-row {
    background-color: #f7f8f7;
    border-radius: 0.8rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
  }
  .bill-payment-electricity-wrap .select-type-box .select-wrap .child-wrap-row .name {
    font-weight: 600;
  }
  .bill-payment-electricity-wrap .select-type-box .select-wrap .child-wrap-row .img-box {
    margin-bottom: 2rem;
    width: 3rem;
    height: 3rem;
  }
  .bill-payment-electricity-wrap .select-type-box .select-wrap .child-wrap-row .img-box .img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }


   //responsive styles
  //  @include xs {
  //   .bill-payment-airtime-wrap {
  //       width: 100% !important;
  //       padding: 2rem;
  //   }
  // }

// .bill-payment-betting-wrap{
//   @media only screen and (max-width: 48em){
//     // width: 100%;
//   }
// }