.side-menu-wrap {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  color: #ffffff;
  // background-color: #020202 !important;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 5rem;

  &::-webkit-scrollbar {
    display: none;
  }

  .logo-box-ipad {
    @media only screen and (min-width: 65em) {
      display: none;
    }

    @media only screen and (max-width: 56em) {
      display: none;
    }

    margin: 0rem auto 2rem auto;

    .img-box {
      width: 5.5rem;
      height: 5.5rem;

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .logo-box {
    display: flex;
    align-items: center;
    padding: 0rem 2rem;
    cursor: pointer;
    margin-bottom: 2rem;
    margin-top: -.5rem;

    @media only screen and (max-width: 65em) {
      display: none;
    }

    @media only screen and (max-width: 56em) {
      display: flex;
      margin-top: 1.5rem;
      margin-bottom: 2rem;
    }

    .img-box {
      width: 8rem;
      height: 3rem;
      display: inline-block;

      .img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .text {
      color: #ffffff;
      font-weight: 700;
      font-size: 1.2rem;
      margin-left: 1rem;
      // transform: translateX(-1rem);
    }
  }

  .account-detail-box {
    width: 90%;
    padding: 1rem 0.8rem 1rem 1.4rem;
    background-color: #333333;
    border-radius: 0.8rem;
    margin: 0rem auto;
    display: flex;
    align-items: center;
    cursor: pointer;

    .copied-box {
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      position: relative;
      background-color: #ffffff;
      // display: none;

      .icon {
        width: 1rem;
        height: 1rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: #020202;
      }
    }

    .img-box {
      cursor: pointer;
      display: grid;
      place-items: center;
      transition: all 0.3s;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      // display: none;

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &:hover {
        transform: scale(1.05);
      }
    }

    .img-box-drop {
      background-color: rgba(103, 103, 103, 1);

      .img {
        width: 60%;
        height: 60%;
        object-fit: contain;
      }
    }

    .text-box {
      margin-right: auto;

      @media only screen and (max-width: 65em) {
        display: none;
      }

      @media only screen and (max-width: 56em) {
        display: unset;
      }

      .num {
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 0.3rem;
      }

      .text {
        font-size: 1.1rem;
        color: #cccccc;
      }
    }
  }

  .list-box {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    gap: 0.5rem;

    .nav-link {
      display: flex;
      align-items: center;
      padding: 0.9rem 2rem;
      padding-left: 2rem;
      gap: 1.2rem;
      position: relative;
      cursor: pointer;
      // background-color: red;

      &::after,
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 0.5rem;
        height: 100%;
        transform: translateY(-50%) scale(0);
        background-color: #ffffff;
      }

      &::before {
        width: 100%;
        background-color: #333333;
      }

      &:hover {
        .name {
          color: #ffffff;
          font-weight: 500;
        }

        .icon {
          fill: #ffffff;
        }
      }

      & > * {
        position: relative;
        z-index: 3;
      }

      .img-box {
        display: grid;
        place-items: center;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background-color: #333333;

        .img {
          width: 75%;
          height: 75%;
          object-fit: contain;
        }
      }

      .name {
        font-size: 1.3rem;
        color: rgba(204, 204, 204, 0.7);
        transition: all 0.3s;
        margin-right: auto;

        @media only screen and (max-width: 65em) {
          display: none;
        }

        @media only screen and (max-width: 56em) {
          display: unset;
        }
      }

      .icon {
        fill: rgba(204, 204, 204, 0.7);
        transition: all 0.3s;

        @media only screen and (max-width: 65em) {
          display: none;
        }

        @media only screen and (max-width: 56em) {
          display: unset;
        }
      }
    }

    .children-box {
      // margin-left: 4.5rem;
      max-height: 0;
      margin-left: 4.2rem;
      overflow-x: hidden;
      overflow-y: hidden;
      transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
      -webkit-transition: max-height 0.3s;
      -moz-transition: max-height 0.3s;
      width: 100%;
      display: flex;
      flex-direction: column;
      // margin-top: .7rem;
      gap: 0.7rem;

      .nav-link {
        &::after,
        &::before {
          display: none;
        }
      }
    }

    .children-box-show {
      // margin-top: -0.6rem;
      max-height: 30rem;
      transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
    }

    .nav-link-active {
      &:hover .name {
        font-weight: 700;
        color: #ffffff !important;
      }

      .img-box {
        background-color: #020202;
      }

      .name {
        font-weight: 700;
        color: #ffffff !important;
      }

      .icon {
        fill: #ffffff;
      }

      &::after,
      &::before {
        transform: translateY(-50%) scale(1);
      }
    }

    .wrap-title {
      padding-left: 2rem;
      font-weight: 800;
      color: #676767;
      font-size: 1rem;
      margin-bottom: 1rem;

      @media only screen and (max-width: 65em) {
        display: none;
      }

      @media only screen and (max-width: 56em) {
        display: unset;
      }
    }

    .underline {
      width: 100%;
      height: 0.04rem;
      background-color: #333333;
      margin: 1rem 0rem;
    }

    .wrap {
      display: flex;
      flex-direction: column;
      //   gap: 0.6rem;

      .nav-link {
        // display: none;
        display: flex;
        align-items: center;
        padding: 0.9rem 2rem;
        padding-left: 2rem;
        gap: 1rem;
        position: relative;
        cursor: pointer;
        // background-color: red;

        &::after,
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          width: 0.5rem;
          height: 90%;
          transform: translateY(-50%) scale(0);
          background-color: #ffffff;
        }

        &::before {
          width: 100%;
          background-color: #333333;
        }

        &:hover {
          .name {
            color: #ffffff;
            font-weight: 500;
          }

          .icon {
            fill: #ffffff;
          }
        }

        & > * {
          position: relative;
          z-index: 3;
        }

        .img-box {
          display: grid;
          place-items: center;
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .name {
          font-size: 1.3rem;
          color: rgba(204, 204, 204, 0.7);
          transition: all 0.3s;
          margin-right: auto;

          @media only screen and (max-width: 65em) {
            display: none;
          }

          @media only screen and (max-width: 56em) {
            display: unset;
          }
        }

        .icon {
          fill: rgba(204, 204, 204, 0.7);
          transition: all 0.3s;

          @media only screen and (max-width: 65em) {
            display: none;
          }

          @media only screen and (max-width: 56em) {
            display: unset;
          }
        }
      }

      .children-box {
        // margin-left: 4.5rem;
        max-height: 0;
        margin-left: 3rem;
        overflow-x: hidden;
        overflow-y: hidden;
        transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
        -webkit-transition: max-height 0.3s;
        -moz-transition: max-height 0.3s;
        width: 100%;

        .nav-link {
          &::after,
          &::before {
            display: none;
          }
        }
      }

      .children-box-show {
        // margin-top: -0.6rem;
        max-height: 30rem;
        transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
      }

      .nav-link-active {
        &:hover .name {
          font-weight: 700;
          color: #ffffff;
        }

        .name {
          font-weight: 700;
          color: #ffffff;
        }

        .icon {
          fill: #ffffff;
        }

        &::after,
        &::before {
          transform: translateY(-50%) scale(1);
        }
      }
    }
  }
}

.new-side-menu-wrap-box {
  overflow-x: hidden;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  // display: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 42em) {
    overflow-y: scroll;
    // display: none;
  }

  & > * {
    padding: 2rem;
  }

  .top-info-box {
    // flex: 0 0 15%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1.5rem;
    // background-color: #ffffff !important;

    .logo-box {
      display: flex;
      align-items: center;
      // padding: 0rem 2rem;
      cursor: pointer;

      @media only screen and (max-width: 65em) {
        display: none;
      }

      @media only screen and (max-width: 56em) {
        display: flex;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
      }

      .img-box {
        width: 10.5rem;
        height: 3rem;
        display: inline-block;

        .img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      .text {
        color: #ffffff;
        font-weight: 700;
        font-size: 1.2rem;
        // margin-left: 1rem;
        transform: translateX(-1rem);
      }
    }

    .account-detail-box {
      width: 100%;
      padding: 1.3rem 0.8rem 1.3rem 1.4rem;
      box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
        0px 0px 2px rgba(51, 51, 51, 0.02);
      border-radius: 0.8rem;
      margin: 0rem auto;
      display: flex;
      align-items: center;

      .copied-box {
        width: 2.7rem;
        height: 2.7rem;
        border-radius: 50%;
        position: relative;
        background-color: #ffffff;
        // display: none;

        .icon {
          width: 1rem;
          height: 1rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          fill: #020202;
        }
      }

      .img-box {
        // display: none;
        cursor: pointer;
        display: grid;
        place-items: center;
        transition: all 0.3s;
        width: 2.7rem;
        height: 2.7rem;
        border-radius: 50%;
        // display: none;

        .img {
          width: 45%;
          height: 45%;
          object-fit: contain;
          // display: none;
        }

        &:hover {
          transform: scale(1.05);
        }
      }

      .text-box {
        margin-right: auto;

        @media only screen and (max-width: 65em) {
          display: none;
        }

        @media only screen and (max-width: 56em) {
          display: unset;
        }

        .num {
          font-size: 1.2rem;
          font-weight: 700;
          margin-bottom: 0.3rem;
          color: #ffffff;
        }

        .text {
          font-size: 1.1rem;
          color: #cccccc;
        }
      }
    }
  }

  .bottom-info-box {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-right: unset;
    // background-color: #1b1b1b !important;

    @media only screen and (max-width: 56em) {
      padding-right: 1rem;
      overflow-y: scroll;

      &::-webkit-scrollbar:horizontal {
        display: none;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      /* width */
      &::-webkit-scrollbar {
        width: 0.5rem;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: none !important;
        background-color: transparent !important;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #cccccc;
        border-radius: 30rem;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #cccccc;
      }
    }

    .nav-link {
      display: flex;
      align-items: center;
      padding: 0.9rem 0rem;
      padding-left: 1.5rem;
      // padding-bottom: 2rem;
      // margin-right: 2rem;
      gap: 1.2rem;
      padding-right: 2rem;
      position: relative;
      cursor: pointer;
      // display: none !important;

      &::after,
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 0.5rem;
        height: 90%;
        transform: translateY(-50%) scale(0);
        // background-color: #755ae2;
      }

      &::after {
        background-color: #020202;
      }

      &::before {
        width: 100%;
        background-color: #ffffff1a;
      }

      &:hover {
        .name {
          // color: #755ae2;

          font-weight: 500;
        }

        .img-box {
          .img {
            filter: saturate(100%);
          }
        }
      }

      & > * {
        position: relative;
        z-index: 3;
      }

      .img-box {
        margin-right: 0.7rem;
        // align-self: center;
        display: grid;
        place-items: center;
        width: 3.1rem;
        height: 3.1rem;
        border-radius: 50%;
        // display: none;
        // background-color: #cccccc;
        border-radius: 50%;

        .img {
          width: 80%;
          height: 80%;
          object-fit: contain;
          filter: saturate(0%);
        }
      }

      .name {
        font-size: 1.3rem;
        transform: translateY(0.1rem);
        transition: all 0.3s;
        margin-right: auto;
        font-weight: 500;

        @media only screen and (max-width: 65em) {
          display: none;
        }

        @media only screen and (max-width: 56em) {
          display: unset;
        }
      }

      .icon {
        transition: all 0.3s;
        // color: #ffffff !important;

        @media only screen and (max-width: 65em) {
          display: none;
        }

        @media only screen and (max-width: 56em) {
          display: unset;
        }
      }
    }

    // .nav-link-children {
    //   // &::after {
    //   //   // display: none;
    //   // }
    // }

    .children-box {
      max-height: 0;
      overflow-x: hidden;
      overflow-y: hidden;
      transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
      -webkit-transition: max-height 0.8s;
      -moz-transition: max-height 0.8s;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.7rem;

      .nav-link {
        position: relative;
        // display: none;

        & > * {
          margin-left: 4rem;
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          width: 0.5rem;
          height: 90%;
          transform: translateY(-50%) scale(0);
          // background-color: #755ae2;
        }
      }

      .nav-link-active {
        &::before {
          transform: translateY(-50%) scale(1);
          width: 0.5rem;
          height: 50%;
          right: unset;
          left: 3rem;
          // display: none;
        }

        .name {
          color: #020202;
        }
      }
    }

    .children-box-show {
      max-height: 30rem;
      transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
    }

    .nav-link-active {
      &:hover .name {
        font-weight: 700;
        // color: #755ae2;
      }

      .name {
        font-weight: 700;
        // color: #ffffff;
      }

      .img-box {
        .img {
          filter: saturate(100%);
        }
      }

      .icon {
        color: #ffffff;
      }

      &::after,
      &::before {
        transform: translateY(-50%) scale(1);
      }
    }
  }
}

// side-menu-wrap-round-icon

.new-side-menu-wrap-box-dark {
  &::-webkit-scrollbar {
    display: none;
  }
  .top-info-box {
    .account-detail-box {
      background-color: #333333 !important;
      border: none;

      .img-box {
        background-color: #ffffff !important;
      }
    }
  }

  .bottom-info-box {
    // display: none;
    .nav-link {
      // display: none !important;
      .name {
        color: #676767;
      }

      .img-box {
        // background-color: red !important;
        width: 4.1rem;
        height: 4.1rem;
        display: grid;
        place-items: center;

        .img {
          width: 60%;
          height: 60%;
          object-fit: contain;
        }
      }

      .icon {
        fill: #676767;
      }
    }

    .nav-link-active {
      .name {
        color: #ffffff;
      }

      .icon {
        fill: #ffffff;
      }
    }

    .children-box {
      .nav-link-active {
        color: #ffffff;
      }
    }
  }
}

.side-menu-wrap-round-icon {
  .top-info-box {
    .account-detail-box {
      background-color: #333333 !important;
      border: none;

      .img-box {
        background-color: #ffffff !important;
      }
    }
  }

  .bottom-info-box {
    .nav-link {
      // display: none;
      .name {
        color: #676767;
      }

      .img-box {
        // background-color: red !important;
        width: 4.1rem;
        height: 4.1rem;
        display: grid;
        place-items: center;

        .img {
          width: 60%;
          height: 60%;
          object-fit: contain;
        }
      }

      .icon {
        fill: #676767;
      }
    }

    .nav-link-active {
      .name {
        color: #ffffff;
      }

      .icon {
        fill: #ffffff;
      }
    }

    .children-box {
      .nav-link-active {
        color: #ffffff;
      }
    }
  }
}
