// @import "../../breakpoints";

.dashboard-overview-wrap {
  width: 100%;
  flex: 1;
  //   background-color: red;
  place-items: center;
  display: grid;

  @media only screen and (min-width: 110em) {
    align-items: flex-start;
    justify-items: flex-start;
  }

  @media only screen and (max-width: 61em) {
    align-items: flex-start;
  }

  .wrap {
    // height: 100%;
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 2.5rem;
    justify-content: center;
    // background-color: red;

    @media only screen and (max-width: 68em) {
      width: 80%;
    }

    @media only screen and (max-width: 61em) {
      width: 90%;
    }

    @media only screen and (max-width: 55em) {
      width: 100%;
    }

    @media only screen and (max-width: 42em) {
      margin-top: 2rem;
    }

    @media only screen and (max-width: 38em) {
      margin-top: 1.2rem;
    }

    .request-advert-box {
      // height: calc(100vh - 62rem);
      // height: calc(100vh - 62rem);
      // max-height: 30rem;
      border: none;
      // background-color: #ffffff;
      // box-shadow: 0px 4.41754px 17.6701px rgba(208, 213, 221, 0.25);
      border-radius: 1.2rem;
      display: flex;
      overflow: hidden;
      padding: 2.5rem 3rem;
      min-height: 20rem;
      background-image: url("../../../assets/overview-advert-img .png");
      background-size: 30%;
      background-position: bottom right;
      background-repeat: no-repeat;

      .content-box {
        flex: 0 0 55%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;

        .text {
          font-size: 2.1rem;
          letter-spacing: -0.02rem;
          font-weight: 600;
          opacity: 0.9;

          @media only screen and (max-width: 42em) {
            font-size: 1.55rem;
          }
        }

        .btn {
          background-color: #333333;
        }
      }

      .img-content-box {
        display: none;
        flex: 1;
        background-image: url("../../../assets/overview-advert-img .png");
        background-size: contain;
        background-position: bottom center;
        background-repeat: no-repeat;

        @media only screen and (max-width: 42em) {
          background-position: bottom right;
          background-size: contain;
        }
      }
    }

    .balance-top-box {
      width: 100%;
      background-color: #1b1b1b;
      background-image: url("../../../assets/overview-available-bg.png");
      background-size: cover;
      border-radius: 2.4rem;
      background-repeat: no-repeat;
      padding: 2.5rem 3rem;
      gap: 1rem;
      display: flex;
      flex-direction: column;
      position: relative;
      

      .role-auth-level-box {
        position: absolute;
        top: 0rem;
        right: 0rem;
        display: flex;
        align-items: center;
        padding: 0.7rem 1.5rem;
        // border-radius: 0.8rem;
        background-color: rgba(255, 246, 237, 0.2);
        border-top-right-radius: 3rem;
        border-bottom-left-radius: 1.2rem;

        .text {
          font-size: 1.25rem;
          text-transform: capitalize;
        }

        .text-two {
          font-weight: 500;
          color: #ffffff !important;
        }

        .icon-img {
          display: grid;
          place-items: center;
          transform: translateY(0.15rem);
          margin-left: 0.2rem;
          cursor: pointer;

          .img-box {
            width: 2rem;
            height: 2rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }

      @media only screen and (max-width: 42em) {
        border-radius: 1.2rem;
      }

      @media only screen and (max-width: 38em) {
        padding: 2rem;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-image: url("../../../assets/overview-available-bg.png");
        background-size: cover;
        border-radius: 2.4rem;
        background-repeat: no-repeat;

        @media only screen and (max-width: 42em) {
          border-radius: 1.2rem;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-image: url("../../../assets/overview-amount-bg.png");
        background-size: contain;
        background-position: top right;
        background-repeat: no-repeat;
        border-radius: 2.4rem;

        @media only screen and (max-width: 42em) {
          border-radius: 1.2rem;
        }
      }

      & > * {
        position: relative;
        z-index: 3;
      }

      .btn-wrap-box {
        display: flex;
        gap: 1.5rem;
        align-items: center;
        margin-top: 2.5rem;

        & > * {
          // min-width: 17rem;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
          padding: 1.4rem 3rem;
          border-radius: 14rem;

          &:hover {
            transform: unset;

            .img-box {
              transform: translate(0.2rem, -0.2rem);
            }
          }

          @media only screen and (max-width: 42em) {
            min-width: unset;
          }

          .img-box {
            width: 1rem;
            height: 1rem;
            transition: all 0.3s ease-in-out;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .btn-one {
          background-color: #ffffff;
          color: #020202;

          &:hover {
            .img-box {
              transform: translate(-0.1rem, 0.1rem);
            }
          }
        }

        // .btn-two{
        //   background-color: #020202 !important;
        //   color: #ffffff !important;
        // }
      }

      .toggle-account-select-box {
        position: relative;
        // margin-bottom: 1rem;
        align-self: flex-start;
        z-index: 10;

        .select-toggle-box {
          position: absolute;
          top: 120%;
          left: 0;
        }

        .value-box {
          display: flex;
          align-items: center;
          gap: 0.7rem;
          background-color: rgba(255, 255, 255, 0.1);
          padding: 0.5rem 0.8rem;
          border-radius: 0.6rem;
          cursor: pointer;

          // &:hover .img-box{
          //   transform: translateY(.25rem);
          // }

          // &:hover .img-box-up{
          //   transform: translateY(-.25rem);
          // }

          .value {
            color: #ffffff;
          }

          .img-box {
            display: grid;
            place-items: center;
            width: 2rem;
            height: 2rem;
            // transition: all .3s ease-in-out;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              transform: translateY(0.2rem);
            }
          }

          .img-box-up {
            transform: translateY(0.25rem) rotateX(-180deg);
          }
        }
      }

      .amount-value-box {
        display: flex;

        .toggle-text-box {
          display: flex;
          align-items: center;
          // align-self: center;
          margin-left: 1rem;

          .text {
            color: rgba(255, 255, 255, 0.4);
          }

          .img-box {
            display: grid;
            place-items: center;
            cursor: pointer;
            margin-left: 1rem;
            width: 2rem;
            height: 2rem;
            transition: all 0.3s ease-in-out;

            &:hover {
              transform: scale(1.1);
            }

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        sup {
          color: rgba(255, 255, 255, 0.4);
          font-size: 2rem;
        }

        .amount {
          font-weight: 900;
          color: #ffffff;
          font-size: 2vw;
          letter-spacing: 0.05rem;
          margin-left: 0.5rem;
          align-self: center;
          // display: inline-block;
          // background-color: red;
          min-width: 11.5rem;
          display: flex;
          align-items: center;

          @media only screen and (min-width: 110em) {
            font-size: 3rem;
          }

          @media only screen and (max-width: 42em) {
            font-size: 3rem;
          }

          .decimal {
            color: rgba(255, 255, 255, 0.4);
          }
        }
      }

      .toggle-text-box {
        display: flex;
        align-items: center;

        .text {
          color: rgba(255, 255, 255, 0.4);
        }

        .img-box {
          display: grid;
          place-items: center;
          cursor: pointer;
          margin-left: 1rem;
          width: 2rem;
          height: 2rem;
          transition: all 0.3s ease-in-out;

          &:hover {
            transform: scale(1.1);
          }

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .balance-top-box-purple {
      background-image: url("../../../assets/overview-available-bg.png") !important;
    }

    .quick-action-wrap {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @media only screen and (max-width: 42em) {
        margin-top: 1.5rem;
      }

      @media only screen and (max-width: 38em) {
        margin-top: 1rem;
      }

      .title {
        // color: #071428;
        // text-transform: capitalize;
        font-weight: 600;
      }

      .select-box {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 3rem;
        box-shadow: none !important;

        @media only screen and (max-width: 68em) {
          grid-gap: 2rem;
        }

        @media only screen and (max-width: 61em) {
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 2rem;
        }

        @media only screen and (max-width: 42em) {
          grid-gap: 1.5rem;
        }

        @media only screen and (max-width: 36em) {
          grid-gap: 1rem;
        }

        .box {
          padding: 1.5rem 1.5rem;
          border-radius: 1.2rem;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          border: none;
          // box-shadow: 0px 4.41754px 17.6701px rgba(208, 213, 221, 0.25);
          cursor: pointer;

          @media only screen and (max-width: 42em) {
            align-items: center;
            padding: 1.2rem 0.2rem;
          }

          @media only screen and (max-width: 36em) {
            // grid-gap: .8rem;
            border-radius: 0.8rem;
          }

          &:hover .img-box {
            animation: moveUpandDown 3s both infinite;
          }

          .img-box {
            width: 4rem;
            height: 4rem;
            transition: all 0.3s ease-in-out;

            @media only screen and (max-width: 42em) {
              width: 4rem;
              height: 4rem;
            }

            .img {
              width: 100%;
              height: 100%;
              // display: none;
              object-fit: contain;
            }
          }

          .name {
            margin-top: 1.8rem;
            font-size: 1.4rem;
            color: #333333;
            font-weight: 700;
            text-transform: capitalize;
            // color: red !important;

            @media only screen and (max-width: 36em) {
              margin-top: 1rem;
              font-weight: 600;
              font-size: 1.3rem;
              text-align: center;
            }
          }

          .text {
            font-size: 1.1rem;
            // color: ;

            @media only screen and (max-width: 42em) {
              display: none;
            }
          }
        }
      }
    }
  }
}

@keyframes moveUpandDown {
  0% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(0%);
  }
  75% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0%);
  }
}

// @include xs {
//   .request-advert-box {
//     height: max-content !important;
//     padding: 2rem !important;
//   }

//   .dashboard-overview-wrap {
//     flex: 0;
//   }
// }
