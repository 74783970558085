.new-account-drop-down-index-wrap-subs-wrap {
  //   position: relative;
  .add-new-account {
    position: absolute;
    top: 1rem;
    right: 2rem;
    padding: 1rem 2rem;
    border-radius: 1.2rem;
    //   transform: translateY(-150%);
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: translateY(-0.12rem);
    }

    .text {
      font-weight: 600;
    }

    .img-icon {
      .img-box {
        width: 2.5rem;
        height: 2.5rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .raven-modal-content-wrap {
    width: 50rem;

    .content {
      padding: 1rem 0rem;

      .new-account-drop-down-index-wrap-subs {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        border-width: 0.15rem;
        padding: 1.5rem 0rem;
        position: relative;

        & > *:not(:last-child) {
          padding-bottom: 1.5rem;
          border-width: 0.15rem;
        }

        .item-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 2rem;
          padding-right: 2rem;
          cursor: pointer;

          .primary-box {
            // margin-left: auto;
            background-color: rgba(240, 238, 255, 1);
            padding: 0.6rem 1rem;
            border-radius: 2rem;
            // align-self: flex-start;

            .text {
              font-weight: 500;
              color: rgba(117, 90, 226, 1);
              font-size: 1.2rem;
            }
          }

          .account-copy-details {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            text-align: right;
            gap: 0.4rem;

            .copy-box {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              padding: 0.7rem 1.4rem;
              border-radius: 0.4rem;

              .text {
                font-size: 1.25rem;
                transform: translateY(0.15rem);
              }

              .copy-icon {
                width: 2rem;
                height: 2rem;
                display: grid;
                place-items: center;

                .icon-box {
                  width: 90%;
                  height: 90%;
                  display: grid;
                  place-items: center;
                  border-radius: 50%;
                  position: relative;

                  .icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 50%;
                    height: 50%;
                    color: #ffffff;
                  }
                }

                .img-box {
                  width: 100%;
                  height: 100%;

                  .img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }
              }
            }
          }

          .bank-details {
            display: flex;
            flex-direction: column;
            gap: 0.4rem;

            .account-name {
              font-weight: 600;
              font-size: 1.55rem;
              display: flex;
              align-items: center;
              gap: 0.7rem;

              .check-box {
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 50%;
                display: grid;
                place-items: center;
                // background-color: #ffffff;

                .icon {
                  width: 1rem;
                  height: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
