.invoice-main-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;

 
}
.invoice-main-wrap .invoice_top_bar {
  display: flex;
  align-items: center;
  padding: 1rem 3rem;
  width: 100%;
  justify-content: space-between;
  flex: 1;
}
.invoice-main-wrap .invoice_top_bar .top_table_stats {
  display: flex;
  flex-direction: row;
  width: 50%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
}
.invoice-main-wrap .invoice_top_bar .top_table_stats .all_invoice,
.invoice-main-wrap .invoice_top_bar .top_table_stats .paid_invoice,
.invoice-main-wrap .invoice_top_bar .top_table_stats .unpaid_invoice {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.1rem;
  border: 1px solid #f7f8f7;
  border-radius: 1.2rem;
  width: 15.1rem;
}
.invoice-main-wrap .invoice_top_bar .top_table_stats .all_invoice p,
.invoice-main-wrap .invoice_top_bar .top_table_stats .paid_invoice p,
.invoice-main-wrap .invoice_top_bar .top_table_stats .unpaid_invoice p {
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  color: #676767;
}
.invoice-main-wrap .invoice_top_bar .top_table_stats .all_invoice span,
.invoice-main-wrap .invoice_top_bar .top_table_stats .paid_invoice span,
.invoice-main-wrap .invoice_top_bar .top_table_stats .unpaid_invoice span {
  font-size: 1.2rem;
  text-align: center;
  padding: 0.8rem 1.2rem;
  /* color: #000000; */
  border-radius: 7.2rem;
}
.invoice-main-wrap .invoice_top_bar .top_table_stats .all_invoice span {
  background: #f7f8f7;
  /* color: #000000; */
  width: 6.4rem;
}
.invoice-main-wrap .invoice_top_bar .top_table_stats .paid_invoice span {
  background: #e8fff6;
  color: #1ace37;
}
.invoice-main-wrap .invoice_top_bar .top_table_stats .unpaid_invoice span {
  background: #fff6ed;
  color: #ea872d;
}
.invoice-main-wrap .invoice_top_bar .title-text {
  margin-right: auto;
}
.invoice-main-wrap .invoice_top_bar .title-text .text {
  font-size: 1.25rem;
}
.invoice-main-wrap .invoice_top_bar .title-text .title {
  font-weight: 700;
  font-size: 1.5rem;
}
.invoice-main-wrap .invoice_top_bar .fitler-box {
  display: grid;
  grid-template-columns: 1fr 1fr max-content;
  grid-gap: 2rem;
  align-items: center;
}
.invoice-main-wrap .invoice_top_bar .fitler-box .reset-text {
  color: var(--primary-purple);
  font-weight: 600;
  cursor: pointer;
}
.invoice-main-wrap .invoice_top_bar .fitler-box .react-select-class {
  min-width: 10rem;
}
.invoice-main-wrap
  .invoice_top_bar
  .fitler-box
  .react-select-class
  > *:nth-child(4) {
  width: 20rem;
  left: unset;
  right: 0;
}
.invoice-main-wrap {
  .filter-sort-wrap-box {
    display: flex;
    align-items: center;
    justify-content: unset;
    padding: 1.5rem 3rem 1rem 3rem;
    margin-bottom: 1.7rem;
    gap: 1.5rem;
    align-items: center;

    @media only screen and (max-width: 48em) {
      flex-direction: column;
      gap: 3.5rem;
      padding: 1.5rem 0rem;
      width: 90%;
      margin-right: auto;
      margin-left: auto;

      form {
        width: 100%;
        .form-group {
          width: 100%;
        }
      }
    }

    .status-box-wrap {
      align-self: center;
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-right: auto;

      @media only screen and (max-width: 48em) {
        width: 100%;
      }

      .child {
        // min-width: 10rem;
        padding: 0rem 1.2rem;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
          content: "";
          position: absolute;
          top: 100%;
          left: 0%;
          width: 100%;
          height: 0.4rem;
          // background-color: #020202;
          border-radius: 1rem;
          transform: translateY(2rem);
          visibility: hidden;

          @media only screen and (max-width: 48em) {
            transform: translateY(1.3rem);
          }
        }

        span {
          position: relative;
          z-index: 3;
          display: inline-block;
          transform: translateY(0.55rem);
          color: currentColor;
        }
      }

      .child-active {
        &::after {
          visibility: visible;
        }
      }
    }

    .invoice-search {
      width: max-content;
    }
  }
}
.invoice-main-wrap .table-wrap {
  padding-bottom: 8rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media only screen and (max-width: 48em) {
    margin: 2rem auto 5rem auto;
    width: 90%;
    padding-bottom: unset;
  }

  .table {
    @media only screen and (max-width: 48em) {
      display: none;
    }
  }

  .invoice-table-action-wrap {
    margin: 0rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: red;
    gap: 1rem;
    width: 100%;

    .img-box {
      width: 3.5rem;
      height: 3.5rem;
      // background-color: #f7f8f7;
      border-radius: 50%;
      display: grid;
      place-items: center;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }

      .img {
        width: 50%;
        height: 50%;
        object-fit: contain;
      }
    }

    // &>*:nth-child(2){

    //   .img {
    //     width: 50%;
    //     height: 50%;
    //     object-fit: contain;
    //   }
    // }

    .delete-box {
      background-color: #fff5f5;
    }
  }
}
.invoice-main-wrap .table-wrap .table-pagination-box {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  padding: 0rem 3rem;
}
.invoice-main-wrap .table-wrap thead tr > *:first-child {
  padding-left: 3rem;
}
.invoice-main-wrap .table-wrap thead tr > *:last-child {
  text-align: center;
  padding-right: 3rem;
}
.invoice-main-wrap .table-wrap .table-body tr > *:first-child {
  padding-left: 3rem;
}
.invoice-main-wrap .table-wrap .table-body tr > *:last-child {
  padding-right: 3rem;
}
.invoice-main-wrap .table-wrap .table-body tr .action-box {
  justify-content: center;
}
.invoice-main-wrap .table-wrap .table-body tr .status-type-wrap {
  background-color: #e9f5ff;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #476885;
}
.invoice-main-wrap .table-wrap .table-body tr .status-type-wrap span {
  font-size: 1.2rem;
}
.invoice-main-wrap .table-wrap .table-body tr .status-type-wrap-fail {
  background-color: #fff5f5;
  color: #ff0f00;
}
.invoice-main-wrap .table-wrap .table-body tr .status-type-wrap-success {
  background-color: #e8fff6;
  color: #1ace37 !important;
}
.invoice-main-wrap .table-wrap .table-body tr .status-type-wrap-pending {
  background-color: #fff6ed;
  color: #ea872d;
}
.invoice-main-wrap .table-wrap .type-wrap {
  padding: 0.8rem 1.2rem;
  background-color: #f7f8f7;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
}
.invoice-main-wrap .table-wrap .type-wrap span {
  font-size: 1.2rem;
}
.invoice-main-wrap .table-wrap .type-text-box {
  display: flex;
  align-items: center;
}
.invoice-main-wrap .table-wrap .type-text-box .img-box {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
}
.invoice-main-wrap .table-wrap .type-text-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.transaction-download-receipt-modal .wrap-modal .title {
  font-size: 1.6rem;
  font-weight: 700;
}
.transaction-download-receipt-modal .wrap-modal .underline {
  width: 100%;
  height: 0.1rem;
  background-color: #f0eeff;
  margin: 1.3rem 0rem;
}
.transaction-download-receipt-modal .label-value-status-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.transaction-download-receipt-modal .label-value-status-box .box {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}
.transaction-download-receipt-modal .label-value-status-box .box .label {
  flex: 0 0 50%;
}
.transaction-download-receipt-modal .label-value-status-box .box .value {
  flex: 1;
  font-weight: 600;
}
.transaction-download-receipt-modal
  .label-value-status-box
  .box
  .status-type-wrap {
  background-color: #e9f5ff;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #476885;
  text-transform: capitalize;
}
.transaction-download-receipt-modal
  .label-value-status-box
  .box
  .status-type-wrap
  span {
  font-size: 1.2rem;
}
.transaction-download-receipt-modal
  .label-value-status-box
  .box
  .status-type-wrap-fail {
  background-color: #fff5f5;
  color: #ff0f00;
}
.transaction-download-receipt-modal
  .label-value-status-box
  .box
  .status-type-wrap-success {
  background-color: #e8fff6;
  color: #1ace37;
}
.transaction-download-receipt-modal
  .label-value-status-box
  .box
  .status-type-wrap-pending {
  background-color: #fff6ed;
  color: #ea872d;
}

/*# sourceMappingURL=existing_invoice.css.map */

.top-invoice-container-box {
  // min-height: 20rem;
  width: 100%;
  // background-color: red;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 2rem;
  margin-bottom: 1.5rem;

  @media only screen and (max-width: 55em) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }

  .box {
    border-radius: 1.2rem;
    padding: 3rem 1rem 1rem 1rem;

    .title {
      font-weight: 700;
      padding-left: 2rem;
      margin-bottom: 1rem;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      gap: 1rem;

      .title-total {
        padding: 0.4rem 1rem;
        border-radius: 1rem;
        display: grid;
        place-items: center;

        span {
          display: inline-block;
          color: #ffffff !important;
          font-size: 1.1rem;
        }
      }
    }

    .value-box {
      padding: 1.5rem 1rem 1.5rem 2rem;
      border-radius: 0.8rem;

      .value {
        font-weight: 700;
        font-size: 1.75rem;
      }
    }
  }
}

.invoice-setting-modal-wrap .raven-modal-content-wrap {
  width: 50rem;

  @media only screen and (max-width: 48em) {
    width: 90vw;
  }
}

.invoice-setting-wrap-modal {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  // background-color: red;
  height: 70vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .title-text-box {
    .title {
      font-weight: 700;
      font-size: 1.7rem;
    }
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
  }

  .content-box {
    display: flex;
    flex-direction: column;
    gap: 2.3rem;
    // margin-top: 3rem;

    .invoice-address-box {
      // display: none;
      margin-top: 3rem;
      padding-top: 1.5rem;

      .address-content-box {
        margin-top: 1.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;

        .address-text{
          font-weight: 700;
          font-size: 1.5rem;
        }

        .img-wrap {

          .img-box {
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            display: grid;
            place-items: center;

            .img {
              width: 50%;
              height: 50%;
              object-fit: contain;
            }
          }
        }
      }

      .text-title-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .edit-box {
          margin-right: 1.5rem;

          .text {
            color: #755ae2;
            font-weight: 600;
            cursor: pointer;
            font-size: 1.5rem;
          }
        }
      }
    }

    .info-box {
      display: flex;
      padding-bottom: 1.5rem;
      flex-direction: column;

      .title {
        font-weight: 700;
        font-size: 1.35rem;
      }
    }

    .increase-decrease-box {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: 1rem;

      .increase-box,
      .decrease-box {
        width: 4rem;
        height: 4rem;
        display: grid;
        place-items: center;
        border-radius: 0.8rem;
        cursor: pointer;
      }

      .input-box {
        display: flex;
        justify-content: center;
        align-items: center;
        // gap: .5rem;
        width: 5rem;
        font-weight: 700;

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        // input[type="number"] {
        //   -moz-appearance: textfield; /* Firefox */
        // }

        .input {
          border: none !important;
          outline: none;
          width: 100%;
          min-width: unset;
          padding: 0.75rem 0.2rem;
          border-radius: 0.8rem;
          font-weight: 700;
          text-align: right;
          // back
          // display: none;

          &:focus {
            // border: .1rem solid red !important;
            margin-right: 0.5rem;
            outline: none;
          }
        }

        // .input-amount{
        //   width: 10rem;
        // }
      }

      .input-box-amount {
        width: 20rem;
      }

      .form-group .form-input {
        // background-color: transparent;
        // border: none !important;
        font-weight: 700;
      }
    }

    .day-select-box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 2rem;
      margin-top: 1.5rem;

      .list-item {
        padding: 1.2rem 1.5rem;
        border-radius: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        cursor: pointer;
        width: max-content;
        box-shadow: 0px 3.56px 7.12px -1.78px rgba(51, 51, 51, 0.1),
          0px 1.78px 3.56px -1.78px rgba(51, 51, 51, 0.06);

        .check-box {
          width: 1.2rem;
          height: 1.2rem;
          display: grid;
          place-items: center;
          background-color: #ffffff;
          border-radius: 50%;

          .icon {
            width: 0.65rem;
            height: 0.65rem;
            fill: #755ae2;
          }
        }

        span {
          font-size: 1.1rem;
          font-weight: 700;
          display: inline-block;
        }
      }

      .list-item-active {
        // cursor: default;
        background-color: #755ae2 !important;

        span {
          color: #ffffff !important;
        }
      }
    }
  }
}
