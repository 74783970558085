.new-account-drop-index-wrapper-source-account {
  display: flex;
  align-items: center;
  position: relative;
}
.new-account-drop-index-wrapper-source-account .value-selected-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: rgb(255, 246, 237);
  padding: 0.5rem 1.2rem;
  border-radius: 2.5rem;
}
.new-account-drop-index-wrapper-source-account .value-selected-box .value-select {
  text-transform: capitalize;
  font-size: 1.3rem;
}
.new-account-drop-index-wrapper-source-account .value-selected-box .icon-box {
  margin-top: 0.25rem;
}
.new-account-drop-index-wrapper-source-account .value-selected-box .icon-box .img-box {
  width: 2rem;
  height: 2rem;
}
.new-account-drop-index-wrapper-source-account .value-selected-box .icon-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.new-account-drop-index-wrapper-source-account .drop-box-wrapper {
  width: 25rem;
  position: absolute;
  border-radius: 1.2rem;
  bottom: 110%;
  right: 0%;
  display: flex;
  flex-direction: column;
  border-width: 0.15rem;
  animation: moveDownSettingDrop 0.5s ease-in-out backwards;
  max-height: 25rem;
  overflow-y: scroll;
}
.new-account-drop-index-wrapper-source-account .drop-box-wrapper::-webkit-scrollbar {
  display: none;
}
.new-account-drop-index-wrapper-source-account .drop-box-wrapper .item-row {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 2rem;
  text-align: left;
  border-width: 0.15rem;
  cursor: pointer;
}
.new-account-drop-index-wrapper-source-account .drop-box-wrapper .item-row .name {
  font-size: 1.45rem;
  font-weight: 600;
}
.new-account-drop-index-wrapper-source-account .drop-box-wrapper .item-row .text {
  font-size: 1.25rem;
}
.new-account-drop-index-wrapper-source-account .drop-box-wrapper > *:not(:last-child) {
  padding-bottom: 2rem;
}
.new-account-drop-index-wrapper-source-account .drop-box-wrapper .item-row-selected {
  cursor: default;
}
.new-account-drop-index-wrapper-source-account .drop-box-wrapper-bottom {
  top: 110%;
  bottom: unset;
}

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}/*# sourceMappingURL=NewAccountSelectDrop.css.map */