.create-new-business-index-wrap {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0rem;
  left: 0rem;
  z-index: 1000;
  background: rgba(2, 2, 2, 0.3);
}
@media only screen and (min-width: 110em) {
  .create-new-business-index-wrap {
    width: 100% !important;
  }
}
.create-new-business-index-wrap::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  z-index: 1;
}
.create-new-business-index-wrap > * {
  position: relative;
  z-index: 3;
}
.create-new-business-index-wrap .content-wrap {
  position: absolute;
  width: 100%;
  height: calc(100% - 6rem);
  bottom: 0rem;
  left: 0rem;
  border-top-right-radius: 3rem;
  border-top-left-radius: 3rem;
  display: flex;
  flex-direction: column;
  animation: moveUpCreateSettingDrop 0.5s ease-in-out backwards;
}
.create-new-business-index-wrap .content-wrap .top-wrap {
  border-top-right-radius: 3rem;
  border-top-left-radius: 3rem;
  position: relative;
  display: flex;
  align-items: center;
  padding: 2rem 5rem;
}
.create-new-business-index-wrap .content-wrap .top-wrap .top-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-new-business-index-wrap .content-wrap .top-wrap .top-content .step-box {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.create-new-business-index-wrap .content-wrap .top-wrap .top-content .step-box span {
  display: inline-block;
  font-weight: 600;
  font-size: 1.6rem;
}
.create-new-business-index-wrap .content-wrap .top-wrap .top-content .close-wrap {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
}
.create-new-business-index-wrap .content-wrap .top-wrap .top-content .close-wrap .img-box {
  width: 2rem;
  height: 2rem;
}
.create-new-business-index-wrap .content-wrap .top-wrap .top-content .close-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.create-new-business-index-wrap .content-wrap .top-wrap .top-content .close-wrap .text {
  font-weight: 600;
  font-size: 1.55rem;
}
.create-new-business-index-wrap .content-wrap .top-wrap .bar-wrap {
  position: absolute;
  top: 100%;
  left: 0%;
  width: 100%;
  transform: translateY(100%);
}
.create-new-business-index-wrap .content-wrap .bottom-wrap {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 3rem 0rem;
  overflow-y: scroll;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form {
  width: 40rem;
  margin: 0rem auto;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .select-option-global-wrap-box {
  align-self: center;
  display: flex;
  align-items: center;
  padding: 0.8rem 1.6rem;
  border-radius: 4rem;
  grid-gap: 2rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .select-option-global-wrap-box .select-item {
  position: relative;
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  border-radius: 4rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .select-option-global-wrap-box .select-item > * {
  position: relative;
  z-index: 2;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .select-option-global-wrap-box .select-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: #ffffff;
  border-radius: 4rem;
  z-index: 1;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .select-option-global-wrap-box .select-item-active::after {
  visibility: visible;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .select-option-global-wrap-box .select-item-active .text {
  font-weight: 600;
  color: #020202;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .title-back-text-box {
  margin-top: 2.5rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .title-back-text-box .back-box {
  display: flex;
  align-items: center;
  gap: 0.1rem;
  cursor: pointer;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .title-back-text-box .back-box .img-box {
  width: 1.7rem;
  height: 1.7rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .title-back-text-box .back-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .title-back-text-box .back-box .back {
  font-size: 1.5rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .title-back-text-box .title {
  font-size: 2.6rem;
  font-weight: 700;
  margin-bottom: -0.5rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .title-back-text-box .text {
  font-size: 1.5rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  height: -moz-max-content;
  height: max-content;
}
@media only screen and (max-width: 72em) {
  .create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box {
    flex: unset;
  }
  .create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .submit-btn-wrap {
    margin-top: 3rem;
  }
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box {
  min-height: 35rem;
  border-radius: 1.2rem;
  border-width: 0.15rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 2rem;
  overflow-y: scroll;
  padding-bottom: 7rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box::-webkit-scrollbar {
  display: none;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box > *:not(:last-child) {
  padding-bottom: 1.5rem;
  border-width: 0.15rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box > *:last-child {
  border: none !important;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box .preview-global-wrap-recipient-box {
  display: flex;
  flex-direction: column;
  border-width: 0.15rem;
  gap: 2.5rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box .preview-global-wrap-recipient-box .title-edit-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box .preview-global-wrap-recipient-box .title-edit-box .title {
  font-size: 1.55rem;
  font-weight: 700;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box .preview-global-wrap-recipient-box .title-edit-box .edit {
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
  border-width: 0.2rem !important;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box .preview-global-wrap-recipient-box .value-label-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box .preview-global-wrap-recipient-box .value-label-box .value,
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box .preview-global-wrap-recipient-box .value-label-box .label {
  font-size: 1.3rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box .preview-global-wrap-recipient-box .value-label-box .value {
  text-align: right;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2rem;
  overflow-y: scroll;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .img-business-wrap {
  background-image: url("../../../assets/business-create-bg-img.png");
  height: 14rem;
  width: 100%;
  background-position: center;
  border-radius: 1.2rem;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 2rem;
  overflow: hidden;
  background-color: rgb(240, 238, 255);
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .success-create-recipient {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .success-create-recipient .img-success-box {
  width: 18rem;
  height: 18rem;
  background-image: url("../../../assets/recipient-global-success-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .success-create-recipient .success-title {
  text-align: center;
  font-size: 2.3rem;
  font-weight: 700;
  margin: 1rem 0rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .success-create-recipient .succes-text {
  text-align: center;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .small-title {
  font-weight: 600;
  color: rgb(172, 172, 172);
  margin-top: 1.5rem;
  text-transform: uppercase;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .big-title {
  font-size: 2.1rem;
  font-weight: 700;
  margin-bottom: -0.5rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .small-create-text {
  font-size: 1.5rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .detail-list-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .detail-list-box .detail-item {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .detail-list-box .detail-item .square span {
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  background-color: rgb(234, 135, 45);
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .two-group {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .submit-btn-wrap {
  width: 100%;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .submit-btn-wrap > * {
  width: 100%;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index {
  flex: 1;
  display: flex;
  padding-top: 3rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .side-menu-box {
  flex: 0 0 25rem;
  display: flex;
  flex-direction: column;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  gap: 2.5rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .side-menu-box .item-row {
  padding: 1.5rem;
  width: 100%;
  border-radius: 1.2rem;
  cursor: pointer;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .side-menu-box .item-row-active {
  cursor: default;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .side-menu-box .item-row-active .name {
  font-weight: 600;
  font-size: 1.35rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box {
  flex: 1;
  display: flex;
  justify-content: center;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div {
  width: 40rem;
  height: 35rem;
  display: flex;
  flex-direction: column;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .form-title-text {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  gap: 0.3rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .form-title-text .title {
  font-weight: 600;
  font-size: 2.2rem;
}
.create-new-business-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .form-title-text .text {
  font-size: 1.3rem;
}
.create-new-business-index-wrap .content-wrap-no-animation {
  animation: unset;
}

@keyframes moveUpCreateSettingDrop {
  0% {
    transform: translateY(5%);
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}/*# sourceMappingURL=CreateBusinessWrapIndex.css.map */