.send-money-wrap-box-bank {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box-bank {
    width: 90%;
    margin: 0rem auto;
  }
}
.send-money-wrap-box-bank .form {
  width: 40rem;
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  gap: 2rem;
  padding-bottom: 10rem;
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box-bank .form {
    width: 100%;
    margin: 0rem auto;
  }
}
.send-money-wrap-box-bank .form .verify-name-box {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: -1rem;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  width: 100%;
}
.send-money-wrap-box-bank .form .verify-name-box > *:first-child .label-check {
  border-radius: 50%;
  transform: scale(0.8);
}
.send-money-wrap-box-bank .form .verify-name-box .text {
  font-weight: 600;
  font-size: 1.3rem;
  margin-left: 0.5rem;
}
.send-money-wrap-box-bank .form .verify-name-box-show {
  max-height: 5rem;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}
.send-money-wrap-box-bank .form .select-react {
  position: relative;
  z-index: 10;
}
.send-money-wrap-box-bank .form .react-select-class-selected > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  font-size: 1.4rem;
  color: #020202;
}
.send-money-wrap-box-bank .form .label-span-amount {
  font-weight: 600 !important;
  opacity: 1 !important;
}
.send-money-wrap-box-bank .form .text-line {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  width: 100%;
}
.send-money-wrap-box-bank .form .text-line .text {
  color: #676767;
  background-color: #f7f8f7;
  font-size: 1.1rem;
  border: 0.1rem solid #cccccc;
  padding: 0.5rem 1.1rem;
  border-radius: 10rem;
  font-weight: 600;
  width: -moz-max-content;
  width: max-content;
  text-align: center;
  text-transform: uppercase;
}
.send-money-wrap-box-bank .form .text-line span {
  height: 0.1rem;
  background-color: rgba(204, 204, 204, 0.5);
}
.send-money-wrap-box-bank .form .add-another-box {
  display: flex;
  align-items: center;
  margin-top: -1rem;
  align-self: flex-start;
  cursor: default;
  opacity: 0.5;
}
.send-money-wrap-box-bank .form .add-another-box:hover .img-box {
  transform: scale(1.2);
}
.send-money-wrap-box-bank .form .add-another-box .text {
  color: var(--primary-purple);
  font-weight: 600;
  font-size: 1.3rem;
}
.send-money-wrap-box-bank .form .add-another-box .img-box {
  transition: all 0.3s ease-in-out;
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
  margin-right: 0.3rem;
}
.send-money-wrap-box-bank .form .add-another-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-wrap-box-bank .form .add-another-box-active {
  opacity: 1;
  cursor: pointer;
}
.send-money-wrap-box-bank .form .recepient-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  width: 100%;
  position: relative;
  z-index: 200;
}
.send-money-wrap-box-bank .form .recepient-box .text-line {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  width: 100%;
}
.send-money-wrap-box-bank .form .recepient-box .text-line .text {
  color: #676767;
  background-color: #f7f8f7;
  font-size: 1.1rem;
  border: 0.1rem solid #cccccc;
  padding: 0.5rem 1.1rem;
  border-radius: 10rem;
  font-weight: 600;
  text-transform: uppercase;
}
.send-money-wrap-box-bank .form .recepient-box .text-line span {
  height: 0.1rem;
  background-color: rgba(204, 204, 204, 0.5);
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box {
  display: flex;
  align-items: center;
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap {
  margin-left: auto;
  position: relative;
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .text {
  font-size: 1.3rem;
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .cancel-box {
  margin-left: auto;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background-color: #ffdfdd;
  margin-left: auto;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.3s;
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .cancel-box:hover {
  transform: scale(1.1);
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .cancel-box .icon {
  fill: #ff0f00;
  width: 1.1rem;
  height: 1.1rem;
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .view-all-box {
  position: absolute;
  bottom: 105%;
  right: 0;
  width: 35rem;
  height: 30rem;
  padding: 2rem;
  z-index: 4;
  overflow-y: scroll;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 2rem;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
  transform-origin: bottom right;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap {
  display: flex;
  align-items: center;
  padding: 1rem 1.3rem;
  border: 0.1rem solid #cccccc !important;
  border-radius: 0.8rem;
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .text-box .name {
  font-weight: 700;
  font-size: 1.3rem;
  text-transform: capitalize;
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .text-box .text {
  font-size: 1.1rem;
  font-weight: 400;
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .img-box {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .cancel-box {
  margin-left: auto;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background-color: #ffdfdd;
  margin-left: auto;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.3s;
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .cancel-box:hover {
  transform: scale(1.1);
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .cancel-box .icon {
  fill: #ff0f00;
  width: 1.1rem;
  height: 1.1rem;
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar:horizontal {
  display: none;
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar {
  width: 0.3rem;
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar-thumb:hover {
  background: #020202;
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .view-all-box-show {
  transform: scale(1);
}
.send-money-wrap-box-bank .form .recepient-box .view-total-box .view-wrap .text {
  cursor: pointer;
  font-weight: 600;
  color: var(--primary-purple);
}
.send-money-wrap-box-bank .form .recepient-box .preview-box {
  margin-top: 1rem;
  max-height: 15rem;
  padding: 1rem 0rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.5rem;
  overflow-x: scroll;
}
.send-money-wrap-box-bank .form .recepient-box .preview-box::-webkit-scrollbar {
  display: none;
}
.send-money-wrap-box-bank .form .recepient-box .preview-box .box {
  background-color: #f7f8f7;
  padding: 1rem;
  cursor: pointer;
  position: relative;
}
.send-money-wrap-box-bank .form .recepient-box .preview-box .box .img-box {
  width: 4.5rem;
  height: 4.5rem;
}
.send-money-wrap-box-bank .form .recepient-box .preview-box .box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-wrap-box-bank .form .recepient-box .preview-box .box .cancel-box {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background-color: #ffdfdd;
  margin-left: auto;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.3s;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.send-money-wrap-box-bank .form .recepient-box .preview-box .box .cancel-box:hover {
  transform: scale(1.1);
}
.send-money-wrap-box-bank .form .recepient-box .preview-box .box .cancel-box .icon {
  fill: #ff0f00;
  width: 1.1rem;
  height: 1.1rem;
}
.send-money-wrap-box-bank .form .recepient-box .preview-box .box .name {
  margin-top: 2rem;
  font-size: 1.45rem;
  text-transform: capitalize;
}
.send-money-wrap-box-bank .form .recepient-box-show {
  overflow-x: unset;
  overflow-y: unset;
  max-height: 30rem;
  transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
}
.send-money-wrap-box-bank .form .beneficiary-box {
  width: 100%;
}
.send-money-wrap-box-bank .form .beneficiary-box .text-line {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  width: 100%;
  margin: 1rem 0rem;
}
.send-money-wrap-box-bank .form .beneficiary-box .text-line .text {
  color: #676767;
  background-color: #f7f8f7;
  font-size: 1.1rem;
  border: 0.1rem solid #cccccc;
  padding: 0.5rem 1.1rem;
  border-radius: 10rem;
  font-weight: 600;
  text-transform: uppercase;
}
.send-money-wrap-box-bank .form .beneficiary-box .text-line span {
  height: 0.1rem;
  background-color: rgba(204, 204, 204, 0.5);
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box {
  display: flex;
  align-items: center;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .select-bene-wrap {
  position: relative;
  z-index: 15;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .select-bene-wrap .react-select-class > *:nth-child(3) {
  background-color: #fafaff;
  border: 0.15rem solid #dbd6fc;
  color: #755ae2;
  font-weight: 600;
  padding: 0.1rem;
  max-width: 17rem;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .select-bene-wrap .react-select-class > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  font-size: 1.2rem;
  color: #755ae2;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .select-bene-wrap .react-select-class > *:nth-child(3) > *:nth-child(2) > *:nth-child(2) {
  padding: unset;
  padding-right: 0.5rem;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .select-bene-wrap .react-select-class > *:nth-child(3) > *:nth-child(2) > *:nth-child(2) svg {
  fill: #755ae2;
  transform: scale(0.8);
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .select-bene-wrap .react-select-class > *:nth-child(3) > *:nth-child(2) span {
  display: none;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .select-bene-wrap .react-select-class > *:nth-child(4) {
  width: -moz-max-content;
  width: max-content;
  min-width: 20rem;
  left: unset;
  right: 0%;
  font-size: 1.2rem !important;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap {
  margin-left: auto;
  position: relative;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .text {
  font-size: 1.3rem;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .cancel-box {
  margin-left: auto;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background-color: #ffdfdd;
  margin-left: auto;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.3s;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .cancel-box:hover {
  transform: scale(1.1);
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .cancel-box .icon {
  fill: #ff0f00;
  width: 1.1rem;
  height: 1.1rem;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .view-all-box {
  position: absolute;
  bottom: 105%;
  right: 0;
  width: 35rem;
  height: 30rem;
  padding: 2rem;
  z-index: 4;
  overflow-y: scroll;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 2rem;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
  transform-origin: bottom right;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .view-all-box .wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap {
  display: flex;
  align-items: center;
  padding: 1rem 1.3rem;
  border: 0.1rem solid #f7f8f7;
  border-radius: 0.8rem;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .text-box .name {
  font-weight: 700;
  font-size: 1.3rem;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .text-box .text {
  font-size: 1.1rem;
  font-weight: 400;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .img-box {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .cancel-box {
  margin-left: auto;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background-color: #ffdfdd;
  margin-left: auto;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.3s;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .cancel-box:hover {
  transform: scale(1.1);
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .cancel-box .icon {
  fill: #ff0f00;
  width: 1.1rem;
  height: 1.1rem;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar:horizontal {
  display: none;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar {
  width: 0.3rem;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar-thumb:hover {
  background: #020202;
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .view-all-box-show {
  transform: scale(1);
}
.send-money-wrap-box-bank .form .beneficiary-box .view-total-box .view-wrap .text {
  cursor: pointer;
  font-weight: 600;
  color: var(--primary-purple);
}
.send-money-wrap-box-bank .form .beneficiary-box .preview-box {
  max-height: 15rem;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
  overflow-x: scroll;
}
.send-money-wrap-box-bank .form .beneficiary-box .preview-box::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box-bank .form .beneficiary-box .preview-box {
    grid-template-columns: repeat(2, 1fr);
  }
}
.send-money-wrap-box-bank .form .beneficiary-box .preview-box .box {
  border-radius: 0.8rem;
  border: 0.1rem solid rgba(204, 204, 204, 0.5);
  padding: 1rem 0.8rem;
  cursor: pointer;
  position: relative;
  gap: 0.4rem;
  position: relative;
}
.send-money-wrap-box-bank .form .beneficiary-box .preview-box .box .check-box {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: grid;
  place-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #020202;
  visibility: hidden;
}
.send-money-wrap-box-bank .form .beneficiary-box .preview-box .box .check-box .icon {
  width: 0.6rem;
  height: 0.6rem;
  fill: #ffffff;
}
.send-money-wrap-box-bank .form .beneficiary-box .preview-box .box .avatar-box {
  margin-bottom: -1rem;
  margin-top: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: #f7f8f7;
}
.send-money-wrap-box-bank .form .beneficiary-box .preview-box .box .avatar-box span {
  font-weight: 700;
  font-size: 1.3rem;
  text-transform: uppercase;
  color: #020202;
}
.send-money-wrap-box-bank .form .beneficiary-box .preview-box .box .name {
  margin-top: 2rem;
  font-size: 1.2rem;
  text-transform: capitalize;
}
.send-money-wrap-box-bank .form .beneficiary-box .preview-box .box .text {
  font-size: 0.9rem;
}
.send-money-wrap-box-bank .form .beneficiary-box .preview-box .box-card-active {
  border: 0.1rem solid #020202;
}
.send-money-wrap-box-bank .form .beneficiary-box .preview-box .box-card-active .check-box {
  visibility: visible;
}

.send-money-wrap-box-bank-auto {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 6rem;
}
.send-money-wrap-box-bank-auto .bulk-schedule-narration-box {
  width: 40rem;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.send-money-wrap-box-bank-auto .bulk-schedule-narration-box .total-box-summary-wrap {
  display: flex;
  gap: 2rem;
  padding: 2rem 0rem;
}
.send-money-wrap-box-bank-auto .bulk-schedule-narration-box .total-box-summary-wrap > * {
  flex: 0 0 50%;
}
.send-money-wrap-box-bank-auto .bulk-schedule-narration-box .total-box-summary-wrap .box {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: -moz-max-content;
  width: max-content;
}
.send-money-wrap-box-bank-auto .bulk-schedule-narration-box .total-box-summary-wrap .box .label {
  text-transform: uppercase;
  font-size: 1.3rem;
}
.send-money-wrap-box-bank-auto .bulk-schedule-narration-box .total-box-summary-wrap .box .value {
  font-weight: 700;
  font-size: 1.75rem;
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box-bank-auto .bulk-schedule-narration-box {
    width: 90%;
    margin: 2rem auto;
  }
}
.send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer {
  width: 100% !important;
  padding: 0rem 3rem;
}
@media only screen and (max-width: 58em) {
  .send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer {
    overflow-x: scroll;
    width: 100%;
    padding-bottom: 3rem;
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
  .send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer::-webkit-scrollbar:vertical {
    display: none;
  }
  .send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.4rem;
  }
  .send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }
  .send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer::-webkit-scrollbar-thumb {
    background: #020202;
    border-radius: 30rem;
  }
  .send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer::-webkit-scrollbar-thumb:hover {
    background: #020202;
  }
  .send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer .table-wrap {
    width: 60rem;
    padding-bottom: 3rem;
  }
  .send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer .table-wrap .status-type-wrap {
    background-color: #e9f5ff;
    border-radius: 3rem;
    width: -moz-max-content;
    width: max-content;
    padding: 0.8rem 1.2rem;
    color: #476885;
    text-transform: capitalize;
  }
  .send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer .table-wrap .status-type-wrap span {
    font-size: 1.2rem;
  }
  .send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer .table-wrap .status-type-wrap-fail {
    background-color: #fff5f5;
    color: #ff0f00;
  }
  .send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer .table-wrap .status-type-wrap-success {
    background-color: #e8fff6;
    color: #1ace37;
  }
  .send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer .table-wrap .status-type-wrap-pending {
    background-color: #fff6ed;
    color: #ea872d;
  }
}
.send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer .text-avatar-box {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer .text-avatar-box .avatar-box {
  width: 3rem;
  height: 3rem;
  background-color: #f7f8f7;
  border: 0.1rem solid #cccccc;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer .text-avatar-box .avatar-box span {
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: capitalize;
}
.send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer .btn-upload-box {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer .btn-upload-box > *:last-child {
  margin-left: 2rem;
}
.send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer .btn-upload-box input {
  display: none;
}
.send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer .btn-upload-box .raven-btn {
  min-width: 15rem;
}
.send-money-wrap-box-bank-auto .table-wrapper-bulk-transfer .table-pagination-box {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
.send-money-wrap-box-bank-auto .upload-doc-wrap {
  width: 55rem;
  margin: 0rem auto;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  gap: 3rem;
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box-bank-auto .upload-doc-wrap {
    width: 90%;
  }
}
.send-money-wrap-box-bank-auto .upload-doc-wrap .upload-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.send-money-wrap-box-bank-auto .upload-doc-wrap .upload-wrapper .upload-group {
  min-height: 20rem;
}
.send-money-wrap-box-bank-auto .upload-doc-wrap .upload-wrapper .upload-group label {
  height: 20rem;
}
.send-money-wrap-box-bank-auto .upload-doc-wrap .upload-wrapper .upload-text-pro {
  color: #755ae2;
  font-weight: 600;
}
.send-money-wrap-box-bank-auto .upload-doc-wrap .download-info-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 3.5rem;
  border-radius: 1.2rem;
  background-color: #f7f8f7;
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box-bank-auto .upload-doc-wrap .download-info-box {
    padding: 1.5rem;
  }
}
.send-money-wrap-box-bank-auto .upload-doc-wrap .download-info-box .text-icon-box {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box-bank-auto .upload-doc-wrap .download-info-box .text-icon-box {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.4rem;
    margin-bottom: 0.3rem;
  }
}
.send-money-wrap-box-bank-auto .upload-doc-wrap .download-info-box .text-icon-box .img-box {
  margin-right: 1rem;
  display: grid;
  place-items: center;
}
.send-money-wrap-box-bank-auto .upload-doc-wrap .download-info-box .text-icon-box .text {
  font-size: 1.3rem;
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box-bank-auto .upload-doc-wrap .download-info-box .text-icon-box .text {
    flex-direction: column;
    align-items: flex-start;
  }
}
.send-money-wrap-box-bank-auto .upload-doc-wrap .download-info-box .text-icon-box .text span {
  color: var(--primary-purple) !important;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s;
  display: inline-block;
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box-bank-auto .upload-doc-wrap .download-info-box .text-icon-box .text span {
    margin-top: -0.4rem;
  }
}
.send-money-wrap-box-bank-auto .upload-doc-wrap .download-info-box .text-icon-box .text span:hover {
  transform: translateY(-0.02rem);
}
.send-money-wrap-box-bank-auto .upload-doc-wrap .download-info-box .dot-text {
  display: flex;
}
.send-money-wrap-box-bank-auto .upload-doc-wrap .download-info-box .dot-text .dot {
  margin-right: 0.5rem;
}
.send-money-wrap-box-bank-auto .upload-doc-wrap .download-info-box .dot-text .text {
  font-size: 1.3rem;
}
.send-money-wrap-box-bank-auto .upload-doc-wrap .download-info-box .dot-text .text span {
  font-weight: 700;
  color: #020202;
}

.table-wrapper-bulk-transfer-raven-bulk {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
}
.table-wrapper-bulk-transfer-raven-bulk .table-wrap {
  flex: 1;
  overflow-y: scroll;
}
.table-wrapper-bulk-transfer-raven-bulk .table-wrap::-webkit-scrollbar {
  display: none;
}

.send-money-bulk-transfer-new-verify tr > *:not(:last-child), .send-money-bulk-transfer-new-verify tr > *:not(:first-child) {
  padding-right: 2rem !important;
}
.send-money-bulk-transfer-new-verify tr > *:nth-child(3) {
  width: 25rem;
}
.send-money-bulk-transfer-new-verify tr .edit-table-input input,
.send-money-bulk-transfer-new-verify tr .react-select-class > *:nth-child(3) {
  background-color: #ffffff;
}
.send-money-bulk-transfer-new-verify tr td {
  position: relative;
}
.send-money-bulk-transfer-new-verify tr td .one-error-box {
  display: flex;
  gap: 0.7rem;
  align-items: center;
}
.send-money-bulk-transfer-new-verify tr td .one-error-box .error-box .img-box {
  display: grid;
  place-items: center;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.send-money-bulk-transfer-new-verify tr td .one-error-box .error-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-bulk-transfer-new-verify tr td .payroll-table-action-wrap {
  margin: 0rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}
.send-money-bulk-transfer-new-verify tr td .payroll-table-action-wrap .img-box {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: transform 0.3s ease-in-out;
}
.send-money-bulk-transfer-new-verify tr td .payroll-table-action-wrap .img-box:hover {
  transform: scale(1.05);
}
.send-money-bulk-transfer-new-verify tr td .payroll-table-action-wrap .img-box .img {
  width: 40%;
  height: 40%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-bulk-transfer-new-verify tr td .payroll-table-action-wrap > *:nth-child(2) .img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-bulk-transfer-new-verify tr td .payroll-table-action-wrap .delete-box {
  background-color: #fff5f5;
}
.send-money-bulk-transfer-new-verify tr .status-type-wrap {
  background-color: #e9f5ff;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #476885;
  text-transform: capitalize;
}
.send-money-bulk-transfer-new-verify tr .status-type-wrap span {
  font-size: 1.2rem;
}
.send-money-bulk-transfer-new-verify tr .status-type-wrap-fail {
  background-color: #fff5f5;
  color: #ff0f00;
}
.send-money-bulk-transfer-new-verify tr .status-type-wrap-success {
  background-color: #e8fff6;
  color: #1ace37;
}
.send-money-bulk-transfer-new-verify tr .status-type-wrap-pending {
  background-color: #fff6ed;
  color: #ea872d;
}
.send-money-bulk-transfer-new-verify .status-type-wrap {
  background-color: #e9f5ff;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #476885;
  text-transform: capitalize;
}
.send-money-bulk-transfer-new-verify .status-type-wrap span {
  font-size: 1.2rem;
}
.send-money-bulk-transfer-new-verify .status-type-wrap-fail {
  background-color: #fff5f5;
  color: #ff0f00;
}
.send-money-bulk-transfer-new-verify .status-type-wrap-success {
  background-color: #e8fff6;
  color: #1ace37;
}
.send-money-bulk-transfer-new-verify .status-type-wrap-pending {
  background-color: #fff6ed;
  color: #ea872d;
}
.send-money-bulk-transfer-new-verify .payroll-table-action-wrap {
  margin: 0rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}
.send-money-bulk-transfer-new-verify .payroll-table-action-wrap .img-box {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: transform 0.3s ease-in-out;
}
.send-money-bulk-transfer-new-verify .payroll-table-action-wrap .img-box:hover {
  transform: scale(1.05);
}
.send-money-bulk-transfer-new-verify .payroll-table-action-wrap .img-box .img {
  width: 40%;
  height: 40%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-bulk-transfer-new-verify .payroll-table-action-wrap > *:nth-child(2) .img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-bulk-transfer-new-verify .payroll-table-action-wrap .delete-box {
  background-color: #fff5f5;
}
.send-money-bulk-transfer-new-verify .filter-export-processing-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 1.5rem 0rem;
  position: relative;
  z-index: 20;
}
.send-money-bulk-transfer-new-verify .filter-export-processing-wrap .retry-wrap-icon {
  display: flex;
}
.send-money-bulk-transfer-new-verify .filter-export-processing-wrap .retry-wrap-icon .raven-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.send-money-bulk-transfer-new-verify .filter-export-processing-wrap .retry-wrap-icon .raven-btn .img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
}
.send-money-bulk-transfer-new-verify .filter-export-processing-wrap .retry-wrap-icon .raven-btn .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-bulk-transfer-new-verify .filter-export-processing-wrap .name-numb-box {
  margin-right: 2rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  gap: 2rem;
}
.send-money-bulk-transfer-new-verify .filter-export-processing-wrap .name-numb-box .text {
  cursor: pointer;
}
.send-money-bulk-transfer-new-verify .filter-export-processing-wrap .name-numb-box span {
  background-color: #020202;
  color: #ffffff;
  padding: 0.6rem 1rem;
  font-weight: 600;
  border-radius: 0.4rem;
}
.send-money-bulk-transfer-new-verify .filter-export-processing-wrap .name-numb-box .failed {
  background-color: rgb(255, 239, 238);
  color: rgb(255, 15, 0);
}
.send-money-bulk-transfer-new-verify .filter-export-processing-wrap .name-numb-box .verified {
  background-color: rgb(232, 255, 246);
  color: rgb(26, 206, 55);
}
.send-money-bulk-transfer-new-verify .filter-export-processing-wrap .export-table-content-box {
  display: flex;
  align-items: center;
  padding: 1.2rem 1.5rem;
  border-radius: 0.8rem;
  gap: 1rem;
  cursor: pointer;
  align-self: flex-end;
  margin-right: auto;
  margin-right: auto;
}
.send-money-bulk-transfer-new-verify .filter-export-processing-wrap .export-table-content-box:hover .img-box {
  transform: translateY(0.4rem);
}
.send-money-bulk-transfer-new-verify .filter-export-processing-wrap .export-table-content-box .img-box {
  width: 2.2rem;
  height: 2.2rem;
  transition: all 0.3s;
}
.send-money-bulk-transfer-new-verify .filter-export-processing-wrap .export-table-content-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-bulk-transfer-new-verify .filter-export-processing-wrap .filter-wrap-box {
  margin-left: auto;
  margin-right: 2rem;
}

.spinner-raven-logo-wrap {
  width: 100% !important;
  max-width: unset;
  flex: 1;
}

.transfer-send-within-account-index-wrap {
  width: 40rem;
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  gap: 2rem;
  padding-bottom: 10rem;
}
@media only screen and (max-width: 42em) {
  .transfer-send-within-account-index-wrap {
    width: 100%;
    margin: 0rem auto;
  }
}/*# sourceMappingURL=SendMoneyBank.css.map */