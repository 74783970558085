.global-account-select-drop-box-wrapper {
  position: relative;
  z-index: 20;

  .select-drop-box {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 1.5rem;
    border-radius: 5rem;
    cursor: pointer;
    // width: max-content !important;

    .value {
      font-weight: 500;
    }

    .img-select {
      .img-box {
        width: 1.7rem;
        height: 1.7rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .drop-icon {
      .img-box {
        width: 1.7rem;
        height: 1.7rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .drop-select-box {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-width: 0.15rem;
    animation: moveDownSettingDrop 0.5s ease-in-out backwards;
    max-height: 30rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    display: flex;
    flex-direction: column;
    // gap: 1.3rem;
    // padding: 1.3rem 0rem;

    // &>*{
    //     padding: 0rem 1.5rem !important;
    // }

    & > *:not(:last-child) {
      padding-bottom: 1.3rem;
    }

    & > *:last-child {
      border: none;
    }

    .item-row-global {
      display: flex;
      border-width: 0.15rem;
      align-items: center;
      padding: 1.3rem;
      gap: 1rem;
      cursor: pointer;

      .img-select {
        .img-box {
          width: 1.7rem;
          height: 1.7rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
    // visibility: hidden;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
    // visibility: ;
  }
}
