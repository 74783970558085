.alert-receiver-list-modal-wrap-index {
  min-height: 35rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  max-height: 50rem;
  overflow-y: scroll;
}
.alert-receiver-list-modal-wrap-index .title-text-box {
  display: flex;
  flex-direction: column;
}
.alert-receiver-list-modal-wrap-index .title-text-box .title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.alert-receiver-list-modal-wrap-index .title-text-box .text {
  font-size: 1.3rem;
  padding-bottom: 1.5rem;
}
.alert-receiver-list-modal-wrap-index .list-box-wrap {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow-y: scroll;
  flex: 1;
  padding-bottom: 10rem;
}
.alert-receiver-list-modal-wrap-index .list-box-wrap::-webkit-scrollbar {
  display: none;
}
.alert-receiver-list-modal-wrap-index .list-box-wrap > *:not(:last-child) {
  padding-bottom: 1.5rem;
  border-width: 0.15rem;
}
.alert-receiver-list-modal-wrap-index .list-box-wrap > *:last-child {
  border: none;
}
.alert-receiver-list-modal-wrap-index .list-box-wrap .list-item {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.alert-receiver-list-modal-wrap-index .list-box-wrap .list-item .name-num-box {
  display: flex;
  flex-direction: column;
}
.alert-receiver-list-modal-wrap-index .list-box-wrap .list-item .name-num-box .name {
  font-size: 1.55rem;
  font-weight: 600;
}
.alert-receiver-list-modal-wrap-index .list-box-wrap .list-item .name-num-box .num {
  font-size: 1.25rem;
}
.alert-receiver-list-modal-wrap-index .list-box-wrap .list-item .delete-icon {
  margin-left: auto;
  cursor: pointer;
}
.alert-receiver-list-modal-wrap-index .list-box-wrap .list-item .delete-icon .img-box {
  width: 4rem;
  height: 4rem;
  background-color: rgb(255, 239, 238);
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.alert-receiver-list-modal-wrap-index .list-box-wrap .list-item .delete-icon .img-box .img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=AlertRecieversModal.css.map */