.global-account-beneficiary-modal-wrap-index {
  min-height: 35rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.global-account-beneficiary-modal-wrap-index .title {
  font-size: 1.6rem;
  font-weight: 700;
  align-self: flex-start;
  margin-bottom: 1rem;
}
.global-account-beneficiary-modal-wrap-index .load-more-select-beneficiary-list-box {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  gap: 1.5rem;
  margin-bottom: 2rem;
  min-height: 20rem;
}
.global-account-beneficiary-modal-wrap-index .load-more-select-beneficiary-list-box .select-beneficiary-list-box {
  max-height: calc(100vh - 40rem);
  padding-bottom: 2.5rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.global-account-beneficiary-modal-wrap-index .load-more-select-beneficiary-list-box .select-beneficiary-list-box::-webkit-scrollbar {
  display: none;
}
.global-account-beneficiary-modal-wrap-index .load-more-select-beneficiary-list-box .select-beneficiary-list-box > *:not(:last-child) {
  padding-bottom: 2rem;
  border-width: 0.15rem;
  margin-bottom: 2rem;
}
.global-account-beneficiary-modal-wrap-index .load-more-select-beneficiary-list-box .select-beneficiary-list-box .bene-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
.global-account-beneficiary-modal-wrap-index .load-more-select-beneficiary-list-box .select-beneficiary-list-box .bene-row .name-num-box {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.global-account-beneficiary-modal-wrap-index .load-more-select-beneficiary-list-box .select-beneficiary-list-box .bene-row .name-num-box .name {
  font-weight: 600;
  font-size: 1.65rem;
  text-transform: capitalize;
}
.global-account-beneficiary-modal-wrap-index .load-more-select-beneficiary-list-box .select-beneficiary-list-box .bene-row .img-bene-wrap .img-box {
  width: 4rem;
  height: 4rem;
}
.global-account-beneficiary-modal-wrap-index .load-more-select-beneficiary-list-box .select-beneficiary-list-box .bene-row .img-bene-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.global-account-beneficiary-modal-wrap-index .load-more-select-beneficiary-list-box .load-more-box {
  padding: 1.2rem 2rem;
  border-radius: 3rem;
  align-self: center;
  cursor: pointer;
  margin-top: auto;
}
.global-account-beneficiary-modal-wrap-index .load-more-select-beneficiary-list-box .load-more-box .text {
  font-weight: 600;
}

.index-beneficiary-global-account-wrap-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
}
.index-beneficiary-global-account-wrap-box .middle-content-box {
  width: 65rem;
  align-self: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
  overflow-y: scroll;
}
@media only screen and (max-width: 48em) {
  .index-beneficiary-global-account-wrap-box .middle-content-box {
    width: 95%;
  }
}
.index-beneficiary-global-account-wrap-box .middle-content-box .select-box-list-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 1.2rem;
  border-radius: 4rem;
  align-self: center;
  gap: 1rem;
  width: -moz-max-content;
  width: max-content;
}
@media only screen and (max-width: 48em) {
  .index-beneficiary-global-account-wrap-box .middle-content-box .select-box-list-wrap {
    border-radius: 1.2rem;
    padding: 0.5rem 0.7rem;
  }
}
.index-beneficiary-global-account-wrap-box .middle-content-box .select-box-list-wrap .item-box {
  padding: 1.2rem 2.7rem;
  display: flex;
  justify-content: center;
  border-radius: 4rem;
  cursor: pointer;
}
@media only screen and (max-width: 48em) {
  .index-beneficiary-global-account-wrap-box .middle-content-box .select-box-list-wrap .item-box {
    padding: 1rem 2rem;
  }
}
.index-beneficiary-global-account-wrap-box .middle-content-box .select-box-list-wrap .item-box .item {
  font-size: 1.45rem;
}
.index-beneficiary-global-account-wrap-box .middle-content-box .select-box-list-wrap .item-box-active {
  cursor: not-allowed;
}
.index-beneficiary-global-account-wrap-box .middle-content-box .select-box-list-wrap .item-box-active .item {
  font-weight: 600;
}
.index-beneficiary-global-account-wrap-box .middle-content-box .box-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  overflow-y: scroll;
  border-radius: 1.6rem;
  padding-bottom: 3rem;
  margin-bottom: 5rem;
}
@media only screen and (max-width: 48em) {
  .index-beneficiary-global-account-wrap-box .middle-content-box .box-wrap {
    border-radius: 1.2rem;
  }
}
.index-beneficiary-global-account-wrap-box .middle-content-box .box-wrap::-webkit-scrollbar {
  display: none;
}
.index-beneficiary-global-account-wrap-box .middle-content-box .box-wrap > *:last-child {
  border: unset;
}
.index-beneficiary-global-account-wrap-box .middle-content-box .box-wrap > *:first-child {
  padding-top: 2rem;
}
.index-beneficiary-global-account-wrap-box .middle-content-box .box-wrap > *:not(:last-child) {
  border-width: 0.15rem;
  padding-bottom: 2rem;
}
.index-beneficiary-global-account-wrap-box .middle-content-box .box-wrap .noti-wrap {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
}
.index-beneficiary-global-account-wrap-box .middle-content-box .box-wrap .noti-wrap .avatar-box {
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
  filter: drop-shadow(0px 1px 2px rgba(51, 51, 51, 0.06)) drop-shadow(0px 0px 2px rgba(51, 51, 51, 0.02));
  background-color: #ffffff;
  display: grid;
  place-items: center;
  position: relative;
}
@media only screen and (max-width: 48em) {
  .index-beneficiary-global-account-wrap-box .middle-content-box .box-wrap .noti-wrap .avatar-box {
    width: 4rem;
    height: 4rem;
  }
}
.index-beneficiary-global-account-wrap-box .middle-content-box .box-wrap .noti-wrap .avatar-box .img-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.index-beneficiary-global-account-wrap-box .middle-content-box .box-wrap .noti-wrap .avatar-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.index-beneficiary-global-account-wrap-box .middle-content-box .box-wrap .noti-wrap .text-box {
  margin-right: auto;
  margin-left: 1rem;
}
.index-beneficiary-global-account-wrap-box .middle-content-box .box-wrap .noti-wrap .text-box .value {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0.3rem;
}
.index-beneficiary-global-account-wrap-box .middle-content-box .box-wrap .noti-wrap .text-box .label {
  font-size: 1.2rem;
}
.index-beneficiary-global-account-wrap-box .middle-content-box .box-wrap .noti-wrap .delete-box {
  width: 4rem !important;
  height: 4rem !important;
  border-radius: 0.8rem;
  position: relative;
  cursor: pointer;
}
.index-beneficiary-global-account-wrap-box .middle-content-box .box-wrap .noti-wrap .delete-box .img-box {
  width: 50% !important;
  height: 50% !important;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.index-beneficiary-global-account-wrap-box .middle-content-box .box-wrap .noti-wrap .delete-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=BeneficiaryGlobalModal.css.map */