.modal-transaction-main-wrap {

    .raven-modal-content-wrap{
      width: 38rem;
    }

  .transaction-pin-wrap {
  //  padding: 0rem 2rem;

    .title {
      font-weight: 700;
      color: #020202;
      font-size: 1.7rem;
    }

    .text {
      font-size: 1.3rem;
    }

    .form-pin{
        margin-top: 3rem;
    }
  }

  .button-wrap{
    .form{
      // padding: 0rem 2rem;
    }
  }
}

// .modal-parent-wrap .content-wrap .content {
//     flex: unset;
// }


// .modal-parent-wrap{
//     .raven-modal-content-wrap{
//         height: 15rem;
//         min-height: 25rem !important;
//     }
// }


// .raven-modal-content-wrap{
//     min-height: unset !important;
//     height: 15rem;
//     display: none;
// }
