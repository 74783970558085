.dashboard-overview-wrap {
  width: 100%;
  flex: 1;
  place-items: center;
  display: grid;
}
@media only screen and (min-width: 110em) {
  .dashboard-overview-wrap {
    align-items: flex-start;
    justify-items: flex-start;
  }
}
@media only screen and (max-width: 61em) {
  .dashboard-overview-wrap {
    align-items: flex-start;
  }
}
.dashboard-overview-wrap .wrap {
  display: flex;
  flex-direction: column;
  width: 70%;
  gap: 2.5rem;
  justify-content: center;
}
@media only screen and (max-width: 68em) {
  .dashboard-overview-wrap .wrap {
    width: 80%;
  }
}
@media only screen and (max-width: 61em) {
  .dashboard-overview-wrap .wrap {
    width: 90%;
  }
}
@media only screen and (max-width: 55em) {
  .dashboard-overview-wrap .wrap {
    width: 100%;
  }
}
@media only screen and (max-width: 42em) {
  .dashboard-overview-wrap .wrap {
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 38em) {
  .dashboard-overview-wrap .wrap {
    margin-top: 1.2rem;
  }
}
.dashboard-overview-wrap .wrap .request-advert-box {
  border: none;
  border-radius: 1.2rem;
  display: flex;
  overflow: hidden;
  padding: 2.5rem 3rem;
  min-height: 20rem;
  background-image: url("../../../assets/overview-advert-img .png");
  background-size: 30%;
  background-position: bottom right;
  background-repeat: no-repeat;
}
.dashboard-overview-wrap .wrap .request-advert-box .content-box {
  flex: 0 0 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}
.dashboard-overview-wrap .wrap .request-advert-box .content-box .text {
  font-size: 2.1rem;
  letter-spacing: -0.02rem;
  font-weight: 600;
  opacity: 0.9;
}
@media only screen and (max-width: 42em) {
  .dashboard-overview-wrap .wrap .request-advert-box .content-box .text {
    font-size: 1.55rem;
  }
}
.dashboard-overview-wrap .wrap .request-advert-box .content-box .btn {
  background-color: #333333;
}
.dashboard-overview-wrap .wrap .request-advert-box .img-content-box {
  display: none;
  flex: 1;
  background-image: url("../../../assets/overview-advert-img .png");
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 42em) {
  .dashboard-overview-wrap .wrap .request-advert-box .img-content-box {
    background-position: bottom right;
    background-size: contain;
  }
}
.dashboard-overview-wrap .wrap .balance-top-box {
  width: 100%;
  background-color: #1b1b1b;
  background-image: url("../../../assets/overview-available-bg.png");
  background-size: cover;
  border-radius: 2.4rem;
  background-repeat: no-repeat;
  padding: 2.5rem 3rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
}
.dashboard-overview-wrap .wrap .balance-top-box .role-auth-level-box {
  position: absolute;
  top: 0rem;
  right: 0rem;
  display: flex;
  align-items: center;
  padding: 0.7rem 1.5rem;
  background-color: rgba(255, 246, 237, 0.2);
  border-top-right-radius: 3rem;
  border-bottom-left-radius: 1.2rem;
}
.dashboard-overview-wrap .wrap .balance-top-box .role-auth-level-box .text {
  font-size: 1.25rem;
  text-transform: capitalize;
}
.dashboard-overview-wrap .wrap .balance-top-box .role-auth-level-box .text-two {
  font-weight: 500;
  color: #ffffff !important;
}
.dashboard-overview-wrap .wrap .balance-top-box .role-auth-level-box .icon-img {
  display: grid;
  place-items: center;
  transform: translateY(0.15rem);
  margin-left: 0.2rem;
  cursor: pointer;
}
.dashboard-overview-wrap .wrap .balance-top-box .role-auth-level-box .icon-img .img-box {
  width: 2rem;
  height: 2rem;
}
.dashboard-overview-wrap .wrap .balance-top-box .role-auth-level-box .icon-img .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
@media only screen and (max-width: 42em) {
  .dashboard-overview-wrap .wrap .balance-top-box {
    border-radius: 1.2rem;
  }
}
@media only screen and (max-width: 38em) {
  .dashboard-overview-wrap .wrap .balance-top-box {
    padding: 2rem;
  }
}
.dashboard-overview-wrap .wrap .balance-top-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: url("../../../assets/overview-available-bg.png");
  background-size: cover;
  border-radius: 2.4rem;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 42em) {
  .dashboard-overview-wrap .wrap .balance-top-box::after {
    border-radius: 1.2rem;
  }
}
.dashboard-overview-wrap .wrap .balance-top-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-image: url("../../../assets/overview-amount-bg.png");
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
  border-radius: 2.4rem;
}
@media only screen and (max-width: 42em) {
  .dashboard-overview-wrap .wrap .balance-top-box::before {
    border-radius: 1.2rem;
  }
}
.dashboard-overview-wrap .wrap .balance-top-box > * {
  position: relative;
  z-index: 3;
}
.dashboard-overview-wrap .wrap .balance-top-box .btn-wrap-box {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin-top: 2.5rem;
}
.dashboard-overview-wrap .wrap .balance-top-box .btn-wrap-box > * {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  padding: 1.4rem 3rem;
  border-radius: 14rem;
}
.dashboard-overview-wrap .wrap .balance-top-box .btn-wrap-box > *:hover {
  transform: unset;
}
.dashboard-overview-wrap .wrap .balance-top-box .btn-wrap-box > *:hover .img-box {
  transform: translate(0.2rem, -0.2rem);
}
@media only screen and (max-width: 42em) {
  .dashboard-overview-wrap .wrap .balance-top-box .btn-wrap-box > * {
    min-width: unset;
  }
}
.dashboard-overview-wrap .wrap .balance-top-box .btn-wrap-box > * .img-box {
  width: 1rem;
  height: 1rem;
  transition: all 0.3s ease-in-out;
}
.dashboard-overview-wrap .wrap .balance-top-box .btn-wrap-box > * .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.dashboard-overview-wrap .wrap .balance-top-box .btn-wrap-box .btn-one {
  background-color: #ffffff;
  color: #020202;
}
.dashboard-overview-wrap .wrap .balance-top-box .btn-wrap-box .btn-one:hover .img-box {
  transform: translate(-0.1rem, 0.1rem);
}
.dashboard-overview-wrap .wrap .balance-top-box .toggle-account-select-box {
  position: relative;
  align-self: flex-start;
  z-index: 10;
}
.dashboard-overview-wrap .wrap .balance-top-box .toggle-account-select-box .select-toggle-box {
  position: absolute;
  top: 120%;
  left: 0;
}
.dashboard-overview-wrap .wrap .balance-top-box .toggle-account-select-box .value-box {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.5rem 0.8rem;
  border-radius: 0.6rem;
  cursor: pointer;
}
.dashboard-overview-wrap .wrap .balance-top-box .toggle-account-select-box .value-box .value {
  color: #ffffff;
}
.dashboard-overview-wrap .wrap .balance-top-box .toggle-account-select-box .value-box .img-box {
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;
}
.dashboard-overview-wrap .wrap .balance-top-box .toggle-account-select-box .value-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  transform: translateY(0.2rem);
}
.dashboard-overview-wrap .wrap .balance-top-box .toggle-account-select-box .value-box .img-box-up {
  transform: translateY(0.25rem) rotateX(-180deg);
}
.dashboard-overview-wrap .wrap .balance-top-box .amount-value-box {
  display: flex;
}
.dashboard-overview-wrap .wrap .balance-top-box .amount-value-box .toggle-text-box {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
.dashboard-overview-wrap .wrap .balance-top-box .amount-value-box .toggle-text-box .text {
  color: rgba(255, 255, 255, 0.4);
}
.dashboard-overview-wrap .wrap .balance-top-box .amount-value-box .toggle-text-box .img-box {
  display: grid;
  place-items: center;
  cursor: pointer;
  margin-left: 1rem;
  width: 2rem;
  height: 2rem;
  transition: all 0.3s ease-in-out;
}
.dashboard-overview-wrap .wrap .balance-top-box .amount-value-box .toggle-text-box .img-box:hover {
  transform: scale(1.1);
}
.dashboard-overview-wrap .wrap .balance-top-box .amount-value-box .toggle-text-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.dashboard-overview-wrap .wrap .balance-top-box .amount-value-box sup {
  color: rgba(255, 255, 255, 0.4);
  font-size: 2rem;
}
.dashboard-overview-wrap .wrap .balance-top-box .amount-value-box .amount {
  font-weight: 900;
  color: #ffffff;
  font-size: 2vw;
  letter-spacing: 0.05rem;
  margin-left: 0.5rem;
  align-self: center;
  min-width: 11.5rem;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 110em) {
  .dashboard-overview-wrap .wrap .balance-top-box .amount-value-box .amount {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 42em) {
  .dashboard-overview-wrap .wrap .balance-top-box .amount-value-box .amount {
    font-size: 3rem;
  }
}
.dashboard-overview-wrap .wrap .balance-top-box .amount-value-box .amount .decimal {
  color: rgba(255, 255, 255, 0.4);
}
.dashboard-overview-wrap .wrap .balance-top-box .toggle-text-box {
  display: flex;
  align-items: center;
}
.dashboard-overview-wrap .wrap .balance-top-box .toggle-text-box .text {
  color: rgba(255, 255, 255, 0.4);
}
.dashboard-overview-wrap .wrap .balance-top-box .toggle-text-box .img-box {
  display: grid;
  place-items: center;
  cursor: pointer;
  margin-left: 1rem;
  width: 2rem;
  height: 2rem;
  transition: all 0.3s ease-in-out;
}
.dashboard-overview-wrap .wrap .balance-top-box .toggle-text-box .img-box:hover {
  transform: scale(1.1);
}
.dashboard-overview-wrap .wrap .balance-top-box .toggle-text-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.dashboard-overview-wrap .wrap .balance-top-box-purple {
  background-image: url("../../../assets/overview-available-bg.png") !important;
}
.dashboard-overview-wrap .wrap .quick-action-wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media only screen and (max-width: 42em) {
  .dashboard-overview-wrap .wrap .quick-action-wrap {
    margin-top: 1.5rem;
  }
}
@media only screen and (max-width: 38em) {
  .dashboard-overview-wrap .wrap .quick-action-wrap {
    margin-top: 1rem;
  }
}
.dashboard-overview-wrap .wrap .quick-action-wrap .title {
  font-weight: 600;
}
.dashboard-overview-wrap .wrap .quick-action-wrap .select-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem;
  box-shadow: none !important;
}
@media only screen and (max-width: 68em) {
  .dashboard-overview-wrap .wrap .quick-action-wrap .select-box {
    grid-gap: 2rem;
  }
}
@media only screen and (max-width: 61em) {
  .dashboard-overview-wrap .wrap .quick-action-wrap .select-box {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
  }
}
@media only screen and (max-width: 42em) {
  .dashboard-overview-wrap .wrap .quick-action-wrap .select-box {
    grid-gap: 1.5rem;
  }
}
@media only screen and (max-width: 36em) {
  .dashboard-overview-wrap .wrap .quick-action-wrap .select-box {
    grid-gap: 1rem;
  }
}
.dashboard-overview-wrap .wrap .quick-action-wrap .select-box .box {
  padding: 1.5rem 1.5rem;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: none;
  cursor: pointer;
}
@media only screen and (max-width: 42em) {
  .dashboard-overview-wrap .wrap .quick-action-wrap .select-box .box {
    align-items: center;
    padding: 1.2rem 0.2rem;
  }
}
@media only screen and (max-width: 36em) {
  .dashboard-overview-wrap .wrap .quick-action-wrap .select-box .box {
    border-radius: 0.8rem;
  }
}
.dashboard-overview-wrap .wrap .quick-action-wrap .select-box .box:hover .img-box {
  animation: moveUpandDown 3s both infinite;
}
.dashboard-overview-wrap .wrap .quick-action-wrap .select-box .box .img-box {
  width: 4rem;
  height: 4rem;
  transition: all 0.3s ease-in-out;
}
@media only screen and (max-width: 42em) {
  .dashboard-overview-wrap .wrap .quick-action-wrap .select-box .box .img-box {
    width: 4rem;
    height: 4rem;
  }
}
.dashboard-overview-wrap .wrap .quick-action-wrap .select-box .box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.dashboard-overview-wrap .wrap .quick-action-wrap .select-box .box .name {
  margin-top: 1.8rem;
  font-size: 1.4rem;
  color: #333333;
  font-weight: 700;
  text-transform: capitalize;
}
@media only screen and (max-width: 36em) {
  .dashboard-overview-wrap .wrap .quick-action-wrap .select-box .box .name {
    margin-top: 1rem;
    font-weight: 600;
    font-size: 1.3rem;
    text-align: center;
  }
}
.dashboard-overview-wrap .wrap .quick-action-wrap .select-box .box .text {
  font-size: 1.1rem;
}
@media only screen and (max-width: 42em) {
  .dashboard-overview-wrap .wrap .quick-action-wrap .select-box .box .text {
    display: none;
  }
}

@keyframes moveUpandDown {
  0% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(0%);
  }
  75% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0%);
  }
}/*# sourceMappingURL=DashboardOverview.css.map */