.verification-five-modal-create-index-wrap {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0rem;
  left: 0rem;
  z-index: 1000;
  background: rgba(2, 2, 2, 0.3);

  @media only screen and (min-width: 110em) {
    width: 100% !important;
  }
  //   opacity: 0.5;

  &::after {
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    z-index: 1;
  }

  & > * {
    position: relative;
    z-index: 3;
  }

  .content-wrap {
    position: absolute;
    width: 100%;
    height: calc(100% - 6rem);
    bottom: 0rem;
    left: 0rem;
    // background-color: red;
    border-top-right-radius: 3rem;
    border-top-left-radius: 3rem;
    display: flex;
    flex-direction: column;
    animation: moveUpCreateSettingDrop 0.5s ease-in-out backwards;

    .top-wrap {
      border-top-right-radius: 3rem;
      border-top-left-radius: 3rem;
      position: relative;
      display: flex;
      align-items: center;
      padding: 2rem 5rem;

      @media only screen and (max-width: 48em){
      padding: 2rem;
      }

      .top-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .step-box {
          display: flex;
          align-items: center;
          gap: 0.4rem;

          span {
            display: inline-block;
            font-weight: 600;
            font-size: 1.6rem;
          }
        }

        .close-wrap {
          display: flex;
          align-items: center;
          gap: 0.3rem;
          cursor: pointer;

          .img-box {
            width: 2rem;
            height: 2rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .text {
            font-weight: 600;
            font-size: 1.55rem;
          }
        }
      }

      .bar-wrap {
        position: absolute;
        top: 100%;
        left: 0%;
        width: 100%;
        transform: translateY(100%);
        // display: none;
      }
    }

    .bottom-wrap {
      flex: 1;
      // background-color: red;
      display: flex;
      justify-content: center;
      padding: 3rem 0rem;
      overflow-y: scroll;

      .content-wrap-box-form {
        width: 40rem;
        margin: 0rem auto;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        // overflow-x: visible;

        .select-option-global-wrap-box {
          align-self: center;
          display: flex;
          align-items: center;
          padding: 0.8rem 1.6rem;
          border-radius: 4rem;
          grid-gap: 2rem;
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          .select-item {
            position: relative;
            padding: 1rem 2rem;
            display: flex;
            justify-content: center;
            border-radius: 4rem;

            & > * {
              position: relative;
              z-index: 2;
            }

            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              visibility: hidden;
              background-color: #ffffff;
              border-radius: 4rem;
              z-index: 1;
            }
          }

          .select-item-active {
            &::after {
              visibility: visible;
            }

            .text {
              font-weight: 600;
              color: #020202;
            }
          }
        }
        // overflow-x: visible;
        // padding: 0rem .5rem;

        .title-back-text-box {
          margin-top: 2.5rem;
          margin-bottom: 4rem;
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .back-box {
            display: flex;
            align-items: center;
            gap: 0.1rem;
            cursor: pointer;

            .img-box {
              width: 1.7rem;
              height: 1.7rem;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .back {
              font-size: 1.5rem;
            }
          }

          .title {
            font-size: 2.6rem;
            font-weight: 700;
            margin-bottom: -0.5rem;
          }

          .text {
            font-size: 1.5rem;
          }
        }

        .form-box {
          display: flex;
          flex-direction: column;
          flex: 1;
          overflow-y: scroll;
          height: max-content;

          @media only screen and (max-width: 72em) {
            flex: unset;

            .submit-btn-wrap {
              margin-top: 3rem;
            }
          }

          .preview-detal-content-info-box {
            min-height: 35rem;
            border-radius: 1.2rem;
            border-width: 0.15rem;
            display: flex;
            flex-direction: column;
            gap: 3rem;
            padding: 2rem;
            overflow-y: scroll;
            padding-bottom: 7rem;

            &::-webkit-scrollbar {
              display: none;
            }

            & > *:not(:last-child) {
              padding-bottom: 1.5rem;
              border-width: 0.15rem;
            }

            & > *:last-child {
              border: none !important;
            }

            .preview-global-wrap-recipient-box {
              display: flex;
              flex-direction: column;
              border-width: 0.15rem;
              gap: 2.5rem;
              // overflow-y: scroll;

              .title-edit-box {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .title {
                  font-size: 1.55rem;
                  font-weight: 700;
                }

                .edit {
                  width: max-content;
                  cursor: pointer;
                  border-width: 0.2rem !important;
                  // display: none;
                  // border-bottom: .15rem solid red;
                }
              }

              .value-label-box {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .value,
                .label {
                  font-size: 1.3rem;
                }

                .value {
                  text-align: right;
                }
              }
            }
          }

          .form-content {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 2rem;
            overflow-y: scroll;

            .img-business-wrap {
              background-image: url("../../../assets/business-create-bg-img.png");
              height: 14rem;
              width: 100%;
              background-position: center;
              border-radius: 1.2rem;
              background-size: cover;
              background-repeat: no-repeat;
              margin-top: 2rem;
              overflow: hidden;
              background-color: rgba(240, 238, 255, 1);
            }

            .success-create-recipient {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .img-success-box {
                width: 18rem;
                height: 18rem;
                background-image: url("../../../assets/recipient-global-success-bg.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
              }

              .success-title {
                text-align: center;
                font-size: 2.3rem;
                font-weight: 700;
                margin: 1rem 0rem;
              }

              .succes-text {
                text-align: center;
              }
            }

            .small-title {
              font-weight: 600;
              color: rgba(172, 172, 172, 1);
              margin-top: 1.5rem;
              text-transform: uppercase;
            }

            .big-title {
              font-size: 2.1rem;
              font-weight: 700;
              margin-bottom: -0.5rem;
            }

            .small-create-text {
              font-size: 1.5rem;
            }

            .detail-list-box {
              display: flex;
              flex-direction: column;
              gap: 1.5rem;
              margin-top: 2rem;

              .detail-item {
                display: flex;
                align-items: center;
                gap: 1rem;

                .square {
                  span {
                    width: 0.8rem;
                    height: 0.8rem;
                    display: inline-block;
                    background-color: rgba(234, 135, 45, 1);
                  }
                }
              }
            }

            .two-group {
              width: 100%;
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 2rem;
            }
          }

          .submit-btn-wrap {
            width: 100%;
            // display: none;

            & > * {
              width: 100%;
            }
          }
        }
      }

      .new-verification-layout-wrap-index {
        flex: 1;
        display: flex;
        padding-top: 3rem;
        position: relative;

        @media only screen and (max-width: 48em) {
          padding-top: unset;
          flex-direction: column;
          gap: 2rem;
        }

        .side-menu-box {
          flex: 0 0 25rem;
          // background-color: blue;/
          display: flex;
          flex-direction: column;
          padding-left: 2.5rem;
          padding-right: 2.5rem;
          gap: 0.5rem;
          position: relative;
          z-index: 5;

          @media only screen and (max-width: 48em) {
            flex-direction: row;
            flex: unset;
            // background-color: red;
            height: max-content;
            flex-wrap: wrap;
          }

          .item-row {
            padding: 1.5rem;
            width: 100%;
            border-radius: 1.2rem;
            cursor: pointer;

            @media only screen and (max-width: 48em) {
              width: max-content;
            }
            gap: 1rem;

            .name {
              white-space: nowrap;
            }

            .tool-tip-wrap {
              font-weight: 400 !important;
              line-height: 1.9rem;
              text-align: justify;
            }
          }

          .item-row-active {
            cursor: default;
            .name {
              font-weight: 600;
              font-size: 1.35rem;
              white-space: nowrap;
            }
          }
        }

        .content-right-box {
          flex: 1;
          display: flex;
          justify-content: center;
          // background-color: red;
          position: absolute;
          z-index: 3;
          top: 3rem;
          left: 0;
          width: 100%;
          height: 100%;

          @media only screen and (max-width: 48em) {
            position: relative;
            top: unset;
            width: 100%;
            // background-color: green;
          }

          .centered-div {
            width: 40rem;
            max-height: calc(100vh - 22rem);
            // background-color: red;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;

            @media only screen and (max-width: 48em) {
              padding-bottom: 5rem;
            }

            .form-wrap {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 2.5rem;
              flex: 1;
              overflow-y: scroll;
              // width: 46rem;

              .download-pdf-wrap-box {
                // height: 52rem;
                flex: 1;
                width: 100%;
                margin-top: -1.5rem;
                border-radius: 1.2rem;
                display: flex;
                flex-direction: column;
                padding: 0.5rem;
                overflow-y: scroll;

                .download-pdf-wrap {
                  width: 100%;
                  flex: 1;
                  // background-color: red !important;
                  border-radius: 1.2rem;
                  display: flex;
                  flex-direction: column;
                  padding: 1.5rem;
                  gap: 3rem;
                  overflow-y: scroll;

                  .file-view-box {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .view-box {
                      cursor: pointer;
                      transition: transform 0.3s ease-in-out;
                      &:hover {
                        transform: scale(1.1);
                      }
                      .img-box {
                        width: 2.5rem;
                        height: 2.5rem;

                        .img {
                          width: 100%;
                          height: 100%;
                          object-fit: contain;
                        }
                      }
                    }

                    .file-text-box {
                      display: flex;
                      align-items: center;
                      gap: 1rem;
                      cursor: pointer;
                      width: max-content;

                      .text {
                        font-size: 1.55rem;
                        color: rgba(117, 90, 226, 1);
                        border-color: currentColor;
                        border-width: 0.15rem;
                      }

                      .file-icon {
                        .img-box {
                          width: 2.5rem;
                          height: 2.5rem;

                          .img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                          }
                        }
                      }
                    }
                  }

                  .read-text-box {
                    flex: 1;
                    // background-color: red;
                    overflow-y: auto;

                    /* width */
                    &::-webkit-scrollbar {
                      width: 0.5rem;
                    }

                    /* Track */
                    &::-webkit-scrollbar-track {
                      -webkit-box-shadow: none !important;
                      background-color: transparent !important;
                    }

                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                      background: #020202;
                      border-radius: 30rem;
                    }

                    /* Handle on hover */
                    &::-webkit-scrollbar-thumb {
                      background: #020202;
                    }

                    .text {
                      text-align: justify;
                      font-size: 1.65rem;
                      line-height: 2.5rem;
                    }
                  }
                }
              }

              .two-btn-box {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
                grid-gap: 2rem;

                .btn {
                  width: 100%;
                  background-color: #ffffff;
                  color: #020202;
                  border-width: 0.2rem;
                }

                .btn-upload {
                  width: 100%;
                  background-color: #ffffff;
                  color: #020202;
                  border-width: 0.2rem;
                  height: 100%;
                  border-radius: 0.8rem;
                  cursor: pointer;
                  display: grid;
                  place-items: center;
                  transition: transform 0.3s ease-in-out;

                  &:hover {
                    transform: translateY(-0.15rem);
                  }
                }
              }

              .directors-list-box {
                display: flex;
                flex-direction: column;
                border-width: 0.15rem;
                border-radius: 1.2rem;

                & > *:last-child {
                  border: unset !important;
                }

                .director-item-row {
                  padding: 1.7rem 1.5rem;
                  display: flex;
                  align-items: center;
                  border-width: 0.15rem;
                  gap: 1rem;
                  cursor: pointer;

                  &:hover {
                    .arrow-icon {
                      transform: translateX(0.2rem);
                    }
                  }

                  .name {
                    font-weight: 600;
                    font-size: 1.5rem;
                  }

                  .arrow-icon {
                    transition: transform 0.3s ease-in-out;
                    .img-box {
                      width: 1.5rem;
                      height: 1.5rem;

                      .img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                      }
                    }
                  }

                  .badge-box {
                    margin-left: auto;
                    transform: scale(0.9);
                  }

                  .avatar-box {
                    width: 3.5rem;
                    height: 3.5rem;
                    border-radius: 50%;
                    display: grid;
                    place-items: center;
                    background-color: rgba(117, 90, 226, 1) !important;

                    span {
                      font-weight: 600;
                      color: #ffffff;
                      font-size: 1.3rem;
                      text-transform: uppercase;
                      letter-spacing: 0.1rem;
                    }
                  }
                }
              }

              .back-box {
                display: flex;
                align-items: center;
                cursor: pointer;
                gap: 0.5rem;
                width: max-content;

                &:hover {
                  .img-back {
                    transform: translateX(-0.2rem);
                  }
                }

                .text {
                  font-size: 1.5rem;
                  font-weight: 500;
                }

                .img-back {
                  transition: transform 0.3s ease-in-out;
                  .img-box {
                    width: 2rem;
                    height: 2rem;

                    .img {
                      width: 100%;
                      height: 100%;
                      object-fit: contain;
                    }
                  }
                }
              }

              .show-option-box {
                width: 100%;
                display: flex;
                flex-direction: column;

                .btn-wrap {
                  margin-top: 5rem;
                }

                .text-line {
                  display: grid;
                  grid-template-columns: 1fr min-content 1fr;
                  align-items: center;
                  width: 100%;
                  margin: 2.5rem 0rem;

                  .text {
                    color: #676767;
                    background-color: #f7f8f7;
                    font-size: 1.1rem;
                    border: 0.1rem solid #cccccc;
                    padding: 0.5rem 1.1rem;
                    border-radius: 10rem;
                    font-weight: 600;
                    width: max-content;
                    text-align: center;
                    text-transform: uppercase;
                  }

                  span {
                    height: 0.1rem;
                    background-color: rgba(204, 204, 204, 0.5);
                  }
                }

                .select-wrap {
                  display: flex;
                  flex-direction: column;
                  border-radius: 1.2rem;
                  border-width: 0.15rem;

                  .box {
                    padding: 1.5rem;
                    display: flex;
                    align-items: center;
                    gap: 0.7rem;
                    border-width: 0.15rem;

                    .name-des-box {
                      display: flex;
                      flex-direction: column;

                      .name {
                        font-weight: 600;
                        font-size: 1.55rem;
                      }

                      .des {
                        font-size: 1.25rem;
                      }
                    }

                    .check-wrap {
                      margin-left: auto;
                      border: 0.2rem solid rgba(238, 238, 238, 1);
                      border-radius: 50%;
                      width: 2.5rem;
                      height: 2.5rem;
                      position: relative;

                      &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 40%;
                        height: 40%;
                        border-radius: 50%;
                        transform: translate(-50%, -50%);
                        background-color: rgba(255, 255, 255, 1);
                      }
                    }

                    .check-wrap-active {
                      background-color: rgba(117, 90, 226, 1);
                    }
                  }
                }
              }

              .two-input-group {
                grid-gap: 2rem;
                width: 100%;
                display: grid;
                grid-template-columns: repeat(2, 1fr);

                @media only screen and (max-width: 48em) {
                  grid-template-columns: 1fr;
                }
              }

              .profile-address-state-box {
                display: flex;
                flex-direction: column;
                border-width: 0.15rem;
                border-radius: 1.2rem;
                // display: none;
                // gap: ;

                .value-label-box {
                  border-width: 0.15rem;
                  display: flex;
                  align-items: center;

                  & > * {
                    padding: 1.5rem;
                    font-size: 1.25rem;
                    font-weight: 600;
                  }

                  .label {
                    flex: 0 0 30%;
                    align-self: stretch;
                  }

                  .value {
                    flex: 1;
                  }
                }
              }

              .cac-badge-box {
                margin-top: 1.5rem;

                span {
                  font-weight: 600;
                }

                .tool-tip-wrap {
                  font-weight: 400 !important;
                  line-height: 1.9rem;
                  text-align: justify;
                }
              }

              .change-edit-state-box {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                cursor: pointer;
                // margin-top: 1rem;
                // margin-bottom: 1rem;

                .text {
                  font-size: 1.45rem;
                }

                .icon-box {
                  .img-box {
                    width: 2rem;
                    height: 2rem;

                    .img {
                      width: 100%;
                      height: 100%;
                      object-fit: contain;
                    }
                  }
                }
              }

              .form-title-text {
                display: flex;
                flex-direction: column;
                margin-bottom: 3rem;
                gap: 0.3rem;

                .title {
                  font-weight: 600;
                  font-size: 2.2rem;
                  // line-height: 2.3rem;
                }

                .text {
                  font-size: 1.3rem;
                }
              }

              .back-box-title-box {
                display: flex;
                flex-direction: column;
                // gap: 1rem;

                .back-box {
                  display: flex;
                  align-items: center;
                  cursor: pointer;

                  &:hover .icon {
                    animation: moveBackFort 0.3s ease-in-out;
                  }

                  .icon {
                    width: 1.45rem;
                    height: 1.45rem;
                    margin-right: 0.1rem;
                    fill: rgba(80, 95, 109, 0.32);
                    transform: scale(1.1);
                  }

                  .text {
                    font-size: 1.6rem;
                    text-transform: capitalize;
                    font-weight: 600;
                  }
                }

                .form-title-text {
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 3rem;
                  gap: 0.3rem;

                  .title {
                    font-weight: 600;
                    font-size: 2.2rem;
                    // line-height: 2.3rem;
                  }

                  .text {
                    font-size: 1.3rem;
                  }
                }
              }
            }
          }

          .centered-div-two {
            width: 46rem;

            & > * {
              & > * {
                padding-left: 3rem;
                padding-right: 3rem;
              }
            }
          }

          .centered-div-no-gap {
            // gap: 1.5rem;

            .form-wrap {
              gap: 2rem;
              // margin-top: -1rem;
            }
            // display: none;
          }
        }
      }
    }
  }

  .content-wrap-no-animation {
    animation: unset;
  }
}

@keyframes moveUpCreateSettingDrop {
  0% {
    transform: translateY(5%);
    //   opacity: 0.6;
    // visibility: hidden;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
    // visibility: ;
  }
}
