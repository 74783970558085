.dashboard-profile-wrap-box {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  width: 100%;

  @media only screen and (max-width: 52em) {
    padding: 2rem;
  }

  .name-image-top-box-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: 52em) {
      flex-direction: column;
      gap: 2rem;
      align-items: flex-start;
    }

    .edit-logout-btn-box {
      display: flex;
      align-items: center;
      gap: 1.5rem;
    }

    .name-img-box {
      // background-color: red;
      display: flex;
      align-items: center;
      gap: 1.5rem;
      position: relative;

      @media only screen and (max-width: 52em) {
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
      }

      .reset-to-default-box{
        position: absolute;
        top: 95%;
        left: 0%;
        width: 2.7rem;
        height: 2.7rem;
        cursor: pointer;
        


        .img-box{
          width: 100%;
          height: 100%;
          display: grid;
          place-items: center;

          .img{
            width: 60%;
            height: 60%;
            object-fit: contain;
          }
        }
      }

      .change-reset-default-box{
        position: absolute;
        top: 110%;
        left: 0%;
        padding: 1.5rem;
        border-radius: 1.2rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        animation: moveDownSettingDrop 0.5s ease-in-out backwards;

        .change-text{
          border-width: .15rem;
          cursor: pointer;
        }
      }

      .name-email-box {
        .transfer-limit-box {
          display: flex;
          align-items: center;
          margin-top: 2rem;
          gap: 1rem;
          width: 40rem;

          @media only screen and (max-width: 52em) {
            width: 100%;
            flex-direction: column;
            gap: 2rem;
            align-items: flex-start;
          }

          .wrap {
            flex: 1;

            @media only screen and (max-width: 52em) {
              width: 70vw;
              flex: unset;
            }
          }

          .text-bar {
            width: max-content;
            font-weight: 700;
            color: #755ae2;
            font-size: 1.25rem;
            cursor: pointer;
            text-transform: uppercase;
          }
        }
        .name {
          font-weight: 700;
          font-size: 2rem;
          margin-bottom: 0.3rem;
        }

        .busineness-name {
          font-size: 1.35rem;
        }
      }

      .profile-img-input {
        display: none;
      }

      .label-save-box {
        display: flex;
        flex-direction: column;

        .upload-profile-btn {
          margin-top: 1.5rem;
        }

        .save-image-text{
          margin-top: 1.5rem;
          align-self: center;
          width: max-content;
          font-weight: 700;
          color: #755ae2;
          font-size: 1.25rem;
          cursor: pointer;
          text-transform: uppercase;
        }

        .profile-img-label {
          // margin-bottom: 1;
          width: 10rem;
          height: 10rem;
          border-radius: 50%;
          position: relative;
          cursor: pointer;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;

          .img-box {
            position: absolute;
            bottom: 6%;
            right: 0rem;
            transform: translateX(10%);
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            display: grid;
            place-items: center;
            background-color: #ffffff;
            border: 0.3rem solid #fafaff;

            .img {
              width: 40%;
              height: 40%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }

  .underline {
    margin: 2.5rem 0rem;
    width: 100%;
  }

  .action-box {
    width: 100%;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
    grid-row-gap: 4rem;

    @media only screen and (max-width: 52em) {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: flex-start;
    }

    .box {
      display: flex;
      align-items: center;
      //   gap: 1.1rem;
      cursor: pointer;

      .img-wrap {
        // flex: 0 0 7%;

        .img-box {
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 50%;
          display: grid;
          place-items: center;

          .img {
            object-fit: contain;
            width: 70%;
            height: 70%;
          }

          .img-small {
            width: 45%;
            height: 45%;
          }
        }
      }

      .text-box {
        display: flex;
        flex-direction: column;
        gap: 0.15rem;
        font-size: 1.65rem;
        margin-left: 1rem;

        .value {
          font-weight: 700;
        }
      }
    }
  }

  .detail-box-wrap {
    width: 60%;
    // background-color: red;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    grid-row-gap: 3rem;

    @media only screen and (max-width: 52em) {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: flex-start;
    }

    .box {
      display: flex;
      flex-direction: column;
      gap: 0.15rem;
      font-size: 1.65rem;

      .value {
        font-weight: 700;
        text-transform: capitalize;
      }
    }
  }
}

.transfer-limit-modal-wrap .raven-modal-content-wrap {
  width: 55rem;
}

.manage-limit-transaction-modal-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  .progress-label-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    margin-top: 1rem;

    @media only screen and (max-width: 52em) {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: flex-start;
    }

    .label {
      font-weight: 700;
      font-size: 1.3rem;
    }
  }

  .show-all-box {
    padding: 0rem 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 1.2rem;
    max-height: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    width: 100%;

    & > *:last-child {
      border-bottom: unset;
    }

    & > *:not(:last-child) {
      margin-bottom: 2rem;
      padding-bottom: 2rem;
    }

    .row {
      display: flex;
      align-items: flex-start;
      // background-color: #755ae2;

      .left-box {
        flex: 0 0 20%;
        margin-right: 1rem;

        .text {
          font-size: 1.2rem;
          font-weight: 700;
        }
      }

      .right-box {
        flex: 1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        // background-color: red;

        @media only screen and (max-width: 52em) {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          align-items: flex-start;
        }

        .box {
          .label {
            font-size: 1.25rem;
          }

          .value {
            font-weight: 700;
            margin-top: 0.3rem;
          }
        }
      }
    }
  }

  .show-all-box-show {
    padding: 2.5rem;
    max-height: 35rem;
    transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
  }

  .preview-limit-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 1.5rem;
    border-radius: 1.2rem;
    margin: 2rem 0rem;

    @media only screen and (max-width: 52em) {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: flex-start;
    }

    .box {
      .value {
        font-weight: 700;
        margin-top: 0.25rem;

        span {
          font-weight: 400;
        }
      }
    }

    .show-all {
      justify-self: flex-end;
      color: #755ae2;
      font-weight: 700;
      cursor: pointer;
      font-size: 1.15rem;
      text-transform: uppercase;
      align-self: center;
    }
  }

  .progress-container-wrap {
    // padding: .7rem 0rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-radius: 4.8rem;
    background-color: #f7f8f7;
    overflow: hidden;

    & > *:not(:last-child) {
      border-right: 0.2rem solid #dbd6fc;
    }
  }

  .text-label-action-box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .text-box {
      .title {
        font-weight: 700;
        font-size: 1.5rem;
      }

      .text {
        font-size: 1.3rem;
      }
    }

    .upgrade {
      color: #755ae2;
      font-weight: 700;
      cursor: pointer;
      font-size: 1.45rem;
    }
  }

  .underline {
    width: 100%;
    margin: 2rem 0rem 3rem 0rem;
  }
}


@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
    // visibility: hidden;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
    // visibility: ;
  }
}
