.create-new-business-index-wrap {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0rem;
  left: 0rem;
  z-index: 1000;
  background: rgba(2, 2, 2, 0.3);

  @media only screen and (min-width: 110em) {
    width: 100% !important;
  }
  //   opacity: 0.5;

  &::after {
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    z-index: 1;
  }

  & > * {
    position: relative;
    z-index: 3;
  }

  .content-wrap {
    position: absolute;
    width: 100%;
    height: calc(100% - 6rem);
    bottom: 0rem;
    left: 0rem;
    // background-color: red;
    border-top-right-radius: 3rem;
    border-top-left-radius: 3rem;
    display: flex;
    flex-direction: column;
    animation: moveUpCreateSettingDrop 0.5s ease-in-out backwards;

    .top-wrap {
      border-top-right-radius: 3rem;
      border-top-left-radius: 3rem;
      position: relative;
      display: flex;
      align-items: center;
      padding: 2rem 5rem;

      .top-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .step-box {
          display: flex;
          align-items: center;
          gap: 0.4rem;

          span {
            display: inline-block;
            font-weight: 600;
            font-size: 1.6rem;
          }
        }

        .close-wrap {
          display: flex;
          align-items: center;
          gap: 0.3rem;
          cursor: pointer;

          .img-box {
            width: 2rem;
            height: 2rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .text {
            font-weight: 600;
            font-size: 1.55rem;
          }
        }
      }

      .bar-wrap {
        position: absolute;
        top: 100%;
        left: 0%;
        width: 100%;
        transform: translateY(100%);
        // display: none;
      }
    }

    .bottom-wrap {
      flex: 1;
      // background-color: red;
      display: flex;
      justify-content: center;
      padding: 3rem 0rem;
      overflow-y: scroll;

      .content-wrap-box-form {
        width: 40rem;
        margin: 0rem auto;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;

        .select-option-global-wrap-box {
          align-self: center;
          display: flex;
          align-items: center;
          padding: 0.8rem 1.6rem;
          border-radius: 4rem;
          grid-gap: 2rem;
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          .select-item {
            position: relative;
            padding: 1rem 2rem;
            display: flex;
            justify-content: center;
            border-radius: 4rem;

            & > * {
              position: relative;
              z-index: 2;
            }

            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              visibility: hidden;
              background-color: #ffffff;
              border-radius: 4rem;
              z-index: 1;
            }
          }

          .select-item-active {
            &::after {
              visibility: visible;
            }

            .text {
              font-weight: 600;
              color: #020202;
            }
          }
        }
        // overflow-x: visible;
        // padding: 0rem .5rem;

        .title-back-text-box {
          margin-top: 2.5rem;
          margin-bottom: 4rem;
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .back-box {
            display: flex;
            align-items: center;
            gap: 0.1rem;
            cursor: pointer;

            .img-box {
              width: 1.7rem;
              height: 1.7rem;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .back {
              font-size: 1.5rem;
            }
          }

          .title {
            font-size: 2.6rem;
            font-weight: 700;
            margin-bottom: -0.5rem;
          }

          .text {
            font-size: 1.5rem;
          }
        }

        .form-box {
          display: flex;
          flex-direction: column;
          flex: 1;
          overflow-y: scroll;
          height: max-content;

          @media only screen and (max-width: 72em) {
            flex: unset;

            .submit-btn-wrap {
              margin-top: 3rem;
            }
          }

          .preview-detal-content-info-box {
            min-height: 35rem;
            border-radius: 1.2rem;
            border-width: 0.15rem;
            display: flex;
            flex-direction: column;
            gap: 3rem;
            padding: 2rem;
            overflow-y: scroll;
            padding-bottom: 7rem;

            &::-webkit-scrollbar {
              display: none;
            }

            & > *:not(:last-child) {
              padding-bottom: 1.5rem;
              border-width: 0.15rem;
            }

            & > *:last-child {
              border: none !important;
            }

            .preview-global-wrap-recipient-box {
              display: flex;
              flex-direction: column;
              border-width: 0.15rem;
              gap: 2.5rem;
              // overflow-y: scroll;

              .title-edit-box {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .title {
                  font-size: 1.55rem;
                  font-weight: 700;
                }

                .edit {
                  width: max-content;
                  cursor: pointer;
                  border-width: 0.2rem !important;
                  // display: none;
                  // border-bottom: .15rem solid red;
                }
              }

              .value-label-box {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .value,
                .label {
                  font-size: 1.3rem;
                }

                .value {
                  text-align: right;
                }
              }
            }
          }

          .form-content {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 2rem;
            overflow-y: scroll;

            .img-business-wrap {
              background-image: url("../../../assets/business-create-bg-img.png");
              height: 14rem;
              width: 100%;
              background-position: center;
              border-radius: 1.2rem;
              background-size: cover;
              background-repeat: no-repeat;
              margin-top: 2rem;
              overflow: hidden;
              background-color: rgba(240, 238, 255, 1);
            }

            .success-create-recipient {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .img-success-box {
                width: 18rem;
                height: 18rem;
                background-image: url("../../../assets/recipient-global-success-bg.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
              }

              .success-title {
                text-align: center;
                font-size: 2.3rem;
                font-weight: 700;
                margin: 1rem 0rem;
              }

              .succes-text {
                text-align: center;
              }
            }

            .small-title {
              font-weight: 600;
              color: rgba(172, 172, 172, 1);
              margin-top: 1.5rem;
              text-transform: uppercase;
            }

            .big-title {
              font-size: 2.1rem;
              font-weight: 700;
              margin-bottom: -0.5rem;
            }

            .small-create-text {
              font-size: 1.5rem;
            }

            .detail-list-box {
              display: flex;
              flex-direction: column;
              gap: 1.5rem;
              margin-top: 2rem;

              .detail-item {
                display: flex;
                align-items: center;
                gap: 1rem;

                .square {
                  span {
                    width: 0.8rem;
                    height: 0.8rem;
                    display: inline-block;
                    background-color: rgba(234, 135, 45, 1);
                  }
                }
              }
            }

            .two-group {
              width: 100%;
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 2rem;
            }
          }

          .submit-btn-wrap {
            width: 100%;
            // display: none;

            & > * {
              width: 100%;
            }
          }
        }
      }

      .new-verification-layout-wrap-index {
        flex: 1;
        display: flex;
        padding-top: 3rem;

        .side-menu-box {
          flex: 0 0 25rem;
          // background-color: blue;
          display: flex;
          flex-direction: column;
          padding-left: 2.5rem;
          padding-right: 2.5rem;
          gap: 2.5rem;

          .item-row {
            padding: 1.5rem;
            width: 100%;
            border-radius: 1.2rem;
            cursor: pointer;
          }

          .item-row-active {
            cursor: default;
            .name {
              font-weight: 600;
              font-size: 1.35rem;
            }
          }
        }

        .content-right-box {
          flex: 1;
          display: flex;
          justify-content: center;

          .centered-div{
            width: 40rem;
            height: 35rem;
            // background-color: red;
            display: flex;
            flex-direction: column;

            .form-wrap{
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 1.5rem;

              .form-title-text{
                display: flex;
                flex-direction: column;
                margin-bottom: 3rem;
                gap: .3rem;

                .title{
                  font-weight: 600;
                  font-size: 2.2rem;
                  // line-height: 2.3rem;
                }

                .text{
                  font-size: 1.3rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .content-wrap-no-animation{
    animation: unset;
  }
}

@keyframes moveUpCreateSettingDrop {
  0% {
    transform: translateY(5%);
    //   opacity: 0.6;
    // visibility: hidden;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
    // visibility: ;
  }
}
