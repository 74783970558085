.auth-layout-one-wrap {
  width: 100vw;
  height: 100vh;
  display: grid;
  overflow: hidden;
  grid-template-rows: min-content 1fr min-content;
}
@media only screen and (max-width: 42em) {
  .auth-layout-one-wrap {
    height: unset;
    min-height: 100vh;
  }
}
.auth-layout-one-wrap .size-wrap {
  display: flex;
  width: 90%;
  margin: 0rem auto;
}
.auth-layout-one-wrap .header-wrap {
  grid-row: 1/span 1;
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.06), 0px 0px 2px rgba(51, 51, 51, 0.02);
}
.auth-layout-one-wrap .header-wrap .size-wrap {
  padding: 0.5rem 0rem;
  align-items: center;
}
@media only screen and (max-width: 42em) {
  .auth-layout-one-wrap .header-wrap .size-wrap {
    flex-direction: column;
  }
}
.auth-layout-one-wrap .header-wrap .size-wrap .security-wrap {
  background-color: #f9f9f9;
  border-radius: 1.2rem;
  position: relative;
  padding: 2.5rem 1.5rem 1rem 1.5rem;
}
.auth-layout-one-wrap .header-wrap .size-wrap .security-wrap .text {
  color: #676767;
  width: 100%;
}
.auth-layout-one-wrap .header-wrap .size-wrap .security-wrap .text span {
  color: #755ae2;
  cursor: pointer;
  font-weight: 700;
}
.auth-layout-one-wrap .header-wrap .size-wrap .security-wrap .security-box {
  position: absolute;
  top: 0;
  left: 4rem;
  transform: translateY(-50%);
}
.auth-layout-one-wrap .header-wrap .size-wrap .security-wrap .security-box .text {
  font-size: 1.2rem;
  margin-left: 1rem;
  color: #676767;
}
.auth-layout-one-wrap .header-wrap .size-wrap .security-wrap-mobile {
  margin-top: 2.5rem;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 42em) {
  .auth-layout-one-wrap .header-wrap .size-wrap .security-wrap-mobile {
    align-self: flex-end;
    display: none;
  }
}
.auth-layout-one-wrap .header-wrap .size-wrap .security-tip-wrap {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.9rem 1.5rem;
  background-color: rgb(255, 246, 237);
  border-radius: 0.8rem;
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 42em) {
  .auth-layout-one-wrap .header-wrap .size-wrap .security-tip-wrap {
    align-self: flex-end;
    display: none;
  }
}
.auth-layout-one-wrap .header-wrap .size-wrap .security-tip-wrap .text {
  font-size: 1.25rem;
  color: rgb(27, 27, 27);
}
@media only screen and (max-width: 48em) {
  .auth-layout-one-wrap .header-wrap .size-wrap .security-tip-wrap .text {
    white-space: unset;
  }
}
.auth-layout-one-wrap .header-wrap .size-wrap .security-tip-wrap .text span {
  font-weight: 600;
  color: rgb(234, 135, 45);
}
.auth-layout-one-wrap .header-wrap .size-wrap .security-tip-wrap .img-wrap .img-box {
  width: 2rem;
  height: 2rem;
}
.auth-layout-one-wrap .header-wrap .size-wrap .security-tip-wrap .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.auth-layout-one-wrap .header-wrap .size-wrap .logo-wrap {
  margin-right: auto;
}
.auth-layout-one-wrap .header-wrap .size-wrap .logo-wrap .img-box {
  width: 19rem;
  height: 4.8rem;
  margin: 2rem 0rem;
}
.auth-layout-one-wrap .header-wrap .size-wrap .logo-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: cntain;
     object-fit: cntain;
  display: inline-block;
  transform: translateX(-15%);
}
@media only screen and (max-width: 42em) {
  .auth-layout-one-wrap .header-wrap .size-wrap .step-wrap {
    align-self: flex-end;
    display: none;
  }
}
.auth-layout-one-wrap .content-wrap {
  grid-row: 2/span 1;
  padding: 3rem 0rem;
  display: grid;
  place-items: center;
}
@media only screen and (max-width: 42em) {
  .auth-layout-one-wrap .content-wrap {
    align-items: flex-start;
  }
}
.auth-layout-one-wrap .content-wrap .size-wrap {
  height: calc(100vh - 24rem);
  display: flex;
}
@media only screen and (min-width: 110em) {
  .auth-layout-one-wrap .content-wrap .size-wrap {
    width: 150rem;
  }
}
@media only screen and (max-width: 42em) {
  .auth-layout-one-wrap .content-wrap .size-wrap {
    height: unset;
  }
}
.auth-layout-one-wrap .content-wrap .size-wrap .mobile-security-tip {
  display: none;
  width: 100%;
  margin-top: 5rem;
}
@media only screen and (min-width: 48em) {
  .auth-layout-one-wrap .content-wrap .size-wrap .mobile-security-tip {
    display: none;
  }
}
.auth-layout-one-wrap .content-wrap .size-wrap .mobile-security-tip .security-wrap {
  background-color: #f9f9f9;
  border-radius: 1.2rem;
  position: relative;
  padding: 2.5rem 1.5rem 1rem 1.5rem;
}
.auth-layout-one-wrap .content-wrap .size-wrap .mobile-security-tip .security-wrap .text {
  color: #676767;
  width: 100%;
}
.auth-layout-one-wrap .content-wrap .size-wrap .mobile-security-tip .security-wrap .text span {
  color: #755ae2;
  cursor: pointer;
  font-weight: 700;
}
.auth-layout-one-wrap .content-wrap .size-wrap .mobile-security-tip .security-wrap .security-box {
  position: absolute;
  top: 0;
  left: 4rem;
  transform: translateY(-50%);
}
.auth-layout-one-wrap .content-wrap .size-wrap .mobile-security-tip .security-wrap .security-box .text {
  font-size: 1.2rem;
  margin-left: 1rem;
  color: #676767;
}
.auth-layout-one-wrap .content-wrap .size-wrap .left-box {
  flex: 0 0 50%;
  overflow-y: scroll;
  border-right: 0.05rem solid #cccccc;
  display: grid;
  place-items: center;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
@media only screen and (min-width: 110em) {
  .auth-layout-one-wrap .content-wrap .size-wrap .left-box {
    border-right: none;
  }
}
@media only screen and (max-width: 48em) {
  .auth-layout-one-wrap .content-wrap .size-wrap .left-box {
    flex: unset;
    width: 100%;
    border-right: unset;
  }
}
.auth-layout-one-wrap .content-wrap .size-wrap .left-box::-webkit-scrollbar:horizontal {
  display: none;
}
.auth-layout-one-wrap .content-wrap .size-wrap .left-box::-webkit-scrollbar {
  width: 0.3rem;
}
.auth-layout-one-wrap .content-wrap .size-wrap .left-box::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.auth-layout-one-wrap .content-wrap .size-wrap .left-box::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.auth-layout-one-wrap .content-wrap .size-wrap .left-box::-webkit-scrollbar-thumb:hover {
  background: #020202;
}
.auth-layout-one-wrap .content-wrap .size-wrap .left-box .wrap {
  width: 45rem;
  height: 100%;
}
@media only screen and (max-width: 67em) {
  .auth-layout-one-wrap .content-wrap .size-wrap .left-box .wrap {
    width: 80%;
  }
}
@media only screen and (max-width: 54em) {
  .auth-layout-one-wrap .content-wrap .size-wrap .left-box .wrap {
    width: 100%;
  }
}
.auth-layout-one-wrap .content-wrap .size-wrap .right-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 4rem;
  gap: 1.5rem;
}
@media only screen and (max-width: 48em) {
  .auth-layout-one-wrap .content-wrap .size-wrap .right-box {
    display: none;
  }
}
.auth-layout-one-wrap .content-wrap .size-wrap .right-box .security-tip-wrap {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.9rem 1.5rem;
  background-color: rgb(255, 246, 237);
  border-radius: 0.8rem;
  margin-bottom: 1.5rem;
}
.auth-layout-one-wrap .content-wrap .size-wrap .right-box .security-tip-wrap .text {
  font-size: 1.25rem;
  color: rgb(27, 27, 27);
}
@media only screen and (max-width: 48em) {
  .auth-layout-one-wrap .content-wrap .size-wrap .right-box .security-tip-wrap .text {
    white-space: unset;
  }
}
.auth-layout-one-wrap .content-wrap .size-wrap .right-box .security-tip-wrap .text span {
  font-weight: 600;
  color: rgb(234, 135, 45);
}
.auth-layout-one-wrap .content-wrap .size-wrap .right-box .security-tip-wrap .img-wrap .img-box {
  width: 2rem;
  height: 2rem;
}
.auth-layout-one-wrap .content-wrap .size-wrap .right-box .security-tip-wrap .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.auth-layout-one-wrap .content-wrap .size-wrap .right-box .security-wrap {
  background-color: #f9f9f9;
  border-radius: 1.2rem;
  position: relative;
  padding: 2.5rem 1.5rem 1rem 1.5rem;
}
.auth-layout-one-wrap .content-wrap .size-wrap .right-box .security-wrap .text {
  color: #676767;
  width: 100%;
}
.auth-layout-one-wrap .content-wrap .size-wrap .right-box .security-wrap .text span {
  color: #755ae2;
  cursor: pointer;
  font-weight: 700;
}
.auth-layout-one-wrap .content-wrap .size-wrap .right-box .security-wrap .security-box {
  position: absolute;
  top: 0;
  left: 4rem;
  transform: translateY(-50%);
}
.auth-layout-one-wrap .content-wrap .size-wrap .right-box .security-wrap .security-box .text {
  font-size: 1.2rem;
  margin-left: 1rem;
  color: #676767;
}
.auth-layout-one-wrap .content-wrap .size-wrap .right-box .text {
  color: #676767;
  width: 90%;
}
.auth-layout-one-wrap .content-wrap .size-wrap .right-box .text span {
  color: #755ae2;
  cursor: pointer;
  font-weight: 600;
}
.auth-layout-one-wrap .content-wrap .size-wrap .right-box .video-background {
  flex: 1;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}
.auth-layout-one-wrap .content-wrap .size-wrap .right-box .video-background .video-background .video {
  position: absolute;
  max-width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.auth-layout-one-wrap .footer-wrap {
  grid-row: 3/-1;
  padding-bottom: 2rem;
}
.auth-layout-one-wrap .footer-wrap .size-wrap {
  justify-content: flex-end;
}
.auth-layout-one-wrap .footer-wrap .size-wrap .img-box {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}
.auth-layout-one-wrap .footer-wrap .size-wrap .img-box .img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  transform: scale(2);
  transition: all 0.3s;
  cursor: pointer;
}
.auth-layout-one-wrap .footer-wrap .size-wrap .img-box .img:hover {
  transform: scale(2) translateY(-0.1rem);
}

.auth-two-layout-wrap {
  width: 100vw;
  height: 100vh;
  display: grid;
  overflow: hidden;
  grid-template-rows: min-content 1fr min-content;
}
.auth-two-layout-wrap .action-bar-wrapper-box > *:first-child {
  padding-left: 8rem;
  padding-right: 3rem;
}
.auth-two-layout-wrap .size-wrap {
  display: flex;
  width: 90%;
  margin: 0rem auto;
}
@media only screen and (max-width: 42em) {
  .auth-two-layout-wrap .size-wrap .security-wrap-big {
    display: none;
  }
}
@media only screen and (min-width: 42em) {
  .auth-two-layout-wrap .size-wrap .security-wrap-two {
    display: none;
  }
}
.auth-two-layout-wrap .header-wrap {
  grid-row: 1/span 1;
  border-right: unset;
  border-left: unset;
  border-top: unset;
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.06), 0px 0px 2px rgba(51, 51, 51, 0.02);
}
@media only screen and (max-width: 42em) {
  .auth-two-layout-wrap .header-wrap {
    background-color: unset;
  }
}
.auth-two-layout-wrap .header-wrap .size-wrap {
  padding: 1.2rem 0rem;
  align-items: center;
}
.auth-two-layout-wrap .header-wrap .size-wrap .logo-wrap {
  margin-right: auto;
}
@media only screen and (max-width: 42em) {
  .auth-two-layout-wrap .header-wrap .size-wrap .logo-wrap {
    display: none;
  }
}
.auth-two-layout-wrap .header-wrap .size-wrap .logo-wrap .img-box {
  width: 15rem;
  height: 3.5rem;
}
@media only screen and (max-width: 42em) {
  .auth-two-layout-wrap .header-wrap .size-wrap .logo-wrap .img-box {
    width: 15rem;
    height: 3.2rem;
    margin: 2rem 0rem;
  }
}
.auth-two-layout-wrap .header-wrap .size-wrap .logo-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: inline-block;
  transform: translateX(-15%);
}
@media only screen and (min-width: 42em) {
  .auth-two-layout-wrap .header-wrap .size-wrap .logo-wrap-two {
    display: none;
  }
}
@media only screen and (max-width: 42em) {
  .auth-two-layout-wrap .header-wrap .size-wrap .logo-wrap-two {
    display: inline-block;
  }
}
.auth-two-layout-wrap .header-wrap .size-wrap .logo-wrap-two .img-box {
  width: 17rem;
  height: 4rem;
  margin: 0rem 0rem 2rem 0rem;
}
.auth-two-layout-wrap .content-wrap {
  grid-row: 2/span 1;
  padding: 3rem 0rem;
  display: grid;
  place-items: center;
}
@media only screen and (max-width: 42em) {
  .auth-two-layout-wrap .content-wrap {
    align-items: flex-start;
  }
}
.auth-two-layout-wrap .content-wrap .size-wrap {
  height: calc(100vh - 22rem);
  display: flex;
  width: 55rem;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 42em) {
  .auth-two-layout-wrap .content-wrap .size-wrap {
    width: 90%;
  }
}
.auth-two-layout-wrap .content-wrap .size-wrap .content {
  flex: 1;
  overflow-y: scroll;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.auth-two-layout-wrap .content-wrap .size-wrap .content::-webkit-scrollbar:horizontal {
  display: none;
}
.auth-two-layout-wrap .content-wrap .size-wrap .content::-webkit-scrollbar {
  width: 0.3rem;
}
.auth-two-layout-wrap .content-wrap .size-wrap .content::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.auth-two-layout-wrap .content-wrap .size-wrap .content::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.auth-two-layout-wrap .content-wrap .size-wrap .content::-webkit-scrollbar-thumb:hover {
  background: #020202;
}
@media only screen and (max-width: 42em) {
  .auth-two-layout-wrap .content-wrap .size-wrap .content .form-wrap {
    width: 100%;
  }
}
.auth-two-layout-wrap .content-wrap .size-wrap .security-wrap {
  background-color: #f9f9f9;
  border-radius: 1.2rem;
  position: relative;
  padding: 2.5rem 1.5rem 1rem 1.5rem;
}
.auth-two-layout-wrap .content-wrap .size-wrap .security-wrap .text {
  color: #676767;
  width: 100%;
}
.auth-two-layout-wrap .content-wrap .size-wrap .security-wrap .text span {
  color: #755ae2;
  cursor: pointer;
  font-weight: 700;
}
.auth-two-layout-wrap .content-wrap .size-wrap .security-wrap .security-box {
  position: absolute;
  top: 0;
  left: 4rem;
  transform: translateY(-50%);
}
.auth-two-layout-wrap .content-wrap .size-wrap .security-wrap .security-box .text {
  font-size: 1.2rem;
  margin-left: 1rem;
  color: #676767;
}
.auth-two-layout-wrap .footer-wrap {
  grid-row: 3/-1;
  padding-bottom: 2rem;
}
.auth-two-layout-wrap .footer-wrap .size-wrap {
  justify-content: flex-end;
}
.auth-two-layout-wrap .footer-wrap .size-wrap .img-box {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}
.auth-two-layout-wrap .footer-wrap .size-wrap .img-box .img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  transform: scale(2);
  transition: all 0.3s;
  cursor: pointer;
}
.auth-two-layout-wrap .footer-wrap .size-wrap .img-box .img:hover {
  transform: scale(2) translateY(-0.1rem);
}

@media only screen and (max-width: 48em) {
  .modal-parent-wrap .raven-modal-content-wrap {
    width: 90vw !important;
  }
}/*# sourceMappingURL=AuthlayoutOne.css.map */