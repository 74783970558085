.swap-global-account-index-wrap-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  align-items: center;
}
.swap-global-account-index-wrap-box .select-form-wrap-box {
  width: 40rem;
}
@media only screen and (max-width: 48em) {
  .swap-global-account-index-wrap-box .select-form-wrap-box {
    width: 100%;
  }
}
.swap-global-account-index-wrap-box .centerered-div-box {
  width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}
@media only screen and (max-width: 48em) {
  .swap-global-account-index-wrap-box .centerered-div-box {
    width: 95%;
  }
}
.swap-global-account-index-wrap-box .centerered-div-box .two-amount-global-box {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
  z-index: 2;
}
.swap-global-account-index-wrap-box .centerered-div-box .two-amount-global-box .amount-grey-wrap-box {
  padding: 1.5rem 2rem;
  border-radius: 1.2rem;
}
.swap-global-account-index-wrap-box .centerered-div-box .two-amount-global-box .swap-global-icon-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  z-index: 3;
}
.swap-global-account-index-wrap-box .centerered-div-box .two-amount-global-box .swap-global-icon-box .img-box {
  width: 50%;
  height: 50%;
}
.swap-global-account-index-wrap-box .centerered-div-box .two-amount-global-box .swap-global-icon-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.swap-global-account-index-wrap-box .centerered-div-box .swap-fee-exchange-rate-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-left: 5rem;
}
.swap-global-account-index-wrap-box .centerered-div-box .swap-fee-exchange-rate-box > * {
  position: relative;
  z-index: 3;
}
.swap-global-account-index-wrap-box .centerered-div-box .swap-fee-exchange-rate-box::after {
  content: "";
  position: absolute;
  width: 0.15rem;
  height: 97%;
  left: 3.5rem;
  top: 0%;
  background-color: rgb(227, 227, 227);
  z-index: 0;
}
.swap-global-account-index-wrap-box .centerered-div-box .swap-fee-exchange-rate-box .label-value-dot-box {
  display: flex;
  align-items: center;
  position: relative;
}
.swap-global-account-index-wrap-box .centerered-div-box .swap-fee-exchange-rate-box .label-value-dot-box::after {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  left: 0%;
  top: 50%;
  transform: translateY(-50%) translateX(-150%);
  background-color: rgb(227, 227, 227);
  z-index: 2;
  border-radius: 50%;
}
.swap-global-account-index-wrap-box .centerered-div-box .swap-fee-exchange-rate-box .label-value-dot-box::before {
  content: "";
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  left: 0%;
  top: 50%;
  transform: translateY(-50%) translateX(-238%);
  z-index: 3;
  border-radius: 50%;
}
.swap-global-account-index-wrap-box .centerered-div-box .swap-fee-exchange-rate-box .label-value-dot-box .text {
  display: flex;
  align-items: center;
  font-size: 1.55rem;
}
.swap-global-account-index-wrap-box .centerered-div-box .swap-fee-exchange-rate-box .label-value-dot-box .text .value {
  font-weight: 500;
}/*# sourceMappingURL=SwapGlobalAccount.css.map */