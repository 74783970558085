.payroll-top-box-wrap {
  width: 100%;
  position: relative;

  .action-bar-wrapper-box {
    margin-top: -1.5rem;
    margin-bottom: 1.5rem;
  }

  .top-payroll-container-box {
    // min-height: 20rem;
    width: 100%;
    // background-color: red;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    grid-gap: 2.5rem;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 55em) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 1.5rem;
    }

    .box {
      border-radius: 1.2rem;
      padding: 3rem 1rem 1rem 1rem;
      display: flex;
      flex-direction: column;

      .title {
        font-weight: 700;
        padding-left: 2rem;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;

        &:hover .title-total .img-box {
          transform: translateX(0.4rem);
        }

        .title-total {
          display: grid;
          place-items: center;

          .img-box {
            width: 2.5rem;
            height: 2.5rem;
            transition: all 0.3s ease-in-out;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }

      .value-box {
        padding: 1.5rem 1rem 1.5rem 2rem;
        border-radius: 0.8rem;
        margin-top: auto;
        // display: none;

        .value {
          font-weight: 700;
          font-size: 1.75rem;
        }
      }

      .title-disabled{
        cursor: not-allowed;

        &:hover .title-total .img-box {
          transform: none;
        }
      }
    }
  }
}

.payroll-top-box-wrap .top-detail-card-box {
  margin-bottom: 1rem;
  border: 1px solid rgba(208, 213, 221, 0.5);
  box-shadow: -1px 0px 20px rgba(229, 230, 230, 0.35);
  border-radius: 1.6rem;
  padding: 1.5rem 2rem;
  display: flex;
  display: none;
}

.payroll-top-box-wrap .top-detail-card-box .left-box {
  flex: 0 0 70%;
  display: flex;
  flex-direction: column;
  border-right: 0.1rem solid #f0eeff;
}

.payroll-top-box-wrap .top-detail-card-box .left-box .wallet-cash-box {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-top: 1.5rem;
}

.payroll-top-box-wrap
  .top-detail-card-box
  .left-box
  .wallet-cash-box
  > *:not(:last-child) {
  border-right: 0.1rem solid #f0eeff;
  padding-right: 3rem;
}

.payroll-top-box-wrap
  .top-detail-card-box
  .left-box
  .wallet-cash-box
  .box
  .label {
  font-size: 1.1rem !important;
  margin-top: 0.3rem;
}

.payroll-top-box-wrap
  .top-detail-card-box
  .left-box
  .wallet-cash-box
  .box
  .value {
  font-weight: 600;
  font-size: 2rem;
}

.payroll-top-box-wrap .top-detail-card-box .left-box .btn-two-box {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.payroll-top-box-wrap .top-detail-card-box .left-box .btn-two-box .raven-btn {
  min-width: 19rem;
}

.payroll-top-box-wrap .top-detail-card-box .right-box {
  flex: 1;
  display: flex;
  padding: 0rem 2rem;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap {
  flex: 1;
  background-color: #020202;
  border-radius: 0.8rem;
  position: relative;
  background-image: url("../../../assets/gauage-map-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap > * {
  position: relative;
  z-index: 3;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  background-image: url("../../../assets/gauge-map-right.png");
  background-position: top right;
  background-size: contain;
  background-repeat: no-repeat;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap .title {
  font-size: 1.05rem !important;
  color: #ffffff;
  text-transform: uppercase;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap .gauge-box {
  margin-top: 1rem;
  flex: 1;
}

.payroll-top-box-wrap
  .top-detail-card-box
  .right-box
  .gauge-wrap
  .gauge-box
  #gauge-chart2 {
  position: relative;
}

.payroll-top-box-wrap
  .top-detail-card-box
  .right-box
  .gauge-wrap
  .gauge-box
  #gauge-chart2::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -47%);
  width: 53%;
  height: 50%;
  content: "";
  display: none;
  background-image: url("../../../assets/border-gauge.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap .days-count {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 0rem auto;
  margin-top: -1.7rem;
  // display: none;
}

.payroll-top-box-wrap
  .top-detail-card-box
  .right-box
  .gauge-wrap
  .days-count
  span {
  font-size: 0.9rem;
  color: #ffffff;
}

.payroll-top-box-wrap
  .top-detail-card-box
  .right-box
  .gauge-wrap
  .bottom-text-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
}

.payroll-top-box-wrap
  .top-detail-card-box
  .right-box
  .gauge-wrap
  .bottom-text-box
  .days {
  font-size: 1.1rem;
}

.payroll-top-box-wrap
  .top-detail-card-box
  .right-box
  .gauge-wrap
  .bottom-text-box
  .days
  span {
  color: #ea872d;
  font-weight: 600;
  font-size: 2.5rem;
}

.payroll-top-box-wrap
  .top-detail-card-box
  .right-box
  .gauge-wrap
  .bottom-text-box
  .learn {
  font-size: 1.1rem;
  align-self: flex-end;
  color: #dbd6fc;
  cursor: pointer;
}
.payroll-top-box-wrap .top-detail-grid-card-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 2rem;
  margin-bottom: 1.5rem;

  @media only screen and (max-width: 42em) {
    grid-gap: 1rem;
  }
}
.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap {
  border-radius: 1.6rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  @media only screen and (max-width: 42em) {
    border-radius: 0.8rem;
  }
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .img-text-box {
  flex: 1;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  @media only screen and (max-width: 42em) {
    padding: 1rem 0.8rem;
  }
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .img-text-box
  .label {
  margin-top: auto;
  font-size: 1.35rem;
  margin-bottom: 1rem;

  @media only screen and (max-width: 42em) {
    font-size: 1.25rem;
    margin-bottom: unset;
  }
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .img-text-box
  .amount {
  font-weight: 700;
  font-size: 2.2rem;

  @media only screen and (max-width: 42em) {
    font-size: 1.8rem;
  }
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .img-text-box
  .img-box {
  width: 4.2rem;
  height: 4.2rem;
  margin-right: 1rem;
  background-color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.06),
    0px 0px 2px rgba(51, 51, 51, 0.02);

  @media only screen and (max-width: 42em) {
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 1.5rem;
  }
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .img-text-box
  .img-box
  .img {
  width: 70%;
  height: 70%;
  -o-object-fit: contain;
  object-fit: contain;
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .img-text-box
  .img-box
  .img-two {
  width: 50%;
  height: 50%;
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .action-link-box {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #755ae2;
  align-self: flex-start;
  cursor: pointer;
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .action-link-box:hover
  .icon {
  transform: translateX(0.25rem);
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .action-link-box
  .text {
  font-weight: 700;
  font-size: 1.2rem;
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .action-link-box
  .icon {
  color: currentColor;
  transition: all 0.3s;
}
.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .gauge-wrap {
  flex: 1;
  background-color: #020202;
  border-radius: 0.8rem;
  position: relative;
  background-image: url("../../../assets/gauage-map-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .gauge-wrap
  > * {
  position: relative;
  z-index: 3;
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .gauge-wrap::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  background-image: url("../../../assets/gauge-map-right.png");
  background-position: top right;
  background-size: contain;
  background-repeat: no-repeat;
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .gauge-wrap
  .title {
  font-size: 1.05rem !important;
  color: #ffffff;
  text-transform: uppercase;
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .gauge-wrap
  .gauge-box {
  margin-top: 0.5rem;
  flex: 1;
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .gauge-wrap
  .gauge-box
  #gauge-chart2 {
  position: relative;
  height: 100%;
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .gauge-wrap
  .gauge-box
  #gauge-chart2::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -47%);
  width: 53%;
  height: 50%;
  content: "";
  display: none;
  background-image: url("../../../assets/border-gauge.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .gauge-wrap
  .days-count {
  display: flex;
  justify-content: space-between;
  width: 78%;
  margin: 0rem auto;
  margin-top: -1.3rem;
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .gauge-wrap
  .days-count
  span {
  font-size: 0.9rem;
  color: #ffffff;
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .gauge-wrap
  .bottom-text-box {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .gauge-wrap
  .bottom-text-box
  .days {
  font-size: 1.3rem;
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .gauge-wrap
  .bottom-text-box
  .days
  span {
  color: #ea872d;
  font-weight: 800;
  // font-size: 2.5rem;
}
.payroll-top-box-wrap
  .top-detail-grid-card-wrap
  .box-detail-wrap
  .gauge-wrap
  .bottom-text-box
  .learn {
  font-size: 1.1rem;
  align-self: flex-end;
  color: #dbd6fc;
  cursor: pointer;
}

.payroll-index-wrap-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
}

.payroll-index-wrap-box .text-search-box {
  display: flex;
  width: 100%;
  gap: 2rem;
  align-items: center;

  @media only screen and (max-width: 42em) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 1.5rem;

    form {
      width: 100%;

      .form-group {
        width: 100%;
      }
    }

    & > *:last-child {
      grid-row: 1 / span 1;
      grid-column: 1 / -1;
    }
  }
}

.payroll-index-wrap-box .text-search-box .download-btn {
  background-color: #f7f8f7;
  border: 0.1rem solid #cccccc;
  padding: 1.4rem 1.6rem;
  color: #676767;
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: 13rem;

  @media only screen and (max-width: 42em) {
    //  width: 100%;
  }
}

.payroll-index-wrap-box .text-search-box .download-btn .img-box {
  width: 1.5rem;
  height: 1.5rem;
}

.payroll-index-wrap-box .text-search-box .download-btn .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.payroll-index-wrap-box .text-search-box .search-input {
  width: -moz-max-content;
  width: max-content;
  // margin-right: auto;
}

.payroll-index-wrap-box .text-search-box .title {
  // margin-right: auto;
  font-size: 1.3rem;

  @media only screen and (max-width: 42em) {
    display: none;
  }
}

.payroll-index-wrap-box .text-search-box .title {
  font-weight: 700;
  padding-left: 2rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  .title-total {
    padding: 0.4rem 1rem;
    border-radius: 1rem;
    display: grid;
    place-items: center;

    span {
      display: inline-block;
      color: #ffffff !important;
      font-size: 1.1rem;
    }
  }
}

.payroll-index-wrap-box .table-box {
  margin-top: 1.5rem;
  padding-bottom: 10rem;

  @media only screen and (max-width: 42em) {
    width: 100%;
    padding-bottom: 4rem;

    .table-wrap {
      width: 100%;
      overflow-x: scroll;
      padding-bottom: 4rem;

      @media only screen and (max-width: 48em) {
        display: none;
      }

      .table {
        width: 85rem;
      }
    }
    // width: 90%;
    // margin: 2rem auto;
  }

  .status-type-wrap {
    background-color: #e9f5ff;
    border-radius: 3rem;
    width: max-content;
    padding: 0.8rem 1.2rem;
    color: #476885;

    span {
      // font-weight: 700;
      font-size: 1.2rem;
    }
  }

  .status-type-wrap-fail {
    background-color: #fff5f5;
    color: #ff0f00;
  }

  .status-type-wrap-success {
    background-color: #e8fff6;
    color: #1ace37;
  }

  .status-type-wrap-pending {
    background-color: #fff6ed;
    color: #ea872d;
  }

  .payroll-table-action-wrap {
    margin: 0rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: red;
    gap: 1rem;
    width: 100%;

    .img-box {
      width: 3.5rem;
      height: 3.5rem;
      // background-color: #f7f8f7;
      border-radius: 50%;
      display: grid;
      place-items: center;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }

      .img {
        width: 40%;
        height: 40%;
        object-fit: contain;
      }
    }

    & > *:nth-child(2) {
      .img {
        width: 50%;
        height: 50%;
        object-fit: contain;
      }
    }

    .delete-box {
      background-color: #fff5f5;
    }
  }
}

.payroll-index-wrap-box .table-box .table-pagination-box {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  padding: 0rem 3rem;
  margin-bottom: 5rem;
}

.payroll-index-wrap-box .table-box .text-avatar-box {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.payroll-index-wrap-box .table-box .text-avatar-box .avatar-box {
  width: 3rem;
  height: 3rem;
  background-color: #f7f8f7;
  border: 0.1rem solid #cccccc;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.payroll-index-wrap-box .table-box .text-avatar-box .avatar-box span {
  font-weight: 600;
  font-size: 1rem;
  text-transform: capitalize;
}

.process-payroll-modal-wrap .content-wrap {
  margin-bottom: 3rem;
}

.process-payroll-modal-wrap .content-wrap .title {
  font-weight: 700;
  font-size: 1.9rem;
  margin-bottom: 0.5rem;
}

.process-payroll-modal-wrap .content-wrap .text {
  font-size: 1.3rem;
  width: 90%;
}

.deduction-payroll-modal-wrap {
  width: 100%;
  // background-color: red;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;

  .title {
    font-weight: 700;
    font-size: 1.8rem;
  }

  .text {
    font-size: 1.35rem;
  }

  .input-detail-wrap {
    margin-top: 2rem;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .permanent-edit-box {
      display: flex;

      .img-box {
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .text {
        font-size: 1.3rem;

        span {
          color: #755ae2 !important;
          border-bottom: 0.1rem solid currentColor;
          font-weight: 700;
          cursor: pointer;
          margin-left: 0.35rem;
          display: inline-block;
          transition: all 0.3s ease-in-out;

          &:hover {
            transform: scale(1.04);
          }
        }
      }
    }
  }
}

.generate-employee-transaction-detail-box {
  display: flex;
  flex-direction: column;
  // gap: 2rem;
  margin-bottom: 3rem;

  & > *:last-child {
    margin-top: 2rem;
  }

  .title {
    font-size: 1.65rem;
    font-weight: 600;
    // margin-bottom: 1rem;
  }

  .text {
    margin-bottom: 2rem;
    margin-top: 0.35rem;
  }
}

.payroll-deduction-modal-wrap-box {
  // height: 25rem;
  // background-color: red;
  margin-bottom: 1.5rem;

  .payout-date-wrap {
    align-self: flex-start;
    border-radius: 2rem;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    cursor: pointer;

    .input-date {
      width: 0.1rem;
      visibility: hidden;

      input {
        padding: 0rem;
      }
    }

    .text {
      font-size: 1.25rem;
    }

    .img-box {
      width: 1.8rem;
      height: 1.8rem;

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .title-payroll {
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 700;
  }

  .deduct-wrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .toggle-text {
      display: flex;
      align-items: center;
      padding: 1.5rem 0rem;

      & > *:last-child {
        transform: scale(0.8);
      }

      .text-box {
        margin-right: auto;
        display: flex;
        flex-direction: column;
        font-size: 1.3rem;

        .two {
          width: 80%;
          font-size: 1.2rem;
        }

        .text {
          font-weight: 600;
          margin-bottom: 0.3rem;
        }
      }
    }
  }
}

.payroll-select-create-type-wrap-index {
  width: 40rem;
  margin-top: 3rem;
  padding-bottom: 10rem;
  // background-color: red;
  margin-left: auto;
  margin-right: auto;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 52em) {
    width: 90%;
    margin: 2rem auto;
  }

  .select-pos-request-type-box {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 2rem;

    .description-box-wrap {
      padding: 2rem 1.5rem;
      border-radius: 1.2rem;
      display: flex;
      flex-direction: column;
      margin-top: 2rem;

      .icon-subtitle {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        gap: 0.5rem;

        .img-wrap {
          display: grid;
          place-items: center;

          .img-box {
            width: 1.4rem;
            height: 1.4rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .sub-title {
          font-size: 1.25rem;
          text-transform: uppercase;
        }
      }

      .list-box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .list-item {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .black-fade-white-bg {
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 50%;
            display: inline-block;
          }
        }
      }
    }

    .select-title {
      font-size: 1.5rem;
    }

    // .img-select-wrap-box{}

    .img-select-wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2.5rem;
      min-height: 12rem;

      .img-wrap {
        display: flex;
        flex-direction: column;
        padding: 2rem 2rem;
        border-radius: 1rem;
        gap: 1.5rem;
        align-items: flex-start;
        justify-content: space-between;

        .img-box {
          width: 4rem;
          height: 4rem;
          display: flex;
          align-items: center;
          border-radius: 50%;
          display: grid;
          place-items: center;
          margin-bottom: 3rem;

          .img {
            object-fit: contain;
            width: 45%;
            height: 45%;
          }
        }

        .name {
          text-align: center;
          font-size: 1.25rem;
          margin-top: auto;
        }
      }

      .select-option-class-active {
        &::after {
          border-width: 0.25rem;
        }
      }
    }
  }
}
