.reusable-add-and-preview-images-initiator-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  width: 100%;
}
.reusable-add-and-preview-images-initiator-box .label {
  align-self: flex-start;
  margin-bottom: 0.4rem;
  font-size: 1.3rem;
  text-transform: capitalize;
}
.reusable-add-and-preview-images-initiator-box .add-preview-img-box {
  margin-top: 1rem;
  height: 7.5rem;
  overflow-x: scroll;
}
.reusable-add-and-preview-images-initiator-box .add-preview-img-box::-webkit-scrollbar {
  display: none;
}
.reusable-add-and-preview-images-initiator-box .add-preview-img-box .inner-scroll {
  display: flex;
  align-items: stretch;
  gap: 2rem;
  width: -moz-max-content;
  width: max-content;
  overflow-x: scroll;
}
.reusable-add-and-preview-images-initiator-box .add-preview-img-box .inner-scroll .general-img-box {
  position: relative;
}
.reusable-add-and-preview-images-initiator-box .add-preview-img-box .inner-scroll .general-img-box:hover .view-box {
  visibility: visible;
}
.reusable-add-and-preview-images-initiator-box .add-preview-img-box .inner-scroll .general-img-box .view-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2rem;
  height: 2rem;
  z-index: 100;
  transform: translate(-50%, -50%);
  cursor: pointer;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.reusable-add-and-preview-images-initiator-box .add-preview-img-box .inner-scroll .general-img-box .view-box .img-box {
  width: 100%;
  height: 100%;
}
.reusable-add-and-preview-images-initiator-box .add-preview-img-box .inner-scroll .general-img-box .view-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.reusable-add-and-preview-images-initiator-box .add-preview-img-box .box {
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 0.8rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-width: 0.15rem;
  position: relative;
  cursor: pointer;
}
.reusable-add-and-preview-images-initiator-box .add-preview-img-box .box:hover .remove-box {
  visibility: visible;
}
.reusable-add-and-preview-images-initiator-box .add-preview-img-box .box .remove-box {
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  cursor: pointer;
  visibility: hidden;
}
.reusable-add-and-preview-images-initiator-box .add-preview-img-box .add-box {
  display: grid;
  place-items: center;
  cursor: pointer !important;
}
.reusable-add-and-preview-images-initiator-box .add-preview-img-box .add-box .label {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
.reusable-add-and-preview-images-initiator-box .add-preview-img-box .add-box .label .img-box {
  width: 45%;
  height: 45%;
}
.reusable-add-and-preview-images-initiator-box .add-preview-img-box .add-box .label .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.reusable-add-and-preview-images-initiator-box .add-preview-img-box .add-box .input {
  display: none;
}/*# sourceMappingURL=DescriptionPreviewBox.css.map */