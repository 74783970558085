
.payment-request-index-wrap{
    flex: 1;
    display: flex;
    flex-direction: column;

    .mobile-table-box {
      max-height: 65rem;
      overflow-y: scroll;
      padding-bottom: 10rem;
      padding: 0rem 2rem;
      padding-bottom: 10rem;
  
      &::-webkit-scrollbar {
        display: none;
      }
    }
    // background-color: red;

    .new-search-filter-chart-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0rem 3rem;
        margin: 2rem 0rem;
        width: 100%;
    
        @media only screen and (max-width: 42em) {
          padding: 0rem 1.5rem;
        }
    
        .chart-hide-show-box {
          .hide-show-box {
            display: flex;
            align-items: center;
            gap: 0.7rem;
    
            @media only screen and (max-width: 42em) {
              display: none;
            }
    
            & > *:last-child {
              transform: scale(0.8);
            }
    
            span {
              width: 1.8rem;
              height: 1.8rem;
            }
          }
        }
    
        .search-filter-box {
          display: flex;
          align-items: stretch;
          justify-content: space-between;
          gap: 2rem;
          position: relative;
          z-index: 100;
          width: 100%;
    
          @media only screen and (max-width: 42em) {
            gap: 1rem;
            justify-content: space-between;
            width: 100%;
          }
    
          .badge-filter-option-box {
            display: flex;
            align-items: center;
            gap: 2rem;
    
            .option-box {
              display: flex;
              align-items: center;
              gap: 1rem;
              padding: 1rem 1.5rem;
              border-radius: 2rem;
    
              .icon {
                cursor: pointer;
                transform: translateY(0.1rem);
              }
            }
          }
    
          .export-table-content-box {
            display: grid;
            place-items: center;
            padding: 0.5rem 1.5rem;
            border-radius: 0.8rem;
            cursor: pointer;
            // margin-left: -2rem;
    
            &:hover .img-box {
              transform: translateY(0.4rem);
            }
    
            .img-box {
              width: 2.2rem;
              height: 2.2rem;
              transition: all 0.3s;
    
              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
    
          .filter-drop-box {
            position: relative;
    
            .drop-box-wrap {
              position: absolute;
              border-radius: 1.2rem;
              top: 110%;
              right: 0;
              padding: 2rem;
              display: flex;
              flex-direction: column;
              min-width: 30rem;
              gap: 2rem;
              animation: moveDownSettingDrop 0.2s ease-in-out backwards;
            }
    
            .filter-box {
              height: 100%;
              display: flex;
              flex-direction: row;
              gap: 0.8rem;
              align-items: center;
              padding: 1rem 1.5rem;
              border-radius: 0.8rem;
              cursor: pointer;
    
              .line-box {
                margin: auto 0rem;
                display: flex;
                flex-direction: column;
                gap: 0.25rem;
                width: 1.8rem;
                align-items: center;
                // align-self: center;
    
                span {
                  display: inline-block;
                  height: 0.15rem;
                  width: 100%;
                  border-radius: 1rem;
                  background-color: #676767;
                }
    
                & > *:nth-child(2) {
                  width: 70%;
                }
    
                & > *:last-child {
                  width: 40%;
                }
              }
    
              .text {
                font-weight: 700;
              }
            }
          }
        }
      }

      .table-wrap {
        padding-bottom: 8rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        position: relative;
        z-index: 3;
        // background-color: red;
        flex: 1;
        overflow-y: scroll;
    
        &::-webkit-scrollbar {
          display: none;
        }

        .mobile-table-box {
          max-height: 65rem;
          overflow-y: scroll;
          padding-bottom: 10rem;
      
          &::-webkit-scrollbar {
            display: none;
          }
        }
    
        @media only screen and (max-width: 48em) {
          padding-bottom: 3rem;
          width: 90%;
          margin: 3rem auto 0rem auto;
        }
        .table .table-body tr .table-data-action, .table .table-body .table-row .table-data-action, .table tbody tr .table-data-action, .table tbody .table-row .table-data-action{
            justify-content: flex-start;
            // background-color: red;
        }
    
        .wrap-scroll {
          @media only screen and (max-width: 51em) {
            overflow-x: scroll;
            width: 100%;
            padding-bottom: 3rem;
            // display: none;
    
            &::-webkit-scrollbar:vertical {
              display: none;
            }
    
            /* width */
            &::-webkit-scrollbar {
              width: 0.5rem;
              height: 0.4rem;
            }
    
            /* Track */
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: none !important;
              background-color: transparent !important;
            }
    
            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #020202;
              border-radius: 30rem;
            }
    
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #020202;
            }
          }
    
          .table {
            @media only screen and (max-width: 51em) {
              width: 95rem;
            }
          }
    
          @media only screen and (max-width: 48em) {
            display: none;
          }
        }
        @media only screen and (max-width: 51em) {
          // overflow-x: scroll;
          // overflow-y: scroll;
        }
    
        .table-pagination-box {
          display: flex;
          justify-content: center;
          margin-top: 2rem;
          padding: 0rem 3rem;
        }
    
        // thead {
        //   tr {
        //     // & > *:first-child {
        //     //   padding-left: 3rem;
        //     // }
    
        //     // & > *:nth-child(3) {
        //     //   text-align: left;
        //     // }
    
        //     // & > *:last-child {
        //     //   text-align: center;
        //     //   padding-right: 3rem;
        //     //   //   color: #1110101a;
        //     // }
        //   }
        // }
    
        .table-body {
          tr {
            td {
              padding-top: 1.8rem !important;
              padding-bottom: 1.8rem !important;
              font-size: 1.45rem !important;
            }
    
            & > *:first-child {
              padding-left: 3rem;
            //   width: 40%;
              // min-width: unset !important;
              padding-right: 2rem;
              // background-color: red;
            }
    
            & > *:last-child {
              //   text-align: center ;
              padding-right: 3rem;
            }
    
            .action-box {
              justify-content: center;
            }
    
            .status-type-wrap {
              background-color: #e9f5ff;
              border-radius: 3rem;
              width: max-content;
              padding: 0.8rem 1.2rem;
              color: #476885;
    
              span {
                font-weight: 400 !important;
                font-size: 1.2rem;
              }
            }
    
            .status-type-wrap-fail {
              background-color: #fff5f5;
              color: #ff0f00;
            }
    
            .status-type-wrap-success {
              background-color: #e8fff6;
              color: #1ace37;
            }
    
            .status-type-wrap-pending {
              background-color: #fff6ed;
              color: #ea872d;
            }
    
            // td{
            //   position: relative;
            //   width: 100%;
            //   height: 100%;
            // }
          }
        }
    
        // .table-data-action {
        //   gap: unset !important
        //   ;
    
        //   .action-box {
        //     gap: unset !important;
        //   }
        // }
    
        .transaction-action-wrap {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 1rem;
        //   background-color: blue;
          padding: 0.45rem 0rem;
          margin: 0rem auto;
          min-width: 9rem;


    
          .img-box {
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 50%;
            display: grid;
            place-items: center;
            transition: transform 0.3s ease-in-out;
    
            &:hover {
              transform: scale(1.05);
            }
    
            .img {
              width: 60%;
              height: 60%;
              object-fit: contain;
            }
          }

          .img-box-delete{
            background-color: rgba(255, 245, 245, 1);
          }

          .icon-wraps{
            display: flex;
            align-items: center;
            gap: 1.5rem;
          }

          .img-box-main{
            margin: 0 auto;
            // display: none;
          }

          .drop-down-box-payroll {
            position: relative;
            transform: unset !important;

            .drop-down-wrap-payroll {
              position: absolute;
              top: 120%;
              right: 2rem;
              width: max-content;
              background-color: #ffffff;
              transform: scale(0);
              transform-origin: top right;
              transition: transform 0.5s ease-in-out;
              display: flex;
              flex-direction: column;
              padding: 2rem 3rem;
              border-radius: 1.2rem;
              gap: 2.5rem;
              // box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.1);

              .row-child {
                display: flex;
                align-items: center;
                gap: 1rem;

                .img-wrap {
                  .img-box {
                    width: 2rem;
                    height: 2rem;

                    .img {
                      width: 100%;
                      height: 100%;
                      object-fit: contain;
                    }
                  }
                }

                .text {
                  font-size: 1.35rem;
                  font-weight: 500;
                }

                .text-red{
                  color: #FF0000;
                }
              }
            }

            .drop-down-wrap-payroll-up {
              top: unset;
              bottom: 120%;
            }

            .drop-down-wrap-payroll-show {
              transform: scale(1);
            }
          }
    
          
        }
    
        .type-wrap {
          padding: 0.8rem 1.2rem;
          background-color: #f7f8f7;
          border-radius: 3rem;
          width: max-content;
          // margin: 0rem auto;
    
          span {
            // font-weight: 400 !important;
            font-size: 1.2rem;
          }
        }
    
        .type-text-box {
          display: flex;
          align-items: center;
          // background-color: red;
          width: max-content;
    
          .text-box {
            display: flex;
            flex-direction: column;
            gap: 0.7rem;
    
            .text {
              font-size: 1.55rem;
              font-weight: 600;
            }
    
            .text-small {
              font-size: 1.3rem;
              font-weight: 400 !important;
            }
          }
    
          .img-wrap {
            // flex: 0 0 5%;
            display: grid;
            place-items: center;
    
            .img-box-cre-deb {
              width: 3rem;
              height: 3rem;
              margin-right: 2rem;
              background-color: #1ace37 !important;
              border-radius: 50%;
              display: grid;
              place-items: center;
              flex: 0 0 5%;
    
              .img {
                width: 35%;
                height: 35%;
                object-fit: contain;
                filter: brightness(1000%);
              }
            }
    
            .img-box-debit {
              background-color: #ff0f00 !important;
            }
          }
        }
      }
}

.confirm-payment-request-index-wrap{
    width: 40rem;
    // background-color: red;
    margin-top: 4rem;
    padding-bottom: 80rem;
}