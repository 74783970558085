.global-account-terms-and-condition-modal-box-wrap .raven-modal-content-wrap {
  width: 70rem;
}
@media only screen and (max-width: 48em) {
  .global-account-terms-and-condition-modal-box-wrap .raven-modal-content-wrap {
    width: 100%;
  }
}
.global-account-terms-and-condition-modal-box-wrap .raven-modal-content-wrap .global-account-terms-and-condition-modal-box {
  height: calc(100vh - 20rem);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 0rem 2rem;
}
@media only screen and (max-width: 48em) {
  .global-account-terms-and-condition-modal-box-wrap .raven-modal-content-wrap .global-account-terms-and-condition-modal-box {
    padding: 0rem;
  }
}
.global-account-terms-and-condition-modal-box-wrap .raven-modal-content-wrap .global-account-terms-and-condition-modal-box::-webkit-scrollbar {
  display: none;
}
.global-account-terms-and-condition-modal-box-wrap .raven-modal-content-wrap .global-account-terms-and-condition-modal-box .frame-box {
  flex: 1;
  overflow-y: scroll;
}
.global-account-terms-and-condition-modal-box-wrap .raven-modal-content-wrap .global-account-terms-and-condition-modal-box .frame-box::-webkit-scrollbar {
  display: none;
}
.global-account-terms-and-condition-modal-box-wrap .raven-modal-content-wrap .global-account-terms-and-condition-modal-box .btn-aggree-box {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.global-account-terms-and-condition-modal-box-wrap .raven-modal-content-wrap .global-account-terms-and-condition-modal-box .btn-aggree-box .btn {
  width: -moz-max-content;
  width: max-content;
}
.global-account-terms-and-condition-modal-box-wrap .raven-modal-content-wrap .global-account-terms-and-condition-modal-box .btn-aggree-box .agree-checkbox-wrap {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 1.5rem 0rem;
  flex: 1;
}
.global-account-terms-and-condition-modal-box-wrap .raven-modal-content-wrap .global-account-terms-and-condition-modal-box .btn-aggree-box .agree-checkbox-wrap .text {
  font-size: 1.35rem;
  line-height: 2.4rem;
  text-align: left;
}/*# sourceMappingURL=TermsAndConditionModal.css.map */