.blackout-loading-logo-modal {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  z-index: 50000;
  display: grid;
  place-items: center;
  background: rgba(2, 2, 2, .5);
  backdrop-filter: blur(3px);

  .wrap {
    .img-box {
      width: 25rem;
      height: 25rem;

      @media only screen and (max-width: 48em){
        width: 20rem;
        height: 20rem;
      }

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
