// @import '../../breakpoints';

.card-create-reusable-container .dashboard-create-card-wrap-box {
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
  width: 40rem;

  @media only screen and (max-width: 52em) {
    width: 90%;
    margin: 0rem auto;
    padding-bottom: 3rem;
    align-items: center;
    justify-content: center;
  }

  .select-card-request-type-box {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 2rem;

    .description-box-wrap {
      padding: 2rem 1.5rem;
      border-radius: 1.2rem;
      display: flex;
      flex-direction: column;
      margin-top: 2rem;

      .icon-subtitle {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        gap: 1rem;

        .img-wrap {
          display: grid;
          place-items: center;

          .img-box-select {
            width: 2rem;
            height: 2rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .sub-title {
          font-size: 1.35rem;
          text-transform: uppercase;
        }
      }

      .list-box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .list-item {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .black-fade-white-bg {
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 50%;
            display: inline-block;
          }
        }
      }
    }

    .select-title {
      font-size: 1.5rem;
    }

    // .img-select-wrap-box{}

    .img-select-wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2.5rem;

      .img-wrap {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 1.5rem 2rem;
        border-radius: 1rem;
        gap: 1.5rem;

        .security-lock-wrap-box {
          position: absolute;
          top: 1rem;
          right: 0.5rem;
        }

        // background-color: #ff0f00;

        .img-box-card {
          width: 10rem;
          height: 7.5rem;
          display: flex;
          // display: none;
          align-items: center;
          // margin-bottom: 7rem;

          .img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            // display: none;
          }
        }

        .name {
          // text-align: center;
          font-size: 1.25rem;
          margin-top: 3rem;
        }
      }
    }
  }
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box
  .preview-details-common-box {
  margin-top: 3rem;
  // display: none;

  @media only screen and (max-width: 52em) {
    width: 90%;
    margin: 2rem auto 0rem auto;
  }
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box
  .select-wrap-box {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  @media only screen and (max-width: 52em) {
    margin: 2rem auto;
    //  gap: 1rem;
  }
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box
  .select-wrap-box
  .title {
  font-size: 1.45rem;
  text-align: left;
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box
  .select-wrap-box
  .select-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin: 2rem 0rem;
  // display: none;

  @media only screen and (max-width: 52em) {
    grid-gap: 1rem;
  }
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box
  .select-wrap-box
  .select-wrap
  .box {
  display: flex;
  flex-direction: column;
  background-color: rgba(247, 248, 247, 0.5);
  border-radius: 0.8rem;
  cursor: pointer;
  padding: 1.3rem;

  @media only screen and (max-width: 52em) {
    gap: 1rem;
  }
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box
  .select-wrap-box
  .select-wrap
  .box
  .img-box {
  width: 4rem;
  height: 4rem;
  margin-bottom: 3rem;

  @media only screen and (max-width: 52em) {
    width: 3rem;
    height: 3rem;
  }
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box
  .select-wrap-box
  .select-wrap
  .box
  .img-box
  .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box
  .select-wrap-box
  .select-wrap
  .box
  .name {
  font-weight: 600;
  font-size: 1.5rem;
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box
  .select-wrap-box
  .select-wrap
  .box
  .text {
  font-size: 1.25rem;
  margin-top: 0.25rem;
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box
  .select-wrap-box
  .text-line {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  width: 100%;
  margin: 1.5rem 0rem;
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box
  .select-wrap-box
  .text-line
  .text {
  color: #676767;
  background-color: #f7f8f7;
  font-size: 1.1rem;
  border: 0.1rem solid #cccccc;
  padding: 0.5rem 1.1rem;
  border-radius: 10rem;
  font-weight: 600;
  width: -moz-max-content;
  width: max-content;
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box
  .select-wrap-box
  .text-line
  span {
  height: 0.1rem;
  background-color: rgba(204, 204, 204, 0.5);
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box
  .select-wrap-box
  .infor-wrap-box {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 2rem;
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box
  .select-wrap-box
  .infor-wrap-box
  .info-box {
  display: flex;
  align-items: center;
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box
  .select-wrap-box
  .infor-wrap-box
  .info-box
  span {
  width: 0.7rem;
  height: 0.7rem;
  display: inline-block;
  border-radius: 50%;
  margin-right: 1rem;
  background-color: var(--primary-purple);
}
.card-create-reusable-container .dashboard-create-card-wrap-box-reduce {
  width: 40rem;
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box-reduce
  .step-two-wrap {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box-reduce
  .step-two-wrap
  .title {
  font-size: 1.45rem;
  text-align: left;
  margin-bottom: -0.5rem;
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box-reduce
  .step-two-wrap
  .select-theme-box {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  overflow-x: scroll;
  // display: none;
  // padding-bottom: 1.5rem;

  &::-webkit-scrollbar:vertical {
    display: none;
  }

  /* width */
  &::-webkit-scrollbar {
    // width: 0.1rem;
    height: 0.5rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 30rem;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #cccccc;
  }
}
// .card-create-reusable-container
//   .dashboard-create-card-wrap-box-reduce
//   .step-two-wrap
//   .select-theme-box:hover
//   .img-box:hover {
//   // transform: scale(1.03);
// }
// .card-create-reusable-container
//   .dashboard-create-card-wrap-box-reduce
//   .step-two-wrap
//   .select-theme-box:hover
//   .img-box:not(:hover) {
//   transform: scale(0.9);
// }

.card-create-reusable-container
  .dashboard-create-card-wrap-box-reduce
  .step-two-wrap
  .select-theme-box
  .img-box-card .select-option-class-active{
border-width: .2rem !important;
  }

.card-create-reusable-container
  .dashboard-create-card-wrap-box-reduce
  .step-two-wrap
  .select-theme-box
  .img-box-card {
  width: 100%;
  height: 14rem;
  transition: all 0.3s;
  border-radius: 0.8rem;
  // display: none;
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box-reduce
  .step-two-wrap
  .select-theme-box
  .img-box-card
  .img {
  border-radius: 0.8rem;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  // display: none;
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box-reduce
  .step-two-wrap
  .input-form-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 2rem;
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box-reduce
  .step-two-wrap
  .input-form-wrap
  .switch-box {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}
.card-create-reusable-container
  .dashboard-create-card-wrap-box-reduce
  .step-two-wrap
  .input-form-wrap
  .switch-box
  .box {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.dashboard-card-main-wrap-box {
  flex: 1;
  display: flex;
  padding-top: 2rem;

  @media only screen and (max-width: 52em) {
    flex-direction: column;
    flex: unset;
    width: 100%;
  }
}
.dashboard-card-main-wrap-box .left-box {
  flex: 0 0 50%;
  border-right: 0.1rem solid rgba(204, 204, 204, 0.5);
  display: flex;
  flex-direction: column;
  padding: 0rem 3rem;
  overflow-y: scroll;

  @media only screen and (max-width: 52em) {
    flex: unset;
    padding: 0 1.5rem;
    margin-bottom: 1.5rem;
    width: 100%;
  }

  .status-type-wrap-disabled {
    background-color: #f7f8f7;
    // color: #ffffff;
  }
}
.dashboard-card-main-wrap-box .left-box .status-type-wrap {
  background-color: #fff6ed;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #ea872d;
  margin-left: -1rem;
}
.dashboard-card-main-wrap-box .left-box .status-type-wrap span {
  font-size: 1.2rem;
}
.dashboard-card-main-wrap-box .left-box .status-type-wrap-virtual {
  background-color: #f0eeff;
  color: #755ae2;
}

.dashboard-card-main-wrap-box .left-box .table-row-selected {
  background-color: #f7f8f7;
}
.dashboard-card-main-wrap-box .left-box .table-pagination-box {
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding: 0rem 3rem;
}
.dashboard-card-main-wrap-box .right-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0rem 3rem;
  position: relative;

  @media only screen and (max-width: 52em) {
    //  order: -1;
    padding: 0rem 1.5rem;
    flex: unset;
    height: max-content;
  }

  .status-type-wrap {
    background-color: #fff6ed;
    border-radius: 3rem;
    width: -moz-max-content;
    width: max-content;
    padding: 0.8rem 1.2rem;
    color: #ea872d;
    position: absolute;
    top: 1rem;
    right: 4rem;
    // margin-left: -1rem;

    span {
      font-size: 1.2rem;
    }
  }
  .status-type-wrap-virtual {
    background-color: #f0eeff;
    color: #755ae2;
  }

  .status-type-wrap-active {
    background-color: #e8fff6;
    color: #1ace37;
  }

  .status-type-wrap-disabled {
    background-color: #f7f8f7;
    // color: #ffffff;
  }

  .status-type-wrap-disabled-box {
    background-color: rgba(255, 245, 245, 1);

    span {
      color: rgba(255, 15, 0, 1);
    }
  }
}
.dashboard-card-main-wrap-box .right-box .information-transaction-box {
  flex: 1;
  margin-top: 2rem;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  @media only screen and (max-width: 52em) {
    grid-auto-rows: auto;
  }
}
.dashboard-card-main-wrap-box .right-box .information-transaction-box > * {
  grid-row: 1/-1;
  grid-column: 1/-1;

  @media only screen and (max-width: 52em) {
    height: max-content;
    margin-bottom: auto;
    align-self: flex-start;
  }
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .information-wrap {
  display: flex;
  position: relative;
  z-index: 2;
  overflow-y: scroll;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .information-wrap::-webkit-scrollbar:horizontal {
  display: none;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .information-wrap::-webkit-scrollbar {
  width: 0.5rem;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .information-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .information-wrap::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .information-wrap::-webkit-scrollbar-thumb:hover {
  background: #020202;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .information-wrap
  .info-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .information-wrap
  .info-wrap
  > *:not(:last-child) {
  border-bottom: 0.1rem solid rgba(204, 204, 204, 0.5);
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .information-wrap
  .info-wrap
  .box {
  display: flex;
  align-items: center;
  padding-right: 3rem;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .information-wrap
  .info-wrap
  .box
  .label-value-box {
  margin-right: auto;
  flex: 0 0 80%;
  padding: 1.5rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .information-wrap
  .info-wrap
  .box
  .label-value-box
  .label {
  font-size: 1.2rem;
  font-weight: 700;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .information-wrap
  .info-wrap
  .box
  .label-value-box
  .value {
  font-weight: 400;
  font-size: 1.5rem;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .information-wrap
  .info-wrap
  .box
  .copy-box {
  padding: 0.55rem 1.3rem;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.3s;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .information-wrap
  .info-wrap
  .box
  .copy-box:hover {
  transform: scale(1.1);
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .information-wrap
  .info-wrap
  .box
  .copy-box
  span {
  font-size: 1.1rem;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .information-wrap
  .info-wrap
  .box
  .copy-box-active {
  background-color: #020202;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .information-wrap
  .info-wrap
  .box
  .copy-box-active
  span {
  color: #ffffff;
  display: inline-block;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .information-wrap
  .info-wrap
  .box
  .copy-box-active
  span
  .icon {
  margin-right: 0.5rem;
  color: currentColor;
  width: 1rem;
  height: 1rem;
  transform: translateY(0.06rem);
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .transaction-wrap {
  position: relative;
  z-index: 3;
  transform: translateX(100%);
  transition: all 0.5s ease-in-out;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .transaction-wrap
  .table-pagination-box {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  padding: 0rem 3rem;
  margin-bottom: 2rem;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .transaction-wrap::-webkit-scrollbar:horizontal {
  display: none;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .transaction-wrap::-webkit-scrollbar {
  width: 0.5rem;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .transaction-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .transaction-wrap::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .transaction-wrap::-webkit-scrollbar-thumb:hover {
  background: #020202;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .transaction-wrap
  .transact-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .transaction-wrap
  .transact-box
  > *:not(:last-child) {
  border-bottom: 0.1rem solid rgba(204, 204, 204, 0.5);
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .transaction-wrap
  .transact-box
  .child-row {
  display: flex;
  align-items: center;
  padding: 1.5rem 3rem 1.5rem 0rem;
  width: 100%;
  border-width: 0.15rem;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .transaction-wrap
  .transact-box
  .child-row
  .amount {
  font-size: 1.35rem;
  color: #1ace37;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .transaction-wrap
  .transact-box
  .child-row
  .amount-debit {
  color: #ff0f00;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .transaction-wrap
  .transact-box
  .child-row
  .img-text-box {
  display: flex;
  margin-right: auto;
  align-items: center;
  padding-left: 2rem;

  .img-box-cre-deb {
    width: 3rem;
    height: 3rem;
    background-color: #1ace37 !important;
    border-radius: 50%;
    display: grid;
    place-items: center;
    // flex: 0 0 5%;

    .img {
      width: 35%;
      height: 35%;
      object-fit: contain;
      filter: brightness(1000%);
    }
  }

  .img-box-debit {
    background-color: #ff0f00 !important;
  }
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .transaction-wrap
  .transact-box
  .child-row
  .img-text-box
  .text-box
  .date {
  font-size: 1.1rem;
  margin-top: 0.4rem;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .transaction-wrap
  .transact-box
  .child-row
  .img-text-box
  .img-box {
  width: 3rem;
  height: 3rem;
  margin-right: 0.7rem;
  // display: none;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .transaction-wrap
  .transact-box
  .child-row
  .img-text-box
  .img-box
  .img {
  width: 35%;
  height: 35%;
  -o-object-fit: contain;
  object-fit: contain;
}
.dashboard-card-main-wrap-box
  .right-box
  .information-transaction-box
  .transaction-wrap-show {
  transform: translateX(0%);
}
.dashboard-card-main-wrap-box .right-box .select-view-wrap {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  border-bottom: 0.1rem solid #f0eeff;
}
.dashboard-card-main-wrap-box .right-box .select-view-wrap .option {
  padding-bottom: 1.2rem;
  cursor: pointer;
  position: relative;
}
.dashboard-card-main-wrap-box .right-box .select-view-wrap .option > * {
  position: relative;
  z-index: 3;
}
.dashboard-card-main-wrap-box .right-box .select-view-wrap .option::after {
  content: "";
  position: absolute;
  height: 0.35rem;
  width: 100%;
  background-color: #020202;
  bottom: 0;
  left: 0;
  visibility: hidden;
  border-radius: 3rem;
}
.dashboard-card-main-wrap-box .right-box .select-view-wrap .option-active {
  cursor: default;
}
.dashboard-card-main-wrap-box
  .right-box
  .select-view-wrap
  .option-active::after {
  visibility: visible;
}
.dashboard-card-main-wrap-box .right-box .select-view-wrap .option-active span {
  color: #020202;
  font-weight: 500;
}
.dashboard-card-main-wrap-box .right-box .card-action-description-box {
  border-radius: 1.2rem;
  background-image: url("../../../assets/wire-frame-bg-img.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  padding: 2rem 1.2rem;
  display: flex;
  margin-bottom: 3rem;

  @media only screen and (max-width: 52em) {
    //  flex-direction: column;
    margin-bottom: 1.5rem;
  }
}
.dashboard-card-main-wrap-box
  .right-box
  .card-action-description-box
  .left-view-box
  .img-box {
  height: 15rem;
  min-width: 10rem;

  @media only screen and (max-width: 52em) {
    //  flex-direction: column;
    display: none;
  }
}
.dashboard-card-main-wrap-box
  .right-box
  .card-action-description-box
  .left-view-box
  .img-box
  .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: 0.8rem;
}
.dashboard-card-main-wrap-box
  .right-box
  .card-action-description-box
  .right-view-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 3rem;
  justify-content: center;

  @media only screen and (max-width: 52em) {
    margin-left: unset;
    gap: 1.5rem;
  }
}
.dashboard-card-main-wrap-box
  .right-box
  .card-action-description-box
  .right-view-box
  .label {
  font-size: 1.2rem;
}
.dashboard-card-main-wrap-box
  .right-box
  .card-action-description-box
  .right-view-box
  .label
  span {
  font-weight: 700;
  color: #020202;
}
.dashboard-card-main-wrap-box
  .right-box
  .card-action-description-box
  .right-view-box
  .value {
  font-size: 2.2rem;
  font-weight: 600;
  margin: 0.6rem 0rem 1.1rem 0rem;

  @media only screen and (max-width: 52em) {
    margin-bottom: 0rem;
    font-size: 1.65rem;
  }
}
.dashboard-card-main-wrap-box
  .right-box
  .card-action-description-box
  .right-view-box
  .label-two {
  font-size: 1.6rem;

  @media only screen and (max-width: 52em) {
    font-size: 1.3rem;
  }
}
.dashboard-card-main-wrap-box
  .right-box
  .card-action-description-box
  .right-view-box
  .action-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  flex-wrap: wrap;
  width: 100%;
}
.dashboard-card-main-wrap-box
  .right-box
  .card-action-description-box
  .right-view-box
  .action-wrap
  > *:not(:last-child) {
  margin-right: 2rem;
}
.dashboard-card-main-wrap-box
  .right-box
  .card-action-description-box
  .right-view-box
  .action-wrap
  .box {
  display: flex;
  align-items: center;
  gap: 0.9rem;
  cursor: pointer;
}
.dashboard-card-main-wrap-box
  .right-box
  .card-action-description-box
  .right-view-box
  .action-wrap
  .box:hover
  .icon-box {
  transform: translateY(-0.1rem);
}
.dashboard-card-main-wrap-box
  .right-box
  .card-action-description-box
  .right-view-box
  .action-wrap
  .box
  .icon-box {
  width: 3rem;
  height: 3rem;
  background-color: #ffffff;
  display: grid;
  place-items: center;
  border-radius: 50%;
  box-shadow: 0px 4px 8px -2px rgba(51, 51, 51, 0.1),
    0px 2px 4px -2px rgba(51, 51, 51, 0.06);
  transition: all 0.3s;
}
.dashboard-card-main-wrap-box
  .right-box
  .card-action-description-box
  .right-view-box
  .action-wrap
  .box
  .icon-box
  .img-box {
  width: 1.3rem;
  height: 1.3rem;
  display: grid;
  place-items: center;
}
.dashboard-card-main-wrap-box
  .right-box
  .card-action-description-box
  .right-view-box
  .action-wrap
  .box
  .icon-box
  .img-box
  .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.dashboard-card-main-wrap-box
  .right-box
  .card-action-description-box
  .right-view-box
  .action-wrap
  .box
  .icon-box
  .freeze {
  width: 1.7rem;
  height: 1.7rem;
} /*# sourceMappingURL=DashboardCard.css.map */

// @include xs {
//   .dashboard-card-main-wrap-box {
//       flex-wrap: wrap !important;
//       gap: 2rem;
//       display: flex;

//       .left-box {
//           flex: unset !important;
//           width: 100%;
//       }

//       .right-box {
//           padding: 2rem !important;
//       }
//   }
// }

.add-another-user-option-box {
  margin-top: 2rem;
  padding-top: 2rem;

  @media only screen and (max-width: 52em) {
    width: 90%;
    align-self: center;
  }

  .select-user-box {
    margin: 1.5rem 0rem;
    display: flex;
    align-items: center;
    gap: 2rem;

    .box {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      & > *:first-child {
        transform: scale(0.9);
      }

      label {
        cursor: pointer;
      }
    }
  }

  .detail-preview-two {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 1.2rem;
    padding: 1.5rem;
    // display: none;
    position: relative;

    .add-error-box {
      padding: 0.6rem 1.7rem;
      background-color: #ffffff;
      border-radius: 6rem;
      box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
        0px 0px 2px rgba(51, 51, 51, 0.02);
      transition: all 0.3s;
      cursor: pointer;
      position: absolute;
      top: 1rem;
      right: 1rem;

      &:hover {
        transform: scale(1.05);
      }

      span {
        font-size: 1.2rem;
        text-transform: capitalize;
      }
    }

    .title-bvn-box {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      .bvn-nin-box {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 1rem;

        p {
          display: flex;
          align-items: center;
          font-size: 1.15rem;

          span {
            margin-right: 0.3rem;
            display: inline-block;
          }
        }
      }

      .title {
        font-size: 1.5rem;
        font-weight: 700;
      }
    }
  }
}
