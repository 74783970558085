// @import "../breakpoints";

.sign-up-wrap {
  width: 85%;
  display: flex;
  flex-direction: column;
  margin: 0rem auto;
  min-height: 100%;

  @media only screen and (max-width: 42em) {
    width: 97%;
  }

  .text-box {
    margin-bottom: 2rem;
    // display: none;

    @media only screen and (max-width: 42em) {
      margin-bottom: 1rem;
    }

    .small-title {
      color: #676767;
      font-size: 2.4rem;
      font-weight: 300;

      @media only screen and (max-width: 42em) {
        font-size: 2rem;
      }
    }

    .big-title {
      font-size: 2.4rem;
      color: #020202;
      font-weight: 700;

      @media only screen and (max-width: 42em) {
        font-size: 2rem;
      }
    }
  }

  .form {
    // margin: auto 0rem;
    width: 100%;
    flex-direction: column;
    display: flex;
    gap: 2rem;

    //  .show-hide    {
    //       //   color: var(--primary-purple) !important;
    //     }

    .input-group {
      .phone-wrap span,
      .show-hide {
        color: #020202;
      }
    }

    .password-wrapper-check {
      position: relative;
      z-index: 10;

      // .check-drop-box {
      //   position: absolute;
      //   top: 80%;
      //   left: 0%;
      //   background-color: #ffffff;
      //   box-shadow: -1px 0px 20px rgba(229, 230, 230, 0.35);
      //   border: 0.1rem solid rgba(229, 230, 230, 1);
      //   border-radius: 1.2rem;
      //   width: 100%;
      //   min-height: 20rem;
      //   display: flex;
      //   flex-direction: column;
      //   gap: 1.5rem;
      //   // display: none;

      //   .top-box {
      //     padding: 1.5rem 2rem;
      //     border-bottom: 0.1rem solid rgba(229, 230, 230, 1);

      //     .text {
      //       color: #020202;
      //       font-size: 1.2rem;
      //     }
      //   }

      //   .bottom-box {
      //     display: flex;
      //     flex-direction: column;
      //     gap: 1rem;
      //     padding: 0rem 2rem 1.5rem 2rem;

      //     .check-text-box {
      //       display: flex;
      //       align-items: center;
      //       gap: 1rem;

      //       .text {
      //         color: #020202;
      //         font-size: 1.2rem;
      //       }

      //       .check-box {
      //         width: 2.5rem;
      //         height: 2.5rem;
      //         border-radius: 50%;
      //         background-color: rgba(249, 249, 249, 1);
      //         border: 0.1rem solid rgba(229, 230, 230, 1);
      //         display: grid;
      //         place-items: center;
      //         transition: all 0.3s ease-in-out;

      //         .icon {
      //           visibility: hidden;
      //           transition: all 0.3s ease-in-out;
      //           width: 40%;
      //           height: 40%;
      //           color: #ffffff;
      //         }
      //       }

      //       .check-box-active {
      //         background-color: rgba(117, 90, 226, 1);

      //         .icon {
      //           visibility: visible;
      //         }
      //       }
      //     }
      //   }
      // }
    }

    .two-group {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;

      @media only screen and (max-width: 42em) {
        grid-template-columns: 1fr;
        gap: 2rem;
      }

      .form-group {
        width: 100%;

        .form-input {
          width: 100%;
        }
      }
    }

    .register-team-submit-btn {
      margin-top: 3rem;
    }

    .check-text-box {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      gap: 1.5rem;

      .text {
        font-size: 1.35rem;
        color: #676767;

        span {
          color: var(--primary-purple);
          font-weight: 600;
          cursor: pointer;
        }
      }

      .check-box-wrap {
        .input-check:checked ~ .label-check {
          border: none;
        }
        .label-check {
          border: 0.1rem solid #cccccc !important;
        }
      }
    }

    .btn-submit {
      width: 100%;
      margin-top: 2rem;
    }

    .already-text {
      font-size: 1.35rem;
      color: #676767;
      text-align: center;

      span {
        color: var(--primary-purple);
        font-weight: 600;
        cursor: pointer;

        @media only screen and (max-width: 42em) {
          font-size: 1.55rem;
        }
      }
    }
  }
}

.sign-in-wrap {
  display: flex;
  flex-direction: column;
  margin: 0rem auto;
  min-height: 100%;
  // background-color: red;

  .text-box {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 2rem;

    .small-title {
      color: #676767;
      font-size: 1.4rem;
      font-weight: 400;
    }

    .big-title {
      font-size: 2.1rem;
      color: #020202;
      font-weight: 700;
    }
  }

  .form {
    margin: auto 0rem;
    width: 100%;
    flex-direction: column;
    display: flex;
    gap: 2rem;

    @media only screen and (max-width: 42em) {
      margin-top: 3rem;
    }

    .security-tip-wrap {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.9rem 1.5rem;
      background-color: rgba(255, 246, 237, 1);
      border-radius: 0.8rem;
      margin-bottom: 1.5rem;

      // @media only screen and (max-width: 48em){
      //   width: max-content;
      // }

      .text {
        white-space: nowrap;
        font-size: 1.25rem;
        color: rgba(27, 27, 27, 1);

        @media only screen and (max-width: 48em) {
          white-space: unset;
        }

        span {
          font-weight: 600;
          color: rgba(234, 135, 45, 1);
        }
      }

      .img-wrap {
        .img-box {
          width: 2rem;
          height: 2rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    // .btn-submit {
    //   width: 100%;
    //   margin-top: 2rem;
    // }

    .forget-text {
      margin-top: -1.2rem;
      align-self: flex-end;
      cursor: pointer;
      color: var(--primary-purple);
      font-weight: 600;
      font-size: 1.3rem;
    }

    .btn-submit {
      width: 100%;
      margin-top: 2rem;
    }

    .already-text {
      font-size: 1.35rem;
      color: #676767;
      text-align: center;

      span {
        color: var(--primary-purple);
        font-weight: 600;
        cursor: pointer;
      }
    }
  }

  .form-forget-password {
    width: 40rem;

    @media only screen and (max-width: 48em) {
      width: 100%;
    }

    .security-tip-wrap {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.9rem 1.5rem;
      background-color: rgba(255, 246, 237, 1);
      border-radius: 0.8rem;
      margin-bottom: 1.5rem;

      // @media only screen and (max-width: 48em){
      //   width: max-content;
      // }

      .text {
        white-space: nowrap;
        font-size: 1.25rem;
        color: rgba(27, 27, 27, 1);

        @media only screen and (max-width: 48em) {
          white-space: unset;
        }

        span {
          font-weight: 600;
          color: rgba(234, 135, 45, 1);
        }
      }

      .img-wrap {
        .img-box {
          width: 2rem;
          height: 2rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .password-wrapper-check {
      position: relative;
      z-index: 10;

      // .check-drop-box {
      //   position: absolute;
      //   top: 80%;
      //   left: 0%;
      //   background-color: #ffffff;
      //   box-shadow: -1px 0px 20px rgba(229, 230, 230, 0.35);
      //   border: 0.1rem solid rgba(229, 230, 230, 1);
      //   border-radius: 1.2rem;
      //   width: 100%;
      //   min-height: 20rem;
      //   display: flex;
      //   flex-direction: column;
      //   gap: 1.5rem;
      //   // display: none;

      //   .top-box {
      //     padding: 1.5rem 2rem;
      //     border-bottom: 0.1rem solid rgba(229, 230, 230, 1);

      //     .text {
      //       color: #020202;
      //       font-size: 1.2rem;
      //     }
      //   }

      //   .bottom-box {
      //     display: flex;
      //     flex-direction: column;
      //     gap: 1rem;
      //     padding: 0rem 2rem 1.5rem 2rem;

      //     .check-text-box {
      //       display: flex;
      //       align-items: center;
      //       gap: 1rem;

      //       .text {
      //         color: #020202;
      //         font-size: 1.2rem;
      //       }

      //       .check-box {
      //         width: 2.5rem;
      //         height: 2.5rem;
      //         border-radius: 50%;
      //         background-color: rgba(249, 249, 249, 1);
      //         border: 0.1rem solid rgba(229, 230, 230, 1);
      //         display: grid;
      //         place-items: center;
      //         transition: all 0.3s ease-in-out;

      //         .icon {
      //           visibility: hidden;
      //           transition: all 0.3s ease-in-out;
      //           width: 40%;
      //           height: 40%;
      //           color: #ffffff;
      //         }
      //       }

      //       .check-box-active {
      //         background-color: rgba(117, 90, 226, 1);

      //         .icon {
      //           visibility: visible;
      //         }
      //       }
      //     }
      //   }
      // }
    }

    .back-text {
      align-self: flex-start;
      display: flex;
      align-items: center;
      cursor: pointer;
      // background-color: red;

      &:hover .icon {
        animation: moveBackFort 0.3s ease-in-out;
      }

      .icon {
        margin-right: 1rem;
        display: inline-block;
        fill: #676767;
        width: 1.2rem;
        height: 1.2rem;
        // transform: translateY(.1rem);
      }

      .text {
        font-size: 1.35rem;
        color: #676767;
        text-align: center;

        span {
          color: var(--primary-purple);
          font-weight: 600;
          cursor: pointer;
        }
      }
    }

    .img-wrap-forget {
      align-self: center;
      margin-bottom: -1rem;

      @media only screen and (max-width: 42em) {
        align-self: flex-start;
      }

      .img-box {
        width: 8rem;
        height: 8rem;

        @media only screen and (max-width: 42em) {
          width: 6rem;
          height: 6rem;
        }

        .img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }

    .text-box {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 0.5rem;
      margin-bottom: 2rem;

      @media only screen and (max-width: 42em) {
        text-align: left;
      }

      .small-title {
        color: #676767;
        font-size: 1.4rem;
        font-weight: 400;

        // @media only screen and (max-width: 42em) {
        //   font-size: 1.3rem;
        // }
      }

      .big-title {
        font-size: 2.1rem;
        color: #020202;
        font-weight: 700;

        // @media only screen and (max-width: 42em) {
        //   font-size: 1.7rem;
        // }
      }
    }
  }
}

@keyframes moveBackFort {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.2rem);
  }
  100% {
    transform: translateX(0rem);
  }
}

.form-modal-auth {
  // display: none;
  // padding: 0rem 2rem;
  display: flex;
  flex-direction: column;
  // background-color: red;

  .select-wrap-account-auth {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-height: 35rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .select-item {
      display: flex;
      align-items: center;
      padding: 1rem;
      border-radius: 0.8rem;
      border: 0.2rem solid rgba(238, 238, 238, 1);
      cursor: pointer;

      .name {
        color: #020202;
        margin-left: 1.5rem;
        display: flex;
        align-items: center;

        .status-type-wrap {
          background-color: #fff6ed;
          border-radius: 3rem;
          width: -moz-max-content;
          width: max-content;
          padding: 0.8rem 1.2rem;
          color: #ea872d;
          margin-left: 2rem;

          span{
            font-size: 1.2rem;
          }
        }
      }

      .check-wrap {
        margin-left: auto;
        border: 0.2rem solid rgba(238, 238, 238, 1);
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 40%;
          height: 40%;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          background-color: rgba(255, 255, 255, 1);
        }
      }

      .check-wrap-active {
        background-color: rgba(117, 90, 226, 1);
      }

      .img-avatar-wrap {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        overflow: hidden;
        // margin-right: 1.5rem;

        .img-box {
          width: 100%;
          height: 100%;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .avatar-box {
          width: 100%;
          height: 100%;
          background-color: #020202;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-weight: 500;
            font-size: 1.2rem;
            color: #ffffff;
            display: inline-block;
          }
        }

        .avatar-box-two{
          background-color: unset;
        }
      }
    }

    // .select-item-two{
    //   border-color: unset !important;

    //   .name{
    //     color: unset;
    //   }
    // }
  }

  .select-wrap-account-auth-in-app{
    // height: 35rem;
    // background-color: red;
    // margin-bottom: 2.5rem;
    // padding-bottom: 2rem;

    .select-item{
      //  border: ;

      .img-avatar-wrap{
        // background-color: #020202 !important;

        .img-box{
          background-color: #020202 !important;
        }

        .img-box-cur{
          background-color: transparent !important;
        }
  
        .avatar-box{
          background-color: #020202 !important;
        }
      }

   
    }
  }

  .pin-group {
    // width: 85%;
    .pin_field_group {
      width: 85%;
    }

    .black-white-color {
      color: #020202;
    }

    .resend-text {
      color: var(--primary-purple);
      margin-bottom: 0rem;
      align-self: flex-start;
      margin-top: 2rem;
      cursor: pointer;
      font-weight: 600;
      // padding-top: 2rem;
    }
  }

  .resend-text {
    color: var(--primary-purple);
    margin-bottom: 0rem;
    align-self: flex-end;
    cursor: pointer;
    font-weight: 600;
  }

  .text-box {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 0.5rem;
    margin-bottom: 2rem;

    .small-title {
      color: #676767;
      font-size: 1.45rem;
      font-weight: 400;
    }

    .big-title {
      font-size: 2rem;
      color: #020202;
      font-weight: 700;

      // @media only screen and (max-width: 42em) {
      //   font-size: 1.5rem;
      //   display: none;
      // }
    }
  }
}

.form-modal-switch-business-account{
  // padding-bottom: 2rem;
  // background-color: red;

  .agree-checkbox-wrap {
    display: flex;
    align-items: center;
    gap: 1rem;
    //   margin-top: 2rem;
    padding: 1.5rem 0rem;
    flex: 1;

    .text {
      font-size: 1.35rem;
      line-height: 2.4rem;
      text-align: left;
    }
  }

  .form-group .input-group input{
   border: none !important;
  }
}

//responsive styles

//mobile
// @include xs{
//   .content {
//     width: 100vw;
//   }

//   .content-wrap {
//     width: 100vw;
//   }

//   .size-wrap {
//     flex-direction: column;
//     gap: 3.7rem;
//     height: max-content !important;
//   }

//   .left-box {
//     height: max-content;
//   }

//   .wrap {
//     width: 100% !important;
//   }

//   .raven-modal-content-wrap {
//     width: 90vw !important;

//     .content {
//       width: 100%;
//     }

//     .pin_field_group {
//       grid-column-gap: 1rem !important;
//     }
//   }
// }
