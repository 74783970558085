.single-sub-account-detail-wrap {
  flex: 1;
  display: flex;
}
.single-sub-account-detail-wrap > * {
  padding: 2rem 5rem;
  padding-top: 5rem;
}
.single-sub-account-detail-wrap .left-detail-box {
  flex: 0 0 45%;
  display: flex;
  flex-direction: column;
  border-width: 0.2rem;
  gap: 2rem;
  overflow-y: scroll;
}
.single-sub-account-detail-wrap .left-detail-box::-webkit-scrollbar {
  display: none;
}
.single-sub-account-detail-wrap .left-detail-box .card-detail-top-box {
  width: 100%;
  min-height: 19rem;
  border-radius: 1.2rem;
  background-image: url("../../../assets/black-gradient-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1.5rem;
  margin-bottom: 2rem;
}
.single-sub-account-detail-wrap .left-detail-box .card-detail-top-box > * {
  position: relative;
  z-index: 4;
}
.single-sub-account-detail-wrap .left-detail-box .card-detail-top-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  background-image: url("../../../assets/overview-available-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  visibility: hidden;
  z-index: 1;
}
.single-sub-account-detail-wrap .left-detail-box .card-detail-top-box .bank-number-name-box {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: auto;
}
.single-sub-account-detail-wrap .left-detail-box .card-detail-top-box .bank-number-name-box .name-bank-box {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  color: #ffffff;
}
.single-sub-account-detail-wrap .left-detail-box .card-detail-top-box .bank-number-name-box .number {
  font-weight: 600;
  font-size: 2.2rem;
  color: #ffffff;
}
.single-sub-account-detail-wrap .left-detail-box .card-detail-top-box .account-name-box .text {
  font-size: 1.45rem;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.16rem;
}
.single-sub-account-detail-wrap .left-detail-box .role-to-attach-box {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.single-sub-account-detail-wrap .left-detail-box .role-to-attach-box .title {
  font-size: 1.35rem;
}
.single-sub-account-detail-wrap .left-detail-box .role-to-attach-box .role-select-box {
  padding: 2rem;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  gap: 2.5rem;
  flex-wrap: wrap;
  padding-left: unset;
}
.single-sub-account-detail-wrap .left-detail-box .role-to-attach-box .role-select-box .role-item-box {
  min-height: 4rem;
  padding: 1rem 2rem;
  border-radius: 2.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.single-sub-account-detail-wrap .left-detail-box .role-to-attach-box .role-select-box .role-item-box .cancel-icon-box {
  cursor: pointer;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  max-width: 2rem;
}
.single-sub-account-detail-wrap .left-detail-box .role-to-attach-box .role-select-box .role-item-box .cancel-icon-box .img-box {
  width: 2rem;
  height: 2rem;
}
.single-sub-account-detail-wrap .left-detail-box .role-to-attach-box .role-select-box .role-item-box .cancel-icon-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.single-sub-account-detail-wrap .left-detail-box .role-to-attach-box .role-select-box .role-item-box:hover .cancel-icon-box {
  max-width: 2rem;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
  overflow: visible;
}
.single-sub-account-detail-wrap .left-detail-box .role-to-attach-box .role-select-box .role-item-box .role {
  font-size: 1.45rem;
  white-space: nowrap;
}
.single-sub-account-detail-wrap .left-detail-box .role-to-attach-box .role-select-box .purple-item-box {
  background-color: rgb(240, 238, 255);
  cursor: pointer;
}
.single-sub-account-detail-wrap .left-detail-box .role-to-attach-box .role-select-box .purple-item-box .cancel-icon-box {
  max-height: 2rem;
  max-width: 2rem;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
}
.single-sub-account-detail-wrap .left-detail-box .role-to-attach-box .role-select-box .purple-item-box .role {
  color: rgb(117, 90, 226);
}
.single-sub-account-detail-wrap .left-detail-box .three-action-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}
.single-sub-account-detail-wrap .left-detail-box .three-action-box .icon-text-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.2rem 2rem;
  border-width: 0.12rem;
  border-radius: 0.8rem;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.single-sub-account-detail-wrap .left-detail-box .three-action-box .icon-text-box:hover {
  transform: translateY(-0.1rem);
}
.single-sub-account-detail-wrap .left-detail-box .three-action-box .icon-text-box .text {
  font-size: 1.55rem;
}
.single-sub-account-detail-wrap .left-detail-box .three-action-box .icon-text-box .delete {
  color: rgb(255, 15, 0);
}
.single-sub-account-detail-wrap .left-detail-box .three-action-box .icon-text-box .img-wrap .img-box {
  width: 3rem;
  height: 3rem;
}
.single-sub-account-detail-wrap .left-detail-box .three-action-box .icon-text-box .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.single-sub-account-detail-wrap .right-detail-box {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.single-sub-account-detail-wrap .right-detail-box .right-submit-wrap-btn {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  border-width: 0.15rem;
  justify-content: center;
}
.single-sub-account-detail-wrap .right-detail-box .right-submit-wrap-btn .edit-btn {
  width: 70%;
}
.single-sub-account-detail-wrap .right-detail-box .filter-title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;
  border-width: 0.15rem;
  position: relative;
  z-index: 10;
}
.single-sub-account-detail-wrap .right-detail-box .filter-title-box .title {
  font-size: 1.65rem;
  font-weight: 500;
}
.single-sub-account-detail-wrap .right-detail-box .filter-title-box .filter-badge-box {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.single-sub-account-detail-wrap .right-detail-box .filter-title-box .filter-badge-box .filter-drop-box {
  position: relative;
}
.single-sub-account-detail-wrap .right-detail-box .filter-title-box .filter-badge-box .filter-drop-box .drop-box-wrap {
  position: absolute;
  border-radius: 1.2rem;
  top: 110%;
  right: 0;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  min-width: 30rem;
  gap: 2rem;
  animation: moveDownSettingDrop 0.2s ease-in-out backwards;
}
.single-sub-account-detail-wrap .right-detail-box .filter-title-box .filter-badge-box .filter-drop-box .filter-box {
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 0.8rem;
  cursor: pointer;
}
.single-sub-account-detail-wrap .right-detail-box .filter-title-box .filter-badge-box .filter-drop-box .filter-box .line-box {
  margin: auto 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 1.8rem;
  align-items: center;
}
.single-sub-account-detail-wrap .right-detail-box .filter-title-box .filter-badge-box .filter-drop-box .filter-box .line-box span {
  display: inline-block;
  height: 0.15rem;
  width: 100%;
  border-radius: 1rem;
  background-color: #676767;
}
.single-sub-account-detail-wrap .right-detail-box .filter-title-box .filter-badge-box .filter-drop-box .filter-box .line-box > *:nth-child(2) {
  width: 70%;
}
.single-sub-account-detail-wrap .right-detail-box .filter-title-box .filter-badge-box .filter-drop-box .filter-box .line-box > *:last-child {
  width: 40%;
}
.single-sub-account-detail-wrap .right-detail-box .filter-title-box .filter-badge-box .filter-drop-box .filter-box .text {
  font-weight: 700;
}
.single-sub-account-detail-wrap .right-detail-box .filter-title-box .filter-badge-box .badge-filter-option-box {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.single-sub-account-detail-wrap .right-detail-box .filter-title-box .filter-badge-box .badge-filter-option-box .option-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
  border-radius: 2rem;
}
.single-sub-account-detail-wrap .right-detail-box .filter-title-box .filter-badge-box .badge-filter-option-box .option-box .icon {
  cursor: pointer;
  transform: translateY(0.1rem);
}
.single-sub-account-detail-wrap .right-detail-box .auth-level-view-box {
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  padding-bottom: 4rem;
}
.single-sub-account-detail-wrap .right-detail-box .auth-level-view-box::-webkit-scrollbar {
  display: none;
}
.single-sub-account-detail-wrap .right-detail-box .auth-level-view-box > *:not(:last-child) {
  padding-bottom: 2rem;
}
.single-sub-account-detail-wrap .right-detail-box .auth-level-view-box .select-item {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 2rem;
  cursor: pointer;
}
.single-sub-account-detail-wrap .right-detail-box .auth-level-view-box .select-item .avatar-box {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: #020202 !important;
  display: grid;
  place-items: center;
}
.single-sub-account-detail-wrap .right-detail-box .auth-level-view-box .select-item .avatar-box span {
  font-weight: 600;
  color: #ffffff;
  display: inline-block;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.single-sub-account-detail-wrap .right-detail-box .auth-level-view-box .select-item .name-role-box {
  display: flex;
  flex-direction: column;
  align-self: center;
}
.single-sub-account-detail-wrap .right-detail-box .auth-level-view-box .select-item .name-role-box .name {
  font-size: 1.7rem;
  font-weight: 600;
}
.single-sub-account-detail-wrap .right-detail-box .auth-level-view-box .select-item .img-wrap .img-box {
  width: 4rem;
  height: 4rem;
}
.single-sub-account-detail-wrap .right-detail-box .auth-level-view-box .select-item .img-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.single-sub-account-detail-wrap .right-detail-box .auth-level-view-box .select-item .select-option-wrap {
  margin-left: auto;
}
.single-sub-account-detail-wrap .right-detail-box .auth-level-view-box .select-item .select-option-wrap form .form-group .react-select-class > *:nth-child(3) {
  width: -moz-max-content !important;
  width: max-content !important;
  border: unset !important;
  border-radius: 3rem;
  background-color: rgb(247, 247, 247);
  padding: 0.07rem 0.3rem;
}
.single-sub-account-detail-wrap .right-detail-box .auth-level-view-box .select-item .select-option-wrap form .form-group .react-select-class > *:nth-child(4) {
  left: unset;
  right: 0.5rem;
  width: -moz-max-content;
  width: max-content;
}/*# sourceMappingURL=SingleSubAccountWrap.css.map */