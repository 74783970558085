.upload-business-custom-two-type-wrapper {
  position: relative;
}
.upload-business-custom-two-type-wrapper .file-text-box {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.upload-business-custom-two-type-wrapper .file-text-box .text {
  margin-top: 1rem;
}
.upload-business-custom-two-type-wrapper .file-text-box .file-box {
  width: 5rem;
  height: 5rem;
}
.upload-business-custom-two-type-wrapper .file-text-box .file-box .img-box {
  width: 100%;
  height: 100%;
}
.upload-business-custom-two-type-wrapper .file-text-box .file-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: inline-block !important;
}/*# sourceMappingURL=ReuseUpload.css.map */