.exchange-rate-modal-box-index-wrap .today-rate-box-wrap {
  width: 100%;
  min-height: 45rem;
  border: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: calc(100vh - 35rem);
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box {
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
  position: relative;
  z-index: 1;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box::-webkit-scrollbar {
  display: none;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box > *:not(:last-child) {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-width: 0.15rem;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box > *:last-child {
  border: none !important;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .from-box,
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .to-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .from-box .value,
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .to-box .value {
  font-size: 1.6rem;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .from-box .label-box,
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .to-box .label-box {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .from-box .label-box .label-text,
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .to-box .label-box .label-text {
  font-weight: 400;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .from-box .label-box .label-text .img-select .img-box,
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .to-box .label-box .label-text .img-select .img-box {
  width: 1.7rem;
  height: 1.7rem;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .from-box .label-box .label-text .img-select .img-box .img,
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .to-box .label-box .label-text .img-select .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .from-box .label-box .icon-wrap,
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .to-box .label-box .icon-wrap {
  display: grid;
  place-items: center;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .from-box .label-box .icon-wrap .img-box,
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .to-box .label-box .icon-wrap .img-box {
  width: 2rem;
  height: 2rem;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .from-box .label-box .icon-wrap .img-box .img,
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .to-box .label-box .icon-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .middle-content-rate-box .from-to-rate-box-wrap .to-box .value {
  text-align: right;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .bottom-text-box-wrap {
  padding: 1.5rem 1.5rem;
  display: flex;
  align-items: center;
  border-width: 0.15rem;
  margin-top: auto;
  border: none;
  display: none;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .bottom-text-box-wrap .text {
  font-weight: 600;
  font-size: 1.5rem;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .title-select-drop-box {
  padding: 1rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-width: 0.15rem;
  background-color: transparent;
  position: relative;
  z-index: 2;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .title-select-drop-box .title {
  font-size: 1.6rem;
  font-weight: 600;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .title-select-drop-box .select-drop-box-wrapper {
  position: relative;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .title-select-drop-box .select-drop-box-wrapper .select-drop-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
  border-radius: 5rem;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .title-select-drop-box .select-drop-box-wrapper .select-drop-box .value {
  font-weight: 500;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .title-select-drop-box .select-drop-box-wrapper .select-drop-box .img-select .img-box {
  width: 1.7rem;
  height: 1.7rem;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .title-select-drop-box .select-drop-box-wrapper .select-drop-box .img-select .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .title-select-drop-box .select-drop-box-wrapper .select-drop-box .drop-icon .img-box {
  width: 1.7rem;
  height: 1.7rem;
}
.exchange-rate-modal-box-index-wrap .today-rate-box-wrap .title-select-drop-box .select-drop-box-wrapper .select-drop-box .drop-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=ExhangeRateModalBox.css.map */