.title-text-box-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  // background-color: red;

  @media only screen and (max-width: 48em) {
    // flex-wrap: wrap;
    margin-bottom: 1.5rem;
    // flex-direction: column;
    gap: 1rem;

    .btn-box {
      margin-left: auto;
      // margin-top: 1.5rem;
    }
  }

  .all-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-self: stretch;

    @media only screen and (max-width: 48em) {
      // flex: 0 0 40%;
    }

    .terminal-request-stat-box {
      position: relative;
      z-index: 20;

      .message-wrap-box {
        animation: moveDownSettingDrop 0.5s ease-in-out backwards;
        position: absolute;
        // top: 110%;
        z-index: 10;
        // right: 0;
        width: 55rem;
        height: 15rem;
        border-radius: 1.6rem;
        display: flex;
        overflow: hidden;

        @media only screen and (max-width: 48em) {
          min-width: unset;
          width: 70vw;
          flex-direction: column;
          gap: 1rem;
          height: unset;
          padding-bottom: 2rem;
        }

        .left-box {
          flex: 0 0 35%;
          background-position: left;
          background-size: cover;
          background-repeat: no-repeat;

          @media only screen and (max-width: 48em) {
            flex: unset;
            width: 100%;
            min-height: 20rem;
          }
        }

        .right-box {
          flex: 1;
          display: flex;
          flex-direction: column;
          padding: 0rem 2rem;
          justify-content: center;

          @media only screen and (max-width: 48em) {
            flex: unset;
            width: 100%;
          }

          .title {
            font-weight: 700;
            font-size: 1.7rem;
            margin-bottom: 1rem;

            @media only screen and (max-width: 48em) {
              font-size: 2.2rem;
            }
          }

          .text {
            font-size: 1.2rem;

            @media only screen and (max-width: 48em) {
              font-size: 1.5rem;
            }

            span {
              font-weight: 700;
              color: #755ae2 !important;
              cursor: pointer;
            }
          }
        }
      }

      .img-box {
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 50%;
        // margin-right: 1rem;
        cursor: pointer;
        background-color: #ea872d;
        display: grid;
        place-items: center;
        transition: all 0.3s;

        &:hover {
          transform: translateY(-0.1rem);
        }

        .img {
          width: 45%;
          height: 45%;
          object-fit: contain;
        }
      }
    }
  }

  .setting-box-wrap {
    margin-left: 2rem;
    position: relative;

    .drop-wrap {
      position: absolute;
      top: 110%;
      right: 0%;
      width: max-content;
      min-width: 22rem;
      z-index: 20;
      //   box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
      box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05),
        -0.1rem -0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
      font-size: 1.5rem;
      display: flex;
      align-items: stretch;
      gap: 1.5rem;
      border-radius: 2.4rem;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 2.5rem 1.6rem;
      // background-color: #ffffff;
      height: max-content;
      animation: moveDownSettingDrop 0.5s ease-in-out backwards;

      & > *:not(:last-child) {
        // border-bottom: 0.09rem solid #e5e6e6;
        padding-bottom: 1.3rem;
      }

      .text-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;

        .avatar-box {
          width: 2.7rem;
          height: 2.7rem;
          border-radius: 50%;
          display: grid;
          place-items: center;
          background-color: #e8fff6;
          border: 0.08rem solid #9be5c9;
          margin-right: 1rem;

          span {
            font-weight: 800;
            font-size: 1rem;
            display: inline-block;
            letter-spacing: 0.07rem;
            color: #0b8376;
          }
        }

        .img-box {
          width: 2.7rem;
          height: 2.7rem;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 1rem;

          @media only screen and (max-width: 42em) {
            width: 2.3rem;
            height: 2.3rem;
          }

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .icon-box {
          width: 2rem;
          height: 2rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .text {
          font-size: 1.3rem;
          font-weight: 400;
          // color: #020202;
          cursor: pointer;
          text-transform: capitalize;

          @media only screen and (max-width: 42em) {
            font-size: 1.2rem;
          }
        }
      }

      .text-box_two {
        .img-box {
          width: 3.5rem;
          height: 3.5rem;

          @media only screen and (max-width: 42em) {
            width: 3rem;
            height: 3rem;
          }
        }

        .text_two {
          display: flex;
          flex-direction: column;
          gap: 0.1rem;

          & > *:first-child {
            font-weight: 600;
            font-size: 1.5rem;
          }

          span {
            letter-spacing: -0.2%;
            font-size: 1.2rem;
          }
        }
      }

      & > *:first-child {
        .img-box {
          width: 3.5rem;
          height: 3.5rem;
          background-color: #f7f8f7;
          border-radius: 50%;
          display: grid;
          place-items: center;

          .img {
            width: 50%;
            height: 50%;
          }
        }
      }
    }

    .setting-box {
      display: grid;
      place-items: center;
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      background-color: #ffffff;
      border: 0.1rem solid #f8fcff;
      filter: drop-shadow(0px 3px 4px rgba(51, 51, 51, 0.04))
        drop-shadow(0px 0px 2px rgba(51, 51, 51, 0.02));
      cursor: pointer;

      .img-box {
        width: 3rem;
        height: 3rem;
        display: grid;
        place-items: center;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .terminal-transaction-box{
    // display: none;
    .raven-btn{
      border-radius: 6rem;
      display: flex;
      align-items: center;
      background-color: #1b1b1b;

      .img-box{
        width: 2rem;
        height: 2rem;

        .img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }


  .setting-pos-box-wrap {
    margin-left: 2rem;
    position: relative;

    .setting-pos-box {
      width: 35rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      &>*:last-child{
        border: unset;
      }

      .setting-payment-link-row{
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;
        // background-color: red;

        .name-text-box{
          display: flex;
          flex-direction: column;

          .name{
            font-weight: 700;
            font-size: 1.5rem;
          }

          .text{
            font-size: 1.25rem;
          }
        }

        .img-wrap{
          flex: 0 0 8%;

          .img-box{
            width: 4rem;
            height: 4rem;

            .img{
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }

      .child-row-pos {
        padding-bottom: 1.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;

        .name-text-box{
          font-size: 1.25rem;
          .name{
            font-weight: 600;
          }

          .text{
            font-size: 1.15rem;
          }
        }

        .img-wrap-box{
          .img-box{
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            display: grid;
            place-items: center;

            .img{
              width: 50%;
              height: 50%;
            }
          }
        }

      

        .terminal-request-stat-box{
          .img-box {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            // margin-right: 1rem;
            cursor: pointer;
            background-color: #ea872d;
            display: grid;
            place-items: center;
            transition: all 0.3s;
    
            &:hover {
              transform: translateY(-0.1rem);
            }
    
            .img {
              width: 45%;
              height: 45%;
              object-fit: contain;
            }
          }
        }
      }

      .wrap {
        display: flex;
        flex-direction: column;
        border: unset;
        gap: 1.3rem;
        max-height: 40rem;
        overflow-y: scroll;

        &::-webkit-scrollbar:horizontal {
          display: none;
        }

        /* width */
        &::-webkit-scrollbar {
          width: 0.5rem;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: none !important;
          background-color: transparent !important;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #cccccc;
          border-radius: 30rem;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #cccccc;
        }

        & > *:not(:last-child) {
          // border-bottom: 0.09rem solid #e5e6e6;
          padding-bottom: 1.3rem;
        }

        & > *:last-child {
          border: unset;
        }
      }

      .add-new-casher-box {
        //  background-color: red;
        margin-top: 0rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;

        &:hover .img-box {
          transform: scale(1.03);
        }

        .img-box {
          transition: all 0.3s;
          display: grid;
          place-items: center;
          margin-right: 0.5rem;
        }
      }

      .child-row {
        display: flex;
        align-items: center;

        .icon-wrap {
          display: grid;
          place-items: center;

          .img-box {
            width: 4.5rem;
            height: 4.5rem;
            border-radius: 50%;
            overflow: hidden;
            position: relative;

            .img {
              width: 75%;
              height: 75%;
              position: absolute;
              bottom: 0;
              right: 0;
            }
          }
        }

        .text-box {
          display: flex;
          flex-direction: column;
          margin-left: 1.5rem;
          align-items: flex-start;

          .name {
            font-weight: 700;
            margin-bottom: 0.25rem;
            text-transform: capitalize;
          }

          .number {
            font-size: 1.2rem;
            text-align: left;
          }
        }

        .delete-wrap {
          flex: 0 0 10%;
          margin-left: auto;
          display: grid;
          place-items: center;

          .img-box {
            background-color: unset;
            width: 2rem;
            height: 2rem;
            display: grid;
            place-items: center;
            cursor: pointer;

            .img {
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .setting-box {
      display: grid;
      place-items: center;
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      background-color: #ffffff;
      border: 0.1rem solid #f8fcff;
      filter: drop-shadow(0px 3px 4px rgba(51, 51, 51, 0.04))
        drop-shadow(0px 0px 2px rgba(51, 51, 51, 0.02));
      cursor: pointer;

      .img-box {
        width: 3rem;
        height: 3rem;
        display: grid;
        place-items: center;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .back-text-box {
    margin-right: auto;
    display: flex;
    align-items: center;
    flex: 1;

    @media only screen and (max-width: 52em) {
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
      // flex: 0 0 50%;
    }

    .back-box {
      margin-right: 1rem;
      display: grid;
      place-items: center;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      background-color: #020202;
      cursor: pointer;
      transition: transform 0.3s;

      @media only screen and (max-width: 42em) {
        flex: 0 0 9%;
        width: unset;
        height: unset;
      }

      &:hover {
        transform: translateX(-0.1rem);
      }

      .img-box {
        width: 1.2rem;
        height: 1.2rem;
        display: grid;
        place-items: center;
        position: relative;

        @media only screen and (max-width: 42em) {
          width: 3.5rem;
          height: 3.5rem;
        }

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          @media only screen and (max-width: 42em) {
            width: 40%;
            height: 40%;
          }
        }
      }
    }

    .text-box {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;

      @media only screen and (max-width: 42em) {
        gap: 1rem;
      }

      .title {
        font-size: 2rem;
        font-weight: 700;
        letter-spacing: -0.05rem;

        @media only screen and (max-width: 42em) {
          font-size: 2rem;
          line-height: 1.5rem;
        }
      }

      .text {
        font-size: 1.4rem;

        @media only screen and (max-width: 42em) {
          font-size: 1.3rem;
          // width: 90%;
        }
      }
    }
  }

  .toggle-box {
    position: relative;
    // display: none;

    .raven-btn {
      position: relative;
      z-index: 20;
    }

    .select-bulk-type-box {
      z-index: 200;
      position: absolute;
      top: 100%;
      right: 100%;
      box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
        0px 48px 64px -36px rgba(51, 51, 51, 0.08);
      border-radius: 2.4rem;
      padding: 2rem 2.5rem;
      display: flex;
      flex-direction: column;
      width: 30rem;
      // border: none;
      transform: scale(0);
      transform-origin: top right;
      transition: all 0.3s;
      opacity: 1;

      & > *:first-child {
        margin-bottom: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: 0.1rem solid #f7f8f7;
      }

      .child-row {
        display: flex;
        align-items: center;
        width: max-content;
        align-self: stretch;
        // background-color: red;
        width: 100%;

        &:hover .img-box {
          transform: scale(1.03);
        }

        .title {
          font-weight: 700;
          font-size: 1.4rem;
          // color: #020202;
          text-align: left;
        }

        .text {
          font-size: 1.25rem;
          text-align: left;
        }

        .img-box {
          width: 4rem;
          height: 4rem;
          display: grid;
          place-items: center;
          margin-right: 1rem;
          transition: all 0.3s;
          border-radius: 50%;
          background-color: #f7f8f7;

          .img {
            object-fit: contain;
            width: 50%;
            height: 50%;
          }
        }
      }
    }

    .select-bulk-type-box-show {
      transform: scale(1);
    }
  }

  .next-box {
    margin-left: 2rem;
    display: grid;
    place-items: center;
    border-radius: 50%;
    background-color: #020202;
    width: 3.5rem;
    height: 3.5rem;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      transform: translateX(0.15rem);
    }

    .img-box {
      width: 1.3rem;
      height: 1.3rem;

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  // .add-box-rearrange{
  //   @media only screen and (max-width: 42em) {
  //     flex: 1 ;
  //     margin-left: auto;
  //     width: 100%;
  //     // display: none;
  //   }
  // }
}

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
    // visibility: hidden;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
    // visibility: ;
  }
}
