// @import "../../breakpoints";

.send-money-wrap-box-bank {
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 42em) {
    width: 90%;
    margin: 0rem auto;
    // background-color: red;
  }

  .form {
    width: 40rem;
    display: flex;
    flex-direction: column;
    padding-top: 2.5rem;
    gap: 2rem;
    padding-bottom: 10rem;

    @media only screen and (max-width: 42em) {
      width: 100%;
      margin: 0rem auto;
      // background-color: red;
    }

    .verify-name-box {
      display: flex;
      align-items: center;
      align-self: flex-start;
      margin-top: -1rem;
      max-height: 0;
      overflow-x: hidden;
      overflow-y: hidden;
      transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
      -webkit-transition: max-height 0.3s;
      -moz-transition: max-height 0.3s;
      width: 100%;

      & > *:first-child .label-check {
        border-radius: 50%;
        transform: scale(0.8);
      }
      .text {
        // color: var(--primary-black);
        font-weight: 600;
        font-size: 1.3rem;
        margin-left: 0.5rem;
      }
    }

    .verify-name-box-show {
      max-height: 5rem;
      transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
    }

    .select-react {
      position: relative;
      z-index: 10;

      // & > *:nth-child(3) {
      //   & > *:nth-child(1) {
      //     & > *:nth-child(1) {
      //       font-size: 1.4rem;
      //       color: #020202 ;
      //     }
      //   }
      // }
    }

    .react-select-class-selected {
      & > *:nth-child(3) {
        & > *:nth-child(1) {
          & > *:nth-child(1) {
            font-size: 1.4rem;
            color: #020202;
          }
        }
      }
    }

    .label-span-amount {
      font-weight: 600 !important;
      opacity: 1 !important;
    }

    .text-line {
      display: grid;
      grid-template-columns: 1fr min-content 1fr;
      align-items: center;
      width: 100%;

      .text {
        color: #676767;
        background-color: #f7f8f7;
        font-size: 1.1rem;
        border: 0.1rem solid #cccccc;
        padding: 0.5rem 1.1rem;
        border-radius: 10rem;
        font-weight: 600;
        width: max-content;
        text-align: center;
        text-transform: uppercase;
      }

      span {
        height: 0.1rem;
        background-color: rgba(204, 204, 204, 0.5);
      }
    }

    .add-another-box {
      display: flex;
      align-items: center;
      margin-top: -1rem;
      align-self: flex-start;
      cursor: default;
      opacity: 0.5;

      &:hover .img-box {
        transform: scale(1.2);
      }

      .text {
        color: var(--primary-purple);
        font-weight: 600;
        font-size: 1.3rem;
      }

      .img-box {
        transition: all 0.3s ease-in-out;
        width: 2rem;
        height: 2rem;
        display: grid;
        place-items: center;
        margin-right: 0.3rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .add-another-box-active {
      opacity: 1;
      cursor: pointer;
    }

    .recepient-box {
      // display: none;
      max-height: 0;
      overflow-x: hidden;
      overflow-y: hidden;
      transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      width: 100%;
      position: relative;
      z-index: 200;

      .text-line {
        display: grid;
        grid-template-columns: 1fr min-content 1fr;
        align-items: center;
        width: 100%;

        .text {
          color: #676767;
          background-color: #f7f8f7;
          font-size: 1.1rem;
          border: 0.1rem solid #cccccc;
          padding: 0.5rem 1.1rem;
          border-radius: 10rem;
          font-weight: 600;
          text-transform: uppercase;
        }

        span {
          height: 0.1rem;
          background-color: rgba(204, 204, 204, 0.5);
        }
      }

      .view-total-box {
        display: flex;
        align-items: center;

        .view-wrap {
          margin-left: auto;
          position: relative;
          //   color: #ff0f00;

          .text {
            font-size: 1.3rem;
            // color: currentColor;
          }

          .cancel-box {
            margin-left: auto;
            width: 2.2rem;
            height: 2.2rem;
            border-radius: 50%;
            background-color: #ffdfdd;
            margin-left: auto;
            display: grid;
            place-items: center;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
              transform: scale(1.1);
            }

            .icon {
              fill: #ff0f00;
              width: 1.1rem;
              height: 1.1rem;
            }
          }

          .view-all-box {
            position: absolute;
            bottom: 105%;
            right: 0;
            width: 35rem;
            height: 30rem;
            padding: 2rem;
            z-index: 4;
            overflow-y: scroll;
            box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
              0px 48px 64px -36px rgba(51, 51, 51, 0.08);
            border-radius: 2rem;
            transform: scale(0);
            transition: all 0.3s ease-in-out;
            transform-origin: bottom right;
            border-top-right-radius: unset;
            border-bottom-right-radius: unset;

            .wrap {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 2rem;
              // padding-bottom: 5rem;

              .child-wrap {
                display: flex;
                align-items: center;
                padding: 1rem 1.3rem;
                border: 0.1rem solid #cccccc !important;
                border-radius: 0.8rem;

                .text-box {
                  .name {
                    font-weight: 700;
                    font-size: 1.3rem;
                    text-transform: capitalize;
                  }

                  .text {
                    font-size: 1.1rem;
                    font-weight: 400;
                  }
                }

                .img-box {
                  width: 3rem;
                  height: 3rem;
                  margin-right: 1rem;

                  .img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }

                .cancel-box {
                  margin-left: auto;
                  width: 2.2rem;
                  height: 2.2rem;
                  border-radius: 50%;
                  background-color: #ffdfdd;
                  margin-left: auto;
                  display: grid;
                  place-items: center;
                  cursor: pointer;
                  transition: all 0.3s;

                  &:hover {
                    transform: scale(1.1);
                  }

                  .icon {
                    fill: #ff0f00;
                    width: 1.1rem;
                    height: 1.1rem;
                  }
                }
              }
            }

            &::-webkit-scrollbar:horizontal {
              display: none;
            }

            /* width */
            &::-webkit-scrollbar {
              width: 0.3rem;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: none !important;
              background-color: transparent !important;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #020202;
              border-radius: 30rem;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #020202;
            }
          }

          .view-all-box-show {
            transform: scale(1);
          }

          .text {
            cursor: pointer;
            font-weight: 600;
            color: var(--primary-purple);
          }
        }
      }

      .preview-box {
        margin-top: 1rem;
        max-height: 15rem;
        padding: 1rem 0rem;
        //   background-color: red;
        // margin-bottom: 10rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1.5rem;

        overflow-x: scroll;

        &::-webkit-scrollbar{
          display: none;
        }

        .box {
          background-color: #f7f8f7;
          padding: 1rem;
          cursor: pointer;
          position: relative;

          .img-box {
            // display: none;
            width: 4.5rem;
            height: 4.5rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .cancel-box {
            width: 2.2rem;
            height: 2.2rem;
            border-radius: 50%;
            background-color: #ffdfdd;
            margin-left: auto;
            display: grid;
            place-items: center;
            cursor: pointer;
            transition: all 0.3s;
            position: absolute;
            top: 1rem;
            right: 1rem;

            &:hover {
              transform: scale(1.1);
            }

            .icon {
              fill: #ff0f00;
              width: 1.1rem;
              height: 1.1rem;
            }
          }

          .name {
            margin-top: 2rem;
            font-size: 1.45rem;
            text-transform: capitalize;
          }
        }
      }
    }

    .recepient-box-show {
      overflow-x: unset;
      overflow-y: unset;
      max-height: 30rem;
      transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
    }

    .beneficiary-box {
      width: 100%;

      .text-line {
        display: grid;
        grid-template-columns: 1fr min-content 1fr;
        align-items: center;
        width: 100%;
        margin: 1rem 0rem;

        .text {
          color: #676767;
          background-color: #f7f8f7;
          font-size: 1.1rem;
          border: 0.1rem solid #cccccc;
          padding: 0.5rem 1.1rem;
          border-radius: 10rem;
          font-weight: 600;
          text-transform: uppercase;
        }

        span {
          height: 0.1rem;
          background-color: rgba(204, 204, 204, 0.5);
        }
      }

      .view-total-box {
        display: flex;
        align-items: center;

        .select-bene-wrap {
          position: relative;
          z-index: 15;
          .react-select-class {
            & > *:nth-child(3) {
              background-color: #fafaff;
              border: 0.15rem solid #dbd6fc;
              color: #755ae2;
              font-weight: 600;
              padding: 0.1rem;
              max-width: 17rem;

              & > *:nth-child(1) {
                & > *:nth-child(1) {
                  font-size: 1.2rem;
                  color: #755ae2;
                }
              }

              & > *:nth-child(2) {
                // display: none;
                & > *:nth-child(2) {
                  padding: unset;
                  padding-right: 0.5rem;
                  svg {
                    fill: #755ae2;
                    transform: scale(0.8);
                  }
                }
                span {
                  display: none;
                }
              }
            }

            & > *:nth-child(4) {
              width: max-content;
              min-width: 20rem;
              left: unset;
              right: 0%;
              font-size: 1.2rem !important;
            }
          }
        }

        .view-wrap {
          margin-left: auto;
          position: relative;
          //   color: #ff0f00;

          .text {
            font-size: 1.3rem;
            // color: currentColor;
          }

          .cancel-box {
            margin-left: auto;
            width: 2.2rem;
            height: 2.2rem;
            border-radius: 50%;
            background-color: #ffdfdd;
            margin-left: auto;
            display: grid;
            place-items: center;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
              transform: scale(1.1);
            }

            .icon {
              fill: #ff0f00;
              width: 1.1rem;
              height: 1.1rem;
            }
          }

          .view-all-box {
            position: absolute;
            bottom: 105%;
            right: 0;
            width: 35rem;
            height: 30rem;
            padding: 2rem;
            z-index: 4;
            overflow-y: scroll;
            box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
              0px 48px 64px -36px rgba(51, 51, 51, 0.08);
            border-radius: 2rem;
            transform: scale(0);
            transition: all 0.3s ease-in-out;
            transform-origin: bottom right;
            border-top-right-radius: unset;
            border-bottom-right-radius: unset;

            .wrap {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 2rem;
              // padding-bottom: 5rem;

              .child-wrap {
                display: flex;
                align-items: center;
                padding: 1rem 1.3rem;
                border: 0.1rem solid #f7f8f7;
                border-radius: 0.8rem;

                .text-box {
                  .name {
                    font-weight: 700;
                    font-size: 1.3rem;
                  }

                  .text {
                    font-size: 1.1rem;
                    font-weight: 400;
                  }
                }

                .img-box {
                  width: 3rem;
                  height: 3rem;
                  margin-right: 1rem;

                  .img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }

                .cancel-box {
                  margin-left: auto;
                  width: 2.2rem;
                  height: 2.2rem;
                  border-radius: 50%;
                  background-color: #ffdfdd;
                  margin-left: auto;
                  display: grid;
                  place-items: center;
                  cursor: pointer;
                  transition: all 0.3s;

                  &:hover {
                    transform: scale(1.1);
                  }

                  .icon {
                    fill: #ff0f00;
                    width: 1.1rem;
                    height: 1.1rem;
                  }
                }
              }
            }

            &::-webkit-scrollbar:horizontal {
              display: none;
            }

            /* width */
            &::-webkit-scrollbar {
              width: 0.3rem;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: none !important;
              background-color: transparent !important;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #020202;
              border-radius: 30rem;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #020202;
            }
          }

          .view-all-box-show {
            transform: scale(1);
          }

          .text {
            cursor: pointer;
            font-weight: 600;
            color: var(--primary-purple);
          }
        }
      }

      .preview-box {
        max-height: 15rem;
        margin-top: 1rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 1rem;
        // background-color: red;

        overflow-x: scroll;

        &::-webkit-scrollbar{
          display: none;
        }

        @media only screen and (max-width: 42em) {
          grid-template-columns: repeat(2, 1fr);
        }

        .box {
          border-radius: 0.8rem;
          // background: #ffffff !important;
          border: 0.1rem solid rgba(204, 204, 204, 0.5);
          padding: 1rem 0.8rem;
          cursor: pointer;
          position: relative;
          gap: 0.4rem;

          position: relative;

          .check-box {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            display: grid;
            place-items: center;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            background-color: #020202;
            visibility: hidden;

            .icon {
              width: 0.6rem;
              height: 0.6rem;
              fill: #ffffff;
            }
          }

          .avatar-box {
            margin-bottom: -1rem;
            margin-top: 1rem;
            width: 2.5rem;
            height: 2.5rem;
            display: grid;
            place-items: center;
            border-radius: 50%;
            background-color: #f7f8f7;

            span {
              font-weight: 700;
              font-size: 1.3rem;
              text-transform: uppercase;
              color: #020202;
            }
          }

          .name {
            margin-top: 2rem;
            font-size: 1.2rem;
            text-transform: capitalize;
          }

          .text {
            font-size: 0.9rem;
          }
        }

        .box-card-active {
          border: 0.1rem solid #020202;

          .check-box {
            visibility: visible;
          }
        }
      }
    }
  }
}

.send-money-wrap-box-bank-auto {
  display: flex;
  flex-direction: column;
  width: 100%;
  //   background-color: red;
  padding-bottom: 6rem;

  .bulk-schedule-narration-box {
    width: 40rem;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .total-box-summary-wrap {
      //   display: grid;
      //   grid-template-columns: repeat(2, 1fr);
      //   gap: 1rem;
      display: flex;
      gap: 2rem;
      padding: 2rem 0rem;

      & > * {
        flex: 0 0 50%;
      }

      .box {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        width: max-content;

        .label {
          text-transform: uppercase;
          font-size: 1.3rem;
        }

        .value {
          font-weight: 700;
          font-size: 1.75rem;
        }
      }
    }

    @media only screen and (max-width: 42em) {
      width: 90%;
      margin: 2rem auto;
    }
  }

  .table-wrapper-bulk-transfer {
    width: 100% !important;
    //    margin-top: 2rem;
    padding: 0rem 3rem;

    // .table-wrap
    @media only screen and (max-width: 58em) {
      // width: ;
      overflow-x: scroll;
      width: 100%;
      padding-bottom: 3rem;
      // background-color: red;

      &::-webkit-scrollbar:vertical {
        display: none;
      }

      /* width */
      &::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.4rem;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: none !important;
        background-color: transparent !important;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #020202;
        border-radius: 30rem;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #020202;
      }

      .table-wrap {
        width: 60rem;
        padding-bottom: 3rem;
        // display: none;

        .status-type-wrap {
          background-color: #e9f5ff;
          border-radius: 3rem;
          width: max-content;
          padding: 0.8rem 1.2rem;
          color: #476885;
          text-transform: capitalize;

          span {
            // font-weight: 700;
            font-size: 1.2rem;
          }
        }

        .status-type-wrap-fail {
          background-color: #fff5f5;
          color: #ff0f00;
        }

        .status-type-wrap-success {
          background-color: #e8fff6;
          color: #1ace37;
        }

        .status-type-wrap-pending {
          background-color: #fff6ed;
          color: #ea872d;
        }
      }
    }

    .text-avatar-box {
      display: flex;
      align-items: center;
      gap: 1rem;

      .avatar-box {
        width: 3rem;
        height: 3rem;
        background-color: #f7f8f7;
        border: 0.1rem solid #cccccc;
        border-radius: 50%;
        display: grid;
        place-items: center;

        span {
          font-weight: 600;
          font-size: 1.2rem;
          text-transform: capitalize;
        }
      }
    }

    .btn-upload-box {
      margin-top: 4rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // margin-bottom: 10rem;

      & > *:last-child {
        margin-left: 2rem;
      }

      input {
        display: none;
      }

      .raven-btn {
        min-width: 15rem;
      }
    }

    .table-pagination-box {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 3rem;
    }
  }

  .upload-doc-wrap {
    width: 55rem;
    // background-color: red;
    margin: 0rem auto;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    gap: 3rem;

    @media only screen and (max-width: 42em) {
      width: 90%;
    }

    .upload-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .upload-group {
        min-height: 20rem;

        label {
          height: 20rem;
        }
      }

      .upload-text-pro {
        color: #755ae2;
        font-weight: 600;
      }
    }

    .download-info-box {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 2rem 3.5rem;
      border-radius: 1.2rem;
      background-color: #f7f8f7;

      @media only screen and (max-width: 42em) {
        padding: 1.5rem;
      }

      .text-icon-box {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        @media only screen and (max-width: 42em) {
          flex-direction: column;
          align-items: flex-start;
          gap: 0.4rem;
          margin-bottom: 0.3rem;
        }

        .img-box {
          margin-right: 1rem;
          display: grid;
          place-items: center;
        }

        .text {
          font-size: 1.3rem;

          @media only screen and (max-width: 42em) {
            flex-direction: column;
            align-items: flex-start;
            // gap: 1rem;
          }

          span {
            color: var(--primary-purple) !important;
            font-weight: 700;
            cursor: pointer;
            transition: all 0.3s;
            display: inline-block;

            @media only screen and (max-width: 42em) {
              margin-top: -0.4rem;
            }

            &:hover {
              transform: translateY(-0.02rem);
            }
          }
        }
      }

      .dot-text {
        display: flex;

        .dot {
          margin-right: 0.5rem;
        }

        .text {
          font-size: 1.3rem;

          span {
            font-weight: 700;
            color: #020202;
          }
        }
      }
    }
  }
}

.table-wrapper-bulk-transfer-raven-bulk {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;

  .table-wrap {
    flex: 1;
    overflow-y: scroll;
    // background-color: red ;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.send-money-bulk-transfer-new-verify {
  tr {
    & > *:not(:last-child),
    & > *:not(:first-child) {
      padding-right: 2rem !important;
    }

    & > *:nth-child(3) {
      width: 25rem;
    }

    .edit-table-input input,
    .react-select-class > *:nth-child(3) {
      background-color: #ffffff;
    }

    td {
      position: relative;
      .one-error-box {
        // background-color: green;
        display: flex;
        gap: 0.7rem;
        align-items: center;

        .error-box {
          .img-box {
            display: grid;
            place-items: center;
            width: 1.5rem;
            height: 1.5rem;
            // border-radius: 50%;
            // background-color: rgba(255, 15, 0, 1);
            cursor: pointer;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          // span {
          //   display: inline-block;
          //   width: 1rem;
          //   height: 1rem;
          //   border-radius: 50%;
          //   background-color: rgba(255, 15, 0, 1);
          //   cursor: pointer;
          // }
        }
      }

      .payroll-table-action-wrap {
        margin: 0rem auto;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: red;
        gap: 1rem;
        width: 100%;

        .img-box {
          width: 3.5rem;
          height: 3.5rem;
          // background-color: #f7f8f7;
          border-radius: 50%;
          display: grid;
          place-items: center;
          transition: transform 0.3s ease-in-out;

          &:hover {
            transform: scale(1.05);
          }

          .img {
            width: 40%;
            height: 40%;
            object-fit: contain;
          }
        }

        & > *:nth-child(2) {
          .img {
            width: 50%;
            height: 50%;
            object-fit: contain;
          }
        }

        .delete-box {
          background-color: #fff5f5;
        }
      }

      //   .react-select-class > *:nth-child(3) {
      //     width: 20rem;

      //     input{
      //         width: 100%;
      //     }

      //   }
    }

    .status-type-wrap {
      background-color: #e9f5ff;
      border-radius: 3rem;
      width: max-content;
      padding: 0.8rem 1.2rem;
      color: #476885;
      text-transform: capitalize;

      span {
        // font-weight: 700;
        font-size: 1.2rem;
      }
    }

    .status-type-wrap-fail {
      background-color: #fff5f5;
      color: #ff0f00;
    }

    .status-type-wrap-success {
      background-color: #e8fff6;
      color: #1ace37;
    }

    .status-type-wrap-pending {
      background-color: #fff6ed;
      color: #ea872d;
    }
  }

  .status-type-wrap {
    background-color: #e9f5ff;
    border-radius: 3rem;
    width: max-content;
    padding: 0.8rem 1.2rem;
    color: #476885;
    text-transform: capitalize;

    span {
      // font-weight: 700;
      font-size: 1.2rem;
    }
  }

  .status-type-wrap-fail {
    background-color: #fff5f5;
    color: #ff0f00;
  }

  .status-type-wrap-success {
    background-color: #e8fff6;
    color: #1ace37;
  }

  .status-type-wrap-pending {
    background-color: #fff6ed;
    color: #ea872d;
  }

  .payroll-table-action-wrap {
    margin: 0rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: red;
    gap: 1rem;
    width: 100%;

    .img-box {
      width: 3.5rem;
      height: 3.5rem;
      // background-color: #f7f8f7;
      border-radius: 50%;
      display: grid;
      place-items: center;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }

      .img {
        width: 40%;
        height: 40%;
        object-fit: contain;
      }
    }

    & > *:nth-child(2) {
      .img {
        width: 50%;
        height: 50%;
        object-fit: contain;
      }
    }

    .delete-box {
      background-color: #fff5f5;
    }
  }

  .filter-export-processing-wrap {
    width: 100%;
    // background-color: red;
    display: flex;
    align-items: center;
    // gap: 2rem;
    margin: 1.5rem 0rem;
    position: relative;
    z-index: 20;

    .retry-wrap-icon {
      // margin-left: auto;
      display: flex;

      .raven-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        // display: none;

        .img-box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2rem;
          height: 2rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .name-numb-box {
      margin-right: 2rem;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      gap: 2rem;

      .text {
        cursor: pointer;
      }

      span {
        background-color: #020202;
        color: #ffffff;
        padding: 0.6rem 1rem;
        font-weight: 600;
        border-radius: 0.4rem;
      }

      .failed {
        background-color: rgba(255, 239, 238, 1);
        color: rgba(255, 15, 0, 1);
      }

      .verified {
        background-color: rgba(232, 255, 246, 1);
        color: rgba(26, 206, 55, 1);
      }
    }

    // .processing-content-box {
    //   // margin-right: 2rem;
    //   // margin-left: auto;
    // }

    .export-table-content-box {
      display: flex;
      align-items: center;
      padding: 1.2rem 1.5rem;
      border-radius: 0.8rem;
      gap: 1rem;
      cursor: pointer;
      // margin-left: -2rem;
      align-self: flex-end;
      margin-right: auto;
      // margin: 2rem;
      margin-right: auto;

      &:hover .img-box {
        transform: translateY(0.4rem);
      }

      .img-box {
        width: 2.2rem;
        height: 2.2rem;
        transition: all 0.3s;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .filter-wrap-box {
      margin-left: auto;
      margin-right: 2rem;
    }
  }
}

.spinner-raven-logo-wrap {
  // background-color: red;
  width: 100% !important;
  max-width: unset;
  flex: 1;
}

// @include xs {
//   .send-money-wrap {
//     width: unset !important;
//     padding: 0 2rem;
//   }
// }


.transfer-send-within-account-index-wrap{
  width: 40rem;
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  gap: 2rem;
  padding-bottom: 10rem;

  @media only screen and (max-width: 42em) {
    width: 100%;
    margin: 0rem auto;
    // background-color: red;
  }
}