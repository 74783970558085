.referee-invite-public-wrap-index-box {
  width: 100vw;
  height: 100vh;
  // background-color: green;
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/invite-ref-bg-img.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .header-nav-box {
    flex: 0 0 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: blue;

    .img-wrap {
      .img-box {
        width: 12rem;
        height: 3rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .footer-wrap-box {
    flex: 0 0 5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .text-wrap {
      display: flex;
      align-items: center;

      .text {
        // color: rgba(139, 139, 139, 1);
        font-size: 1.5rem;

        span {
          //   color: #020202;
          font-weight: 600;
          border-bottom: 0.15rem solid currentColor;
          cursor: pointer;
        }
      }
    }
  }

  .middle-content-wrap {
    flex: 1;
    overflow-y: scroll;
    display: grid;
    place-items: center;

    .middle-content-box {
      width: 65rem;
      min-height: 45rem;

      @media only screen and (max-width: 48em) {
        width: 95%;
      }

      .success-failed-main-index-content-wrap {
        // min-height: 45rem;
        border-radius: 1.2rem;
        border-width: 0.15rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5rem;

        @media only screen and (max-width: 48em) {
          padding: 4rem 3rem;
        }

        .img-round-box {
          margin-bottom: 3rem;
          width: 15rem;
          height: 15rem;
          border-radius: 50%;
          background-color: rgba(255, 246, 237, 1);
          display: flex;
          align-items: flex-end;
          justify-content: center;
          overflow: hidden;

          .img-box {
            width: 80%;
            height: 80%;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .img-round-box-failed {
          background-color: rgba(250, 250, 255, 1);
        }

        .title {
          font-weight: 700;
          font-size: 2.5rem;
          margin-bottom: 1rem;
          text-align: center;
        }

        .text {
          font-size: 1.65rem;
          text-align: center;
        }
      }

      .instruction-content-index-wrap {
        width: 100%;
        height: 100%;
        border-radius: 1.2rem;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        gap: 1.5rem;
        // border-width: .15rem;

        .top-box {
          flex: 0 0 30%;
          background-color: rgba(255, 246, 237, 1);
          display: flex;
          flex-direction: column;
          gap: 1rem;
          align-items: center;
          padding: 2rem 1rem;

          .text {
            // margin-top: 1.5rem;
            // color: rgba(139, 139, 139, 1);
            font-size: 1.5rem;
            text-align: center;

            span {
              //   color: #020202;
              font-weight: 600;
              border-bottom: 0.15rem solid currentColor;
              cursor: pointer;
            }
          }

          .img-wrap-content {
            width: 100%;
            height: 20rem;
            background-image: url("../../assets/referre-invitetop-bg.png");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin-bottom: -3rem;
          }
        }

        .bottom-content {
          //   min-height: 35rem;
          padding: 1.5rem 2.5rem;
          display: flex;
          flex-direction: column;
          gap: 2rem;

          .btn-instruc-box {
            align-self: flex-end;
            margin: 1rem 0rem;
          }

          .bottom-title {
            font-weight: 600;
            font-size: 1.6rem;
          }

          .list-instruc-box-wrap {
            border-radius: 1.2rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            padding: 2rem;

            .item-row {
              display: flex;
              align-items: center;
              gap: 1rem;

              .dot-box {
                .dot {
                  width: 1rem;
                  height: 1rem;
                  border-radius: 0.2rem;
                  background-color: rgba(234, 135, 45, 1);
                }
              }

              .text {
                font-size: 1.35rem;
              }
            }
          }
        }
      }

      .referee-invite-form-index-wrap {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .top-title-back-box {
          .title-box {
            text-align: center;
            font-size: 2.5rem;
            font-weight: 500;
          }
        }

        .side-nav-form-box {
          min-height: 40rem;
          border-width: 0.15rem;
          border-radius: 1.2rem;
          padding: 2rem;
          display: flex;
          gap: 1.5rem;

          @media only screen and (max-width: 48em) {
            flex-direction: column;
            gap: 3rem;
          }

          .menu-nav-box {
            // flex: 0 0 25%;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            @media only screen and (max-width: 48em) {
              flex-direction: row;
            }

            .nav-item {
              width: max-content;
              cursor: pointer;
              position: relative;
              padding: 1.5rem 1.8rem;
              border-radius: 1.2rem;

              .name {
                font-size: 1.35rem;
              }
            }

            .nav-item-active {
              cursor: not-allowed;

              .name {
                font-weight: 600;
              }
            }
          }

          .form-content {
            flex: 1;
            // background-color: blue;
            padding: 2.5rem;
            display: flex;
            flex-direction: column;
            padding-top: unset;
            gap: 2.5rem;

            @media only screen and (max-width: 48em) {
              padding: 0rem 0rem 3rem 0rem;
            }
          }
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
