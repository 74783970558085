.title-text-box-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 48em) {
  .title-text-box-wrap {
    margin-bottom: 1.5rem;
    gap: 1rem;
  }
  .title-text-box-wrap .btn-box {
    margin-left: auto;
  }
}
.title-text-box-wrap .all-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: stretch;
}
.title-text-box-wrap .all-btn-wrap .terminal-request-stat-box {
  position: relative;
  z-index: 20;
}
.title-text-box-wrap .all-btn-wrap .terminal-request-stat-box .message-wrap-box {
  animation: moveDownSettingDrop 0.5s ease-in-out backwards;
  position: absolute;
  z-index: 10;
  width: 55rem;
  height: 15rem;
  border-radius: 1.6rem;
  display: flex;
  overflow: hidden;
}
@media only screen and (max-width: 48em) {
  .title-text-box-wrap .all-btn-wrap .terminal-request-stat-box .message-wrap-box {
    min-width: unset;
    width: 70vw;
    flex-direction: column;
    gap: 1rem;
    height: unset;
    padding-bottom: 2rem;
  }
}
.title-text-box-wrap .all-btn-wrap .terminal-request-stat-box .message-wrap-box .left-box {
  flex: 0 0 35%;
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 48em) {
  .title-text-box-wrap .all-btn-wrap .terminal-request-stat-box .message-wrap-box .left-box {
    flex: unset;
    width: 100%;
    min-height: 20rem;
  }
}
.title-text-box-wrap .all-btn-wrap .terminal-request-stat-box .message-wrap-box .right-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem;
  justify-content: center;
}
@media only screen and (max-width: 48em) {
  .title-text-box-wrap .all-btn-wrap .terminal-request-stat-box .message-wrap-box .right-box {
    flex: unset;
    width: 100%;
  }
}
.title-text-box-wrap .all-btn-wrap .terminal-request-stat-box .message-wrap-box .right-box .title {
  font-weight: 700;
  font-size: 1.7rem;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 48em) {
  .title-text-box-wrap .all-btn-wrap .terminal-request-stat-box .message-wrap-box .right-box .title {
    font-size: 2.2rem;
  }
}
.title-text-box-wrap .all-btn-wrap .terminal-request-stat-box .message-wrap-box .right-box .text {
  font-size: 1.2rem;
}
@media only screen and (max-width: 48em) {
  .title-text-box-wrap .all-btn-wrap .terminal-request-stat-box .message-wrap-box .right-box .text {
    font-size: 1.5rem;
  }
}
.title-text-box-wrap .all-btn-wrap .terminal-request-stat-box .message-wrap-box .right-box .text span {
  font-weight: 700;
  color: #755ae2 !important;
  cursor: pointer;
}
.title-text-box-wrap .all-btn-wrap .terminal-request-stat-box .img-box {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #ea872d;
  display: grid;
  place-items: center;
  transition: all 0.3s;
}
.title-text-box-wrap .all-btn-wrap .terminal-request-stat-box .img-box:hover {
  transform: translateY(-0.1rem);
}
.title-text-box-wrap .all-btn-wrap .terminal-request-stat-box .img-box .img {
  width: 45%;
  height: 45%;
  -o-object-fit: contain;
     object-fit: contain;
}
.title-text-box-wrap .setting-box-wrap {
  margin-left: 2rem;
  position: relative;
}
.title-text-box-wrap .setting-box-wrap .drop-wrap {
  position: absolute;
  top: 110%;
  right: 0%;
  width: -moz-max-content;
  width: max-content;
  min-width: 22rem;
  z-index: 20;
  box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05), -0.1rem -0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
  font-size: 1.5rem;
  display: flex;
  align-items: stretch;
  gap: 1.5rem;
  border-radius: 2.4rem;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 2.5rem 1.6rem;
  height: -moz-max-content;
  height: max-content;
  animation: moveDownSettingDrop 0.5s ease-in-out backwards;
}
.title-text-box-wrap .setting-box-wrap .drop-wrap > *:not(:last-child) {
  padding-bottom: 1.3rem;
}
.title-text-box-wrap .setting-box-wrap .drop-wrap .text-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.title-text-box-wrap .setting-box-wrap .drop-wrap .text-box .avatar-box {
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: #e8fff6;
  border: 0.08rem solid #9be5c9;
  margin-right: 1rem;
}
.title-text-box-wrap .setting-box-wrap .drop-wrap .text-box .avatar-box span {
  font-weight: 800;
  font-size: 1rem;
  display: inline-block;
  letter-spacing: 0.07rem;
  color: #0b8376;
}
.title-text-box-wrap .setting-box-wrap .drop-wrap .text-box .img-box {
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1rem;
}
@media only screen and (max-width: 42em) {
  .title-text-box-wrap .setting-box-wrap .drop-wrap .text-box .img-box {
    width: 2.3rem;
    height: 2.3rem;
  }
}
.title-text-box-wrap .setting-box-wrap .drop-wrap .text-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.title-text-box-wrap .setting-box-wrap .drop-wrap .text-box .icon-box {
  width: 2rem;
  height: 2rem;
}
.title-text-box-wrap .setting-box-wrap .drop-wrap .text-box .icon-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.title-text-box-wrap .setting-box-wrap .drop-wrap .text-box .text {
  font-size: 1.3rem;
  font-weight: 400;
  cursor: pointer;
  text-transform: capitalize;
}
@media only screen and (max-width: 42em) {
  .title-text-box-wrap .setting-box-wrap .drop-wrap .text-box .text {
    font-size: 1.2rem;
  }
}
.title-text-box-wrap .setting-box-wrap .drop-wrap .text-box_two .img-box {
  width: 3.5rem;
  height: 3.5rem;
}
@media only screen and (max-width: 42em) {
  .title-text-box-wrap .setting-box-wrap .drop-wrap .text-box_two .img-box {
    width: 3rem;
    height: 3rem;
  }
}
.title-text-box-wrap .setting-box-wrap .drop-wrap .text-box_two .text_two {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}
.title-text-box-wrap .setting-box-wrap .drop-wrap .text-box_two .text_two > *:first-child {
  font-weight: 600;
  font-size: 1.5rem;
}
.title-text-box-wrap .setting-box-wrap .drop-wrap .text-box_two .text_two span {
  letter-spacing: -0.2%;
  font-size: 1.2rem;
}
.title-text-box-wrap .setting-box-wrap .drop-wrap > *:first-child .img-box {
  width: 3.5rem;
  height: 3.5rem;
  background-color: #f7f8f7;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.title-text-box-wrap .setting-box-wrap .drop-wrap > *:first-child .img-box .img {
  width: 50%;
  height: 50%;
}
.title-text-box-wrap .setting-box-wrap .setting-box {
  display: grid;
  place-items: center;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: #ffffff;
  border: 0.1rem solid #f8fcff;
  filter: drop-shadow(0px 3px 4px rgba(51, 51, 51, 0.04)) drop-shadow(0px 0px 2px rgba(51, 51, 51, 0.02));
  cursor: pointer;
}
.title-text-box-wrap .setting-box-wrap .setting-box .img-box {
  width: 3rem;
  height: 3rem;
  display: grid;
  place-items: center;
}
.title-text-box-wrap .setting-box-wrap .setting-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.title-text-box-wrap .terminal-transaction-box .raven-btn {
  border-radius: 6rem;
  display: flex;
  align-items: center;
  background-color: #1b1b1b;
}
.title-text-box-wrap .terminal-transaction-box .raven-btn .img-box {
  width: 2rem;
  height: 2rem;
}
.title-text-box-wrap .terminal-transaction-box .raven-btn .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.title-text-box-wrap .setting-pos-box-wrap {
  margin-left: 2rem;
  position: relative;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box {
  width: 35rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box > *:last-child {
  border: unset;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .setting-payment-link-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .setting-payment-link-row .name-text-box {
  display: flex;
  flex-direction: column;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .setting-payment-link-row .name-text-box .name {
  font-weight: 700;
  font-size: 1.5rem;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .setting-payment-link-row .name-text-box .text {
  font-size: 1.25rem;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .setting-payment-link-row .img-wrap {
  flex: 0 0 8%;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .setting-payment-link-row .img-wrap .img-box {
  width: 4rem;
  height: 4rem;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .setting-payment-link-row .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row-pos {
  padding-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row-pos .name-text-box {
  font-size: 1.25rem;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row-pos .name-text-box .name {
  font-weight: 600;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row-pos .name-text-box .text {
  font-size: 1.15rem;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row-pos .img-wrap-box .img-box {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row-pos .img-wrap-box .img-box .img {
  width: 50%;
  height: 50%;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row-pos .terminal-request-stat-box .img-box {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #ea872d;
  display: grid;
  place-items: center;
  transition: all 0.3s;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row-pos .terminal-request-stat-box .img-box:hover {
  transform: translateY(-0.1rem);
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row-pos .terminal-request-stat-box .img-box .img {
  width: 45%;
  height: 45%;
  -o-object-fit: contain;
     object-fit: contain;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .wrap {
  display: flex;
  flex-direction: column;
  border: unset;
  gap: 1.3rem;
  max-height: 40rem;
  overflow-y: scroll;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .wrap::-webkit-scrollbar:horizontal {
  display: none;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .wrap::-webkit-scrollbar {
  width: 0.5rem;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .wrap::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 30rem;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .wrap::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .wrap > *:not(:last-child) {
  padding-bottom: 1.3rem;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .wrap > *:last-child {
  border: unset;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .add-new-casher-box {
  margin-top: 0rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .add-new-casher-box:hover .img-box {
  transform: scale(1.03);
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .add-new-casher-box .img-box {
  transition: all 0.3s;
  display: grid;
  place-items: center;
  margin-right: 0.5rem;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row {
  display: flex;
  align-items: center;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row .icon-wrap {
  display: grid;
  place-items: center;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row .icon-wrap .img-box {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row .icon-wrap .img-box .img {
  width: 75%;
  height: 75%;
  position: absolute;
  bottom: 0;
  right: 0;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row .text-box {
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
  align-items: flex-start;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row .text-box .name {
  font-weight: 700;
  margin-bottom: 0.25rem;
  text-transform: capitalize;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row .text-box .number {
  font-size: 1.2rem;
  text-align: left;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row .delete-wrap {
  flex: 0 0 10%;
  margin-left: auto;
  display: grid;
  place-items: center;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row .delete-wrap .img-box {
  background-color: unset;
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-pos-box .child-row .delete-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-box {
  display: grid;
  place-items: center;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: #ffffff;
  border: 0.1rem solid #f8fcff;
  filter: drop-shadow(0px 3px 4px rgba(51, 51, 51, 0.04)) drop-shadow(0px 0px 2px rgba(51, 51, 51, 0.02));
  cursor: pointer;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-box .img-box {
  width: 3rem;
  height: 3rem;
  display: grid;
  place-items: center;
}
.title-text-box-wrap .setting-pos-box-wrap .setting-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.title-text-box-wrap .back-text-box {
  margin-right: auto;
  display: flex;
  align-items: center;
  flex: 1;
}
@media only screen and (max-width: 52em) {
  .title-text-box-wrap .back-text-box {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
}
.title-text-box-wrap .back-text-box .back-box {
  margin-right: 1rem;
  display: grid;
  place-items: center;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: #020202;
  cursor: pointer;
  transition: transform 0.3s;
}
@media only screen and (max-width: 42em) {
  .title-text-box-wrap .back-text-box .back-box {
    flex: 0 0 9%;
    width: unset;
    height: unset;
  }
}
.title-text-box-wrap .back-text-box .back-box:hover {
  transform: translateX(-0.1rem);
}
.title-text-box-wrap .back-text-box .back-box .img-box {
  width: 1.2rem;
  height: 1.2rem;
  display: grid;
  place-items: center;
  position: relative;
}
@media only screen and (max-width: 42em) {
  .title-text-box-wrap .back-text-box .back-box .img-box {
    width: 3.5rem;
    height: 3.5rem;
  }
}
.title-text-box-wrap .back-text-box .back-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 42em) {
  .title-text-box-wrap .back-text-box .back-box .img-box .img {
    width: 40%;
    height: 40%;
  }
}
.title-text-box-wrap .back-text-box .text-box {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
@media only screen and (max-width: 42em) {
  .title-text-box-wrap .back-text-box .text-box {
    gap: 1rem;
  }
}
.title-text-box-wrap .back-text-box .text-box .title {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.05rem;
}
@media only screen and (max-width: 42em) {
  .title-text-box-wrap .back-text-box .text-box .title {
    font-size: 2rem;
    line-height: 1.5rem;
  }
}
.title-text-box-wrap .back-text-box .text-box .text {
  font-size: 1.4rem;
}
@media only screen and (max-width: 42em) {
  .title-text-box-wrap .back-text-box .text-box .text {
    font-size: 1.3rem;
  }
}
.title-text-box-wrap .toggle-box {
  position: relative;
}
.title-text-box-wrap .toggle-box .raven-btn {
  position: relative;
  z-index: 20;
}
.title-text-box-wrap .toggle-box .select-bulk-type-box {
  z-index: 200;
  position: absolute;
  top: 100%;
  right: 100%;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 2.4rem;
  padding: 2rem 2.5rem;
  display: flex;
  flex-direction: column;
  width: 30rem;
  transform: scale(0);
  transform-origin: top right;
  transition: all 0.3s;
  opacity: 1;
}
.title-text-box-wrap .toggle-box .select-bulk-type-box > *:first-child {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.1rem solid #f7f8f7;
}
.title-text-box-wrap .toggle-box .select-bulk-type-box .child-row {
  display: flex;
  align-items: center;
  width: -moz-max-content;
  width: max-content;
  align-self: stretch;
  width: 100%;
}
.title-text-box-wrap .toggle-box .select-bulk-type-box .child-row:hover .img-box {
  transform: scale(1.03);
}
.title-text-box-wrap .toggle-box .select-bulk-type-box .child-row .title {
  font-weight: 700;
  font-size: 1.4rem;
  text-align: left;
}
.title-text-box-wrap .toggle-box .select-bulk-type-box .child-row .text {
  font-size: 1.25rem;
  text-align: left;
}
.title-text-box-wrap .toggle-box .select-bulk-type-box .child-row .img-box {
  width: 4rem;
  height: 4rem;
  display: grid;
  place-items: center;
  margin-right: 1rem;
  transition: all 0.3s;
  border-radius: 50%;
  background-color: #f7f8f7;
}
.title-text-box-wrap .toggle-box .select-bulk-type-box .child-row .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 50%;
  height: 50%;
}
.title-text-box-wrap .toggle-box .select-bulk-type-box-show {
  transform: scale(1);
}
.title-text-box-wrap .next-box {
  margin-left: 2rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: #020202;
  width: 3.5rem;
  height: 3.5rem;
  cursor: pointer;
  transition: all 0.3s;
}
.title-text-box-wrap .next-box:hover {
  transform: translateX(0.15rem);
}
.title-text-box-wrap .next-box .img-box {
  width: 1.3rem;
  height: 1.3rem;
}
.title-text-box-wrap .next-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}/*# sourceMappingURL=TitletextBox.css.map */