.raven-to-raven-manual-page-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.raven-to-raven-manual-page-wrap tr > *:not(:last-child), .raven-to-raven-manual-page-wrap tr > *:not(:first-child) {
  padding-right: 2rem !important;
}
.raven-to-raven-manual-page-wrap tr > *:nth-child(3) {
  width: 25rem;
}
.raven-to-raven-manual-page-wrap tr .edit-table-input input,
.raven-to-raven-manual-page-wrap tr .react-select-class > *:nth-child(3) {
  background-color: #ffffff;
}
.raven-to-raven-manual-page-wrap tr td .payroll-table-action-wrap {
  margin: 0rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}
.raven-to-raven-manual-page-wrap tr td .payroll-table-action-wrap .img-box {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: transform 0.3s ease-in-out;
}
.raven-to-raven-manual-page-wrap tr td .payroll-table-action-wrap .img-box:hover {
  transform: scale(1.05);
}
.raven-to-raven-manual-page-wrap tr td .payroll-table-action-wrap .img-box .img {
  width: 40%;
  height: 40%;
  -o-object-fit: contain;
     object-fit: contain;
}
.raven-to-raven-manual-page-wrap tr td .payroll-table-action-wrap > *:nth-child(2) .img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
.raven-to-raven-manual-page-wrap tr td .payroll-table-action-wrap .delete-box {
  background-color: #fff5f5;
}
.raven-to-raven-manual-page-wrap tr .status-type-wrap {
  background-color: #e9f5ff;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #476885;
  text-transform: capitalize;
}
.raven-to-raven-manual-page-wrap tr .status-type-wrap span {
  font-size: 1.2rem;
}
.raven-to-raven-manual-page-wrap tr .status-type-wrap-fail {
  background-color: #fff5f5;
  color: #ff0f00;
}
.raven-to-raven-manual-page-wrap tr .status-type-wrap-success {
  background-color: #e8fff6;
  color: #1ace37;
}
.raven-to-raven-manual-page-wrap tr .status-type-wrap-pending {
  background-color: #fff6ed;
  color: #ea872d;
}
.raven-to-raven-manual-page-wrap .btn-wrap-box {
  padding-left: 3rem;
  padding-right: 3rem;
  margin-top: 3rem;
  padding-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.raven-to-raven-manual-page-wrap .btn-wrap-box > *:last-child {
  margin-left: 3rem;
}
.raven-to-raven-manual-page-wrap .bulk-schedule-narration-box {
  width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 0rem auto;
  margin-top: 3rem;
}
.raven-to-raven-manual-page-wrap .bulk-schedule-narration-box .total-box-summary-wrap {
  display: flex;
  gap: 2rem;
  padding: 2rem 0rem;
}
.raven-to-raven-manual-page-wrap .bulk-schedule-narration-box .total-box-summary-wrap > * {
  flex: 0 0 50%;
}
.raven-to-raven-manual-page-wrap .bulk-schedule-narration-box .total-box-summary-wrap .box {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: -moz-max-content;
  width: max-content;
}
.raven-to-raven-manual-page-wrap .bulk-schedule-narration-box .total-box-summary-wrap .box .label {
  text-transform: uppercase;
  font-size: 1.3rem;
}
.raven-to-raven-manual-page-wrap .bulk-schedule-narration-box .total-box-summary-wrap .box .value {
  font-weight: 700;
  font-size: 1.75rem;
}
@media only screen and (max-width: 42em) {
  .raven-to-raven-manual-page-wrap .bulk-schedule-narration-box {
    width: 90%;
    margin: 2rem auto;
  }
}
.raven-to-raven-manual-page-wrap .bulk-schedule-narration-box .save-bulk-template-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: -1rem;
}
.raven-to-raven-manual-page-wrap .bulk-schedule-narration-box .save-bulk-template-box .template-title-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  width: 100%;
}
.raven-to-raven-manual-page-wrap .bulk-schedule-narration-box .save-bulk-template-box .template-title-box-show {
  max-height: 15rem;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}
.raven-to-raven-manual-page-wrap .bulk-schedule-narration-box .save-bulk-template-box .toggle-select-text-box {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.raven-to-raven-manual-page-wrap .bulk-schedule-narration-box .save-bulk-template-box .toggle-select-text-box .text {
  font-weight: 500;
}
.raven-to-raven-manual-page-wrap .bulk-schedule-narration-box .save-bulk-template-box .toggle-select-text-box > *:last-child {
  transform: scale(0.8);
}

.raven-record-modal-wrap {
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
}
.raven-record-modal-wrap .text-title-box {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}
.raven-record-modal-wrap .text-title-box .title {
  font-size: 1.75rem;
  font-weight: 700;
}
.raven-record-modal-wrap .text-title-box .text {
  font-size: 1.35rem;
}
.raven-record-modal-wrap .record-wrap {
  margin-top: 3.5rem;
  max-height: 40rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: scroll;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.raven-record-modal-wrap .record-wrap::-webkit-scrollbar {
  width: 0.5rem;
}
.raven-record-modal-wrap .record-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.raven-record-modal-wrap .record-wrap::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 30rem;
}
.raven-record-modal-wrap .record-wrap::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}
.raven-record-modal-wrap .record-wrap > *:not(:last-child) {
  padding-bottom: 1.5rem;
}
.raven-record-modal-wrap .record-wrap .template-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
.raven-record-modal-wrap .record-wrap .template-row .img-wrap {
  display: grid;
  place-items: center;
}
.raven-record-modal-wrap .record-wrap .template-row .img-wrap .img-box {
  width: 4rem;
  height: 4rem;
}
.raven-record-modal-wrap .record-wrap .template-row .img-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.raven-record-modal-wrap .record-wrap .template-row .title-text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateY(-0.5rem);
}
.raven-record-modal-wrap .record-wrap .template-row .title-text-box .title {
  font-weight: 700;
  font-size: 1.6rem;
}
.raven-record-modal-wrap .record-wrap .template-row .title-text-box .text {
  font-size: 1.3rem;
}/*# sourceMappingURL=ManualPageRavanToRaven.css.map */