.auth_layout_version_one_point_five_wrap {
  display: flex;
  width: 100vw;
  height: 100vh;
  gap: 3rem;

  @media only screen and (max-width: 48em) {
    gap: unset;
  }

  .left-box {
    flex: 0 0 45%;
    display: grid;
    place-items: center;

    @media only screen and (max-width: 48em) {
      display: none;
    }

    .wrapper {
      width: 95%;
      height: 95%;
      background-image: url("../../assets/bgauth-sign-up-img.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 1.6rem;
      padding: 2rem;
      display: flex;
      flex-direction: column;

      .bottom-wrap {
        margin-top: auto;
        padding: 3rem 2rem;
        display: flex;
        flex-direction: column;
        border-radius: 1.2rem;

        .step-box-wrap {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          margin-top: 2.5rem;

          .box {
            display: flex;
            align-items: center;
            flex-direction: row;
            position: relative;
            justify-content: flex-start;
            z-index: 3;
            width: 100%;
            border-radius: 1.2rem;
            width: 60%;
            padding: 1.5rem;
            gap: 1rem;

            .num {
              width: 3.2rem;
              height: 3.2rem;
              border-radius: 50%;
              background-color: rgba(180, 180, 180, 1);
              display: grid;
              place-items: center;
              cursor: pointer;

              span {
                color: #ffffff;
                font-size: 1.2rem;
              }
            }

            .text {
              font-size: 1.2rem;
              color: #333333;
            }
          }

          .box-active {
            .num {
              background-color: #333333;

              span {
                color: #ffffff;
                font-weight: 600;
              }
            }

            // .text {
            // //   color: #020202;
            // }
          }
        }

        .small-text-wrap {
          .text {
            font-size: 1.3rem;
          }
        }

        .big-text-wrap {
          margin-bottom: 0.5rem;
          .text {
            font-size: 2.5rem;
            font-weight: 700;
            line-height: 3rem;

            span {
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .right-box {
    flex: 1;
    display: grid;
    place-items: center;

    .wrapper-auth-content {
      width: max-content;

      @media only screen and (max-width: 48em) {
        width: 100%;
        display: grid;
        place-items: center;
      }

      .sign-up-form-content {
        width: 40rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @media only screen and (max-width: 48em) {
          width: 95%;
        }

      

        .form-group {
          .input-group {
            input {
              border: unset !important;
            }
          }
        }

        .check-text-box {
          margin-top: 1rem;
          display: flex;
          align-items: center;
          gap: 1.5rem;

          .text {
            font-size: 1.35rem;
            color: #676767;

            span {
              color: var(--primary-purple);
              font-weight: 600;
              cursor: pointer;
            }
          }

          .check-box-wrap {
            .input-check:checked ~ .label-check {
              border: none;
            }
            .label-check {
              border: 0.1rem solid #cccccc !important;
            }
          }
        }

        .two-group {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 2rem;

          @media only screen and (max-width: 42em) {
            grid-template-columns: 1fr;
            gap: 2rem;
          }

          .form-group {
            width: 100%;

            .form-input {
              width: 100%;
            }
          }
        }

        .forget-text {
          margin-top: -1.2rem;
          align-self: flex-end;
          cursor: pointer;
          color: var(--primary-purple);
          font-weight: 600;
          font-size: 1.3rem;
        }

        .btn-submit {
          width: 100%;
          margin-top: 2rem;
        }

        .already-text {
          font-size: 1.35rem;
          color: #676767;
          text-align: center;

          span {
            color: var(--primary-purple);
            font-weight: 600;
            cursor: pointer;
          }
        }

        .big-text-wrap {
          margin-bottom: 1.5rem;
          .text {
            font-size: 2.2rem;
            font-weight: 700;
            line-height: 3rem;

            span {
              font-weight: 400;
            }
          }
        }
      }

      .btn-submit-white input{
        color: #ffffff !important;
    }
    }
  }
}
