.badge-reusable-wrap-index .badge-wrap-index {
  padding: 0.65rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff6ed;
  cursor: pointer;
  border-radius: 3.5rem;
  width: -moz-max-content;
  width: max-content;
  gap: 0.5rem;
}
.badge-reusable-wrap-index .badge-wrap-index .img-box {
  width: 1.5rem;
  height: 1.5rem;
}
.badge-reusable-wrap-index .badge-wrap-index .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.badge-reusable-wrap-index .badge-wrap-index span {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1.3rem;
  color: #ea872d !important;
}
.badge-reusable-wrap-index .pending {
  background-color: #fff6ed;
}
.badge-reusable-wrap-index .pending span {
  color: #ea872d !important;
}
.badge-reusable-wrap-index .info {
  background-color: #e9f5ff;
}
.badge-reusable-wrap-index .info span {
  color: #476885 !important;
}
.badge-reusable-wrap-index .failed {
  background-color: #fff5f5;
}
.badge-reusable-wrap-index .failed span {
  color: #ff0f00 !important;
}
.badge-reusable-wrap-index .success {
  background-color: #e8fff6;
}
.badge-reusable-wrap-index .success span {
  color: #1ACE37 !important;
}/*# sourceMappingURL=BadgeComponent.css.map */