.top-transaction-single-bulk-container-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 2.5rem;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 55em) {
  .top-transaction-single-bulk-container-box {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }
}
.top-transaction-single-bulk-container-box .box {
  border-radius: 1.2rem;
  padding: 3rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
}
.top-transaction-single-bulk-container-box .box .title {
  font-weight: 700;
  padding-left: 2rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.top-transaction-single-bulk-container-box .box .title .title-total {
  padding: 0.4rem 1rem;
  border-radius: 1rem;
  display: grid;
  place-items: center;
}
.top-transaction-single-bulk-container-box .box .title .title-total span {
  display: inline-block;
  color: #ffffff !important;
  font-size: 1.1rem;
}
.top-transaction-single-bulk-container-box .box .title-two {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  margin-bottom: 1rem;
}
.top-transaction-single-bulk-container-box .box .title-two .title {
  margin-bottom: unset;
  padding-left: unset;
  margin-left: 0.7rem;
}
.top-transaction-single-bulk-container-box .box .value-box {
  padding: 1.5rem 1rem 1.5rem 2rem;
  border-radius: 0.8rem;
  margin-top: auto;
}
.top-transaction-single-bulk-container-box .box .value-box .value {
  font-weight: 700;
  font-size: 1.75rem;
}

.transaction-main-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}
@media only screen and (max-width: 48em) {
  .transaction-main-wrap {
    flex: unset;
    min-height: 50rem;
  }
}
.transaction-main-wrap .mobile-table-box {
  max-height: 65rem;
  overflow-y: scroll;
}
.transaction-main-wrap .mobile-table-box::-webkit-scrollbar {
  display: none;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 0rem;
  transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  position: relative;
  z-index: 2;
}
@media only screen and (max-width: 48em) {
  .transaction-main-wrap .transaction-card-bar-chart-wrap {
    display: none;
  }
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .filter-by-text {
  font-size: 1.2rem;
  cursor: pointer;
  color: #755ae2;
  align-self: flex-start;
  font-weight: 600;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .text-compare {
  font-size: 1.1rem;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .text-compare span {
  color: #1ace37 !important;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box {
  display: flex;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box > * {
  padding: 2rem 3rem;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .left-box {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .left-box .card-info {
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
  border-radius: 1.2rem;
  gap: 2.5rem;
  cursor: pointer;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .left-box .card-info > * {
  padding: 0rem 1.5rem;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .left-box .card-info .total-val-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2.5rem;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .left-box .card-info .total-val-box .label-val .label {
  text-transform: uppercase;
  font-size: 1.2rem;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .left-box .card-info .total-val-box .label-val .value {
  font-weight: 700;
  font-size: 2rem;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .left-box .card-info .total-val-box .img-wrap {
  display: grid;
  place-items: center;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .left-box .card-info .total-val-box .img-wrap .img-box-cre-deb {
  width: 3rem;
  height: 3rem;
  background-color: #1ace37 !important;
  border-radius: 50%;
  display: grid;
  place-items: center;
  flex: 0 0 5%;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .left-box .card-info .total-val-box .img-wrap .img-box-cre-deb .img {
  width: 35%;
  height: 35%;
  -o-object-fit: contain;
     object-fit: contain;
  filter: brightness(1000%);
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .left-box .card-info .total-val-box .img-wrap .img-box-debit {
  background-color: #ff0f00 !important;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .top-info-box {
  display: flex;
  justify-content: space-between;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .top-info-box .total-wallet-box {
  font-size: 1.25rem;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .top-info-box .total-wallet-box .value {
  font-size: 2.5rem;
  font-weight: 700;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .top-info-box .total-wallet-box .update {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .top-info-box .label-select-box {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .top-info-box .label-select-box .pos-select-wrap {
  width: -moz-max-content;
  width: max-content;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .top-info-box .label-select-box .pos-select-wrap .react-select-class > *:nth-child(3) {
  box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04), 0px 0px 2px rgba(51, 51, 51, 0.02);
  min-height: unset;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .top-info-box .label-select-box .pos-select-wrap .react-select-class > *:nth-child(3) > *:first-child {
  padding: unset;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .top-info-box .label-select-box .pos-select-wrap .react-select-class > *:nth-child(3) > *:first-child > *:nth-child(2) input {
  min-width: unset !important;
  grid-area: unset !important;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .top-info-box .label-select-box .pos-select-wrap .react-select-class > *:nth-child(3) > *:nth-child(2) {
  padding: unset;
  margin-left: 0.7rem;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .top-info-box .label-select-box .pos-select-wrap .react-select-class > *:nth-child(3) > *:nth-child(2) > *:nth-child(2) {
  padding: unset;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .top-info-box .label-select-box .pos-select-wrap .react-select-class > *:nth-child(3) > *:nth-child(2) > *:nth-child(2) svg {
  width: 1.5rem;
  height: 1.5rem;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .top-info-box .label-select-box .pos-select-wrap .react-select-class > *:nth-child(4) {
  width: -moz-max-content;
  width: max-content;
  left: unset;
  right: 0;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .top-info-box .label-select-box .text-label {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .top-info-box .label-select-box .text-label .text {
  font-size: 1.25rem;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .top-info-box .label-select-box .text-label span {
  width: 1rem;
  height: 1rem;
  background-color: #755ae2;
  border-radius: 0.3rem;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .top-info-box .label-select-box .text-label .expense {
  background-color: #b4a4fc;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .bottom-info-box {
  flex: 1;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .bottom-info-box .bar-chat-box {
  width: 100%;
  height: 100%;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap-show {
  overflow-y: visible;
  max-height: 40rem;
  margin-bottom: 2rem;
  transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
}
.transaction-main-wrap .new-search-filter-chart-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 3rem;
  margin: 2rem 0rem;
}
@media only screen and (max-width: 42em) {
  .transaction-main-wrap .new-search-filter-chart-box {
    padding: 0rem 1.5rem;
  }
}
.transaction-main-wrap .new-search-filter-chart-box .chart-hide-show-box .hide-show-box {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
@media only screen and (max-width: 42em) {
  .transaction-main-wrap .new-search-filter-chart-box .chart-hide-show-box .hide-show-box {
    display: none;
  }
}
.transaction-main-wrap .new-search-filter-chart-box .chart-hide-show-box .hide-show-box > *:last-child {
  transform: scale(0.8);
}
.transaction-main-wrap .new-search-filter-chart-box .chart-hide-show-box .hide-show-box span {
  width: 1.8rem;
  height: 1.8rem;
}
.transaction-main-wrap .new-search-filter-chart-box .search-filter-box {
  display: flex;
  align-items: stretch;
  gap: 2rem;
  position: relative;
  z-index: 100;
}
@media only screen and (max-width: 42em) {
  .transaction-main-wrap .new-search-filter-chart-box .search-filter-box {
    gap: 1rem;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }
}
.transaction-main-wrap .new-search-filter-chart-box .search-filter-box .badge-filter-option-box {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.transaction-main-wrap .new-search-filter-chart-box .search-filter-box .badge-filter-option-box .option-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
  border-radius: 2rem;
}
.transaction-main-wrap .new-search-filter-chart-box .search-filter-box .badge-filter-option-box .option-box .icon {
  cursor: pointer;
  transform: translateY(0.1rem);
}
.transaction-main-wrap .new-search-filter-chart-box .search-filter-box .export-table-content-box {
  display: grid;
  place-items: center;
  padding: 0.5rem 1.5rem;
  border-radius: 0.8rem;
  cursor: pointer;
}
.transaction-main-wrap .new-search-filter-chart-box .search-filter-box .export-table-content-box:hover .img-box {
  transform: translateY(0.4rem);
}
.transaction-main-wrap .new-search-filter-chart-box .search-filter-box .export-table-content-box .img-box {
  width: 2.2rem;
  height: 2.2rem;
  transition: all 0.3s;
}
.transaction-main-wrap .new-search-filter-chart-box .search-filter-box .export-table-content-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.transaction-main-wrap .new-search-filter-chart-box .search-filter-box .filter-drop-box {
  position: relative;
}
.transaction-main-wrap .new-search-filter-chart-box .search-filter-box .filter-drop-box .drop-box-wrap {
  position: absolute;
  border-radius: 1.2rem;
  top: 110%;
  right: 0;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  min-width: 30rem;
  gap: 2rem;
  animation: moveDownSettingDrop 0.2s ease-in-out backwards;
}
.transaction-main-wrap .new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box {
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 0.8rem;
  cursor: pointer;
}
.transaction-main-wrap .new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .line-box {
  margin: auto 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 1.8rem;
  align-items: center;
}
.transaction-main-wrap .new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .line-box span {
  display: inline-block;
  height: 0.15rem;
  width: 100%;
  border-radius: 1rem;
  background-color: #676767;
}
.transaction-main-wrap .new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .line-box > *:nth-child(2) {
  width: 70%;
}
.transaction-main-wrap .new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .line-box > *:last-child {
  width: 40%;
}
.transaction-main-wrap .new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .text {
  font-weight: 700;
}
.transaction-main-wrap .new-top-chart-box {
  display: flex;
  min-height: 30rem;
  margin-top: 3rem;
}
.transaction-main-wrap .new-top-chart-box .left-box {
  flex: 0 0 70%;
  border-right: 0.1rem solid rgba(204, 204, 204, 0.3);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0rem 3rem;
}
.transaction-main-wrap .new-top-chart-box .left-box .graph-box {
  flex: 1;
}
.transaction-main-wrap .new-top-chart-box .left-box .switch-box {
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 0.1rem solid rgba(204, 204, 204, 0.3);
  display: flex;
  align-items: center;
}
.transaction-main-wrap .new-top-chart-box .left-box .switch-box .switch-wrap {
  align-self: stretch;
  display: flex;
  align-items: flex-end;
  margin-right: auto;
  gap: 2rem;
}
.transaction-main-wrap .new-top-chart-box .left-box .switch-box .switch-wrap .text {
  position: relative;
  cursor: pointer;
}
.transaction-main-wrap .new-top-chart-box .left-box .switch-box .switch-wrap .text > * {
  position: relative;
  z-index: 4;
}
.transaction-main-wrap .new-top-chart-box .left-box .switch-box .switch-wrap .text::after {
  content: "";
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.3rem;
  background-color: #020202;
  border-radius: 3rem;
  transform: translateY(0.9rem);
  visibility: hidden;
}
.transaction-main-wrap .new-top-chart-box .left-box .switch-box .switch-wrap .text-active {
  font-weight: 500;
}
.transaction-main-wrap .new-top-chart-box .left-box .switch-box .switch-wrap .text-active span {
  color: #020202;
}
.transaction-main-wrap .new-top-chart-box .left-box .switch-box .switch-wrap .text-active::after {
  visibility: visible;
}
.transaction-main-wrap .new-top-chart-box .left-box .switch-box .wrap-filter {
  width: 10rem;
  position: relative;
  z-index: 10;
}
.transaction-main-wrap .new-top-chart-box .left-box .switch-box .wrap-filter .react-select-class > *:nth-child(3) {
  height: 3.5rem !important;
}
.transaction-main-wrap .new-top-chart-box .left-box .switch-box .wrap-filter .react-select-class > *:nth-child(4) {
  width: 20rem;
  left: unset;
  right: 0;
}
.transaction-main-wrap .new-top-chart-box .right-box {
  flex: 1;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  padding-left: 3rem;
  padding-right: 3rem;
}
.transaction-main-wrap .new-top-chart-box .right-box > *:not(:last-child) {
  border-bottom: 0.1rem solid rgba(204, 204, 204, 0.3);
}
.transaction-main-wrap .new-top-chart-box .right-box .box {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  justify-content: center;
}
.transaction-main-wrap .new-top-chart-box .right-box .box .label {
  font-size: 1.3rem;
}
.transaction-main-wrap .new-top-chart-box .right-box .box .value {
  font-weight: 700;
  font-size: 2rem;
}
.transaction-main-wrap .top-chart-box {
  padding: 2rem 3rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.transaction-main-wrap .top-chart-box .metric-select-box {
  display: flex;
}
.transaction-main-wrap .top-chart-box .metric-select-box .metrics-box {
  flex: 0 0 85%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.5rem;
}
.transaction-main-wrap .top-chart-box .metric-select-box .metrics-box .card-metrics {
  filter: drop-shadow(0px 1px 2px rgba(51, 51, 51, 0.06)) drop-shadow(0px 0px 2px rgba(51, 51, 51, 0.02));
  border-radius: 0.8rem;
  border: 0.1rem solid #f0eeff;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.transaction-main-wrap .top-chart-box .metric-select-box .metrics-box .card-metrics .text-value-box {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.transaction-main-wrap .top-chart-box .metric-select-box .metrics-box .card-metrics .text-value-box .text {
  font-size: 1.35rem;
}
.transaction-main-wrap .top-chart-box .metric-select-box .metrics-box .card-metrics .text-value-box .value {
  font-weight: 600;
  font-size: 2.2rem;
  letter-spacing: -0.05rem;
}
.transaction-main-wrap .top-chart-box .metric-select-box .metrics-box .card-metrics .chart-box-wrap {
  width: 5.5rem;
  height: 5.5rem;
  position: relative;
}
.transaction-main-wrap .top-chart-box .metric-select-box .metrics-box .card-metrics .chart-box-wrap .img-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2rem;
  height: 2rem;
}
.transaction-main-wrap .top-chart-box .metric-select-box .metrics-box .card-metrics .chart-box-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.transaction-main-wrap .top-chart-box .metric-select-box .select-filter-box {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.transaction-main-wrap .top-chart-box .metric-select-box .select-filter-box .wrap-filter {
  width: 10rem;
  position: relative;
  z-index: 10;
}
.transaction-main-wrap .top-chart-box .metric-select-box .select-filter-box .wrap-filter .react-select-class > *:nth-child(4) {
  width: 20rem;
  left: unset;
  right: 0;
}
.transaction-main-wrap .top-chart-box .bar-chat-box {
  width: 100%;
  height: 22rem;
}
.transaction-main-wrap .title-sort-text-box {
  display: flex;
  align-items: center;
  padding: 1rem 3rem;
  margin: 1rem 0rem;
}
@media only screen and (max-width: 58em) {
  .transaction-main-wrap .title-sort-text-box {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 42em) {
  .transaction-main-wrap .title-sort-text-box {
    margin: 0rem;
    padding: 0rem 1.5rem;
  }
  .transaction-main-wrap .title-sort-text-box form {
    width: 100%;
  }
}
.transaction-main-wrap .title-sort-text-box .date-picker-wrap {
  position: relative;
}
.transaction-main-wrap .title-sort-text-box .date-picker-wrap .select-custom-wrap {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1rem 0.8rem rgba(218, 218, 218, 0.3);
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.8s;
  -moz-transition: max-height 0.8s;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.transaction-main-wrap .title-sort-text-box .date-picker-wrap .select-custom-wrap .title {
  font-weight: 600;
}
.transaction-main-wrap .title-sort-text-box .date-picker-wrap .select-custom-wrap .btn-reuse {
  margin-top: 0.5rem;
}
.transaction-main-wrap .title-sort-text-box .date-picker-wrap .select-custom-wrap .input-date-group {
  display: flex;
  flex-direction: column;
}
.transaction-main-wrap .title-sort-text-box .date-picker-wrap .select-custom-wrap .input-date-group .label {
  text-align: left;
  align-self: flex-start;
  text-transform: capitalize;
  font-size: 1.35rem;
  margin-bottom: 0.4rem;
}
.transaction-main-wrap .title-sort-text-box .date-picker-wrap .select-custom-wrap .input-date-group .input-calender {
  padding: 1.4rem !important;
}
.transaction-main-wrap .title-sort-text-box .date-picker-wrap .select-custom-wrap-show {
  max-height: 40rem;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
  border: 0.1rem solid #cccccc;
  padding: 1rem;
}
.transaction-main-wrap .title-sort-text-box .search-group {
  margin-right: auto;
}
@media only screen and (max-width: 57em) {
  .transaction-main-wrap .title-sort-text-box .search-group {
    margin-bottom: 1.5rem;
    margin-right: unset;
    width: 100%;
  }
}
.transaction-main-wrap .title-sort-text-box .title-text {
  margin-right: auto;
}
.transaction-main-wrap .title-sort-text-box .title-text .text {
  font-size: 1.25rem;
}
.transaction-main-wrap .title-sort-text-box .title-text .title {
  font-weight: 700;
  font-size: 1.5rem;
}
.transaction-main-wrap .title-sort-text-box .fitler-box {
  display: grid;
  grid-template-columns: 1fr 1fr max-content;
  grid-gap: 2rem;
  align-items: center;
  margin-left: auto;
}
@media only screen and (max-width: 57em) {
  .transaction-main-wrap .title-sort-text-box .fitler-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .transaction-main-wrap .title-sort-text-box .fitler-box .date-picker-wrap {
    width: 100%;
  }
  .transaction-main-wrap .title-sort-text-box .fitler-box .date-picker-wrap .form-group {
    width: 100%;
  }
  .transaction-main-wrap .title-sort-text-box .fitler-box .date-picker-wrap .form-group .react-select-class {
    width: 100%;
  }
  .transaction-main-wrap .title-sort-text-box .fitler-box .date-picker-wrap .form-group .react-select-class > *:nth-child(3) {
    min-width: unset;
    width: 100%;
  }
  .transaction-main-wrap .title-sort-text-box .fitler-box .date-picker-wrap .form-group .react-select-class > *:nth-child(4) {
    left: unset;
    right: 0;
  }
  .transaction-main-wrap .title-sort-text-box .fitler-box .form-group {
    width: 100%;
  }
  .transaction-main-wrap .title-sort-text-box .fitler-box .form-group .react-select-class {
    width: 100%;
  }
  .transaction-main-wrap .title-sort-text-box .fitler-box .form-group .react-select-class > *:nth-child(3) {
    min-width: unset;
    width: 100%;
  }
  .transaction-main-wrap .title-sort-text-box .fitler-box .form-group .react-select-class > *:nth-child(4) {
    left: unset;
    right: 0;
  }
}
.transaction-main-wrap .title-sort-text-box .fitler-box .reset-text {
  color: var(--primary-purple);
  font-weight: 600;
  cursor: pointer;
}
@media only screen and (max-width: 57em) {
  .transaction-main-wrap .title-sort-text-box .fitler-box .reset-text {
    width: -moz-max-content;
    width: max-content;
  }
}
@media only screen and (max-width: 51em) {
  .transaction-main-wrap .title-sort-text-box .fitler-box .reset-text {
    display: none;
  }
}
.transaction-main-wrap .title-sort-text-box .fitler-box .react-select-class > *:nth-child(3) {
  min-width: 25rem;
}
@media only screen and (max-width: 57em) {
  .transaction-main-wrap .title-sort-text-box .fitler-box .react-select-class > *:nth-child(3) {
    min-width: unset;
    width: -moz-max-content;
    width: max-content;
  }
}
.transaction-main-wrap .title-sort-text-box .fitler-box .react-select-class > *:nth-child(4) {
  left: unset;
  right: 0;
}
.transaction-main-wrap .table-wrap {
  padding-bottom: 8rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  z-index: 3;
  flex: 1;
  overflow-y: scroll;
}
.transaction-main-wrap .table-wrap::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 48em) {
  .transaction-main-wrap .table-wrap {
    padding-bottom: 0rem;
    width: 90%;
    margin: 3rem auto 0rem auto;
  }
}
@media only screen and (max-width: 51em) {
  .transaction-main-wrap .table-wrap .wrap-scroll {
    overflow-x: scroll;
    width: 100%;
    padding-bottom: 3rem;
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
  .transaction-main-wrap .table-wrap .wrap-scroll::-webkit-scrollbar:vertical {
    display: none;
  }
  .transaction-main-wrap .table-wrap .wrap-scroll::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.4rem;
  }
  .transaction-main-wrap .table-wrap .wrap-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }
  .transaction-main-wrap .table-wrap .wrap-scroll::-webkit-scrollbar-thumb {
    background: #020202;
    border-radius: 30rem;
  }
  .transaction-main-wrap .table-wrap .wrap-scroll::-webkit-scrollbar-thumb:hover {
    background: #020202;
  }
}
@media only screen and (max-width: 51em) {
  .transaction-main-wrap .table-wrap .wrap-scroll .table {
    width: 95rem;
  }
}
@media only screen and (max-width: 48em) {
  .transaction-main-wrap .table-wrap .wrap-scroll {
    display: none;
  }
}
.transaction-main-wrap .table-wrap .table-pagination-box {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  padding: 0rem 3rem;
}
.transaction-main-wrap .table-wrap .table-body tr td {
  padding-top: 1.8rem !important;
  padding-bottom: 1.8rem !important;
  font-size: 1.45rem !important;
}
.transaction-main-wrap .table-wrap .table-body tr > *:first-child {
  padding-left: 3rem;
  width: 40%;
  padding-right: 2rem;
}
.transaction-main-wrap .table-wrap .table-body tr > *:last-child {
  padding-right: 3rem;
}
.transaction-main-wrap .table-wrap .table-body tr .action-box {
  justify-content: center;
}
.transaction-main-wrap .table-wrap .table-body tr .status-type-wrap {
  background-color: #e9f5ff;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #476885;
}
.transaction-main-wrap .table-wrap .table-body tr .status-type-wrap span {
  font-weight: 400 !important;
  font-size: 1.2rem;
}
.transaction-main-wrap .table-wrap .table-body tr .status-type-wrap-fail {
  background-color: #fff5f5;
  color: #ff0f00;
}
.transaction-main-wrap .table-wrap .table-body tr .status-type-wrap-success {
  background-color: #e8fff6;
  color: #1ace37;
}
.transaction-main-wrap .table-wrap .table-body tr .status-type-wrap-pending {
  background-color: #fff6ed;
  color: #ea872d;
}
.transaction-main-wrap .table-wrap .transaction-action-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0.45rem 0rem;
}
.transaction-main-wrap .table-wrap .transaction-action-wrap .img-box {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: transform 0.3s ease-in-out;
}
.transaction-main-wrap .table-wrap .transaction-action-wrap .img-box:hover {
  transform: scale(1.05);
}
.transaction-main-wrap .table-wrap .transaction-action-wrap .img-box .img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
.transaction-main-wrap .table-wrap .transaction-action-wrap > *:nth-child(2) .img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
.transaction-main-wrap .table-wrap .type-wrap {
  padding: 0.8rem 1.2rem;
  background-color: #f7f8f7;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
}
.transaction-main-wrap .table-wrap .type-wrap span {
  font-size: 1.2rem;
}
.transaction-main-wrap .table-wrap .type-text-box {
  display: flex;
  align-items: center;
  width: -moz-max-content;
  width: max-content;
}
.transaction-main-wrap .table-wrap .type-text-box .text-box {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}
.transaction-main-wrap .table-wrap .type-text-box .text-box .text {
  font-size: 1.55rem;
  font-weight: 600;
}
.transaction-main-wrap .table-wrap .type-text-box .text-box .text-small {
  font-size: 1.3rem;
  font-weight: 400 !important;
}
.transaction-main-wrap .table-wrap .type-text-box .img-wrap {
  display: grid;
  place-items: center;
}
.transaction-main-wrap .table-wrap .type-text-box .img-wrap .img-box-cre-deb {
  width: 3rem;
  height: 3rem;
  margin-right: 2rem;
  background-color: #1ace37 !important;
  border-radius: 50%;
  display: grid;
  place-items: center;
  flex: 0 0 5%;
}
.transaction-main-wrap .table-wrap .type-text-box .img-wrap .img-box-cre-deb .img {
  width: 35%;
  height: 35%;
  -o-object-fit: contain;
     object-fit: contain;
  filter: brightness(1000%);
}
.transaction-main-wrap .table-wrap .type-text-box .img-wrap .img-box-debit {
  background-color: #ff0f00 !important;
}

.transaction-download-receipt-modal .wrap-modal::-webkit-scrollbar {
  display: none;
}
.transaction-download-receipt-modal .wrap-modal .title {
  font-size: 1.6rem;
  font-weight: 700;
}
.transaction-download-receipt-modal .wrap-modal .underline {
  width: 100%;
  height: 0.1rem;
  background-color: #f0eeff;
  margin: 1.3rem 0rem;
}
.transaction-download-receipt-modal .label-value-status-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
}
.transaction-download-receipt-modal .label-value-status-box .box {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}
.transaction-download-receipt-modal .label-value-status-box .box .label-download {
  flex: 0 0 40%;
}
.transaction-download-receipt-modal .label-value-status-box .box .value-download {
  flex: 1;
  font-weight: 600;
}
.transaction-download-receipt-modal .label-value-status-box .box .value-download-two {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.transaction-download-receipt-modal .label-value-status-box .box .value-download-two .avatar-box {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  filter: drop-shadow(0px 1px 2px rgba(51, 51, 51, 0.06)) drop-shadow(0px 0px 2px rgba(51, 51, 51, 0.02));
  background-color: #ffffff;
  display: grid;
  place-items: center;
  position: relative;
}
@media only screen and (max-width: 48em) {
  .transaction-download-receipt-modal .label-value-status-box .box .value-download-two .avatar-box {
    width: 4rem;
    height: 4rem;
  }
}
.transaction-download-receipt-modal .label-value-status-box .box .value-download-two .avatar-box .img-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.transaction-download-receipt-modal .label-value-status-box .box .value-download-two .avatar-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.transaction-download-receipt-modal .label-value-status-box .box .value-download-two .copied-box-reciept {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  position: relative;
  background-color: black;
}
.transaction-download-receipt-modal .label-value-status-box .box .value-download-two .copied-box-reciept .icon {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #020202;
}
.transaction-download-receipt-modal .label-value-status-box .box .value-download-two .img-box-reciept {
  cursor: pointer;
  display: grid;
  place-items: center;
  transition: all 0.3s;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.transaction-download-receipt-modal .label-value-status-box .box .value-download-two .img-box-reciept .img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
.transaction-download-receipt-modal .label-value-status-box .box .value-download-two .img-box-reciept:hover {
  transform: scale(1.05);
}
.transaction-download-receipt-modal .label-value-status-box .box .status-type-wrap {
  background-color: #e9f5ff;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #476885;
  text-transform: capitalize;
}
.transaction-download-receipt-modal .label-value-status-box .box .status-type-wrap span {
  font-size: 1.2rem;
}
.transaction-download-receipt-modal .label-value-status-box .box .status-type-wrap-fail {
  background-color: #fff5f5;
  color: #ff0f00;
}
.transaction-download-receipt-modal .label-value-status-box .box .status-type-wrap-success {
  background-color: #e8fff6;
  color: #1ace37;
}
.transaction-download-receipt-modal .label-value-status-box .box .status-type-wrap-pending {
  background-color: #fff6ed;
  color: #ea872d;
}
.transaction-download-receipt-modal .label-value-status-box .box .status-type-wrap-disabled {
  background-color: #f7f8f7;
}
.transaction-download-receipt-modal .two-button-wrap {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #f7f8f7;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  padding: 2rem 2rem;
  gap: 2rem;
}
.transaction-download-receipt-modal .two-button-wrap .raven-btn {
  width: 100%;
}

.two-line-transaction-box-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
}
.two-line-transaction-box-wrap .top-label-box {
  display: flex;
  align-items: center;
  padding: 0rem 3rem;
}
@media only screen and (max-width: 42em) {
  .two-line-transaction-box-wrap .top-label-box {
    gap: 1rem;
    padding: 0rem 1.5rem;
    flex-direction: column;
    align-items: flex-start;
  }
}
.two-line-transaction-box-wrap .top-label-box .box-hide-mobile {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  margin-left: auto;
}
@media only screen and (min-width: 42em) {
  .two-line-transaction-box-wrap .top-label-box .box-hide-mobile {
    display: none;
  }
}
@media only screen and (max-width: 42em) {
  .two-line-transaction-box-wrap .top-label-box .box-hide-mobile {
    display: flex !important;
  }
}
.two-line-transaction-box-wrap .top-label-box .box-hide-mobile > *:last-child {
  transform: scale(0.65);
}
.two-line-transaction-box-wrap .top-label-box .date-box {
  margin-right: 2rem;
}
.two-line-transaction-box-wrap .top-label-box .expense-income-label {
  display: flex;
  align-items: center;
  gap: 3rem;
}
@media only screen and (max-width: 42em) {
  .two-line-transaction-box-wrap .top-label-box .expense-income-label {
    display: none;
  }
}
.two-line-transaction-box-wrap .top-label-box .expense-income-label .box {
  display: flex;
  align-items: center;
}
.two-line-transaction-box-wrap .top-label-box .expense-income-label .box span {
  display: inline-block;
  width: 0.9rem;
  height: 0.9rem;
}
.two-line-transaction-box-wrap .top-label-box .expense-income-label .box .text {
  font-size: 1.2rem;
  margin-left: 0.7rem;
}
.two-line-transaction-box-wrap .top-label-box .expense-income-label .hide-show-box {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
@media only screen and (max-width: 42em) {
  .two-line-transaction-box-wrap .top-label-box .expense-income-label .hide-show-box {
    display: none;
  }
}
.two-line-transaction-box-wrap .top-label-box .expense-income-label .hide-show-box > *:last-child {
  transform: scale(0.8);
}
.two-line-transaction-box-wrap .top-label-box .expense-income-label .hide-show-box span {
  width: 1.8rem;
  height: 1.8rem;
}
.two-line-transaction-box-wrap .top-label-box .expense-income-label .income span {
  background-color: #1ace37;
}
.two-line-transaction-box-wrap .top-label-box .expense-income-label .expense span {
  background-color: #ff0f00;
}
.two-line-transaction-box-wrap .top-label-box .wrap-filter {
  width: 10rem;
  position: relative;
  z-index: 10;
  margin-right: auto;
}
@media only screen and (max-width: 42em) {
  .two-line-transaction-box-wrap .top-label-box .wrap-filter {
    display: none;
  }
}
.two-line-transaction-box-wrap .top-label-box .wrap-filter .react-select-class > *:nth-child(3) {
  padding: 0rem;
  flex: unset;
  justify-content: space-evenly;
}
.two-line-transaction-box-wrap .top-label-box .wrap-filter .react-select-class > *:nth-child(3) > *:first-child {
  padding-right: 0rem;
  flex: unset;
}
.two-line-transaction-box-wrap .top-label-box .wrap-filter .react-select-class > *:nth-child(3) > *:nth-child(2) > *:nth-child(2) {
  padding: unset;
}
.two-line-transaction-box-wrap .top-label-box .wrap-filter .react-select-class > *:nth-child(4) {
  left: 0;
}
.two-line-transaction-box-wrap .chart-wrap-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-bottom: 0.1rem solid #f0eeff;
  padding-bottom: 1.5rem;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.8s;
  -moz-transition: max-height 0.8s;
  width: 100%;
}
@media only screen and (max-width: 42em) {
  .two-line-transaction-box-wrap .chart-wrap-box {
    display: none;
  }
}
.two-line-transaction-box-wrap .chart-wrap-box .chart-wrap {
  height: 20rem;
  background-image: url("../../../assets/two-line-chart-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 2rem 3rem;
}
.two-line-transaction-box-wrap .chart-wrap-box .label-wrap {
  padding: 0rem 3rem;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.two-line-transaction-box-wrap .chart-wrap-box .label-wrap .text {
  font-size: 1.2rem;
}
.two-line-transaction-box-wrap .chart-wrap-box-show {
  max-height: 30rem;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
}
.two-line-transaction-box-wrap .metrics-wrap-box {
  display: flex;
  padding: 0rem 3rem;
  border-bottom: 0.1rem solid #f0eeff;
}
@media only screen and (max-width: 58em) {
  .two-line-transaction-box-wrap .metrics-wrap-box {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media only screen and (max-width: 42em) {
  .two-line-transaction-box-wrap .metrics-wrap-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    overflow-x: hidden;
    justify-items: center;
    margin: 2rem 0rem;
  }
  .two-line-transaction-box-wrap .metrics-wrap-box > *:first-child {
    grid-row: 1/span 1;
    grid-column: 1/-1;
    width: 100%;
    justify-content: center;
  }
  .two-line-transaction-box-wrap .metrics-wrap-box > *:nth-child(2) {
    border-bottom: unset !important;
    border-right: 0.1rem solid #f0eeff !important;
  }
}
.two-line-transaction-box-wrap .metrics-wrap-box > *:not(:last-child) {
  border-right: 0.1rem solid #f0eeff;
  padding-right: 4rem !important;
  margin-right: 2rem;
}
@media only screen and (max-width: 58em) {
  .two-line-transaction-box-wrap .metrics-wrap-box > *:not(:last-child) {
    border-bottom: 0.1rem solid #f0eeff;
    margin-right: unset;
    border-right: unset;
  }
}
@media only screen and (max-width: 58em) and (max-width: 42em) {
  .two-line-transaction-box-wrap .metrics-wrap-box > *:not(:last-child) {
    margin-right: unset;
    padding-right: unset;
  }
}
.two-line-transaction-box-wrap .metrics-wrap-box .card-metrics {
  padding: 1.5rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
@media only screen and (max-width: 42em) {
  .two-line-transaction-box-wrap .metrics-wrap-box .card-metrics {
    gap: 1rem;
    padding: 1rem 0.5rem;
  }
}
.two-line-transaction-box-wrap .metrics-wrap-box .card-metrics .text-value-box {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.two-line-transaction-box-wrap .metrics-wrap-box .card-metrics .text-value-box .text {
  font-size: 1.2rem;
}
@media only screen and (max-width: 42em) {
  .two-line-transaction-box-wrap .metrics-wrap-box .card-metrics .text-value-box .text {
    font-size: 1rem;
  }
}
.two-line-transaction-box-wrap .metrics-wrap-box .card-metrics .text-value-box .value {
  font-weight: 500;
  font-size: 2.5rem;
  letter-spacing: -0.05rem;
}
@media only screen and (max-width: 42em) {
  .two-line-transaction-box-wrap .metrics-wrap-box .card-metrics .text-value-box .value {
    font-size: 1.45rem;
    letter-spacing: -0.015rem;
    font-weight: 700;
  }
}
.two-line-transaction-box-wrap .metrics-wrap-box .card-metrics .chart-box-wrap {
  width: 4.5rem;
  height: 4.5rem;
  position: relative;
}
@media only screen and (max-width: 42em) {
  .two-line-transaction-box-wrap .metrics-wrap-box .card-metrics .chart-box-wrap {
    width: 3.4rem;
    height: 3.4rem;
  }
}
.two-line-transaction-box-wrap .metrics-wrap-box .card-metrics .chart-box-wrap .img-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.6rem;
  height: 1.6rem;
}
@media only screen and (max-width: 42em) {
  .two-line-transaction-box-wrap .metrics-wrap-box .card-metrics .chart-box-wrap .img-box {
    width: 1.2rem;
    height: 1.2rem;
    transform: translateY(-60%) translateX(-50%);
  }
}
.two-line-transaction-box-wrap .metrics-wrap-box .card-metrics .chart-box-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
@media only screen and (max-width: 42em) {
  .two-line-transaction-box-wrap .metrics-box-mobile {
    max-height: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
    -webkit-transition: max-height 0.8s;
    -moz-transition: max-height 0.8s;
    width: 100%;
  }
}
@media only screen and (max-width: 42em) {
  .two-line-transaction-box-wrap .metrics-box-mobile-show {
    max-height: 30rem;
    transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
  }
}

.bulk-transaction-main-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.bulk-transaction-main-wrap .export-table-content-box {
  display: flex;
  align-items: center;
  padding: 1.2rem 1.5rem;
  border-radius: 0.8rem;
  gap: 1rem;
  cursor: pointer;
  align-self: flex-end;
  margin: 2rem;
}
.bulk-transaction-main-wrap .export-table-content-box:hover .img-box {
  transform: translateY(0.4rem);
}
.bulk-transaction-main-wrap .export-table-content-box .img-box {
  width: 2.2rem;
  height: 2.2rem;
  transition: all 0.3s;
}
.bulk-transaction-main-wrap .export-table-content-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.bulk-transaction-main-wrap .transaction-action-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.bulk-transaction-main-wrap .transaction-action-wrap .img-box {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: transform 0.3s ease-in-out;
}
.bulk-transaction-main-wrap .transaction-action-wrap .img-box:hover {
  transform: scale(1.05);
}
.bulk-transaction-main-wrap .transaction-action-wrap .img-box .img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
.bulk-transaction-main-wrap .transaction-action-wrap > *:nth-child(2) .img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=DashboardTransaction.css.map */