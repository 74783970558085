.send-money-global-account-index-box {
  flex: 1;
  display: flex;
  // background-color: red;
  flex-direction: column;
  margin-top: 3rem;

  @media only screen and (max-width: 48em) {
    // width: 90%;
    min-height: 45rem;
  }

  .select-form-wrap-box {
    display: flex;
    // background-color: red;
    flex-direction: column;
    width: 40rem;
    align-self: center;
    position: relative;

    @media only screen and (max-width: 48em) {
      width: 90%;
      // min-height: 45rem !important;
    }

    .select-option-global-wrap-box {
      align-self: center;
      display: flex;
      align-items: center;
      padding: 0.8rem 1.6rem;
      border-radius: 4rem;
      grid-gap: 2rem;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .select-item {
        position: relative;
        padding: 1rem 2rem;
        display: flex;
        justify-content: center;
        border-radius: 4rem;

        & > * {
          position: relative;
          z-index: 2;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          visibility: hidden;
          background-color: #ffffff;
          border-radius: 4rem;
          z-index: 1;
        }
      }

      .select-item-active {
        &::after {
          visibility: visible;
        }

        .text {
          font-weight: 600;
          color: #020202;
        }
      }
    }

    .form-box-wrap {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin-top: 4rem;

      //   .global-amount-box {
      //     margin-top: 2rem;
      //     display: flex;
      //     // align-items: center;
      //     width: 100%;
      //     border-width: 0.15rem;
      //     position: relative;
      //     border-radius: 1.2rem;
      //     padding-left: 1.2rem;
      //     // gap: 1rem;

      //     .form-group__black-light {
      //       position: unset;

      //       .form-label {
      //         position: absolute;
      //         top: 0;
      //         left: 0;
      //         transform: translateY(-110%);
      //         width: 100%;
      //       }

      //       .form-input {
      //         border: none !important;
      //       }
      //     }

      //     .select-drop-box-wrapper {
      //       position: relative;
      //       //   margin-bottom: -0.5rem;
      //       padding-right: 1rem;
      //       display: grid;
      //       place-items: center;
      //       border-width: .15rem !important;

      //       .select-drop-box {
      //         display: flex;
      //         align-items: center;
      //         gap: 0.5rem;
      //         //   padding: 1rem 1.5rem;
      //         //   border-radius: 5rem;

      //         .value {
      //           font-weight: 500;
      //         }

      //         .img-select {
      //           .img-box {
      //             width: 2.5rem;
      //             height: 2.5rem;

      //             .img {
      //               width: 100%;
      //               height: 100%;
      //               object-fit: contain;
      //             }
      //           }
      //         }

      //         .drop-icon {
      //           .img-box {
      //             width: 1.7rem;
      //             height: 1.7rem;

      //             .img {
      //               width: 100%;
      //               height: 100%;
      //               object-fit: contain;
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }

      .select-add-receipient-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .add {
          color: rgba(117, 90, 226, 1);
          cursor: pointer;
        }
      }

      .select-global-recipient {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        max-height: 35rem;
        overflow-y: scroll;
        margin-top: 1rem;

        &::-webkit-scrollbar {
          display: none;
        }

        .select-item {
          display: flex;
          align-items: center;
          padding: 1rem;
          border-radius: 0.8rem;
          border-width: 0.15rem !important;
          //   border: 0.2rem solid rgba(238, 238, 238, 1);
          cursor: pointer;

          .name-num-box {
            margin-left: 1.5rem;
            .name {
              // color: #020202 !;
              display: flex;
              align-items: center;
              font-weight: 700;
              font-size: 1.35rem;
              line-height: 1.75rem;

              .status-type-wrap {
                background-color: #fff6ed;
                border-radius: 3rem;
                width: -moz-max-content;
                width: max-content;
                padding: 0.8rem 1.2rem;
                color: #ea872d;
                margin-left: 2rem;

                span {
                  font-size: 1.2rem;
                }
              }
            }

            .number {
              font-size: 1.2rem;
            }
          }

          .check-wrap {
            margin-left: auto;
            // border: 0.2rem solid rgba(238, 238, 238, 1);
            border-radius: 50%;
            width: 2.5rem;
            height: 2.5rem;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              width: 40%;
              height: 40%;
              border-radius: 50%;
              transform: translate(-50%, -50%);
              background-color: rgba(255, 255, 255, 1);
            }
          }

          .check-wrap-active {
            background-color: rgba(117, 90, 226, 1);
          }

          .img-avatar-wrap {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            overflow: hidden;
            // margin-right: 1.5rem;
            background-color: rgba(238, 238, 238, 1);

            .img-box {
              width: 100%;
              height: 100%;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .avatar-box {
              width: 100%;
              height: 100%;
              //   background-color: #020202;
              display: flex;
              justify-content: center;
              align-items: center;

              span {
                font-weight: 700;
                font-size: 1.2rem;
                // color: #ffffff;
                display: inline-block;
              }
            }

            .avatar-box-two {
              background-color: unset;
            }
          }
        }

        // .select-item-two{
        //   border-color: unset !important;

        //   .name{
        //     color: unset;
        //   }
        // }
      }

      //   .select-global-recipient-in-app{
      //     // height: 35rem;
      //     // background-color: red;
      //     // margin-bottom: 2.5rem;
      //     // padding-bottom: 2rem;

      //     .select-item{
      //       //  border: ;

      //       .img-avatar-wrap{
      //         // background-color: #020202 !important;

      //         // .img-box{
      //         //   background-color: #020202 !important;
      //         // }

      //         // .avatar-box{
      //         //   background-color: #020202 !important;
      //         // }
      //       }

      //     }
      //   }
    }

    .two-amount-global-box {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      position: relative;
      z-index: 2;
      .amount-grey-wrap-box {
        padding: 1.5rem 2rem;
        border-radius: 1.2rem;

        // .global-amount-input-box{
        //     // back
        // }
      }

      .swap-global-icon-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        display: grid;
        place-items: center;
        z-index: 3;

        .img-box {
          width: 50%;
          height: 50%;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .swap-fee-exchange-rate-box {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      //   width: max-content;
      //   margin-left: auto;
      padding-left: 5rem;

      & > * {
        position: relative;
        z-index: 3;
      }

      &::after {
        content: "";
        position: absolute;
        width: 0.15rem;
        height: 97%;
        left: 3.5rem;
        top: 0%;
        background-color: rgba(227, 227, 227, 1);
        z-index: 0;
      }

      .label-value-dot-box {
        display: flex;
        align-items: center;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 1.5rem;
          height: 1.5rem;
          left: 0%;
          top: 50%;
          transform: translateY(-50%) translateX(-150%);
          background-color: rgba(227, 227, 227, 1);
          z-index: 2;
          border-radius: 50%;
        }

        &::before {
          content: "";
          position: absolute;
          width: 0.8rem;
          height: 0.8rem;
          left: 0%;
          top: 50%;
          transform: translateY(-50%) translateX(-238%);
          // background-color: #ffffff;
          z-index: 3;
          border-radius: 50%;
        }

        .text {
          display: flex;
          align-items: center;
          font-size: 1.55rem;

          .value {
            font-weight: 500;
          }
        }
      }
    }
  }

  .select-form-wrap-box-two {
    width: 45rem;

    @media only screen and (max-width: 48em) {
      width: 90%;
    }
  }

  .content-box-two {
    width: 100%;
    padding: 2.5rem;
    border-width: 0.15rem;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    align-items: stretch;
    border-radius: 1.2rem;

    @media only screen and (max-width: 48em) {
      padding: 1.5rem 1rem;
    }

    .add-money-item {
      align-self: stretch;
      border-width: 0.15rem;
      padding-bottom: 1.5rem;

      .top-box {
        display: flex;
        align-items: center;
        cursor: pointer;

        .title-text-box {
          .title {
            font-size: 1.45rem;
            // color: #020202;
            font-weight: 700;
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;

            .title-total {
              padding: 0.4rem 1rem;
              border-radius: 1rem;
              display: grid;
              place-items: center;

              span {
                display: inline-block;
                color: #ffffff !important;
                font-size: 1.1rem;
              }
            }

            @media only screen and (max-width: 42em) {
              font-size: 1.35rem;
            }
          }

          .text {
            margin-top: 0.3rem;
            color: #676767;
            font-size: 1.25rem;

            @media only screen and (max-width: 42em) {
              font-size: 1.15rem;
              width: 80%;
            }
          }
        }

        .img-wrap {
          margin-right: 1.5rem;
          .img-box {
            width: 4.5rem;
            height: 4.5rem;
            border-radius: 50%;
            display: grid;
            place-items: center;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .arrow-coming-soon-box {
          margin-left: auto;
          position: relative;

          .img-box-arrow {
            width: 2.5rem;
            height: 2.5rem;
            transform: rotate(-90deg);
            transition: all 0.3s ease-in-out;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .img-box-arrow-rotate {
            transform: unset;
          }

          .coming-soon {
            position: absolute;
            top: -3rem;
            right: 0;
          }
        }
      }

      .content-display-box {
        max-height: 0;
        overflow-x: hidden;
        overflow-y: hidden;
        transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        width: 100%;

        .display-wrap {
          padding: 2rem;
          display: flex;
          align-items: center;
          border-radius: 1.2rem;

          @media only screen and (max-width: 48em) {
            flex-direction: column;
            align-items: flex-start;

            .copy-box {
              margin-left: unset !important;
              margin-top: 1.5rem;
            }
          }

          .copy-box {
            margin-left: auto;
            display: flex;
            align-items: center;
            gap: 0.4rem;
            padding: 0.7rem 2rem;
            border-radius: 3rem;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
              transform: scale(1.01);
            }

            .img-box {
              width: 1.5rem;
              height: 1.5rem;

              .img {
                width: 100%;
                height: 100%;
                color: rgba(117, 90, 226, 1);
              }

              .icon {
                width: 1.3rem;
                height: 1.3rem;
              }
            }

            span {
              color: rgba(117, 90, 226, 1);
              font-weight: 500;
            }
          }

          .info-box {
            display: flex;
            flex-direction: column;
            gap: 0.4rem;

            .bank-info {
              display: flex;
              align-items: center;
              gap: 0.5rem;
            }

            .acc_num {
              font-weight: 600;
              font-size: 1.7rem;
            }
          }
        }

        .select-option-link-box {
          display: flex;
          align-items: center;
          gap: 3rem;
          // background-color: red;

          .radio-label-item {
            display: flex;
            align-items: center;
            gap: 1rem;

            .label {
              cursor: pointer;
            }
          }
        }

        .form-box {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          margin-top: 3rem;
          //   padding-bottom: 10rem;

          .edit-amount-check-wrap {
            display: flex;
            align-items: center;
            gap: 0.8rem;
            margin-top: -1rem;

            .text {
              font-size: 1.3rem;
            }
          }
        }
      }

      .content-display-box-show {
        max-height: 15rem;
        transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        padding-top: 1.5rem;
      }

      .content-display-box-show-two {
        max-height: 65rem;
        margin-bottom: 10rem;
      }
    }
  }

  .wrap-coming-soon {
    opacity: 0.5;
    cursor: default;

    @media only screen and (max-width: 42em) {
      flex-direction: column;
      padding: 1rem;
    }

    // .content-box-two {
    .add-money-item {
      .top-box {
        cursor: not-allowed;
        @media only screen and (max-width: 42em) {
          flex-direction: column;
          align-items: flex-start;

          & > *:last-child {
            margin-left: unset;
            align-self: flex-end;
            margin-top: 1rem;
          }
        }

        .text {
          margin-top: 0.3rem;
          color: #676767;
          font-size: 1.25rem;

          @media only screen and (max-width: 42em) {
            font-size: 1.15rem;
            width: 100%;
          }
        }
      }
    }
  }
}
