.invoice-wrap-box {
  width: 100%;
  display: flex;
  height: 100%;
}
@media only screen and (max-width: 55em) {
  .invoice-wrap-box {
    flex-direction: column;
  }
}
.invoice-wrap-box .left-box {
  flex: 0 0 60%;
  border-right: 0.1rem solid rgba(204, 204, 204, 0.5);
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem;
}
@media only screen and (max-width: 55em) {
  .invoice-wrap-box .left-box {
    flex: 0 0 25%;
    padding: 0rem 1rem;
  }
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap {
  flex: 1;
  overflow-y: scroll;
  width: 100%;
  border-radius: 2.4rem;
  min-height: 45rem;
  margin: 2rem auto;
  padding: 3rem 0rem;
  display: flex;
  flex-direction: column;
  border: unset;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 55em) {
  .invoice-wrap-box .left-box .invoice-preview-detail-wrap {
    padding-bottom: 1.5rem;
    margin-bottom: unset;
  }
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap::-webkit-scrollbar:horizontal {
  display: none;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap::-webkit-scrollbar {
  width: 0.5rem;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 30rem;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .box {
  display: flex;
  padding: 2rem 2.5rem;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 55em) {
  .invoice-wrap-box .left-box .invoice-preview-detail-wrap .box {
    padding: 1.5rem;
  }
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .box .left-box {
  flex: 0 0 40%;
  border-right: unset;
  padding-top: 0rem;
}
@media only screen and (max-width: 55em) {
  .invoice-wrap-box .left-box .invoice-preview-detail-wrap .box .left-box {
    flex: 0 0 25%;
  }
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .box .left-box .label {
  font-weight: 700;
  font-size: 1.25rem;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .box .left-box .value {
  font-size: 1.35rem;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .box .right-box {
  flex: 1;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .box .right-box .label {
  font-weight: 700;
  font-size: 1.35rem;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .box .right-box .value {
  font-size: 1.45rem;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .top-info .left-box .value {
  margin-top: 0.6rem;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .top-info .right-box .value {
  margin-top: 0.6rem;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .detail-info .right-box {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .detail-info .right-box > *:last-child {
  text-align: right;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .detail-info .right-box > *:nth-child(2) {
  text-align: center;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .middle-info .left-box .label {
  margin-bottom: 1.5rem;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .middle-info .left-box .value {
  margin-top: 0.8rem;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .middle-info .right-box .label {
  margin-bottom: 1.5rem;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .middle-info .right-box .value {
  margin-top: 0.8rem;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .sum-detail-box {
  padding: 0.5rem 2.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .sum-detail-box .left-box .name {
  text-transform: capitalize;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .sum-detail-box .right-box {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .sum-detail-box .right-box > *:last-child {
  text-align: right;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .sum-detail-box .right-box > *:nth-child(2) {
  text-align: center;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .sum-detail-box .underline {
  width: 100%;
  margin: 1rem 0rem 0.4rem 0rem;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .sum-detail-box-underline .right-box {
  grid-template-columns: 1fr;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .underline-box {
  padding: 0rem 2.5rem;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .note-wrap-box {
  padding: 0rem 2.5rem;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .note-wrap-box .note {
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
}
.invoice-wrap-box .left-box .invoice-preview-detail-wrap .note-wrap-box .text {
  font-size: 1.45rem;
  line-height: 2rem;
  letter-spacing: 0.02rem;
}
.invoice-wrap-box .right-box {
  flex: 1;
  padding-right: 3rem;
  padding-left: 3rem;
}
@media only screen and (max-width: 55em) {
  .invoice-wrap-box .right-box {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
.invoice-wrap-box .right-box .payment-detail-record {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 1.5rem;
}
.invoice-wrap-box .right-box .payment-detail-record .title {
  font-weight: 600;
  font-size: 1.65rem;
}
.invoice-wrap-box .right-box .payment-detail-record .label-value-detail-wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.invoice-wrap-box .right-box .payment-detail-record .label-value-detail-wrap .box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.invoice-wrap-box .right-box .payment-detail-record .label-value-detail-wrap .box .value {
  text-align: right;
  font-weight: 600;
}
.invoice-wrap-box .right-box .content-selected-box {
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem;
  padding-top: 3rem;
  width: 100%;
  animation: moveUpAnime 0.5s ease-in-out;
}
@media only screen and (max-width: 55em) {
  .invoice-wrap-box .right-box .content-selected-box {
    padding-top: unset;
  }
}
.invoice-wrap-box .right-box .content-selected-box .link-clear-box {
  display: flex;
  align-items: center;
  width: 100%;
}
.invoice-wrap-box .right-box .content-selected-box .link-clear-box .btn-outline-grey {
  margin-left: auto;
  border-radius: 3rem;
  padding: 0.6rem 1.3rem;
}
.invoice-wrap-box .right-box .content-selected-box .text-value-box {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  gap: 3rem;
}
.invoice-wrap-box .right-box .content-selected-box .text-value-box .box {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}
.invoice-wrap-box .right-box .content-selected-box .text-value-box .box .value {
  margin-left: auto;
  text-align: right;
  font-weight: 600;
}
.invoice-wrap-box .right-box .content-selected-box .btn-two-wrap {
  display: flex;
  align-items: center;
  margin-top: 3.5rem;
  gap: 2rem;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box {
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  background-color: #f7f8f7;
  border-radius: 0.9rem;
  border: 0.1rem solid #cccccc;
  margin-top: 2rem;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .launch-link {
  text-decoration: none;
  margin-top: 2.5rem;
  display: flex;
  align-items: center;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .launch-link .preview-icon {
  margin-left: auto;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  cursor: pointer;
  display: grid;
  place-items: center;
  transition: all 0.3s ease-in-out;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .launch-link .preview-icon:hover {
  transform: scale(1.05);
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .launch-link .preview-icon .img-box {
  width: 50%;
  height: 50%;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .launch-link .preview-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .launch-link .launch-box {
  display: flex;
  padding: 0.9rem 1.7rem;
  border-radius: 2.4rem;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
  transition: all 0.3s;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .launch-link .launch-box .img-box {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  display: grid;
  place-items: center;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .launch-link .launch-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .launch-link .launch-box:hover {
  transform: translateY(-0.1rem);
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .launch-link .launch-box .text {
  font-weight: 600;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box {
  display: flex;
  align-items: center;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box .link-box {
  flex: 0 0 85%;
  display: flex;
  align-items: center;
  margin-right: auto;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box .link-box .link {
  font-size: 1.3rem;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box .link-box .img-box {
  width: 2rem;
  height: 2rem;
  margin-right: 0.7rem;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box .link-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box .copy-box {
  width: 3rem;
  height: 3rem;
  display: grid;
  place-items: center;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box .copy-box .img-box {
  width: 3rem;
  height: 3rem;
  margin-right: 0.7rem;
  cursor: pointer;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box .copy-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box .copy-box .icon-box {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #020202;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.invoice-wrap-box .right-box .content-selected-box .launch-link-url-box .link-copy-box .copy-box .icon-box .icon {
  width: 0.9rem;
  height: 0.9rem;
  fill: #ffffff;
}
.invoice-wrap-box .right-box .change_invoice_status {
  margin-top: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
}
.invoice-wrap-box .right-box .change_invoice_status .btn_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
}
.invoice-wrap-box .right-box .change_invoice_status .btn_wrapper .two-btn-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  width: 100%;
}
.invoice-wrap-box .right-box .change_invoice_status .btn_wrapper .two-btn-wrapper > * {
  width: 100%;
}
.invoice-wrap-box .right-box .change_invoice_status .btn_wrapper .two-btn-wrapper .btn-outline-black-light {
  background-color: transparent !important;
  font-weight: 700;
}
.invoice-wrap-box .right-box .change_invoice_status .btn_wrapper .delete {
  font-weight: 700;
  font-size: 1.4rem;
  text-align: center;
  color: #ff0f00;
  cursor: pointer;
}
.invoice-wrap-box .right-box .change_invoice_status .btn_wrapper .delete:hover {
  transform: translateX(2px);
  transition: transform 300ms ease-in-out;
}

@keyframes moveUpAnime {
  0% {
    transform: translateY(2%);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
.request-money-wrap-box {
  width: 40rem;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 10rem;
}
.request-money-wrap-box > *:not(:last-child) {
  margin-bottom: 2rem;
}
.request-money-wrap-box .reccuring-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  width: 100%;
  margin-top: -1rem;
}
.request-money-wrap-box .reccuring-box-show {
  margin-top: unset;
  max-height: 10rem;
  transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
}
.request-money-wrap-box .select-type-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
}
.request-money-wrap-box .select-type-box .box {
  padding: 1.5rem;
  border-radius: 0.8rem;
  background-color: rgba(247, 248, 247, 0.5);
  position: relative;
  cursor: pointer;
}
.request-money-wrap-box .select-type-box .box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 0.8rem;
  border: 0.15rem solid #020202;
  visibility: hidden;
}
.request-money-wrap-box .select-type-box .box > * {
  position: relative;
  z-index: 3;
}
.request-money-wrap-box .select-type-box .box .check-box {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: grid;
  place-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #020202;
  visibility: hidden;
}
.request-money-wrap-box .select-type-box .box .check-box .icon {
  width: 0.6rem;
  height: 0.6rem;
  fill: #ffffff;
}
.request-money-wrap-box .select-type-box .box .img-box {
  margin-bottom: 3rem;
  width: 2.5rem;
  height: 2.5rem;
}
.request-money-wrap-box .select-type-box .box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.request-money-wrap-box .select-type-box .box .title {
  font-weight: 700;
  font-size: 1.4rem;
}
.request-money-wrap-box .select-type-box .box .text {
  font-size: 1.2rem;
  margin-top: 0.5rem;
}
.request-money-wrap-box .select-type-box .box-active::before {
  visibility: visible;
}
.request-money-wrap-box .select-type-box .box-active .check-box {
  visibility: visible;
}/*# sourceMappingURL=preview_invoice.css.map */