.reusable-icon-drop-fragment-business-wrapper-box {
  position: relative;
  z-index: 100;
}
.reusable-icon-drop-fragment-business-wrapper-box .icon-click-drop-box {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.reusable-icon-drop-fragment-business-wrapper-box .icon-click-drop-box .img-box {
  width: 60%;
  height: 60%;
}
.reusable-icon-drop-fragment-business-wrapper-box .icon-click-drop-box .img-box .img,
.reusable-icon-drop-fragment-business-wrapper-box .icon-click-drop-box .img-box svg {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.reusable-icon-drop-fragment-business-wrapper-box .drop-list-box-wrap {
  min-width: 25rem;
  position: absolute;
  top: 110%;
  right: 0%;
  border-radius: 1.2rem;
  animation: moveDownSettingDrop 0.5s ease-in-out backwards;
  display: flex;
  flex-direction: column;
  border-width: 0.15rem !important;
}
.reusable-icon-drop-fragment-business-wrapper-box .drop-list-box-wrap .list-item {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
.reusable-icon-drop-fragment-business-wrapper-box .drop-list-box-wrap .list-item .text {
  font-size: 1.35rem;
}
.reusable-icon-drop-fragment-business-wrapper-box .drop-list-box-wrap .list-item .icon-box {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.reusable-icon-drop-fragment-business-wrapper-box .drop-list-box-wrap .list-item .icon-box .img-box {
  width: 55%;
  height: 55%;
}
.reusable-icon-drop-fragment-business-wrapper-box .drop-list-box-wrap .list-item .icon-box .img-box .img,
.reusable-icon-drop-fragment-business-wrapper-box .drop-list-box-wrap .list-item .icon-box .img-box svg {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}/*# sourceMappingURL=IconDropFragment.css.map */