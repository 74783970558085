.track-delivery-terminal-bankbox-wrapper-box-modal {
  .raven-modal-content-wrap {
    width: 60rem;

    .track-delivery-terminal-bankbox-wrapper-box {
      min-height: 25rem;
      display: flex;
      flex-direction: column;

      .default-progress-bar-grey-box {
        width: 100%;
        min-height: 3rem;
        border-radius: 5rem;
        margin-top: 3rem;
        position: relative;
        // background-color: red;

        .progress-bar-wrapper {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 2;
        }

        .track-list-box {
          position: absolute;
          top: 100%;
          left: 0%;
          display: flex;
          justify-content: space-between;
          z-index: 10;
          width: 100%;
          //   height: 100%;
          padding: 0rem 1rem;
          transform: translateY(-3.4rem);
          background-color: transparent;

          .icon-text-date-box {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .text {
              font-weight: 600;
              font-size: 1.65rem;
            }

            .date {
              font-size: 1.15rem;
              margin-top: -.3rem;
            }

            .icon-box {
              margin-bottom: 1rem;
              .img-box {
                width: 3rem;
                height: 3rem;
                border-radius: 50%;
                display: grid;
                place-items: center;
                background-color: rgba(219, 219, 219, 1);

                .img {
                  width: 60%;
                  height: 60%;
                  object-fit: contain;
                }
              }
            }
          }

          .icon-text-date-box-end {
            align-items: flex-end;
            // background-color: red;
            .text {
              text-align: right;
            }

            .date {
              text-align: right;
            }
          }
        }
      }

      .title-text-box {
        display: flex;
        flex-direction: column;

        .title {
          font-size: 2rem;
          font-weight: 700;
          margin-bottom: 0.5rem;
        }

        .text {
          font-size: 1.3rem;
          // margin-bottom: 2rem;
          padding-bottom: 1.5rem;
        }
      }
    }
  }
}
