.dashboard-settings-wrap-box {
  display: flex;
  flex: 1;
  overflow-y: scroll;
  position: relative;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */

  @media only screen and (max-width: 52em) {
    flex-direction: column;
    // padding: 1rem 1.5rem;
    gap: 1.3rem;
    // color: rgba(255, 255, 255, .2);
  }

  // &::-webkit-scrollbar {
  //   display: none;
  // }

  // &:hover::-webkit-scrollbar {
  //   display: block;
  // }
}
.dashboard-settings-wrap-box::-webkit-scrollbar {
  display: none;
}
.dashboard-settings-wrap-box::-webkit-scrollbar {
  width: 0.5rem;
}
.dashboard-settings-wrap-box::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.dashboard-settings-wrap-box::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.dashboard-settings-wrap-box::-webkit-scrollbar-thumb:hover {
  background: #020202;
}
.dashboard-settings-wrap-box .left-box {
  flex: 0 0 25%;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  top: 0;
  padding: 1rem 2.5rem;
  padding-top: 3rem;

  @media only screen and (max-width: 52em) {
    flex: unset;
    width: 100%;
    padding: 1rem;
  }
}
.dashboard-settings-wrap-box .left-box .wrap {
  border-radius: 1.2rem;
  padding: 2.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media only screen and (max-width: 52em) {
    // padding: 2rem 1.5rem;
    // gap: 1.3rem;
  }
}
.dashboard-settings-wrap-box .left-box .wrap .text {
  cursor: pointer;
  position: relative;
  border: none;
  color: #676767;
  padding: 1rem;
}
.dashboard-settings-wrap-box .left-box .wrap .text .check-box {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  display: grid;
  place-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #020202;
  visibility: hidden;
}
.dashboard-settings-wrap-box .left-box .wrap .text .check-box .icon {
  width: 0.6rem;
  height: 0.6rem;
  fill: #ffffff;
}
.dashboard-settings-wrap-box .left-box .wrap .text-active {
  font-weight: 700;
  border-radius: 0.6rem;
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.06),
    0px 0px 2px rgba(51, 51, 51, 0.02);
  color: #020202;
  // display: none;
}
.dashboard-settings-wrap-box .left-box .wrap .text-active .check-box {
  visibility: visible;
}
.dashboard-settings-wrap-box .right-box {
  width: 100%;
  display: flex;
  padding: 3rem 12rem 3rem 3rem;
  gap: 5rem;
  flex-direction: column;

  @media only screen and (max-width: 52em) {
    flex-direction: column;
    padding: 1rem 1.5rem;
    gap: unset;
  }
}
.dashboard-settings-wrap-box .right-box .detail-wrap {
  display: flex;
  flex-direction: column;
  padding-bottom: 7rem;

  .box-wrap {
    flex: 1;
    display: flex;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .reinvite-delete-invite-box {
      display: flex;
      align-items: center;
      // gap: 1.5rem;

      .retry-box,
      .delete-box {
        margin-left: auto;
        // margin-right: 3rem;
        width: 4rem !important;
        height: 4rem !important;
        border-radius: 0.8rem;
        position: relative;
        cursor: pointer;

        .img-box {
          width: 50% !important;
          height: 50% !important;
          position: absolute;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .pending-box-wrap {
      margin-left: 3rem;
      // display: none;
      // position: relative;

      .status-type-wrap {
        background-color: #e9f5ff;
        border-radius: 3rem;
        width: max-content;
        padding: 0.8rem 1.2rem;
        color: #476885;

        span {
          // font-weight: 700;
          font-size: 1.2rem;
        }
      }

      .status-type-wrap-pending {
        // position: absolute;
        // top: 1rem;
        // right: 1rem;
        background-color: #fff6ed;
        color: #ea872d;
      }
    }

    .content-body-select-sub-account-wrap {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      //  background-color: red !important;
      border-radius: 1.2rem;
      // padding: 2rem;
      max-height: calc(100vh - 40rem);
      overflow-y: scroll;
      // padding-bottom: unset;

      &::-webkit-scrollbar {
        display: none;
      }

      .sub-account-item {
        border-width: 0.15rem;
        padding: 1.5rem 2rem;
        border-radius: 1.2rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .detail-action-box {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          cursor: pointer;
          gap: 3rem;
          // flex: 1;

          .action-box {
            margin-left: auto;
            cursor: pointer;
            position: relative;

            .drop-box-wrap {
              position: absolute;
              top: 110%;
              right: 10%;
              // min-height: 20rem;
              // min-width: 20rem;
              border-radius: 1.2rem;
              transform: scale(0);
              transition: transform 0.2s ease-in-out;
              transform-origin: top right;
              width: max-content;
              height: max-content;
              padding: 1.5rem 2rem;
              display: flex;
              flex-direction: column;
              gap: 1.5rem;

              & > *:not(:last-child) {
                padding-bottom: 1.5rem;
              }

              .icon-text-box {
                display: flex;
                align-items: center;
                gap: 1rem;

                .text {
                  font-size: 1.3rem;
                }

                .delete {
                  color: rgba(255, 15, 0, 1);
                }

                .img-wrap {
                  .img-box {
                    width: 2.2rem;
                    height: 2.2rem;

                    .img {
                      width: 100%;
                      height: 100%;
                      object-fit: contain;
                    }
                  }
                }
              }
            }

            .drop-bow-wrap-up {
              top: unset;
              bottom: 110%;
              transform-origin: bottom right;
            }

            .drop-box-wrap-active {
              transform: scale(1);
            }
          }

          .primary-box {
            background-color: rgba(232, 255, 246, 1);
            padding: 1rem 2rem;
            border-radius: 2.5rem;
            cursor: pointer;
            align-self: flex-start;

            span {
              font-weight: 500;
              color: rgba(26, 206, 55, 1);
            }
          }

          .disabled-box {
            background-color: rgba(255, 245, 245, 1);

            span {
              color: rgba(255, 15, 0, 1);
            }
          }

          .detail-account-box {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .name {
              font-size: 1.75rem;
              font-weight: 600;
            }

            .bank-acc_num {
              font-size: 1.3rem;
            }
          }
        }

        .role-box-wrap {
          padding: 1.5rem;
          border-radius: 1.2rem;
          display: flex;
          align-items: center;
          gap: 2rem;
          flex-wrap: wrap;

          .role-item-box {
            min-height: 4rem;
            padding: 1rem 2rem;
            border-radius: 2.5rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            justify-content: center;

            .cancel-icon-box {
              cursor: pointer;
              // max-height: 0;
              visibility: hidden;
              transition: visibility 0.3s ease-in-out;
              -webkit-transition: visibility 0.3s;
              -moz-transition: visibility 0.3s;
              max-width: 0;

              .img-box {
                width: 1.5rem;
                height: 1.5rem;

                .img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }

            &:hover .cancel-icon-box {
              // max-height: 2rem;
              visibility: visible;
              transition: visibility 0.3s ease-in-out;
              overflow: visible;
            }

            .role {
              font-size: 1.45rem;
              white-space: nowrap;
              margin-left: -0.5rem;
            }
          }

          .purple-item-box {
            background-color: rgba(240, 238, 255, 1);
            cursor: pointer;

            .cancel-icon-box {
              max-height: 2rem;
              max-width: 2rem;
              transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
            }

            .role {
              color: rgba(117, 90, 226, 1);
            }
          }
        }
      }
    }
  }
}
.dashboard-settings-wrap-box .right-box .detail-wrap .text-btn-box {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 42em) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;

    & > *:last-child {
      margin-left: auto;
    }
  }

  .select-box-list-wrap {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.7rem 1rem;
    border-radius: 4rem;
    gap: 1rem;

    .item-box {
      padding: 1rem 2rem;
      display: flex;
      justify-content: center;
      border-radius: 4rem;
      cursor: pointer;

      .item {
        font-size: 1.25rem;
      }
    }

    .item-box-active {
      cursor: not-allowed;
      .item {
        font-weight: 600;
      }
    }
  }
}
.dashboard-settings-wrap-box .right-box .detail-wrap .text-btn-box .text-wrap {
  margin-right: auto;
}
.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .text-btn-box
  .text-wrap
  .title {
  font-weight: 600;
  font-size: 2.4rem;
}
.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .text-btn-box
  .text-wrap
  .sub-title {
  font-size: 1.3rem;
}
.dashboard-settings-wrap-box .right-box .detail-wrap .title {
  font-weight: 600;
  font-size: 2.4rem;
}
.dashboard-settings-wrap-box .right-box .detail-wrap .sub-title {
  font-size: 1.3rem;
}
.dashboard-settings-wrap-box .right-box .detail-wrap .box-wrap {
  border-radius: 2rem;
  padding: 3.2rem 1.6rem;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  @media only screen and (max-width: 52em) {
    padding: 0.5rem 1.5rem;
    border-radius: 1rem;
  }

  .theme-preference-wrap {
    display: flex;
    flex-direction: column;

    .btn-text-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0rem 2rem;

      .update {
        font-weight: 700;
        color: #755ae2;
        cursor: pointer;
        font-size: 1.6rem;
      }

      .text-box {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;

        .content-title {
          font-size: 1.7rem;
          font-weight: 700;
          padding: 0rem;
        }
      }
    }

    .content-title {
      font-size: 1.7rem;
      font-weight: 700;
      padding: 0rem 1.6rem;
    }

    .select-quick-action-wrap {
      padding: 2.5rem 2rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 2rem;

      .child {
        padding: 1.2rem 2rem;
        border-radius: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        cursor: pointer;
        width: max-content;
        box-shadow: 0px 3.56px 7.12px -1.78px rgba(51, 51, 51, 0.1),
          0px 1.78px 3.56px -1.78px rgba(51, 51, 51, 0.06);

        .check-box {
          width: 1.4rem;
          height: 1.4rem;
          display: grid;
          place-items: center;
          background-color: #ffffff;
          border-radius: 50%;

          .icon {
            width: 0.65rem;
            height: 0.65rem;
            fill: #755ae2;
          }
        }

        span {
          font-size: 1.25rem;
          font-weight: 700;
          display: inline-block;
        }
      }

      .child-active {
        // cursor: default;
        background-color: #755ae2 !important;

        span {
          color: #ffffff !important;
        }
      }
    }

    .select-wrapper {
      margin-top: 2rem;
      display: flex;
      padding-left: 1.6rem;
      gap: 3rem;
      // display: none;

      @media only screen and (max-width: 42em) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 0rem 1.5rem;
      }

      .box {
        .img-wrap {
          min-height: 15rem;
          min-width: 20rem;
          background-position: center;
          background-size: contain;
          background-image: url("../../../assets/light-theme-example-img.png");
          background-repeat: no-repeat;
          position: relative;
          border-radius: 0.8rem;

          @media only screen and (max-width: 42em) {
            min-width: unset;
          }

          &::before {
            border-color: #755ae2 !important;
            border-width: 0.2rem;
          }

          .check-box {
            background-color: #755ae2 !important;

            .icon {
              color: #ffffff !important;
              fill: #ffffff !important;
            }
          }
        }

        .text {
          font-weight: 700;
          margin-top: 1rem;
          font-size: 1.35rem;
        }

        .minimal {
          background-image: url("../../../assets/sidenav-light-example-img.png") !important;
        }
        .solid {
          background-image: url("../../../assets/sidenav-dark-example-img.png") !important;
        }

        .dark-img {
          background-image: url("../../../assets/dark-theme-example-img.png") !important;
        }
        .light-img {
          background-image: url("../../../assets/light-theme-example-img.png") !important;
        }
      }

      // & > *:nth-child(2) {
      //   .img-wrap {
      //     background-image: url("../../../assets/dark-theme-example-img.png");
      //   }
      // }
    }

    .table-select-font-wrap {
      display: flex;
      align-items: center;
      gap: 2rem;
      // margin-left: 2rem;

      .child {
        padding: 1.2rem 2rem;
        border-radius: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        cursor: pointer;
        width: max-content;
        box-shadow: 0px 3.56px 7.12px -1.78px rgba(51, 51, 51, 0.1),
          0px 1.78px 3.56px -1.78px rgba(51, 51, 51, 0.06);

        .check-box {
          width: 1.4rem;
          height: 1.4rem;
          display: grid;
          place-items: center;
          background-color: #ffffff;
          border-radius: 50%;

          .icon {
            width: 0.65rem;
            height: 0.65rem;
            fill: #755ae2;
          }
        }

        span {
          font-size: 1.25rem;
          font-weight: 700;
          display: inline-block;
        }
      }

      .child-active {
        // cursor: default;
        background-color: #755ae2 !important;

        span {
          color: #ffffff !important;
        }
      }
    }
  }

  .underline-theme {
    width: 100%;
    margin: 2rem 0rem;
    display: none !important;
  }
}

.dashboard-settings-wrap-box .right-box .detail-wrap .box-wrap-remove-padding {
  padding: 3.2rem 0rem;

  // &>*{
  //   // padding: 00rem 1.6rem;
  // }
}

.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  > *:not(:last-child) {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid rgba(204, 204, 204, 0.5);
}
.dashboard-settings-wrap-box .right-box .detail-wrap .box-wrap .noti-wrap {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 52em) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;

    .toggle-wrap {
      align-self: flex-end;
    }
  }

  .right-arrow {
    margin-left: auto;

    &:hover .icon {
      transform: translateX(0.4rem);
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
      transition: all 0.3s ease-in-out;
    }
  }
}

.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap-sms-notify-toggle {
  .toggle-wrap .toggle-label {
    cursor: not-allowed;
  }
}

.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .avatar-box {
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
  filter: drop-shadow(0px 1px 2px rgba(51, 51, 51, 0.06))
    drop-shadow(0px 0px 2px rgba(51, 51, 51, 0.02));
  background-color: #ffffff;
  display: grid;
  place-items: center;
  position: relative;

  .img-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .avatar-box
  span {
  color: #676767;
  font-weight: 600;
  font-size: 2rem;
  display: inline-block;
}
.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .delete-box {
  margin-left: unset;
  width: 4.5rem;
  height: 4.5rem;
  display: grid;
  place-items: center;
  position: relative;
  background-color: #ffffff;
  border: 0.1rem solid rgba(204, 204, 204, 0.5);
  border-radius: 0.8rem;
  cursor: pointer;
  margin-left: 2rem;
  // display: none;

  // @media only screen and (max-width: 52em) {
  //   flex-direction: column;

  // }
}
.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .delete-box:hover
  .img-box {
  transform: translate(-50%, -50%) scale(1.1);
}
.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .delete-box
  .img-box {
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
}
.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .delete-box
  .img-box
  .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .react-select-team {
  margin-left: auto;
  width: -moz-max-content;
  width: max-content;
}
.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .react-select-team
  .react-select-class
  > *:nth-child(3) {
  max-width: max-content;
  background-color: #ffffff;
  border: 0.1rem solid rgba(204, 204, 204, 0.5);
}
.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .img-box {
  width: 4.5rem;
  height: 4.5rem;
  display: grid;
  place-items: center;
  transform: translateY(0.5rem);
}
.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .img-box
  .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .add-error-box {
  padding: 0.6rem 1.7rem;
  background-color: #ffffff;
  border-radius: 6rem;
  box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
    0px 0px 2px rgba(51, 51, 51, 0.02);
  transition: all 0.3s;
  cursor: pointer;

  @media only screen and (max-width: 52em) {
    align-self: flex-end;
  }
}
.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .add-error-box:hover {
  transform: scale(1.05);
}
.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .add-error-box
  span {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .error-type {
  background-color: #fff5f5;
}
.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .error-type
  span {
  color: #ff0f00;
}

.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .verified {
  background-color: #e8fff6;

  span {
    color: #0b8376;
  }
}

.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .failed {
  background-color: #fff5f5;

  span {
    color: #ff0f00;
  }
}

.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .pending {
  background-color: #fff6ed;

  span {
    color: #ea872d;
  }
}

.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .text-box {
  margin-right: auto;
  margin-left: 1rem;
}
.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .text-box
  .value {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0.3rem;
}
.dashboard-settings-wrap-box
  .right-box
  .detail-wrap
  .box-wrap
  .noti-wrap
  .text-box
  .label {
  font-size: 1.2rem;
} /*# sourceMappingURL=DashboardSettings.css.map */

.dashboard-settings-wrap-box .right-box .role-permission-setting-wrap {
  // display: none;
  display: flex;
  flex-direction: column;
  // flex: 1;
  // background-color: red;
  overflow-y: scroll;

  .box-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    grid-gap: unset;
    padding: unset;
    border-radius: 1.2rem;
    overflow: hidden;
    // flex: 1;
    overflow-y: scroll;
    // align-items: flex-start;

    &::-webkit-scrollbar {
      display: none;
    }

    .role-wrap {
      margin-bottom: unset;
      // border-width: .125rem;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      cursor: pointer;

      .name {
        font-size: 1.7rem;
      }

      .des {
        font-size: 1.35rem;
      }

      .staff-no-box {
        display: flex;
        align-items: center;
        align-self: flex-start;
        padding: 0.85rem 1.85rem;
        cursor: pointer;
        border-radius: 2rem;

        &:hover .img-box {
          transform: translateX(0.25rem);
        }

        .img-box {
          width: 2rem;
          height: 2rem;
          transition: all 0.3s ease-in-out;
          margin-left: 0.4rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  // .box-wrap-two {
  //   background-color: red;
  //   height: 400rem;
  // }
}
