.security-lock-wrap-box {
  display: flex;
  align-items: center;
  background-color: rgba(204, 204, 204, 0.3);
  border-radius: 0.4rem;
  border: 0.1rem solid #cccccc;
  align-self: flex-start;
  width: -moz-max-content;
  width: max-content;
  padding: 0.3rem 0.8rem;
  cursor: pointer;
  transition: all 0.3s;
}
.security-lock-wrap-box .img-box {
  display: grid;
  place-items: center;
}
.security-lock-wrap-box:hover {
  transform: translateY(-0.1rem);
}
.security-lock-wrap-box .text {
  font-size: 1.2rem;
  margin-left: 1rem;
  color: #676767 !important;
}/*# sourceMappingURL=LockBox.css.map */