.blackout-loading-logo-modal {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  z-index: 50000;
  display: grid;
  place-items: center;
  background: rgba(2, 2, 2, 0.5);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}
.blackout-loading-logo-modal .wrap .img-box {
  width: 25rem;
  height: 25rem;
}
@media only screen and (max-width: 48em) {
  .blackout-loading-logo-modal .wrap .img-box {
    width: 20rem;
    height: 20rem;
  }
}
.blackout-loading-logo-modal .wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=BlackoutLogoModal.css.map */