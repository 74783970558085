.auth_layout_version_one_point_five_wrap {
  display: flex;
  width: 100vw;
  height: 100vh;
  gap: 3rem;
}
@media only screen and (max-width: 48em) {
  .auth_layout_version_one_point_five_wrap {
    gap: unset;
  }
}
.auth_layout_version_one_point_five_wrap .left-box {
  flex: 0 0 45%;
  display: grid;
  place-items: center;
}
@media only screen and (max-width: 48em) {
  .auth_layout_version_one_point_five_wrap .left-box {
    display: none;
  }
}
.auth_layout_version_one_point_five_wrap .left-box .wrapper {
  width: 95%;
  height: 95%;
  background-image: url("../../assets/bgauth-sign-up-img.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 1.6rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}
.auth_layout_version_one_point_five_wrap .left-box .wrapper .bottom-wrap {
  margin-top: auto;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
}
.auth_layout_version_one_point_five_wrap .left-box .wrapper .bottom-wrap .step-box-wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2.5rem;
}
.auth_layout_version_one_point_five_wrap .left-box .wrapper .bottom-wrap .step-box-wrap .box {
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  justify-content: flex-start;
  z-index: 3;
  width: 100%;
  border-radius: 1.2rem;
  width: 60%;
  padding: 1.5rem;
  gap: 1rem;
}
.auth_layout_version_one_point_five_wrap .left-box .wrapper .bottom-wrap .step-box-wrap .box .num {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background-color: rgb(180, 180, 180);
  display: grid;
  place-items: center;
  cursor: pointer;
}
.auth_layout_version_one_point_five_wrap .left-box .wrapper .bottom-wrap .step-box-wrap .box .num span {
  color: #ffffff;
  font-size: 1.2rem;
}
.auth_layout_version_one_point_five_wrap .left-box .wrapper .bottom-wrap .step-box-wrap .box .text {
  font-size: 1.2rem;
  color: #333333;
}
.auth_layout_version_one_point_five_wrap .left-box .wrapper .bottom-wrap .step-box-wrap .box-active .num {
  background-color: #333333;
}
.auth_layout_version_one_point_five_wrap .left-box .wrapper .bottom-wrap .step-box-wrap .box-active .num span {
  color: #ffffff;
  font-weight: 600;
}
.auth_layout_version_one_point_five_wrap .left-box .wrapper .bottom-wrap .small-text-wrap .text {
  font-size: 1.3rem;
}
.auth_layout_version_one_point_five_wrap .left-box .wrapper .bottom-wrap .big-text-wrap {
  margin-bottom: 0.5rem;
}
.auth_layout_version_one_point_five_wrap .left-box .wrapper .bottom-wrap .big-text-wrap .text {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3rem;
}
.auth_layout_version_one_point_five_wrap .left-box .wrapper .bottom-wrap .big-text-wrap .text span {
  font-weight: 700;
}
.auth_layout_version_one_point_five_wrap .right-box {
  flex: 1;
  display: grid;
  place-items: center;
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content {
  width: -moz-max-content;
  width: max-content;
}
@media only screen and (max-width: 48em) {
  .auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content {
    width: 100%;
    display: grid;
    place-items: center;
  }
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content {
  width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
@media only screen and (max-width: 48em) {
  .auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content {
    width: 95%;
  }
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content .form-group .input-group input {
  border: unset !important;
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content .check-text-box {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content .check-text-box .text {
  font-size: 1.35rem;
  color: #676767;
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content .check-text-box .text span {
  color: var(--primary-purple);
  font-weight: 600;
  cursor: pointer;
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content .check-text-box .check-box-wrap .input-check:checked ~ .label-check {
  border: none;
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content .check-text-box .check-box-wrap .label-check {
  border: 0.1rem solid #cccccc !important;
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content .two-group {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
}
@media only screen and (max-width: 42em) {
  .auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content .two-group {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content .two-group .form-group {
  width: 100%;
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content .two-group .form-group .form-input {
  width: 100%;
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content .forget-text {
  margin-top: -1.2rem;
  align-self: flex-end;
  cursor: pointer;
  color: var(--primary-purple);
  font-weight: 600;
  font-size: 1.3rem;
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content .btn-submit {
  width: 100%;
  margin-top: 2rem;
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content .already-text {
  font-size: 1.35rem;
  color: #676767;
  text-align: center;
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content .already-text span {
  color: var(--primary-purple);
  font-weight: 600;
  cursor: pointer;
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content .big-text-wrap {
  margin-bottom: 1.5rem;
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content .big-text-wrap .text {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 3rem;
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .sign-up-form-content .big-text-wrap .text span {
  font-weight: 400;
}
.auth_layout_version_one_point_five_wrap .right-box .wrapper-auth-content .btn-submit-white input {
  color: #ffffff !important;
}/*# sourceMappingURL=AuthLayoutOne_Five.css.map */