.account-drop-down-box-toggle-wrap {
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  min-width: 25rem;
  animation: moveDownSettingDrop 0.5s ease-in-out backwards;
  overflow: hidden;
  max-height: 45rem;
  overflow-y: scroll;
}
@media only screen and (max-width: 48em) {
  .account-drop-down-box-toggle-wrap {
    max-height: 90vh;
  }
}
.account-drop-down-box-toggle-wrap::-webkit-scrollbar {
  display: none;
}
.account-drop-down-box-toggle-wrap .label-value-box {
  display: flex;
  flex-direction: column;
}
.account-drop-down-box-toggle-wrap .label-value-box .label {
  font-weight: 600;
  font-size: 1.35rem;
  white-space: nowrap;
}
.account-drop-down-box-toggle-wrap .label-value-box .value {
  font-size: 1.25rem;
}
.account-drop-down-box-toggle-wrap .account-select {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  padding: 2rem;
}
.account-drop-down-box-toggle-wrap .account-select .primary-box {
  margin-left: auto;
  background-color: rgb(240, 238, 255);
  padding: 0.8rem 1.5rem;
  border-radius: 2rem;
}
.account-drop-down-box-toggle-wrap .account-select .primary-box .text {
  font-weight: 500;
  color: rgb(117, 90, 226);
}
.account-drop-down-box-toggle-wrap .account-select .img-wrap .img-box {
  width: 3rem;
  height: 3rem;
}
.account-drop-down-box-toggle-wrap .account-select .img-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.account-drop-down-box-toggle-wrap .active-sub-account .check-box {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: #ffffff;
}
.account-drop-down-box-toggle-wrap .active-sub-account .check-box .icon {
  width: 1rem;
  height: 1rem;
}

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}/*# sourceMappingURL=AccountDropDownBox.css.map */