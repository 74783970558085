.payroll-current-disbursemen-top-detail-card-box {
  margin-bottom: 1rem;
  border: 1px solid rgba(208, 213, 221, 0.5);
  box-shadow: -1px 0px 20px rgba(229, 230, 230, 0.35);
  border-radius: 1.6rem;
  padding: 1.5rem 2rem;
  display: flex;
}
.payroll-current-disbursemen-top-detail-card-box .left-box {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
}
.payroll-current-disbursemen-top-detail-card-box .left-box .wallet-cash-box {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-top: 1.5rem;

  @media only screen and (max-width: 60em) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
    grid-gap: 2rem;
  }

  @media only screen and (max-width: 42em) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}
.payroll-current-disbursemen-top-detail-card-box
  .left-box
  .wallet-cash-box
  > *:not(:last-child) {
  border-right: 0.1rem solid #f0eeff;
  padding-right: 3rem;
}
.payroll-current-disbursemen-top-detail-card-box
  .left-box
  .wallet-cash-box
  .box
  .label {
  font-size: 1.1rem !important;
  margin-top: 0.3rem;
}
.payroll-current-disbursemen-top-detail-card-box
  .left-box
  .wallet-cash-box
  .box
  .value {
  font-weight: 600;
  font-size: 2rem;
}
.payroll-current-disbursemen-top-detail-card-box .left-box .btn-two-box {
  margin-top: 6rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media only screen and (max-width: 42em) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3rem;
  }
}
.payroll-current-disbursemen-top-detail-card-box
  .left-box
  .btn-two-box
  .raven-btn {
  min-width: 19rem;
}
.payroll-current-disbursemen-top-detail-card-box .right-box {
  flex: 1;
  display: flex;
  padding: 0rem 2rem;
}

.payroll-current-disbursemen-table-wrap-content-box {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2rem;

  .total-employee-text-box {
    display: flex;
    width: 100%;
    margin-right: auto;
    justify-content: space-between;

    @media only screen and (max-width: 42em) {
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5rem;
      margin-bottom: 2rem;

      & > *:last-child {
        align-self: flex-end;
        width: 100%;

        .form-group {
          width: 100%;

          .input-group {
            width: 100%;
          }
        }
      }
    }

    .total-employee-text {
      font-size: 1.5rem;

      span {
        font-weight: 700;
      }
    }
  }
}
.payroll-current-disbursemen-table-wrap-content-box .payroll-search {
  width: -moz-max-content;
  width: max-content;
}
.payroll-current-disbursemen-table-wrap-content-box .table-box {
  margin-top: 1.5rem;
  width: 100%;
  padding-bottom: 10rem;

  .table {
    @media only screen and (max-width: 48em) {
      display: none;
    }
  }

  // display: none;
  .payroll-current-action-wrap {
    margin: 0rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: red;
    gap: 1rem;
    width: 100%;

    .drop-down-box-payroll {
      position: relative;
      transform: unset !important;

      .drop-down-wrap-payroll {
        position: absolute;
        top: 120%;
        right: 2rem;
        width: max-content;
        background-color: #ffffff;
        transform: scale(0);
        transform-origin: top right;
        transition: transform 0.5s ease-in-out;
        display: flex;
        flex-direction: column;
        padding: 1.5rem 2rem;
        border-radius: 1.2rem;
        gap: 1rem;
        box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.1);

        & > *:first-child {
          padding-bottom: 1rem;
          border-top: unset;
          border-left: unset;
          border-right: unset;
          color: #ff0f00;
        }

        & > * {
          font-weight: 500;
          cursor: pointer;
        }

        & > *:last-child {
          color: #0b8376;
        }
      }

      .drop-down-wrap-payroll-up {
        top: unset;
        bottom: 120%;
      }

      .drop-down-wrap-payroll-show {
        transform: scale(1);
      }
    }

    .tooltip-hover-wrap {
      position: relative;
      z-index: 15;
      & > *:last-child {
        transform: scale(0.75);
      }
    }

    .img-box {
      width: 3.5rem;
      height: 3.5rem;
      // background-color: #f7f8f7;
      border-radius: 50%;
      display: grid;
      place-items: center;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }

      .img {
        width: 50%;
        height: 50%;
        object-fit: contain;
        // color:  rgba(117, 90, 226, .3);
      }
    }

    & > *:nth-child(2) {
      .img {
        width: 50%;
        height: 50%;
        object-fit: contain;
      }
    }

    .delete-box {
      background-color: #fff5f5;
    }
  }

  .payroll-table-row {
    position: relative;
    z-index: 2 !important;
  }

  .payroll-table-row-index {
    z-index: 4 !important;
  }
}
.payroll-current-disbursemen-table-wrap-content-box
  .table-box
  .table-pagination-box {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  padding: 0rem 3rem;
  margin-bottom: 5rem;
}
.payroll-current-disbursemen-table-wrap-content-box
  .table-box
  .text-avatar-box {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.payroll-current-disbursemen-table-wrap-content-box
  .table-box
  .text-avatar-box
  .avatar-box {
  width: 3rem;
  height: 3rem;
  background-color: #f7f8f7;
  border: 0.1rem solid #cccccc;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.payroll-current-disbursemen-table-wrap-content-box
  .table-box
  .text-avatar-box
  .avatar-box
  span {
  font-weight: 600;
  font-size: 1rem;
  text-transform: capitalize;
}

.payroll-history-top-detail-card-box {
  margin-bottom: 1rem;
  border: 1px solid rgba(208, 213, 221, 0.5);
  box-shadow: -1px 0px 20px rgba(229, 230, 230, 0.35);
  border-radius: 1.6rem;
  padding: 2.5rem 2rem;
  display: flex;
  flex-direction: column;
}
.payroll-history-top-detail-card-box .top-box {
  margin-bottom: 1rem;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid rgba(204, 204, 204, 0.3);
}
.payroll-history-top-detail-card-box .top-box .select-wrap {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: -moz-max-content;
  width: max-content;
  border-radius: 1.3rem;
  gap: 1.5rem;
}
.payroll-history-top-detail-card-box .top-box .select-wrap .select {
  border-radius: 1rem;
  padding: 1.2rem 1.2rem;
  cursor: pointer;
  font-weight: 500;
}
.payroll-history-top-detail-card-box .top-box .select-wrap .selected {
  background: #ffffff;
  box-shadow: 0px 3.4601px 4.61347px rgba(51, 51, 51, 0.04),
    0px 0px 2.30673px rgba(51, 51, 51, 0.02);
}
.payroll-history-top-detail-card-box .wrap {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 52em) {
    flex-direction: column;
  }
}
.payroll-history-top-detail-card-box .wrap .left-box {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  flex: unset;
  width: 100%;
}
.payroll-history-top-detail-card-box .wrap .left-box .wallet-cash-box {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-top: 1.5rem;

  @media only screen and (max-width: 52em) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
    grid-gap: 2.5rem;
  }

  @media only screen and (max-width: 38em) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}
.payroll-history-top-detail-card-box
  .wrap
  .left-box
  .wallet-cash-box
  > *:not(:last-child) {
  border-right: 0.1rem solid #f0eeff;
  padding-right: 3rem;

  @media only screen and (max-width: 52em) {
    border-right: unset;
    padding-right: unset;
  }
}
.payroll-history-top-detail-card-box
  .wrap
  .left-box
  .wallet-cash-box
  .box
  .label {
  font-size: 1.1rem !important;
  margin-top: 0.3rem;
}
.payroll-history-top-detail-card-box
  .wrap
  .left-box
  .wallet-cash-box
  .box
  .value {
  font-weight: 600;
  font-size: 2rem;

  @media only screen and (max-width: 42em) {
    font-size: 1.7rem;
  }
}
.payroll-history-top-detail-card-box .wrap .left-box .btn-two-box {
  margin-top: 6rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.payroll-history-top-detail-card-box .wrap .left-box .btn-two-box .raven-btn {
  min-width: 19rem;
}
.payroll-history-top-detail-card-box .wrap .right-box {
  display: flex;
  padding: 0rem 2rem;

  @media only screen and (max-width: 42em) {
    margin-top: 2rem;
    padding: 0rem;
    justify-content: flex-end;
    width: 100%;

  }
}
.payroll-history-top-detail-card-box .wrap .right-box .download-btn {
  background-color: #f7f8f7;
  border: 0.1rem solid #cccccc;
  padding: 1.4rem 1.6rem;
  color: #676767;
  display: flex;
  align-items: center;
  gap: 1rem;

  @media only screen and (max-width: 42em) {
    align-items: flex-end;
    // display: none;
    align-self: flex-end;
  }
}
.payroll-history-top-detail-card-box .wrap .right-box .download-btn .img-box {
  width: 1.5rem;
  height: 1.5rem;
}
.payroll-history-top-detail-card-box
  .wrap
  .right-box
  .download-btn
  .img-box
  .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.payroll-history-table-box-wrap {
  padding: 0rem 2rem;
  width: 100%;

  @media only screen and (max-width: 48em) {
    margin: 3rem 0rem 5rem 0rem;
  }

  .scroll-wrap {
    width: 100%;
    overflow-x: scroll;
    padding-bottom: 3rem;

    @media only screen and (max-width: 67em) {
      width: 65rem;
    }

    @media only screen and (max-width: 48em) {
      display: none;
    }
  }

  .transaction-action-wrap {
    // color: rgba(255, 15, 0, .3);
    margin: 0rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: red;
    gap: 1rem;
    width: 100%;

    .img-box {
      width: 3.5rem;
      height: 3.5rem;
      // background-color: #f7f8f7;
      border-radius: 50%;
      display: grid;
      place-items: center;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }

      .img {
        width: 40%;
        height: 40%;
        object-fit: contain;
      }
    }
  }
}
.payroll-history-table-box-wrap .text-avatar-box {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.payroll-history-table-box-wrap .text-avatar-box .avatar-box {
  width: 3rem;
  height: 3rem;
  background-color: #f7f8f7;
  border: 0.1rem solid #cccccc;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.payroll-history-table-box-wrap .text-avatar-box .avatar-box span {
  font-weight: 600;
  font-size: 1rem;
  text-transform: capitalize;
}

.employee-profile-payroll-wrap-top-box {
  min-height: 20rem;
  background-color: #020202;
  border-radius: 1.6rem;
  background-image: url("../../../assets/employee-profile-payroll-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 2.5rem 2rem;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 56em) {
    min-height: unset;
  }
}
.employee-profile-payroll-wrap-top-box .amount-detail-box {
  display: flex;
  margin-top: auto;
  align-items: center;
  gap: 3rem;

  @media only screen and (max-width: 56em) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    grid-template-rows: repeat(2, 1fr);
  }
}
.employee-profile-payroll-wrap-top-box .amount-detail-box .box {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.employee-profile-payroll-wrap-top-box .amount-detail-box .box .value {
  color: #ffffff;
  font-size: 2rem;

  @media only screen and (max-width: 42em) {
    font-size: 1.7rem;
  }
}
.employee-profile-payroll-wrap-top-box .amount-detail-box .box .label {
  font-size: 1.35rem;

  @media only screen and (max-width: 42em) {
    font-size: 1.25rem;
  }
}
.employee-profile-payroll-wrap-top-box .detail-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 56em) {
    flex-direction: column;
    justify-content: unset;
    gap: 2rem;
    align-items: flex-start;
    margin-bottom: 3rem;
  }
}
.employee-profile-payroll-wrap-top-box .detail-btn-wrap .detail-wrap .name {
  font-weight: 600;
  font-size: 2.7rem;
  color: #ffffff;
  margin-bottom: 0.4rem;
}
.employee-profile-payroll-wrap-top-box .detail-btn-wrap .detail-wrap .email {
  color: #cccccc;
  font-size: 1.35rem;
}
.employee-profile-payroll-wrap-top-box .detail-btn-wrap .btn-two-wrap {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.employee-profile-payroll-wrap-top-box
  .detail-btn-wrap
  .btn-two-wrap
  .btn-white {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
}
.employee-profile-payroll-wrap-top-box
  .detail-btn-wrap
  .btn-two-wrap
  .btn-white
  .img-box {
  width: 1.5rem;
  height: 1.5rem;
}
.employee-profile-payroll-wrap-top-box
  .detail-btn-wrap
  .btn-two-wrap
  .btn-white
  .img-box
  .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.employee-profile-payroll-wrap-top-box
  .detail-btn-wrap
  .btn-two-wrap
  .btn-white
  .text {
  color: #020202;
}

.employee-profile-payroll-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem;
}
.employee-profile-payroll-wrap .table-wrap-box {
  margin-top: 2rem;
  width: 100%;

  .scroll-wrap {
    width: 100%;
    overflow-x: scroll;
    padding-bottom: 3rem;

    @media only screen and (max-width: 56em) {
      .table {
        width: 75rem;
      }
    }

    @media only screen and (max-width: 48em) {
      display: none;
    }
  }

  .transaction-action-wrap {
    // color: rgba(255, 15, 0, .3);
    margin: 0rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: red;
    gap: 1rem;
    width: 100%;

    .img-box {
      width: 3.5rem;
      height: 3.5rem;
      // background-color: #f7f8f7;
      border-radius: 50%;
      display: grid;
      place-items: center;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }

      .img {
        width: 40%;
        height: 40%;
        object-fit: contain;
      }
    }
  }
}
.employee-profile-payroll-wrap .top-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;

  @media only screen and (max-width: 48em) {
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
  }
}
.employee-profile-payroll-wrap .top-box .title {
  font-size: 1.6rem;
  font-weight: 600;
}
.employee-profile-payroll-wrap .top-box .download-btn {
  background-color: #f7f8f7;
  border: 0.1rem solid #cccccc;
  padding: 1.4rem 1.6rem;
  color: #676767;
  display: flex;
  align-items: center;
  gap: 1rem;

  @media only screen and (max-width: 48em) {
    margin-left: auto;
  }
}
.employee-profile-payroll-wrap .top-box .download-btn .img-box {
  width: 1.5rem;
  height: 1.5rem;
}
.employee-profile-payroll-wrap .top-box .download-btn .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
} /*# sourceMappingURL=PayrollDisbursement.css.map */


.new-employee-profile-payroll-wrap-top-box {
  width: 100%;
  // height: 100%;
  display: flex;
  flex: 1;
  // background-color: red;
  // padding: 0rem 3rem;
  // overflow-y: scroll;

  .left-box {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 3rem;

    & > * {
      padding: 0rem 5rem 0rem 5rem;
    }

    .title {
      font-size: 1.7rem;
      font-weight: 700;
    }

    .detail-label-value-box {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 3rem;

      .box {
        display: flex;
        align-items: flex-start;
        gap: 1.5rem;

        .label {
          flex: 0 0 30%;
          font-size: 1.5rem;
        }

        .value {
          font-weight: 500;
          font-size: 1.5rem;
          opacity: 0.65;
          flex: 0 0 50%;
          // width: 65%;
        }
      }
    }

    .info-copy-wrap {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding-bottom: 2rem;
      gap: 2rem;
      min-height: 10rem;

      .name-email-box{
        display: flex;
        flex-direction: column;

        .name{
          font-weight: 700;
          font-size: 2.5rem;
          text-transform: capitalize;
        }

     
      }

      .avatar-action-box{
        display: flex;
        align-items: center;
        width: 100%;

        .avatar-box{
          margin-right: auto;
          width: 5.5rem;
          height: 5.5rem;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: .15rem;

          span{
            font-weight: 500;
            text-transform: uppercase;
            font-size: 2rem;
          }
        }
      }

      .icon-action-box{
        display: flex;
        align-items: center;
        gap: 2rem;

        .icon-box{
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          display: grid;
          place-items: center;
          cursor: pointer;
          transition: all .3s ease-in-out;
          
          &:hover{
            transform: scale(1.05);
          }

          .img-box{
            width: 50%;
            height: 50%;

            .img{
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }

      .img-wrap {
        flex: 0 0 13%;
        width: 100%;
        height: 9rem;
        background-image: url("../../../assets/new-invoice-img.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      .text-link-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: space-between;

        .img-icon-link {
          display: flex;
          gap: 1.5rem;
          width: 100%;

          .copy-box {
            flex: 0 0 10%;
            display: grid;
            place-items: center;

            .img-box {
              width: 5rem;
              height: 100%;
              background-color: #ffffff;
              display: grid;
              place-items: center;
              border-radius: 0.8rem;
              cursor: pointer;

              &:hover .img {
                transform: scale(1.05);
              }

              .img {
                object-fit: contain;
                width: 50%;
                height: 50%;
                transition: all 0.3s;
              }
            }

            .icon-box {
              width: 3rem;
              height: 3rem;
              background-color: #020202 !important;
              border-radius: 50%;
              display: grid;
              place-items: center;

              .icon {
                width: 0.9rem;
                height: 0.9rem;
                fill: #ffffff;
              }
            }
          }

          .box {
            padding: 1.5rem 1.7rem;
            border-radius: 0.8rem;
            display: flex;
            align-items: center;
            flex: 1;

            .text {
              margin-right: auto;
              margin-left: 1.5rem;
              font-size: 1.3rem;
              font-weight: 400;
            }

            .img-box {
              width: 1.65rem;
              height: 1.65rem;

              .img {
                object-fit: contain;
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        .text {
          font-size: 1.25rem;

          span {
            color: #755ae2 !important;
            cursor: pointer;
            font-weight: 600;
          }
        }
      }
    }
  }

  .right-box {
    flex: 1;
    // background-color: red;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    // overflow-y: scroll;

    .transaction-info-wrap {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow-y: scroll;

      .bottom-detail-box {
        position: relative;
        z-index: 1;
        flex: 1;
        padding-bottom: 3rem;
        overflow-y: scroll;
        gap: 2rem;

        &::-webkit-scrollbar {
          display: none;
        }

        .trans-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 1.5rem 4rem 1.5rem 2rem;

          .avatar-box {
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: .1rem;

            span {
              font-weight: 600;
              display: inline-block;
              font-size: 1.65rem;
              text-transform: uppercase;
            }
          }

          .name-email-box {
            display: flex;
            flex-direction: column;
            margin-right: auto;
            margin-left: 1.2rem;
            gap: 0.2rem;

            .name {
              font-size: 1.6rem;
              font-weight: 600;
            }

            .email {
              font-size: 1.3rem;
            }
          }

          .date-amount-box {
            text-align: right;

            .date {
              font-size: 1.2rem;
              font-weight: 500;
            }

            .amount {
              font-size: 1.55rem;
              color: #1ace37;
              font-weight: 500;
            }

         
          }
        }
      }

      .top-box {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        padding-left: 4rem;
        padding-right: 4rem;
        position: relative;
        z-index: 3;

        .filter-drop-box {
          .drop-box-wrap {
            position: absolute;
            border-radius: 1.2rem;
            top: 110%;
            right: 4rem;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            min-width: 30rem;
            gap: 2rem;
            animation: moveDownSettingDrop 0.2s ease-in-out backwards;
          }

          .filter-box {
            height: 100%;
            display: flex;
            flex-direction: row;
            gap: 0.8rem;
            align-items: center;
            padding: 1rem 1.5rem;
            border-radius: 0.8rem;
            cursor: pointer;

            .line-box {
              margin: auto 0rem;
              display: flex;
              flex-direction: column;
              gap: 0.25rem;
              width: 1.8rem;
              align-items: center;
              // align-self: center;

              span {
                display: inline-block;
                height: 0.15rem;
                width: 100%;
                border-radius: 1rem;
                background-color: #676767;
              }

              & > *:nth-child(2) {
                width: 70%;
              }

              & > *:last-child {
                width: 40%;
              }
            }

            .text {
              font-weight: 700;
            }
          }
        }

        .title-text-box {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .label-text {
            font-size: 1.25rem;
          }

          .title-box {
            display: flex;
            align-items: center;
            gap: 1rem;

            .title {
              font-size: 1.85rem;
              font-weight: 700;
            }

            .num {
              border-radius: 1.2rem;
              padding: 0.8rem 1rem;
              display: grid;
              place-items: center;

              span {
                font-weight: 700;
                font-size: 1.1rem;
                display: inline-block;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}