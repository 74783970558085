.dashboard-pos-index-wrap {
  display: flex;
  flex-direction: column;
  flex: 1;

  .top-detail-card-box-new {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    margin-bottom: 1rem;
    background-color: unset;

    @media only screen and (max-width: 55em) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 1.5rem;

      & > *:first-child {
        grid-row: 1 / span 1;
        grid-column: 1 / -1;
      }
    }

    .box {
      border-radius: 1.2rem;
      padding: 3rem 1rem 1rem 1rem;

      .title {
        font-weight: 700;
        padding-left: 2rem;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        // gap: 1rem;
        justify-content: space-between;

        .bottom-text-action {
          align-self: flex-start;
          display: flex;
          align-items: center;
          gap: 0.3rem;
          cursor: pointer;
          color: #755ae2;

          &:hover .icon {
            transform: translateY(0.1rem) translateX(0.15rem);
          }

          .text {
            font-size: 1.25rem;
            color: currentColor;
            font-weight: 700;
          }

          .icon {
            transform: translateY(0.1rem);
            color: currentColor;
            transition: all 0.3s;
          }
        }
      }

      .value-box {
        padding: 1.5rem 1rem 1.5rem 2rem;
        border-radius: 0.8rem;

        .value {
          font-weight: 700;
          font-size: 1.75rem;
          // color: #755ae2;
        }
      }
    }

    // & > * {
    //   display: flex;
    //   flex-direction: column;
    //   padding: 1.5rem;
    //   gap: 1rem;

    //   .top-card-info-wrap {
    //     flex: 1;
    //     border-radius: 0.8rem;
    //     padding: 1rem 1.5rem;
    //     display: flex;
    //     flex-direction: column;

    //     .img-wrap {
    //       margin-bottom: auto;
    //       display: flex;
    //       align-items: center;
    //       justify-content: space-between;

    //       .pos-select-wrap {
    //         width: max-content;

    //         .react-select-class {
    //           & > *:nth-child(3) {
    //             box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
    //               0px 0px 2px rgba(51, 51, 51, 0.02);
    //             min-height: unset;

    //             & > *:first-child {
    //               padding: unset;

    //               & > *:nth-child(2) {
    //                 // display: none;
    //                 input {
    //                   min-width: unset !important;
    //                   grid-area: unset !important;
    //                 }
    //               }
    //             }

    //             & > *:nth-child(2) {
    //               padding: unset;
    //               margin-left: 0.7rem;
    //               & > *:nth-child(2) {
    //                 padding: unset;

    //                 svg {
    //                   width: 1.5rem;
    //                   height: 1.5rem;
    //                   // fill: #020202;
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }

    //       .img-box {
    //         width: 4rem;
    //         height: 4rem;
    //         overflow: hidden;

    //         .img {
    //           width: 100%;
    //           height: 100%;
    //           object-fit: contain;
    //         }
    //       }
    //     }

    //     .label {
    //       font-size: 1.25rem;
    //       // text-transform: uppercase;
    //     }

    //     .value {
    //       font-weight: 700;
    //       font-size: 1.75rem;
    //       margin-top: 0.25rem;
    //     }

    //     .income {
    //       color: #1ace37;
    //       // &>*:nth-child()
    //     }

    //     .expense {
    //       color: #ff0f00;
    //     }
    //   }

    //   .bottom-text-action {
    //     align-self: flex-start;
    //     display: flex;
    //     align-items: center;
    //     gap: 0.3rem;
    //     cursor: pointer;

    //     &:hover .icon {
    //       transform: translateY(0.1rem) translateX(0.15rem);
    //     }

    //     .text {
    //       font-size: 1.35rem;
    //       color: currentColor;
    //       font-weight: 700;
    //     }

    //     .icon {
    //       transform: translateY(0.1rem);
    //       color: currentColor;
    //       transition: all 0.3s;
    //     }
    //   }
    // }

    // .wallet-card {
    //   border-radius: 1.2rem;
    //   background-color: #1b1b1b;
    //   position: relative;

    //   &>*{
    //     position: relative;
    //     z-index: 3;
    //   }

    //   &::after {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     z-index: 1;
    //     background-image: url("../../../assets/overview-available-bg.png");
    //     background-size: cover;
    //     border-radius: 1.2rem;
    //     background-repeat: no-repeat;

    //     @media only screen and (max-width: 42em) {
    //       border-radius: 1.2rem;
    //     }
    //   }

    //   .top-card-info-wrap {
    //     background-color: rgba(255, 255, 255, 0.1);
    //     border: 0.881673px solid rgba(208, 213, 221, 0.5);

    //     .value {
    //       color: #ffffff;
    //     }
    //   }

    //   .bottom-text-action {
    //     color: #ffffff;
    //   }
    // }

    // .income-card {
    //   border-radius: 1.2rem;

    //   .top-card-info-wrap {
    //     .img-wrap {
    //       .img-box {
    //         width: 4.2rem;
    //         height: 4.2rem;
    //         overflow: hidden;

    //         .img {
    //           width: 100%;
    //           height: 100%;
    //           object-fit: contain;
    //         }
    //       }
    //     }
    //   }

    //   .bottom-text-action {
    //     color: #755ae2;
    //   }
    // }
  }
}

.top-up-pos-modal-wrap-box {
  margin-top: -1rem;
  // display: none;
  .wrap {
    flex: 1;
    border-radius: 0.8rem;
    background-image: url("../../../assets/wire-frame-bg-img.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    // min-height: 20rem;
    gap: 1rem;

    .bttom-wrap {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    .bttom-wrap .box {
      display: flex;
      align-items: center;
      // display: none;
    }

    .bttom-wrap .box .label-value-box {
      margin-right: auto;
      // display: none;
    }

    .bttom-wrap .box .label-value-box .label {
      font-size: 1.1rem;
      margin-bottom: 0.3rem;
    }

    .bttom-wrap .box .label-value-box .value {
      font-weight: 600;
      font-size: 1.5rem;
    }

    .bttom-wrap .box .copy-box {
      box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
        0px 0px 2px rgba(51, 51, 51, 0.02);
      padding: 0.55rem 1.3rem;
      border-radius: 2rem;
      cursor: pointer;
      transition: all 0.3s;
    }

    .bttom-wrap .box .copy-box:hover {
      transform: scale(1.03);
    }

    .bttom-wrap .box .copy-box span {
      font-size: 1.1rem;
    }

    .bttom-wrap .box .copy-box-active {
      background-color: #020202 !important;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      /* color: rgba(26, 206, 55, .3); */
      gap: 0.4rem;
    }

    .bttom-wrap .box .copy-box-active:hover {
      transform: unset;
    }

    .bttom-wrap .box .copy-box-active span {
      color: #ffffff;
      display: inline-block;
    }

    .bttom-wrap .box .copy-box-active span .icon {
      margin-right: 0.5rem;
      color: currentColor;
      width: 1rem;
      height: 1rem;
      transform: translateY(0.06rem);
    }
  }
}

.view-pos-cashier-modal-wrap {
  display: flex;
  flex-direction: column;
  max-height: 55rem;
  overflow-y: scroll;

  &::-webkit-scrollbar:horizontal {
    display: none;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 30rem;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #cccccc;
  }

  .wrap {
    display: flex;
    flex-direction: column;
    border: unset;
    gap: 1.3rem;
    max-height: 40rem;
    overflow-y: scroll;

    &::-webkit-scrollbar:horizontal {
      display: none;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none !important;
      background-color: transparent !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #cccccc;
      border-radius: 30rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #cccccc;
    }

    & > *:not(:last-child) {
      // border-bottom: 0.09rem solid #e5e6e6;
      padding-bottom: 1.3rem;
    }

    & > *:last-child {
      border: unset;
    }
  }

  .child-row {
    display: flex;
    align-items: center;

    .icon-wrap {
      display: grid;
      place-items: center;

      .img-box {
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 50%;
        overflow: hidden;
        position: relative;

        .img {
          width: 75%;
          height: 75%;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }

    .text-box {
      display: flex;
      flex-direction: column;
      margin-left: 1.5rem;
      align-items: flex-start;

      .name {
        font-weight: 700;
        margin-bottom: 0.25rem;
        text-transform: capitalize;
      }

      .number {
        font-size: 1.2rem;
        text-align: left;
      }
    }

    .delete-wrap {
      flex: 0 0 10%;
      margin-left: auto;
      display: grid;
      place-items: center;

      .img-box {
        background-color: unset;
        width: 2rem;
        height: 2rem;
        display: grid;
        place-items: center;
        cursor: pointer;

        .img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.bottom-content-box-pos-index-new {
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .table-content-pos {
    flex: 1;
    overflow-y: scroll;
    // display: none;
    // background-color: blue;
    padding-bottom: 10rem;

    &::-webkit-scrollbar {
      display: none;
    }

    .status-type-wrap {
      background-color: #e9f5ff;
      border-radius: 3rem;
      width: max-content;
      padding: 0.8rem 1.2rem;
      color: #476885;
  
      span {
        font-weight: 400 !important;
        font-size: 1.2rem;
      }
    }
  
    .status-type-wrap-fail {
      background-color: #fff5f5;
      color: #ff0f00;
    }
  
    .status-type-wrap-success {
      background-color: #e8fff6;
      color: #1ace37;
    }
  
    .status-type-wrap-pending {
      background-color: #fff6ed;
      color: #ea872d;
    }
  
    .status-type-wrap-disabled {
      background-color: #f7f8f7;
      // color: #020202;
    }
  }
  .table-body-info-wrap {
    flex: 1;
    // background-color: red;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (max-width: 48em) {
      flex: unset;
    }
  }

  .type-text-box {
    display: flex;
    align-items: center;
    // background-color: red;
    width: max-content;

    .text-box {
      display: flex;
      flex-direction: column;
      gap: 0.7rem;

      .text {
        font-size: 1.55rem;
        font-weight: 600;
      }

      .text-small {
        font-size: 1.3rem;
        font-weight: 400 !important;
      }
    }

    .img-wrap {
      // flex: 0 0 5%;
      display: grid;
      place-items: center;

      .img-box-cre-deb {
        width: 3rem;
        height: 3rem;
        margin-right: 2rem;
        background-color: #1ace37;
        border-radius: 50%;
        display: grid;
        place-items: center;
        flex: 0 0 5%;

        .img {
          width: 35%;
          height: 35%;
          object-fit: contain;
          filter: brightness(1000%);
        }
      }

      .img-box-debit {
        background-color: #ff0f00;
      }

      .img-box-failed {
        background-color: #cccccc;
      }
    }
  }

  .all_terminal_transcation_filter-box {
    width: 100%;
    display: flex;
    align-items: center;
    border-width: 0.15rem;
    padding-bottom: 1rem;
    min-height: 6rem;
    margin-top: -1rem;

    .all-select-box {
      flex: 0 0 50%;
      display: flex;
      align-items: center;

      .select-view-wrap {
        display: flex;
        align-items: center;
        gap: 2.5rem;
        // border-bottom: 0.1rem solid #f0eeff;

        .option {
          // padding-bottom: 1.2rem;
          cursor: pointer;
          position: relative;
          transform: translateY(0.7rem);

          & > * {
            position: relative;
            z-index: 3;
          }

          &::after {
            content: "";
            position: absolute;
            height: 0.5rem;
            width: 100%;
            background-color: #020202;
            bottom: 0;
            left: 0;
            transform: translateY(1.8rem);
            visibility: hidden;
            border-radius: 3rem;
          }
        }

        .option-active {
          cursor: default;

          &::after {
            visibility: visible;
          }

          span {
            color: #020202;
            font-weight: 500;
          }
        }
      }
    }

    .search-filter-box-pos {
      // flex: 1;
      display: flex;
      align-items: center;
      margin-left: auto;
      gap: 2rem;

      .search-group {
        margin-right: auto;
        width: 35rem;

        @media only screen and (max-width: 57em) {
          margin-bottom: 1.5rem;
          // display: none;
          margin-right: unset;
          width: 100%;
        }
      }

      .pos-select-wrap {
        width: max-content;

        .react-select-class {
          // min-height: 55rem;
          // height: max-content;

          & > *:nth-child(3) {
            box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
              0px 0px 2px rgba(51, 51, 51, 0.02);
            // min-height: 40rem;
            //  height: max-content;
            overflow: unset;

            & > *:first-child {
              padding: unset;

              & > *:nth-child(2) {
                // display: none;
                input {
                  min-width: unset !important;
                  grid-area: unset !important;
                }
              }
            }

            & > *:nth-child(2) {
              padding: unset;
              margin-left: 0.7rem;
              & > *:nth-child(2) {
                padding: unset;

                svg {
                  width: 1.5rem;
                  height: 1.5rem;
                  // fill: #020202;
                }
              }
            }
          }
          & > *:nth-child(4) {
            width: max-content;
            left: unset;
            // background-color: red;
            right: 0;
            // height: 35rem;
            // min-height: unset;
          }
        }
      }
    }
  }

  .all-pending-active-terminals-box-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 4rem;
    margin-top: 2rem;

    .terminal-card {
      padding: 1rem 1.6rem;
      border-radius: 1.2rem;
      display: flex;
      flex-direction: column;
      border-width: 0.15rem;
      gap: 1rem;
      cursor: pointer;

      .icon-label-serial-box {
        display: flex;
        align-items: center;
        gap: 1rem;

        .icon-wrap {
          width: 5.5rem;
          height: 5.5rem;
          border-radius: 50%;
          // display: ;
          position: relative;

          .img-box {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            // background-color: red;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .label-serial-box {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .label {
            text-transform: capitalize;
            font-size: 1.7rem;
            font-weight: 600;
          }

          .serial {
            font-size: 1.25rem;
          }
        }
      }

      .cash-in-box-wrap {
        padding: 1rem;
        border-radius: 0.4rem;
        display: flex;
        flex-direction: column;
        gap: 0.6rem;

        .label {
          font-size: 1.35rem;
          text-transform: uppercase;
          font-weight: 600;
        }

        .value {
          font-size: 1.75rem;
          font-weight: 500;
        }
      }
    }

    .terminal-card-pending {
      position: relative;

      .pending-tractk-box {
        position: absolute;
        top: 1rem;
        right: 1rem;

        .terminal-badge {
          border-radius: 0.8rem;

          span {
            font-weight: 600;
          }
        }
      }

      .icon-label-serial-box {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        align-items: flex-start;
      }

      .cash-in-box-wrap {
        display: none;
      }
    }
  }

  .content-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 55em) {
      flex-direction: column;
      align-items: flex-start;

      .text-select-box {
        align-self: flex-end;
      }
    }

    // .title {
    // }

    .text-select-box {
      display: flex;
      align-items: center;
      gap: 1rem;

      .text {
        font-weight: 600;
      }

      .pos-select-wrap {
        width: max-content;

        .react-select-class {
          // min-height: 55rem;
          // height: max-content;

          & > *:nth-child(3) {
            box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
              0px 0px 2px rgba(51, 51, 51, 0.02);
            // min-height: 40rem;
            //  height: max-content;
            overflow: unset;

            & > *:first-child {
              padding: unset;

              & > *:nth-child(2) {
                // display: none;
                input {
                  min-width: unset !important;
                  grid-area: unset !important;
                }
              }
            }

            & > *:nth-child(2) {
              padding: unset;
              margin-left: 0.7rem;
              & > *:nth-child(2) {
                padding: unset;

                svg {
                  width: 1.5rem;
                  height: 1.5rem;
                  // fill: #020202;
                }
              }
            }
          }
          & > *:nth-child(4) {
            width: max-content;
            left: unset;
            // background-color: red;
            right: 0;
            // height: 35rem;
            // min-height: unset;
          }
        }
      }
    }
  }

  .terminal-show-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    // width: 95%;
    margin-top: 2rem;
    padding-bottom: 4rem;

    @media only screen and (max-width: 42em) {
      grid-template-columns: 1fr;
    }

    .terminal-wrap-box {
      // min-height: 30rem;
      display: flex;
      flex-direction: column;
      border-radius: 1.2rem;
      padding: 1.5rem 2rem;
      cursor: pointer;

      .top-img-box {
        height: 15rem;
        display: none;
        width: 100%;
        background-image: url("../../../assets/raven-terminal-bg-img.png");
        background-position: bottom center;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 0.8rem;
        // opacity: .3;
      }

      .income-expense-box {
        display: flex;
        gap: 5rem;

        .box {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 1.5rem;

          .img-wrap {
            // flex: 0 0 5%;
            display: grid;
            place-items: center;

            .img-box-cre-deb {
              width: 3rem;
              height: 3rem;
              // margin-right: 2rem;
              background-color: #1ace37 !important;
              border-radius: 50%;
              display: grid;
              place-items: center;
              flex: 0 0 5%;

              .img {
                width: 35%;
                height: 35%;
                object-fit: contain;
                filter: brightness(1000%);
              }
            }

            .img-box-debit {
              background-color: #ff0f00 !important;
            }

            .img-box-failed {
              background-color: #f7f8f7;
            }
          }

          .text-box {
            .label {
              font-size: 1.15rem;
              text-transform: uppercase;
            }

            .value {
              font-size: 1.6rem;
              font-weight: 700;
              margin-top: 0.3rem;
            }

            .income {
              color: #1ace37;
            }

            .expense {
              color: #ff0f00;
            }
          }
        }
      }

      .middle-content-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: unset;
        border-right: unset;
        border-left: unset;
        margin: 1.5rem 0rem;
        padding-bottom: 1.5rem;

        .title-label-box {
          display: flex;
          flex-direction: column;
          gap: 0.3rem;

          .label {
            font-size: 1.25rem;
            display: flex;
            align-items: center;
            gap: 0.3rem;

            span {
              display: inline-block;
            }
          }

          .title-box {
            display: flex;
            align-items: center;
            gap: 0.6rem;

            .title {
              font-weight: 700;
              font-size: 1.7rem;
            }

            span {
              width: 0.9rem;
              height: 0.9rem;
              border-radius: 50%;
              display: none;
              background-color: #1ace37;
            }
          }
        }

        .status-type-wrap {
          background-color: #e8fff6;
          border-radius: 3rem;
          width: -moz-max-content;
          width: max-content;
          padding: 0.8rem 1.2rem;
          text-transform: capitalize;
        }
        .status-type-wrap span {
          font-size: 1.2rem;
        }
        .status-type-wrap-success {
          color: #1ace37;
        }
        .status-type-wrap-disabled {
          background-color: #f7f8f7;
          // color: #ffffff;
        }
      }
    }

    .new-terminal-child-box {
      display: flex;
      flex-direction: column;
      border-radius: 1.2rem;
      padding: 1.5rem 2rem;
      cursor: pointer;

      .label-serial-no-box {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;

        .label {
          font-size: 2rem;
          font-weight: 600;
        }

        .serial-no {
          font-size: 1.3rem;
        }
      }

      .income-expense-wrap {
        padding: 2.5rem 1.5rem;
        border-radius: 1.2rem;
        margin-top: 1.5rem;

        .status-type-wrap {
          display: flex;
          align-items: center;
          gap: 1.5rem;
          margin-top: 2rem;
          background-color: transparent !important;

          .box {
            padding: 0.8rem 1.5rem;
            border-radius: 0.8rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .text {
              font-size: 1.45rem;
            }

            .img-wrap {
              .img-box {
                width: 2rem;
                height: 2rem;

                .img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }
        }

        .income-expense-box {
          display: flex;
          gap: 1.5rem;
          align-items: center;

          .box {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;

            .value {
              font-weight: 700;
              font-size: 2rem;
            }

            .income {
              color: #1ace37;
            }

            .expense {
              color: rgba(255, 15, 0, 1);
            }
          }

          .income-box {
            padding-right: 1.5rem;
          }
        }
      }
    }
  }
}

.dashboard-pos-index-wrap .top-detail-card-box {
  min-height: 20rem;
  margin-bottom: 1rem;
  border: 1px solid rgba(208, 213, 221, 0.5);
  box-shadow: -1px 0px 20px rgba(229, 230, 230, 0.35);
  border-radius: 1.6rem;
  padding: 1.5rem 2rem;
  display: flex;

  @media only screen and (max-width: 52em) {
    flex-direction: column;
    gap: 2rem;
  }
}
.dashboard-pos-index-wrap .top-detail-card-box .left-box {
  flex: 0 0 57%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 52em) {
    flex: unset;
    width: 100%;
    gap: 1.5rem;
  }
}
.dashboard-pos-index-wrap .top-detail-card-box .left-box .wallet-cash-box {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-top: 1.5rem;

  @media only screen and (max-width: 52em) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }
}
.dashboard-pos-index-wrap
  .top-detail-card-box
  .left-box
  .wallet-cash-box
  > *:not(:last-child) {
  border-right: 0.1rem solid #f0eeff;
  padding-right: 3rem;
}
.dashboard-pos-index-wrap
  .top-detail-card-box
  .left-box
  .wallet-cash-box
  .box
  .label {
  font-size: 1.1rem !important;
  margin-top: 0.3rem;
}
.dashboard-pos-index-wrap
  .top-detail-card-box
  .left-box
  .wallet-cash-box
  .box
  .value {
  font-weight: 600;
  font-size: 2rem;

  @media only screen and (max-width: 52em) {
    font-size: 1.5rem;
  }
}
.dashboard-pos-index-wrap .top-detail-card-box .left-box .btn-two-box {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media only screen and (max-width: 52em) {
    margin-top: 1.5rem;
  }
}
.dashboard-pos-index-wrap
  .top-detail-card-box
  .left-box
  .btn-two-box
  .raven-btn {
  min-width: 19rem;

  @media only screen and (max-width: 52em) {
    min-width: unset;
  }
}
.dashboard-pos-index-wrap .top-detail-card-box .right-box {
  flex: 1;
  display: flex;

  @media only screen and (max-width: 52em) {
    flex: unset;
    order: -1;
    min-height: 20rem;
  }
}
.dashboard-pos-index-wrap .top-detail-card-box .right-box .wrap {
  flex: 1;
  border-radius: 0.8rem;
  background-image: url("../../../assets/wire-frame-bg-img.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.dashboard-pos-index-wrap .top-detail-card-box .right-box .wrap .top-wrap {
  padding: 1.8rem 2rem;
  background-color: rgba(204, 204, 204, 0.2);
}
.dashboard-pos-index-wrap
  .top-detail-card-box
  .right-box
  .wrap
  .top-wrap
  .text {
  font-size: 1.3rem;
}
.dashboard-pos-index-wrap .top-detail-card-box .right-box .wrap .bttom-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0rem 2rem;
}
.dashboard-pos-index-wrap
  .top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box {
  display: flex;
  align-items: center;
}
.dashboard-pos-index-wrap
  .top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .label-value-box {
  margin-right: auto;
}
.dashboard-pos-index-wrap
  .top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .label-value-box
  .label {
  font-size: 1.1rem;
  margin-bottom: 0.3rem;
}
.dashboard-pos-index-wrap
  .top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .label-value-box
  .value {
  font-weight: 600;
  font-size: 1.5rem;
}
.dashboard-pos-index-wrap
  .top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .copy-box {
  box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
    0px 0px 2px rgba(51, 51, 51, 0.02);
  padding: 0.55rem 1.3rem;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.3s;
}
.dashboard-pos-index-wrap
  .top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .copy-box:hover {
  transform: scale(1.03);
}
.dashboard-pos-index-wrap
  .top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .copy-box
  span {
  font-size: 1.1rem;
}
.dashboard-pos-index-wrap
  .top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .copy-box-active {
  background-color: #020202 !important;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: rgba(26, 206, 55, .3); */
  gap: 0.4rem;
}
.dashboard-pos-index-wrap
  .top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .copy-box-active:hover {
  transform: unset;
}
.dashboard-pos-index-wrap
  .top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .copy-box-active
  span {
  color: #ffffff;
  display: inline-block;
}
.dashboard-pos-index-wrap
  .top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .copy-box-active
  span
  .icon {
  margin-right: 0.5rem;
  color: currentColor;
  width: 1rem;
  height: 1rem;
  transform: translateY(0.06rem);
}

.bottom-content-box-pos-index {
  width: 100%;
  padding: 2rem;
  margin: 0rem auto;

  @media only screen and (max-width: 48em) {
    padding: unset;
    margin: 3rem auto 5rem auto;
    width: 90%;
  }

  .table {
    @media only screen and (max-width: 48em) {
      display: none;
    }
  }
}
.bottom-content-box-pos-index .status-type-wrap {
  background-color: #e8fff6;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  text-transform: capitalize;
}
.bottom-content-box-pos-index .status-type-wrap span {
  font-size: 1.2rem;
}
.bottom-content-box-pos-index .status-type-wrap-success {
  color: #1ace37;
}
.bottom-content-box-pos-index .status-type-wrap-disabled {
  background-color: #f7f8f7;
  //   color: #020202;
}

.cashier-assign-modal-wrap {
  .step-progress-wrap-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    width: 80%;
    margin: 2rem 0rem;

    .progress-bar-wrap-reuseable {
      background-color: #d1d1d1;
      cursor: pointer;
    }
  }

  .step-process-wrap-box {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;

    .note-box-wrap {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      background-color: rgba(255, 246, 237, 1);
      border-radius: 0.8rem;
      margin-bottom: 1rem;

      .img-box {
        width: 3rem;
        height: 3rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .text {
        font-size: 1.2rem;
        color: rgba(234, 135, 45, 1);
      }
    }

    .back-box {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 2rem;

      &:hover .icon {
        animation: moveBackFort 0.3s ease-in-out;
      }

      .icon {
        width: 1.45rem;
        height: 1.45rem;
        margin-right: 0.1rem;
        fill: rgba(80, 95, 109, 0.32);
        transform: scale(1.1);
      }

      .text {
        font-size: 1.6rem;
        text-transform: capitalize;
        font-weight: 600;
      }
    }

    .step-box {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 1.5rem;

      .box {
        display: flex;
        align-items: center;
        gap: 0.4rem;

        .num {
          width: 3.2rem;
          height: 3.2rem;
          border-radius: 50%;
          background-color: rgba(189, 189, 189, 0.2);
          display: grid;
          place-items: center;
          // cursor: pointer;

          span {
            color: rgba(22, 45, 76, 0.4);
            font-size: 1.2rem;
          }
        }

        .text {
          margin-top: 0.1rem;
          color: #bdbdbd;
          font-size: 1.2rem;

          @media only screen and (max-width: 42em) {
            display: none;
          }
        }
      }

      .box-active {
        .num {
          background-color: #333333;

          span {
            color: #ffffff;
            font-weight: 600;
          }
        }

        .text {
          color: #020202;

          @media only screen and (max-width: 42em) {
            display: none;
          }
        }
      }
    }
  }
}

.cashier-assign-modal-wrap .title {
  font-weight: 700;
  font-size: 1.9rem;
  margin-bottom: 0.5rem;
}
.cashier-assign-modal-wrap .text {
  font-size: 1.45rem;
  width: 90%;
}
.cashier-assign-modal-wrap .underline {
  width: 100%;
  height: 0.1rem;
  background-color: #f0eeff;
  margin: 1.5rem 0rem;
}
.cashier-assign-modal-wrap .form-inputs-wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  // margin-bottom: 3rem;
  min-height: 37rem;
  overflow-y: scroll;
  padding: 0rem 0.1rem 3rem 0.1rem;

  @media only screen and (max-width: 48em) {
    // min-height: 50vh;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.terminal-information-top-detail-card-box {
  min-height: 20rem;
  margin-bottom: 1rem;
  box-shadow: -1px 0px 20px rgba(229, 230, 230, 0.35);
  border-radius: 1.6rem;
  padding: 1.5rem 2rem;
  display: flex;

  @media only screen and (max-width: 52em) {
    flex-direction: column;
    min-height: unset;
  }
}
.terminal-information-top-detail-card-box .left-box {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  margin-right: auto;

  @media only screen and (max-width: 52em) {
    flex: unset;
    width: 100%;
    gap: 1.5rem;
  }
}
.terminal-information-top-detail-card-box .left-box .top-label-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: 52em) {
    flex: unset;
    width: 100%;
    // flex-direction: column;
    gap: 1.5rem;
  }
}
.terminal-information-top-detail-card-box
  .left-box
  .top-label-box
  .wrap-filter {
  width: 10rem;
  position: relative;
  z-index: 10;
}
.terminal-information-top-detail-card-box
  .left-box
  .top-label-box
  .wrap-filter
  .react-select-class
  > *:nth-child(3) {
  padding: 0rem;
  flex: unset;
  justify-content: space-evenly;
}
.terminal-information-top-detail-card-box
  .left-box
  .top-label-box
  .wrap-filter
  .react-select-class
  > *:nth-child(3)
  > *:first-child {
  padding-right: 0rem;
  flex: unset;
}
.terminal-information-top-detail-card-box
  .left-box
  .top-label-box
  .wrap-filter
  .react-select-class
  > *:nth-child(3)
  > *:nth-child(2)
  > *:nth-child(2) {
  padding: unset;
}
.terminal-information-top-detail-card-box
  .left-box
  .top-label-box
  .wrap-filter
  .react-select-class
  > *:nth-child(4) {
  left: 0;
}
.terminal-information-top-detail-card-box .left-box .wallet-cash-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;

  @media only screen and (max-width: 52em) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.terminal-information-top-detail-card-box
  .left-box
  .wallet-cash-box
  > *:not(:last-child) {
  border-right: 0.1rem solid #f0eeff;
  padding-right: 3rem;
}
.terminal-information-top-detail-card-box
  .left-box
  .wallet-cash-box
  .box
  .label {
  font-size: 1.1rem !important;
  margin-top: 0.3rem;
}
.terminal-information-top-detail-card-box
  .left-box
  .wallet-cash-box
  .box
  .value {
  font-weight: 600;
  font-size: 2rem;
}
.terminal-information-top-detail-card-box .right-box {
  flex: 0 0 43%;
  display: flex;

  @media only screen and (max-width: 52em) {
    flex: unset;
    width: 100%;
    order: -1;
    margin-bottom: 2.5rem;
  }
}
.terminal-information-top-detail-card-box .right-box .wrap {
  flex: 1;
  border-radius: 0.8rem;
  background-image: url("../../../assets/wire-frame-bg-img.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.terminal-information-top-detail-card-box .right-box .wrap .top-wrap {
  padding: 1.8rem 2rem;
  background-color: rgba(204, 204, 204, 0.2);
}
.terminal-information-top-detail-card-box .right-box .wrap .top-wrap .text {
  font-size: 1.3rem;
}
.terminal-information-top-detail-card-box .right-box .wrap .bttom-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0rem 2rem;
}
.terminal-information-top-detail-card-box .right-box .wrap .bttom-wrap .box {
  display: flex;
  align-items: center;
}
.terminal-information-top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .label-value-box {
  margin-right: auto;
}
.terminal-information-top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .label-value-box
  .label {
  font-size: 1.1rem;
  margin-bottom: 0.3rem;
}
.terminal-information-top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .label-value-box
  .value {
  font-weight: 600;
  font-size: 1.5rem;
}
.terminal-information-top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .copy-box {
  box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
    0px 0px 2px rgba(51, 51, 51, 0.02);
  padding: 0.55rem 1.3rem;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.3s;
}
.terminal-information-top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .copy-box:hover {
  transform: scale(1.03);
}
.terminal-information-top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .copy-box
  span {
  font-size: 1.1rem;
}
.terminal-information-top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .copy-box-active {
  background-color: #020202;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
}
.terminal-information-top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .copy-box-active:hover {
  transform: unset;
}
.terminal-information-top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .copy-box-active
  span {
  color: #ffffff;
  display: inline-block;
}
.terminal-information-top-detail-card-box
  .right-box
  .wrap
  .bttom-wrap
  .box
  .copy-box-active
  span
  .icon {
  margin-right: 0.5rem;
  color: currentColor;
  width: 1rem;
  height: 1rem;
  transform: translateY(0.06rem);
}

.terminal-info-table-wrap-box {
  // background-color: red;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .terminal-table-sort-box {
    padding: 0rem 3rem;
    margin: 2rem 0rem;

    .title-text-box {
      .title {
        font-weight: 700;
        font-size: 1.8rem;
      }

      .text {
        font-size: 1.25rem;
      }
    }
  }

  // .table-content-pos{
  //   fl
  // }

  .status-type-wrap {
    background-color: #e9f5ff;
    border-radius: 3rem;
    width: max-content;
    padding: 0.8rem 1.2rem;
    color: #476885;

    span {
      font-weight: 400 !important;
      font-size: 1.2rem;
    }
  }

  .status-type-wrap-fail {
    background-color: #fff5f5;
    color: #ff0f00;
  }

  .status-type-wrap-success {
    background-color: #e8fff6;
    color: #1ace37;
  }

  .status-type-wrap-pending {
    background-color: #fff6ed;
    color: #ea872d;
  }

  .status-type-wrap-disabled {
    background-color: #f7f8f7;
    // color: #020202;
  }

  width: 100%;

  @media only screen and (max-width: 48em) {
    width: 90%;
    margin: 2rem auto;
  }

  .table {
    @media only screen and (max-width: 48em) {
      display: none;
    }
  }

  .transaction-action-wrap {
    // color: rgba(255, 15, 0, .3);
    // margin: 0rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: red;
    gap: 1rem;
    // width: 100%;
    // min-width: 10rem;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);

    .img-box {
      width: 3.5rem;
      height: 3.5rem;
      // background-color: #f7f8f7;
      border-radius: 50%;
      display: grid;
      place-items: center;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }

      .img {
        width: 50%;
        height: 50%;
        object-fit: contain;
      }
    }

    & > *:nth-child(2) {
      .img {
        width: 50%;
        height: 50%;
        object-fit: contain;
      }
    }
  }
}
.terminal-info-table-wrap-box .type-text-box {
  display: flex;
  align-items: center;
  // background-color: red;
  width: max-content;

  .text-box {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;

    .text {
      font-size: 1.55rem;
      font-weight: 600;
    }

    .text-small {
      font-size: 1.3rem;
      font-weight: 400 !important;
    }
  }

  .img-wrap {
    // flex: 0 0 5%;
    display: grid;
    place-items: center;

    .img-box-cre-deb {
      width: 3rem;
      height: 3rem;
      margin-right: 2rem;
      background-color: #1ace37;
      border-radius: 50%;
      display: grid;
      place-items: center;
      flex: 0 0 5%;

      .img {
        width: 35%;
        height: 35%;
        object-fit: contain;
        filter: brightness(1000%);
      }
    }

    .img-box-debit {
      background-color: #ff0f00;
    }

    .img-box-failed {
      background-color: #cccccc;
    }
  }
}
.terminal-info-table-wrap-box .title-sort-text-box {
  display: flex;
  align-items: center;
  padding: 1rem 3rem;
  margin: 1rem 0rem;

  @media only screen and (max-width: 52em) {
    flex-direction: column;
    padding: 1rem 1.5rem;
    gap: 1.3rem;
  }

  @media only screen and (max-width: 48em) {
    // flex-direction: column;
    padding: 0rem;
    // gap: 2rem;
  }
}
.terminal-info-table-wrap-box .title-sort-text-box .title {
  margin-right: auto;
  font-weight: 700;
  font-size: 1.5rem;
}
.terminal-info-table-wrap-box .title-sort-text-box .title-text {
  margin-right: auto;
}
.terminal-info-table-wrap-box .title-sort-text-box .title-text .text {
  font-size: 1.25rem;
}
.terminal-info-table-wrap-box .title-sort-text-box .title-text .title {
  font-weight: 700;
  font-size: 1.5rem;
}
.terminal-info-table-wrap-box .title-sort-text-box .fitler-box {
  display: flex;
  //   grid-template-columns: repeat(3, max-content) ;
  gap: 2rem;
  align-items: center;
  //   background-color: red;

  @media only screen and (max-width: 48em) {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0rem;
    gap: 2rem;
    align-items: flex-start;
    margin-bottom: 2rem;
  }
}

.terminal-info-table-wrap-box .title-sort-text-box .fitler-box .reset-text {
  color: var(--primary-purple);
  font-weight: 600;
  cursor: pointer;
  min-width: 9rem;
}
// .terminal-info-table-wrap-box
//   .title-sort-text-box
//   .fitler-box
//   .react-select-class
//   > *:nth-child(3) {
//   //   min-width: 29rem;
// }

.terminal-info-table-wrap-box
  .title-sort-text-box
  .fitler-box
  .react-select-class
  > *:nth-child(4) {
  left: unset;
  right: 0;
}
.terminal-info-table-wrap-box
  .title-sort-text-box
  .fitler-box
  .filter-by-source
  .react-select-class
  > *:nth-child(3) {
  min-width: unset;
  width: -moz-max-content;
  //   width: max-content;
} /*# sourceMappingURL=DashboardPosIndex.css.map */

.verify-name-box {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: -1rem;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  width: 100%;

  & > *:first-child .label-check {
    border-radius: 50%;
    transform: scale(0.8);
  }
  .text {
    // color: var(--primary-black);
    font-weight: 600;
    font-size: 1.3rem;
    margin-left: 0.5rem;
  }
}

.verify-name-box-show {
  max-height: 5rem;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}

.cashier-assign-modal-wrap-settings {
  // margin-bottom: 2rem;

  .underline {
    margin-bottom: 3rem;
  }

  .form-inputs-wrap {
    margin-top: 2rem;
  }

  .verify-name-box {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: -1rem;
    max-height: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    width: 100%;

    & > *:first-child .label-check {
      border-radius: 50%;
      transform: scale(0.8);
    }
    .text {
      // color: var(--primary-black);
      font-weight: 600;
      font-size: 1.3rem;
      margin-left: 0.5rem;
    }
  }

  .verify-name-box-show {
    max-height: 5rem;
    transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  }

  .detail-tex-setting-director-box {
    display: flex;
    flex-direction: column;
    // margin: 1rem 0rem;
    gap: 1.5rem;

    .box {
      width: 100%;
      display: grid;
      grid-template-columns: 40% 1fr;
      font-size: 1.25rem;

      .value {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        font-weight: 700;

        .num {
          flex: 0 0 55%;
        }

        span {
          // margin-left: auto;
          display: inline-block;
          color: #755ae2;
          cursor: pointer;
        }
      }
    }
  }
}

.terminal-info-table-wrap-box {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  .new-search-filter-chart-box {
    // position: ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 3rem;
    margin: 2rem 0rem;
    // background-color: red;

    .all-text {
      font-weight: 600;
      font-size: 2rem;
      margin-right: auto;
    }

    .chart-hide-show-box {
      .hide-show-box {
        display: flex;
        align-items: center;
        gap: 0.7rem;

        @media only screen and (max-width: 42em) {
          display: none;
        }

        & > *:last-child {
          transform: scale(0.8);
        }

        span {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
    }

    .search-filter-box {
      display: flex;
      align-items: stretch;
      justify-content: unset;
      gap: 1.5rem;
      // width: 100%;
      // background-color: red;
      gap: 2rem;
      position: relative;
      z-index: 100;

      .badge-filter-option-box {
        display: flex;
        align-items: center;
        gap: 2rem;

        .option-box {
          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 1rem 1.5rem;
          border-radius: 2rem;

          .icon {
            cursor: pointer;
            transform: translateY(0.1rem);
          }
        }
      }

      .export-table-content-box {
        display: grid;
        place-items: center;
        padding: 0.5rem 1.5rem;
        border-radius: 0.8rem;
        cursor: pointer;
        // margin-left: -2rem;

        &:hover .img-box {
          transform: translateY(0.4rem);
        }

        .img-box {
          width: 2.2rem;
          height: 2.2rem;
          transition: all 0.3s;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .filter-drop-box {
        position: relative;
        // margin-left: auto;

        .drop-box-wrap {
          position: absolute;
          border-radius: 1.2rem;
          top: 110%;
          right: 0;
          padding: 2rem;
          display: flex;
          flex-direction: column;
          min-width: 30rem;
          gap: 2rem;
          animation: moveDownSettingDrop 0.2s ease-in-out backwards;
        }

        .filter-box {
          height: 100%;
          display: flex;
          flex-direction: row;
          gap: 0.8rem;
          align-items: center;
          padding: 1rem 1.5rem;
          border-radius: 0.8rem;
          cursor: pointer;

          .line-box {
            margin: auto 0rem;
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            width: 1.8rem;
            align-items: center;
            // align-self: center;

            span {
              display: inline-block;
              height: 0.15rem;
              width: 100%;
              border-radius: 1rem;
              background-color: #676767;
            }

            & > *:nth-child(2) {
              width: 70%;
            }

            & > *:last-child {
              width: 40%;
            }
          }

          .text {
            font-weight: 700;
          }
        }
      }
    }
  }

  .table-content-pos {
    flex: 1;
    overflow-y: scroll;
    // display: none;
    // background-color: blue;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .table-body-info-wrap {
    flex: 1;
    // background-color: red;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (max-width: 48em) {
      flex: unset;
    }
  }
}

.pos-settlement-wrap-modal {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .title-text-box {
    .title {
      font-weight: 700;
      font-size: 1.75rem;
    }

    .text {
      font-size: 1.35rem;
    }
  }

  .content-box {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    // gap: 2.5rem;

    .accout-wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      padding: 2.5rem 1.5rem;
      grid-row-gap: 2rem;
      background-image: url("../../../assets/wire-frame-bg-img.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top right;
      border-radius: 1.2rem;

      & > *:last-child {
        grid-column: 1 / -1;
      }

      .box {
        .label {
          font-size: 1.3rem;
        }

        .value {
          font-weight: 700;
          font-size: 1.3rem;
        }
      }
    }

    .select-type-box {
      display: flex;
      align-items: center;
      gap: 2.5rem;
      justify-content: flex-start;
      margin-bottom: 2.5rem;

      .type-child {
        position: relative;
        padding-bottom: 1.2rem;
        cursor: pointer;

        &::after {
          content: "";
          position: absolute;
          top: 90%;
          left: 0;
          height: 0.25rem;
          width: 100%;
          border-radius: 1rem;
        }

        span {
          position: relative;
          z-index: 2;
          font-size: 1.45rem;
        }
      }

      .type-child-active {
        span {
          font-weight: 700;
        }
      }
    }

    .form-input-wraps {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      // display: none;

      .note-box-wrap {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        background-color: rgba(255, 246, 237, 1);
        border-radius: 0.8rem;
        margin-bottom: 1rem;

        .img-box {
          width: 3rem;
          height: 3rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .text {
          font-size: 1.2rem;
          color: rgba(234, 135, 45, 1);
        }
      }
    }

    .toggle-slide-text-box {
      display: flex;
      align-items: center;
      margin-top: 2rem;
      justify-content: space-between;
    }
    .toggle-slide-text-box > *:last-child {
      align-self: center;
      transform: scale(0.8);
    }
    .toggle-slide-text-box .text-info-box {
      display: flex;
      align-items: center;
    }
    .toggle-slide-text-box .text-info-box .img-box {
      width: 1.2rem;
      height: 1.2rem;
      display: grid;
      place-items: center;
      margin-left: 0.7rem;
    }
    .toggle-slide-text-box .text-info-box .img-box .img {
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }
    .toggle-slide-text-box .text-info-box .text {
      font-size: 1.2rem;
    }
  }
}

.top-pos-single-detail-card-box-new {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  margin-bottom: 1rem;
  background-color: unset;

  @media only screen and (max-width: 55em) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1.5rem;

    & > *:first-child {
      grid-row: 1 / span 1;
      grid-column: 1 / -1;
    }
  }

  .box {
    border-radius: 1.2rem;
    padding: 3rem 1rem 1rem 1rem;
    // cursor: pointer;

    .title {
      font-weight: 700;
      padding-left: 2rem;
      margin-bottom: 1rem;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      // gap: 1rem;
      justify-content: space-between;

      .bottom-text-action {
        align-self: flex-start;
        display: flex;
        align-items: center;
        gap: 0.3rem;
        cursor: pointer;
        color: #755ae2;

        &:hover .icon {
          transform: translateY(0.1rem) translateX(0.15rem);
        }

        .text {
          font-size: 1.25rem;
          color: currentColor;
          font-weight: 700;
        }

        .icon {
          transform: translateY(0.1rem);
          color: currentColor;
          transition: all 0.3s;
        }
      }
    }

    .value-box {
      padding: 1.5rem 1rem 1.5rem 2rem;
      border-radius: 0.8rem;

      .value {
        font-weight: 700;
        font-size: 1.75rem;
        // color: #755ae2;
      }
    }
  }

  .card-terminal-info {
    display: flex;
    flex-direction: column;

    .terminal-detail-box {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
      margin-top: 2rem;

      .terminal-label {
        font-size: 1.35rem;
      }

      .account-no {
        font-size: 1.8rem;
        font-weight: 600;

        span {
          font-size: 1.35rem;
          font-weight: 400;
        }
      }
    }

    .img-wrap {
      margin-bottom: auto;
      width: max-content;
      margin-top: -2rem;

      .img-box {
        width: 5rem;
        height: 5rem;
        display: grid;
        justify-content: flex-end;
        align-items: flex-end;
        border-radius: 50%;
        overflow: hidden;

        .img {
          width: 90%;
          height: 90%;
          object-fit: contain;
        }
      }
    }
  }

  // & > * {
  //   display: flex;
  //   flex-direction: column;
  //   padding: 1.5rem;
  //   gap: 1rem;

  //   .top-card-info-wrap {
  //     flex: 1;
  //     border-radius: 0.8rem;
  //     padding: 1rem 1.5rem;
  //     display: flex;
  //     flex-direction: column;

  //     .img-wrap {
  //       margin-bottom: auto;
  //       display: flex;
  //       align-items: center;
  //       justify-content: space-between;

  //       .pos-select-wrap {
  //         width: max-content;

  //         .react-select-class {
  //           & > *:nth-child(3) {
  //             box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
  //               0px 0px 2px rgba(51, 51, 51, 0.02);
  //             min-height: unset;

  //             & > *:first-child {
  //               padding: unset;

  //               & > *:nth-child(2) {
  //                 // display: none;
  //                 input {
  //                   min-width: unset !important;
  //                   grid-area: unset !important;
  //                 }
  //               }
  //             }

  //             & > *:nth-child(2) {
  //               padding: unset;
  //               margin-left: 0.7rem;
  //               & > *:nth-child(2) {
  //                 padding: unset;

  //                 svg {
  //                   width: 1.5rem;
  //                   height: 1.5rem;
  //                   // fill: #020202;
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }

  //       .img-box {
  //         width: 4rem;
  //         height: 4rem;
  //         overflow: hidden;

  //         .img {
  //           width: 100%;
  //           height: 100%;
  //           object-fit: contain;
  //         }
  //       }
  //     }

  //     .label {
  //       font-size: 1.25rem;
  //       // text-transform: uppercase;
  //     }

  //     .value {
  //       font-weight: 700;
  //       font-size: 1.75rem;
  //       margin-top: 0.25rem;
  //     }

  //     .income {
  //       color: #1ace37;
  //       // &>*:nth-child()
  //     }

  //     .expense {
  //       color: #ff0f00;
  //     }
  //   }

  //   .bottom-text-action {
  //     align-self: flex-start;
  //     display: flex;
  //     align-items: center;
  //     gap: 0.3rem;
  //     cursor: pointer;

  //     &:hover .icon {
  //       transform: translateY(0.1rem) translateX(0.15rem);
  //     }

  //     .text {
  //       font-size: 1.35rem;
  //       color: currentColor;
  //       font-weight: 700;
  //     }

  //     .icon {
  //       transform: translateY(0.1rem);
  //       color: currentColor;
  //       transition: all 0.3s;
  //     }
  //   }
  // }

  // .wallet-card {
  //   border-radius: 1.2rem;
  //   background-color: #1b1b1b;
  //   position: relative;

  //   &>*{
  //     position: relative;
  //     z-index: 3;
  //   }

  //   &::after {
  //     content: "";
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 100%;
  //     z-index: 1;
  //     background-image: url("../../../assets/overview-available-bg.png");
  //     background-size: cover;
  //     border-radius: 1.2rem;
  //     background-repeat: no-repeat;

  //     @media only screen and (max-width: 42em) {
  //       border-radius: 1.2rem;
  //     }
  //   }

  //   .top-card-info-wrap {
  //     background-color: rgba(255, 255, 255, 0.1);
  //     border: 0.881673px solid rgba(208, 213, 221, 0.5);

  //     .value {
  //       color: #ffffff;
  //     }
  //   }

  //   .bottom-text-action {
  //     color: #ffffff;
  //   }
  // }

  // .income-card {
  //   border-radius: 1.2rem;

  //   .top-card-info-wrap {
  //     .img-wrap {
  //       .img-box {
  //         width: 4.2rem;
  //         height: 4.2rem;
  //         overflow: hidden;

  //         .img {
  //           width: 100%;
  //           height: 100%;
  //           object-fit: contain;
  //         }
  //       }
  //     }
  //   }

  //   .bottom-text-action {
  //     color: #755ae2;
  //   }
  // }
}

.verify-name-box-beneficiary-settings {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: -1rem;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  width: 100%;

  & > *:first-child .label-check {
    border-radius: 50%;
    transform: scale(0.8);
  }
  .text {
    // color: var(--primary-black);
    font-weight: 600;
    font-size: 1.3rem;
    margin-left: 0.5rem;
  }
}

.verify-name-box-show-beneficiary-settings {
  max-height: 5rem;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}

.add-role-to-for-sub-account-modal-wrap {
  max-height: 60rem;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .role-select-wrap-box {
    display: flex;
    flex-direction: column;
    // gap: 1.5rem;
    border-radius: 1.2rem;
    border-width: 0.15rem;
    max-height: 50rem;
    // background-color: red;
    padding-bottom: 1rem;
    margin-bottom: 10rem;
    // padding-top: 1rem;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    .role-and-user-box {
      display: flex;
      flex-direction: column;

      & > * {
        padding: 0rem 1.5rem;
      }

      .top-box {
        .role-box {
          display: flex;
          align-items: center;
          gap: 1.5rem;
          padding: 1.5rem 0rem;

          .check-box-wrap .input-check:checked ~ .label-check__black-light {
            background-color: #755ae2 !important;
            border-color: #755ae2 !important;
          }

          .check-box-wrap .label-check__black-light {
            border: 0.15rem solid rgba(204, 204, 204, 1) !important;
            width: 2.5rem;
            height: 2.5rem;
          }

          .text {
            text-transform: capitalize;
          }
        }
      }

      .underline {
        border-width: 0.12rem;
        margin-top: 2rem;
        margin-bottom: unset;
      }

      .bottom-box {
        flex: 1;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        max-height: 0;
        // margin-left: 3rem;
        transform-origin: top;
        overflow-x: hidden;
        overflow-y: hidden;
        transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        width: 100%;

        &::-webkit-scrollbar {
          display: none;
        }

        & > *:not(:last-child) {
          padding-bottom: 2rem;
        }

        .select-item {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 1rem;
          cursor: pointer;
          border-width: 0.15rem;

          .avatar-box {
            // flex: 0 0 9%;
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            background-color: #020202 !important;
            display: grid;
            place-items: center;

            span {
              font-weight: 600;
              color: #ffffff;
              display: inline-block;
              font-size: 1rem;
              text-transform: uppercase;
            }
          }

          .name-role-box {
            display: flex;
            flex-direction: column;
            align-self: center;

            .name {
              font-size: 1.45rem;
              font-weight: 600;
              // display: none;
            }

            .role {
              font-size: 1.1rem;
            }
          }

          .img-wrap {
            .img-box {
              width: 4rem;
              height: 4rem;

              .img {
                object-fit: contain;
                width: 100%;
                height: 100%;
              }
            }
          }

          .select-option-wrap {
            // display: none;
            // position: relative;
            // z-index: 10;
            margin-left: auto;

            form {
              .form-group {
                .react-select-class {
                  & > *:nth-child(3) {
                    width: max-content !important;
                    border: unset !important;
                    border-radius: 3rem;
                    background-color: rgba(247, 247, 247, 1);
                    padding: 0.03rem 0.3rem;
                  }

                  & > *:nth-child(4) {
                    left: unset;
                    right: 0.5rem;
                    width: max-content;
                    // height: 30rem;
                  }
                }
              }
            }
          }
        }
      }

      .bottom-box-show {
        gap: 2rem;
        overflow-x: visible;
        overflow-y: visible;
        margin-top: 2rem;
        max-height: max-content;
        transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
      }
    }
  }
}

.note-box-wrap-team-invite {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: rgba(255, 246, 237, 1);
  border-radius: 0.8rem;
  margin-bottom: 1rem;

  .img-box {
    width: 3rem;
    height: 3rem;

    .img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .text {
    font-size: 1.2rem;
    color: rgba(234, 135, 45, 1);
  }
}
