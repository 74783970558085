.card-top-up-modal-reuse-wrap .raven-modal-content-wrap .close-box {
    display: none;
  }
  .card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap {
    width: 100%;
    padding-bottom: 10rem;
    margin-top: 3rem;

    .account-change-drop-wrap{
      margin-top: .5rem;
    }

    .description-box-wrap {
      padding: 2rem 1.5rem;
      border-radius: 1.2rem;
      display: flex;
      flex-direction: column;
      margin-top: 2rem;

      .icon-subtitle {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        gap: 1rem;

        .img-wrap {
          display: grid;
          place-items: center;

          .img-box-select {
            width: 2rem;
            height: 2rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .sub-title {
          font-size: 1.35rem;
          text-transform: uppercase;
          transform: translateY(-.25rem);
        }
      }

      .list-box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .list-item {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .black-fade-white-bg {
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 50%;
            display: inline-block;
          }
        }
      }
    }
  }
  .card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap .title-card {
    font-weight: 700;
    font-size: 1.9rem;
    margin-bottom: 1.5rem;
    // color: red !important;
  }
  .card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap .text {
    font-size: 1.45rem;
    width: 90%;
  }
  .card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap .pin-test {
    margin-bottom: 2.5rem;
  }
  .card-top-up-modal-reuse-wrap .raven-modal-content-wrap .container-wrap .pin-test span {
    color: #020202;
    font-weight: 600;
  }
  .card-top-up-modal-reuse-wrap .raven-modal-content-wrap .two-button-wrap {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #f7f8f7;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1.5rem 2rem;
    gap: 2rem;
  }
  .card-top-up-modal-reuse-wrap .raven-modal-content-wrap .two-button-wrap .cancel {
    cursor: pointer;
    font-size: 1.5rem;
  }/*# sourceMappingURL=Modal.css.map */