.request-new-bankbox-wrapper-index-version-one-five {
  width: 40rem;
  margin-top: 3rem;
  padding-bottom: 10rem;
  overflow-y: scroll;
}
.request-new-bankbox-wrapper-index-version-one-five::-webkit-scrollbar {
  display: none;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .collapse-box-business .collapse-inner-box {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 0rem 0.1rem;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .collapse-box-business .collapse-inner-box .pick-up-delivery-details-box {
  display: flex;
  flex-direction: column;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .collapse-box-business .collapse-inner-box .pick-up-delivery-details-box .label {
  margin-bottom: 0.4rem;
  font-size: 1.3rem;
  text-transform: capitalize;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .collapse-box-business .collapse-inner-box .pick-up-delivery-details-box .raven-loc-box {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2rem;
  border-radius: 1.2rem;
  gap: 1rem;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .collapse-box-business .collapse-inner-box .pick-up-delivery-details-box .raven-loc-box .address {
  font-size: 1.45rem;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .collapse-box-business .collapse-inner-box .pick-up-delivery-details-box .raven-loc-box .icon-box .img-box {
  width: 3rem;
  height: 3rem;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .collapse-box-business .collapse-inner-box .pick-up-delivery-details-box .raven-loc-box .icon-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .select-title-owner-type-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .select-title-owner-type-box .select-box-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .select-title-owner-type-box .select-box-wrap .select-item {
  min-height: 10rem;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .select-title-owner-type-box .select-box-wrap .select-item .img-icon-box {
  width: 4rem;
  border-radius: 50%;
  height: 4rem;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .select-title-owner-type-box .select-box-wrap .select-item .img-icon-box .img-box {
  width: 4rem;
  height: 4rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .select-title-owner-type-box .select-box-wrap .select-item .img-icon-box .img-box .img,
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .select-title-owner-type-box .select-box-wrap .select-item .img-icon-box .img-box svg {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .select-title-owner-type-box .select-box-wrap .select-item .name {
  font-size: 1.25rem;
  margin-top: auto;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .request-badge-box {
  margin-top: -2rem;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .info-box {
  display: flex;
  flex-direction: column;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .info-box .title {
  margin-bottom: 0.4rem;
  font-size: 1.3rem;
  text-transform: capitalize;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .info-box .increase-decrease-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-width: 0.15rem;
  border-radius: 0.8rem;
  overflow: hidden;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .info-box .increase-decrease-box .increase-box,
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .info-box .increase-decrease-box .decrease-box {
  width: 6.5rem;
  height: 5rem;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .info-box .increase-decrease-box .input-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 700;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .info-box .increase-decrease-box .input-box input[type=number]::-webkit-inner-spin-button,
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .info-box .increase-decrease-box .input-box input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .info-box .increase-decrease-box .input-box .input {
  border: none !important;
  outline: none;
  width: 100%;
  min-width: unset;
  padding: 0.75rem 0.2rem;
  border-radius: 0.8rem;
  font-weight: 700;
  text-align: center;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .info-box .increase-decrease-box .input-box .input:focus {
  margin-right: 0.5rem;
  outline: none;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .info-box .increase-decrease-box .input-box-amount {
  width: 20rem;
}
.request-new-bankbox-wrapper-index-version-one-five .scrollable-div-box .info-box .increase-decrease-box .form-group .form-input {
  font-weight: 700;
}
@media only screen and (max-width: 52em) {
  .request-new-bankbox-wrapper-index-version-one-five {
    width: 90%;
    margin: 2rem auto;
  }
}
.request-new-bankbox-wrapper-index-version-one-five .bankbox-img-display-box {
  width: 100%;
  height: 20rem;
  border-radius: 1.2rem;
  background-image: url("../../../assets/banbox-create.gif");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 5rem center;
}
.request-new-bankbox-wrapper-index-version-one-five .title-grey-wrapper-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.request-new-bankbox-wrapper-index-version-one-five .title-grey-wrapper-box .title {
  font-size: 1.6rem;
  font-weight: 500;
}
.request-new-bankbox-wrapper-index-version-one-five .title-grey-wrapper-box .label-value-wrapper-box {
  padding: 2rem 0rem;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.request-new-bankbox-wrapper-index-version-one-five .title-grey-wrapper-box .label-value-wrapper-box > * {
  border-width: 0.15rem;
}
.request-new-bankbox-wrapper-index-version-one-five .title-grey-wrapper-box .label-value-wrapper-box > *:not(:last-child) {
  padding-bottom: 2rem;
}
.request-new-bankbox-wrapper-index-version-one-five .title-grey-wrapper-box .label-value-wrapper-box > *:last-child {
  border: none;
}
.request-new-bankbox-wrapper-index-version-one-five .title-grey-wrapper-box .label-value-wrapper-box .item-list-row {
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 100%;
}
.request-new-bankbox-wrapper-index-version-one-five .title-grey-wrapper-box .label-value-wrapper-box .item-list-row .title-text-box {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.request-new-bankbox-wrapper-index-version-one-five .title-grey-wrapper-box .label-value-wrapper-box .item-list-row .title-text-box .title {
  font-size: 1.45rem;
  font-weight: 600;
}
.request-new-bankbox-wrapper-index-version-one-five .title-grey-wrapper-box .label-value-wrapper-box .item-list-row .title-text-box .text {
  font-size: 1.25rem;
}
.request-new-bankbox-wrapper-index-version-one-five .title-grey-wrapper-box .label-value-wrapper-box .item-list-row .icon-box .img-box {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.request-new-bankbox-wrapper-index-version-one-five .title-grey-wrapper-box .label-value-wrapper-box .item-list-row .icon-box .img-box .img {
  width: 65%;
  height: 65%;
  -o-object-fit: contain;
     object-fit: contain;
}
.request-new-bankbox-wrapper-index-version-one-five .title-grey-wrapper-box .label-value-wrapper-box .item-list-row .value-right {
  margin-left: auto;
  font-weight: 500;
}/*# sourceMappingURL=NewRequestTerminal.css.map */