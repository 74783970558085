.global-amount-input-box {
  margin-top: 2rem;
  display: flex;
  width: 100%;
  border-width: 0.15rem;
  position: relative;
  border-radius: 1.2rem;
}
.global-amount-input-box .form-group__black-light {
  position: unset;
}
.global-amount-input-box .form-group__black-light .form-input {
  transform: translateY(20%);
}
.global-amount-input-box .form-group__black-light .form-label {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-120%);
  width: 100%;
}
.global-amount-input-box .form-group__black-light .form-input {
  border: none !important;
  background-color: transparent !important;
}
.global-amount-input-box .select-drop-box-wrapper {
  position: relative;
  padding-right: 1rem;
  display: grid;
  place-items: center;
  border-width: 0.15rem !important;
  padding: 0.5rem;
}
.global-amount-input-box .select-drop-box-wrapper .select-drop-box {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 1.2rem;
  border-radius: 2rem;
}
.global-amount-input-box .select-drop-box-wrapper .select-drop-box .value {
  font-weight: 500;
}
.global-amount-input-box .select-drop-box-wrapper .select-drop-box .img-select .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.global-amount-input-box .select-drop-box-wrapper .select-drop-box .img-select .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.global-amount-input-box .select-drop-box-wrapper .select-drop-box .drop-icon .img-box {
  width: 1.7rem;
  height: 1.7rem;
}
.global-amount-input-box .select-drop-box-wrapper .select-drop-box .drop-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=GlobalAmountInput.css.map */