.confirm-two-fa-modal-wrap-index-modal .raven-modal-content-wrap {
  width: 70rem;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index {
  width: 100%;
  min-height: 35rem;
  background-color: #ffffff;
  padding-bottom: 10rem;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .title-text-box {
  margin-bottom: 2.5rem;
  line-height: 2.3rem;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .title-text-box .title {
  font-weight: 600;
  font-size: 2rem;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .bar-code-text-box-wrap {
  display: flex;
  gap: 3rem;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .bar-code-text-box-wrap .bar-box {
  width: 17rem;
  height: 17rem;
  display: grid;
  place-items: center;
  border-radius: 1.2rem;
  flex: 0 0 17rem;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .bar-code-text-box-wrap .bar-box .bar {
  width: 85%;
  height: 85%;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .bar-code-text-box-wrap .text-copy-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .bar-code-text-box-wrap .text-copy-box .text-box {
  width: 75%;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .bar-code-text-box-wrap .text-copy-box .text-box .text {
  font-size: 1.65rem;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .bar-code-text-box-wrap .text-copy-box .copy-box {
  display: flex;
  padding: 1rem 1.6rem;
  border-radius: 0.8rem;
  align-items: center;
  justify-content: space-between;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .bar-code-text-box-wrap .text-copy-box .copy-box .copy-text {
  color: rgb(103, 103, 103);
  font-weight: 700;
  font-size: 1.6rem;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .bar-code-text-box-wrap .text-copy-box .copy-box .copy-icon {
  margin-left: auto;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .bar-code-text-box-wrap .text-copy-box .copy-box .copy-icon .img-box,
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .bar-code-text-box-wrap .text-copy-box .copy-box .copy-icon .copied {
  width: 2.7rem;
  height: 2.7rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .bar-code-text-box-wrap .text-copy-box .copy-box .copy-icon .img-box .check,
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .bar-code-text-box-wrap .text-copy-box .copy-box .copy-icon .copied .check {
  width: 40%;
  height: 40%;
  color: #ffffff;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .bar-code-text-box-wrap .text-copy-box .copy-box .copy-icon .img-box .img,
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .bar-code-text-box-wrap .text-copy-box .copy-box .copy-icon .copied .img {
  width: 90%;
  height: 90%;
  -o-object-fit: contain;
     object-fit: contain;
  cursor: pointer;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .enter-pin-text-box {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .enter-pin-text-box .enter-text {
  font-size: 1.65rem;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .enter-pin-text-box .pin-box {
  width: 38rem;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .enter-pin-text-box-two .pin-box {
  width: 25rem;
}
.confirm-two-fa-modal-wrap-index-modal .confirm-two-fa-modal-wrap-index .btn-two-box {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  left: 0;
  bottom: 0;
  padding: 1.5rem 3rem;
  gap: 2rem;
}

.diable-two-fa-modal-wrap-index-modal .raven-modal-content-wrap {
  width: 45rem !important;
}
.diable-two-fa-modal-wrap-index-modal .raven-modal-content-wrap .confirm-two-fa-modal-wrap-index {
  min-height: unset;
  padding-bottom: 3rem;
}
.diable-two-fa-modal-wrap-index-modal .raven-modal-content-wrap .confirm-two-fa-modal-wrap-index .title-text-box {
  margin-bottom: 3.5rem;
}
.diable-two-fa-modal-wrap-index-modal .raven-modal-content-wrap .confirm-two-fa-modal-wrap-index .request-pin-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}
.diable-two-fa-modal-wrap-index-modal .raven-modal-content-wrap .confirm-two-fa-modal-wrap-index .request-pin-box .text {
  font-size: 1.5rem;
}
.diable-two-fa-modal-wrap-index-modal .raven-modal-content-wrap .confirm-two-fa-modal-wrap-index .request-pin-box .text-click {
  padding: 0.8rem 1.5rem;
  border-radius: 3rem;
  cursor: pointer;
  min-width: 15rem;
  display: flex;
  justify-content: center;
}
.diable-two-fa-modal-wrap-index-modal .raven-modal-content-wrap .confirm-two-fa-modal-wrap-index .request-pin-box .text-click .recieve {
  color: rgb(151, 71, 255);
  font-weight: 500;
}

.confirm-two-fa-modal-wrap-index-type-two {
  display: flex;
  flex-direction: column;
  padding-bottom: 2.5rem;
  min-height: 45rem;
}
.confirm-two-fa-modal-wrap-index-type-two .loading-fa-box {
  flex: 1;
  display: grid;
  place-items: center;
}
.confirm-two-fa-modal-wrap-index-type-two .loading-fa-box > * {
  transform: scale(0.8);
}
.confirm-two-fa-modal-wrap-index-type-two .title-text-box {
  margin-bottom: 2.5rem;
}
.confirm-two-fa-modal-wrap-index-type-two .title-text-box .title {
  font-weight: 600;
  font-size: 2rem;
  line-height: 3rem;
}
.confirm-two-fa-modal-wrap-index-type-two .two-fa-pin-box {
  width: 100%;
  display: flex;
  justify-content: center;
}
.confirm-two-fa-modal-wrap-index-type-two .two-fa-pin-box .pin-two-fa {
  width: 90%;
}
@media only screen and (max-width: 48em) {
  .confirm-two-fa-modal-wrap-index-type-two .two-fa-pin-box .pin-two-fa {
    width: 100%;
  }
}
.confirm-two-fa-modal-wrap-index-type-two .scan-big-box {
  width: 100%;
  padding: 1.5rem;
  border-radius: 1.2rem;
  display: grid;
  place-items: center;
  margin-bottom: 2rem;
}
.confirm-two-fa-modal-wrap-index-type-two .scan-big-box .scan-inner-wrap {
  width: 16rem;
  height: 16rem;
  border-radius: 1.2rem;
  padding: 0rem;
}
.confirm-two-fa-modal-wrap-index-type-two .scan-big-box .scan-inner-wrap .img-box {
  width: 100%;
  height: 100%;
}
.confirm-two-fa-modal-wrap-index-type-two .scan-big-box .scan-inner-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.confirm-two-fa-modal-wrap-index-type-two .num-text-title-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  gap: 1rem;
}
.confirm-two-fa-modal-wrap-index-type-two .num-text-title-box .text {
  font-size: 1.3rem;
}
.confirm-two-fa-modal-wrap-index-type-two .num-text-title-box .num-title-box {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.confirm-two-fa-modal-wrap-index-type-two .num-text-title-box .num-title-box .title {
  font-weight: 600;
  font-size: 1.65rem;
}
.confirm-two-fa-modal-wrap-index-type-two .num-text-title-box .num-title-box .num {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.confirm-two-fa-modal-wrap-index-type-two .num-text-title-box .num-title-box .num span {
  display: inline-block;
  font-weight: 700;
  font-size: 1.5rem;
}/*# sourceMappingURL=ConfirmTwoFaModal.css.map */