.reusable-collapse-fragment-box-index-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.reusable-collapse-fragment-box-index-wrapper .title-drop-icon-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.reusable-collapse-fragment-box-index-wrapper .title-drop-icon-box .icon-box {
  margin-top: 0.3rem;
}
.reusable-collapse-fragment-box-index-wrapper .title-drop-icon-box .icon-box .img-box {
  width: var(--icon-size);
  height: var(--icon-size);
}
.reusable-collapse-fragment-box-index-wrapper .title-drop-icon-box .icon-box .img-box .img, .reusable-collapse-fragment-box-index-wrapper .title-drop-icon-box .icon-box .img-box svg {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.reusable-collapse-fragment-box-index-wrapper .title-drop-icon-box .title-collapse {
  font-size: var(--title-size);
  color: var(--title-color);
}
.reusable-collapse-fragment-box-index-wrapper .collapse-outer-box {
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.reusable-collapse-fragment-box-index-wrapper .collapse-outer-box .collapse-inner-box {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
}

.reusable-collapse-fragment-box-index-wrapper-open .title-drop-icon-box .icon-box {
  transform: rotate(180deg);
}
.reusable-collapse-fragment-box-index-wrapper-open .collapse-outer-box {
  max-height: var(--collapse-height);
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}/*# sourceMappingURL=CollapseFragment.css.map */