.payroll-top-box-wrap {
  width: 100%;
  position: relative;
}
.payroll-top-box-wrap .action-bar-wrapper-box {
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
}
.payroll-top-box-wrap .top-payroll-container-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 2.5rem;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 55em) {
  .payroll-top-box-wrap .top-payroll-container-box {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }
}
.payroll-top-box-wrap .top-payroll-container-box .box {
  border-radius: 1.2rem;
  padding: 3rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
}
.payroll-top-box-wrap .top-payroll-container-box .box .title {
  font-weight: 700;
  padding-left: 2rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
.payroll-top-box-wrap .top-payroll-container-box .box .title:hover .title-total .img-box {
  transform: translateX(0.4rem);
}
.payroll-top-box-wrap .top-payroll-container-box .box .title .title-total {
  display: grid;
  place-items: center;
}
.payroll-top-box-wrap .top-payroll-container-box .box .title .title-total .img-box {
  width: 2.5rem;
  height: 2.5rem;
  transition: all 0.3s ease-in-out;
}
.payroll-top-box-wrap .top-payroll-container-box .box .title .title-total .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payroll-top-box-wrap .top-payroll-container-box .box .value-box {
  padding: 1.5rem 1rem 1.5rem 2rem;
  border-radius: 0.8rem;
  margin-top: auto;
}
.payroll-top-box-wrap .top-payroll-container-box .box .value-box .value {
  font-weight: 700;
  font-size: 1.75rem;
}
.payroll-top-box-wrap .top-payroll-container-box .box .title-disabled {
  cursor: not-allowed;
}
.payroll-top-box-wrap .top-payroll-container-box .box .title-disabled:hover .title-total .img-box {
  transform: none;
}

.payroll-top-box-wrap .top-detail-card-box {
  margin-bottom: 1rem;
  border: 1px solid rgba(208, 213, 221, 0.5);
  box-shadow: -1px 0px 20px rgba(229, 230, 230, 0.35);
  border-radius: 1.6rem;
  padding: 1.5rem 2rem;
  display: flex;
  display: none;
}

.payroll-top-box-wrap .top-detail-card-box .left-box {
  flex: 0 0 70%;
  display: flex;
  flex-direction: column;
  border-right: 0.1rem solid #f0eeff;
}

.payroll-top-box-wrap .top-detail-card-box .left-box .wallet-cash-box {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-top: 1.5rem;
}

.payroll-top-box-wrap .top-detail-card-box .left-box .wallet-cash-box > *:not(:last-child) {
  border-right: 0.1rem solid #f0eeff;
  padding-right: 3rem;
}

.payroll-top-box-wrap .top-detail-card-box .left-box .wallet-cash-box .box .label {
  font-size: 1.1rem !important;
  margin-top: 0.3rem;
}

.payroll-top-box-wrap .top-detail-card-box .left-box .wallet-cash-box .box .value {
  font-weight: 600;
  font-size: 2rem;
}

.payroll-top-box-wrap .top-detail-card-box .left-box .btn-two-box {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.payroll-top-box-wrap .top-detail-card-box .left-box .btn-two-box .raven-btn {
  min-width: 19rem;
}

.payroll-top-box-wrap .top-detail-card-box .right-box {
  flex: 1;
  display: flex;
  padding: 0rem 2rem;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap {
  flex: 1;
  background-color: #020202;
  border-radius: 0.8rem;
  position: relative;
  background-image: url("../../../assets/gauage-map-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap > * {
  position: relative;
  z-index: 3;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  background-image: url("../../../assets/gauge-map-right.png");
  background-position: top right;
  background-size: contain;
  background-repeat: no-repeat;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap .title {
  font-size: 1.05rem !important;
  color: #ffffff;
  text-transform: uppercase;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap .gauge-box {
  margin-top: 1rem;
  flex: 1;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap .gauge-box #gauge-chart2 {
  position: relative;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap .gauge-box #gauge-chart2::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -47%);
  width: 53%;
  height: 50%;
  content: "";
  display: none;
  background-image: url("../../../assets/border-gauge.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap .days-count {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 0rem auto;
  margin-top: -1.7rem;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap .days-count span {
  font-size: 0.9rem;
  color: #ffffff;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap .bottom-text-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap .bottom-text-box .days {
  font-size: 1.1rem;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap .bottom-text-box .days span {
  color: #ea872d;
  font-weight: 600;
  font-size: 2.5rem;
}

.payroll-top-box-wrap .top-detail-card-box .right-box .gauge-wrap .bottom-text-box .learn {
  font-size: 1.1rem;
  align-self: flex-end;
  color: #dbd6fc;
  cursor: pointer;
}

.payroll-top-box-wrap .top-detail-grid-card-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 2rem;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 42em) {
  .payroll-top-box-wrap .top-detail-grid-card-wrap {
    grid-gap: 1rem;
  }
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap {
  border-radius: 1.6rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}
@media only screen and (max-width: 42em) {
  .payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap {
    border-radius: 0.8rem;
  }
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .img-text-box {
  flex: 1;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}
@media only screen and (max-width: 42em) {
  .payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .img-text-box {
    padding: 1rem 0.8rem;
  }
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .img-text-box .label {
  margin-top: auto;
  font-size: 1.35rem;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 42em) {
  .payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .img-text-box .label {
    font-size: 1.25rem;
    margin-bottom: unset;
  }
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .img-text-box .amount {
  font-weight: 700;
  font-size: 2.2rem;
}
@media only screen and (max-width: 42em) {
  .payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .img-text-box .amount {
    font-size: 1.8rem;
  }
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .img-text-box .img-box {
  width: 4.2rem;
  height: 4.2rem;
  margin-right: 1rem;
  background-color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.06), 0px 0px 2px rgba(51, 51, 51, 0.02);
}
@media only screen and (max-width: 42em) {
  .payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .img-text-box .img-box {
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 1.5rem;
  }
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .img-text-box .img-box .img {
  width: 70%;
  height: 70%;
  -o-object-fit: contain;
  object-fit: contain;
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .img-text-box .img-box .img-two {
  width: 50%;
  height: 50%;
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .action-link-box {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #755ae2;
  align-self: flex-start;
  cursor: pointer;
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .action-link-box:hover .icon {
  transform: translateX(0.25rem);
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .action-link-box .text {
  font-weight: 700;
  font-size: 1.2rem;
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .action-link-box .icon {
  color: currentColor;
  transition: all 0.3s;
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .gauge-wrap {
  flex: 1;
  background-color: #020202;
  border-radius: 0.8rem;
  position: relative;
  background-image: url("../../../assets/gauage-map-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .gauge-wrap > * {
  position: relative;
  z-index: 3;
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .gauge-wrap::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  background-image: url("../../../assets/gauge-map-right.png");
  background-position: top right;
  background-size: contain;
  background-repeat: no-repeat;
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .gauge-wrap .title {
  font-size: 1.05rem !important;
  color: #ffffff;
  text-transform: uppercase;
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .gauge-wrap .gauge-box {
  margin-top: 0.5rem;
  flex: 1;
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .gauge-wrap .gauge-box #gauge-chart2 {
  position: relative;
  height: 100%;
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .gauge-wrap .gauge-box #gauge-chart2::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -47%);
  width: 53%;
  height: 50%;
  content: "";
  display: none;
  background-image: url("../../../assets/border-gauge.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .gauge-wrap .days-count {
  display: flex;
  justify-content: space-between;
  width: 78%;
  margin: 0rem auto;
  margin-top: -1.3rem;
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .gauge-wrap .days-count span {
  font-size: 0.9rem;
  color: #ffffff;
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .gauge-wrap .bottom-text-box {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .gauge-wrap .bottom-text-box .days {
  font-size: 1.3rem;
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .gauge-wrap .bottom-text-box .days span {
  color: #ea872d;
  font-weight: 800;
}

.payroll-top-box-wrap .top-detail-grid-card-wrap .box-detail-wrap .gauge-wrap .bottom-text-box .learn {
  font-size: 1.1rem;
  align-self: flex-end;
  color: #dbd6fc;
  cursor: pointer;
}

.payroll-index-wrap-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
}

.payroll-index-wrap-box .text-search-box {
  display: flex;
  width: 100%;
  gap: 2rem;
  align-items: center;
}
@media only screen and (max-width: 42em) {
  .payroll-index-wrap-box .text-search-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 1.5rem;
  }
  .payroll-index-wrap-box .text-search-box form {
    width: 100%;
  }
  .payroll-index-wrap-box .text-search-box form .form-group {
    width: 100%;
  }
  .payroll-index-wrap-box .text-search-box > *:last-child {
    grid-row: 1/span 1;
    grid-column: 1/-1;
  }
}

.payroll-index-wrap-box .text-search-box .download-btn {
  background-color: #f7f8f7;
  border: 0.1rem solid #cccccc;
  padding: 1.4rem 1.6rem;
  color: #676767;
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: 13rem;
}
.payroll-index-wrap-box .text-search-box .download-btn .img-box {
  width: 1.5rem;
  height: 1.5rem;
}

.payroll-index-wrap-box .text-search-box .download-btn .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.payroll-index-wrap-box .text-search-box .search-input {
  width: -moz-max-content;
  width: max-content;
}

.payroll-index-wrap-box .text-search-box .title {
  font-size: 1.3rem;
}
@media only screen and (max-width: 42em) {
  .payroll-index-wrap-box .text-search-box .title {
    display: none;
  }
}

.payroll-index-wrap-box .text-search-box .title {
  font-weight: 700;
  padding-left: 2rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.payroll-index-wrap-box .text-search-box .title .title-total {
  padding: 0.4rem 1rem;
  border-radius: 1rem;
  display: grid;
  place-items: center;
}
.payroll-index-wrap-box .text-search-box .title .title-total span {
  display: inline-block;
  color: #ffffff !important;
  font-size: 1.1rem;
}

.payroll-index-wrap-box .table-box {
  margin-top: 1.5rem;
  padding-bottom: 10rem;
}
@media only screen and (max-width: 42em) {
  .payroll-index-wrap-box .table-box {
    width: 100%;
    padding-bottom: 4rem;
  }
  .payroll-index-wrap-box .table-box .table-wrap {
    width: 100%;
    overflow-x: scroll;
    padding-bottom: 4rem;
  }
}
@media only screen and (max-width: 42em) and (max-width: 48em) {
  .payroll-index-wrap-box .table-box .table-wrap {
    display: none;
  }
}
@media only screen and (max-width: 42em) {
  .payroll-index-wrap-box .table-box .table-wrap .table {
    width: 85rem;
  }
}
.payroll-index-wrap-box .table-box .status-type-wrap {
  background-color: #e9f5ff;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #476885;
}
.payroll-index-wrap-box .table-box .status-type-wrap span {
  font-size: 1.2rem;
}
.payroll-index-wrap-box .table-box .status-type-wrap-fail {
  background-color: #fff5f5;
  color: #ff0f00;
}
.payroll-index-wrap-box .table-box .status-type-wrap-success {
  background-color: #e8fff6;
  color: #1ace37;
}
.payroll-index-wrap-box .table-box .status-type-wrap-pending {
  background-color: #fff6ed;
  color: #ea872d;
}
.payroll-index-wrap-box .table-box .payroll-table-action-wrap {
  margin: 0rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}
.payroll-index-wrap-box .table-box .payroll-table-action-wrap .img-box {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: transform 0.3s ease-in-out;
}
.payroll-index-wrap-box .table-box .payroll-table-action-wrap .img-box:hover {
  transform: scale(1.05);
}
.payroll-index-wrap-box .table-box .payroll-table-action-wrap .img-box .img {
  width: 40%;
  height: 40%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payroll-index-wrap-box .table-box .payroll-table-action-wrap > *:nth-child(2) .img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payroll-index-wrap-box .table-box .payroll-table-action-wrap .delete-box {
  background-color: #fff5f5;
}

.payroll-index-wrap-box .table-box .table-pagination-box {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  padding: 0rem 3rem;
  margin-bottom: 5rem;
}

.payroll-index-wrap-box .table-box .text-avatar-box {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.payroll-index-wrap-box .table-box .text-avatar-box .avatar-box {
  width: 3rem;
  height: 3rem;
  background-color: #f7f8f7;
  border: 0.1rem solid #cccccc;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.payroll-index-wrap-box .table-box .text-avatar-box .avatar-box span {
  font-weight: 600;
  font-size: 1rem;
  text-transform: capitalize;
}

.process-payroll-modal-wrap .content-wrap {
  margin-bottom: 3rem;
}

.process-payroll-modal-wrap .content-wrap .title {
  font-weight: 700;
  font-size: 1.9rem;
  margin-bottom: 0.5rem;
}

.process-payroll-modal-wrap .content-wrap .text {
  font-size: 1.3rem;
  width: 90%;
}

.deduction-payroll-modal-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}
.deduction-payroll-modal-wrap .title {
  font-weight: 700;
  font-size: 1.8rem;
}
.deduction-payroll-modal-wrap .text {
  font-size: 1.35rem;
}
.deduction-payroll-modal-wrap .input-detail-wrap {
  margin-top: 2rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.deduction-payroll-modal-wrap .input-detail-wrap .permanent-edit-box {
  display: flex;
}
.deduction-payroll-modal-wrap .input-detail-wrap .permanent-edit-box .img-box {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}
.deduction-payroll-modal-wrap .input-detail-wrap .permanent-edit-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.deduction-payroll-modal-wrap .input-detail-wrap .permanent-edit-box .text {
  font-size: 1.3rem;
}
.deduction-payroll-modal-wrap .input-detail-wrap .permanent-edit-box .text span {
  color: #755ae2 !important;
  border-bottom: 0.1rem solid currentColor;
  font-weight: 700;
  cursor: pointer;
  margin-left: 0.35rem;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}
.deduction-payroll-modal-wrap .input-detail-wrap .permanent-edit-box .text span:hover {
  transform: scale(1.04);
}

.generate-employee-transaction-detail-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}
.generate-employee-transaction-detail-box > *:last-child {
  margin-top: 2rem;
}
.generate-employee-transaction-detail-box .title {
  font-size: 1.65rem;
  font-weight: 600;
}
.generate-employee-transaction-detail-box .text {
  margin-bottom: 2rem;
  margin-top: 0.35rem;
}

.payroll-deduction-modal-wrap-box {
  margin-bottom: 1.5rem;
}
.payroll-deduction-modal-wrap-box .payout-date-wrap {
  align-self: flex-start;
  border-radius: 2rem;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
}
.payroll-deduction-modal-wrap-box .payout-date-wrap .input-date {
  width: 0.1rem;
  visibility: hidden;
}
.payroll-deduction-modal-wrap-box .payout-date-wrap .input-date input {
  padding: 0rem;
}
.payroll-deduction-modal-wrap-box .payout-date-wrap .text {
  font-size: 1.25rem;
}
.payroll-deduction-modal-wrap-box .payout-date-wrap .img-box {
  width: 1.8rem;
  height: 1.8rem;
}
.payroll-deduction-modal-wrap-box .payout-date-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payroll-deduction-modal-wrap-box .title-payroll {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 700;
}
.payroll-deduction-modal-wrap-box .deduct-wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.payroll-deduction-modal-wrap-box .deduct-wrap .toggle-text {
  display: flex;
  align-items: center;
  padding: 1.5rem 0rem;
}
.payroll-deduction-modal-wrap-box .deduct-wrap .toggle-text > *:last-child {
  transform: scale(0.8);
}
.payroll-deduction-modal-wrap-box .deduct-wrap .toggle-text .text-box {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
}
.payroll-deduction-modal-wrap-box .deduct-wrap .toggle-text .text-box .two {
  width: 80%;
  font-size: 1.2rem;
}
.payroll-deduction-modal-wrap-box .deduct-wrap .toggle-text .text-box .text {
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.payroll-select-create-type-wrap-index {
  width: 40rem;
  margin-top: 3rem;
  padding-bottom: 10rem;
  margin-left: auto;
  margin-right: auto;
  overflow-y: scroll;
}
.payroll-select-create-type-wrap-index::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 52em) {
  .payroll-select-create-type-wrap-index {
    width: 90%;
    margin: 2rem auto;
  }
}
.payroll-select-create-type-wrap-index .select-pos-request-type-box {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 2rem;
}
.payroll-select-create-type-wrap-index .select-pos-request-type-box .description-box-wrap {
  padding: 2rem 1.5rem;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.payroll-select-create-type-wrap-index .select-pos-request-type-box .description-box-wrap .icon-subtitle {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
}
.payroll-select-create-type-wrap-index .select-pos-request-type-box .description-box-wrap .icon-subtitle .img-wrap {
  display: grid;
  place-items: center;
}
.payroll-select-create-type-wrap-index .select-pos-request-type-box .description-box-wrap .icon-subtitle .img-wrap .img-box {
  width: 1.4rem;
  height: 1.4rem;
}
.payroll-select-create-type-wrap-index .select-pos-request-type-box .description-box-wrap .icon-subtitle .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payroll-select-create-type-wrap-index .select-pos-request-type-box .description-box-wrap .icon-subtitle .sub-title {
  font-size: 1.25rem;
  text-transform: uppercase;
}
.payroll-select-create-type-wrap-index .select-pos-request-type-box .description-box-wrap .list-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.payroll-select-create-type-wrap-index .select-pos-request-type-box .description-box-wrap .list-box .list-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.payroll-select-create-type-wrap-index .select-pos-request-type-box .description-box-wrap .list-box .list-item .black-fade-white-bg {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  display: inline-block;
}
.payroll-select-create-type-wrap-index .select-pos-request-type-box .select-title {
  font-size: 1.5rem;
}
.payroll-select-create-type-wrap-index .select-pos-request-type-box .img-select-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2.5rem;
  min-height: 12rem;
}
.payroll-select-create-type-wrap-index .select-pos-request-type-box .img-select-wrap .img-wrap {
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;
  border-radius: 1rem;
  gap: 1.5rem;
  align-items: flex-start;
  justify-content: space-between;
}
.payroll-select-create-type-wrap-index .select-pos-request-type-box .img-select-wrap .img-wrap .img-box {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-bottom: 3rem;
}
.payroll-select-create-type-wrap-index .select-pos-request-type-box .img-select-wrap .img-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 45%;
  height: 45%;
}
.payroll-select-create-type-wrap-index .select-pos-request-type-box .img-select-wrap .img-wrap .name {
  text-align: center;
  font-size: 1.25rem;
  margin-top: auto;
}
.payroll-select-create-type-wrap-index .select-pos-request-type-box .img-select-wrap .select-option-class-active::after {
  border-width: 0.25rem;
}/*# sourceMappingURL=PayrollIndex.css.map */