// @import "../../breakpoints";

.add-money-select-wrap-box {
  // background-color: red;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 3rem;

  @media only screen and (max-width: 42em) {
    gap: 1.7rem;
    margin-top: 2rem;
  }

  .wrap-add-money {
    // width: max-content;
    // background-color: #f7f8f7;
    // border: 0.1rem solid #cccccc;
    // color: rgba(249, 249, 249, .3);
    border-radius: 1.6rem;
    padding: 2.4rem 1.6rem;
    cursor: pointer;
    min-width: 50rem;

    @media only screen and (max-width: 42em) {
      padding: 2rem 1.2rem;
      // background-color: red;
      min-width: unset;
      width: 100%;
      margin: 0rem auto;
    }

    .icon {
      margin-left: auto;
      width: 2rem;
      height: 2rem;
      //   color: #020202;
    }

    .top-box {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 42em) {
        // flex-wrap: wrap;
      }

      .title {
        font-size: 1.45rem;
        // color: #020202;
        font-weight: 700;

        @media only screen and (max-width: 42em) {
          font-size: 1.35rem;
        }
      }

      .text {
        margin-top: 0.3rem;
        color: #676767;
        font-size: 1.25rem;

        @media only screen and (max-width: 42em) {
          font-size: 1.15rem;
          width: 80%;
        }
      }

      .img-box-icon {
        width: 4rem;
        height: 4rem;
        margin-right: 1rem;

        .img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      .coming-soon {
        align-self: center;
        margin-left: auto;
        // color: red;
      }
    }

    .bottom-box {
      max-height: 0;
      overflow-x: hidden;
      overflow-y: hidden;
      transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      width: 100%;

      & > *:not(:last-child) {
        padding-bottom: 2rem;
      }

      .text {
        font-size: 1.25rem;
        color: #676767;
        display: flex;
        align-items: center;

        .copy-wrap {
          margin-left: auto;
          height: 3.5rem;
          width: 3.5rem;
          display: grid;
          place-items: center;

          .img-box-copy {
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 50%;
            display: grid;
            place-items: center;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }

            .icon {
              width: 1rem;
              height: 1rem;
              display: inline-block;
              color: #ffffff;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .img-box-copied {
            width: 2.5rem;
            height: 2.5rem;
            position: relative;
            background-color: #020202;
          }
        }

        span {
          font-weight: 600;
          margin-left: 0.6rem;
        }
      }
    }

    .bottom-box-show {
      //   border-top: 0.1rem solid #cccccc;
      max-height: 15rem;
      transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      padding-top: 1.5rem;
    }
  }

  .wrap-coming-soon {
    opacity: 0.5;
    cursor: default;

    @media only screen and (max-width: 42em) {
      flex-direction: column;
      padding: 1rem;
    }

    .top-box {
      @media only screen and (max-width: 42em) {
        flex-direction: column;
        align-items: flex-start;

        & > *:last-child {
          margin-left: unset;
          align-self: flex-end;
          margin-top: 1rem;
        }
      }

      .text {
        margin-top: 0.3rem;
        color: #676767;
        font-size: 1.25rem;

        @media only screen and (max-width: 42em) {
          font-size: 1.15rem;
          width: 100%;
        }
      }
    }

    .coming-soon {
      cursor: default;

      // @media only screen and (max-width: 42em) {
      //   width: max-content;
      // }

      &:hover {
        transform: none;
      }
    }
  }

  // & > *:last-child {
  //   margin-bottom: 10rem;
  // }
}

.add-money-select-wrap-box-new {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 4rem;
  width: 53rem;
  // background-color: red;

  @media only screen and (max-width: 42em) {
    gap: 1.7rem;
    margin-top: 2rem;
    width: 100%;
  }

  .title-drop-box {
    display: flex;
    align-items: center;
    // background-color: red;
    gap: 1.5rem;
    position: relative;
    z-index: 10;
    width: max-content;

    @media only screen and (max-width: 48em) {
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      
      .value-box {
        margin-left: auto;
      }
    }

    .title {
      font-size: 1.6rem;
      // color: red;
    }

    .select-toggle-box {
      position: absolute;
      top: 120%;
      right: 0;
    }

    .value-box {
      display: flex;
      align-items: center;
      gap: 0.7rem;
      background-color: rgba(240, 238, 255, 1);
      padding: 0.5rem 0.8rem;
      border-radius: 0.6rem;
      cursor: pointer;

      // &:hover .img-box{
      //   transform: translateY(.25rem);
      // }

      // &:hover .img-box-up{
      //   transform: translateY(-.25rem);
      // }

      .value {
        color: rgba(117, 90, 226, 1);
        font-weight: 600;
      }

      .img-box {
        display: grid;
        place-items: center;
        width: 2rem;
        height: 2rem;
        // transition: all .3s ease-in-out;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          transform: translateY(0.2rem);
        }
      }

      .img-box-up {
        transform: translateY(0.25rem) rotateX(-180deg);
      }
    }
  }

  .content-box-two {
    width: 100%;
    padding: 2.5rem;
    border-width: 0.15rem;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    align-items: stretch;
    border-radius: 1.2rem;

    @media only screen and (max-width: 48em){
      padding: 1.5rem 1rem;
    }

    .add-money-item {
      align-self: stretch;
      border-width: 0.15rem;
      padding-bottom: 1.5rem;

      .top-box {
        display: flex;
        align-items: center;
        cursor: pointer;

        .title-text-box {
          .title {
            font-size: 1.45rem;
            // color: #020202;
            font-weight: 700;

            @media only screen and (max-width: 42em) {
              font-size: 1.35rem;
            }
          }

          .text {
            margin-top: 0.3rem;
            color: #676767;
            font-size: 1.25rem;

            @media only screen and (max-width: 42em) {
              font-size: 1.15rem;
              width: 80%;
            }
          }
        }

        .img-wrap {
          margin-right: 1.5rem;
          .img-box {
            width: 4.5rem;
            height: 4.5rem;
            border-radius: 50%;
            display: grid;
            place-items: center;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .arrow-coming-soon-box {
          margin-left: auto;
          position: relative;

          .img-box-arrow {
            width: 2.5rem;
            height: 2.5rem;
            transform: rotate(-90deg);
            transition: all 0.3s ease-in-out;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .img-box-arrow-rotate {
            transform: unset;
          }

          .coming-soon {
            position: absolute;
            top: -3rem;
            right: 0;
          }
        }
      }

      .content-display-box {
        max-height: 0;
        overflow-x: hidden;
        overflow-y: hidden;
        transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        width: 100%;

        .display-wrap {
          padding: 2rem;
          display: flex;
          align-items: center;
          border-radius: 1.2rem;

          @media only screen and (max-width: 48em){
            flex-direction: column;
            align-items: flex-start;

            .copy-box{
              margin-left: unset !important;
              margin-top: 1.5rem;
            }
          }

          .copy-box {
            margin-left: auto;
            display: flex;
            align-items: center;
            gap: 0.4rem;
            padding: .7rem 2rem;
            border-radius: 3rem;
            cursor: pointer;
            transition: all .3s ease-in-out;

            &:hover{
              transform: scale(1.01);
            }

            .img-box{
              width: 1.5rem;
              height: 1.5rem;

              .img{
                width: 100%;
                height: 100%;
                color:rgba(117, 90, 226, 1);
              }

              .icon{
                width: 1.3rem;
                height: 1.3rem;
              }
            }

            span {
              color: rgba(117, 90, 226, 1);
              font-weight: 500;
            }
          }

          .info-box {
            display: flex;
            flex-direction: column;
            gap: 0.4rem;

            .bank-info {
              display: flex;
              align-items: center;
              gap: 0.5rem;
            }

            .acc_num {
              font-weight: 600;
              font-size: 1.7rem;
            }
          }
        }
      }

      .content-display-box-show {
        max-height: 15rem;
        transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        padding-top: 1.5rem;
      }
    }
  }

  .wrap-coming-soon {
    opacity: 0.5;
    cursor: default;

    @media only screen and (max-width: 42em) {
      flex-direction: column;
      padding: 1rem;
    }

    // .content-box-two {
    .add-money-item {
      .top-box {
        cursor: not-allowed;
        @media only screen and (max-width: 42em) {
          flex-direction: column;
          align-items: flex-start;

          & > *:last-child {
            margin-left: unset;
            align-self: flex-end;
            margin-top: 1rem;
          }
        }

        .text {
          margin-top: 0.3rem;
          color: #676767;
          font-size: 1.25rem;

          @media only screen and (max-width: 42em) {
            font-size: 1.15rem;
            width: 100%;
          }
        }
      }
    }
  }

  .coming-soon {
    cursor: default;

    // @media only screen and (max-width: 42em) {
    //   width: max-content;
    // }

    &:hover {
      transform: none;
    }
  }
  // }
}
