.notification-wrap-modal-index {
  min-height: 60rem;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}
.notification-wrap-modal-index .title-all-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.notification-wrap-modal-index .title-all-box .title {
  font-size: 2.3rem;
  font-weight: 600;
}
.notification-wrap-modal-index .title-all-box .all {
  font-size: 1.35rem;
  cursor: pointer;
  border-bottom: 0.1rem solid currentColor;
}
.notification-wrap-modal-index .select-view-box {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding-bottom: 1.2rem;
  border-width: 0.15rem;
}
.notification-wrap-modal-index .select-view-box .select-view-item {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  position: relative;
  cursor: pointer;
}
.notification-wrap-modal-index .select-view-box .select-view-item::after {
  content: "";
  top: 100%;
  height: 0.3rem;
  width: 100%;
  position: absolute;
  transform: translateY(1.1rem);
  visibility: hidden;
}
.notification-wrap-modal-index .select-view-box .select-view-item .text {
  font-size: 1.5rem;
}
.notification-wrap-modal-index .select-view-box .select-view-item .num {
  padding: 0.2rem 0.8rem;
  border-radius: 0.4rem;
  display: grid;
  place-items: center;
  opacity: 0.8;
}
.notification-wrap-modal-index .select-view-box .select-view-item .num span {
  color: #ffffff;
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
}
.notification-wrap-modal-index .select-view-box .select-view-item-active::after {
  visibility: visible;
}
.notification-wrap-modal-index .notification-content-box {
  display: flex;
  flex-direction: column;
  max-height: 40rem;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 1rem !important;
}
.notification-wrap-modal-index .notification-content-box::-webkit-scrollbar {
  display: none;
}
.notification-wrap-modal-index .notification-content-box:hover .notify-item:not(:hover) {
  position: relative;
  z-index: 5;
}
.notification-wrap-modal-index .notification-content-box:hover .notify-item:hover {
  position: relative;
  z-index: 10;
}
.notification-wrap-modal-index .notification-content-box .notify-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 2rem 0rem;
  border-width: 0.15rem;
  cursor: pointer;
}
.notification-wrap-modal-index .notification-content-box .notify-item .img-wrap .img-box {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.notification-wrap-modal-index .notification-content-box .notify-item .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.notification-wrap-modal-index .notification-content-box .notify-item .description-box {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.notification-wrap-modal-index .notification-content-box .notify-item .description-box .notify-text {
  font-size: 1.45rem;
  font-weight: 400;
}
.notification-wrap-modal-index .notification-content-box .notify-item .description-box .notify-text span {
  font-weight: 600;
}
.notification-wrap-modal-index .view-all-box-wrap {
  margin-top: auto;
  align-self: center;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 1.5rem;
  margin-top: 1rem !important;
}
.notification-wrap-modal-index .view-all-box-wrap .pagination-wrap__black-light .child {
  transform: scale(0.75) !important;
}/*# sourceMappingURL=NotificationModal.css.map */