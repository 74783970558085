.global-amount-input-box {
  margin-top: 2rem;
  display: flex;
  // align-items: center;
  width: 100%;
  border-width: 0.15rem;
  position: relative;
  border-radius: 1.2rem;
  // background-color: red !important;
  // gap: 1rem;

  .form-group__black-light {
    position: unset;
    
    .form-input{
      transform: translateY(20%);
    }

    .form-label {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-120%);
      width: 100%;
    }

    .form-input {
      border: none !important;
      background-color: transparent !important;
    }
  }

  .select-drop-box-wrapper {
    position: relative;
    //   margin-bottom: -0.5rem;
    padding-right: 1rem;
    display: grid;
    place-items: center;
    border-width: 0.15rem !important;
    padding: 0.5rem;

    .select-drop-box {
      align-self: stretch;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding-left: 1.2rem;
      border-radius: 2rem;
      // padding-right: 1rem;
      //   padding: 1rem 1.5rem;
      //   border-radius: 5rem;

      .value {
        font-weight: 500;
      }

      .img-select {
        .img-box {
          width: 2.5rem;
          height: 2.5rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .drop-icon {
        .img-box {
          width: 1.7rem;
          height: 1.7rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}
