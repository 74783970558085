.add-sub-account-index-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  // background-color: red;

  @media only screen and (max-width: 42em) {
    width: 90%;
    margin: 0rem auto;
    // background-color: red;
  }

  .form {
    width: 40rem;
    display: flex;
    flex-direction: column;
    padding-top: 2.5rem;
    gap: 2.5rem;
    // padding-bottom: 5rem;
    flex: 1;
    overflow-y: scroll;
    // background-color: red;

    @media only screen and (max-width: 42em) {
      width: 100%;
      margin: 0rem auto;
      // background-color: red;
    }

    .select-wrap {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 1.5rem;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      //   background-color: red;

      .select-title {
        align-self: flex-start;
        margin-bottom: 1.5rem;
        font-size: 1.3rem;
        text-transform: capitalize;
      }

      .select-box {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        flex-wrap: wrap;
        flex-direction: column;

        & > *:not(:last-child) {
          padding-bottom: 1.5rem !important;
          display: none;
        }

        .select-role-item {
          width: 100%;
          display: flex;
          align-items: center;

          .role {
            text-transform: capitalize;
            font-weight: 500;
          }

          .check-wrap {
            margin-left: auto;

            .check-box-wrap .input-check:checked ~ .label-check__purple-light {
              background-color: #755ae2 !important;
            }

            .check-box-wrap .label-check__purple-light {
              border: 0.15rem solid rgba(204, 204, 204, 1) !important;
            }

            .check-box-wrap .input-check:checked ~ .label-check__purple-light {
              border: 0.15rem solid #755ae2 !important;
            }
          }

          .check-wrap-disabled {
            label {
              cursor: not-allowed !important;
            }
            // display: none;
          }
        }

        .item {
          display: flex;
          align-items: center;
          border-radius: 2.5rem;
          padding: 1.2rem 2.1rem;
          cursor: pointer;
          gap: 1rem;
          position: relative;

          & > * {
            position: relative;
            z-index: 3;
          }

          &::after {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            border-radius: 2.5rem;
            top: 0;
            left: 0;
            border: 0.25rem solid rgba(117, 90, 226, 1);
            content: "";
            visibility: hidden;
          }

          .img-box {
            width: 2.5rem;
            height: 2.5rem;

            .img {
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }

          .name {
            font-weight: 600;
          }
        }

        .item-active {
          cursor: default;

          &::after {
            visibility: visible;
          }
        }
      }
    }

    .select-manage-wrap {
      flex: 1;
      // background-color: red;
      overflow-y: scroll;
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding-bottom: 5rem;

      @media only screen and (max-width: 42em) {
        flex: unset;
        min-height: 100vh;
        // background-color: red;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      & > *:not(:last-child) {
        padding-bottom: 2rem;
      }

      .select-react-disabled {
        cursor: not-allowed !important;
        input {
          cursor: not-allowed !important;
        }
      }

      .select-item {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 2rem;
        cursor: pointer;

        .avatar-box {
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 50%;
          background-color: #020202 !important;
          display: grid;
          place-items: center;

          span {
            font-weight: 600;
            color: #ffffff;
            display: inline-block;
            font-size: 1.2rem;
            text-transform: uppercase;
          }
        }

        .name-role-box {
          display: flex;
          flex-direction: column;
          align-self: center;

          .name {
            font-size: 1.7rem;
            font-weight: 600;
          }
        }

        .img-wrap {
          .img-box {
            width: 4rem;
            height: 4rem;

            .img {
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }
        }

        .select-option-wrap {
          // display: none;
          margin-left: auto;

          form {
            .form-group {
              .react-select-class {
                & > *:nth-child(3) {
                  width: max-content !important;
                  border: unset !important;
                  border-radius: 3rem;
                  background-color: rgba(247, 247, 247, 1);
                  padding: 0.07rem 0.3rem;
                }

                & > *:nth-child(4) {
                  left: unset;
                  right: 0.5rem;
                  width: max-content;
                  // height: 30rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .form-two {
    width: 100%;
    align-self: stretch;
    flex: 1;
    // background-color: #755ae2;
    // height: 55rem !important;

    .two-view-box {
      // display: flex;
      width: 100%;
      flex: 1;
      // height: 100%;
      // background-color: blue;
      align-self: stretch;
      position: relative;

      .left-box {
        // background-color: red;
        display: flex;
        justify-content: center;
        border-width: 0.15rem;
        margin-right: 3rem;
        transition: all .3s ease-in-out;
        position: relative;
        position: absolute;
        top: 0%;
        left: 0%;
        width: 50%;
        z-index: 4;
        height: 100%;

        .bring-back-box{
          position: absolute;
          top: 2rem;
          right: 0rem;
          cursor: pointer;
          width: 3rem;
          height: 3rem;
          display: grid;
          place-items: center;
          
          .icon{
            width: 2rem;
            height: 2rem;
          }
        }

        .wrappert {
          display: flex;
          flex-direction: column;

          .title {
            font-size: 1.4rem;
            margin-bottom: 2rem;
          }

          // .select-manage-wrap{
          //   // height: 15rem !important;
          //   background-color: red;
          // }
        }
      }

      .left-box-center {
        z-index: 4;
        width: 100%;
        transform: translateX(-50%);
        left: 50%;
      }

      .right-box {
        display: grid;
        place-items: center;
        margin-left: auto;
        position: relative;
        z-index: 2;
        position: absolute;
        transition: all .3s ease-in-out;
        top: 0%;
        right: 0%;
        width: 50%;

        .wrapper-sub {
          width: 100%;
          display: grid;
          place-items: center;
          transition: all 0.3s ease-in-out;

          .wrap{
          border-radius: 1.2rem;
          width: 70%;
          display: flex;
          flex-direction: column;
          overflow: hidden;

          .title-header {
            padding: 1.5rem 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
              font-size: 1.8rem;
              font-weight: 600;
            }

            .cancel-box {
              width: 3rem;
              height: 3rem;
              border-radius: 50%;
              display: grid;
              place-items: center;
              cursor: pointer;

              .img-box {
                width: 50%;
                height: 50%;

                .img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }

          .content-wrap {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            padding-top: 2rem;
            padding-bottom: 2rem;

            & > *:not(:last-child) {
              padding-bottom: 2rem;
            }

            .read-item {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              padding-left: 2rem;
              padding-right: 2rem;

              .name {
                font-size: 1.3rem;
                font-weight: 600;
              }

              .text {
                font-size: 1.3rem;
              }
            }
          }
          }

       
        }

        .wrapper-sub-translate {
          animation: subInfoAnime .3s ease-in-out;
        }
      }

      .right-box-leave{
        transform: translateX(100%);
      }
    }
  }
}

@keyframes subInfoAnime {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0.5;
  }
}
