// @import "../../breakpoints";

.top-transaction-single-bulk-container-box {
  // min-height: 20rem;
  width: 100%;
  // background-color: red;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 2.5rem;
  margin-bottom: 1.5rem;

  @media only screen and (max-width: 55em) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }

  .box {
    border-radius: 1.2rem;
    padding: 3rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 700;
      padding-left: 2rem;
      margin-bottom: 1rem;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      gap: 1rem;

      .title-total {
        padding: 0.4rem 1rem;
        border-radius: 1rem;
        display: grid;
        place-items: center;

        span {
          display: inline-block;
          color: #ffffff !important;
          font-size: 1.1rem;
        }
      }
    }

    .title-two {
      display: flex;
      align-items: center;
      padding-left: 2rem;
      margin-bottom: 1rem;

      .title {
        margin-bottom: unset;
        padding-left: unset;
        margin-left: 0.7rem;
      }
    }

    .value-box {
      padding: 1.5rem 1rem 1.5rem 2rem;
      border-radius: 0.8rem;
      margin-top: auto;

      .value {
        font-weight: 700;
        font-size: 1.75rem;
      }
    }
  }
}

.transaction-main-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  @media only screen and (max-width: 48em) {
    flex: unset;
    // overflow-y: scroll;
    min-height: 50rem;
  }
  // background-color: red;
  // height: 100%;
  //   margin-bottom: 5rem;

  .mobile-table-box {
    max-height: 65rem;
    overflow-y: scroll;
    // padding-bottom: 10rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .transaction-card-bar-chart-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 0rem;
    transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    position: relative;
    z-index: 2;

    @media only screen and (max-width: 48em) {
      display: none;
    }

    .filter-by-text {
      font-size: 1.2rem;
      cursor: pointer;
      color: #755ae2;
      align-self: flex-start;
      font-weight: 600;
    }

    .text-compare {
      font-size: 1.1rem;
      // margin: 1rem 0rem;

      span {
        color: #1ace37 !important;
      }
    }

    .chart-info-content-box {
      display: flex;

      & > * {
        padding: 2rem 3rem;
      }

      .left-box {
        flex: 0 0 30%;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .card-info {
          display: flex;
          flex-direction: column;
          padding: 2rem 0rem;
          border-radius: 1.2rem;
          gap: 2.5rem;
          cursor: pointer;

          & > * {
            padding: 0rem 1.5rem;
          }

          .total-val-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 2.5rem;

            .label-val {
              .label {
                text-transform: uppercase;
                font-size: 1.2rem;
              }

              .value {
                font-weight: 700;
                font-size: 2rem;
              }
            }

            .img-wrap {
              // flex: 0 0 5%;
              display: grid;
              place-items: center;

              .img-box-cre-deb {
                width: 3rem;
                height: 3rem;
                background-color: #1ace37 !important;
                border-radius: 50%;
                display: grid;
                place-items: center;
                flex: 0 0 5%;

                .img {
                  width: 35%;
                  height: 35%;
                  object-fit: contain;
                  filter: brightness(1000%);
                }
              }

              .img-box-debit {
                background-color: #ff0f00 !important;
              }
            }
          }
        }
      }

      .right-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        // min-height: 38rem;

        .top-info-box {
          // flex: 0 0 25%;
          display: flex;
          justify-content: space-between;

          .total-wallet-box {
            font-size: 1.25rem;

            .value {
              font-size: 2.5rem;
              font-weight: 700;
            }

            .update {
              display: flex;
              align-items: center;
              gap: 0.5rem;
            }
          }

          .label-select-box {
            display: flex;
            align-items: center;
            gap: 2rem;

            .pos-select-wrap {
              width: max-content;

              .react-select-class {
                & > *:nth-child(3) {
                  box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
                    0px 0px 2px rgba(51, 51, 51, 0.02);
                  min-height: unset;

                  & > *:first-child {
                    padding: unset;

                    & > *:nth-child(2) {
                      // display: none;
                      input {
                        min-width: unset !important;
                        grid-area: unset !important;
                      }
                    }
                  }

                  & > *:nth-child(2) {
                    padding: unset;
                    margin-left: 0.7rem;
                    & > *:nth-child(2) {
                      padding: unset;

                      svg {
                        width: 1.5rem;
                        height: 1.5rem;
                        // fill: #020202;
                      }
                    }
                  }
                }
                & > *:nth-child(4) {
                  width: max-content;
                  left: unset;
                  right: 0;
                }
              }
            }

            .text-label {
              display: flex;
              align-items: center;
              gap: 0.6rem;

              .text {
                font-size: 1.25rem;
              }

              span {
                width: 1rem;
                height: 1rem;
                background-color: #755ae2;
                border-radius: 0.3rem;
              }

              .expense {
                background-color: #b4a4fc;
              }
            }
          }
        }

        .bottom-info-box {
          flex: 1;
          // background-color: red;

          .bar-chat-box {
            width: 100%;
            height: 100%;
            // background-color: red;
          }
        }
      }
    }

    // .underline {
    //   // margin: 2rem 0rem;
    // }
  }

  .transaction-card-bar-chart-wrap-show {
    // padding: 2rem 0rem;
    overflow-y: visible;
    max-height: 40rem;
    margin-bottom: 2rem;
    transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
  }

  .new-search-filter-chart-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 3rem;
    margin: 2rem 0rem;

    @media only screen and (max-width: 42em) {
      padding: 0rem 1.5rem;
    }

    .chart-hide-show-box {
      .hide-show-box {
        display: flex;
        align-items: center;
        gap: 0.7rem;

        @media only screen and (max-width: 42em) {
          display: none;
        }

        & > *:last-child {
          transform: scale(0.8);
        }

        span {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
    }

    .search-filter-box {
      display: flex;
      align-items: stretch;
      gap: 2rem;
      position: relative;
      z-index: 100;

      @media only screen and (max-width: 42em) {
        gap: 1rem;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
      }

      .badge-filter-option-box {
        display: flex;
        align-items: center;
        gap: 2rem;

        .option-box {
          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 1rem 1.5rem;
          border-radius: 2rem;

          .icon {
            cursor: pointer;
            transform: translateY(0.1rem);
          }
        }
      }

      .export-table-content-box {
        display: grid;
        place-items: center;
        padding: 0.5rem 1.5rem;
        border-radius: 0.8rem;
        cursor: pointer;
        // margin-left: -2rem;

        &:hover .img-box {
          transform: translateY(0.4rem);
        }

        .img-box {
          width: 2.2rem;
          height: 2.2rem;
          transition: all 0.3s;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .filter-drop-box {
        position: relative;

        .drop-box-wrap {
          position: absolute;
          border-radius: 1.2rem;
          top: 110%;
          right: 0;
          padding: 2rem;
          display: flex;
          flex-direction: column;
          min-width: 30rem;
          gap: 2rem;
          animation: moveDownSettingDrop 0.2s ease-in-out backwards;
        }

        .filter-box {
          height: 100%;
          display: flex;
          flex-direction: row;
          gap: 0.8rem;
          align-items: center;
          padding: 1rem 1.5rem;
          border-radius: 0.8rem;
          cursor: pointer;

          .line-box {
            margin: auto 0rem;
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            width: 1.8rem;
            align-items: center;
            // align-self: center;

            span {
              display: inline-block;
              height: 0.15rem;
              width: 100%;
              border-radius: 1rem;
              background-color: #676767;
            }

            & > *:nth-child(2) {
              width: 70%;
            }

            & > *:last-child {
              width: 40%;
            }
          }

          .text {
            font-weight: 700;
          }
        }
      }
    }
  }

  .new-top-chart-box {
    display: flex;
    min-height: 30rem;
    margin-top: 3rem;

    .left-box {
      flex: 0 0 70%;
      border-right: 0.1rem solid rgba(204, 204, 204, 0.3);
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      padding: 0rem 3rem;

      .graph-box {
        flex: 1;
      }

      .switch-box {
        width: 100%;
        padding-bottom: 1rem;
        border-bottom: 0.1rem solid rgba(204, 204, 204, 0.3);
        display: flex;
        align-items: center;

        .switch-wrap {
          align-self: stretch;
          display: flex;
          align-items: flex-end;
          margin-right: auto;
          gap: 2rem;
          // background-color: red;

          .text {
            position: relative;
            cursor: pointer;

            & > * {
              position: relative;
              z-index: 4;
            }

            &::after {
              content: "";
              position: absolute;
              z-index: 3;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 0.3rem;
              background-color: #020202;
              border-radius: 3rem;
              transform: translateY(0.9rem);
              visibility: hidden;
              // color: rgb(227, 50, 37);
            }
          }

          .text-active {
            font-weight: 500;

            span {
              color: #020202;
            }

            &::after {
              visibility: visible;
            }
          }
        }

        .wrap-filter {
          width: 10rem;
          position: relative;
          z-index: 10;

          .react-select-class {
            & > *:nth-child(3) {
              height: 3.5rem !important;
            }

            & > *:nth-child(4) {
              width: 20rem;
              left: unset;
              right: 0;
            }
          }
        }
      }
    }

    .right-box {
      flex: 1;
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      padding-left: 3rem;
      padding-right: 3rem;

      & > *:not(:last-child) {
        border-bottom: 0.1rem solid rgba(204, 204, 204, 0.3);
      }

      .box {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        justify-content: center;

        .label {
          font-size: 1.3rem;
        }

        .value {
          font-weight: 700;
          font-size: 2rem;
        }
      }
    }
  }

  .top-chart-box {
    padding: 2rem 3rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .metric-select-box {
      display: flex;

      .metrics-box {
        flex: 0 0 85%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2.5rem;

        .card-metrics {
          filter: drop-shadow(0px 1px 2px rgba(51, 51, 51, 0.06))
            drop-shadow(0px 0px 2px rgba(51, 51, 51, 0.02));
          border-radius: 0.8rem;
          border: 0.1rem solid #f0eeff;
          padding: 1.5rem;
          display: flex;
          align-items: center;
          gap: 1rem;

          .text-value-box {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;

            .text {
              font-size: 1.35rem;
            }

            .value {
              font-weight: 600;
              font-size: 2.2rem;
              letter-spacing: -0.05rem;
            }
          }

          .chart-box-wrap {
            width: 5.5rem;
            height: 5.5rem;
            position: relative;

            .img-box {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 2rem;
              height: 2rem;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }

      .select-filter-box {
        flex: 1;
        display: flex;
        justify-content: flex-end;

        .wrap-filter {
          width: 10rem;
          position: relative;
          z-index: 10;

          .react-select-class {
            & > *:nth-child(4) {
              width: 20rem;
              left: unset;
              right: 0;

              // & > *:not(:last-child) {
              //   // border-bottom: 0.09rem solid #e5e6e6 !important;
              // }
            }
          }
        }
      }
    }

    .bar-chat-box {
      width: 100%;
      height: 22rem;
      // background-color: red;
    }
  }

  // .underline {
  //   width: 100%;
  //   height: 0.1rem;
  //   margin: 1.5rem 0rem 1rem 0rem;
  //   background-color: #f0eeff;

  //   @media only screen and (max-width: 42em) {
  //     margin: 0rem;
  //   }
  // }

  .title-sort-text-box {
    display: flex;
    align-items: center;
    padding: 1rem 3rem;
    margin: 1rem 0rem;

    @media only screen and (max-width: 58em) {
      flex-wrap: wrap;
      //  gap: ;
    }

    @media only screen and (max-width: 42em) {
      margin: 0rem;
      padding: 0rem 1.5rem;

      form {
        width: 100%;
      }
    }

    // @media only screen and (max-width: 42em) {
    //   flex-direction: column;
    //   padding: 1rem ;

    // }

    // display: none;

    .date-picker-wrap {
      position: relative;

      .select-custom-wrap {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 1rem 0.8rem rgba(218, 218, 218, 0.3);
        max-height: 0;
        overflow-x: hidden;
        overflow-y: hidden;
        transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
        -webkit-transition: max-height 0.8s;
        -moz-transition: max-height 0.8s;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .title {
          font-weight: 600;
        }

        .btn-reuse {
          margin-top: 0.5rem;
        }

        .input-date-group {
          display: flex;
          flex-direction: column;
          // display: none;

          .label {
            text-align: left;
            align-self: flex-start;
            text-transform: capitalize;
            font-size: 1.35rem;
            margin-bottom: 0.4rem;
          }

          .input-calender {
            padding: 1.4rem !important;
          }
        }
      }

      .select-custom-wrap-show {
        max-height: 40rem;
        transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
        border: 0.1rem solid #cccccc;
        padding: 1rem;
      }
    }

    .search-group {
      margin-right: auto;

      @media only screen and (max-width: 57em) {
        margin-bottom: 1.5rem;
        // display: none;
        margin-right: unset;
        width: 100%;
      }
    }

    .title-text {
      margin-right: auto;
      .text {
        font-size: 1.25rem;
      }
      .title {
        font-weight: 700;
        font-size: 1.5rem;
      }
    }

    .fitler-box {
      //   width: 40rem;
      display: grid;
      grid-template-columns: 1fr 1fr max-content;
      grid-gap: 2rem;
      align-items: center;
      margin-left: auto;

      @media only screen and (max-width: 57em) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .date-picker-wrap {
          width: 100%;
          .form-group {
            width: 100%;

            .react-select-class {
              width: 100%;

              & > *:nth-child(3) {
                min-width: unset;
                width: 100%;
              }

              & > *:nth-child(4) {
                // width: 20rem;
                left: unset;
                right: 0;

                // & > *:not(:last-child) {
                //   // border-bottom: 0.09rem solid #e5e6e6 !important;
                // }
              }
            }
          }
        }

        .form-group {
          width: 100%;

          .react-select-class {
            width: 100%;

            & > *:nth-child(3) {
              min-width: unset;
              width: 100%;
            }

            & > *:nth-child(4) {
              // width: 20rem;
              left: unset;
              right: 0;

              // & > *:not(:last-child) {
              //   // border-bottom: 0.09rem solid #e5e6e6 !important;
              // }
            }
          }
        }
      }

      // @media only screen and (max-width: 51em) {
      //   margin-left: unset;
      //   justify-content: flex-start;
      //   display: grid;
      //   grid-template-columns: repeat(2, 1fr);
      //   grid-gap: 1.5rem;
      //   // display: none;

      //   &>*:first-child{
      //     grid-column:  1 / span 1;
      //   }

      //   &>*:nth-child(2){
      //     grid-column: 2 / -1;
      //   }
      // }

      // @media only screen and (max-width: 42em) {
      // grid-template-columns: repeat(auto-fit, minmax(min-content, 15rem));
      // }

      .reset-text {
        color: var(--primary-purple);
        font-weight: 600;
        cursor: pointer;

        @media only screen and (max-width: 57em) {
          width: max-content;
        }

        @media only screen and (max-width: 51em) {
          display: none;
        }
      }

      .react-select-class {
        // min-width: 10rem;

        & > *:nth-child(3) {
          min-width: 25rem;

          @media only screen and (max-width: 57em) {
            min-width: unset;
            width: max-content;
            // width: 19rem;
          }
        }

        & > *:nth-child(4) {
          // width: 20rem;
          left: unset;
          right: 0;

          // & > *:not(:last-child) {
          //   // border-bottom: 0.09rem solid #e5e6e6 !important;
          // }
        }
      }
    }
  }

  .table-wrap {
    padding-bottom: 8rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
    z-index: 3;
    // background-color: red !important;
    flex: 1;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (max-width: 48em) {
      padding-bottom: 0rem;
      width: 90%;
      margin: 3rem auto 0rem auto;
      // min-height: 50rem;
    }

    .wrap-scroll {
      @media only screen and (max-width: 51em) {
        overflow-x: scroll;
        width: 100%;
        padding-bottom: 3rem;
        // display: none;

        &::-webkit-scrollbar:vertical {
          display: none;
        }

        /* width */
        &::-webkit-scrollbar {
          width: 0.5rem;
          height: 0.4rem;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: none !important;
          background-color: transparent !important;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #020202;
          border-radius: 30rem;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #020202;
        }
      }

      .table {
        @media only screen and (max-width: 51em) {
          width: 95rem;
        }
      }

      @media only screen and (max-width: 48em) {
        display: none;
      }
    }
    @media only screen and (max-width: 51em) {
      // overflow-x: scroll;
      // overflow-y: scroll;
    }

    .table-pagination-box {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      padding: 0rem 3rem;
      // padding-bottom: 20rem;
    }

    // thead {
    //   tr {
    //     // & > *:first-child {
    //     //   padding-left: 3rem;
    //     // }

    //     // & > *:nth-child(3) {
    //     //   text-align: left;
    //     // }

    //     // & > *:last-child {
    //     //   text-align: center;
    //     //   padding-right: 3rem;
    //     //   //   color: #1110101a;
    //     // }
    //   }
    // }

    .table-body {
      tr {
        td {
          padding-top: 1.8rem !important;
          padding-bottom: 1.8rem !important;
          font-size: 1.45rem !important;
        }

        & > *:first-child {
          padding-left: 3rem;
          width: 40%;
          // min-width: unset !important;
          padding-right: 2rem;
          // background-color: red;
        }

        & > *:last-child {
          //   text-align: center ;
          padding-right: 3rem;
        }

        .action-box {
          justify-content: center;
        }

        .status-type-wrap {
          background-color: #e9f5ff;
          border-radius: 3rem;
          width: max-content;
          padding: 0.8rem 1.2rem;
          color: #476885;

          span {
            font-weight: 400 !important;
            font-size: 1.2rem;
          }
        }

        .status-type-wrap-fail {
          background-color: #fff5f5;
          color: #ff0f00;
        }

        .status-type-wrap-success {
          background-color: #e8fff6;
          color: #1ace37;
        }

        .status-type-wrap-pending {
          background-color: #fff6ed;
          color: #ea872d;
        }

        // td{
        //   position: relative;
        //   width: 100%;
        //   height: 100%;
        // }
      }
    }

    // .table-data-action {
    //   gap: unset !important
    //   ;

    //   .action-box {
    //     gap: unset !important;
    //   }
    // }

    .transaction-action-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      // background-color: red;
      padding: 0.45rem 0rem;

      .img-box {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        display: grid;
        place-items: center;
        transition: transform 0.3s ease-in-out;

        &:hover {
          transform: scale(1.05);
        }

        .img {
          width: 50%;
          height: 50%;
          object-fit: contain;
        }
      }

      & > *:nth-child(2) {
        .img {
          width: 50%;
          height: 50%;
          object-fit: contain;
        }
      }
    }

    .type-wrap {
      padding: 0.8rem 1.2rem;
      background-color: #f7f8f7;
      border-radius: 3rem;
      width: max-content;
      // margin: 0rem auto;

      span {
        // font-weight: 400 !important;
        font-size: 1.2rem;
      }
    }

    .type-text-box {
      display: flex;
      align-items: center;
      // background-color: red;
      width: max-content;

      .text-box {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;

        .text {
          font-size: 1.55rem;
          font-weight: 600;
        }

        .text-small {
          font-size: 1.3rem;
          font-weight: 400 !important;
        }
      }

      .img-wrap {
        // flex: 0 0 5%;
        display: grid;
        place-items: center;

        .img-box-cre-deb {
          width: 3rem;
          height: 3rem;
          margin-right: 2rem;
          background-color: #1ace37 !important;
          border-radius: 50%;
          display: grid;
          place-items: center;
          flex: 0 0 5%;

          .img {
            width: 35%;
            height: 35%;
            object-fit: contain;
            filter: brightness(1000%);
          }
        }

        .img-box-debit {
          background-color: #ff0f00 !important;
        }
      }
    }
  }
}

.transaction-download-receipt-modal {
  // background-color: red;

  .wrap-modal {
    // overflow-x: scroll;
    // width: 100%;
    // background-color: red;
    &::-webkit-scrollbar {
      display: none;
    }

    .title {
      font-size: 1.6rem;
      font-weight: 700;
    }

    .underline {
      width: 100%;
      height: 0.1rem;
      background-color: #f0eeff;
      margin: 1.3rem 0rem;
    }
  }

  .label-value-status-box {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    // background-color: red;
    // margin-top: 2rem;
    margin-bottom: 2rem;

    .box {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      // flex-wrap: wrap;

      .label-download {
        flex: 0 0 40%;
        // margin-right: 3rem;
      }

      .value-download {
        flex: 1;
        font-weight: 600;
      }

      .value-download-two {
        display: flex;
        align-items: center;
        gap: 1rem;

        .avatar-box {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          filter: drop-shadow(0px 1px 2px rgba(51, 51, 51, 0.06))
            drop-shadow(0px 0px 2px rgba(51, 51, 51, 0.02));
          background-color: #ffffff;
          display: grid;
          place-items: center;
          position: relative;

          @media only screen and (max-width: 48em){
            width: 4rem;
            height: 4rem;
           }

          .img-box {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .copied-box-reciept {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          position: relative;
          background-color: black;

          .icon {
            width: 1rem;
            height: 1rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            fill: #020202;
          }
        }

        .img-box-reciept {
          cursor: pointer;
          display: grid;
          place-items: center;
          transition: all 0.3s;
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          // display: none;

          .img {
            width: 50%;
            height: 50%;
            object-fit: contain;
            // background-color: #1ace37;
          }

          &:hover {
            transform: scale(1.05);
          }
        }
      }

      // .ref {
      //   display: none;

      //   span {
      //     display: inline-block;
      //     width: 8rem !important;
      //   }
      // }

      .status-type-wrap {
        background-color: #e9f5ff;
        border-radius: 3rem;
        width: max-content;
        padding: 0.8rem 1.2rem;
        color: #476885;
        text-transform: capitalize;

        span {
          // font-weight: 700;
          font-size: 1.2rem;
        }
      }

      .status-type-wrap-fail {
        background-color: #fff5f5;
        color: #ff0f00;
      }

      .status-type-wrap-success {
        background-color: #e8fff6;
        color: #1ace37;
      }

      .status-type-wrap-pending {
        background-color: #fff6ed;
        color: #ea872d;
      }

      .status-type-wrap-disabled {
        background-color: #f7f8f7;
        // color: ;
        //
      }
    }
  }

  .two-button-wrap {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #f7f8f7;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    padding: 2rem 2rem;
    gap: 2rem;

    .raven-btn {
      width: 100%;
    }
  }
}

.two-line-transaction-box-wrap {
  // min-height: 35rem;
  width: 100%;
  // background-color: red;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;

  // display: none;
  // margin-bottom: 3rem;

  // .underline {
  //   width: 100%;
  //   height: 0.1rem;
  //   margin: 1.5rem 0rem 1rem 0rem;
  //   background-color: #f0eeff;
  // }

  .top-label-box {
    display: flex;
    align-items: center;
    padding: 0rem 3rem;
    // display: none;

    @media only screen and (max-width: 42em) {
      gap: 1rem;
      padding: 0rem 1.5rem;
      flex-direction: column;
      align-items: flex-start;
    }

    .box-hide-mobile {
      display: flex;
      align-items: center;
      gap: 0.7rem;
      margin-left: auto;

      @media only screen and (min-width: 42em) {
        display: none;
      }

      @media only screen and (max-width: 42em) {
        display: flex !important;
      }

      & > *:last-child {
        transform: scale(0.65);
      }
    }

    .date-box {
      margin-right: 2rem;
    }

    .expense-income-label {
      display: flex;
      align-items: center;
      gap: 3rem;
      // display: none;

      @media only screen and (max-width: 42em) {
        display: none;
      }

      .box {
        display: flex;
        align-items: center;

        span {
          display: inline-block;
          width: 0.9rem;
          height: 0.9rem;
        }

        .text {
          font-size: 1.2rem;
          margin-left: 0.7rem;
        }
      }

      .hide-show-box {
        display: flex;
        align-items: center;
        gap: 0.7rem;

        @media only screen and (max-width: 42em) {
          display: none;
        }

        & > *:last-child {
          transform: scale(0.8);
        }

        span {
          width: 1.8rem;
          height: 1.8rem;
        }
      }

      .income {
        span {
          background-color: #1ace37;
        }
      }

      .expense {
        span {
          background-color: #ff0f00;
        }
      }
    }

    .wrap-filter {
      width: 10rem;
      position: relative;
      z-index: 10;
      margin-right: auto;

      @media only screen and (max-width: 42em) {
        display: none;
      }

      .react-select-class {
        & > *:nth-child(3) {
          padding: 0rem;
          flex: unset;
          justify-content: space-evenly;

          & > *:first-child {
            padding-right: 0rem;
            flex: unset;
          }

          & > *:nth-child(2) {
            & > *:nth-child(2) {
              padding: unset;
            }
          }
        }

        & > *:nth-child(4) {
          // width: 20rem;
          left: 0;
        }
      }
    }
  }

  .chart-wrap-box {
    flex: 1;
    // min-height: 25rem;
    display: flex;
    flex-direction: column;
    border-bottom: 0.1rem solid #f0eeff;
    padding-bottom: 1.5rem;
    max-height: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
    -webkit-transition: max-height 0.8s;
    -moz-transition: max-height 0.8s;
    width: 100%;

    @media only screen and (max-width: 42em) {
      display: none;
    }

    .chart-wrap {
      // padding: 0rem 3rem;
      height: 20rem;
      background-image: url("../../../assets/two-line-chart-bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin: 2rem 3rem;
    }

    .label-wrap {
      padding: 0rem 3rem;
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text {
        font-size: 1.2rem;
      }
    }
  }

  .chart-wrap-box-show {
    max-height: 30rem;
    transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
  }

  .metrics-wrap-box {
    display: flex;
    //  background-color: red;
    padding: 0rem 3rem;
    // margin-bottom: -0.5rem;
    border-bottom: 0.1rem solid #f0eeff;
    // display: none;

    @media only screen and (max-width: 58em) {
      flex-wrap: wrap;
      //  gap: ;
      justify-content: center;
    }

    @media only screen and (max-width: 42em) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      overflow-x: hidden;
      justify-items: center;
      margin: 2rem 0rem;

      & > *:first-child {
        grid-row: 1 / span 1;
        grid-column: 1 / -1;
        width: 100%;
        justify-content: center;
      }

      & > *:nth-child(2) {
        border-bottom: unset !important;
        border-right: 0.1rem solid #f0eeff !important;
      }
    }

    & > *:not(:last-child) {
      border-right: 0.1rem solid #f0eeff;
      padding-right: 4rem !important;
      margin-right: 2rem;

      @media only screen and (max-width: 58em) {
        border-bottom: 0.1rem solid #f0eeff;
        margin-right: unset;
        border-right: unset;
        // margin-bottom: 2rem;

        @media only screen and (max-width: 42em) {
          margin-right: unset;
          padding-right: unset;
        }
      }
    }

    .card-metrics {
      // border-radius: 0.8rem;
      // border: 0.1rem solid #f0eeff;
      padding: 1.5rem 1.5rem;
      display: flex;
      align-items: center;
      gap: 1.5rem;
      // flex: 0 0 25%;

      @media only screen and (max-width: 42em) {
        gap: 1rem;
        padding: 1rem 0.5rem;
      }

      .text-value-box {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        .text {
          font-size: 1.2rem;

          @media only screen and (max-width: 42em) {
            font-size: 1rem;
          }
        }

        .value {
          font-weight: 500;
          font-size: 2.5rem;
          letter-spacing: -0.05rem;

          @media only screen and (max-width: 42em) {
            font-size: 1.45rem;
            letter-spacing: -0.015rem;
            font-weight: 700;
          }
        }
      }

      .chart-box-wrap {
        width: 4.5rem;
        height: 4.5rem;
        position: relative;

        @media only screen and (max-width: 42em) {
          width: 3.4rem;
          height: 3.4rem;
        }

        .img-box {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 1.6rem;
          height: 1.6rem;

          @media only screen and (max-width: 42em) {
            width: 1.2rem;
            height: 1.2rem;
            transform: translateY(-60%) translateX(-50%);
          }

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  .metrics-box-mobile {
    @media only screen and (max-width: 42em) {
      max-height: 0;
      overflow-x: hidden;
      overflow-y: hidden;
      transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
      -webkit-transition: max-height 0.8s;
      -moz-transition: max-height 0.8s;
      width: 100%;
    }
  }

  .metrics-box-mobile-show {
    @media only screen and (max-width: 42em) {
      max-height: 30rem;
      transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
    }
  }
}

//responsive styling
// @include xs {
//   .top-label-box {
//     flex-wrap: wrap;
//     gap: 1rem;
//     justify-content: space-between;

//     .wrap-filter {
//       margin: unset !important;
//     }
//   }

//   .chart-wrap-box{
//     border-bottom: none !important;
//   }

//   .metrics-wrap-box {
//     flex-wrap: wrap;
//     border-radius: 1.2rem;
//     align-items: center;
//     padding: 0 !important;
//     margin: 0 2rem;
//     border: 0.075rem solid rgba(217, 217, 217, 0.67) !important;

//     .card-metrics:nth-child(1){
//       border-bottom: 0.075rem solid rgba(217, 217, 217, 0.67) !important;
//     }

//     .card-metrics:nth-child(2){
//       border-bottom: 0.075rem solid rgba(217, 217, 217, 0.67) !important;
//     }

//     .card-metrics {
//       width: 100%;
//       padding-right: 0rem !important;
//     }
//   }

//   .title-sort-text-box {
//     flex-wrap: wrap;
//     gap: 2rem !important;
//     width: 100%;

//     .search-group {
//       width: 100%;
//     }

//     .fitler-box {
//       width: 100%;
//       display: flex !important;
//       flex-wrap: wrap;
//     }
//   }

//   .dashboard-schedule-wrapper-box {
//     .title-sort-text-box {
//       padding: 0rem !important;
//     }
//   }
// }

.bulk-transaction-main-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;

  .export-table-content-box {
    display: flex;
    align-items: center;
    padding: 1.2rem 1.5rem;
    border-radius: 0.8rem;
    gap: 1rem;
    cursor: pointer;
    // margin-left: -2rem;
    align-self: flex-end;
    margin: 2rem;

    &:hover .img-box {
      transform: translateY(0.4rem);
    }

    .img-box {
      width: 2.2rem;
      height: 2.2rem;
      transition: all 0.3s;

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .transaction-action-wrap {
    // color: rgba(255, 15, 0, .3);
    // margin: 0rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: red;
    gap: 1rem;
    // width: 100%;
    // min-width: 10rem;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);

    .img-box {
      width: 3.5rem;
      height: 3.5rem;
      // background-color: #f7f8f7;
      border-radius: 50%;
      display: grid;
      place-items: center;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }

      .img {
        width: 50%;
        height: 50%;
        object-fit: contain;
      }
    }

    & > *:nth-child(2) {
      .img {
        width: 50%;
        height: 50%;
        object-fit: contain;
      }
    }
  }
}
