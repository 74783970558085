.confirm-two-fa-modal-wrap-index-modal {
  .raven-modal-content-wrap {
    width: 70rem;
  }

  .confirm-two-fa-modal-wrap-index {
    width: 100%;
    min-height: 35rem;
    background-color: #ffffff;
    padding-bottom: 10rem;

    .title-text-box {
      margin-bottom: 2.5rem;

      .title {
        font-weight: 600;
        font-size: 2rem;
      }

      line-height: 2.3rem;
    }

    .bar-code-text-box-wrap {
      display: flex;
      gap: 3rem;

      .bar-box {
        width: 17rem;
        height: 17rem;
        display: grid;
        place-items: center;
        border-radius: 1.2rem;
        flex: 0 0 17rem;

        .bar {
          width: 85%;
          height: 85%;
        }
      }

      .text-copy-box {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        flex: 1;
        // display: none;

        .text-box {
          width: 75%;

          .text {
            font-size: 1.65rem;
          }
        }

        .copy-box {
          display: flex;
          padding: 1rem 1.6rem;
          border-radius: 0.8rem;
          align-items: center;
          justify-content: space-between;

          .copy-text {
            color: rgba(103, 103, 103, 1);
            font-weight: 700;
            font-size: 1.6rem;
          }

          .copy-icon {
            margin-left: auto;

            .img-box,
            .copied {
              width: 2.7rem;
              height: 2.7rem;
              display: grid;
              place-items: center;
              border-radius: 50%;

              .check {
                width: 40%;
                height: 40%;
                color: #ffffff;
              }

              .img {
                width: 90%;
                height: 90%;
                object-fit: contain;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .enter-pin-text-box {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .enter-text {
        font-size: 1.65rem;
        // font-weight: 600;
      }

      .pin-box {
        width: 38rem;
        // min-height: 6rem;
      }
    }

    .enter-pin-text-box-two {
      .pin-box {
        width: 25rem;
      }
    }
    .btn-two-box {
      position: absolute;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      left: 0;
      bottom: 0;
      padding: 1.5rem 3rem;
      gap: 2rem;
    }
  }
}

.diable-two-fa-modal-wrap-index-modal {
  .raven-modal-content-wrap {
    width: 45rem !important;

    .confirm-two-fa-modal-wrap-index {
      min-height: unset;
      padding-bottom: 3rem;

      .title-text-box {
        margin-bottom: 3.5rem;
      }

      .request-pin-box {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 2rem;

        .text {
          font-size: 1.5rem;
        }

        .text-click {
          padding: 0.8rem 1.5rem;
          border-radius: 3rem;
          cursor: pointer;
          min-width: 15rem;
          display: flex;
          justify-content: center;

          .recieve {
            color: rgba(151, 71, 255, 1);
            font-weight: 500;
          }
        }
      }
    }
  }
}

.confirm-two-fa-modal-wrap-index-type-two {
  display: flex;
  flex-direction: column;
  padding-bottom: 2.5rem;
  min-height: 45rem;

  .loading-fa-box{
    flex: 1;
    display: grid;
    place-items: center;

    &>*{
      transform: scale(0.8);
    }
  }

  .title-text-box {
    margin-bottom: 2.5rem;

    .title {
      font-weight: 600;
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  .two-fa-pin-box{
    width: 100%;
    display: flex;
    justify-content: center;

    .pin-two-fa{
      width: 90%;

      @media only screen and (max-width: 48em){
        width: 100%;
      }
    }
  }

  .scan-big-box {
    width: 100%;
    padding: 1.5rem;
    border-radius: 1.2rem;
    display: grid;
    place-items: center;
    margin-bottom: 2rem;

    .scan-inner-wrap {
      width: 16rem;
      height: 16rem;
      border-radius: 1.2rem;
      padding: 0rem;

      .img-box {
        width: 100%;
        height: 100%;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .num-text-title-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    gap: 1rem;

    .text {
      font-size: 1.3rem;
    }

    .num-title-box {
      display: flex;
      align-items: center;
      gap: 1rem;

      .title {
        font-weight: 600;
        font-size: 1.65rem;
      }

      .num {
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 50%;
        display: grid;
        place-items: center;

        span {
          display: inline-block;
          font-weight: 700;
          font-size: 1.5rem;
        }
      }
    }
  }
}
