.raven-sign-modal-wrap-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 1.5rem;
}
.raven-sign-modal-wrap-box .sign-title {
  font-weight: 600;
  font-size: 1.8rem;
}
.raven-sign-modal-wrap-box .badge-sign-panel-content-box {
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  border-width: 0.15rem;
  padding-bottom: 1.5rem;
}
.raven-sign-modal-wrap-box .badge-sign-panel-content-box .sign-panel-content-box {
  width: 100%;
  height: 100%;
  height: 25rem;
  position: relative;
}
.raven-sign-modal-wrap-box .badge-sign-panel-content-box .sign-panel-content-box .sigCanvas {
  width: 100%;
  height: 100%;
}
.raven-sign-modal-wrap-box .badge-sign-panel-content-box .badge-sign {
  padding-left: 1.5rem;
  margin-top: 1rem;
}
.raven-sign-modal-wrap-box .btn-box-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
  margin-top: 1rem;
}
.raven-sign-modal-wrap-box .btn-box-wrap .btn-outline {
  border-width: 0.2rem;
}/*# sourceMappingURL=RavenSignModal.css.map */