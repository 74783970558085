.global-account-select-drop-box-wrapper {
  position: relative;
  z-index: 20;
}
.global-account-select-drop-box-wrapper .select-drop-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
  border-radius: 5rem;
  cursor: pointer;
}
.global-account-select-drop-box-wrapper .select-drop-box .value {
  font-weight: 500;
}
.global-account-select-drop-box-wrapper .select-drop-box .img-select .img-box {
  width: 1.7rem;
  height: 1.7rem;
}
.global-account-select-drop-box-wrapper .select-drop-box .img-select .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.global-account-select-drop-box-wrapper .select-drop-box .drop-icon .img-box {
  width: 1.7rem;
  height: 1.7rem;
}
.global-account-select-drop-box-wrapper .select-drop-box .drop-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.global-account-select-drop-box-wrapper .drop-select-box {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-width: 0.15rem;
  animation: moveDownSettingDrop 0.5s ease-in-out backwards;
  max-height: 30rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.global-account-select-drop-box-wrapper .drop-select-box::-webkit-scrollbar {
  display: none;
}
.global-account-select-drop-box-wrapper .drop-select-box > *:not(:last-child) {
  padding-bottom: 1.3rem;
}
.global-account-select-drop-box-wrapper .drop-select-box > *:last-child {
  border: none;
}
.global-account-select-drop-box-wrapper .drop-select-box .item-row-global {
  display: flex;
  border-width: 0.15rem;
  align-items: center;
  padding: 1.3rem;
  gap: 1rem;
  cursor: pointer;
}
.global-account-select-drop-box-wrapper .drop-select-box .item-row-global .img-select .img-box {
  width: 1.7rem;
  height: 1.7rem;
}
.global-account-select-drop-box-wrapper .drop-select-box .item-row-global .img-select .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}/*# sourceMappingURL=GlobalSelectDropBox.css.map */