.swap-global-account-index-wrap-box {
  flex: 1;
  // background-color: red;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  align-items: center;

  .select-form-wrap-box {
    width: 40rem;

    @media only screen and (max-width: 48em) {
      width: 100%;
    }
  }

  .centerered-div-box {
    width: 40rem;
    // min-height: 35rem;
    // background-color: red;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;

    @media only screen and (max-width: 48em) {
      width: 95%;
    }

    .two-amount-global-box {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      position: relative;
      z-index: 2;
      .amount-grey-wrap-box {
        padding: 1.5rem 2rem;
        border-radius: 1.2rem;

        // .global-amount-input-box{
        //     // back
        // }
      }

      .swap-global-icon-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        display: grid;
        place-items: center;
        z-index: 3;

        // .tooltip-hover-wrap{
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        // }

        .img-box {
          width: 50%;
          height: 50%;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .swap-fee-exchange-rate-box {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      //   width: max-content;
      //   margin-left: auto;
      padding-left: 5rem;

      & > * {
        position: relative;
        z-index: 3;
      }

      &::after {
        content: "";
        position: absolute;
        width: 0.15rem;
        height: 97%;
        left: 3.5rem;
        top: 0%;
        background-color: rgba(227, 227, 227, 1);
        z-index: 0;
      }

      .label-value-dot-box {
        display: flex;
        align-items: center;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 1.5rem;
          height: 1.5rem;
          left: 0%;
          top: 50%;
          transform: translateY(-50%) translateX(-150%);
          background-color: rgba(227, 227, 227, 1);
          z-index: 2;
          border-radius: 50%;
        }

        &::before {
          content: "";
          position: absolute;
          width: 0.8rem;
          height: 0.8rem;
          left: 0%;
          top: 50%;
          transform: translateY(-50%) translateX(-238%);
          // background-color: #ffffff;
          z-index: 3;
          border-radius: 50%;
        }

        .text {
          display: flex;
          align-items: center;
          font-size: 1.55rem;

          .value {
            font-weight: 500;
          }
        }
      }
    }
  }
}
