.raven-sign-modal-wrap-box {
  // background-color: red;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 1.5rem;

  .sign-title {
    font-weight: 600;
    font-size: 1.8rem;
  }

  .badge-sign-panel-content-box {
    width: 100%;
    height: 100%;
    border-radius: 1.2rem;
    border-width: 0.15rem;
    padding-bottom: 1.5rem;

    .sign-panel-content-box {
      width: 100%;
      height: 100%;
      height: 25rem;
      position: relative;

      .sigCanvas {
        width: 100%;
        height: 100%;
      }
    }

    .badge-sign {
      padding-left: 1.5rem;
      margin-top: 1rem;
    }
  }

  .btn-box-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
    margin-top: 1rem;

    .btn-outline {
      border-width: 0.2rem;
    }
  }
}
