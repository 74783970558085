.settings-modal-wrap .generate-modal-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  min-height: 35rem;
}
.settings-modal-wrap .generate-modal-wrap .select-type-wrap {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.8s;
  -moz-transition: max-height 0.8s;
  width: 100%;
}
.settings-modal-wrap .generate-modal-wrap .select-type-wrap-show {
  overflow-x: visible;
  overflow-y: visible;
  max-height: 30rem;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .btn-text-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .btn-text-box .update {
  font-weight: 700;
  color: #755ae2;
  cursor: pointer;
  font-size: 1.6rem;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .btn-text-box .text-box {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  margin-bottom: 1rem;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .btn-text-box .text-box .content-title {
  font-size: 1.7rem;
  font-weight: 700;
  padding: 0rem;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .content-title {
  font-size: 1.7rem;
  font-weight: 700;
  padding: 0rem 1.6rem;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-quick-action-wrap {
  padding: 2.5rem 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-quick-action-wrap .child {
  padding: 1.2rem 2rem;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  width: -moz-max-content;
  width: max-content;
  box-shadow: 0px 3.56px 7.12px -1.78px rgba(51, 51, 51, 0.1), 0px 1.78px 3.56px -1.78px rgba(51, 51, 51, 0.06);
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-quick-action-wrap .child .check-box {
  width: 1.4rem;
  height: 1.4rem;
  display: grid;
  place-items: center;
  background-color: #ffffff;
  border-radius: 50%;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-quick-action-wrap .child .check-box .icon {
  width: 0.65rem;
  height: 0.65rem;
  fill: #755ae2;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-quick-action-wrap .child span {
  font-size: 1.25rem;
  font-weight: 700;
  display: inline-block;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-quick-action-wrap .child-active {
  background-color: #755ae2 !important;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-quick-action-wrap .child-active span {
  color: #ffffff !important;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-wrapper {
  display: flex;
  gap: 3rem;
}
@media only screen and (max-width: 42em) {
  .settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0rem 1.5rem;
  }
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-wrapper .box .img-wrap {
  min-height: 15rem;
  min-width: 20rem;
  background-position: center;
  background-size: contain;
  background-image: url("../../../assets/light-theme-example-img.png");
  background-repeat: no-repeat;
  position: relative;
  border-radius: 0.8rem;
}
@media only screen and (max-width: 42em) {
  .settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-wrapper .box .img-wrap {
    min-width: unset;
  }
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-wrapper .box .img-wrap::before {
  border-color: #755ae2 !important;
  border-width: 0.2rem;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-wrapper .box .img-wrap .check-box {
  background-color: #755ae2 !important;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-wrapper .box .img-wrap .check-box .icon {
  color: #ffffff !important;
  fill: #ffffff !important;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-wrapper .box .text {
  font-weight: 700;
  margin-top: 1rem;
  font-size: 1.35rem;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-wrapper .box .minimal {
  background-image: url("../../../assets/sidenav-light-example-img.png") !important;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-wrapper .box .solid {
  background-image: url("../../../assets/sidenav-dark-example-img.png") !important;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-wrapper .dark-img {
  background-image: url("../../../assets/dark-theme-example-img.png") !important;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .select-wrapper .light-img {
  background-image: url("../../../assets/light-theme-example-img.png") !important;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .table-select-font-wrap {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .table-select-font-wrap .child {
  padding: 1.2rem 2rem;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  width: -moz-max-content;
  width: max-content;
  box-shadow: 0px 3.56px 7.12px -1.78px rgba(51, 51, 51, 0.1), 0px 1.78px 3.56px -1.78px rgba(51, 51, 51, 0.06);
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .table-select-font-wrap .child .check-box {
  width: 1.4rem;
  height: 1.4rem;
  display: grid;
  place-items: center;
  background-color: #ffffff;
  border-radius: 50%;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .table-select-font-wrap .child .check-box .icon {
  width: 0.65rem;
  height: 0.65rem;
  fill: #755ae2;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .table-select-font-wrap .child span {
  font-size: 1.25rem;
  font-weight: 700;
  display: inline-block;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .table-select-font-wrap .child-active {
  background-color: #755ae2 !important;
}
.settings-modal-wrap .generate-modal-wrap .theme-preference-wrap .table-select-font-wrap .child-active span {
  color: #ffffff !important;
}
.settings-modal-wrap .generate-modal-wrap .title {
  font-size: 1.7rem;
  font-weight: 800;
}
.settings-modal-wrap .generate-modal-wrap .text {
  font-size: 1.3rem;
}
.settings-modal-wrap .generate-modal-wrap .select-wrap {
  margin-top: 2rem;
}
.settings-modal-wrap .generate-modal-wrap .input-type-wrap {
  margin-top: 2rem;
}
.settings-modal-wrap .generate-modal-wrap .input-type-wrap .select-type-wrap {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.8s;
  -moz-transition: max-height 0.8s;
  width: 100%;
}
.settings-modal-wrap .generate-modal-wrap .input-type-wrap .select-type-wrap-show {
  overflow-x: visible;
  overflow-y: visible;
  max-height: 30rem;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
}
.settings-modal-wrap .generate-modal-wrap .input-type-wrap .custom-type-wrap {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.8s;
  -moz-transition: max-height 0.8s;
  width: 100%;
}
.settings-modal-wrap .generate-modal-wrap .input-type-wrap .custom-type-wrap-show {
  overflow-x: visible;
  overflow-y: visible;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: 30rem;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
}
.settings-modal-wrap .generate-modal-wrap .input-type-wrap-two {
  min-height: unset;
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap {
  max-height: 40vh;
  margin-top: 1.5rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: scroll;
  padding-bottom: 4rem;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box::-webkit-scrollbar:horizontal {
  display: none;
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box::-webkit-scrollbar {
  width: 0.6rem;
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box::-webkit-scrollbar-thumb:hover {
  background: #020202;
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box .child-wrap {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  border: 0.1rem solid #cccccc;
  border-radius: 1.5rem;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box .child-wrap:hover {
  transform: translateY(-0.1rem);
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box .child-wrap > * {
  position: relative;
  z-index: 3;
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box .child-wrap::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  border: 0.15rem solid #333333;
  border-radius: 1.5rem;
  visibility: hidden;
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box .child-wrap .check-box-show {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  padding: 1rem 1.5rem;
  border-radius: 1.5rem;
  border-top-left-radius: unset;
  border-bottom-right-radius: unset;
  background-color: #333333;
  color: #ffffff;
  display: grid;
  place-items: center;
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box .child-wrap .check-box-show .icon {
  color: currentColor;
  width: 1rem;
  height: 1rem;
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box .child-wrap .text-box {
  font-size: 1.45rem;
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box .child-wrap .text-box .label {
  color: #676767;
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box .child-wrap .text-box .value {
  color: #020202;
  font-weight: 800;
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box .child-wrap .img-wrap {
  margin-right: 1.5rem;
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box .child-wrap .img-wrap .img-box {
  width: 4.5rem;
  height: 4.5rem;
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box .child-wrap-active:hover {
  transform: unset;
}
.settings-modal-wrap .generate-modal-wrap .electricity-child-wrap .child-wrap-box .child-wrap-active::after {
  visibility: visible;
}
.settings-modal-wrap .generate-modal-wrap .customer-invoice-child-wrap {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 3rem;
}
.settings-modal-wrap .generate-modal-wrap .customer-invoice-child-wrap::-webkit-scrollbar:horizontal {
  display: none;
}
.settings-modal-wrap .generate-modal-wrap .customer-invoice-child-wrap .customer-item {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  border-radius: 1.2rem;
  border-width: 0.15rem;
  cursor: pointer;
}
.settings-modal-wrap .generate-modal-wrap .customer-invoice-child-wrap .customer-item .detail-box {
  display: flex;
  flex-direction: column;
}
.settings-modal-wrap .generate-modal-wrap .customer-invoice-child-wrap .customer-item .detail-box .name {
  font-size: 1.45rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.settings-modal-wrap .generate-modal-wrap .customer-invoice-child-wrap .customer-item .detail-box .name .num-box {
  padding: 0.6rem 1rem;
  border-radius: 1.2rem;
  display: grid;
  place-items: center;
}
.settings-modal-wrap .generate-modal-wrap .customer-invoice-child-wrap .customer-item .detail-box .name .num-box span {
  font-weight: 700;
  font-size: 1rem;
}
.settings-modal-wrap .generate-modal-wrap .customer-invoice-child-wrap .customer-item .detail-box .email-num {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
.settings-modal-wrap .generate-modal-wrap .customer-invoice-child-wrap .customer-item .detail-box .email-num p {
  font-size: 1.25rem !important;
}
.settings-modal-wrap .generate-modal-wrap .customer-invoice-child-wrap .customer-item .avatar-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin-right: 1.5rem;
  gap: 0.1rem;
}
.settings-modal-wrap .generate-modal-wrap .customer-invoice-child-wrap .customer-item .avatar-box span {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 1.3rem;
}/*# sourceMappingURL=SettingModal.css.map */