.global-account-single-page-index-wrap {
  flex: 1;
  // background-color: blue;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  .centered-wrap {
    width: 65rem;
    // background-color: red;
    flex: 1;
    margin: 3rem auto 0rem auto;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    @media only screen and (max-width: 48em) {
      width: 95%;
    }

    // .select-option-link-box

    .amount-swap-receive-send-wrap-box {
      display: flex;
      flex-direction: column;
      border-radius: 1.2rem;
      border-width: 0.15rem;
      padding: 2rem;
      gap: 4rem;

      .show-balance-currency-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .show-balance-box {
          display: flex;
          align-items: center;
          gap: 1rem;
          cursor: pointer;
          width: max-content;

          .text {
            font-size: 1.6rem;
          }

          .eye-icon {
            .img-box {
              width: 2.5rem;
              height: 2.5rem;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }

        .select-drop-box-wrapper {
          position: relative;

          .select-drop-box {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 1rem 1.5rem;
            border-radius: 5rem;

            .value {
              font-weight: 500;
            }

            .img-select {
              .img-box {
                width: 1.7rem;
                height: 1.7rem;

                .img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }

            .drop-icon {
              .img-box {
                width: 1.7rem;
                height: 1.7rem;

                .img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }
        }
      }

      .amount-receive-swap-send-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: 48em) {
          flex-wrap: wrap;
          gap: 1.5rem;
        }

        .receive-send-swap-box {
          display: flex;
          align-items: center;
          gap: 2rem;

          @media only screen and (max-width: 48em) {
            width: 100%;
            flex-wrap: wrap;
            // gap: 1rem;
            justify-content: space-between;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1.5rem;
          }

          .box {
            padding: 1rem 1.5rem;
            border-radius: 4rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            justify-content: center;
            background-color: rgba(238, 238, 238, 1);
            transition: transform 0.3s ease-in-out;

            &:hover {
              transform: scale(1.05);
            }

            .text {
              font-size: 1.35rem;
              font-weight: 500;
            }

            .icon-box {
              .img-box {
                width: 1.8rem;
                height: 1.8rem;

                .img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }

            .rotate {
              .img-box {
                transform: rotate(180deg);
              }
            }
          }
        }

        .amount-detail-box {
          display: flex;
          flex-direction: column;

          .label {
            font-size: 1.5rem;
          }

          .value {
            font-size: 4rem;
            font-weight: 700;
            letter-spacing: -0.2rem;
          }
        }
      }
    }

    .transaction-global-wrap-box {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      flex: 1;
      overflow-y: scroll;
      //   background-color: blue;
      position: relative;

      &::-webkit-scrollbar {
        display: none;
      }

      .title {
        font-size: 1.6rem;
        // margin-bottom: 1rem;
        height: max-content;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        // background-color: green;
      }

      .transaction-content-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-top: 1rem;

        .no-global-acc-box {
          flex: 1;
          display: grid;
          place-items: center;

          .center-div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .img-box-no-content {
              width: 17rem;
              height: 17rem;
              background-image: url("../../../assets/global-no-trx-img.png");
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
            }

            .text {
              margin-top: 1rem;
              font-size: 1.7rem;
              text-align: center;
            }
          }
        }

        .global-trans-table-box {
          flex: 1;
          // background-color: red;
          display: flex;
          flex-direction: column;
          padding-top: 2rem;
          padding-bottom: 3.5rem;

          gap: 2.5rem;

          & > *:not(:last-child) {
            padding-bottom: 2.5rem;
          }

          @media only screen and (min-width: 48em) {
             & > *:last-child {
            border: none !important;
          }
  
          }

       
          .item-row {
            display: flex;
            align-items: center;
            border-width: 0.15rem;

            @media only screen and (max-width: 48em) {
              flex-direction: column;
              // align-items: stretch;
              gap: 1rem;
              align-items: flex-start;
              padding: 1.5rem 1rem;
              border-radius: 1.2rem;
            }

            .img-wrap {
              // flex: 0 0 5%;
              display: grid;
              place-items: center;

              .img-box-cre-deb {
                width: 3rem;
                height: 3rem;
                background-color: #1ace37 !important;
                border-radius: 50%;
                display: grid;
                place-items: center;
                flex: 0 0 5%;

                .img {
                  width: 35%;
                  height: 35%;
                  object-fit: contain;
                  filter: brightness(1000%);
                }
              }

              .img-box-debit {
                background-color: #ff0f00 !important;
              }
            }

            .label-value-box {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;

              .value {
                font-size: 1.55rem;
                font-weight: 600;
              }

              .label {
                font-size: 1.35rem;
              }
            }

            .label-value-box-right {
              margin-left: auto;

              .value,
              .label {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}
