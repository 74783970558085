.verification-five-status-resuable-box-wrap {
  width: 100%;
  min-height: 45rem;
  // background-color: red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .img-big-box {
    margin-bottom: 2rem;
    .img-box {
      width: 15rem;
      height: 15rem;
      display: grid;
      place-items: center;
      border-radius: 50%;
      overflow: hidden;

      .img {
        width: 75%;
        height: 75%;
        object-fit: contain;
      }
    }

    .img-box-bottom {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      .img{
        transform: translateY(1rem);
    }
    }
  }

  .title {
    text-align: center;
    font-size: 2.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .text {
    font-size: 1.45rem;
    text-align: center;
    line-height: 2.1rem;
    // background-color: red;
  }
}
