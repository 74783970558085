.reusable-icon-drop-fragment-business-wrapper-box {
  position: relative;
  z-index: 100;

  .icon-click-drop-box {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    display: grid;
    place-items: center;
    cursor: pointer;

    .img-box {
      width: 60%;
      height: 60%;

      .img,
      svg {
        object-fit: contain;
        width: 100%;
        height: 100%;
        // background-color: red;
      }
    }
  }

  .drop-list-box-wrap {
    min-width: 25rem;
    position: absolute;
    top: 110%;
    right: 0%;
    border-radius: 1.2rem;
    animation: moveDownSettingDrop 0.5s ease-in-out backwards;
    // padding: 2rem;
    display: flex;
    flex-direction: column;
    border-width: 0.15rem !important;

    .list-item {
      padding: 1rem 2rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      cursor: pointer;

      .text{
        font-size: 1.35rem;
      }

      .icon-box {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        display: grid;
        place-items: center;

        .img-box {
          width: 55%;
          height: 55%;

          .img,
          svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
    // visibility: hidden;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
    // visibility: ;
  }
}
