.send-money-global-account-index-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}
@media only screen and (max-width: 48em) {
  .send-money-global-account-index-box {
    min-height: 45rem;
  }
}
.send-money-global-account-index-box .select-form-wrap-box {
  display: flex;
  flex-direction: column;
  width: 40rem;
  align-self: center;
  position: relative;
}
@media only screen and (max-width: 48em) {
  .send-money-global-account-index-box .select-form-wrap-box {
    width: 90%;
  }
}
.send-money-global-account-index-box .select-form-wrap-box .select-option-global-wrap-box {
  align-self: center;
  display: flex;
  align-items: center;
  padding: 0.8rem 1.6rem;
  border-radius: 4rem;
  grid-gap: 2rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.send-money-global-account-index-box .select-form-wrap-box .select-option-global-wrap-box .select-item {
  position: relative;
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  border-radius: 4rem;
}
.send-money-global-account-index-box .select-form-wrap-box .select-option-global-wrap-box .select-item > * {
  position: relative;
  z-index: 2;
}
.send-money-global-account-index-box .select-form-wrap-box .select-option-global-wrap-box .select-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: #ffffff;
  border-radius: 4rem;
  z-index: 1;
}
.send-money-global-account-index-box .select-form-wrap-box .select-option-global-wrap-box .select-item-active::after {
  visibility: visible;
}
.send-money-global-account-index-box .select-form-wrap-box .select-option-global-wrap-box .select-item-active .text {
  font-weight: 600;
  color: #020202;
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 4rem;
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-add-receipient-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-add-receipient-box .add {
  color: rgb(117, 90, 226);
  cursor: pointer;
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-global-recipient {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: 35rem;
  overflow-y: scroll;
  margin-top: 1rem;
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-global-recipient::-webkit-scrollbar {
  display: none;
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-global-recipient .select-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 0.8rem;
  border-width: 0.15rem !important;
  cursor: pointer;
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-global-recipient .select-item .name-num-box {
  margin-left: 1.5rem;
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-global-recipient .select-item .name-num-box .name {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.35rem;
  line-height: 1.75rem;
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-global-recipient .select-item .name-num-box .name .status-type-wrap {
  background-color: #fff6ed;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #ea872d;
  margin-left: 2rem;
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-global-recipient .select-item .name-num-box .name .status-type-wrap span {
  font-size: 1.2rem;
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-global-recipient .select-item .name-num-box .number {
  font-size: 1.2rem;
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-global-recipient .select-item .check-wrap {
  margin-left: auto;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-global-recipient .select-item .check-wrap::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  height: 40%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-global-recipient .select-item .check-wrap-active {
  background-color: rgb(117, 90, 226);
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-global-recipient .select-item .img-avatar-wrap {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgb(238, 238, 238);
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-global-recipient .select-item .img-avatar-wrap .img-box {
  width: 100%;
  height: 100%;
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-global-recipient .select-item .img-avatar-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-global-recipient .select-item .img-avatar-wrap .avatar-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-global-recipient .select-item .img-avatar-wrap .avatar-box span {
  font-weight: 700;
  font-size: 1.2rem;
  display: inline-block;
}
.send-money-global-account-index-box .select-form-wrap-box .form-box-wrap .select-global-recipient .select-item .img-avatar-wrap .avatar-box-two {
  background-color: unset;
}
.send-money-global-account-index-box .select-form-wrap-box .two-amount-global-box {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
  z-index: 2;
}
.send-money-global-account-index-box .select-form-wrap-box .two-amount-global-box .amount-grey-wrap-box {
  padding: 1.5rem 2rem;
  border-radius: 1.2rem;
}
.send-money-global-account-index-box .select-form-wrap-box .two-amount-global-box .swap-global-icon-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  z-index: 3;
}
.send-money-global-account-index-box .select-form-wrap-box .two-amount-global-box .swap-global-icon-box .img-box {
  width: 50%;
  height: 50%;
}
.send-money-global-account-index-box .select-form-wrap-box .two-amount-global-box .swap-global-icon-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-global-account-index-box .select-form-wrap-box .swap-fee-exchange-rate-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-left: 5rem;
}
.send-money-global-account-index-box .select-form-wrap-box .swap-fee-exchange-rate-box > * {
  position: relative;
  z-index: 3;
}
.send-money-global-account-index-box .select-form-wrap-box .swap-fee-exchange-rate-box::after {
  content: "";
  position: absolute;
  width: 0.15rem;
  height: 97%;
  left: 3.5rem;
  top: 0%;
  background-color: rgb(227, 227, 227);
  z-index: 0;
}
.send-money-global-account-index-box .select-form-wrap-box .swap-fee-exchange-rate-box .label-value-dot-box {
  display: flex;
  align-items: center;
  position: relative;
}
.send-money-global-account-index-box .select-form-wrap-box .swap-fee-exchange-rate-box .label-value-dot-box::after {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  left: 0%;
  top: 50%;
  transform: translateY(-50%) translateX(-150%);
  background-color: rgb(227, 227, 227);
  z-index: 2;
  border-radius: 50%;
}
.send-money-global-account-index-box .select-form-wrap-box .swap-fee-exchange-rate-box .label-value-dot-box::before {
  content: "";
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  left: 0%;
  top: 50%;
  transform: translateY(-50%) translateX(-238%);
  z-index: 3;
  border-radius: 50%;
}
.send-money-global-account-index-box .select-form-wrap-box .swap-fee-exchange-rate-box .label-value-dot-box .text {
  display: flex;
  align-items: center;
  font-size: 1.55rem;
}
.send-money-global-account-index-box .select-form-wrap-box .swap-fee-exchange-rate-box .label-value-dot-box .text .value {
  font-weight: 500;
}
.send-money-global-account-index-box .select-form-wrap-box-two {
  width: 45rem;
}
@media only screen and (max-width: 48em) {
  .send-money-global-account-index-box .select-form-wrap-box-two {
    width: 90%;
  }
}
.send-money-global-account-index-box .content-box-two {
  width: 100%;
  padding: 2.5rem;
  border-width: 0.15rem;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: stretch;
  border-radius: 1.2rem;
}
@media only screen and (max-width: 48em) {
  .send-money-global-account-index-box .content-box-two {
    padding: 1.5rem 1rem;
  }
}
.send-money-global-account-index-box .content-box-two .add-money-item {
  align-self: stretch;
  border-width: 0.15rem;
  padding-bottom: 1.5rem;
}
.send-money-global-account-index-box .content-box-two .add-money-item .top-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.send-money-global-account-index-box .content-box-two .add-money-item .top-box .title-text-box .title {
  font-size: 1.45rem;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
.send-money-global-account-index-box .content-box-two .add-money-item .top-box .title-text-box .title .title-total {
  padding: 0.4rem 1rem;
  border-radius: 1rem;
  display: grid;
  place-items: center;
}
.send-money-global-account-index-box .content-box-two .add-money-item .top-box .title-text-box .title .title-total span {
  display: inline-block;
  color: #ffffff !important;
  font-size: 1.1rem;
}
@media only screen and (max-width: 42em) {
  .send-money-global-account-index-box .content-box-two .add-money-item .top-box .title-text-box .title {
    font-size: 1.35rem;
  }
}
.send-money-global-account-index-box .content-box-two .add-money-item .top-box .title-text-box .text {
  margin-top: 0.3rem;
  color: #676767;
  font-size: 1.25rem;
}
@media only screen and (max-width: 42em) {
  .send-money-global-account-index-box .content-box-two .add-money-item .top-box .title-text-box .text {
    font-size: 1.15rem;
    width: 80%;
  }
}
.send-money-global-account-index-box .content-box-two .add-money-item .top-box .img-wrap {
  margin-right: 1.5rem;
}
.send-money-global-account-index-box .content-box-two .add-money-item .top-box .img-wrap .img-box {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.send-money-global-account-index-box .content-box-two .add-money-item .top-box .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-global-account-index-box .content-box-two .add-money-item .top-box .arrow-coming-soon-box {
  margin-left: auto;
  position: relative;
}
.send-money-global-account-index-box .content-box-two .add-money-item .top-box .arrow-coming-soon-box .img-box-arrow {
  width: 2.5rem;
  height: 2.5rem;
  transform: rotate(-90deg);
  transition: all 0.3s ease-in-out;
}
.send-money-global-account-index-box .content-box-two .add-money-item .top-box .arrow-coming-soon-box .img-box-arrow .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-global-account-index-box .content-box-two .add-money-item .top-box .arrow-coming-soon-box .img-box-arrow-rotate {
  transform: unset;
}
.send-money-global-account-index-box .content-box-two .add-money-item .top-box .arrow-coming-soon-box .coming-soon {
  position: absolute;
  top: -3rem;
  right: 0;
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  width: 100%;
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box .display-wrap {
  padding: 2rem;
  display: flex;
  align-items: center;
  border-radius: 1.2rem;
}
@media only screen and (max-width: 48em) {
  .send-money-global-account-index-box .content-box-two .add-money-item .content-display-box .display-wrap {
    flex-direction: column;
    align-items: flex-start;
  }
  .send-money-global-account-index-box .content-box-two .add-money-item .content-display-box .display-wrap .copy-box {
    margin-left: unset !important;
    margin-top: 1.5rem;
  }
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box .display-wrap .copy-box {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.7rem 2rem;
  border-radius: 3rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box .display-wrap .copy-box:hover {
  transform: scale(1.01);
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box .display-wrap .copy-box .img-box {
  width: 1.5rem;
  height: 1.5rem;
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box .display-wrap .copy-box .img-box .img {
  width: 100%;
  height: 100%;
  color: rgb(117, 90, 226);
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box .display-wrap .copy-box .img-box .icon {
  width: 1.3rem;
  height: 1.3rem;
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box .display-wrap .copy-box span {
  color: rgb(117, 90, 226);
  font-weight: 500;
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box .display-wrap .info-box {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box .display-wrap .info-box .bank-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box .display-wrap .info-box .acc_num {
  font-weight: 600;
  font-size: 1.7rem;
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box .select-option-link-box {
  display: flex;
  align-items: center;
  gap: 3rem;
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box .select-option-link-box .radio-label-item {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box .select-option-link-box .radio-label-item .label {
  cursor: pointer;
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box .form-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3rem;
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box .form-box .edit-amount-check-wrap {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-top: -1rem;
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box .form-box .edit-amount-check-wrap .text {
  font-size: 1.3rem;
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box-show {
  max-height: 15rem;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding-top: 1.5rem;
}
.send-money-global-account-index-box .content-box-two .add-money-item .content-display-box-show-two {
  max-height: 65rem;
  margin-bottom: 10rem;
}
.send-money-global-account-index-box .wrap-coming-soon {
  opacity: 0.5;
  cursor: default;
}
@media only screen and (max-width: 42em) {
  .send-money-global-account-index-box .wrap-coming-soon {
    flex-direction: column;
    padding: 1rem;
  }
}
.send-money-global-account-index-box .wrap-coming-soon .add-money-item .top-box {
  cursor: not-allowed;
}
@media only screen and (max-width: 42em) {
  .send-money-global-account-index-box .wrap-coming-soon .add-money-item .top-box {
    flex-direction: column;
    align-items: flex-start;
  }
  .send-money-global-account-index-box .wrap-coming-soon .add-money-item .top-box > *:last-child {
    margin-left: unset;
    align-self: flex-end;
    margin-top: 1rem;
  }
}
.send-money-global-account-index-box .wrap-coming-soon .add-money-item .top-box .text {
  margin-top: 0.3rem;
  color: #676767;
  font-size: 1.25rem;
}
@media only screen and (max-width: 42em) {
  .send-money-global-account-index-box .wrap-coming-soon .add-money-item .top-box .text {
    font-size: 1.15rem;
    width: 100%;
  }
}/*# sourceMappingURL=SendMoneyGlobalAccount.css.map */