.referee-invite-public-wrap-index-box {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/invite-ref-bg-img.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.referee-invite-public-wrap-index-box .header-nav-box {
  flex: 0 0 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.referee-invite-public-wrap-index-box .header-nav-box .img-wrap .img-box {
  width: 12rem;
  height: 3rem;
}
.referee-invite-public-wrap-index-box .header-nav-box .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.referee-invite-public-wrap-index-box .footer-wrap-box {
  flex: 0 0 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.referee-invite-public-wrap-index-box .footer-wrap-box .text-wrap {
  display: flex;
  align-items: center;
}
.referee-invite-public-wrap-index-box .footer-wrap-box .text-wrap .text {
  font-size: 1.5rem;
}
.referee-invite-public-wrap-index-box .footer-wrap-box .text-wrap .text span {
  font-weight: 600;
  border-bottom: 0.15rem solid currentColor;
  cursor: pointer;
}
.referee-invite-public-wrap-index-box .middle-content-wrap {
  flex: 1;
  overflow-y: scroll;
  display: grid;
  place-items: center;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box {
  width: 65rem;
  min-height: 45rem;
}
@media only screen and (max-width: 48em) {
  .referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box {
    width: 95%;
  }
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .success-failed-main-index-content-wrap {
  border-radius: 1.2rem;
  border-width: 0.15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem;
}
@media only screen and (max-width: 48em) {
  .referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .success-failed-main-index-content-wrap {
    padding: 4rem 3rem;
  }
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .success-failed-main-index-content-wrap .img-round-box {
  margin-bottom: 3rem;
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  background-color: rgb(255, 246, 237);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .success-failed-main-index-content-wrap .img-round-box .img-box {
  width: 80%;
  height: 80%;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .success-failed-main-index-content-wrap .img-round-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .success-failed-main-index-content-wrap .img-round-box-failed {
  background-color: rgb(250, 250, 255);
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .success-failed-main-index-content-wrap .title {
  font-weight: 700;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .success-failed-main-index-content-wrap .text {
  font-size: 1.65rem;
  text-align: center;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .instruction-content-index-wrap {
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 1.5rem;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .instruction-content-index-wrap .top-box {
  flex: 0 0 30%;
  background-color: rgb(255, 246, 237);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 2rem 1rem;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .instruction-content-index-wrap .top-box .text {
  font-size: 1.5rem;
  text-align: center;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .instruction-content-index-wrap .top-box .text span {
  font-weight: 600;
  border-bottom: 0.15rem solid currentColor;
  cursor: pointer;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .instruction-content-index-wrap .top-box .img-wrap-content {
  width: 100%;
  height: 20rem;
  background-image: url("../../assets/referre-invitetop-bg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: -3rem;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .instruction-content-index-wrap .bottom-content {
  padding: 1.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .instruction-content-index-wrap .bottom-content .btn-instruc-box {
  align-self: flex-end;
  margin: 1rem 0rem;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .instruction-content-index-wrap .bottom-content .bottom-title {
  font-weight: 600;
  font-size: 1.6rem;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .instruction-content-index-wrap .bottom-content .list-instruc-box-wrap {
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .instruction-content-index-wrap .bottom-content .list-instruc-box-wrap .item-row {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .instruction-content-index-wrap .bottom-content .list-instruc-box-wrap .item-row .dot-box .dot {
  width: 1rem;
  height: 1rem;
  border-radius: 0.2rem;
  background-color: rgb(234, 135, 45);
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .instruction-content-index-wrap .bottom-content .list-instruc-box-wrap .item-row .text {
  font-size: 1.35rem;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .referee-invite-form-index-wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .referee-invite-form-index-wrap .top-title-back-box .title-box {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 500;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .referee-invite-form-index-wrap .side-nav-form-box {
  min-height: 40rem;
  border-width: 0.15rem;
  border-radius: 1.2rem;
  padding: 2rem;
  display: flex;
  gap: 1.5rem;
}
@media only screen and (max-width: 48em) {
  .referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .referee-invite-form-index-wrap .side-nav-form-box {
    flex-direction: column;
    gap: 3rem;
  }
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .referee-invite-form-index-wrap .side-nav-form-box .menu-nav-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
@media only screen and (max-width: 48em) {
  .referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .referee-invite-form-index-wrap .side-nav-form-box .menu-nav-box {
    flex-direction: row;
  }
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .referee-invite-form-index-wrap .side-nav-form-box .menu-nav-box .nav-item {
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
  position: relative;
  padding: 1.5rem 1.8rem;
  border-radius: 1.2rem;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .referee-invite-form-index-wrap .side-nav-form-box .menu-nav-box .nav-item .name {
  font-size: 1.35rem;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .referee-invite-form-index-wrap .side-nav-form-box .menu-nav-box .nav-item-active {
  cursor: not-allowed;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .referee-invite-form-index-wrap .side-nav-form-box .menu-nav-box .nav-item-active .name {
  font-weight: 600;
}
.referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .referee-invite-form-index-wrap .side-nav-form-box .form-content {
  flex: 1;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  padding-top: unset;
  gap: 2.5rem;
}
@media only screen and (max-width: 48em) {
  .referee-invite-public-wrap-index-box .middle-content-wrap .middle-content-box .referee-invite-form-index-wrap .side-nav-form-box .form-content {
    padding: 0rem 0rem 3rem 0rem;
  }
}
.referee-invite-public-wrap-index-box .middle-content-wrap::-webkit-scrollbar {
  display: none;
}/*# sourceMappingURL=RefereeInvitePublic.css.map */