.global-account-single-page-index-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.global-account-single-page-index-wrap .centered-wrap {
  width: 65rem;
  flex: 1;
  margin: 3rem auto 0rem auto;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
@media only screen and (max-width: 48em) {
  .global-account-single-page-index-wrap .centered-wrap {
    width: 95%;
  }
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box {
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  border-width: 0.15rem;
  padding: 2rem;
  gap: 4rem;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .show-balance-currency-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .show-balance-currency-box .show-balance-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  width: -moz-max-content;
  width: max-content;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .show-balance-currency-box .show-balance-box .text {
  font-size: 1.6rem;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .show-balance-currency-box .show-balance-box .eye-icon .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .show-balance-currency-box .show-balance-box .eye-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .show-balance-currency-box .select-drop-box-wrapper {
  position: relative;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .show-balance-currency-box .select-drop-box-wrapper .select-drop-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
  border-radius: 5rem;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .show-balance-currency-box .select-drop-box-wrapper .select-drop-box .value {
  font-weight: 500;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .show-balance-currency-box .select-drop-box-wrapper .select-drop-box .img-select .img-box {
  width: 1.7rem;
  height: 1.7rem;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .show-balance-currency-box .select-drop-box-wrapper .select-drop-box .img-select .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .show-balance-currency-box .select-drop-box-wrapper .select-drop-box .drop-icon .img-box {
  width: 1.7rem;
  height: 1.7rem;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .show-balance-currency-box .select-drop-box-wrapper .select-drop-box .drop-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .amount-receive-swap-send-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 48em) {
  .global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .amount-receive-swap-send-box {
    flex-wrap: wrap;
    gap: 1.5rem;
  }
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .amount-receive-swap-send-box .receive-send-swap-box {
  display: flex;
  align-items: center;
  gap: 2rem;
}
@media only screen and (max-width: 48em) {
  .global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .amount-receive-swap-send-box .receive-send-swap-box {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .amount-receive-swap-send-box .receive-send-swap-box .box {
  padding: 1rem 1.5rem;
  border-radius: 4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  background-color: rgb(238, 238, 238);
  transition: transform 0.3s ease-in-out;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .amount-receive-swap-send-box .receive-send-swap-box .box:hover {
  transform: scale(1.05);
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .amount-receive-swap-send-box .receive-send-swap-box .box .text {
  font-size: 1.35rem;
  font-weight: 500;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .amount-receive-swap-send-box .receive-send-swap-box .box .icon-box .img-box {
  width: 1.8rem;
  height: 1.8rem;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .amount-receive-swap-send-box .receive-send-swap-box .box .icon-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .amount-receive-swap-send-box .receive-send-swap-box .box .rotate .img-box {
  transform: rotate(180deg);
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .amount-receive-swap-send-box .amount-detail-box {
  display: flex;
  flex-direction: column;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .amount-receive-swap-send-box .amount-detail-box .label {
  font-size: 1.5rem;
}
.global-account-single-page-index-wrap .centered-wrap .amount-swap-receive-send-wrap-box .amount-receive-swap-send-box .amount-detail-box .value {
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: -0.2rem;
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  flex: 1;
  overflow-y: scroll;
  position: relative;
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box::-webkit-scrollbar {
  display: none;
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .title {
  font-size: 1.6rem;
  height: -moz-max-content;
  height: max-content;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .no-global-acc-box {
  flex: 1;
  display: grid;
  place-items: center;
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .no-global-acc-box .center-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .no-global-acc-box .center-div .img-box-no-content {
  width: 17rem;
  height: 17rem;
  background-image: url("../../../assets/global-no-trx-img.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .no-global-acc-box .center-div .text {
  margin-top: 1rem;
  font-size: 1.7rem;
  text-align: center;
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .global-trans-table-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 3.5rem;
  gap: 2.5rem;
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .global-trans-table-box > *:not(:last-child) {
  padding-bottom: 2.5rem;
}
@media only screen and (min-width: 48em) {
  .global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .global-trans-table-box > *:last-child {
    border: none !important;
  }
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .global-trans-table-box .item-row {
  display: flex;
  align-items: center;
  border-width: 0.15rem;
}
@media only screen and (max-width: 48em) {
  .global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .global-trans-table-box .item-row {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    padding: 1.5rem 1rem;
    border-radius: 1.2rem;
  }
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .global-trans-table-box .item-row .img-wrap {
  display: grid;
  place-items: center;
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .global-trans-table-box .item-row .img-wrap .img-box-cre-deb {
  width: 3rem;
  height: 3rem;
  background-color: #1ace37 !important;
  border-radius: 50%;
  display: grid;
  place-items: center;
  flex: 0 0 5%;
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .global-trans-table-box .item-row .img-wrap .img-box-cre-deb .img {
  width: 35%;
  height: 35%;
  -o-object-fit: contain;
     object-fit: contain;
  filter: brightness(1000%);
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .global-trans-table-box .item-row .img-wrap .img-box-debit {
  background-color: #ff0f00 !important;
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .global-trans-table-box .item-row .label-value-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .global-trans-table-box .item-row .label-value-box .value {
  font-size: 1.55rem;
  font-weight: 600;
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .global-trans-table-box .item-row .label-value-box .label {
  font-size: 1.35rem;
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .global-trans-table-box .item-row .label-value-box-right {
  margin-left: auto;
}
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .global-trans-table-box .item-row .label-value-box-right .value,
.global-account-single-page-index-wrap .centered-wrap .transaction-global-wrap-box .transaction-content-box .global-trans-table-box .item-row .label-value-box-right .label {
  text-align: right;
}/*# sourceMappingURL=GlobalAccountSingleIndex.css.map */