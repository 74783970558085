.dashboard-schedule-wrapper-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  margin-top: 3rem;
  padding-bottom: 10rem;
}
.dashboard-schedule-wrapper-box .schedule-action-wrap {
  margin: 0rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 1rem;
  width: 100%;
  padding-bottom: 2rem;
}
.dashboard-schedule-wrapper-box .schedule-action-wrap .img-box {
  width: 3.5rem;
  height: 3.5rem;
  background-color: green;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: transform 0.3s ease-in-out;
  transform: translateY(1rem);
}
.dashboard-schedule-wrapper-box .schedule-action-wrap .img-box:hover {
  transform: translateY(1rem) scale(1.05);
}
.dashboard-schedule-wrapper-box .schedule-action-wrap .img-box .img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
.dashboard-schedule-wrapper-box .schedule-action-wrap > *:nth-child(2) .img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}

.dashboard-schedule-wrapper-box .title-sort-text-box {
  display: flex;
  align-items: center;
  padding: 0rem 3rem;
}

.dashboard-schedule-wrapper-box .title-sort-text-box .search-group {
  margin-right: auto;
}

.dashboard-schedule-wrapper-box .title-sort-text-box .title-text {
  margin-right: auto;
}

.dashboard-schedule-wrapper-box .title-sort-text-box .title-text .text {
  font-size: 1.25rem;
}

.dashboard-schedule-wrapper-box .title-sort-text-box .title-text .title {
  font-weight: 700;
  font-size: 1.5rem;
}

.dashboard-schedule-wrapper-box .title-sort-text-box .fitler-box {
  display: grid;
  grid-template-columns: 1fr 1fr max-content;
  grid-gap: 2rem;
  align-items: center;
}

.dashboard-schedule-wrapper-box .title-sort-text-box .fitler-box .reset-text {
  color: var(--primary-purple);
  font-weight: 600;
  cursor: pointer;
}

.dashboard-schedule-wrapper-box .title-sort-text-box .fitler-box .react-select-class > *:nth-child(3) {
  min-width: 25rem;
}

.dashboard-schedule-wrapper-box .title-sort-text-box .fitler-box .react-select-class > *:nth-child(4) {
  left: unset;
  right: 0;
}

@media only screen and (max-width: 48em) {
  .dashboard-schedule-wrapper-box .table-wrapper .table {
    display: none;
  }
}
@media only screen and (max-width: 48em) {
  .dashboard-schedule-wrapper-box .table-wrapper {
    width: 90%;
    margin: 2rem auto 5rem auto;
  }
}
.dashboard-schedule-wrapper-box .table-wrapper .status-type-wrap {
  background-color: #e9f5ff;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #476885;
  text-transform: capitalize;
}
.dashboard-schedule-wrapper-box .table-wrapper .status-type-wrap span {
  font-size: 1.2rem;
}
.dashboard-schedule-wrapper-box .table-wrapper .status-type-wrap-fail {
  background-color: #fff5f5;
  color: #ff0f00;
}
.dashboard-schedule-wrapper-box .table-wrapper .status-type-wrap-success {
  background-color: #e8fff6;
  color: #1ace37;
}
.dashboard-schedule-wrapper-box .table-wrapper .status-type-wrap-pending {
  background-color: #fff6ed;
  color: #ea872d;
}
.dashboard-schedule-wrapper-box .table-wrapper .status-type-wrap-disabled {
  background-color: #f7f8f7;
}

.dashboard-schedule-wrapper-box .table-wrapper .type-wrap {
  padding: 0.8rem 1.2rem;
  background-color: #f7f8f7;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
}

.dashboard-schedule-wrapper-box .table-wrapper .type-wrap span {
  font-size: 1.2rem;
  font-weight: 400 !important;
}

.dashboard-schedule-wrapper-box .table-wrapper .narration-wrap {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.dashboard-schedule-wrapper-box .table-wrapper .narration-wrap .name {
  font-size: 1.55rem;
}

.dashboard-schedule-wrapper-box .table-wrapper .narration-wrap .info {
  font-size: 1.3rem;
  font-weight: 400;
}

.dashboard-schedule-wrapper-box .table-wrapper .table-pagination-box {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  padding: 0rem 3rem;
}

.dashboard-schedule-wrapper-box .table-wrapper thead tr > *:first-child {
  padding-left: 3rem;
}

.dashboard-schedule-wrapper-box .table-wrapper thead tr > *:last-child {
  text-align: center;
  padding-right: 3rem;
}

.dashboard-schedule-wrapper-box .table-wrapper .table-body tr {
  cursor: pointer;
}

.dashboard-schedule-wrapper-box .table-wrapper .table-body tr > *:first-child {
  padding-left: 3rem;
}

.dashboard-schedule-wrapper-box .table-wrapper .table-body tr > *:last-child {
  padding-right: 3rem;
}

.dashboard-schedule-wrapper-box .table-wrapper .table-body tr .action-box {
  justify-content: center;
} /*# sourceMappingURL=DashboardSchedulePayment.css.map */