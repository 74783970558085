.auth-layout-one-wrap {
  width: 100vw;
  height: 100vh;
  // background-color: blue;
  display: grid;
  overflow: hidden;
  grid-template-rows: min-content 1fr min-content;

  @media only screen and (max-width: 42em) {
    height: unset;
    min-height: 100vh;
    //  padding-bottom: 2rem;
  }

  .size-wrap {
    display: flex;
    width: 90%;
    margin: 0rem auto;

  }

  .header-wrap {
    grid-row: 1 / span 1;
    // background-color: red;
    box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.06),
      0px 0px 2px rgba(51, 51, 51, 0.02);
    // padding-bottom: 5rem;
    .size-wrap {
      padding: 0.5rem 0rem;
      align-items: center;
      //   background-color: green;

      @media only screen and (max-width: 42em) {
        flex-direction: column;
      }

      .security-wrap {
        background-color: #f9f9f9;
        border-radius: 1.2rem;
        position: relative;
        padding: 2.5rem 1.5rem 1rem 1.5rem;

        .text {
          color: #676767;
          width: 100%;

          span {
            color: #755ae2;
            cursor: pointer;
            font-weight: 700;
          }
        }

        .security-box {
          position: absolute;
          top: 0;
          left: 4rem;
          transform: translateY(-50%);

          .text {
            font-size: 1.2rem;
            margin-left: 1rem;
            color: #676767;
          }
        }
      }

      .security-wrap-mobile {
        margin-top: 2.5rem;
        margin-bottom: 2rem;
        @media only screen and (min-width: 42em) {
          align-self: flex-end;
          //  width: 100%;
          display: none;
        }
      }

      .security-tip-wrap {

        @media only screen and (min-width: 42em) {
          align-self: flex-end;
          //  width: 100%;
          display: none;
        }

        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.9rem 1.5rem;
        background-color: rgba(255, 246, 237, 1);
        border-radius: 0.8rem;
        margin-bottom: 1.5rem;
  
        // @media only screen and (max-width: 48em){
        //   width: max-content;
        // }
  
        .text {
          // white-space: nowrap;
          font-size: 1.25rem;
          color: rgba(27, 27, 27, 1);
  
          @media only screen and (max-width: 48em) {
            white-space: unset;
          }
  
          span {
            font-weight: 600;
            color: rgba(234, 135, 45, 1);
          }
        }
  
        .img-wrap {
          .img-box {
            width: 2rem;
            height: 2rem;
  
            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }

      .logo-wrap {
        margin-right: auto;

        .img-box {
          width: 19rem;
          height: 4.8rem;
          margin: 2rem 0rem;
          // display: none;

          .img {
            width: 100%;
            height: 100%;
            object-fit: cntain;
            display: inline-block;
            transform: translateX(-15%);
          }
        }
      }

    

      .step-wrap {
        // background-color: red;

        @media only screen and (max-width: 42em) {
          align-self: flex-end;
          //  width: 100%;
          display: none;
        }
      }
    }
  }

  .content-wrap {
    grid-row: 2 / span 1;
    // background-color: green;
    padding: 3rem 0rem;
    display: grid;
    place-items: center;

    @media only screen and (max-width: 42em) {
      align-items: flex-start;
    }

    .size-wrap {
      // background-color: red;
      height: calc(100vh - 24rem);
      display: flex;


  

      @media only screen and (min-width: 110em) {
        width: 150rem;
      }

      @media only screen and (max-width: 42em) {
        height: unset;
      }

      .mobile-security-tip {
        display: none;

        @media only screen and (min-width: 48em) {
          display: none;
        }
        width: 100%;
        margin-top: 5rem;
        // pos

        .security-wrap {
          background-color: #f9f9f9;
          border-radius: 1.2rem;
          position: relative;
          padding: 2.5rem 1.5rem 1rem 1.5rem;

          .text {
            color: #676767;
            width: 100%;

            span {
              color: #755ae2;
              cursor: pointer;
              font-weight: 700;
            }
          }

          .security-box {
            position: absolute;
            top: 0;
            left: 4rem;
            transform: translateY(-50%);

            .text {
              font-size: 1.2rem;
              margin-left: 1rem;
              color: #676767;
            }
          }
        }
      }

      .left-box {
        flex: 0 0 50%;
        // background-color: blue;
        overflow-y: scroll;
        border-right: 0.05rem solid #cccccc;
        display: grid;
        place-items: center;

        @media only screen and (min-width: 110em) {
         border-right: none;
        }

        @media only screen and (max-width: 48em) {
          flex: unset;
          width: 100%;
          border-right: unset;
        }

        // display: flex;
        // align-items: center;
        &::-webkit-scrollbar:horizontal {
          display: none;
        }

        /* width */
        &::-webkit-scrollbar {
          width: 0.3rem;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: none !important;
          background-color: transparent !important;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #020202;
          border-radius: 30rem;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #020202;
        }

        .wrap {
          width: 45rem;
          height: 100%;
          //   background-color: red;

          @media only screen and (max-width: 67em) {
            width: 80%;
          }

          @media only screen and (max-width: 54em) {
            width: 100%;
          }
        }
      }

      .right-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 4rem;
        gap: 1.5rem;
        // justify-content: center;

        @media only screen and (max-width: 48em) {
          display: none;
        }

        .security-tip-wrap {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.9rem 1.5rem;
          background-color: rgba(255, 246, 237, 1);
          border-radius: 0.8rem;
          margin-bottom: 1.5rem;
    
          // @media only screen and (max-width: 48em){
          //   width: max-content;
          // }
    
          .text {
            // white-space: nowrap;
            font-size: 1.25rem;
            color: rgba(27, 27, 27, 1);
    
            @media only screen and (max-width: 48em) {
              white-space: unset;
            }
    
            span {
              font-weight: 600;
              color: rgba(234, 135, 45, 1);
            }
          }
    
          .img-wrap {
            .img-box {
              width: 2rem;
              height: 2rem;
    
              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }

        .security-wrap {
          background-color: #f9f9f9;
          border-radius: 1.2rem;
          position: relative;
          padding: 2.5rem 1.5rem 1rem 1.5rem;
          // width: 90%;
          // align-self: center;
          // margin-left: 3rem;

          .text {
            color: #676767;
            width: 100%;

            span {
              color: #755ae2;
              cursor: pointer;
              font-weight: 700;
            }
          }

          .security-box {
            position: absolute;
            top: 0;
            left: 4rem;
            transform: translateY(-50%);

            .text {
              font-size: 1.2rem;
              margin-left: 1rem;
              color: #676767;
            }
          }
        }

        .text {
          color: #676767;
          width: 90%;
          // margin-left: 4rem;

          // background-color: green;

          span {
            color: #755ae2;
            cursor: pointer;
            font-weight: 600;
          }
        }

        .video-background {
          flex: 1;
          position: relative;
          height: 100%;
          width: 100%;
          // margin-top: -3rem;
          overflow: hidden;
          z-index: -1;
          // background-color: red;
          // margin-bottom: 4rem;

          .video-background .video {
            position: absolute;
            max-width: 100%;
            height: auto;
            top: 50%;
            left: 50%;
            width: auto;
            height: auto;
            min-width: 100%;
            min-height: 100%;
            background-size: cover;
            transform: translate(-50%, -50%);
            z-index: -1;
            // display: none !important;
          }
        }

        // .img-content {
        //   flex: 1;
        //   // background-color: red;
        //   background-image: url("../../assets/raven-onboarding.gif");
        //   background-size: contain;    
        //   background-repeat: no-repeat;
        //   background-position: center;
        //   margin-top: 3rem;
        // }
      }
    }
  }

  .footer-wrap {
    grid-row: 3 / -1;
    // background-color: blue;
    padding-bottom: 2rem;

    .size-wrap {
      justify-content: flex-end;

      .img-box {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        //   background-color: red;

        .img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: contain;
          transform: scale(2);
          transition: all 0.3s;
          cursor: pointer;

          &:hover {
            transform: scale(2) translateY(-0.1rem);
          }
        }
      }
    }
  }
}

.auth-two-layout-wrap {
  width: 100vw;
  height: 100vh;
  // background-color: blue;

  display: grid;
  overflow: hidden;
  grid-template-rows: min-content 1fr min-content;

  .action-bar-wrapper-box{
    &>*:first-child{
      padding-left: 8rem;
    padding-right: 3rem;
    }
  }

  .size-wrap {
    display: flex;
    width: 90%;
    margin: 0rem auto;

    .security-wrap-big {
      @media only screen and (max-width: 42em) {
        // background-color: red;
        display: none;
      }
    }

    .security-wrap-two {
      @media only screen and (min-width: 42em) {
        // background-color: red;
        display: none;
      }
    }
  }

  .header-wrap {
    grid-row: 1 / span 1;
    border-right: unset;
    border-left: unset;
    border-top: unset;
    box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.06),
      0px 0px 2px rgba(51, 51, 51, 0.02);
    // background-color: red;

    @media only screen and (max-width: 42em) {
      background-color: unset;
    }

    .size-wrap {
      padding: 1.2rem 0rem;
      align-items: center;

      .logo-wrap {
        margin-right: auto;

        @media only screen and (max-width: 42em) {
          // background-color: red;
          display: none;
        }

        .img-box {
          width: 15rem;
          height: 3.5rem;

          @media only screen and (max-width: 42em) {
            width: 15rem;
            height: 3.2rem;
            margin: 2rem 0rem;
          }

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: inline-block;
            transform: translateX(-15%);
          }
        }
      }

      .logo-wrap-two {
        @media only screen and (min-width: 42em) {
          // background-color: red;
          display: none;
        }

        @media only screen and (max-width: 42em) {
          // background-color: red;
          display: inline-block;
        }

        .img-box {
          width: 17rem;
          height: 4rem;
          margin: 0rem 0rem 2rem 0rem;
        }
      }
    }
  }

  .content-wrap {
    grid-row: 2 / span 1;
    // background-color: green;
    padding: 3rem 0rem;
    display: grid;
    place-items: center;

    @media only screen and (max-width: 42em) {
      align-items: flex-start;
    }

    .size-wrap {
        // background-color: red;
      height: calc(100vh - 22rem);
      display: flex;
      width: 55rem;
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 42em) {
        width: 90%;
      }

      .content {
        flex: 1;
        overflow-y: scroll;
        margin-bottom: 3rem;
        display: flex;
        justify-content: center;
        &::-webkit-scrollbar:horizontal {
          display: none;
        }

        /* width */
        &::-webkit-scrollbar {
          width: 0.3rem;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: none !important;
          background-color: transparent !important;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #020202;
          border-radius: 30rem;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #020202;
        }

        .form-wrap {
          //   height: 100rem;
          // width: 75%;
          //   background-color: red;

          @media only screen and (max-width: 42em) {
            width: 100%;
          }
        }
      }

      .security-wrap {
        background-color: #f9f9f9;
        border-radius: 1.2rem;
        position: relative;
        padding: 2.5rem 1.5rem 1rem 1.5rem;

        .text {
          color: #676767;
          width: 100%;

          span {
            color: #755ae2;
            cursor: pointer;
            font-weight: 700;
          }
        }

        .security-box {
          position: absolute;
          top: 0;
          left: 4rem;
          transform: translateY(-50%);

          .text {
            font-size: 1.2rem;
            margin-left: 1rem;
            color: #676767;
          }
        }
      }
    }
  }

  .footer-wrap {
    grid-row: 3 / -1;
    // background-color: blue;
    padding-bottom: 2rem;

    .size-wrap {
      justify-content: flex-end;

      .img-box {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        //   background-color: red;

        .img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: contain;
          transform: scale(2);
          transition: all 0.3s;
          cursor: pointer;

          &:hover {
            transform: scale(2) translateY(-0.1rem);
          }
        }
      }
    }
  }
}

.modal-parent-wrap .raven-modal-content-wrap {
  @media only screen and (max-width: 48em) {
    width: 90vw !important;
  }
}
