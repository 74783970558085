.reusable-collapse-fragment-box-index-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .title-drop-icon-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .icon-box {
      margin-top: 0.3rem;
      .img-box {
        width: var(--icon-size);
        height: var(--icon-size);

        .img , svg{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .title-collapse {
      font-size: var(--title-size);
      color: var(--title-color);
    }
  }

  .collapse-outer-box {
    // background-color: red;
    max-height: 0;
    // overflow-x: hidden;
    overflow-y: hidden;
    transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    width: 100%;
    display: flex;
    flex-direction: column;

    .collapse-inner-box {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
    }
  }
}

.reusable-collapse-fragment-box-index-wrapper-open {
  .title-drop-icon-box {
    .icon-box {
      transform: rotate(180deg);
    }
  }

  .collapse-outer-box {
    max-height: var(--collapse-height);
    transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  }
}

// max-height: 0;
// // overflow-x: hidden;
// overflow-y: hidden;
// transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
// -webkit-transition: max-height 0.3s;
// -moz-transition: max-height 0.3s;
// width: 100%;

// max-height: 30rem;
// transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
