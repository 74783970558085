// @import "../breakpoints";
.dashboard-layout-wrap {
  width: 100vw;
  height: 100vh;
  // background-color: red;
  position: relative;

  @media only screen and (min-width: 110em) {
    width: 100% !important;
  }

  .mobile-blur-bg {
    @media only screen and (min-width: 56em) {
      display: none;
    }

    position: fixed;
    z-index: 8;
    width: 100vw;
    height: 100vh;
    // filter: blur(50px);

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background: #020202;
      opacity: 0.4;
    }
  }

  .chat-box {
    position: fixed !important;
    bottom: 3rem;
    right: 4rem;
    z-index: 7;
    // display: none;

    .img-box {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      // background-color: red;

      .img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: scale(2);
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
          transform: scale(2) translateY(-0.1rem);
        }
      }
    }
  }

  .sidebar-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 25rem;
    // background-color: transparent;
    height: 100vh;
    overflow-y: auto;
    display: flex;

    @media only screen and (max-width: 65em) {
      position: absolute;
      top: 0;
      left: 0;
      // transform: translateX(-100%);
      width: 7rem;
      overflow-x: hidden;
      z-index: 4;
      padding-top: 0.5rem;
    }

    @media only screen and (max-width: 56em) {
      width: 27.5rem;
      transform: translateX(-100%);
      height: 100vh;
      position: fixed;
      transition: all 0.4s ease-in-out;
      z-index: 10;
      padding: 0rem;
    }

    @media only screen and (max-width: 56em) {
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (max-width: 42em) {
      overflow-y: scroll;
      display: unset;
      // flex-direction: column;
      // background-color: red;
      overflow-x: hidden;

      & > * {
       height: 100vh;
       padding-bottom: 4rem; 
       overflow-x: hidden;
       overflow-y: scroll;
      }
    }

    &:hover::-webkit-scrollbar {
      display: block;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none !important;
      background-color: transparent !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #cccccc;
      border-radius: 30rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb {
      background: #cccccc;
    }
  }

  @media only screen and (max-width: 56em) {
   .sidebar-wrap-show {
      transform: translateX(0%);
    } 
  }

  .main-body-wrap {
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100% - 25rem);
    height: 100vh;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 110em) {
      width: calc(100% - 25rem) !important;
      // display: none;
      // left: 25rem;
      right: 0;
      // background-color: green;
    }

    @media only screen and (max-width: 65em) {
      width: calc(100% - 7rem);
    }

    @media only screen and (max-width: 56em) {
      width: 100%;
      height: unset;
    }

    .action-bar-wrapper-box {
      .action-bar-set-pin-info {
        padding-left: 5rem;
        padding-right: 5rem;
        align-items: center;

        .img-box {
          transform: translateY(-0.05rem);
        }
      }
    }

    .header-wrap {
      //   flex: 0 0 7%;
      //   background-color: #ffffff;
      // border-bottom: 0.1rem solid solid #f7f8f7;
      position: relative;
      z-index: 3;
      padding: 1rem 5rem;

      @media only screen and (min-width: 110em) {
        padding: 1rem 7rem;
      }

      @media only screen and (max-width: 42em) {
        padding: 1rem 2.5rem;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
      }
    }

    .main-wrap {
      flex: 1;
      overflow-y: scroll;
      //   background-color: red;
      position: relative;
      z-index: 2;
      padding: 2.5rem 5rem;
      display: flex;
      flex-direction: column;

      @media only screen and (min-width: 110em) {
        width: calc(160rem - 25rem) !important;
        padding: 7rem;
      }

      @media only screen and (max-width: 58em) {
        flex: unset;
        // min-height: 100vh;
      }

      @media only screen and (max-width: 42em) {
        padding: 2.5rem;
        padding-top: 10rem;
        // overflow-y: unset;
        // height: 100%;
        height: max-content;
      }

      &::-webkit-scrollbar:horizontal {
        display: none;
      }

      /* width */
      &::-webkit-scrollbar {
        width: 0.5rem;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: none !important;
        background-color: transparent !important;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #020202;
        border-radius: 30rem;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #020202;
      }

      //   .child {
      //     height: 100%;
      //     width: 100%;
      //     background-color: red;
      //   }
    }
  }
}
