// @import "../../breakpoints";

.send-money-wrap-box {
  flex: 1;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 42em) {
    width: 100%;
    align-self: stretch;
    flex: unset;
    // background-color: red;
  }

  .send-money-wrap {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    width: 50rem;
    gap: 2rem;
    padding-bottom: 5rem;

    @media only screen and (max-width: 42em) {
      width: 100%;
      padding: 2rem 2rem;
      align-self: stretch;
    }

    .title {
      font-size: 1.6rem;
      // font-weight: 300;
    }
    .title-drop-box {
      display: flex;
      align-items: center;
      // background-color: red;
      gap: 1.5rem;
      position: relative;
      z-index: 10;
      width: max-content;

      @media only screen and (max-width: 48em) {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        
        .value-box {
          margin-left: auto;
        }
      }

      .title {
        font-size: 1.6rem;
        // color: red;
      }

      .select-toggle-box {
        position: absolute;
        top: 120%;
        right: 0;
      }

      .value-box {
        display: flex;
        align-items: center;
        gap: 0.7rem;
        background-color: rgba(240, 238, 255, 1);
        padding: 0.5rem 0.8rem;
        border-radius: 0.6rem;
        cursor: pointer;

        // &:hover .img-box{
        //   transform: translateY(.25rem);
        // }

        // &:hover .img-box-up{
        //   transform: translateY(-.25rem);
        // }

        .value {
          color: rgba(117, 90, 226, 1);
          font-weight: 600;
        }

        .img-box {
          display: grid;
          place-items: center;
          width: 2rem;
          height: 2rem;
          // transition: all .3s ease-in-out;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transform: translateY(0.2rem);
          }
        }

        .img-box-up {
          transform: translateY(0.25rem) rotateX(-180deg);
        }
      }
    }

    .select-box {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 2rem 0rem;
      border-radius: 1.6rem;
      border-width: 0.15rem;

      & > *:not(:last-child) {
        padding-bottom: 2rem !important;
      }

      & > *:last-child {
        border: unset;
        // padding-bottom: 2;
      }

      @media only screen and (max-width: 42em) {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        // align-items: flex-start;
        width: 100%;
        align-self: stretch;
      }

      .box {
        cursor: pointer;
        padding: 1.5rem 0rem;
        position: relative;
        // background-color: red;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 1.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border-radius: unset;

        // @media only screen and (max-width: 48em){
        //   width: 100% !important;
        //   background-color: red ;
        // }

        &:hover .right-arrow .icon {
          transform: translateX(0.4rem);
        }

        .text-box {
          .name {
            margin-top: unset;
            margin-bottom: 0.3rem;
          }
        }

        .img-wrap {
          margin-right: 1.3rem;
          .img-box {
            width: 4.2rem;
            height: 4.2rem;
            display: grid;
            place-items: center;
            transition: all 0.3s;
            border-radius: 50%;
            background-color: #f7f8f7 !important;
            // display: none;

            .img {
              object-fit: contain;
              width: 40%;
              height: 40%;
            }
          }
        }

        .right-arrow {
          // @media only screen and (min-width: 42em){
          //  display: none;
          // }
          margin-left: auto;

          .icon {
            width: 1.5rem;
            height: 1.5rem;
            transition: all 0.3s;
          }
        }

        @media only screen and (max-width: 42em) {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1rem;
          padding: 1.5rem 1rem;

          .img-box {
            @media only screen and (max-width: 38em) {
              margin-top: unset;

              width: 3.5rem;
              height: 3.5rem;
            }
          }

          .text {
            font-size: 1.2rem;
            width: 85%;
          }
        }

        .name {
          @media only screen and (max-width: 42em) {
            margin-top: unset;
            font-size: 1.45rem;
          }
        }
      }

      & > *:not(:first-child) {
        .img-wrap {
          .img-box {
            .img {
              width: 70%;
              height: 70%;
            }
          }
        }
      }

      // &>*:nth-child(2){
      //   .img-wrap{
      //     .img-box{
      //       .img{
      //         width: 65%;
      //         height: 65%;
      //       }
      //     }
      //   }
      // }
    }
  }
}

// raven user starrt

.send-money-wrap-box-raven {
  flex: 1;
  display: flex;
  flex-direction: column;
  //   background-color: red;

  .form {
    padding-top: 3rem;
    width: 40rem;
    flex-direction: column;
    display: flex;
    gap: 2rem;
    // background-color: red;
    padding-bottom: 7rem;

    @media only screen and (max-width: 42em) {
      width: 90%;
      align-self: center;
    }

    .recepient-box {
      max-height: 0;
      overflow-x: hidden;
      overflow-y: hidden;
      transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      width: 100%;

      .text-line {
        display: grid;
        grid-template-columns: 1fr min-content 1fr;
        align-items: center;
        width: 100%;

        .text {
          color: #676767;
          background-color: #f7f8f7;
          font-size: 1.1rem;
          border: 0.1rem solid #cccccc;
          padding: 0.5rem 1.1rem;
          border-radius: 10rem;
          font-weight: 600;
          text-transform: uppercase;
        }

        span {
          height: 0.1rem;
          background-color: rgba(204, 204, 204, 0.5);
        }
      }

      .view-total-box {
        display: flex;
        align-items: center;

        .view-wrap {
          margin-left: auto;
          position: relative;
          //   color: #ff0f00;

          .text {
            font-size: 1.3rem;
            // color: currentColor;
          }

          .cancel-box {
            margin-left: auto;
            width: 2.2rem;
            height: 2.2rem;
            border-radius: 50%;
            background-color: #ffdfdd;
            margin-left: auto;
            display: grid;
            place-items: center;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
              transform: scale(1.1);
            }

            .icon {
              fill: #ff0f00;
              width: 1.1rem;
              height: 1.1rem;
            }
          }

          .view-all-box {
            position: absolute;
            bottom: 105%;
            right: 0;
            width: 35rem;
            height: 30rem;
            padding: 2rem;
            z-index: 4;
            overflow-y: scroll;
            box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
              0px 48px 64px -36px rgba(51, 51, 51, 0.08);
            border-radius: 2rem;
            transform: scale(0);
            transition: all 0.3s ease-in-out;
            transform-origin: bottom right;
            border-top-right-radius: unset;
            border-bottom-right-radius: unset;

            .wrap {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 2rem;
              // padding-bottom: 5rem;

              .child-wrap {
                display: flex;
                align-items: center;
                padding: 1rem 1.3rem;
                // border: 0.1rem solid #f7f8f7;
                border-radius: 0.8rem;

                .text-box {
                  .name {
                    font-weight: 700;
                    font-size: 1.3rem;
                  }

                  .text {
                    font-size: 1.1rem;
                    font-weight: 400;
                  }
                }

                .img-box {
                  width: 3rem;
                  height: 3rem;
                  margin-right: 1rem;

                  .img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }

                .cancel-box {
                  margin-left: auto;
                  width: 2.2rem;
                  height: 2.2rem;
                  border-radius: 50%;
                  background-color: #ffdfdd;
                  margin-left: auto;
                  display: grid;
                  place-items: center;
                  cursor: pointer;
                  transition: all 0.3s;

                  &:hover {
                    transform: scale(1.1);
                  }

                  .icon {
                    fill: #ff0f00;
                    width: 1.1rem;
                    height: 1.1rem;
                  }
                }
              }
            }

            &::-webkit-scrollbar:horizontal {
              display: none;
            }

            /* width */
            &::-webkit-scrollbar {
              width: 0.3rem;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: none !important;
              background-color: transparent !important;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #020202;
              border-radius: 30rem;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #020202;
            }
          }

          .view-all-box-show {
            transform: scale(1);
          }

          .text {
            cursor: pointer;
            font-weight: 600;
            color: var(--primary-purple);
          }
        }
      }

      .preview-box {
        margin-top: 1rem;
        max-height: 15rem;
        padding: 1rem 0rem;
        // background-color: red;
        // margin-bottom: 10rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1.5rem;

        .box {
          // background-color: #f7f8f7;
          padding: 1rem;
          cursor: pointer;
          position: relative;

          .cancel-box {
            width: 2.2rem;
            height: 2.2rem;
            border-radius: 50%;
            background-color: #ffdfdd;
            margin-left: auto;
            display: grid;
            place-items: center;
            cursor: pointer;
            transition: all 0.3s;
            position: absolute;
            top: 1rem;
            right: 1rem;

            &:hover {
              transform: scale(1.1);
            }

            .icon {
              fill: #ff0f00;
              width: 1.1rem;
              height: 1.1rem;
            }
          }

          .name {
            margin-top: 2rem;
            font-size: 1.45rem;
          }
        }
      }
    }

    .recepient-box-show {
      overflow-x: unset;
      overflow-y: unset;
      max-height: 30rem;
      transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
    }

    .label-span-amount {
      font-weight: 600 !important;
      opacity: 1 !important;
    }

    .verify-name-box {
      display: flex;
      align-items: center;
      align-self: flex-start;
      margin-top: -1rem;
      max-height: 0;
      overflow-x: hidden;
      overflow-y: hidden;
      transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
      -webkit-transition: max-height 0.3s;
      -moz-transition: max-height 0.3s;
      width: 100%;

      & > *:first-child .label-check {
        border-radius: 50%;
        transform: scale(0.8);
      }
      .text {
        // color: var(--primary-black);
        font-weight: 600;
        font-size: 1.3rem;
        margin-left: 0.5rem;
      }
    }

    .verify-name-box-show {
      max-height: 5rem;
      transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
    }

    .add-another-box {
      display: flex;
      align-items: center;
      margin-top: -1rem;
      align-self: flex-start;
      cursor: default;
      opacity: 0.5;

      &:hover .img-box {
        transform: scale(1.2);
      }

      .text {
        color: var(--primary-purple);
        font-weight: 600;
        font-size: 1.3rem;
      }

      .img-box {
        transition: all 0.3s ease-in-out;
        width: 2rem;
        height: 2rem;
        display: grid;
        place-items: center;
        margin-right: 0.3rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .add-another-box-active {
      opacity: 1;
      cursor: pointer;
    }

    .label-span {
      font-weight: 700;
      opacity: 0.5;
      cursor: default;
    }

    .label-span-active {
      opacity: 1;
      cursor: pointer;
    }

    .bg-box {
      width: 100%;
      height: 10rem;
      background-color: #f7f8f7;
      border-radius: 1.6rem;
      background-image: url("../../../assets/raven-to-raven-bg-img.png");
      background-position: right;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

// @include xs {
//   .send-money-wrap-box-raven {
//     width: 100%;
//     padding: 0 2rem !important;

//     .form {
//       width: 100% !important;
//     }
//   }
// }

@keyframes moveUpAnimeSendMoney {
  0% {
    transform: translateY(-3%) translateX(-50%);
    opacity: 0.7;
  }

  100% {
    transform: translateY(0%) translateX(-50%);
    opacity: 1;
  }
}

.bulk-drop-wrap {
  position: relative;

  .right-arrow-rotate {
    transform: rotate(90deg);
  }

  .select-content-wrap {
    max-height: 0;
    // margin-left: 4.2rem;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    width: 100%;

    .wrap-content {
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      border-radius: 0.8rem;

      .title-select {
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
      }

      .select-item-box {
        display: flex;
        align-items: center;
        gap: 2rem;
        flex-wrap: wrap;

        .select-item {
          cursor: pointer;
          padding: 1rem 1.7rem;
          border-radius: 2rem;
          transition: transform 0.3s ease-in-out;

          &:hover {
            transform: scale(1.02);
          }
        }
      }
    }
  }

  .select-content-wrap-show {
    padding: 2rem;
    max-height: 30rem;
    transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  }

  .select-bulk-type-box {
    max-height: 0;
    // margin-left: 4.2rem;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    display: flex;
    flex-direction: column;
    transition: all 0.3s;
    // gap: 1.5rem;

    .second-drop-child-wrap {
      position: relative;

      .child-row-two {
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-20%);
        // width: 25rem;
        // background-color: #ff0f00;
        padding: 2rem;
        gap: 1.5rem;
        display: flex;
        flex-direction: column;
        border-radius: 1.2rem;

        & > *:first-child {
          padding-bottom: 1.5rem;
        }

        & > *:last-child {
          border: unset !important;
        }

        .child-row {
          margin-left: unset;
          // padding: 1.5rem !important;

          transform: unset !important;
        }

        .child-row-parent {
          padding: 1.5rem 0rem !important;
        }
      }
    }

    .child-row {
      display: flex;
      align-items: center;
      width: max-content;
      align-self: stretch;
      // background-color: red;
      margin-left: 5rem;
      // padding: 2rem 0rem !important;
      width: 100%;

      &:hover .img-box {
        transform: scale(1.03);
      }

      .title {
        font-weight: 700;
        // font-size: 1.4rem;
      }

      .img-box {
        width: 4rem;
        height: 4rem;
        display: grid;
        place-items: center;
        margin-right: 1rem;
        transition: all 0.3s;
        border-radius: 50%;
        background-color: #f7f8f7;
        // display: none;

        .img {
          object-fit: contain;
          width: 50%;
          height: 50%;
        }
      }
    }
  }

  .select-bulk-type-box-show {
    max-height: 30rem;
    overflow-x: visible;
    overflow-y: visible;
    transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
    & > *:not(:last-child) {
      // margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
      // border-bottom: 0.1rem solid #f7f8f7;
    }
  }
}

.send-money-select-bulk-type-modal-wrap {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-bottom: 2rem;

  .des-text {
    font-size: 1.5rem;
    line-height: 2rem;
    background-color: #fff6ed;
    display: flex;
    align-items: flex-start;
    padding: 2rem 1rem;
    border-radius: 1.2rem;

    span {
      font-size: 1.35rem;
    }
  }

  .select-box {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 1rem;
    padding-top: 2rem;
    margin-bottom: 2rem;

    .select-item {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      padding: 2rem 1.5rem;
      border-radius: 0.8rem;
      cursor: pointer;

      &:hover .icon {
        transform: translateX(0.3rem);
      }

      .text-box {
        margin-right: auto;

        .text {
          font-weight: 700;
          font-size: 1.55rem;
        }
      }

      .img-box {
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 50%;
        margin-right: 1.5rem;
        display: grid;
        place-items: center;

        .img {
          width: 65%;
          height: 65%;
          object-fit: contain;
        }
      }

      .icon {
        margin-left: auto;
        width: 2rem;
        height: 2rem;
        color: #755ae2;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .switch-box {
    display: flex;
    align-items: center;
    padding: 0.7rem 1.5rem;
    border-radius: 3rem;
    gap: 2rem;
    width: max-content;

    .switch-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: 3rem;
      gap: 0.8rem;
      cursor: pointer;
      padding: 1rem 1.5rem;
    }

    .switch-item-active {
      cursor: default;
      box-shadow: 0px 3.0687px 4.0916px rgba(51, 51, 51, 0.04),
        0px 0px 2.0458px rgba(51, 51, 51, 0.02);
      background-color: #755ae2;

      .text {
        color: #ffffff;
      }
    }
  }
}

.coming-soon-type-box {
  position: relative;
  // cursor: default !important;

  .box-card {
    opacity: 0.5;
  }

  .security-lock-wrap-box {
    position: absolute;
    top: 0%;
    // transform: translateY(-50%);
    right: 1.5rem;

    @media only screen and (max-width: 48em) {
      top: 0%;
      transform: unset;
    }
  }
}
