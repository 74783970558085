.step-box-wrap {
  display: grid;
  grid-gap: 1rem;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 40%;
    top: 1.7rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    height: .1rem ;
    background-color:rgba(47, 19, 67, .1);
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 3;

    .num {
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      background-color: rgba(189, 189, 189, 0.2);
      display: grid;
      place-items: center;
      cursor: pointer;

      span {
        color: rgba(22, 45, 76, .4);
        font-size: 1.2rem;
      }
    }

    .text {
        margin-top: .1rem;
      color: #bdbdbd;
      font-size: 1.2rem;
    }
  }

  .box-active {
    .num {
      background-color: #333333;

      span {
        color: #ffffff;
        font-weight: 600;
      }
    }

    .text {
        color: #020202;
    }
  }
}
