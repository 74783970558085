.verification-five-modal-create-index-wrap {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0rem;
  left: 0rem;
  z-index: 1000;
  background: rgba(2, 2, 2, 0.3);
}
@media only screen and (min-width: 110em) {
  .verification-five-modal-create-index-wrap {
    width: 100% !important;
  }
}
.verification-five-modal-create-index-wrap::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  z-index: 1;
}
.verification-five-modal-create-index-wrap > * {
  position: relative;
  z-index: 3;
}
.verification-five-modal-create-index-wrap .content-wrap {
  position: absolute;
  width: 100%;
  height: calc(100% - 6rem);
  bottom: 0rem;
  left: 0rem;
  border-top-right-radius: 3rem;
  border-top-left-radius: 3rem;
  display: flex;
  flex-direction: column;
  animation: moveUpCreateSettingDrop 0.5s ease-in-out backwards;
}
.verification-five-modal-create-index-wrap .content-wrap .top-wrap {
  border-top-right-radius: 3rem;
  border-top-left-radius: 3rem;
  position: relative;
  display: flex;
  align-items: center;
  padding: 2rem 5rem;
}
@media only screen and (max-width: 48em) {
  .verification-five-modal-create-index-wrap .content-wrap .top-wrap {
    padding: 2rem;
  }
}
.verification-five-modal-create-index-wrap .content-wrap .top-wrap .top-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.verification-five-modal-create-index-wrap .content-wrap .top-wrap .top-content .step-box {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.verification-five-modal-create-index-wrap .content-wrap .top-wrap .top-content .step-box span {
  display: inline-block;
  font-weight: 600;
  font-size: 1.6rem;
}
.verification-five-modal-create-index-wrap .content-wrap .top-wrap .top-content .close-wrap {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
}
.verification-five-modal-create-index-wrap .content-wrap .top-wrap .top-content .close-wrap .img-box {
  width: 2rem;
  height: 2rem;
}
.verification-five-modal-create-index-wrap .content-wrap .top-wrap .top-content .close-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.verification-five-modal-create-index-wrap .content-wrap .top-wrap .top-content .close-wrap .text {
  font-weight: 600;
  font-size: 1.55rem;
}
.verification-five-modal-create-index-wrap .content-wrap .top-wrap .bar-wrap {
  position: absolute;
  top: 100%;
  left: 0%;
  width: 100%;
  transform: translateY(100%);
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 3rem 0rem;
  overflow-y: scroll;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form {
  width: 40rem;
  margin: 0rem auto;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .select-option-global-wrap-box {
  align-self: center;
  display: flex;
  align-items: center;
  padding: 0.8rem 1.6rem;
  border-radius: 4rem;
  grid-gap: 2rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .select-option-global-wrap-box .select-item {
  position: relative;
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  border-radius: 4rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .select-option-global-wrap-box .select-item > * {
  position: relative;
  z-index: 2;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .select-option-global-wrap-box .select-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: #ffffff;
  border-radius: 4rem;
  z-index: 1;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .select-option-global-wrap-box .select-item-active::after {
  visibility: visible;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .select-option-global-wrap-box .select-item-active .text {
  font-weight: 600;
  color: #020202;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .title-back-text-box {
  margin-top: 2.5rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .title-back-text-box .back-box {
  display: flex;
  align-items: center;
  gap: 0.1rem;
  cursor: pointer;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .title-back-text-box .back-box .img-box {
  width: 1.7rem;
  height: 1.7rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .title-back-text-box .back-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .title-back-text-box .back-box .back {
  font-size: 1.5rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .title-back-text-box .title {
  font-size: 2.6rem;
  font-weight: 700;
  margin-bottom: -0.5rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .title-back-text-box .text {
  font-size: 1.5rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  height: -moz-max-content;
  height: max-content;
}
@media only screen and (max-width: 72em) {
  .verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box {
    flex: unset;
  }
  .verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .submit-btn-wrap {
    margin-top: 3rem;
  }
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box {
  min-height: 35rem;
  border-radius: 1.2rem;
  border-width: 0.15rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 2rem;
  overflow-y: scroll;
  padding-bottom: 7rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box::-webkit-scrollbar {
  display: none;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box > *:not(:last-child) {
  padding-bottom: 1.5rem;
  border-width: 0.15rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box > *:last-child {
  border: none !important;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box .preview-global-wrap-recipient-box {
  display: flex;
  flex-direction: column;
  border-width: 0.15rem;
  gap: 2.5rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box .preview-global-wrap-recipient-box .title-edit-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box .preview-global-wrap-recipient-box .title-edit-box .title {
  font-size: 1.55rem;
  font-weight: 700;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box .preview-global-wrap-recipient-box .title-edit-box .edit {
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
  border-width: 0.2rem !important;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box .preview-global-wrap-recipient-box .value-label-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box .preview-global-wrap-recipient-box .value-label-box .value,
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box .preview-global-wrap-recipient-box .value-label-box .label {
  font-size: 1.3rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .preview-detal-content-info-box .preview-global-wrap-recipient-box .value-label-box .value {
  text-align: right;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2rem;
  overflow-y: scroll;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .img-business-wrap {
  background-image: url("../../../assets/business-create-bg-img.png");
  height: 14rem;
  width: 100%;
  background-position: center;
  border-radius: 1.2rem;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 2rem;
  overflow: hidden;
  background-color: rgb(240, 238, 255);
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .success-create-recipient {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .success-create-recipient .img-success-box {
  width: 18rem;
  height: 18rem;
  background-image: url("../../../assets/recipient-global-success-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .success-create-recipient .success-title {
  text-align: center;
  font-size: 2.3rem;
  font-weight: 700;
  margin: 1rem 0rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .success-create-recipient .succes-text {
  text-align: center;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .small-title {
  font-weight: 600;
  color: rgb(172, 172, 172);
  margin-top: 1.5rem;
  text-transform: uppercase;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .big-title {
  font-size: 2.1rem;
  font-weight: 700;
  margin-bottom: -0.5rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .small-create-text {
  font-size: 1.5rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .detail-list-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .detail-list-box .detail-item {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .detail-list-box .detail-item .square span {
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  background-color: rgb(234, 135, 45);
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .form-content .two-group {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .submit-btn-wrap {
  width: 100%;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .content-wrap-box-form .form-box .submit-btn-wrap > * {
  width: 100%;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index {
  flex: 1;
  display: flex;
  padding-top: 3rem;
  position: relative;
}
@media only screen and (max-width: 48em) {
  .verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index {
    padding-top: unset;
    flex-direction: column;
    gap: 2rem;
  }
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .side-menu-box {
  flex: 0 0 25rem;
  display: flex;
  flex-direction: column;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  gap: 0.5rem;
  position: relative;
  z-index: 5;
}
@media only screen and (max-width: 48em) {
  .verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .side-menu-box {
    flex-direction: row;
    flex: unset;
    height: -moz-max-content;
    height: max-content;
    flex-wrap: wrap;
  }
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .side-menu-box .item-row {
  padding: 1.5rem;
  width: 100%;
  border-radius: 1.2rem;
  cursor: pointer;
  gap: 1rem;
}
@media only screen and (max-width: 48em) {
  .verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .side-menu-box .item-row {
    width: -moz-max-content;
    width: max-content;
  }
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .side-menu-box .item-row .name {
  white-space: nowrap;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .side-menu-box .item-row .tool-tip-wrap {
  font-weight: 400 !important;
  line-height: 1.9rem;
  text-align: justify;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .side-menu-box .item-row-active {
  cursor: default;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .side-menu-box .item-row-active .name {
  font-weight: 600;
  font-size: 1.35rem;
  white-space: nowrap;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box {
  flex: 1;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 3;
  top: 3rem;
  left: 0;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 48em) {
  .verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box {
    position: relative;
    top: unset;
    width: 100%;
  }
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div {
  width: 40rem;
  max-height: calc(100vh - 22rem);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
@media only screen and (max-width: 48em) {
  .verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div {
    padding-bottom: 5rem;
  }
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  flex: 1;
  overflow-y: scroll;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .download-pdf-wrap-box {
  flex: 1;
  width: 100%;
  margin-top: -1.5rem;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  overflow-y: scroll;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .download-pdf-wrap-box .download-pdf-wrap {
  width: 100%;
  flex: 1;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 3rem;
  overflow-y: scroll;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .download-pdf-wrap-box .download-pdf-wrap .file-view-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .download-pdf-wrap-box .download-pdf-wrap .file-view-box .view-box {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .download-pdf-wrap-box .download-pdf-wrap .file-view-box .view-box:hover {
  transform: scale(1.1);
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .download-pdf-wrap-box .download-pdf-wrap .file-view-box .view-box .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .download-pdf-wrap-box .download-pdf-wrap .file-view-box .view-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .download-pdf-wrap-box .download-pdf-wrap .file-view-box .file-text-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  width: -moz-max-content;
  width: max-content;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .download-pdf-wrap-box .download-pdf-wrap .file-view-box .file-text-box .text {
  font-size: 1.55rem;
  color: rgb(117, 90, 226);
  border-color: currentColor;
  border-width: 0.15rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .download-pdf-wrap-box .download-pdf-wrap .file-view-box .file-text-box .file-icon .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .download-pdf-wrap-box .download-pdf-wrap .file-view-box .file-text-box .file-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .download-pdf-wrap-box .download-pdf-wrap .read-text-box {
  flex: 1;
  overflow-y: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .download-pdf-wrap-box .download-pdf-wrap .read-text-box::-webkit-scrollbar {
  width: 0.5rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .download-pdf-wrap-box .download-pdf-wrap .read-text-box::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .download-pdf-wrap-box .download-pdf-wrap .read-text-box::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .download-pdf-wrap-box .download-pdf-wrap .read-text-box::-webkit-scrollbar-thumb {
  background: #020202;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .download-pdf-wrap-box .download-pdf-wrap .read-text-box .text {
  text-align: justify;
  font-size: 1.65rem;
  line-height: 2.5rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .two-btn-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-gap: 2rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .two-btn-box .btn {
  width: 100%;
  background-color: #ffffff;
  color: #020202;
  border-width: 0.2rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .two-btn-box .btn-upload {
  width: 100%;
  background-color: #ffffff;
  color: #020202;
  border-width: 0.2rem;
  height: 100%;
  border-radius: 0.8rem;
  cursor: pointer;
  display: grid;
  place-items: center;
  transition: transform 0.3s ease-in-out;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .two-btn-box .btn-upload:hover {
  transform: translateY(-0.15rem);
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .directors-list-box {
  display: flex;
  flex-direction: column;
  border-width: 0.15rem;
  border-radius: 1.2rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .directors-list-box > *:last-child {
  border: unset !important;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .directors-list-box .director-item-row {
  padding: 1.7rem 1.5rem;
  display: flex;
  align-items: center;
  border-width: 0.15rem;
  gap: 1rem;
  cursor: pointer;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .directors-list-box .director-item-row:hover .arrow-icon {
  transform: translateX(0.2rem);
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .directors-list-box .director-item-row .name {
  font-weight: 600;
  font-size: 1.5rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .directors-list-box .director-item-row .arrow-icon {
  transition: transform 0.3s ease-in-out;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .directors-list-box .director-item-row .arrow-icon .img-box {
  width: 1.5rem;
  height: 1.5rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .directors-list-box .director-item-row .arrow-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .directors-list-box .director-item-row .badge-box {
  margin-left: auto;
  transform: scale(0.9);
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .directors-list-box .director-item-row .avatar-box {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: rgb(117, 90, 226) !important;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .directors-list-box .director-item-row .avatar-box span {
  font-weight: 600;
  color: #ffffff;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .back-box {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.5rem;
  width: -moz-max-content;
  width: max-content;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .back-box:hover .img-back {
  transform: translateX(-0.2rem);
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .back-box .text {
  font-size: 1.5rem;
  font-weight: 500;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .back-box .img-back {
  transition: transform 0.3s ease-in-out;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .back-box .img-back .img-box {
  width: 2rem;
  height: 2rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .back-box .img-back .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .show-option-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .show-option-box .btn-wrap {
  margin-top: 5rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .show-option-box .text-line {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  width: 100%;
  margin: 2.5rem 0rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .show-option-box .text-line .text {
  color: #676767;
  background-color: #f7f8f7;
  font-size: 1.1rem;
  border: 0.1rem solid #cccccc;
  padding: 0.5rem 1.1rem;
  border-radius: 10rem;
  font-weight: 600;
  width: -moz-max-content;
  width: max-content;
  text-align: center;
  text-transform: uppercase;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .show-option-box .text-line span {
  height: 0.1rem;
  background-color: rgba(204, 204, 204, 0.5);
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .show-option-box .select-wrap {
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  border-width: 0.15rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .show-option-box .select-wrap .box {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  border-width: 0.15rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .show-option-box .select-wrap .box .name-des-box {
  display: flex;
  flex-direction: column;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .show-option-box .select-wrap .box .name-des-box .name {
  font-weight: 600;
  font-size: 1.55rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .show-option-box .select-wrap .box .name-des-box .des {
  font-size: 1.25rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .show-option-box .select-wrap .box .check-wrap {
  margin-left: auto;
  border: 0.2rem solid rgb(238, 238, 238);
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .show-option-box .select-wrap .box .check-wrap::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  height: 40%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .show-option-box .select-wrap .box .check-wrap-active {
  background-color: rgb(117, 90, 226);
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .two-input-group {
  grid-gap: 2rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media only screen and (max-width: 48em) {
  .verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .two-input-group {
    grid-template-columns: 1fr;
  }
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .profile-address-state-box {
  display: flex;
  flex-direction: column;
  border-width: 0.15rem;
  border-radius: 1.2rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .profile-address-state-box .value-label-box {
  border-width: 0.15rem;
  display: flex;
  align-items: center;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .profile-address-state-box .value-label-box > * {
  padding: 1.5rem;
  font-size: 1.25rem;
  font-weight: 600;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .profile-address-state-box .value-label-box .label {
  flex: 0 0 30%;
  align-self: stretch;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .profile-address-state-box .value-label-box .value {
  flex: 1;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .cac-badge-box {
  margin-top: 1.5rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .cac-badge-box span {
  font-weight: 600;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .cac-badge-box .tool-tip-wrap {
  font-weight: 400 !important;
  line-height: 1.9rem;
  text-align: justify;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .change-edit-state-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .change-edit-state-box .text {
  font-size: 1.45rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .change-edit-state-box .icon-box .img-box {
  width: 2rem;
  height: 2rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .change-edit-state-box .icon-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .form-title-text {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  gap: 0.3rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .form-title-text .title {
  font-weight: 600;
  font-size: 2.2rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .form-title-text .text {
  font-size: 1.3rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .back-box-title-box {
  display: flex;
  flex-direction: column;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .back-box-title-box .back-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .back-box-title-box .back-box:hover .icon {
  animation: moveBackFort 0.3s ease-in-out;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .back-box-title-box .back-box .icon {
  width: 1.45rem;
  height: 1.45rem;
  margin-right: 0.1rem;
  fill: rgba(80, 95, 109, 0.32);
  transform: scale(1.1);
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .back-box-title-box .back-box .text {
  font-size: 1.6rem;
  text-transform: capitalize;
  font-weight: 600;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .back-box-title-box .form-title-text {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  gap: 0.3rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .back-box-title-box .form-title-text .title {
  font-weight: 600;
  font-size: 2.2rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div .form-wrap .back-box-title-box .form-title-text .text {
  font-size: 1.3rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div-two {
  width: 46rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div-two > * > * {
  padding-left: 3rem;
  padding-right: 3rem;
}
.verification-five-modal-create-index-wrap .content-wrap .bottom-wrap .new-verification-layout-wrap-index .content-right-box .centered-div-no-gap .form-wrap {
  gap: 2rem;
}
.verification-five-modal-create-index-wrap .content-wrap-no-animation {
  animation: unset;
}

@keyframes moveUpCreateSettingDrop {
  0% {
    transform: translateY(5%);
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}/*# sourceMappingURL=VerificationFiveModal.css.map */