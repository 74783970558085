.role-and-permission-single-wrap {
  flex: 1;
  display: flex;
}
@media only screen and (max-width: 48em) {
  .role-and-permission-single-wrap {
    flex-direction: column;
    gap: 3rem;
    flex: unset;
    width: 100%;
  }
}
.role-and-permission-single-wrap > * {
  padding: 5rem;
  padding-bottom: 2rem;
}
@media only screen and (max-width: 48em) {
  .role-and-permission-single-wrap > * {
    padding: 2rem;
  }
}
.role-and-permission-single-wrap .left-container-wrap {
  flex: 0 0 45%;
  display: flex;
  flex-direction: column;
  border-width: 0.2rem;
  overflow-y: scroll;
}
@media only screen and (max-width: 48em) {
  .role-and-permission-single-wrap .left-container-wrap {
    flex: unset;
    width: 100%;
    border: unset !important;
  }
}
.role-and-permission-single-wrap .left-container-wrap::-webkit-scrollbar {
  display: none;
}
.role-and-permission-single-wrap .left-container-wrap .section-title {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 2rem;
}
.role-and-permission-single-wrap .left-container-wrap .text-des-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;
}
.role-and-permission-single-wrap .left-container-wrap .text-des-box .text {
  font-size: 1.25rem;
  opacity: 0.8;
}
.role-and-permission-single-wrap .left-container-wrap .text-des-box .des {
  font-size: 1.45rem;
  width: 80%;
  line-height: 2rem;
}
.role-and-permission-single-wrap .left-container-wrap .sub-text {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}
.role-and-permission-single-wrap .left-container-wrap .team-mate-box {
  overflow-y: scroll;
  max-height: calc(100vh - 25rem);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 2rem 0rem;
}
.role-and-permission-single-wrap .left-container-wrap .team-mate-box .team-item {
  display: flex;
  align-items: center;
  border-width: 0.12rem;
  padding-bottom: 1.5rem;
  gap: 1.5rem;
}
@media only screen and (max-width: 48em) {
  .role-and-permission-single-wrap .left-container-wrap .team-mate-box .team-item {
    flex-wrap: wrap;
  }
}
.role-and-permission-single-wrap .left-container-wrap .team-mate-box .team-item .avatar-wrap {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.role-and-permission-single-wrap .left-container-wrap .team-mate-box .team-item .avatar-wrap span {
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 600;
}
.role-and-permission-single-wrap .left-container-wrap .team-mate-box .team-item .name {
  font-size: 1.6rem;
  font-weight: 500;
}
.role-and-permission-single-wrap .left-container-wrap .team-mate-box .team-item .remove-change-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: auto;
}
.role-and-permission-single-wrap .left-container-wrap .team-mate-box .team-item .remove-change-box .change {
  cursor: pointer;
  color: rgb(117, 90, 226);
}
.role-and-permission-single-wrap .left-container-wrap .team-mate-box .team-item .remove-change-box .remove {
  color: rgb(255, 15, 0);
  cursor: pointer;
}
.role-and-permission-single-wrap .left-container-wrap .team-mate-box .team-item .remove-badge {
  margin-left: auto;
  background-color: rgb(255, 245, 245);
  padding: 1rem 2rem;
  border-radius: 2.5rem;
}
.role-and-permission-single-wrap .left-container-wrap .team-mate-box .team-item .remove-badge span {
  color: rgb(255, 15, 0);
}
.role-and-permission-single-wrap .left-container-wrap .team-mate-box::-webkit-scrollbar {
  display: none;
}
.role-and-permission-single-wrap .left-container-wrap .invite-label-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.role-and-permission-single-wrap .left-container-wrap .invite-label-box .add-box {
  display: flex;
  align-items: center;
  border-radius: 2rem;
  padding: 1rem 2rem;
  gap: 0.7rem;
  cursor: pointer;
}
.role-and-permission-single-wrap .left-container-wrap .invite-label-box .add-box:hover .img-box {
  transform: scale(1.1);
}
.role-and-permission-single-wrap .left-container-wrap .invite-label-box .add-box .img-box {
  width: 2rem;
  height: 2rem;
  transition: transform 0.3s ease-in-out;
}
.role-and-permission-single-wrap .left-container-wrap .invite-label-box .add-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.role-and-permission-single-wrap .right-container-wrap {
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 48em) {
  .role-and-permission-single-wrap .right-container-wrap {
    flex: unset;
    width: 100%;
  }
}
.role-and-permission-single-wrap .right-container-wrap::-webkit-scrollbar {
  display: none;
}
.role-and-permission-single-wrap .right-container-wrap .edit-button-box {
  margin-top: auto;
  height: 5rem;
  width: 100%;
  align-self: stretch;
  border-width: 0.15rem;
  display: flex;
  justify-content: center;
}
.role-and-permission-single-wrap .right-container-wrap .edit-button-box .edit-btn {
  width: 70%;
}
.role-and-permission-single-wrap .right-container-wrap .first-child {
  flex: 1;
  display: flex;
  overflow-y: scroll;
  padding-bottom: 3rem;
  align-items: flex-start;
  margin-bottom: 1rem;
}
.role-and-permission-single-wrap .right-container-wrap .first-child::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 48em) {
  .role-and-permission-single-wrap .right-container-wrap .first-child {
    flex: unset;
    width: 100%;
  }
}
.role-and-permission-single-wrap .right-container-wrap .role-permission-show-box {
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  border-width: 0.12rem;
  border-radius: 1.2rem;
  min-width: 45rem;
}
@media only screen and (max-width: 48em) {
  .role-and-permission-single-wrap .right-container-wrap .role-permission-show-box {
    width: 100%;
    min-width: unset;
  }
}
.role-and-permission-single-wrap .right-container-wrap .role-permission-show-box > *:last-child {
  border-bottom: unset;
}
.role-and-permission-single-wrap .right-container-wrap .role-permission-show-box > *:not(:last-child) {
  border-width: 0.12rem;
}
.role-and-permission-single-wrap .right-container-wrap .role-permission-show-box .role-per-box .top-box {
  padding: 2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.role-and-permission-single-wrap .right-container-wrap .role-permission-show-box .role-per-box .top-box .role {
  font-weight: 500;
}
.role-and-permission-single-wrap .right-container-wrap .role-permission-show-box .role-per-box .top-box .img-box {
  width: 2rem;
  height: 2rem;
  transition: transform 0.3s ease-in-out;
}
.role-and-permission-single-wrap .right-container-wrap .role-permission-show-box .role-per-box .top-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.role-and-permission-single-wrap .right-container-wrap .role-permission-show-box .role-per-box .top-box .img-box-up {
  transform: rotate(-90deg);
}
.role-and-permission-single-wrap .right-container-wrap .role-permission-show-box .role-per-box .bottom-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  width: 100%;
}
.role-and-permission-single-wrap .right-container-wrap .role-permission-show-box .role-per-box .bottom-box .all-permission-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem 2rem;
}
.role-and-permission-single-wrap .right-container-wrap .role-permission-show-box .role-per-box .bottom-box .all-permission-box .permission-item {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.role-and-permission-single-wrap .right-container-wrap .role-permission-show-box .role-per-box .bottom-box .all-permission-box .permission-item .check-box-wrap .input-check:checked ~ .label-check__purple-light {
  background-color: #755ae2 !important;
}
.role-and-permission-single-wrap .right-container-wrap .role-permission-show-box .role-per-box .bottom-box .all-permission-box .permission-item .check-box-wrap .label-check__purple-light {
  border: 0.15rem solid rgb(204, 204, 204) !important;
}
.role-and-permission-single-wrap .right-container-wrap .role-permission-show-box .role-per-box .bottom-box .all-permission-box .permission-item .check-box-wrap .input-check:checked ~ .label-check__purple-light {
  border: 0.15rem solid #755ae2 !important;
}
.role-and-permission-single-wrap .right-container-wrap .role-permission-show-box .role-per-box .bottom-box-show {
  max-height: -moz-max-content;
  max-height: max-content;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
}

.creste-role-and-permission-form-wrap {
  flex: 1;
  display: flex;
}
@media only screen and (max-width: 42em) {
  .creste-role-and-permission-form-wrap {
    flex: unset;
    width: 100%;
  }
}
.creste-role-and-permission-form-wrap .form-wrap-start {
  width: 40rem;
  padding-top: 3rem;
  padding-bottom: 6rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  flex: 1;
}
@media only screen and (max-width: 42em) {
  .creste-role-and-permission-form-wrap .form-wrap-start {
    width: 100% !important;
    padding: 2rem 2rem;
    align-self: stretch;
  }
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-permission-to-role-box {
  display: flex;
  flex-direction: column;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-permission-to-role-box .label-title {
  align-self: flex-start;
  margin-bottom: 0.8rem;
  font-size: 1.3rem;
  text-transform: capitalize;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-permission-to-role-box .role-permission-show-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-width: 0.12rem;
  border-radius: 1.2rem;
  margin-bottom: 10rem;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-permission-to-role-box .role-permission-show-box > *:last-child {
  border-bottom: unset;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-permission-to-role-box .role-permission-show-box > *:not(:last-child) {
  border-width: 0.12rem;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-permission-to-role-box .role-permission-show-box .role-per-box .top-box {
  padding: 2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-permission-to-role-box .role-permission-show-box .role-per-box .top-box .role {
  font-weight: 500;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-permission-to-role-box .role-permission-show-box .role-per-box .top-box .img-box {
  width: 2rem;
  height: 2rem;
  transition: transform 0.3s ease-in-out;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-permission-to-role-box .role-permission-show-box .role-per-box .top-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-permission-to-role-box .role-permission-show-box .role-per-box .top-box .img-box-up {
  transform: rotate(-90deg);
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-permission-to-role-box .role-permission-show-box .role-per-box .bottom-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  width: 100%;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-permission-to-role-box .role-permission-show-box .role-per-box .bottom-box .all-permission-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem 2rem;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-permission-to-role-box .role-permission-show-box .role-per-box .bottom-box .all-permission-box .permission-item {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-permission-to-role-box .role-permission-show-box .role-per-box .bottom-box .all-permission-box .permission-item .check-box-wrap .input-check:checked ~ .label-check__purple-light {
  background-color: #755ae2 !important;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-permission-to-role-box .role-permission-show-box .role-per-box .bottom-box .all-permission-box .permission-item .check-box-wrap .label-check__purple-light {
  border: 0.15rem solid rgb(204, 204, 204) !important;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-permission-to-role-box .role-permission-show-box .role-per-box .bottom-box .all-permission-box .permission-item .check-box-wrap .input-check:checked ~ .label-check__purple-light {
  border: 0.15rem solid #755ae2 !important;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-permission-to-role-box .role-permission-show-box .role-per-box .bottom-box-show {
  max-height: -moz-max-content;
  max-height: max-content;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .label-title {
  align-self: flex-start;
  margin-bottom: 0.8rem;
  font-size: 1.3rem;
  text-transform: capitalize;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .note-box-wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: rgb(255, 246, 237);
  border-radius: 0.8rem;
  margin-bottom: 1rem;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .note-box-wrap .img-box {
  width: 3rem;
  height: 3rem;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .note-box-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .note-box-wrap .text {
  font-size: 1.2rem;
  color: rgb(234, 135, 45);
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box {
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box::-webkit-scrollbar {
  display: none;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box > *:not(:last-child) {
  padding-bottom: 2rem;
  border-width: 0.12rem;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box::-webkit-scrollbar {
  display: none;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box .select-react-disabled {
  cursor: not-allowed !important;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box .select-react-disabled input {
  cursor: not-allowed !important;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box .select-item {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 2rem;
  cursor: pointer;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box .select-item .check-wrap {
  margin-left: auto;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box .select-item .check-wrap .check-box-wrap .input-check:checked ~ .label-check__purple-light {
  background-color: #755ae2 !important;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box .select-item .check-wrap .check-box-wrap .label-check__purple-light {
  border: 0.15rem solid rgb(204, 204, 204) !important;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box .select-item .check-wrap .check-box-wrap .input-check:checked ~ .label-check__purple-light {
  border: 0.15rem solid #755ae2 !important;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box .select-item .avatar-box {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  background-color: #020202 !important;
  display: grid;
  place-items: center;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box .select-item .avatar-box span {
  font-weight: 600;
  color: #ffffff;
  display: inline-block;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box .select-item .name-role-box {
  display: flex;
  flex-direction: column;
  align-self: center;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box .select-item .name-role-box .name {
  font-size: 1.7rem;
  font-weight: 600;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box .select-item .img-wrap .img-box {
  width: 4rem;
  height: 4rem;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box .select-item .img-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box .select-item .select-option-wrap {
  margin-left: auto;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box .select-item .select-option-wrap form .form-group .react-select-class > *:nth-child(3) {
  width: -moz-max-content !important;
  width: max-content !important;
  border: unset !important;
  border-radius: 3rem;
  background-color: rgb(247, 247, 247);
  padding: 0.07rem 0.3rem;
}
.creste-role-and-permission-form-wrap .form-wrap-start .add-team-to-role-box .team-to-add-box .select-item .select-option-wrap form .form-group .react-select-class > *:nth-child(4) {
  left: unset;
  right: 0.5rem;
  width: -moz-max-content;
  width: max-content;
}/*# sourceMappingURL=SingleRoleAndPermissionIndex.css.map */