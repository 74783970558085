.payroll-create-index-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;

  // @media only screen and (max-width: 42em){
  //   width: 90%;
  //   margin: 0rem auto;
  // }
}
.payroll-create-index-wrap .upload-doc-wrap {
  width: 55rem;
  margin: 0rem auto;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  gap: 3rem;

  @media only screen and (max-width: 42em) {
    width: 90%;
    // margin: 2rem auto;
  }
}
.payroll-create-index-wrap .upload-doc-wrap .upload-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.payroll-create-index-wrap .upload-doc-wrap .upload-wrapper .upload-group {
  min-height: 25rem;
}
.payroll-create-index-wrap
  .upload-doc-wrap
  .upload-wrapper
  .upload-group
  label {
  height: 25rem;
}
.payroll-create-index-wrap .upload-doc-wrap .upload-wrapper .upload-text-pro {
  color: #755ae2;
  font-weight: 600;
}
.payroll-create-index-wrap .upload-doc-wrap .download-info-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 3.5rem;
  border-radius: 1.2rem;
  background-color: #f7f8f7;

  @media only screen and (max-width: 42em) {
    padding: 1.5rem;
  }
}
.payroll-create-index-wrap .upload-doc-wrap .download-info-box .text-icon-box {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  @media only screen and (max-width: 42em) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.4rem;
    margin-bottom: 0.3rem;
  }
}
.payroll-create-index-wrap
  .upload-doc-wrap
  .download-info-box
  .text-icon-box
  .img-box {
  margin-right: 1rem;
  display: grid;
  place-items: center;
}
.payroll-create-index-wrap
  .upload-doc-wrap
  .download-info-box
  .text-icon-box
  .text {
  font-size: 1.3rem;

  @media only screen and (max-width: 42em) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // gap: 1rem;
  }
}
.payroll-create-index-wrap
  .upload-doc-wrap
  .download-info-box
  .text-icon-box
  .text
  span {
  color: var(--primary-purple);
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s;
  display: inline-block;
}
.payroll-create-index-wrap
  .upload-doc-wrap
  .download-info-box
  .text-icon-box
  .text
  span:hover {
  transform: translateY(-0.02rem);
}
.payroll-create-index-wrap .upload-doc-wrap .download-info-box .dot-text {
  display: flex;
}
.payroll-create-index-wrap .upload-doc-wrap .download-info-box .dot-text .dot {
  margin-right: 0.5rem;
}
.payroll-create-index-wrap .upload-doc-wrap .download-info-box .dot-text .text {
  font-size: 1.3rem;

  @media only screen and (max-width: 42em) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // gap: 1rem;
  }
}
.payroll-create-index-wrap
  .upload-doc-wrap
  .download-info-box
  .dot-text
  .text
  span {
  font-weight: 700;
  color: #020202;
}
.payroll-create-index-wrap .create-table-wrap {
  width: 100%;

  @media only screen and (max-width: 42em) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // gap: 1rem;
    overflow-x: scroll;

    margin: 3rem 0rem 5rem 0rem;

    .scroll-wrap {
      width: 85rem;

      @media only screen and (max-width: 48em) {
        display: none;
      }
    }
  }

  tr {
    & > *:not(:last-child),
    & > *:not(:first-child) {
      padding-right: 2rem !important;
    }

    & > *:nth-child(3) {
      width: 15rem;
    }

    .edit-table-input input,
    .react-select-class > *:nth-child(3) {
      background-color: #ffffff;
    }

    td {
      // width: 10rem !important;
      // display: none;
      white-space: nowrap;
      form {
        // background-color: #ff0f00;
        // width: 12rem;

        input {
          max-width: 15rem !important;
        }

        .form-group{
          // width: 12rem;
          .input-group{
            background-color: #ffffff;
            padding-left: .7rem;
            .phone-wrap{
              display: none;
            }

            input{
              background-color: #ffffff;
            }
          }
        }
      }

      .payroll-table-action-wrap {
        margin: 0rem auto;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: red;
        gap: 1rem;
        width: 100%;

        .img-box {
          width: 3.5rem;
          height: 3.5rem;
          // background-color: #f7f8f7;
          border-radius: 50%;
          display: grid;
          place-items: center;
          transition: transform 0.3s ease-in-out;

          &:hover {
            transform: scale(1.05);
          }

          .img {
            width: 40%;
            height: 40%;
            object-fit: contain;
          }
        }

        & > *:nth-child(2) {
          .img {
            width: 50%;
            height: 50%;
            object-fit: contain;
          }
        }

        .delete-box {
          background-color: #fff5f5;
        }
      }

      //   .react-select-class > *:nth-child(3) {
      //     width: 20rem;

      //     input{
      //         width: 100%;
      //     }

      //   }
    }

    .status-type-wrap {
      background-color: #e9f5ff;
      border-radius: 3rem;
      width: max-content;
      padding: 0.8rem 1.2rem;
      color: #476885;
      text-transform: capitalize;

      span {
        // font-weight: 700;
        font-size: 1.2rem;
      }
    }

    .status-type-wrap-fail {
      background-color: #fff5f5;
      color: #ff0f00;
    }

    .status-type-wrap-success {
      background-color: #e8fff6;
      color: #1ace37;
    }

    .status-type-wrap-pending {
      background-color: #fff6ed;
      color: #ea872d;
    }
  }
}
.payroll-create-index-wrap .create-table-wrap .btn-upload-box {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 3rem;

  @media only screen and (max-width: 48em) {
    display: none;
  }
}
.payroll-create-index-wrap .create-table-wrap .btn-upload-box > *:last-child {
  margin-left: 2rem;
}
.payroll-create-index-wrap .create-table-wrap .btn-upload-box input {
  display: none;
}
.payroll-create-index-wrap .create-table-wrap .btn-upload-box .raven-btn {
  min-width: 15rem;
}
.payroll-create-index-wrap .create-table-wrap .text-avatar-box {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.payroll-create-index-wrap .create-table-wrap .text-avatar-box .avatar-box {
  width: 3rem;
  height: 3rem;
  background-color: #f7f8f7;
  border: 0.1rem solid #cccccc;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.payroll-create-index-wrap
  .create-table-wrap
  .text-avatar-box
  .avatar-box
  span {
  font-weight: 600;
  font-size: 1rem;
  text-transform: capitalize;
}
.payroll-create-index-wrap .create-table-wrap .table-pagination-box {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  padding: 0rem 3rem;
  margin-bottom: 2rem;
}
.payroll-create-index-wrap .step-three-wrap-box {
  width: 40rem;
  margin-top: 3rem;

  @media only screen and (max-width: 42em) {
    width: 90%;
    margin: 3rem auto;
  }
}
.payroll-create-index-wrap .step-three-wrap-box .yellow-info-box {
  margin-top: 5rem;
  display: flex;
  align-items: flex-start;
  gap: 0.6rem;
  min-height: 20rem;
}
.payroll-create-index-wrap .step-three-wrap-box .yellow-info-box .img-box {
  width: 2.2rem;
  height: 2.2rem;
}
.payroll-create-index-wrap .step-three-wrap-box .yellow-info-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.payroll-create-index-wrap .step-three-wrap-box .yellow-info-box .text {
  font-size: 1.3rem;
  color: #ea872d;
  text-align: center;
}
.payroll-create-index-wrap .step-three-wrap-box .amount-detail-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.payroll-create-index-wrap
  .step-three-wrap-box
  .amount-detail-box
  > *:last-child {
  text-align: right;
}
.payroll-create-index-wrap .step-three-wrap-box .amount-detail-box .box {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.payroll-create-index-wrap .step-three-wrap-box .amount-detail-box .box .label {
  font-size: 1.25rem;
}
.payroll-create-index-wrap .step-three-wrap-box .amount-detail-box .box .value {
  font-size: 1.45rem;
  font-weight: 700;
}
.payroll-create-index-wrap .step-three-wrap-box .amount-detail-box .img-box {
  width: 2rem;
  height: 2rem;
}
.payroll-create-index-wrap
  .step-three-wrap-box
  .amount-detail-box
  .img-box
  .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.payroll-create-index-wrap .step-three-wrap-box .tax-pension-nhf-box {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  border-radius: 1.6rem;
  gap: 1.5rem;
  margin: 2rem 0rem;
}
.payroll-create-index-wrap
  .step-three-wrap-box
  .tax-pension-nhf-box
  > *:not(:last-child) {
  border-bottom: 0.1rem solid rgba(204, 204, 204, 0.5);
  padding-bottom: 1.5rem;
}
.payroll-create-index-wrap .step-three-wrap-box .tax-pension-nhf-box .box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payroll-create-index-wrap
  .step-three-wrap-box
  .tax-pension-nhf-box
  .box
  .text {
  font-size: 1.25rem;
}
.payroll-create-index-wrap
  .step-three-wrap-box
  .tax-pension-nhf-box
  .box
  .text
  span {
  font-weight: 700;
  color: #020202;
}
.payroll-create-index-wrap
  .step-three-wrap-box
  .tax-pension-nhf-box
  .box
  > *:last-child {
  transform: scale(0.75);
}
.payroll-create-index-wrap .step-three-wrap-box .box-alone {
  margin-top: 1.5rem;
}
.payroll-create-index-wrap .step-three-wrap-box .box-alone .text {
  font-size: 1.25rem;
}
.payroll-create-index-wrap .step-three-wrap-box .box-alone .text span {
  font-weight: 700;
  color: #020202;
}

.payroll-manual-wrap-box {
  margin-top: 3rem;
  width: 40rem;

  @media only screen and (max-width: 42em) {
    width: 90%;
    margin: 2rem auto;

    .form-group {
      width: 100%;
    }
  }
}
.payroll-manual-wrap-box .step-three-wrap-box {
  width: 40rem;
  margin-top: 3rem;

  @media only screen and (max-width: 42em) {
    width: 100%;
    margin: 2rem auto;
  }
}
.payroll-manual-wrap-box .step-three-wrap-box .yellow-info-box {
  margin-top: 5rem;
  display: flex;
  align-items: flex-start;
  gap: 0.6rem;
  min-height: 20rem;

  @media only screen and (max-width: 42em) {
    min-height: unset;
  }
}
.payroll-manual-wrap-box .step-three-wrap-box .yellow-info-box .img-box {
  width: 2.2rem;
  height: 2.2rem;
}
.payroll-manual-wrap-box .step-three-wrap-box .yellow-info-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.payroll-manual-wrap-box .step-three-wrap-box .yellow-info-box .text {
  font-size: 1.3rem;
  color: #ea872d;
  text-align: center;
}
.payroll-manual-wrap-box .step-three-wrap-box .amount-detail-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.payroll-manual-wrap-box
  .step-three-wrap-box
  .amount-detail-box
  > *:last-child {
  text-align: right;
}
.payroll-manual-wrap-box .step-three-wrap-box .amount-detail-box .box {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.payroll-manual-wrap-box .step-three-wrap-box .amount-detail-box .box .label {
  font-size: 1.25rem;
}
.payroll-manual-wrap-box .step-three-wrap-box .amount-detail-box .box .value {
  font-size: 1.45rem;
  font-weight: 700;
}
.payroll-manual-wrap-box .step-three-wrap-box .amount-detail-box .img-box {
  width: 2rem;
  height: 2rem;
}
.payroll-manual-wrap-box .step-three-wrap-box .amount-detail-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.payroll-manual-wrap-box .step-three-wrap-box .tax-pension-nhf-box {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  border-radius: 1.6rem;
  gap: 1.5rem;
  margin: 2rem 0rem;
}
.payroll-manual-wrap-box
  .step-three-wrap-box
  .tax-pension-nhf-box
  > *:not(:last-child) {
  border-bottom: 0.1rem solid rgba(204, 204, 204, 0.5);
  padding-bottom: 1.5rem;
}
.payroll-manual-wrap-box .step-three-wrap-box .tax-pension-nhf-box .box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payroll-manual-wrap-box .step-three-wrap-box .tax-pension-nhf-box .box .text {
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.payroll-manual-wrap-box
  .step-three-wrap-box
  .tax-pension-nhf-box
  .box
  .text
  span {
  font-weight: 700;
  color: #020202;
  // display: none;
}
.payroll-manual-wrap-box
  .step-three-wrap-box
  .tax-pension-nhf-box
  .box
  > *:last-child {
  transform: scale(0.75);
}
.payroll-manual-wrap-box .step-three-wrap-box .box-alone {
  margin-top: 1.5rem;
}
.payroll-manual-wrap-box .step-three-wrap-box .box-alone .text {
  font-size: 1.25rem;
}
.payroll-manual-wrap-box .step-three-wrap-box .box-alone .text span {
  font-weight: 700;
  color: #020202;
}
.payroll-manual-wrap-box .form-wrap-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .two-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;

    @media only screen and (max-width: 42em) {
      grid-template-columns: 1fr;
    }
  }
}
.payroll-manual-wrap-box .form-wrap-box .verify-text {
  font-weight: 700;
}
.payroll-manual-wrap-box .form-wrap-box .show-hide-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.payroll-manual-wrap-box .form-wrap-box .show-hide-box-visible {
  overflow-x: unset;
  overflow-y: unset;
  max-height: 30rem;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
}
.payroll-manual-wrap-box .form-wrap-box .verify-name-box {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: 0.7rem;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  width: 100%;
}
.payroll-manual-wrap-box
  .form-wrap-box
  .verify-name-box
  > *:first-child
  .label-check {
  border-radius: 50%;
  transform: scale(0.8);
}
.payroll-manual-wrap-box .form-wrap-box .verify-name-box .text {
  font-weight: 600;
  font-size: 1.3rem;
  margin-left: 0.5rem;
}
.payroll-manual-wrap-box .form-wrap-box .verify-name-box-show {
  max-height: 5rem;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}
.payroll-manual-wrap-box .form-wrap-box .select-wrap-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin-bottom: 2rem;
}
.payroll-manual-wrap-box .form-wrap-box .select-wrap-box .box {
  border-radius: 0.8rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: none;
}
.payroll-manual-wrap-box .form-wrap-box .select-wrap-box .box .img-box {
  width: 4rem;
  height: 4rem;
}
.payroll-manual-wrap-box .form-wrap-box .select-wrap-box .box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.payroll-manual-wrap-box .form-wrap-box .select-wrap-box .box .name {
  margin-top: 3rem;
  font-weight: 600;
} /*# sourceMappingURL=PayrollCretaeIndex.css.map */

.payroll-manual-wrap-box .step-three-wrap-box .tax-pension-nhf-box .box-check {
  display: flex;
  align-items: center;
  justify-content: unset;

  & > *:first-child {
    transform: scale(0.85);
    margin-right: -2.5rem;
  }

  .des-text {
    font-size: 1.7rem !important;
    // display: none;
  }
}

.payroll-create-index-wrap
  .step-three-wrap-box
  .tax-pension-nhf-box
  .box-check {
  display: flex;
  align-items: center;
  justify-content: unset;

  & > *:first-child {
    transform: scale(0.85);
    margin-right: -2.5rem;
  }

  .des-text {
    font-size: 1.7rem !important;
    // display: none;
  }
}
