.card-create-reusable-container .dashboard-create-card-wrap-box {
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
  width: 40rem;
}
@media only screen and (max-width: 52em) {
  .card-create-reusable-container .dashboard-create-card-wrap-box {
    width: 90%;
    margin: 0rem auto;
    padding-bottom: 3rem;
    align-items: center;
    justify-content: center;
  }
}
.card-create-reusable-container .dashboard-create-card-wrap-box .select-card-request-type-box {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 2rem;
}
.card-create-reusable-container .dashboard-create-card-wrap-box .select-card-request-type-box .description-box-wrap {
  padding: 2rem 1.5rem;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.card-create-reusable-container .dashboard-create-card-wrap-box .select-card-request-type-box .description-box-wrap .icon-subtitle {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  gap: 1rem;
}
.card-create-reusable-container .dashboard-create-card-wrap-box .select-card-request-type-box .description-box-wrap .icon-subtitle .img-wrap {
  display: grid;
  place-items: center;
}
.card-create-reusable-container .dashboard-create-card-wrap-box .select-card-request-type-box .description-box-wrap .icon-subtitle .img-wrap .img-box-select {
  width: 2rem;
  height: 2rem;
}
.card-create-reusable-container .dashboard-create-card-wrap-box .select-card-request-type-box .description-box-wrap .icon-subtitle .img-wrap .img-box-select .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.card-create-reusable-container .dashboard-create-card-wrap-box .select-card-request-type-box .description-box-wrap .icon-subtitle .sub-title {
  font-size: 1.35rem;
  text-transform: uppercase;
}
.card-create-reusable-container .dashboard-create-card-wrap-box .select-card-request-type-box .description-box-wrap .list-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.card-create-reusable-container .dashboard-create-card-wrap-box .select-card-request-type-box .description-box-wrap .list-box .list-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.card-create-reusable-container .dashboard-create-card-wrap-box .select-card-request-type-box .description-box-wrap .list-box .list-item .black-fade-white-bg {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  display: inline-block;
}
.card-create-reusable-container .dashboard-create-card-wrap-box .select-card-request-type-box .select-title {
  font-size: 1.5rem;
}
.card-create-reusable-container .dashboard-create-card-wrap-box .select-card-request-type-box .img-select-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2.5rem;
}
.card-create-reusable-container .dashboard-create-card-wrap-box .select-card-request-type-box .img-select-wrap .img-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
  border-radius: 1rem;
  gap: 1.5rem;
}
.card-create-reusable-container .dashboard-create-card-wrap-box .select-card-request-type-box .img-select-wrap .img-wrap .security-lock-wrap-box {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
}
.card-create-reusable-container .dashboard-create-card-wrap-box .select-card-request-type-box .img-select-wrap .img-wrap .img-box-card {
  width: 10rem;
  height: 7.5rem;
  display: flex;
  align-items: center;
}
.card-create-reusable-container .dashboard-create-card-wrap-box .select-card-request-type-box .img-select-wrap .img-wrap .img-box-card .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.card-create-reusable-container .dashboard-create-card-wrap-box .select-card-request-type-box .img-select-wrap .img-wrap .name {
  font-size: 1.25rem;
  margin-top: 3rem;
}

.card-create-reusable-container .dashboard-create-card-wrap-box .preview-details-common-box {
  margin-top: 3rem;
}
@media only screen and (max-width: 52em) {
  .card-create-reusable-container .dashboard-create-card-wrap-box .preview-details-common-box {
    width: 90%;
    margin: 2rem auto 0rem auto;
  }
}

.card-create-reusable-container .dashboard-create-card-wrap-box .select-wrap-box {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
@media only screen and (max-width: 52em) {
  .card-create-reusable-container .dashboard-create-card-wrap-box .select-wrap-box {
    margin: 2rem auto;
  }
}

.card-create-reusable-container .dashboard-create-card-wrap-box .select-wrap-box .title {
  font-size: 1.45rem;
  text-align: left;
}

.card-create-reusable-container .dashboard-create-card-wrap-box .select-wrap-box .select-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin: 2rem 0rem;
}
@media only screen and (max-width: 52em) {
  .card-create-reusable-container .dashboard-create-card-wrap-box .select-wrap-box .select-wrap {
    grid-gap: 1rem;
  }
}

.card-create-reusable-container .dashboard-create-card-wrap-box .select-wrap-box .select-wrap .box {
  display: flex;
  flex-direction: column;
  background-color: rgba(247, 248, 247, 0.5);
  border-radius: 0.8rem;
  cursor: pointer;
  padding: 1.3rem;
}
@media only screen and (max-width: 52em) {
  .card-create-reusable-container .dashboard-create-card-wrap-box .select-wrap-box .select-wrap .box {
    gap: 1rem;
  }
}

.card-create-reusable-container .dashboard-create-card-wrap-box .select-wrap-box .select-wrap .box .img-box {
  width: 4rem;
  height: 4rem;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 52em) {
  .card-create-reusable-container .dashboard-create-card-wrap-box .select-wrap-box .select-wrap .box .img-box {
    width: 3rem;
    height: 3rem;
  }
}

.card-create-reusable-container .dashboard-create-card-wrap-box .select-wrap-box .select-wrap .box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.card-create-reusable-container .dashboard-create-card-wrap-box .select-wrap-box .select-wrap .box .name {
  font-weight: 600;
  font-size: 1.5rem;
}

.card-create-reusable-container .dashboard-create-card-wrap-box .select-wrap-box .select-wrap .box .text {
  font-size: 1.25rem;
  margin-top: 0.25rem;
}

.card-create-reusable-container .dashboard-create-card-wrap-box .select-wrap-box .text-line {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  width: 100%;
  margin: 1.5rem 0rem;
}

.card-create-reusable-container .dashboard-create-card-wrap-box .select-wrap-box .text-line .text {
  color: #676767;
  background-color: #f7f8f7;
  font-size: 1.1rem;
  border: 0.1rem solid #cccccc;
  padding: 0.5rem 1.1rem;
  border-radius: 10rem;
  font-weight: 600;
  width: -moz-max-content;
  width: max-content;
}

.card-create-reusable-container .dashboard-create-card-wrap-box .select-wrap-box .text-line span {
  height: 0.1rem;
  background-color: rgba(204, 204, 204, 0.5);
}

.card-create-reusable-container .dashboard-create-card-wrap-box .select-wrap-box .infor-wrap-box {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 2rem;
}

.card-create-reusable-container .dashboard-create-card-wrap-box .select-wrap-box .infor-wrap-box .info-box {
  display: flex;
  align-items: center;
}

.card-create-reusable-container .dashboard-create-card-wrap-box .select-wrap-box .infor-wrap-box .info-box span {
  width: 0.7rem;
  height: 0.7rem;
  display: inline-block;
  border-radius: 50%;
  margin-right: 1rem;
  background-color: var(--primary-purple);
}

.card-create-reusable-container .dashboard-create-card-wrap-box-reduce {
  width: 40rem;
}

.card-create-reusable-container .dashboard-create-card-wrap-box-reduce .step-two-wrap {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.card-create-reusable-container .dashboard-create-card-wrap-box-reduce .step-two-wrap .title {
  font-size: 1.45rem;
  text-align: left;
  margin-bottom: -0.5rem;
}

.card-create-reusable-container .dashboard-create-card-wrap-box-reduce .step-two-wrap .select-theme-box {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  overflow-x: scroll;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.card-create-reusable-container .dashboard-create-card-wrap-box-reduce .step-two-wrap .select-theme-box::-webkit-scrollbar:vertical {
  display: none;
}
.card-create-reusable-container .dashboard-create-card-wrap-box-reduce .step-two-wrap .select-theme-box::-webkit-scrollbar {
  height: 0.5rem;
}
.card-create-reusable-container .dashboard-create-card-wrap-box-reduce .step-two-wrap .select-theme-box::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.card-create-reusable-container .dashboard-create-card-wrap-box-reduce .step-two-wrap .select-theme-box::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 30rem;
}
.card-create-reusable-container .dashboard-create-card-wrap-box-reduce .step-two-wrap .select-theme-box::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}

.card-create-reusable-container .dashboard-create-card-wrap-box-reduce .step-two-wrap .select-theme-box .img-box-card .select-option-class-active {
  border-width: 0.2rem !important;
}

.card-create-reusable-container .dashboard-create-card-wrap-box-reduce .step-two-wrap .select-theme-box .img-box-card {
  width: 100%;
  height: 14rem;
  transition: all 0.3s;
  border-radius: 0.8rem;
}

.card-create-reusable-container .dashboard-create-card-wrap-box-reduce .step-two-wrap .select-theme-box .img-box-card .img {
  border-radius: 0.8rem;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.card-create-reusable-container .dashboard-create-card-wrap-box-reduce .step-two-wrap .input-form-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 2rem;
}

.card-create-reusable-container .dashboard-create-card-wrap-box-reduce .step-two-wrap .input-form-wrap .switch-box {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.card-create-reusable-container .dashboard-create-card-wrap-box-reduce .step-two-wrap .input-form-wrap .switch-box .box {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.dashboard-card-main-wrap-box {
  flex: 1;
  display: flex;
  padding-top: 2rem;
}
@media only screen and (max-width: 52em) {
  .dashboard-card-main-wrap-box {
    flex-direction: column;
    flex: unset;
    width: 100%;
  }
}

.dashboard-card-main-wrap-box .left-box {
  flex: 0 0 50%;
  border-right: 0.1rem solid rgba(204, 204, 204, 0.5);
  display: flex;
  flex-direction: column;
  padding: 0rem 3rem;
  overflow-y: scroll;
}
@media only screen and (max-width: 52em) {
  .dashboard-card-main-wrap-box .left-box {
    flex: unset;
    padding: 0 1.5rem;
    margin-bottom: 1.5rem;
    width: 100%;
  }
}
.dashboard-card-main-wrap-box .left-box .status-type-wrap-disabled {
  background-color: #f7f8f7;
}

.dashboard-card-main-wrap-box .left-box .status-type-wrap {
  background-color: #fff6ed;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #ea872d;
  margin-left: -1rem;
}

.dashboard-card-main-wrap-box .left-box .status-type-wrap span {
  font-size: 1.2rem;
}

.dashboard-card-main-wrap-box .left-box .status-type-wrap-virtual {
  background-color: #f0eeff;
  color: #755ae2;
}

.dashboard-card-main-wrap-box .left-box .table-row-selected {
  background-color: #f7f8f7;
}

.dashboard-card-main-wrap-box .left-box .table-pagination-box {
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding: 0rem 3rem;
}

.dashboard-card-main-wrap-box .right-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0rem 3rem;
  position: relative;
}
@media only screen and (max-width: 52em) {
  .dashboard-card-main-wrap-box .right-box {
    padding: 0rem 1.5rem;
    flex: unset;
    height: -moz-max-content;
    height: max-content;
  }
}
.dashboard-card-main-wrap-box .right-box .status-type-wrap {
  background-color: #fff6ed;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #ea872d;
  position: absolute;
  top: 1rem;
  right: 4rem;
}
.dashboard-card-main-wrap-box .right-box .status-type-wrap span {
  font-size: 1.2rem;
}
.dashboard-card-main-wrap-box .right-box .status-type-wrap-virtual {
  background-color: #f0eeff;
  color: #755ae2;
}
.dashboard-card-main-wrap-box .right-box .status-type-wrap-active {
  background-color: #e8fff6;
  color: #1ace37;
}
.dashboard-card-main-wrap-box .right-box .status-type-wrap-disabled {
  background-color: #f7f8f7;
}
.dashboard-card-main-wrap-box .right-box .status-type-wrap-disabled-box {
  background-color: rgb(255, 245, 245);
}
.dashboard-card-main-wrap-box .right-box .status-type-wrap-disabled-box span {
  color: rgb(255, 15, 0);
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box {
  flex: 1;
  margin-top: 2rem;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}
@media only screen and (max-width: 52em) {
  .dashboard-card-main-wrap-box .right-box .information-transaction-box {
    grid-auto-rows: auto;
  }
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box > * {
  grid-row: 1/-1;
  grid-column: 1/-1;
}
@media only screen and (max-width: 52em) {
  .dashboard-card-main-wrap-box .right-box .information-transaction-box > * {
    height: -moz-max-content;
    height: max-content;
    margin-bottom: auto;
    align-self: flex-start;
  }
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .information-wrap {
  display: flex;
  position: relative;
  z-index: 2;
  overflow-y: scroll;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .information-wrap::-webkit-scrollbar:horizontal {
  display: none;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .information-wrap::-webkit-scrollbar {
  width: 0.5rem;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .information-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .information-wrap::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .information-wrap::-webkit-scrollbar-thumb:hover {
  background: #020202;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .information-wrap .info-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .information-wrap .info-wrap > *:not(:last-child) {
  border-bottom: 0.1rem solid rgba(204, 204, 204, 0.5);
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .information-wrap .info-wrap .box {
  display: flex;
  align-items: center;
  padding-right: 3rem;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .information-wrap .info-wrap .box .label-value-box {
  margin-right: auto;
  flex: 0 0 80%;
  padding: 1.5rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .information-wrap .info-wrap .box .label-value-box .label {
  font-size: 1.2rem;
  font-weight: 700;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .information-wrap .info-wrap .box .label-value-box .value {
  font-weight: 400;
  font-size: 1.5rem;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .information-wrap .info-wrap .box .copy-box {
  padding: 0.55rem 1.3rem;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.3s;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .information-wrap .info-wrap .box .copy-box:hover {
  transform: scale(1.1);
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .information-wrap .info-wrap .box .copy-box span {
  font-size: 1.1rem;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .information-wrap .info-wrap .box .copy-box-active {
  background-color: #020202;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .information-wrap .info-wrap .box .copy-box-active span {
  color: #ffffff;
  display: inline-block;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .information-wrap .info-wrap .box .copy-box-active span .icon {
  margin-right: 0.5rem;
  color: currentColor;
  width: 1rem;
  height: 1rem;
  transform: translateY(0.06rem);
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap {
  position: relative;
  z-index: 3;
  transform: translateX(100%);
  transition: all 0.5s ease-in-out;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap .table-pagination-box {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  padding: 0rem 3rem;
  margin-bottom: 2rem;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap::-webkit-scrollbar:horizontal {
  display: none;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap::-webkit-scrollbar {
  width: 0.5rem;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap::-webkit-scrollbar-thumb:hover {
  background: #020202;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap .transact-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap .transact-box > *:not(:last-child) {
  border-bottom: 0.1rem solid rgba(204, 204, 204, 0.5);
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap .transact-box .child-row {
  display: flex;
  align-items: center;
  padding: 1.5rem 3rem 1.5rem 0rem;
  width: 100%;
  border-width: 0.15rem;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap .transact-box .child-row .amount {
  font-size: 1.35rem;
  color: #1ace37;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap .transact-box .child-row .amount-debit {
  color: #ff0f00;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap .transact-box .child-row .img-text-box {
  display: flex;
  margin-right: auto;
  align-items: center;
  padding-left: 2rem;
}
.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap .transact-box .child-row .img-text-box .img-box-cre-deb {
  width: 3rem;
  height: 3rem;
  background-color: #1ace37 !important;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap .transact-box .child-row .img-text-box .img-box-cre-deb .img {
  width: 35%;
  height: 35%;
  -o-object-fit: contain;
     object-fit: contain;
  filter: brightness(1000%);
}
.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap .transact-box .child-row .img-text-box .img-box-debit {
  background-color: #ff0f00 !important;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap .transact-box .child-row .img-text-box .text-box .date {
  font-size: 1.1rem;
  margin-top: 0.4rem;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap .transact-box .child-row .img-text-box .img-box {
  width: 3rem;
  height: 3rem;
  margin-right: 0.7rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap .transact-box .child-row .img-text-box .img-box .img {
  width: 35%;
  height: 35%;
  -o-object-fit: contain;
  object-fit: contain;
}

.dashboard-card-main-wrap-box .right-box .information-transaction-box .transaction-wrap-show {
  transform: translateX(0%);
}

.dashboard-card-main-wrap-box .right-box .select-view-wrap {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  border-bottom: 0.1rem solid #f0eeff;
}

.dashboard-card-main-wrap-box .right-box .select-view-wrap .option {
  padding-bottom: 1.2rem;
  cursor: pointer;
  position: relative;
}

.dashboard-card-main-wrap-box .right-box .select-view-wrap .option > * {
  position: relative;
  z-index: 3;
}

.dashboard-card-main-wrap-box .right-box .select-view-wrap .option::after {
  content: "";
  position: absolute;
  height: 0.35rem;
  width: 100%;
  background-color: #020202;
  bottom: 0;
  left: 0;
  visibility: hidden;
  border-radius: 3rem;
}

.dashboard-card-main-wrap-box .right-box .select-view-wrap .option-active {
  cursor: default;
}

.dashboard-card-main-wrap-box .right-box .select-view-wrap .option-active::after {
  visibility: visible;
}

.dashboard-card-main-wrap-box .right-box .select-view-wrap .option-active span {
  color: #020202;
  font-weight: 500;
}

.dashboard-card-main-wrap-box .right-box .card-action-description-box {
  border-radius: 1.2rem;
  background-image: url("../../../assets/wire-frame-bg-img.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  padding: 2rem 1.2rem;
  display: flex;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 52em) {
  .dashboard-card-main-wrap-box .right-box .card-action-description-box {
    margin-bottom: 1.5rem;
  }
}

.dashboard-card-main-wrap-box .right-box .card-action-description-box .left-view-box .img-box {
  height: 15rem;
  min-width: 10rem;
}
@media only screen and (max-width: 52em) {
  .dashboard-card-main-wrap-box .right-box .card-action-description-box .left-view-box .img-box {
    display: none;
  }
}

.dashboard-card-main-wrap-box .right-box .card-action-description-box .left-view-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: 0.8rem;
}

.dashboard-card-main-wrap-box .right-box .card-action-description-box .right-view-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 3rem;
  justify-content: center;
}
@media only screen and (max-width: 52em) {
  .dashboard-card-main-wrap-box .right-box .card-action-description-box .right-view-box {
    margin-left: unset;
    gap: 1.5rem;
  }
}

.dashboard-card-main-wrap-box .right-box .card-action-description-box .right-view-box .label {
  font-size: 1.2rem;
}

.dashboard-card-main-wrap-box .right-box .card-action-description-box .right-view-box .label span {
  font-weight: 700;
  color: #020202;
}

.dashboard-card-main-wrap-box .right-box .card-action-description-box .right-view-box .value {
  font-size: 2.2rem;
  font-weight: 600;
  margin: 0.6rem 0rem 1.1rem 0rem;
}
@media only screen and (max-width: 52em) {
  .dashboard-card-main-wrap-box .right-box .card-action-description-box .right-view-box .value {
    margin-bottom: 0rem;
    font-size: 1.65rem;
  }
}

.dashboard-card-main-wrap-box .right-box .card-action-description-box .right-view-box .label-two {
  font-size: 1.6rem;
}
@media only screen and (max-width: 52em) {
  .dashboard-card-main-wrap-box .right-box .card-action-description-box .right-view-box .label-two {
    font-size: 1.3rem;
  }
}

.dashboard-card-main-wrap-box .right-box .card-action-description-box .right-view-box .action-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  flex-wrap: wrap;
  width: 100%;
}

.dashboard-card-main-wrap-box .right-box .card-action-description-box .right-view-box .action-wrap > *:not(:last-child) {
  margin-right: 2rem;
}

.dashboard-card-main-wrap-box .right-box .card-action-description-box .right-view-box .action-wrap .box {
  display: flex;
  align-items: center;
  gap: 0.9rem;
  cursor: pointer;
}

.dashboard-card-main-wrap-box .right-box .card-action-description-box .right-view-box .action-wrap .box:hover .icon-box {
  transform: translateY(-0.1rem);
}

.dashboard-card-main-wrap-box .right-box .card-action-description-box .right-view-box .action-wrap .box .icon-box {
  width: 3rem;
  height: 3rem;
  background-color: #ffffff;
  display: grid;
  place-items: center;
  border-radius: 50%;
  box-shadow: 0px 4px 8px -2px rgba(51, 51, 51, 0.1), 0px 2px 4px -2px rgba(51, 51, 51, 0.06);
  transition: all 0.3s;
}

.dashboard-card-main-wrap-box .right-box .card-action-description-box .right-view-box .action-wrap .box .icon-box .img-box {
  width: 1.3rem;
  height: 1.3rem;
  display: grid;
  place-items: center;
}

.dashboard-card-main-wrap-box .right-box .card-action-description-box .right-view-box .action-wrap .box .icon-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.dashboard-card-main-wrap-box .right-box .card-action-description-box .right-view-box .action-wrap .box .icon-box .freeze {
  width: 1.7rem;
  height: 1.7rem;
} 
.add-another-user-option-box {
  margin-top: 2rem;
  padding-top: 2rem;
}
@media only screen and (max-width: 52em) {
  .add-another-user-option-box {
    width: 90%;
    align-self: center;
  }
}
.add-another-user-option-box .select-user-box {
  margin: 1.5rem 0rem;
  display: flex;
  align-items: center;
  gap: 2rem;
}
.add-another-user-option-box .select-user-box .box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.add-another-user-option-box .select-user-box .box > *:first-child {
  transform: scale(0.9);
}
.add-another-user-option-box .select-user-box .box label {
  cursor: pointer;
}
.add-another-user-option-box .detail-preview-two {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 1.2rem;
  padding: 1.5rem;
  position: relative;
}
.add-another-user-option-box .detail-preview-two .add-error-box {
  padding: 0.6rem 1.7rem;
  background-color: #ffffff;
  border-radius: 6rem;
  box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04), 0px 0px 2px rgba(51, 51, 51, 0.02);
  transition: all 0.3s;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.add-another-user-option-box .detail-preview-two .add-error-box:hover {
  transform: scale(1.05);
}
.add-another-user-option-box .detail-preview-two .add-error-box span {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.add-another-user-option-box .detail-preview-two .title-bvn-box {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.add-another-user-option-box .detail-preview-two .title-bvn-box .bvn-nin-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}
.add-another-user-option-box .detail-preview-two .title-bvn-box .bvn-nin-box p {
  display: flex;
  align-items: center;
  font-size: 1.15rem;
}
.add-another-user-option-box .detail-preview-two .title-bvn-box .bvn-nin-box p span {
  margin-right: 0.3rem;
  display: inline-block;
}
.add-another-user-option-box .detail-preview-two .title-bvn-box .title {
  font-size: 1.5rem;
  font-weight: 700;
}/*# sourceMappingURL=DashboardCard.css.map */