.no_content_wrapper {
   
        .sub_text {
            font-weight: 400;
            color: #676767;
  
    }
}

.sub_text {
    font-weight: 400;
    color: #676767;
}