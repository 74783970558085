.new-account-drop-index-wrapper-source-account {
  // width: 25rem;
  display: flex;
  align-items: center;
  position: relative;

  .value-selected-box {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: rgba(255, 246, 237, 1);
    padding: 0.5rem 1.2rem;
    border-radius: 2.5rem;

    .value-select {
      text-transform: capitalize;
      font-size: 1.3rem;
    }

    .icon-box {
      margin-top: 0.25rem;
      .img-box {
        width: 2rem;
        height: 2rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .drop-box-wrapper {
    width: 25rem;
    // min-height: 25rem;
    // background-color: red;
    position: absolute;
    border-radius: 1.2rem;
    bottom: 110%;
    right: 0%;
    display: flex;
    flex-direction: column;
    border-width: 0.15rem;
    animation: moveDownSettingDrop 0.5s ease-in-out backwards;
    max-height: 25rem;
    overflow-y: scroll;

    &::-webkit-scrollbar{
        display: none;
    }

    .item-row {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 1rem 2rem;
      text-align: left;
      border-width: 0.15rem;
      cursor: pointer;

      .name {
        font-size: 1.45rem;
        font-weight: 600;
      }

      .text {
        font-size: 1.25rem;
      }
    }

    & > *:not(:last-child) {
      padding-bottom: 2rem;
    }

    .item-row-selected {
    //   background-color: rgba(247, 248, 247, 1);
      cursor: default;
    }
  }

  .drop-box-wrapper-bottom {
    top: 110%;
    bottom: unset;
  }
}

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
    // visibility: hidden;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
    // visibility: ;
  }
}
